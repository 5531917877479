import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { GridReadyEvent } from '@ag-grid-community/core'
import SortableTable from '../../../Shared/SortableTable'
import { Button, Form, FormGroup, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorComponent, LoadingComponent } from '../../../Report/Shared/ReportComponent'
import { CreateDataLoadInput, DataLoadBasicFragment, DataLoadDetailsFragment, DataLoadTemplateFragment, FileBasicInfoFragment, LoadTypeCode, Maybe, useCreateDataLoadMutation, useDataLoaderDetailsLazyQuery, useDataLoadersBasicQuery, useGetAllDataLoadTemplatesQuery, useMeDepartmentQuery } from '../../../../__generated__/graphql'
import { ImportedFilesColumnDef } from './ImportedFilesConfig'
import { AddFileModal } from '../../../Shared/Document'
import { match } from 'react-router-dom'
import { TemporaryAlertContext } from '../../../../Context/TemporaryAlertContext'
import { compact } from 'lodash'
import { useForceUpdate } from '../../../../helpers/helpers'

const defaultDataLoadInput = {
  template: {
    id: '',
  },
  type: {
    code: '',
    value: '',
  },
}

type ImportedFilesProps = {
  auth: any
  match: match
}

const getImportInput = (dataLoadState: any) => {
  let { template, type, uploadFileInfo } = dataLoadState
  return { template: template?.id, type: type?.code, uploadFileInfo }
}

const getTemplatesOptions = (templates: Maybe<DataLoadTemplateFragment>[], type: LoadTypeCode = LoadTypeCode._2) => {
  let filteredTemplates = compact(templates.filter((template) => template?.type?.code === type))
  return filteredTemplates.map((template) => {
    return {
      code: template.id,
      // https://callanllc.atlassian.net/browse/CAL-2926?focusedCommentId=85929
      // value: template.vendor?.name,
      value: template?.name || template.vendor?.name,
    }
  })
}

const AllowedFileTypes = [
  "application/vnd.ms-excel", // xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
]

const ImportedFilesComponent: React.FC<ImportedFilesProps> = (props) => {
  const { auth } = props
  const name = ''
  const [search, setSearch] = useState('')
  const [gridReadyEvent, setGridApi] = useState<Maybe<GridReadyEvent>>(null)
  const [dataState, setDataState] = useState<Maybe<DataLoadBasicFragment>[]>([])
  const [templates, setTemplates] = useState<Maybe<DataLoadTemplateFragment>[]>([])

  // const [documentUploadSignedUrlQuery, {loading: urlLoading, data: urlData, error: urlError}] = useGetFileUploadSignedUrlLazyQuery
  const addFileInputRef = useRef<HTMLInputElement>(null)
  const [addFileModalOpen, setAddFileModalOpen] = useState(false)
  const [newDataLoadState, setNewDataLoadState] = useState<Maybe<any>>(defaultDataLoadInput)

  const { loading: userLoading, error: userError, data: userData } = useMeDepartmentQuery({ fetchPolicy: 'cache-first' })
  const [monitorIds, setMonitorIds] = useState<number[]>([])
  const user = userData?.me || undefined
  const [addDocument] = useCreateDataLoadMutation()

  const [refetchNew] = useDataLoaderDetailsLazyQuery({fetchPolicy: 'no-cache'})
  const [needPolling, setPollingState] = useState(false)
  const [forceUpdateFlag, setForceUpdate] = useState(false)
  const forceUpdateNow = useForceUpdate()

  // const [dataLoadId, setDataLoadId] = useState<any>(null)
  const { addAlert } = useContext(TemporaryAlertContext)

  const { loading, data, error, refetch } = useDataLoadersBasicQuery({
    fetchPolicy: 'no-cache',
  })

  const {loading: templatesLoading, data: templatesData, error: templatesError} = useGetAllDataLoadTemplatesQuery({fetchPolicy: 'cache-first'})

  useEffect(() => {
    if (data) {
      let newData = data?.dataLoads || []
      setDataState(newData)
    }
  }, [data])

  useEffect(() => {
    if(templatesData) {
      let templates = templatesData?.dataLoadTemplates || []
      setTemplates(templatesData?.dataLoadTemplates || [])
    }
  }, [templatesData])

  useEffect(() => {
    let id = monitorIds.length > 0 ? monitorIds[0]: null
    if(!id) return
    if(id && needPolling) {
      console.log("polling Starts", {monitorIds})
      const refetchQueryInterval = setInterval(() => {
        refetchNew({variables: {id}}).then((result) => {
          console.log("refetch Starts")
          const data = result.data?.dataLoad as DataLoadDetailsFragment
          const status = data?.status?.code
          const message = data?.messages
          let isInProcess = status === "_1" && !message
          let isSuccess = status === "_1" && message
          let isFailed = status === "_2"
          if(!isInProcess) {
            if(isSuccess ) {
              addAlert({title: "Import Complete | No issues found. Review and publish the data.", message: "", color: "userSuccess", timeout: 3000})
            }else if(isFailed){
              addAlert({title: "Import Complete | Data imported. Review messages and resolve any errors before publishing.", message: "", color: "importMessage", timeout: 3000})
            }
            if(!addFileModalOpen) {
              refetch().then((result) => {
                console.log("status updated")
                let data = result?.data?.dataLoads?.filter((data) => data?.person?.id === user?.person?.id)
                let newMonitorIds = monitorIds.filter((monitorId) => monitorId !== id && !data?.find((data) => data?.id === monitorId)?.messages)
                if(newMonitorIds.length) {
                  console.log({newMonitorIds})
                  setMonitorIds(newMonitorIds)
                }else {
                  setMonitorIds(newMonitorIds)
                  setPollingState(false)
                  if(!forceUpdateFlag){
                    setForceUpdate(true)
                  }
                }
              })
            }else {
              setForceUpdate(true)
            }
          }else {
            if(monitorIds.length > 1) {
              let newMonitorIds = monitorIds.filter((monitorId) => monitorId !== id)
              setMonitorIds([...newMonitorIds, id as number])
            }
            console.log("refetch Ends, still in Process")
          }
        }).catch((error) => {})
      }, 10000)
      return () => clearInterval(refetchQueryInterval)
    }
  }, [monitorIds.length])

  useEffect(() => {
    if(forceUpdateFlag) {
      refetch().then((result) => {
        console.log("refetch Ends, status updated")
        setPollingState(false)
        setForceUpdate(false)
        forceUpdateNow()
      })
    }
  }, [forceUpdateFlag])

  const colDef = useMemo(() => ImportedFilesColumnDef(), [data])
  const onReady = (params: GridReadyEvent) => {
    let { api } = params
    setGridApi(params)
    if (api && user?.person?.firstName && user?.person?.lastName) {
      api!.setFilterModel({
        person: {
          filterType: 'set',
          values: [`${user?.person?.firstName} ${user?.person?.lastName}`],
        },
      })
    }
  }

  const openFileModal = () => {
    setAddFileModalOpen(true)
    // addFileInputRef?.current?.click()
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  if (loading || templatesLoading) return <LoadingComponent name={name} />
  if (error) return <ErrorComponent name={name} error={error?.message} />

  let importPermission = auth.checkPermissions(["use:data_import"])

  const showImportButton = importPermission
  let importButton = importPermission && (
    <div
      className="d-flex align-items-center justify-content-between pl-1"
      id="importedFilesContainer"
    >
      <Button
        className="px-3 text-callan-blue pb-2"
        color="secondary btn-thin"
        id="importedFilesTooltip"
        onClick={openFileModal}
      >
        <span>Import</span>
        <FontAwesomeIcon icon="upload" className="ml-2 text-callan-blue" />
      </Button>
    </div>
  )
  return (
    <>
      <div className="pane pane-toolbar sticky-top above-picker w-100">
        <Form className="mr-2 pr-2" onKeyDown={handleEnterKeyDown}>
          <FormGroup row className="relative m-0">
            <Input
              type="text"
              placeholder="Find imported file"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="mid-search"
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={['fas', 'search']}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        {showImportButton && importButton}
      </div>
      <AddFileModal
        inputRef={addFileInputRef}
        user={user}
        associationType="Import"
        modalOpen={addFileModalOpen}
        setModalOpen={setAddFileModalOpen}
        auth={auth}
        type="import"
        dataLoadState={newDataLoadState}
        setDataLoadState={setNewDataLoadState}
        addDocument={(uploadFileInfo: FileBasicInfoFragment) => {
          let input: CreateDataLoadInput = getImportInput({
            ...newDataLoadState,
            uploadFileInfo,
          })
          addDocument({ variables: { input } })
            .then((result) => {
              const data = result.data?.createDataLoad as DataLoadBasicFragment
              const id = data?.id
              const status = data?.status?.code
              const message = data?.messages
              let isInProcess = message === null
              let isSuccess = status === "_1" && message === 'OK'
              let isImportFailed = status === "_2" && message
              if(isInProcess) {
                addAlert({title: "Import In Process | Data is being imported. Review messages and resolve any errors before publishing.", message: ``, color: "importPending", timeout: 3000})
                setPollingState(true)
                setMonitorIds([...monitorIds ,id])
              }
              if(isSuccess ) {
                addAlert({title: "Import Complete | No issues found. Review and publish the data.", message: ``, color: "userSuccess", timeout: 3000})
              }else if(isImportFailed){
                addAlert({title: "Import Complete | Data imported. Review messages and resolve any errors before publishing.", message: "", color: "importMessage", timeout: 3000})
              }
              setNewDataLoadState(defaultDataLoadInput)
              refetch()
            })
            .catch((error) => {
              console.log('Error Import File', input, error.message)
              addAlert({title: "Import Failed | Check that the file is formatted correctly and try again.", message: "", color: "error", timeout: 3000})
            })
        }}
        setSelectedDocument={(id) => {}}
        allowedFileTypes={AllowedFileTypes}
        templatesOptions={getTemplatesOptions(templates, newDataLoadState.type?.code as LoadTypeCode)}
      />
      <SortableTable
        loading={loading}
        filterText={search}
        columnDefs={colDef}
        tableData={dataState}
        onReady={onReady}
        editMode={false}
        rowId={'id'}
        pageSize={100}
      />
    </>
  )
}

export default ImportedFilesComponent

import React, { ComponentType, useEffect, useState } from 'react';
import { Maybe, TargetConstantRebalance, TargetConstantRebalanceConstant } from '../../../../__generated__/graphql';

import { FormInput } from '../../../ui/Forms/FormInput';
import { GetLookupDataToOptions } from '../../../ui/LookupOptions'
import classnames from 'classnames'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, FormInputField, FormInputSubClasses } from '../../../../helpers/constant'
import { cloneDeep, compact, get, merge, orderBy, set } from 'lodash'
import { TargetConstantRebalanceExtendedType } from '../helper'
import moment from 'moment'

type TargetConstantComponentProps = {
  data: TargetConstantRebalanceConstant;
  row: number | string
  handleChange: (state: any, property: string) => void;
  editMode: boolean;
  horizontalDisplay?: boolean
};

const ConstantBasisOptions = () =>
  GetLookupDataToOptions({
    data: [
      {
        code: 1,
        value: "Monthly",
      },
      {
        code: 3,
        value: "Quarterly",
      },
      {
        code: 12,
        value: "Yearly",
      },
    ],
    placeholder: "Please Select",
    multiple: true,
  })

export const TargetConstantComponent: React.FC<TargetConstantComponentProps> = ({ data, editMode, handleChange, row, horizontalDisplay }) => {
  const [editedData, setEditedData] = useState(data)
  const updateValue = (value: any, type: string, property: string) => {
    let result: number = value
    if(type === "number") {
      result = parseInt(value)
    }
    let newData = {...editedData, [property]: result}
    handleChange(newData, property)
  }

  useEffect(() => {
    setEditedData(data)
  }, [data])

  if (!!horizontalDisplay) {
    return (
    <div key={row} className={"form-group row"}>
      <label className="col-form-label col-sm-4">
        Constant
      </label>
      <FormInput
        key={`${row}-amount`}
        property={'amount'}
        type='float'
        subtype='percent'
        idx={`${row}-amount`}
        editMode={editMode}
        propertyVal={editedData?.amount ? editedData?.amount : 0}
        placeholder=''
        subClasses={{wrapperClasses: "col-sm-4 pl-3 no-row", inputWrapperClasses: "pl-0"}}
        updateValue={(value) => updateValue(value, 'float', 'amount')}
        // do not show zero in view mode
        showZero={true}
      />
      <FormInput
        key={`${row}-basis`}
        property={'basis'}
        type='select'
        idx='spread-form-input'
        editMode={editMode}
        propertyVal={editedData?.basis}
        placeholder='Constant Basis'
        options={ConstantBasisOptions()}
        subClasses={{wrapperClasses: "col-sm-4 px-0"}}
        updateValue={(value) => updateValue(value, "number", 'basis')}
        showZero={true}
      />
    </div>)
  }
  return (
    <td key={row} className={classnames('')}>
      <div className={classnames('d-flex')} style={{width: 108}}>
        <FormInput
          key={`${row}-amount`}
          property={'amount'}
          type='float'
          subtype='percent'
          idx={`${row}-amount`}
          editMode={editMode}
          propertyVal={editedData?.amount ? editedData?.amount : 0}
          placeholder=''
          subClasses={{wrapperClasses: "col-sm-8 pl-0 mr-0", inputWrapperClasses: "pr-1", inputClasses: "pr-0 pl-0"}}
          updateValue={(value) => updateValue(value, 'float', 'amount')}
          // do not show zero in view mode
          showZero={true}
        />
        <FormInput
          key={`${row}-basis`}
          property={'basis'}
          type='select'
          idx='spread-form-input'
          editMode={editMode}
          propertyVal={editedData?.basis}
          placeholder='Constant Basis'
          options={ConstantBasisOptions()}
          subClasses={{wrapperClasses: "px-0", inputWrapperClasses: "pl-0", inputClasses: "pl-1"}}
          updateValue={(value) => updateValue(value, "number", 'basis')}
          showZero={true}
        />
      </div>
    </td>
  )
}

type tableKeys = "new" | "current" | "previous"

interface TargetOptionsTableProps {
  data: Maybe<TargetConstantRebalance>
  key: tableKeys
  targetId?: number
  editMode: boolean;
  showStatus: boolean,
  handleTableStateChange: (state: any, tableName: tableKeys) => void

  // setEditedCompositeMembers: (props: any) => void,
  endCurrent: () => void,
  revertEndCurrent: () => void,
  setData: (data: any) => void, // to update parent state for all data
  showSuffixEndDate?: boolean
  currentTableBatchEndDate?: string
  hasFullHistoryButton?: boolean
}

interface TargetOptionsCurrentTableProps extends TargetOptionsTableProps {
  key: 'current'
  showSuffixEndDate: boolean
  setCurrentTableBatchEndDate?: React.Dispatch<React.SetStateAction<string | null>>
  currentTableBatchEndDate: string
  greyOut: boolean
}

interface TargetOptionsNewTableProps extends TargetOptionsTableProps {
  key: 'new'
  currentTableBatchEndDate: string
}

interface TargetOptionsPreviousTableProps extends TargetOptionsTableProps {
  key: 'previous'
  toggleFullHistoryModal?: () => void
}

// interface columnDefItem {
//   field: string
//   type: string
//   title: string
//   className?: string
//   readonly?: boolean
//   subtype?: string
//   optionSource?: string
//   required?: boolean
//   subClasses?: { [name in FormInputSubClasses]?: string }
//   widthClass?: string
//   textLinkParams?: {
//     property?: string, // to get property value from state and then passed to input as text params
//     url: string,
//   },
//   // displayData?: DisplayDataType
// }

interface SingleTargetOptionsInput extends FormInputField {
  component?: ComponentType<any> // for table
  optionSourceFunction?: (variables?: any) => JSX.Element | null // for select boxes like Asset Classes
}

const BatchEndDateInput: FormInputField[] = [
  {
    property: "startDate",
    label: "Start",
    type: "date",
    readonly: true,
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
  {
    property: "editedEndDate",
    label: "End",
    type: "date",
    subtype: "month",
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
]

const TargetOptionsInput: SingleTargetOptionsInput[] = [
  {
    property: "constant",
    label: "Constant",
    type: "table",
    component: TargetConstantComponent,
    subClasses: {
    },
  },
  {
    property: "frequency.code",
    label: "Rebalancing",
    type: "select",
    subtype: "single",
    optionSource: "TargetConstantRebalanceFrequencyCode",
    subClasses: {
      inputWrapperClasses: "col-sm-6 col-lg-4",
    },
  },
]

const isEmptyTable = (props: Maybe<TargetConstantRebalance>) => {
  return !(props?.frequency?.code || props?.constant || props?.startDate)
}

const TargetOptionsRow = (props: any) => {
  let { data, index, columnDef: headerDef, editMode, handleValueChange, tableKey } = props
  let { property, type, label, subtype, placeholder, subClasses } = headerDef
  let value = get(data, property)
  let onChangeCallback = (value: any) => handleValueChange(value, property)
  let { optionSource, readonly } = headerDef
  let Component = headerDef?.component
  if(Component) {
    return (
      <Component
        data={value}
        editMode={editMode}
        row={`custom-target-options-${tableKey}`}
        handleChange={onChangeCallback}
        horizontalDisplay={true}
      />
    )
  }
  return (
    <div key={index}>
      <FormInput
        key={index}
        property={property}
        displayName={label}
        type={type}
        subtype={subtype}
        idx={`custom-benchmarks-${index}-${property}`}
        editMode={editMode}
        readonly={!!readonly}
        propertyVal={value}
        placeholder={placeholder}
        subClasses={subClasses}
        optionSource={optionSource}
        updateValue={onChangeCallback}
      />
    </div>
  )
}

export const TargetOptionsTableComponent = (props: TargetOptionsTableProps | TargetOptionsCurrentTableProps | TargetOptionsNewTableProps | TargetOptionsPreviousTableProps) => {
  let {editMode, showStatus, key, data, setData, endCurrent, revertEndCurrent, showSuffixEndDate, currentTableBatchEndDate, handleTableStateChange: handleChange, targetId, hasFullHistoryButton} = props

  let {toggleFullHistoryModal} = props as TargetOptionsPreviousTableProps
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(!!showSuffixEndDate && key !== "previous")
  const [showFullHistoryButton, setShowFullHistoryButton] = useState<boolean>(key ==="previous" && !!hasFullHistoryButton)

  useEffect(() => {
    setShowDeleteButton(!!props?.showSuffixEndDate)
  }, [props?.showSuffixEndDate])

  // const generateId = () => {
  //   // console.log("new", {key, data})
  //   if(data?.length > 0) {
  //     return (data[data.length - 1]?.orderedPid || 999999999) + 1
  //   }
  // }

  // TODO: isEmptyTable doesn't work
  useEffect(() => {
    // console.log(240, {key, data})
    if(!editMode) {
      // console.log(241, {key, data})
    }
  }, [data])

  const batchEndDateComponent = () => {
    let setCurrentTableBatchEndDate = (props as any)?.setCurrentTableBatchEndDate

    let labelClass = ""
    if(key === "current") {
      labelClass = "background-private-background text-accent-red"
    }

    return (
      <div key={"batch-end-date"} className={classnames("d-flex justify-content mb-3 pt-0 pl-0 target-constant-row", labelClass)}>
        {BatchEndDateInput.map((headerDef, idx) => {
          let property = headerDef.property
          let propertyVal:string|undefined = ""
          if(key === "current" && property === "editedEndDate") {
            propertyVal = currentTableBatchEndDate
          }else if (property === "startDate") {
            propertyVal = moment(get(data, property) || "1900-01-01").add(1, 'day').format(DATE_API_FORMAT)
          }

          return(
            <div key={idx} className={classnames('d-flex ml-3')}>
              <div className="client-portfolio-date-label">
                {headerDef.label}
              </div>
              <FormInput
                key={headerDef.property}
                property={property}
                displayName={""}
                type={headerDef.type}
                subtype={headerDef.subtype}
                idx={`${key}-target-constant-${targetId}-item-batch-end-date`}
                editMode={editMode}
                propertyVal={propertyVal}
                readonly={!!headerDef.readonly || key ==="previous"}
                updateValue={(value: any) => {
                  // updateValue(value, headerDef.type, headerDef.field)
                  // handleBatchCurrentEndDateChange(value, data, key)
                  if(setCurrentTableBatchEndDate) setCurrentTableBatchEndDate(value)
                }}
                inputProps={{
                  min: data?.startDate || "1900-01-01",
                }}
                // required={headerDef.required}
                // optionSource={headerDef.optionSource}
                subClasses={headerDef.subClasses || {}}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const handleValueChange = (value: any, property: any) => {
    let newData = cloneDeep(data) || {}
    set(newData, property, value)
    setData(newData)
    handleChange(newData, key)
  }

  if(!showStatus) {
    return <div key={`no-${key}`}></div>
  }else {
    let greyClass = (props as any)?.greyOut ? "bg-gray-full": ""
    return (
      <div key={`target-options-${key}`}>
        <div className={"d-flex justify-content-start w-100 ml-negative-3"}>
          <h5 className="headline gray-underline underline mt-1 text-nowrap">
            {`Options `}
          </h5>
          <h5 className="headline gray-underline underline text-uppercase text-gray-70 w-100 pl-1 mt-1">{(!!greyClass || key !== "current") && ` (${key})`}</h5>
          <h5 className="headline gray-underline underline mt-1 text-nowrap">
          {editMode && key === "current" && (
            !showDeleteButton && <Button key={"end-current"} color="link" className="text-callan-blue btn-thin py-0" onClick={() => endCurrent()}>
              {"End Current & Start New"}
              <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
            </Button>)}
            {editMode && (key === "new") &&
            (showDeleteButton && <Button key={"revert"} color="link" className="text-callan-blue btn-thin py-0" onClick={() => revertEndCurrent()}>
              {"Delete"}
              <FontAwesomeIcon icon="trash" className="ml-2 text-callan-blue" />
            </Button>)
          }
            {key === "current" && hasFullHistoryButton &&
              (<Button key={"full-history"} color="link" className="text-callan-blue btn-thin py-0" onClick={toggleFullHistoryModal}>
                {"View Full History"}
                <FontAwesomeIcon icon="history" className="ml-2 text-callan-blue" />
              </Button>)
            }
            {key === "current" &&
              <div className='font-weight-normal d-inline-block vertical-align-middle'>
                Beginning {moment(data?.startDate).add(1, "day").format(DATE_DISPLAY_FORMAT)}
              </div>
            }
          </h5>
        </div>
        {!isEmptyTable(data) && (<div className={classnames(greyClass, "target-constant-row", {'pb-3': key === "current"})}>
          {TargetOptionsInput.map((headerDef, index) => {
            return (
              <React.Fragment key={index}>
                <TargetOptionsRow
                  data={data}
                  index={index}
                  columnDef={headerDef}
                  editMode={editMode}
                  handleValueChange={handleValueChange}
                  tableKey={key}
                />
              </React.Fragment>
            )
          })}
        </div>)}
        {key === "current" && editMode && !isEmptyTable(data) && (props as any)?.showSuffixEndDate && (
          batchEndDateComponent()
        )}
      </div>
    )
  }
}

interface TargetOptionsHistoryProps {
  isOpen: boolean
  title: string
  toggle: () => void
  data: TargetOptionsHistoryData
}

interface TargetOptionsHistoryData {
  headers: string[]
  rows: {date: string, data: any[]}[]
  // rows: FeeHistoryRow[]
}

export const TargetOptionsHistoryModal: React.FC<TargetOptionsHistoryProps> = ({
  isOpen,
  title,
  toggle,
  data,
}) => {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} zIndex={1500} key={'target-options-history'}>
      <ModalHeader className="fee-modal-header">History | {title}</ModalHeader>
      <ModalBody>
        {data.rows?.map((row, idx) => {
          const fromDate = moment(row.date).add(1, "day").format(DATE_DISPLAY_FORMAT)
          const toDate = idx === 0 ? "Current" : moment(data.rows[idx - 1].date).format(DATE_DISPLAY_FORMAT)
          return (
            <div className="target-options-modal-history-item" key={idx}>
              <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
                <h5 className="headline mt-3 text-nowrap d-inline-block">
                  {fromDate} - {toDate}
                </h5>
              </div>
                {row.data.map((tr, rowIdx) => {
                  let key = "history"
                  return (
                    <div key={rowIdx} className={"pl-4"}>
                      {TargetOptionsInput.map((headerDef, index) => {
                      return (
                        <TargetOptionsRow
                          data={tr}
                          index={index}
                          columnDef={headerDef}
                          editMode={false}
                          handleValueChange={() => {}}
                          key={index}
                          tableKey={key}
                        />
                      )
                    })}</div>)
                })
              }
            </div>
          )
        })}
      </ModalBody>
    </Modal>
  )
}

const getInitialData = (data: Maybe<TargetConstantRebalance>[]) => {
  if(!data) {
    return []
  }
  let sortedData = orderBy(data, ["startDate"], ["desc"]) as TargetConstantRebalanceExtendedType[]
  let traceableData = sortedData.map((el, idx) => {
    if(el?.source) {
      return el
    }else {
      if(idx === 0) {
        return {...el, source: "current"}
      }else {
        return {...el, source: "previous"}
      }
    }
  })
  return traceableData as Maybe<TargetConstantRebalanceExtendedType>[]
}

const DEFAULT_NEW_DATA = {
  __typename: 'TargetConstantRebalance',
  startDate: "",
  frequency: {code: "MTH"},
  constant: {amount: 0, basis: 1},
  source: "new",
}

const getNewTableData = (data: Maybe<TargetConstantRebalanceExtendedType>[]) => {
  let newData = data.filter(el => el?.source === "new")
  if(!newData || newData?.length < 1) {
    return DEFAULT_NEW_DATA as TargetConstantRebalanceExtendedType
  }else {
    return newData[0]
  }
}

const getCurrentTableData = (data: Maybe<TargetConstantRebalanceExtendedType>[]) => {
  return data.find(el => el?.source === "current")
}

const getPreviousTableData = (data: Maybe<TargetConstantRebalanceExtendedType>[]) => {
  if(data?.length > 1) {
    return data.filter(el => el?.source === "previous")
  }else {
    return []
  }
}

const getFirstPreviousTableData = (data: any) =>{
  if(data?.length > 0) {
    return data[0]
  }else {
    return null
  }
}

const getHistoryTableData = (initialData: Maybe<TargetConstantRebalance>[]) => {
  let data = initialData || []
  if(!data) {
    return ({headers:[], rows: [{date: "", data: []}]})
  }
  let headers = TargetOptionsInput.slice(0, 3).map(row => row.property)
  let result = data?.reduce((acc, row) => {
    let startDate = row?.startDate || ""
    if(!startDate) {
      return acc
    }
    if(!acc[startDate]) {
      acc[startDate] = {
        date: startDate,
        data: []
      }
    }
    acc[startDate].data.push(row)
    return acc
  }, {} as any)
  let rows =  Object.values(result) as {date: string, data: any[]}[]
  return ({headers, rows}) as TargetOptionsHistoryData
}


interface CustomBenchmarkTargetOptionsProps {
  data: Maybe<TargetConstantRebalance>[]
  handleChange: (state: any, property?: string) => void;
  editMode: boolean;
  targetId?: number
}

const DEFAULT_NEW_START_DATE = "1900-01-01"

export const CustomBenchmarkTargetOptionsComponent = (props: CustomBenchmarkTargetOptionsProps) => {
  let {data, handleChange, editMode, targetId} = props
  let componentName = "custom-target-options"

  const [initialData, resetData] = useState(data as TargetConstantRebalanceExtendedType[])
  // edited data
  const [currentData, setData] = useState(() => getInitialData(data))
  const [initialCurrentData, setInitialCurrentData] = useState(() => getInitialData(initialData))
  const [newTableData, setNewTableData] =  useState<any>(() => getNewTableData(currentData))
  const [currentTableData, setCurrentTableData] = useState(() => getCurrentTableData(currentData))
  const [previousTableData, setPreviousTableData] =  useState(() => getPreviousTableData(currentData))
  const [firstPreviousTable, setFirstPreviousTableData] = useState(() => getFirstPreviousTableData(previousTableData))

  const [greyOutCurrentTable, setGreyOutCurrentTable] = useState<boolean>(false)
  const [showCurrentTable, setShowCurrentTable] = useState<boolean>(!!currentTableData)

  const [currentTableBatchEndDate, setCurrentTableBatchEndDate] = useState<string | null>(null)
  const [showCurrentTableEndDate, setShowCurrentTableEndDate] = useState<boolean>(false)

  const [currentTableEditable, setCurrentTableEditable] = useState<boolean>(editMode)

  const [showNewTable, setShowNewTable] = useState<boolean>(false)

  const [showPreviousTable, setShowPreviousTable] = useState<boolean>(false)

  const [historyData, setHistoryData] = useState<TargetOptionsHistoryData>({headers: [], rows: [{date: "", data: []}]})
  const [showFullHistoryModal, setShowFullHistoryModal] = useState<boolean>(false)

  const toggleFullHistoryModal = () => setShowFullHistoryModal(!showFullHistoryModal)

  const endCurrent = () => {
    console.log('end current')
    setCurrentTableBatchEndDate(null)
    setCurrentTableEditable(false)
    setShowCurrentTableEndDate(true)
    setShowNewTable(true)
    setGreyOutCurrentTable(true)
  }

  const revertEndCurrent = () => {
    handleChange(initialData)
    setShowCurrentTableEndDate(false)
    setCurrentTableEditable(true)
    setCurrentTableBatchEndDate(null)
    setShowNewTable(false)
    setGreyOutCurrentTable(false)
  }

  const showHistoryButtonComponent = () => {
    return (<div className="expand-link" key={'show-history-button'}>
      <Button color="link" onClick={(event) => {event.stopPropagation(); setShowPreviousTable(!showPreviousTable)}} className={"wider-link-on-hover"}>
        <FontAwesomeIcon
          icon={showPreviousTable  ? "chevron-up" : "chevron-down"}
          size="sm"
        />
        <span className="pl-2 expand-text">{showPreviousTable  ? "Hide History" : "Show History"}</span>
      </Button>
    </div>)
  }

  const handleTableStateChange = (state: any, tableName: string) => {
    //TODO note when state is array/object
    let newState: any[] = []
    switch (tableName) {
      case "current":
        let [, ...restData] = data
        newState = [state, ...restData]
        break
      case "new":
        newState = [currentTableData, state, ...previousTableData]
        break
      case "previous":
        newState = [currentTableData, ...newTableData, ...state,]
        break
    }
    let compactNewState = compact(newState)
    handleChange(compactNewState)
  }

  useEffect(() => {
    let newState = getInitialData(data)
    setData(newState)
    let newPreviousTableData = getPreviousTableData(newState)
    let newCurrentData = getCurrentTableData(newState)
    setPreviousTableData(newPreviousTableData)
    setNewTableData(getNewTableData(newState))
    setCurrentTableData(newCurrentData)
    setFirstPreviousTableData(getFirstPreviousTableData(newPreviousTableData))
    if(!editMode) {
      setInitialCurrentData(newState)
      setHistoryData(getHistoryTableData(initialCurrentData))
    }
  }, [data])

  useEffect(() => {
    if(!editMode) {
      setShowNewTable(false)
      // TODO
      setShowPreviousTable(false)
      setShowCurrentTableEndDate(false)
      setCurrentTableBatchEndDate(null)
      setGreyOutCurrentTable(false)
    }
  }, [editMode])

  useEffect(() => {
    let nextCurrentTableData: Maybe<TargetConstantRebalanceExtendedType> | undefined  = cloneDeep(currentTableData)
    if(nextCurrentTableData && editMode && !!currentTableBatchEndDate){
      set(nextCurrentTableData, "editedEndDate", currentTableBatchEndDate)
      if(nextCurrentTableData?.source) {
        set(nextCurrentTableData, "source", "current")
      }
      setCurrentTableData(nextCurrentTableData)
      let nextNewTableData: TargetConstantRebalanceExtendedType = cloneDeep(newTableData)
      if (nextNewTableData) {
        merge(nextNewTableData, {startDate: currentTableBatchEndDate})
        if(newTableData?.source) {
          set(nextNewTableData, "source", "new")
        }
        setNewTableData(nextNewTableData)
        let newState = [nextCurrentTableData, nextNewTableData, ...previousTableData]
        handleChange(newState)
      }
    }
  }, [currentTableBatchEndDate])

  let componentProps = {editMode, endCurrent, revertEndCurrent, handleTableStateChange, targetId}

  let newTableProps = {...componentProps, showStatus: showNewTable, key: "new" as tableKeys, data: newTableData, setData: setNewTableData, currentTableBatchEndDate, showSuffixEndDate: true,}
  let currentTableProps = {...componentProps, showStatus: showCurrentTable, key: "current" as tableKeys, data: currentTableData, setData: setCurrentTableData, showSuffixEndDate: showCurrentTableEndDate, setCurrentTableBatchEndDate, currentTableBatchEndDate, editMode: editMode, greyOut: greyOutCurrentTable, toggleFullHistoryModal, hasFullHistoryButton: previousTableData?.length > 0}

  let previousTableProps = {...componentProps, showStatus: showPreviousTable, key: "previous" as tableKeys, data: firstPreviousTable, setData: setPreviousTableData, showSuffixEndDate: true, editMode: editMode, toggleFullHistoryModal, hasFullHistoryButton: true}
  return (
    <Row className={classnames("pl-0 mt-4")} key={`${componentName}`}>
      <Col key={"target-options-tables"}>
        {/* "---new Table below ------------------------------------------" */}
        {/* "---current Table below --------------------------------------" */}
        {TargetOptionsTableComponent(newTableProps as TargetOptionsNewTableProps)}
        {TargetOptionsTableComponent(currentTableProps as TargetOptionsCurrentTableProps )}
        {/* {TargetOptionsTableComponent(previousTableProps as TargetOptionsPreviousTableProps )}
        {previousTableData?.length > 0 && showHistoryButtonComponent()} */}
      </Col>
      <TargetOptionsHistoryModal
        isOpen={showFullHistoryModal}
        title={'Benchmark Options'}
        toggle={toggleFullHistoryModal}
        data={historyData}
      />
    </Row>)
}

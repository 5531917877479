import React from "react"

import {
  Row,
  Col,
  ListGroupItem
} from "reactstrap"
import { SearchPersonResultFragment } from "../../../__generated__/graphql"

export const PersonResult = (props:SearchPersonResultFragment) => {
  const name = [props.lastName, props.firstName].join(', ')
  const {title, employer, city, state} = props
  const location = [city, state?.code].filter(l => l).join(', ')
  const status = props.active ? 'Active' : 'Inactive'

  return (
    <ListGroupItem tag="a" href={`/managers/${employer?.id}/people/${props.id}`}>
      <div className="category person">
        <div className="category-text">
          <h6>Person</h6>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md="3">
            <h3>{name}</h3>
            <h5 className="d-inline mr-1">Manager:</h5>
            <p className="d-inline">{employer?.name}</p>
          </Col>
          <Col md="9">
            <Row>
              <Col md="6">
                <h5>Title</h5>
                <p>{title}</p>
              </Col>
              <Col md="4">
                <h5>Location</h5>
                <p>{location}</p>
              </Col>
              <Col md="2">
                <h5>Status</h5>
                <p>{status}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroupItem>
  )
}
import classNames from "classnames"
import React, { Component, useEffect, useState } from "react";
import { Link as ScrollLink, Events,  animateScroll as scroll,  scrollSpy} from "react-scroll";
import { ReactScrollLinkProps } from "react-scroll/modules/components/Link"

interface NavLinkProps extends ReactScrollLinkProps {
  text: string
}

const NavLink: React.FC<NavLinkProps> = ({ to, text, onSetActive, onSetInactive, ...props })=> {
  // https://github.com/fisshy/react-scroll/issues/442#issuecomment-763002395
  // allow activate AnchorLink if manual scroll
  const [active, setActive] = useState(false)
  useEffect(() => {
    const ioOptions = {
      threshold: 0.5,
    }
    const ioCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      const entry = entries[0]
      setActive(entry.isIntersecting)
    }
    const observer = new IntersectionObserver(ioCallback, ioOptions)
    observer.observe(document.querySelector(`#${to}`)!)
  }, [to])

  return (
    <ScrollLink
    /**
     * Uncomment line below after testing threshold number if activate anchorlink by manual scroll feature is needed.
    */
      // className={classNames({active})}
      smooth
      isDynamic
      to={to}
      spy={true}
      duration={500}
      // onSetActive={()=>{if(onSetActive) {onSetActive(to)}}}
      // onSetInactive={()=>{if(onSetInactive) {onSetInactive()}}}
      {...props}
    >
      {text}
    </ScrollLink>
  )
}

/**
 * @property {title} for display
 * @property {anchorId} unique across all anchorId
 */
export type AnchorLinkItem = {
  title: string
  anchorId: string
  hidden?: boolean
}

interface AnchorLinkProps {
  list: AnchorLinkItem[]
  linkProps?: Partial<ReactScrollLinkProps> // allow config props
  handleSetActive: (id:string) => void
  handleSetInactive: (id:string) => void
}

interface AnchorLinkMenuState {
  activeLink: string
}

export default class AnchorLinkMenu extends Component<AnchorLinkProps, AnchorLinkMenuState> {
  constructor(props:AnchorLinkProps) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.state = {
      activeLink: ""
    }
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", function () {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      // console.log("end", arguments);
    });

    scrollSpy.update();
  }
  scrollToTop(options: any) {
    scroll.scrollToTop(options);
  }
  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  setActiveLink = (e: string) => this.setState({activeLink: e})

  handleOnSetActive = (e: string) => {
    this.setActiveLink(e);
  };

  handleOnSetInActive = () => {
    this.setActiveLink("");
  };

  renderContent() {
    let {linkProps, handleSetActive, handleSetInactive} = this.props
    
    return (
        <div className="container-fluid pb-1 navbar-nav">
          <h4 className="px-1 mb-0 pb-0">SECTIONS</h4>
          <ul className="pl-1 mb-0">
            {this.props.list.map((item, idx)=>{
              let onSetActive = handleSetActive
              let onSetInactive = () => handleSetInactive(item.anchorId)
              let navLinkProps = {...linkProps, onSetActive, onSetInactive}
              return (!item.hidden && (<li className="anchor-menu-item" key={idx}><NavLink to={item.anchorId}text={item.title} {...navLinkProps}></NavLink></li>)
            )})}
            {/** <li className="anchor-menu-item"><a onClick={(event) => this.scrollToTop(event.target)} >To the top!</a></li>*/}
          </ul>
        </div>
    );
  }

  render() {
    return (
      <div className="sticky-top anchor-menu-container pt-3">
        <div id="scroll-container" className="">{this.renderContent()}</div>
      </div>
    );
  }
}

import _, { orderBy } from 'lodash'
import React from 'react'
import { keys } from '../../helpers/object'
import {
  useGetLookupQuery,
  useGetProductManagementLookupQuery
} from '../../__generated__/graphql'

export type LookupName = {
  name: string
  multiple?: boolean
  filterMap?: { [name: string]: boolean | number }
  optionsSortFunction?: (a: any, b: any) => number
}
/**
 * Functional Component to get Options by wrapped introspection query(useGetLookupQuery),
 * All Lookups would be formatted from {name, description} to {code, value}
 * @param {name: string} LookupName object
 * @param {multiple: boolean} True if it's a multiple select.
 * @return JSXElement <>option list</>
 * **/
export const GetLookupToOptions: React.FC<LookupName> = ({
  name,
  filterMap,
  multiple = false,
  optionsSortFunction,
}) => {
  const { loading, data, error } = useGetLookupQuery({ variables: { name } })

  if (loading) return <>loading</>

  if (error) return <>`Error! ${error.message}`</>

  if (!loading && data && data.__type && data.__type.enumValues) {
    // do not show blank option if it's multiple select.
    let defaultOption: (JSX.Element | null)[] = multiple
      ? []
      : [<option key={-1} value="">Please select</option>]

    let enums
    if (name === 'ApproximationTypeCode' || name === 'OwnershipAmountCode' ) {
      enums = data.__type.enumValues
    } else {
      enums = orderBy(data.__type.enumValues, [optionsSortFunction || (o => o.value) ])
    }

    let filteredEnums = enums
    if (!_.isUndefined(filterMap) && !_.isEmpty(filterMap)) {
      filteredEnums = enums.filter(el => filterMap[el.code])
      filteredEnums = _.sortBy(filteredEnums, [
        optionsSortFunction || (o => filterMap[o.code])
      ])
    }
    let options = filteredEnums.map(opt => {
      if (opt) {
        return (
          <option key={opt.code} value={opt.code}>
            {opt.value || opt.code}
          </option>
        )
      } else {
        return <></>
      }
    })
    return <>{defaultOption.concat(options)}</>
  }
  return <>Error</>
}

export type GetLookupDataToOptionsProps = {
  data: {
    code: number | string
    value?: string | null
    [property: string]: any
  }[]
  placeholder?: string | null
  multiple?: boolean
  hideBlank?: boolean
  sort?: boolean
  optionsSortFunction?: (a: any, b: any) => number
}

export const GetLookupDataToOptions: React.FC<GetLookupDataToOptionsProps> = ({
  data,
  placeholder = "",
  multiple = false,
  hideBlank = false,
  sort = true,
  optionsSortFunction
}) => {
  // do not show blank option if it's multiple select or if hideBlank is true.
  let defaultOption: (JSX.Element | null)[] = multiple || hideBlank
    ? []
    : [
        <option key={-1} value="">
          {placeholder}
        </option>
    ]

  let enums
  if (sort) {
    enums = _.sortBy(data, [optionsSortFunction || (o => o.value)])
  } else {
    enums = data
  }

  let options = enums.map((opt, idx) => (
    <option key={`${idx}-${opt.code}`} value={opt.code}>
      {opt.value || opt.code}
    </option>
  ))
  return (<>{defaultOption.concat(options)}</>) as JSX.Element
}

export const GetProductManagementLookupsToOptions = () => {
  const { loading, data, error } = useGetProductManagementLookupQuery()
  let defaultOption: (JSX.Element | null)[] = [
    <option key={-1} value="">
      {`-`}
    </option>
  ]
  if (loading) {
    return { loading: defaultOption }
  }
  if (error) {
    return { error: [<>{error.message}</>] }
  }
  if (!loading && data) {
    const obj = {} as { [name: string]: any[] }
    type ProductManagementLookupsType =
      | "managementType"
      | "portfolioConstructionMethod"
      | "investmentManagementStrategy"
      | "currencyHedging"
    keys(data).map(key => {
      const t = data[key as ProductManagementLookupsType]?.enumValues?.map(
        opt => {
          if (opt) {
            return (
              <option key={opt.code} value={opt.code}>
                {opt.value}
              </option>
            )
          } else {
            return null
          }
        }
      )

      if (t) {
        return obj[key] = defaultOption.concat(t)
      } else {
        return null
      }
    })
    return obj
  }
}


export const GetLookupToOptionObject = ({
  name,
  filterMap,
  optionsSortFunction
}:LookupName) => {
  const { loading, data, error } = useGetLookupQuery({ variables: { name } })

  if (loading) return []

  if (error) console.error(`Error ${error.message}`)

  if (!loading && data && data.__type && data.__type.enumValues) {

    let enums
    if (name === 'ApproximationTypeCode' || name === 'OwnershipAmountCode') {
      enums = data.__type.enumValues
    } else {
      enums = orderBy(data.__type.enumValues, [optionsSortFunction || (o => o.value)])
    }

    let filteredEnums = enums
    if (!_.isUndefined(filterMap) && !_.isEmpty(filterMap)) {
      filteredEnums = enums.filter(el => filterMap[el.code])
      filteredEnums = _.sortBy(filteredEnums, [
        optionsSortFunction || (o => filterMap[o.code])])
    }

    return filteredEnums.map(opt => {
      return { value: opt.code, label: opt.value }
    })
  }
}
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect } from 'react'
import { Redirect, StaticContext } from 'react-router'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { CrumbRoute } from '././routes/crumb-route'
import App from './App'
import Auth from './Auth/Auth'
import Callback from './Callback/Callback'
import GlidePathDetails from './Components/GlidePath/GlidePathDetail'
import QueryManagerDetails from './Components/Org/ManagerDetail'
import ClientDetails from './Components/Client/ClientDetail'
import PlanList from './Components/Plan/PlanList'
import PlanDetails from './Components/Plan/PlanDetail'
import ClientPortfolioDetails from './Components/ClientPortfolio/ClientPortfolioDetail'
import AllManagersComponent from './Components/Org/Managers'
import AllClientsComponent from './Components/Client/AllClients'
import QueryProductDetails from './Components/Product/ProductDetails'
import QueryDocumentDetails from './Components/Documents/DocumentDetail'
import Page404 from './Pages/Page404'
import Page500 from './Pages/Page500'
import StyleGuide from './Pages/StyleGuide'
import SearchResults from './Components/Search/SearchResults'
import RecordKeeperDetails from './Components/RecordKeeper/RecordKeeperDetail'
import CustodianDetails from './Components/Custodian/CustodianDetail'
import ListList from './Components/List/ListList'
import ListDetail from './Components/List/ListDetail'
import LoginRedirect from './LoginRedirect'
import ReportList from './Components/Report/ReportList'
import ReportDetail from './Components/Report/ReportDetail'
import TestGraphJS from './Pages/TestGraphJS'
import ResearchProductDetails from './Components/ResearchProducts/ResearchProductDetails'
import ResearchProducts from './Components/ResearchProducts/ResearchProducts'
import GroupDetails from './Components/Group/GroupDetails'
import IndexDetails from './Components/Index/IndexDetails'
import ControlCenterDashboard from './Components/ControlCenter/ControlCenterDashboard'
import ImportedFileDetailsComponent from './Components/ControlCenter/Admin/DataImporter/ImportedFileDetails'
import { useLogRouteMutation } from './__generated__/graphql'
import { usePathname } from './Context/PathnameContext'

library.add(fas)
library.add(far)
library.add(fal)
library.add(fad)

const auth = new Auth()
const handleAuthentication = (nextState: RouteComponentProps<any, StaticContext, any>) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication(nextState)
  }
}

const RouteSwitch = (
    <Switch>
      <Route exact path="/404" component={Page404} />
      <Route exact path="/500" component={Page500} />
      <CrumbRoute exact path="/styleguide" title="Style Guide" component={StyleGuide} auth={auth} />
      <CrumbRoute exact path="/test-graph" title="Test Graph" component={TestGraphJS} auth={auth} />
      <CrumbRoute exact path="/:category(search)" title="Search Results" component={SearchResults} auth={auth} />
      <Route
        path="/callback"
        render={(props) => {
          handleAuthentication(props)
          return <Callback {...props} />
        }}
      />
      {/* https://github.com/ReactTraining/react-router/issues/4841#issuecomment-523625186 */}
      {/* Removes trailing slashes */}
      <Route path="/:url*(/+)" exact strict render={({ location }) => <Redirect to={location.pathname.replace(/\/+$/, "")} />} />
      {/*https://github.com/ReactTraining/react-router/issues/4841#issuecomment-821980722 */}
      {/* Removes duplicate slashes in the middle of the URL */}
      {/* disable below to remove regex params unmatched bug. */}
      {/* <Redirect from="/:url*(/+)" to={window.location.pathname.replace(/\/+$/, window.location.search)} /> */}
      <CrumbRoute title="Login" exact={true} path="/login-redirect" auth={auth} component={LoginRedirect} />
      <CrumbRoute title="Managers" exact path="/managers" auth={auth} component={AllManagersComponent} />
      <CrumbRoute
        title="ID"
        path="/:category(managers)/:managerId(\d+)/:type(profile|overview|products|people|assets|interactions|write-ups|documents)/:subtype?"
        auth={auth}
        component={QueryManagerDetails}
      />
      <Redirect
        from="/managers/:managerId(\d+)/:type?"
        to="/managers/:managerId(\d+)/overview/summary"
      />
      <CrumbRoute
        title="Clients"
        exact
        path="/:category(clients)"
        auth={auth}
        component={AllClientsComponent}
      />
      <CrumbRoute title="ID" path="/:category(clients)/:clientId(\d+)/:typeOrReportId/:type?" auth={auth} component={ClientDetails} />
      <Redirect
         from="/clients/:clientId(\d+)/:type?"
         to="/clients/:clientId(\d+)/documents"
       />
      <CrumbRoute title="Plans" path="/:category(plans)" exact auth={auth} component={PlanList}/>
      <CrumbRoute title="Plan" path="/:category(plans)/:planId(\d+)/:type(overview|portfolios|performance|activities)" auth={auth} component={PlanDetails} />
      <Redirect
         from="/plans/:planId(\d+)/:type?"
         to="/plans/:planId(\d+)/portfolios"
       />
       <CrumbRoute title="ID" path="/:category(clientportfolios)/:clientportfolioId(\d+)/:type(overview|jobsettings)" auth={auth} component={ClientPortfolioDetails} />
      <Redirect
         from="/clientportfolios/:clientportfolioId(\d+)/:type?"
         to="/clientportfolios/:clientportfolioId(\d+)/overview"
       />
      <CrumbRoute
        title="Products"
        path="/:category(products)/:productId(\d+)/:type(profile|analysis|overview|vehicles|portfolios|people|assets|performance|characteristics|write-ups|interactions|documents)/:subtype?"
        auth={auth}
        component={QueryProductDetails}
      />
      <CrumbRoute
        title="Target Date Families"
        path="/:category(glidepaths)/:glidepathId(\d+)/:type(overview|glidepath|series|interactions|documents)/:subtype?"
        auth={auth}
        component={GlidePathDetails}
      />
      <CrumbRoute title="Documents" path="/:category(documents)/:documentId([A-Za-z0-9\-]+)" auth={auth} component={QueryDocumentDetails} />
      <Redirect from="/glidepaths/:glidepathId(\d+)/:type?" to="/glidepaths/:glidepathId(\d+)/overview" />
      <Redirect from="/products/:productId(\d+)/:type?" to="/products/:productId(\d+)/overview/summary" />
      <CrumbRoute
        title="Record Keepers"
        path="/:category(recordkeepers)/:recordKeeperId(\d+)/:type(overview)/:subtype?"
        // path="/:category(recordkeepers)/:recordKeeperId(\d+)/:type(profile|overview|people|interactions|documents)/:subtype?"
        auth={auth}
        component={RecordKeeperDetails}
      />
      <Redirect from="/recordkeepers/:recordKeeperId(\d+)/:type?" to="/recordkeepers/:recordKeeperId(\d+)/overview" />
      <CrumbRoute
        title="Custodians"
        path="/:category(custodians)/:custodianId(\d+)/:type(overview)/:subtype?"
        // path="/:category(custodians)/:custodianId(\d+)/:type(profile|overview|people|interactions|documents)/:subtype?"
        auth={auth}
        component={CustodianDetails} />
      <Redirect from="/custodians/:custodianId(\d+)/:type?" to="/custodians/:custodianId(\d+)/overview" />
      <CrumbRoute
        title="Lists"
        path="/:category(lists)/:listId(\d+)"
        auth={auth}
        component={ListDetail}
      />
      <CrumbRoute
        title="Lists"
        exact
        path="/:category(lists)"
        auth={auth}
        component={ListList}
      />
      <CrumbRoute
        title="Reports"
        path="/:category(reports)/:reportId(\d+)/:portfolioId?"
        auth={auth}
        component={ReportDetail}
      />
      <CrumbRoute
        title="Reports"
        exact
        path="/:category(reports)"
        auth={auth}
        component={ReportList}
      />
      <CrumbRoute
        title="Research Products"
        exact
        path="/:category(research)"
        auth={auth}
        component={ResearchProducts}
      />
      <CrumbRoute
        title="Research Products"
        exact
        path="/:category(research)/:type(_1|_2|_3|_4)"
        auth={auth}
        component={ResearchProductDetails}
      />
      <Redirect from="/:category(research)/:type?" to="/:category(research)" />
      <CrumbRoute
        title="Groups"
        path="/:category(groups)/:groupId(\w+)/:type(overview)"
        auth={auth}
        component={GroupDetails} />
      <Redirect from="/groups/:groupId(\w+)/:type?" to="/groups/:groupId(\w+)/overview" />
      <CrumbRoute
        title="Indexes"
        path="/:category(indexes)/:indexId([^\/]+)/:type(overview)"
        auth={auth}
        component={IndexDetails} />
      <Redirect from="/indexes/:indexId([^\/]+)/:type?" to="/indexes/:indexId([^\/]+)/overview" />
      <CrumbRoute
        title="Control Center"
        exact
        path="/:category(control-center)/:type(control-panel|dna-users|report-due-dates|imported-files)"
        auth={auth}
        component={ControlCenterDashboard}
      />
      <CrumbRoute
        title="Control Center"
        exact
        path="/:category(control-center)/:type(imported-files)/:id(\d+)/:subtype(messages|data)"
        auth={auth}
        component={ImportedFileDetailsComponent}
      />
      <Redirect from="/:category(control-center)/:type(imported-files)/:id(\d+)/:subtype?" to="/:category(control-center)/:type(imported-files)/:id(\d+)/messages" />
      <Redirect from="/:category(control-center)/:type?" to="/:category(control-center)/control-panel" />
      <CrumbRoute title="" exact={true} path="/" auth={auth} component={App} />
      <Route component={Page404} />
    </Switch>
  )

const MainRoutes: React.FC = () => {
  const {pathname} = usePathname()
  const [logRoute] = useLogRouteMutation()
  useEffect(() => {
    logRoute({ variables: { path: pathname }}).then(result => {
      if(result.data?.logRoute) {
        // console.log('logged route', pathname, {result: result.data?.logRoute})
      }
    }).catch(err => {console.log(err.message)})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  return RouteSwitch
}

export default MainRoutes

import React, { Component } from 'react'
import { Row, Col, Form } from 'reactstrap'
import _ from 'lodash'

import { Vehicle } from '.'
import { FormInputField } from '../../../helpers/constant'
import { FormInput } from '../../ui/Forms/FormInput'
import { VehicleDetailInputs } from '.'
import VehicleFees, { VehicleType } from './VehicleFees'
import { GetLookupDataToOptions } from '../../ui/LookupOptions'
import Auth from '../../../Auth/Auth'

interface VehicleDetailsProps {
  vehicle: Vehicle
  setVehicle: (vehicle: Vehicle) => void
  editing: boolean
  auth: Auth
}

interface VehicleDetailsState {
  vehicle: Vehicle
}

export class VehicleDetails extends Component<
  VehicleDetailsProps,
  VehicleDetailsState
> {
  state: VehicleDetailsState = {
    vehicle: this.props.vehicle,
  }

  static getDerivedStateFromProps(
    props: VehicleDetailsProps,
    state: VehicleDetailsState
  ) {
    if (
      !_.isEqual(
        props?.vehicle?.vehicle?.id,
        state?.vehicle?.vehicle?.id || !state
      )
    ) {
      return {
        vehicle: props.vehicle,
      }
    } else {
      return state
    }
  }

  handleInputChange = (value: any, property: string) => {
    if (property === 'vehicle.feeSchedule') {
      let updatedVehicleState = JSON.parse(JSON.stringify(this.state.vehicle))
      _.set(updatedVehicleState, property, value)
      this.props.setVehicle(updatedVehicleState)
    } else {
      this.setState(
        (prevState) => {
          let updatedVehicleState = JSON.parse(
            JSON.stringify(prevState.vehicle)
          )
          _.set(updatedVehicleState, property, value)
          return { vehicle: updatedVehicleState }
        },
        () => {
          this.debouncedSetVehicle()
        }
      )
    }
  }

  debouncedSetVehicle = _.debounce(() => {
    this.props.setVehicle(this.state.vehicle)
  }, 300) // Wait for 300ms before calling setVehicle to avoid overwriting state from previous calls

  renderInputs = (
    inputProperties: FormInputField & {
      applicableTypes?: VehicleType[]
      inApplicableTypes?: VehicleType[]
      permissionToSee?: string[]
      permissionToEdit?: string[]
    },
    idx: number,
    vehicle: Vehicle
  ) => {
    const { editing } = this.props
    const vehicleType = vehicle.__typename
    const vehicleHasProp = _.has(vehicle, inputProperties.property)
    if (inputProperties.property === 'vehicle.type.code') {
      const vehicleDetails = vehicle.vehicle
      const typeOptions = vehicleDetails?.typeOptions
      if (vehicleDetails && typeOptions) {
        const category = vehicleDetails.category?.code
        let options: JSX.Element
        if (category) {
          options = GetLookupDataToOptions({
            data: typeOptions
              .filter((item) => item.category === category)
              .map(({ code, value }) => ({ code, value })),
          }) as JSX.Element
        } else {
          options = GetLookupDataToOptions({
            data: _.uniqBy(typeOptions, 'code').map(({ code, value }) => ({
              code,
              value,
            })),
          }) as JSX.Element
        }
        inputProperties.options = options
      }
    }
    if (
      inputProperties.applicableTypes &&
      inputProperties.applicableTypes.indexOf(vehicleType) === -1
    ) {
      return null
    }
    if (
      inputProperties.inApplicableTypes &&
      inputProperties.inApplicableTypes.indexOf(vehicleType) !== -1
    ) {
      return null
    }
    if (inputProperties.property !== '' && !vehicleHasProp) return null

    let editable = editing
    let { permissionToSee, permissionToEdit } = inputProperties
    if (permissionToSee?.length) {
      let canSee = this.props.auth.checkPermissions(permissionToSee)
      if (!canSee) return null
    }

    if (permissionToEdit?.length) {
      editable = editable && this.props.auth.checkPermissions(permissionToEdit)
    }

    const propertyVal = _.get(vehicle, inputProperties.property)
    const onChangeCallback = (value: any) =>
      this.handleInputChange(value, inputProperties.property)
    return (
      <FormInput
        key={idx}
        idx={idx}
        editMode={editable}
        propertyVal={propertyVal}
        updateValue={onChangeCallback}
        {...inputProperties}
      />
    )
  }

  render() {
    const { editing } = this.props
    const { vehicle } = this.state
    const vehicleDetails = vehicle.vehicle
    return vehicleDetails ? (
      <div className="pane">
        <Row>
          <Col>
            <h2 className="headline underline">
              {vehicleDetails.name}
              {vehicleDetails.status?.value === 'Terminated' && (
                <span className="inactive-tag">
                  {vehicleDetails.status?.value}
                </span>
              )}
            </h2>
            <Row>
              <Col
                md={12}
                xl={4}
                className="pane exportable-form"
                data-export-name="Vehicle Summary"
              >
                <h3 className="headline gray-underline underline mt-4">
                  Summary
                </h3>
                <Form>
                  <Col>
                    {VehicleDetailInputs.map((el, idx) =>
                      this.renderInputs(el, idx, vehicle)
                    )}
                  </Col>
                </Form>
              </Col>
              <Col md={12} xl={8}>
                <h3 className="headline gray-underline underline mt-4">Fees</h3>
                <VehicleFees
                  vehicleType={vehicle.__typename}
                  handleInputChange={this.handleInputChange}
                  editing={editing}
                  fundid={vehicleDetails.id}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    ) : (
      <div>Missing Data</div>
    )
  }
}

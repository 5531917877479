import classnames from 'classnames'
import _, { isEmpty } from 'lodash'
import React, { useContext, useState, Component, useRef, RefObject, useEffect, useCallback } from 'react'
import { Table, Button } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import iassign from 'immutable-assign'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import { useProductAssetsByClientTypeQuery, ProductAssetsByClientTypeQuery, useUpdateProductAssetsByClientTypeSingleMutation, ProductAssetsByClientSingleFields, ProductAssetsByClientSingleDcFields, AssetsByClient, CategoryType, ProductAssetsByClientHistoricalAssetRow, ProductAssetsByClientHistoricalAccountRow, ProductAssetsByClientHistoricalAccountFields, ClientType, ProductAssetsByClientHistoricalAssetFields,useUpdateProductAssetsByClientTypeHistoricAssetsMutation, useUpdateProductAssetsByClientTypeHistoricAccountsMutation, UpdateProductAssetsByClientHistoricalAssetInput, OriginCode, AssetsByClientOtherExplanation} from '../../__generated__/graphql'
import { CalendarContext, appDate } from '../../Context/CalendarContext'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, FormInputField } from '../../helpers/constant'
import { CalendarPicker } from '../CalendarPicker'
import PlaceHolder from './../ui/PlaceHolder'
import { ManagerType, listQuarters, numbroFormatter } from "../../helpers/helpers"
import { FormInput } from '../ui/Forms/FormInput'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import exportTables from '../../helpers/exportTable'
import ErrorDisplay from '../Shared/ErrorDisplay'

interface ProductAssetsClientTypeProps {
  productId: number
  auth: Auth
}

export interface GroupedAssetsContainer {
  [key: string]: GroupedAssets
}

export interface GroupedAssets {
  [key: string]: GroupedAsset
}

export interface GroupedAsset {
  [key: string]: AssetsByClient
}

interface stateSingleDiffType {
  fields: ProductAssetsByClientSingleFields[],
  explanation?: string | null,
  dcFields: ProductAssetsByClientSingleDcFields[]
}

type HistoricUpdateRowMap = {
  numberOfAccounts: ProductAssetsByClientHistoricalAccountRow[],
  assetsUnderManagement: ProductAssetsByClientHistoricalAssetRow[]
}
export type HistoricUpdateRow = keyof HistoricUpdateRowMap


type HistoricUpdateFieldsMap = {
  numberOfAccounts: ProductAssetsByClientHistoricalAccountFields,
  assetsUnderManagement: ProductAssetsByClientHistoricalAssetFields
}

export interface stateHistoricDiffType {
  [key: string]: HistoricUpdateRowMap
}

interface ResultProps {
  setSearchDate: (searchDate:string) => void
  data: ProductAssetsByClientTypeQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  onChange: (newState:ProductAssetsByClientTypeQuery) => void
  ref: RefObject<Result>
}

interface TotalAssets {
  [key: string]: {
    assetsUnderManagement: number
    numberOfAccounts: number
  }
}

interface HistoricResultProps {
  setSearchDate: (searchDate:string) => void
  data: ProductAssetsByClientTypeQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  onChange: (categoryType: CategoryType, updateType: HistoricUpdateRow, newState:GroupedAssets) => void
  fetchMore: any
  ref: RefObject<HistoricResult>
  productType: string
}

const HeadingOrder = [["CORP","Corporate"], ["SUPAN","Superannuation"], ["PUBLIC","Public Fund (Gov)"], ["MULTI","Union/Multi-Emp"], ["ENDOW","Found&Endow"], ["HEALTH","Health Care"], ["INSUR","Insurance"], ["HINET","High Net Worth"], ["WRAP","Wrap Accounts"], ["SUBADV","Sub-Advised"], ["SUPN","Supranationals"], ["SOV","Sov Wealth Funds"], ["OTHER","Other"], ["TOT", "Calculated Total"]]

const firstHistoricalDate = moment(appDate).subtract(5,"years")

const defaultSingleDiff: stateSingleDiffType = {
  fields: [],
  explanation: '',
  dcFields: []
}

const ProductAssetsClientType: React.FC<ProductAssetsClientTypeProps> = ({ productId, auth }) => {
  const context = useContext(CalendarContext)
  const history = useHistory()
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [screen, setScreen] = useState("single")
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [updateCount, setUpdateCount] = useState(0)

  const historicalResultRef = useRef<HistoricResult>(null)
  const singleResultRef = useRef<Result>(null)

  const [updateProductAssetsByClient] = useUpdateProductAssetsByClientTypeSingleMutation()
  const [updateProductAssetsByClientHistoricAssets] = useUpdateProductAssetsByClientTypeHistoricAssetsMutation()
  const [updateProductAssetsByClientHistoricAccounts] = useUpdateProductAssetsByClientTypeHistoricAccountsMutation()

  let { data, loading, error, fetchMore, refetch: _refetch } =
  useProductAssetsByClientTypeQuery({
    variables: {
      id: productId,
      startDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT),
      endDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT)
    },
    // partialRefetch: true,
    fetchPolicy: 'network-only'
  })
  // https://github.com/apollographql/react-apollo/issues/3862
  const refetchCallback = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch])

  const handleEdit = () => {
    singleResultRef!.current?.resetForm()
    historicalResultRef!.current?.resetForm()
    setStateDiff(defaultSingleDiff)
    setHistoricStateDiff({})
    setUpdateCount(0)
    setEditMode(!editMode)
  }

  const [stateDiff, setStateDiff]:[stateSingleDiffType, React.Dispatch<React.SetStateAction<stateSingleDiffType>>] = useState(
    defaultSingleDiff)

  const [historicStateDiff, setHistoricStateDiff]:[stateHistoricDiffType, React.Dispatch<React.SetStateAction<stateHistoricDiffType>>] = useState({})

  // avoid unnecessary forceUpdate
  const [forceUpdateFlag, setForceUpdateFlag] = useState<boolean>(false)

  useEffect(() => {
    if(!editMode && forceUpdateFlag) {
      setStateDiff({
        fields: [],
        explanation: '',
        dcFields: []
      } as stateSingleDiffType)
      setHistoricStateDiff({})
      setUpdateCount(0)
      refetchCallback()
    }
  }, [editMode])

  const afterUpdate = (resultPromise:Promise<any>) => {
    resultPromise.then(result => {
      if(updateCount <= 0){
        setSaving(false)
        setEditMode(false)
        historicalResultRef.current?.resetHistory()
        setStateDiff({
          fields: [],
          explanation: '',
          dcFields: []
        } as stateSingleDiffType)
        setHistoricStateDiff({})
        setUpdateCount(0)
      } else {
        setUpdateCount(updateCount - 1)
      }
    })
    .catch(err => {
      if(updateCount <= 0){
        setSaving(false)
        setUpdateCount(0)
      } else {
        setUpdateCount(updateCount - 1)
      }
      console.log("Error testManagerSummary", err.message)
    })
  }

  const getHistoricDiff = (state:stateHistoricDiffType) => {
    // get only diff change
    let stateDiff:stateHistoricDiffType = {}
    _.map(state, (groupedAssets:GroupedAssets, date:string) => {
      _.map(groupedAssets, (row:GroupedAsset, category:string) => {
        _.map(row, (asset:AssetsByClient, clientType: string) => {
          const oldAsset = _.get(data, [date, category, clientType])
          if (!_.isEqual(asset, oldAsset)) {
            _.set(stateDiff, [date, category, clientType as ClientType], asset)
          }
        })
      })
    })
    return stateDiff
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    setForceUpdateFlag(true)
    let returnPromise:Promise<any>
    if (screen === "single") {
      if(isEmpty(stateDiff.fields) && isEmpty(stateDiff.fields) && stateDiff.explanation === ''){
        setForceUpdateFlag(false)
        setSaving(false)
        setEditMode(false)
      } else {
        let submissionData = {
          id: productId,
          date: searchDate,
          ...stateDiff
        }
        afterUpdate(updateProductAssetsByClient({ variables: { input: submissionData, startDate: searchDate, endDate: searchDate } }))
      }
    } else {
      let diff = getHistoricDiff(historicStateDiff)
      let tempUpdateCount = Object.keys(diff).reduce((acc, type) => {
        const typeRows =  diff[type]
        if (typeRows?.assetsUnderManagement?.length > 0) {
          acc ++
        }
        if (typeRows?.numberOfAccounts?.length > 0){
          acc ++
        }
        return acc
      }, 0)

      let countDown = tempUpdateCount

      const resetAfterCountdown = (countDown: number) => {
        if(countDown <= 0){
          setSaving(false)
          setEditMode(false)
        }
      }
      if(countDown > 0) {
        Object.keys(diff).forEach((type, idx) => {
          const typeRows =  diff[type]
          if (typeRows?.assetsUnderManagement?.length > 0) {
            const submissionData:UpdateProductAssetsByClientHistoricalAssetInput = {
              id: productId,
              categoryType: type as CategoryType,
              rows: typeRows.assetsUnderManagement
            }
            updateProductAssetsByClientHistoricAssets({ variables: { input: submissionData, endDate: appDate.format(DATE_API_FORMAT), startDate: firstHistoricalDate.format(DATE_API_FORMAT) } }).then(result => {
              countDown --
              resetAfterCountdown(countDown)
            }).catch(err => {
              countDown --
              console.log("Error updateProductAssetsByClientHistoricAssets", err.message)
              resetAfterCountdown(countDown)
            })
          }
          if (typeRows?.numberOfAccounts?.length > 0){
            const submissionData:UpdateProductAssetsByClientHistoricalAssetInput = {
              id: productId,
              categoryType: type as CategoryType,
              rows: typeRows.numberOfAccounts
            }
            updateProductAssetsByClientHistoricAccounts({ variables: { input: submissionData, endDate: appDate.format(DATE_API_FORMAT), startDate: firstHistoricalDate.format(DATE_API_FORMAT) } }).then(result => {
              countDown --
              resetAfterCountdown(countDown)
            }).catch(err => {
              countDown --
              console.log("Error updateProductAssetsByClientHistoricAccounts", err.message)
              resetAfterCountdown(countDown)
            })
          }
        })
      }else {
        setForceUpdateFlag(false)
        setSaving(false)
        setEditMode(false)
      }
    }
  }

  const handleSingleChange = (newState:ProductAssetsByClientTypeQuery) => {

    if ( newState?.product?.product?.assetsByClient && newState?.product?.product?.assetsByClientOtherExplanation ){
      const { assetsByClient, assetsByClientOtherExplanation } = newState?.product?.product

      let submissionData:stateSingleDiffType = {
        fields: [],
        dcFields: []
      }

      assetsByClient.map((asset) => {
        if (asset?.clientType.code === 'DC') {
          let field:ProductAssetsByClientSingleDcFields = {
            categoryType: asset?.categoryType.code,
            assetsUnderManagement: asset?.assetsUnderManagement,
            numberOfAccounts: asset?.numberOfAccounts
          }

          submissionData.dcFields.push(field)
        } else if (asset) {
          let field:ProductAssetsByClientSingleFields = {
            clientType: asset.clientType.code,
            categoryType: asset.categoryType.code,
            assetsUnderManagement: asset.assetsUnderManagement,
            numberOfAccounts: asset.numberOfAccounts
          }

          submissionData.fields.push(field)
        }
      })

      if (assetsByClientOtherExplanation && assetsByClientOtherExplanation.length > 0) {
        submissionData.explanation = assetsByClientOtherExplanation[0]?.explanation
      }

      setStateDiff(submissionData)
    }
  }

  const handleHistoricChange = (categoryType: CategoryType, updateType: keyof HistoricUpdateRowMap, diff:GroupedAssets) => {

    let rows:HistoricUpdateRowMap[typeof updateType] = []
    _.map(diff, (row:GroupedAsset, date:string) => {
      let fields:HistoricUpdateFieldsMap[typeof updateType][] = []

      _.map(row, (asset:AssetsByClient, clientType: ClientType) => {
        if (updateType === 'assetsUnderManagement') {
          fields.push({ clientType, assetsUnderManagement: asset.assetsUnderManagement })
        } else {
          fields.push({ clientType, numberOfAccounts: asset.numberOfAccounts })
        }
      })

      rows.push({ date: date, fields })
    })

    let historicState = _.cloneDeep(historicStateDiff)
    let newState = iassign(
      historicState,
      [categoryType],
      (category) => {
        // data format { numberOfAccounts: [{date, fields:[{clientType, numberOfAccounts: value}]}], assetsUnderManagement: [{date, fields:[{clientType, assetsUnderManagement: value}]}]}
        let rowsClone = _.cloneDeep(category)
        if(!rowsClone){
          rowsClone = { numberOfAccounts: [], assetsUnderManagement: [] } as HistoricUpdateRowMap
        }

        let oldValue = _.get(rowsClone, updateType) as any
        if(!oldValue || oldValue?.length < 1){
          _.set(rowsClone as any, updateType, rows)
        } else {
          let newValue = rows[0] // rows always contain only one element
          let newValueField = newValue.fields[0]
          let date = newValue.date
          let rowIndex = _.findIndex(oldValue, (row: any) => row?.date === date)
          if(rowIndex >= 0) {
            let oldValueFields: any[] = _.get(oldValue[rowIndex], "fields")
            let matchedOldValueFieldIndex = _.findIndex(oldValueFields, field => newValueField.clientType === field?.clientType && field?.hasOwnProperty(updateType))
            if(matchedOldValueFieldIndex >=0) {
              let filteredFields = _.filter(oldValueFields, (_, idx) => matchedOldValueFieldIndex !== idx)
              _.set(rowsClone as HistoricUpdateRowMap, [updateType, rowIndex, "fields"], [...filteredFields, newValueField])
            }else {
              _.set(rowsClone as HistoricUpdateRowMap, [updateType,  rowIndex, "fields"], [...oldValueFields, newValueField])
            }
          }else {
            _.set(rowsClone as HistoricUpdateRowMap, [updateType], [...oldValue, newValue])
          }
        }
        return rowsClone
      }
    )
    console.log('Changed Value', newState)
    setHistoricStateDiff(newState)
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={true}
          updateType={(type:string) => setScreen(type)}
          editMode={editMode}
          setEditMode={(value:boolean) => setEditMode(value)}
        />
        {screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>historicalResultRef.current?.loadMore()}>
            {historicalResultRef.current?.state.loadingMore && "Loading"}
            {!historicalResultRef.current?.state.loadingMore && "Load 5 More Years"}
          </Button>
        }
        <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="text-callan-blue mt-1 ml-1" onClick={()=> exportTables()}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} cancelEdit={handleEdit}/>
        }
      </div>
    </>
  )

  if (loading) {
    return (
      <>
        {heading}
        <div className='pane pane-table'>
          <PlaceHolder />
        </div>
      </>
    );
  }
  if (error) {
    return (
      <>
        {heading}
        <ErrorDisplay error={error}/>
      </>
    );
  }
  if (data && data.product ) {
    if(screen === "single"){
      return (
        <>
          {heading}
          <Result
            editMode={editMode}
            setEditMode={setEditMode}
            setSearchDate={setSearchDate}
            data={data}
            searchDate={searchDate}
            onChange={handleSingleChange}
            ref={singleResultRef}
          />
        </>
      )
    } else if(screen === "historical") {
      return (
        <>
          {heading}
          <HistoricResult
            editMode={editMode}
            setEditMode={setEditMode}
            setSearchDate={setSearchDate}
            data={data}
            searchDate={searchDate}
            onChange={handleHistoricChange}
            fetchMore={fetchMore}
            ref={historicalResultRef}
            productType={data.product.__typename}
          />
        </>
      )
    }
  }
  return <div>data doesn't exist</div>
}

class Result extends Component<ResultProps> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data
  }

  static getDerivedStateFromProps(props: HistoricResultProps, state:HistoricResultState) {
    return {
      currentState: props.editMode ? state.currentState : props.data,
      initialState: props.data,
    }
  }

  resetForm = () => {
    this.setState({ currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    clientType: string,
    categoryType?: string
  ) => {
    let state = _.cloneDeep(this.state.currentState)
    if(!(state?.product?.product as any)[table]){
      _.set(state, ["product", "product", table], [])
    }
    let newState = iassign(
      state,
      ['product', 'product', table],
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as any[])
        var selectedRow = _.find(rows, (o) => {return (o?.clientType?.code === clientType) && (!categoryType || o?.categoryType?.code === categoryType)})
        if(selectedRow){
          _.set(selectedRow, property, value);
        } else {
          const newRow= {
            assetsUnderManagement: null,
            categoryType: {code: categoryType, __typename: "CategoryTypeLookup"},
            clientType: {code: clientType, __typename: "ClientTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: this.props.searchDate,
            __typename: "AssetsByClient",
          }
          _.set(newRow, property, value);
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ currentState: newState }, () =>
      this.props.onChange(newState)
    )
  }

  handleOtherChange = (
    value: string
  ) => {
    let state = _.cloneDeep(this.state.currentState)
    let newState = iassign(
      state,
      currentState => currentState?.product?.product?.assetsByClientOtherExplanation,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as AssetsByClientOtherExplanation[])
        var selectedRow = rows[0]
        if(selectedRow){
          _.set(selectedRow, "explanation", value);
        } else {
          const newRow: AssetsByClientOtherExplanation = {
            __typename: "AssetsByClientOtherExplanation",
            date: this.props.searchDate,
            explanation: value
          }
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ currentState: newState }, () =>
      this.props.onChange(newState)
    )
  }

  render() {
    const assets:AssetsByClient[] = _.get(this.state.currentState, "product.product.assetsByClient", [])
    const otherExplanation:AssetsByClientOtherExplanation[] = _.get(this.state.currentState, 'product.product.assetsByClientOtherExplanation', [])
    const rowOrder = [
      {code: "CORP", display: "Corporate"},
      {code: "SUPAN", display: "Superannuation"},
      {code: "PUBLIC", display: "Public Fund (Gov)"},
      {code: "MULTI", display: "Union/Multi-Emp"},
      {code: "ENDOW", display: "Found&Endow"},
      {code: "HEALTH", display: "Health Care"},
      {code: "INSUR", display: "Insurance"},
      {code: "HINET", display: "High Net Worth"},
      {code: "WRAP", display: "Wrap Accounts"},
      {code: "SUBADV", display: "Sub-Advised"},
      {code: "SUPN", display: "Supranationals"},
      {code: "SOV", display: "Sov Wealth Funds"},
      {code: "OTHER", display: "Other"},
      {code: "TOT", display: "Total"}
    ]
    var totals: TotalAssets = {}
    const groupedAssets = assets.reduce(
      (
        result: GroupedAssets,
        entry: AssetsByClient //ProductAssetsByClientType_product_product_assetsByClient
      ) => {
        result[entry!.clientType!.code] = result[entry!.clientType!.code] || []
        result[entry.clientType.code][entry.categoryType.code] = entry
        if (!["TOT", "DC", "US"].includes(entry.clientType.code)) {
          if (totals[entry.categoryType.code]) {
            totals[entry.categoryType.code] = {
              assetsUnderManagement:
                totals[entry.categoryType.code].assetsUnderManagement +
                (entry.assetsUnderManagement || 0),
              numberOfAccounts:
                totals[entry.categoryType.code].numberOfAccounts +
                (entry.numberOfAccounts || 0)
            }
          } else {
            totals[entry.categoryType.code] = {
              assetsUnderManagement: entry.assetsUnderManagement || 0,
              numberOfAccounts: entry.numberOfAccounts || 0
            }
          }
          return result
        }
        return result
      },
      {}
    )
    const productType = this.state.currentState.product?.__typename
    const closedProduct = productType === "ClosedEndedPrivateCredit" || productType === "ClosedEndedPrivateEquity" || productType === "ClosedEndedRealAssets"
    const hedgeProduct = productType === "OpenEndedHedgeFund" || productType === "ClosedEndedHedgeFund"
    return(
      <>
      <div className="pane pane-table">
        <div className="pane-title">
          <h3 id="assetsByClientTypeTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByClientTypeTooltip">
              Assets By Client Type
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                className="ml-2 mt-0"
                size="sm"
              />
            </div>
          </h3>
        </div>
        <div className="table-container">
          <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.product?.product?.name}-client`}>
            <thead>
              <tr className="table-title row-border-olive-100">
                <th></th>
                <th colSpan={closedProduct ? 1 : 4} className="py-4">Assets ($M)</th>
                <th className="boundary-left"></th>
                <th className="boundary-right"></th>
                <th colSpan={closedProduct ? 1 : 4} className="py-4"># Accounts</th>
              </tr>
              <tr>
                <th>Client Type</th>
                <th className="text-right" id="productAssetsTotalTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="productAssetsTotalTooltip">
                    Total
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                {!closedProduct &&
                  <>
                    <th className="text-right">Taxable</th>
                    <th className="text-right">Tax-Exempt</th>
                    <th className="text-right">Institutional</th>
                  </>
                }
                <th className="boundary-left"></th>
                <th className="boundary-right"></th>
                <th className="text-right" id="productAccountsTotalTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="productAccountsTotalTooltip">
                    Total
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                {!closedProduct &&
                  <>
                    <th className="text-right">Taxable</th>
                    <th className="text-right">Tax-Exempt</th>
                    <th className="text-right">Institutional</th>
                  </>
                }
              </tr>
            </thead>
            <tbody>
              {rowOrder.map((arr, idx) => {
                return(
                  <AssetClassRow
                    data={groupedAssets}
                    row={idx}
                    key={idx}
                    editMode={this.props.editMode}
                    clientType={arr}
                    updateValue={(value:any, property:string, categoryType?:string) => this.handleInputChange(value, property, 'assetsByClient', arr.code, categoryType)}
                    totalOnly={closedProduct}
                  />
                )
              })}
              <tr>
                <td className='text-left text-nowrap'>Calculated Total</td>
                <td className={"text-right"}>
                    {numbroFormatter(totals["TOT"]?.assetsUnderManagement, '$0,0.00')}
                </td>
                {!closedProduct &&
                  <>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["TAX"]?.assetsUnderManagement , '$0,0.00')}
                    </td>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["TAXE"]?.assetsUnderManagement , '$0,0.00')}
                    </td>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["INST"]?.assetsUnderManagement , '$0,0.00')}
                    </td>
                  </>
                }
                <td className="boundary-left"></td>
                <td className="boundary-right"></td>
                <td className={"text-right"}>
                  {numbroFormatter(totals["TOT"]?.numberOfAccounts , '0')}
                </td>
                {!closedProduct &&
                  <>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["TAX"]?.numberOfAccounts , '0')}
                    </td>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["TAXE"]?.numberOfAccounts , '0')}
                    </td>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["INST"]?.numberOfAccounts , '0')}
                    </td>
                  </>
                }
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      {!closedProduct &&
        <div className="pane pane-table">
          <div className="pane-title">
            Other Explanation
          </div>
          <div className={"m-3"}>
            <FormInput
              property={"client"}
              displayName={""}
              type={"textarea"}
              subtype={""}
              showZero={true}
              placeholder={""}
              idx={1}
              editMode={this.props.editMode}
              propertyVal={otherExplanation[0] && otherExplanation[0].explanation}
              updateValue={this.handleOtherChange}
            />
          </div>
        </div>
      }
      {!(closedProduct || hedgeProduct) &&
        <div className="pane pane-table">
          <div className="pane-title">
            <h3>Defined Contribution Assets</h3>
          </div>
          <div className="table-container">
            <Table hover className="table-bordered-internal exportable" data-export-name="Defined Contribution Assets and Accounts">
              <thead>
                <tr className="table-title row-border-olive-100">
                  <th></th>
                  <th colSpan={5} className="py-4">Assets ($M)</th>
                  <th colSpan={5} className="py-4"># Accounts</th>
                </tr>
                <tr>
                  <th>Client Type</th>
                  <th className="text-right">Total</th>
                  <th className="text-right">Taxable</th>
                  <th className="text-right">Tax-Exempt</th>
                  <th className="text-right">Institutional</th>
                  <th className="boundary-left"></th>
                  <th className="boundary-right"></th>
                  <th className="text-right">Total</th>
                  <th className="text-right">Taxable</th>
                  <th className="text-right">Tax-Exempt</th>
                  <th className="text-right">Institutional</th>
                </tr>
              </thead>
              <tbody>
                <AssetClassRow
                  data={groupedAssets}
                  row={20}
                  editMode={this.props.editMode}
                  clientType={{code: "DC", display: "Defined Contribution"}}
                  updateValue={(value:any, property:string, categoryType?:string) => this.handleInputChange(value, property, 'assetsByClient', "DC", categoryType)}
                  totalOnly={closedProduct}
                />
              </tbody>
            </Table>
          </div>
        </div>
      }
    </>
    )
  }
}

interface AssetClassRowProps {
  data: GroupedAssets
  row: number
  editMode: boolean
  clientType: {code: string, display: string}
  updateValue: (value:any, property:string, categoryType?:CategoryType) => void
  managerType?: ManagerType
  historicalDate?: string
  date?: string
  totalOnly?: boolean
}

interface AssetClassInputField extends FormInputField{
  categoryType?: CategoryType
}

const AssetClassInputList:AssetClassInputField[] = [
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: CategoryType.TOT},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: CategoryType.TAX},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: CategoryType.TAXE},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: CategoryType.INST},
  { property: "boundary", label: "", type: "boundary"},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: CategoryType.TOT},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: CategoryType.TAX},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: CategoryType.TAXE},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: CategoryType.INST},
]

const AssetClassRow = ({data, row, editMode, clientType, updateValue, managerType, date, totalOnly}: AssetClassRowProps) => {
  return (
    <tr key={row}>
      <td className="text-left text-nowrap">
        {!date && clientType.display}
        {!!date && date}
      </td>
      {AssetClassInputList.map(({property, label, type, subtype, categoryType}, idx) => {
        let propertyVal, onChangeCallback, dataObject

        if(type === "boundary"){
          return (
            <React.Fragment key={idx}>
              <td className="boundary-left"></td>
              <td className="boundary-right"></td>
            </React.Fragment>
          )
        }

        propertyVal = _.get(data, date ? categoryType+'.'+clientType.code+'.'+property : clientType.code+'.'+categoryType+'.'+property)
        onChangeCallback = (value:any) => updateValue(value, property, categoryType)
        if(categoryType === "TOT" || !totalOnly){
          return(
            <td key={`${idx}-${date}-${categoryType}-${property}-${clientType.code}`} className="text-right">
              <FormInput
                property={property}
                displayName={label}
                type={type}
                showZero={true}
                subtype={subtype}
                // idx={idx + (row*AssetClassInputList.length)}
                idx={`${idx}-${date}-${categoryType}-${property}-${clientType.code}`}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
              />
            </td>
          )
        }
      })}
    </tr>
  )
}

interface HistoricResultState {
  currentState: GroupedAssetsContainer
  initialState: GroupedAssetsContainer
  loadingMore: boolean
  historicalDate: moment.Moment
  displayValue: HistoricUpdateRow
  category: CategoryType
}
class HistoricResult extends Component<HistoricResultProps, HistoricResultState> {
  constructor(props: any) {
    super(props)

    let newState = HistoricResult.groupData(props)
    this.state = {
      currentState: newState,
      initialState: newState,
      loadingMore: false,
      historicalDate: moment(firstHistoricalDate),
      displayValue: "assetsUnderManagement",
      category: CategoryType["TOT"]
    }

  }

  resetForm = () => {
    this.setState({ currentState: this.state.initialState })
  }

  static getDerivedStateFromProps(props: HistoricResultProps, state:HistoricResultState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    const groupData = HistoricResult.groupData(props)
    return {
      currentState: resetDate && !props.editMode ? groupData : state.currentState,
      initialState: resetDate ? groupData : state.initialState,
      loadingMore: state.loadingMore,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
    }
  }

  static groupData = (props:HistoricResultProps) => {
    const assets:AssetsByClient[] = _.get(props.data, "product.product.assetsByClient", [])
    // const filteredAssets = _.filter(assets, o => { return o.categoryType.code === category })

    let groupedData:GroupedAssetsContainer = {}
    assets.map((entry:AssetsByClient) => {
      let date: string = entry.quarterEndDate
      let clientCode:ClientType = entry.clientType.code
      let category:CategoryType = entry.categoryType.code
      _.set(groupedData, [date, category, clientCode], entry)
    })

    return groupedData
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  loadMore = () =>{
    if(this.state.loadingMore){
      return
    }
    this.setState({loadingMore: true})
    this.props.fetchMore({
      variables: {
        id: this.props.data.product?.product?.id,
        startDate: this.state.historicalDate.format(DATE_API_FORMAT),
        endDate: moment(this.state.historicalDate).subtract(5, "years").format(DATE_API_FORMAT)
      },
      updateQuery: (previousResult:ProductAssetsByClientTypeQuery, { fetchMoreResult }:{fetchMoreResult:ProductAssetsByClientTypeQuery}) => {
        if(!fetchMoreResult){
          return previousResult
        }
        const newInitialState = this.state.initialState
        const newCurrentState = _.cloneDeep(this.state.currentState)

        const newAssets:AssetsByClient[] = _.get(fetchMoreResult, "product.product.assetsByClient", [])
        newAssets.map((entry:AssetsByClient) => {
          let date: string = entry.quarterEndDate
          let clientCode:ClientType = entry.clientType.code
          let categoryCode:CategoryType = entry.categoryType.code

          if (!_.has(this.state.currentState, [date, categoryCode, clientCode])) {
            _.set(newInitialState, [date, categoryCode, clientCode], entry)
            _.set(newCurrentState, [date, categoryCode, clientCode], entry)
          }
        })

        this.setState({
          initialState: newInitialState,
          currentState: newCurrentState,
          historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
          loadingMore: false
        })
        return previousResult
      }
    })
  }

  setCategory = (category:CategoryType) => {
    this.setState({
      category: category,
    })
  }

  setDisplayValue = (displayValue:HistoricUpdateRow) => {
    this.setState({
      displayValue: displayValue
    })
  }

  heading() {
    const productType = this.props.productType
    const closedProduct = productType === "ClosedEndedPrivateCredit" || productType === "ClosedEndedPrivateEquity" || productType === "ClosedEndedRealAssets"
    return(
      <div className="w-100 d-flex justify-content-between">
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-item",{ active: this.state.category === "TOT" })} onClick={() => this.setCategory(CategoryType["TOT"])}>Total</li>
          {!closedProduct &&
            <>
              <li className={classnames("horizontal-picker-item",{ active: this.state.category === "TAX" })}  onClick={() => this.setCategory(CategoryType["TAX"])}>Taxable</li>
              <li className={classnames("horizontal-picker-item",{ active: this.state.category === "TAXE" })}  onClick={() => this.setCategory(CategoryType["TAXE"])}>Tax-Exempt</li>
              <li className={classnames("horizontal-picker-item",{ active: this.state.category === "INST" })}  onClick={() => this.setCategory(CategoryType["INST"])}>Institutional</li>
            </>
          }

        </ul>

        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-button",{ active: this.state.displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets $(M)</li>
          <li className={classnames("horizontal-picker-button",{ active: this.state.displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
      </div>
    )
  }

  onChangeCallback = (
    date: string,
    clientType: ClientType,
    value: any,
    property?: string,
    inCategory?: CategoryType,
  ) => {
    const { displayValue, category } = this.state
    const editCategory = inCategory || category
    let newState = _.cloneDeep(this.state.currentState)
    if(!newState[date]){
      _.set(newState, date, {})
    }
    if(!newState[date][editCategory]){
      _.set(newState, [date, editCategory], {})
    }
    newState = iassign(
      newState,
      [date ,editCategory, clientType],
      selectedCell => {
        let cell = _.cloneDeep(selectedCell as AssetsByClient)
        const selectedRow = _.find(cell, (o) => {return (o?.clientType?.code === clientType && o.quarterEndDate === date)})

        if (selectedRow) {
          _.set(cell, property || displayValue, value)
        } else {
          cell = {
            assetsUnderManagement: null,
            categoryType: {code: editCategory, __typename: "CategoryTypeLookup"},
            clientType: {code: clientType as ClientType, __typename: "ClientTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: date,
            __typename: "AssetsByClient",
            origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'}
          }
        }
        _.set(cell, property || displayValue, value)
        return cell
      }
    )
    //data format {[date: string]: GroupedAsset}
    let groupedDiff:GroupedAssets = {}
    let asset =  _.get(newState, [date, editCategory, clientType])
    _.set(groupedDiff, [date, clientType as ClientType], asset)
    // _.map(newState[date], (row:GroupedAsset, category:string) => {
    //   _.map(row, (asset:AssetsByClient, clientType: string) => {
    //     const oldAsset = _.get(this.state.initialState[date], category + "."+ clientType)
    //     if (!_.isEqual(asset, oldAsset)) {
    //       _.set(groupedDiff, [date, clientType as ClientType], asset)
    //     }
    //   })
    // })

    this.setState({ currentState: newState }, () => {
      this.props.onChange(editCategory, (property as keyof HistoricUpdateRowMap) || displayValue, groupedDiff)
    })
  }

  render() {
    const { editMode } = this.props
    const { displayValue, category } = this.state
    const data = this.state.currentState
    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

    const subType = displayValue === 'assetsUnderManagement' ? 'currency' : ''
    const type = displayValue === 'assetsUnderManagement' ? 'float' : 'number'

    const productType = this.props.productType
    const closedProduct = productType === "ClosedEndedPrivateCredit" || productType === "ClosedEndedPrivateEquity" || productType === "ClosedEndedRealAssets"
    const hedgeProduct = productType === "OpenEndedHedgeFund" || productType === "ClosedEndedHedgeFund"
    let testName = ""
    if(this.state.displayValue === "numberOfAccounts"){
      if(category === "TOT"){
        testName = "Historical Total Accounts"
      }
      else if(category === "TAX"){
        testName = "Historical Tax Accounts"
      }
      else if(category === "TAXE"){
        testName = "Historical Tax-Exempt Accounts"
      }
      else if(category === "INST")
        testName = "Historical Institutional Accounts"
    }
    else {
      if(category === "TOT"){
        testName = "Historical Total Assets"
      }
      else if(category === "TAX"){
        testName = "Historical Tax Assets"
      }
      else if(category === "TAXE"){
        testName = "Historical Tax-Exempt Assets"
      }
      else if(category === "INST")
        testName = "Historical Institutional Assets"
    }
    return (
      <>
        <div className="pane pane-table">
          <div className="pane-title-alt">
            <h3 id="assetsByClientTypesHistoricalTooltipContainer">
              <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByClientTypeHistoricalTooltip">
                Assets by Client Type
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  className="ml-2 mt-0"
                  size="sm"
                />
              </div>
            </h3>
          </div>
          <Table hover className="table-condensed table-borderless">
              <thead>
                <tr>
                  <td colSpan={1000}>{this.heading()}</td>
                </tr>
              </thead>
            </Table>
          <div className="table-container">
            <Table hover className="table-bordered-internal exportable" data-export-name = {testName}>
              <thead>
                <tr>
                  <th>Date</th>
                  {HeadingOrder.map((arr) => {
                    const key:string = arr[0]
                    const name:string = arr[1]

                    let classes = []
                    if(key === "DC" && (closedProduct || hedgeProduct)) return (<React.Fragment key={key}></React.Fragment>)

                    return(<th key={key} className="fit-currency">{name}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {allDates.map((date: string, dateIdx) => {
                  const rowDate = _.get(data, date)
                  const rowData = _.get(rowDate, category)
                  let assetsUnderManagementArr: any[] = []
                  const groupedAssetsKeys = rowData && Object.keys(rowData)

                  groupedAssetsKeys?.map((key) => !(key === "DC" || key === "TOT" || key === "US") && assetsUnderManagementArr.push(_.get(rowData, displayValue === 'assetsUnderManagement' ? `${key}.assetsUnderManagement` : `${key}.numberOfAccounts` )))
                  const calculatedTotal = assetsUnderManagementArr.reduce((preValue, currValue)=>preValue+currValue, null)

                  return (
                    <tr key={date} className="fadein fadein-yellow" >
                      <td className="text-left text-nowrap">{date}</td>
                      {HeadingOrder.map((arr, idx) => {
                        const key:string = arr[0]
                        const value = _.get(rowData, [key, displayValue])
                        if(key === "DC" && (closedProduct || hedgeProduct)) return (<React.Fragment key={idx}></React.Fragment>)

                        return (
                          <td key={`${date}-${displayValue}-${category}-${key}`}>
                            <FormInput
                              property={`${date}-${displayValue}-${category}-${key}`}
                              displayName={''}
                              type={type}
                              showZero={true}
                              subtype={subType}
                              idx={`${date}-${displayValue}-${category}-${key}`}
                              editMode={key === 'TOT' ? false : editMode}
                              propertyVal={key === 'TOT' ? calculatedTotal : value}
                              updateValue={(v:any) => this.onChangeCallback(date, key as ClientType, v)}
                            />
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          </div>
            <div className="pane pane-table">
            <div className="pane-title">
              <h3>Defined Contribution Assets</h3>
            </div>
            <div className="table-container">
              <Table hover className="table-bordered-internal table-condensed exportable" data-export-name="Defined Contribution Assets and Accounts">
                <thead>
                  <tr className="table-title row-border-olive-100">
                    <th></th>
                    <th colSpan={4} className="py-2">Assets ($M)</th>
                    <th className="boundary-left"></th>
                    <th className="boundary-right"></th>
                    <th colSpan={4} className="py-2"># Accounts</th>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th className="text-right">Total</th>
                    <th className="text-right">Taxable</th>
                    <th className="text-right">Tax-Exempt</th>
                    <th className="text-right">Institutional</th>
                    <th className="boundary-left"></th>
                    <th className="boundary-right"></th>
                    <th className="text-right">Total</th>
                    <th className="text-right">Taxable</th>
                    <th className="text-right">Tax-Exempt</th>
                    <th className="text-right">Institutional</th>
                  </tr>
                </thead>
              <tbody>
                {allDates.map((date: string, row: number) => {
                  return (
                    <AssetClassRow
                      key={row}
                      data={this.state.currentState[date]}
                      row={row}
                      editMode={this.props.editMode}
                      clientType={{ code: "DC", display: "Defined Contribution" }}
                      updateValue={(value, property, category) => this.onChangeCallback(date, "DC" as ClientType, value, property, category)}
                      totalOnly={closedProduct}
                      date={date}
                    />
                  )
                })}
                </tbody>
              </Table>
            </div>
        </div>
      </>
    )
  }
}

export default ProductAssetsClientType

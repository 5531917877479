import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { compact, first } from 'lodash'
import React, { SyntheticEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap'

import { ClientAssociationFragment, FileFullDetailsFragment, GlidePathAssociationFragment, InteractionAssociationsInput, InteractionFragment, ManagerAssociationFragment, Plan, PlanAssociationFragment, ProductAssociationFragment, UpdateFileDataInput, UpdateFileMetadataInput, useRemoveInteractionAssociationMutation, useUpdateFileMetadataMutation, VehicleAssociationFragment } from '../../__generated__/graphql'

export interface AssociationManager {
  __typename: "Manager";
  id: number;
  name: string;
}

export interface AssociationProduct {
  __typename: "ProductFields";
  id: number;
  name: string;
}

export interface AssociationVehicle {
  __typename: "VehicleFields";
  id: string;
  name: string;
}

export interface AssociationPlan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface AssociationClient {
  __typename: "Client";
  id: string;
  name: string;
}

export interface Association {
  __typename: "ProductFields" | "Manager" | "GlidePath" | "VehicleFields" | "Plan" | "Client";
  id: number | string;
  name: string;
  managerName?: string
  info?: string
  productId?: number
  orgId?: number
  reportId?: number
}

export interface mapAssociationsProps {
  managers?: ManagerAssociationFragment[]
  products?: ProductAssociationFragment[]
  glidePaths?: GlidePathAssociationFragment[]
  vehicles?: VehicleAssociationFragment[]
  plans?: PlanAssociationFragment[]
  clients?: ClientAssociationFragment[]
}

export const mapAssociations = (associations:mapAssociationsProps) => {
  const productAssociations = compact(associations.products?.map(p => p?.product))
  const vehiclesAssociations = compact(associations.vehicles?.map(p => p?.vehicle))
  let allAssociations:Association[] = compact(associations.managers).map(m => { return {
    __typename: m.__typename,
    id: m.id,
    name: m.name || ''
  }})
  allAssociations = allAssociations.concat(compact(associations.clients).map(g => { return {
    __typename: g.__typename,
    id: g.id,
    name: g.name || '',
  }}))
  allAssociations = allAssociations.concat(compact(productAssociations).map(p => { return {
    __typename: p.__typename,
    id: p.id,
    name: p.name,
    managerName: p.manager?.name
  }}))
  allAssociations = allAssociations.concat(compact(associations.glidePaths).map(g => { return {
    __typename: g.__typename,
    id: g.id,
    name: g.name || '',
    managerName: g.manager?.name || '',
  }}))
  allAssociations = allAssociations.concat(compact(vehiclesAssociations).map(g => { return {
    __typename: g.__typename,
    id: g.id,
    name: g.name || '',
    productId: g.product?.product?.id
  }}))
  allAssociations = allAssociations.concat(compact(associations.plans).map(g => { return {
    __typename: g.__typename,
    id: g.id,
    name: g.name || '',
    managerName: g.client?.name || '',
    orgId: g.client?.id,
    reportId: first(g.report)?.id || 0,
  }}))
  // allAssociations = allAssociations.concat(compact(associations.plans).map(g => { return { __typename: g.__typename, id: g.id, name: g.name || '' } }))
  return allAssociations
}

interface AssociationsListProps {
  associations: Association[],
  onClick?: (association:Association) => void
  icon?: JSX.Element
  flat?: boolean
  removable?: boolean
  removing?: boolean
  onRemove?: (association:Association) => void
}
export const AssociationsList:React.FC<AssociationsListProps> = ({ icon, associations, onClick, flat, removable, onRemove, removing }) => {
  const history = useHistory()

  return (
    <ListGroup className={classNames("horizontal", "with-category", flat && "flat")}>
      { associations.map((association, idx) => {
        if (!association) {
          return (<></>)
        }

        let clickAssociation = onClick || ((association) => {
          switch(association.__typename) {
            case "Manager":
              return history.push(`/managers/${association.id}/overview`)
            case "ProductFields":
              return history.push(`/products/${association.id}/overview/summary`)
            case "GlidePath":
              return history.push(`/glidepaths/${association.id}/overview`)
            case "VehicleFields":
              return history.push(`/products/${association.productId}/vehicles/${association.id}`)
            case "Plan":
              return history.push(`/clients/${association.orgId}/${association.reportId}/documents`)
            case "Client":
              return history.push(`/clients/${association.id}/1/documents`)
          }
        })

        let removeAssociation = ((event:SyntheticEvent) => {
          event.stopPropagation()
          event.preventDefault()
          if(onRemove){
            onRemove(association)
          }
        })

        let categoryClass = association.__typename.toLocaleLowerCase()
        let name = association.name
        let category:string = association.__typename
        let infoHeader:string = ""
        let relationType = "Manager"

        if (association.__typename === "GlidePath") {
          category = "Target Date"
          categoryClass = "target"
        } else if (association.__typename === "ProductFields") {
          category = "Product"
          categoryClass = "product"
        } else if (association.__typename === "Plan"){
          relationType = "Client"
        } else if (association.__typename === "VehicleFields"){
          category = "Vehicle"
          relationType = "Vehicle"
          categoryClass = "vehicle"
        }

        return (
          // CAL-1553
          <ListGroupItem tag="a" className="hover-display-container" onClick={() => clickAssociation(association)} key={`association-list-${categoryClass}-${idx}`}>
            <div className={classNames("category", categoryClass)}>
              <div className="category-text">
                <h6>{category}</h6>
              </div>
            </div>
            <div className="w-100">
              <Row>
                <Col md={removable ? 6 : 6}>
                  <h3>{name}</h3>
                  {association.managerName  &&
                    <p>{relationType.toLocaleUpperCase()}: {association.managerName}</p>
                  }
                </Col>
                <Col md={!!infoHeader ? 2 : 4}>
                  <h5>{category} ID</h5>
                  <p>{association.id}</p>
                </Col>
                {infoHeader &&
                  <Col md="2">
                    <h5>{infoHeader}</h5>
                    <p>{association.info}</p>
                  </Col>
                }
                {removable && !removing &&
                  <Col md="2" className="d-flex hover-swap-icon hover-display-item" onClick={(e) => removeAssociation(e)}>
                    <FontAwesomeIcon icon="times-circle" className="on-hover"/>
                    <FontAwesomeIcon icon="times" className="off-hover"/>
                  </Col>
                }
                {removable && removing &&
                  <Col md="2" className="d-flex">
                    <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />
                  </Col>
                }
              </Row>
            </div>
          </ListGroupItem>
        )

      })}
    </ListGroup>
  )
}

interface DocumentAssociationsListProps {
  document: FileFullDetailsFragment
  associations: Association[],
  onClick?: (association:Association) => void
  flat?: boolean
  removable?: boolean
}

export const DocumentAssociationsList:React.FC<DocumentAssociationsListProps> = (props:DocumentAssociationsListProps) => {
  const { document, associations, onClick, flat, removable } = props

  const [removing, setRemoving] = useState(false)
  const [updateFileMutation] = useUpdateFileMetadataMutation()

  const handleRemove = (association: Association) => {
    setRemoving(true)
    let patch:UpdateFileDataInput = {}
    switch(association.__typename){
      case "Manager":
        patch.managers = compact(document.managers?.map((man) => association.id === man?.id ? null : man?.id ) || [])
        break
      case "GlidePath":
        patch.glidePaths = compact(document.glidePaths?.map((man) => association.id === man?.id ? null : man?.id ) || [])
        break
      case "ProductFields":
        patch.products = compact(document.products?.map((man) => association.id === man?.product?.id ? null : man?.product?.id ) || [])
        break
      case "VehicleFields":
        patch.vehicles = compact(document.vehicles?.map((man) => association.id === man?.vehicle?.id ? null : man?.vehicle?.id ) || [])
        break
      case "Plan":
        patch.plans = compact(document.plans?.map((man) => association.id === man?.id ? null : man?.id ) || [])
        break
      case "Client":
        patch.clients = compact(document.clients?.map((man) => association.id === man?.id ? null : man?.id ) || [])
        break
    }
    const input = {
      id: document.id,
      patch: patch
    } as UpdateFileMetadataInput

    updateFileMutation({ variables: { input } })
      .then(result => {
        setRemoving(false)
        if (result && result.data) {
        }
      })
      .catch(err => {
        console.error("Error adding associations", err.message)
        // throw new Error(`${err.message}`)
        setRemoving(false)
      })
  }

  return(
    <AssociationsList
      associations={associations}
      onClick={onClick}
      onRemove={handleRemove}
      flat={flat}
      removable={removable}
      removing={removing}
    />
  )
}

interface InteractionAssociationsListProps {
  interaction: InteractionFragment
  associations: Association[],
  onClick?: (association:Association) => void
  flat?: boolean
  removable?: boolean
}

export const InteractionAssociationsList:React.FC<InteractionAssociationsListProps> = (props:InteractionAssociationsListProps) => {
  const { interaction, associations, onClick, flat, removable } = props

  const [removing, setRemoving] = useState(false)
  const [removeInteractionsMutation] = useRemoveInteractionAssociationMutation()

  const handleRemove = (association:Association) => {
    setRemoving(true)

    const removeInteractionsInput:InteractionAssociationsInput = {
      id: interaction.id,
      orgs: association.__typename === "Manager" ? [association.id as number] : [],
      glidePaths: association.__typename === "GlidePath" ? [association.id as number] : [],
      products: association.__typename === "ProductFields" ? [association.id as number] : [],
    }
    removeInteractionsMutation({ variables: { input: removeInteractionsInput } })
      .then(result => {
        setRemoving(false)
        if (result && result.data) {
        }
      })
      .catch(err => {
        setRemoving(false)
        console.error("Error removing associations", err.message)
        // throw new Error(`${err.message}`)

      })
  }

  return(
    <AssociationsList
      associations={associations}
      onClick={onClick}
      onRemove={handleRemove}
      flat={flat}
      removable={removable}
      removing={removing}
    />
  )
}
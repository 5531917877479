export const domain = process.env.REACT_APP_AUTH0_DOMAIN || ""
if (domain === "") {
  console.error("Error: you must set REACT_APP_AUTH0_DOMAIN in the .env file")
}

export const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID || ""
if (clientID === "") {
  console.error(
    "Error: you must set REACT_APP_AUTH0_CLIENT_ID in the .env file"
  )
}

export const audience = process.env.REACT_APP_AUTH0_AUDIENCE || ""
if (audience === "") {
  console.error("Error: you must set REACT_APP_AUTH0_AUDIENCE in the .env file")
}

export const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL || ""
if (redirectUri === "") {
  console.error(
    "Error: you must set REACT_APP_AUTH0_CALLBACK_URL in the .env file"
  )
}

export const auth0Namespace = process.env.REACT_APP_AUTH0_NAMESPACE || ""
if (auth0Namespace === "") {
  console.error(
    "Error: you must set REACT_APP_AUTH0_NAMESPACE in the .env file"
  )
}

export const name = process.env.REACT_APP_NAME || ""
if (audience === "") {
  console.error("Error: you must set REACT_APP_NAME in the .env file")
}

export const version = process.env.REACT_APP_VERSION || ""
if (audience === "") {
  console.error("Error: you must set REACT_APP_VERSION in the .env file")
}

export const uri = process.env.REACT_APP_GRAPHQL_ENDPOINT || ""
if (audience === "") {
  console.error(
    "Error: you must set REACT_APP_GRAPHQL_ENDPOINT in the .env file"
  )
}

export const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || ""
if (gtmId === "") {
  console.error(
    "Error: you must set REACT_APP_GOOGLE_TAG_MANAGER_ID in the .env file"
  )
}

export const gtmAuth = process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH || ""
if (gtmAuth === "") {
  console.error(
    "Error: you must set REACT_APP_GOOGLE_TAG_MANAGER_AUTH in the .env file"
  )
}

export const gtmPreview = process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW || ""
if (gtmPreview === "") {
  console.error(
    "Error: you must set REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW in the .env file"
  )
}

export const logrocketId = process.env.REACT_APP_LOGROCKET_ID || ""
if (logrocketId === "") {
  console.error("Error: you must set REACT_APP_LOGROCKET_ID in the .env file")
}

import iassign from "immutable-assign"
import classnames from 'classnames'
import React, { Component, useState, useRef, useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { Container, Row, Col, Nav, NavItem, NavLink, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from 'moment'
import _ from "lodash"

import EditButtons from '../ui/EditButtons'
import Auth from "../../Auth/Auth"
import { DATE_API_FORMAT} from "../../helpers/constant"
import PlaceHolder from "./../ui/PlaceHolder"
import { ManagerType } from '../../helpers/helpers'
import { useManagerWriteUpsQuery, Fact, ManagerWriteUpsQuery, Opinion, useCreateFactMutation, useCreateOpinionMutation, useMeQuery, User, ResearchCategoryCode, CreateFactInput, CreateOpinionInput, ManagerWriteUpsDocument, useDeleteFactMutation, useDeleteOpinionMutation, DeleteInput, StatusCode, CreateOpinionMutation, useOrgsQuery, OrgType, CreateManagerNoteInput, useCreateManagerNoteMutation, useUpdateManagerNoteMutation, UpdateManagerNoteFields, useDeleteManagerNoteMutation, Manager, ManagerNoteFragment } from '../../__generated__/graphql'
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import WriteUpComponent, { ClientSelection, WriteUpHistory } from "../Shared/WriteUp"
import {
  convertLookupToString,
  excludePropertyArray,
} from "../../helpers/object"
import { dnaNoteHasChanged, factualHasChanged, opinionHasChanged, sortAndFilterNotes } from "../../helpers/writeUps"
import AnchorLinkMenu, { AnchorLinkItem } from "../ui/AnchorLinkMenu"
import { PersonSelection } from "../ui/Pickers/EmployeePicker"
import { DnaNote, LegacyNote } from "../../queries/extended/types/WriteUps"
import { ApolloQueryResult } from "@apollo/client"

interface ManagerWriteUpsSwitcherProps {
  id: number
  auth: Auth
  managerType?: ManagerType
}

interface ManagerWriteUpsProps {
  id: number
  auth: Auth
  managerType?: ManagerType
  code: ResearchCategoryCode
}

export const WRITEUPS_ANCHOR_LIST: AnchorLinkItem[] = [
  {
    anchorId: "factual",
    title: "Factual Summary"
  },
  {
    anchorId: "callan-rep",
    title: "Callan Representative Opinion"
  },
  {
    anchorId: "client-opinions",
    title: "Client & Callan Team Opinions"
  },
  {
    anchorId: "private-notes",
    title: "Private Notes"
  },
]

// Some config values
const LOAD_LIMIT = 10
const TABLE_INCREMENT = 5 // This number <= LOAD_LIMIT
const INITIAL_TABLE_LENGTH = 5 // This number <= LOAD_LIMIT

const ManagerWriteUpsSwitcher: React.FC<ManagerWriteUpsSwitcherProps> = ({ id, managerType, auth }: ManagerWriteUpsSwitcherProps) => {
  const params = useParams() as { subtype?: string }
  const history = useHistory()
  const match = useRouteMatch()
  let urlWithoutSubtype =
      params && params.subtype
        ? match.url.slice(0, -params.subtype.length - 1)
        : match.url
  const handleRedirect = (tab: string) => {
    history.push(urlWithoutSubtype + "/" + tab)
  }
  const picker = (
    <Container fluid>
      <Row>
        <Col>
          <Nav className="sub-nav sub-nav-secondary" tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: match.url.indexOf(`${urlWithoutSubtype}/overall`) === 0
                })}
                onClick={() => handleRedirect("overall")}
              >
                Overall Manager
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: match.url.indexOf(`${urlWithoutSubtype}/people`) === 0
                })}
                onClick={() => handleRedirect("people")}
              >
                Manager People
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </Container>
  )

  return(
    <>
      {picker}
      <Switch>
        <Route
          exact
          path="/managers/:managerId(\d+)/write-ups/:subtype(overall)"
          component={() =>
            <ManagerWriteUps
              id={id}
              managerType={managerType}
              code={"OOVER" as ResearchCategoryCode}
              auth={auth}
              key={1}
            />
          }
        />
        <Route
          exact
          path="/managers/:managerId(\d+)/write-ups/:subtype(people)"
          component={() =>
            <ManagerWriteUps
              id={id}
              managerType={managerType}
              code={"OPEO" as ResearchCategoryCode}
              auth={auth}
              key={2}
            />
          }
        />
        <Redirect
          from="/managers/:managerId(\d+)/write-ups"
          to="/managers/:managerId(\d+)/write-ups/overall"
        />
      </Switch>
    </>
  )
}

const useForceUpdate = () =>{
  // integer state
  const [value, setValue] = useState(0);
  // update the state to force render
  return () => setValue(value => value + 1); 
}

const ManagerWriteUps: React.FC<ManagerWriteUpsProps> = ({ id, managerType, auth, code }: ManagerWriteUpsProps) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [historyDropdown, setHistoryDropdown] = useState(false)
  const resultRef = useRef<Result>(null)
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState("")
  // fix author/date change remains after clicking saving when nothing saved.
  const [forceUpdateFlag, setForceUpdateFlag] = useState<boolean>(false)
  const [noteIdsToDelete, setNoteIdsToDelete] = useState(new Set<number>())

  const [offset, setOffset] = useState(0)

  let { data, loading, error, refetch, fetchMore } =
  useManagerWriteUpsQuery({
    variables: {
      id,
      // Fix CAL-1825
      filters:{
        researchCategory: code,
        offset,
        limit: LOAD_LIMIT
      }
    },
    fetchPolicy: "network-only"
    // partialRefetch: true,
  })

  let { data: clientData, loading: clientLoading, error: clientError } =
  useOrgsQuery({
    variables: {
      limit: 4_000, // TODO: temp fix for CAL-2671, api research needed.
      type: "Client" as OrgType
    }
    // partialRefetch: true,
  })
  
  const [createFactMutation] = useCreateFactMutation()
  const [createOpinionMutationFunction] = useCreateOpinionMutation()
  const [createDnaNote] = useCreateManagerNoteMutation()
  const [updateDnaNote] = useUpdateManagerNoteMutation()
  const [deleteDnaNote] = useDeleteManagerNoteMutation()
  const [deleteFactMutation] = useDeleteFactMutation()
  const [deleteOpinionMutation] = useDeleteOpinionMutation()
  let {data: currentUserData, loading: currentUserLoading, error: currentUserError } = useMeQuery({fetchPolicy: "cache-first"})
  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setErrorMessage("")
    setEditMode(!editMode)
  }

  const [deleting, setDeleting] = useState(false)
  const forceUpdateNow = useForceUpdate()

  useEffect(()=> {
    if(!editMode && forceUpdateFlag && !saving) {
      forceUpdateNow()
    }
  }, [editMode])

  const onSubmit = () => {
    // check for non-nullable status code when creating opinion
    let opinions = _.cloneDeep(resultRef!.current?.state?.editedOpinion)
    let checkPassFlag = 
      Object.keys(opinions || {}).every((identifier) => {
        let opinionVersions = _.cloneDeep(resultRef!.current?.state?.opinionList[identifier]) || [] as Opinion[]
        let currentOpinion = resultRef!.current?.getLatest(opinionVersions) as Opinion
        // new created opinion has id < 0, back to normal id = clientId after client dropdown selected.
        if(currentOpinion) {
          return !!currentOpinion?.status?.code
        }else {
          return true
        }
      })

    if(checkPassFlag) {
      handleSubmit()
    }else {
      setErrorMessage("Red fields are required.")
      return
    }
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:writeups"])){
      return
    }
    setErrorMessage("")
    setSaving(true)
    setForceUpdateFlag(true)
    let currentFact = resultRef!.current?.getLatest(_.cloneDeep(resultRef!.current?.state?.currentFacts)) as Fact // may be undefined
    let initialFact = resultRef!.current?.getInitial(_.cloneDeep(resultRef!.current?.state?.initialFacts)) as Fact // may be undefined
    let factualEditedFlag = factualHasChanged(currentFact, initialFact)
    if (currentFact && resultRef!.current?.state?.editedFact) {
      // update only if edited or new created
      if(factualEditedFlag || currentFact?.id === initialFact?.id){
        currentFact = currentFact as Fact
        let formattedData = convertLookupToString(currentFact, false, [])
        formattedData.authorId = formattedData.author.id
        delete formattedData.author
        formattedData.category = formattedData.researchCategory
        delete formattedData.researchCategory

        const formattedRemoveNull = formattedData //_.pickBy(formattedDataRemove, _.identity);

        const updateData = {
          id: id,
          type: "MANAGER",
          fact: excludePropertyArray(formattedRemoveNull, ["__typename", "name", "id", "lastUpdated"]),
        } as CreateFactInput

        createFactMutation({ variables: {
            input: updateData,
          },
          optimisticResponse: {
            __typename: "Mutation",
            fact: {
              ...currentFact
            }
          },
          awaitRefetchQueries: true,
          refetchQueries:[{query: ManagerWriteUpsDocument, variables:{ id: id, filters:{
            researchCategory: code,
            offset,
            limit: LOAD_LIMIT
          }}}]
          // update: (cache, { data }) => {
          //   const org: any = cache.readQuery({
          //     query: ManagerWriteUpsDocument,
          //     variables: { id: id },
          //   })
          //   let updatedOrg = iassign(
          //     org,
          //     (currentOrg) => _.get(currentOrg, "org.writeUps.facts"),
          //     (selectedOrg) => {
          //       let rows = _.cloneDeep(selectedOrg)
          //       rows.push(data?.fact)
          //       return rows
          //     }
          //   )
          //   cache.writeQuery({
          //     query: ManagerWriteUpsDocument,
          //     variables: { id: id },
          //     data: updatedOrg,
          //   })
          // },
        })
        .then(() => {
          setEditMode(false)
          setSaving(false)
        })
        .catch((err) => {
          setSaving(false)
          console.log("Error ", err.message)
        })
      } else {
        console.log("fact is not updated")
        setEditMode(false)
        setSaving(false)
      }
    }

    let editedOpinionFlag:boolean = false
    Object.keys(resultRef!.current?.state?.editedOpinion || {}).map((identifier) => {
      let opinionVersions = _.cloneDeep(resultRef!.current?.state?.opinionList[identifier]) || [] as Opinion[]
      let initialVersion = resultRef!.current?.getInitial(opinionVersions) as Opinion
      let currentOpinion = resultRef!.current?.getLatest(opinionVersions) as Opinion
      let currentVersion = currentOpinion
      let opinionEditedFlag = opinionHasChanged(currentOpinion, initialVersion)
      if (currentOpinion && resultRef!.current?.state?.editedOpinion[identifier]){
        // update only if edited or new created
        if(opinionEditedFlag || currentVersion.id === initialVersion.id){
          currentOpinion = currentOpinion as Opinion
          let formattedData = convertLookupToString(currentOpinion, false, [])
          formattedData.authorId = formattedData.author.id
          delete formattedData.author
          formattedData.category = formattedData.researchCategory
          delete formattedData.researchCategory
          formattedData.clientId = formattedData.client?.id || 244
          delete formattedData.client

          const formattedRemoveNull = formattedData

          const updateData = {
            id: id,
            type: "MANAGER",
            opinion: excludePropertyArray(formattedRemoveNull, ["__typename", "name", "id", "lastUpdated"]),
          } as CreateOpinionInput
          createOpinionMutationFunction({ variables: {
              input: updateData,
            },
            optimisticResponse: {
              __typename: "Mutation",
              opinion: {
                ...currentOpinion
              }
            } as CreateOpinionMutation,
            awaitRefetchQueries: true,
            refetchQueries:[{query: ManagerWriteUpsDocument, variables:{ id: id, filters:{
              researchCategory: code,
              offset,
              limit: LOAD_LIMIT
            }}}]
            // update: (cache, { data }) => {
            //   const org: any = cache.readQuery({
            //     query: ManagerWriteUpsDocument,
            //     variables: { id: id },
            //   })
            //   let updatedOrg = iassign(
            //     org,
            //     (currentOrg) => _.get(currentOrg, "org.writeUps.opinions"),
            //     (selectedOrg) => {
            //       let rows = _.cloneDeep(selectedOrg)
            //       rows.push(data?.opinion)
            //       return rows
            //     }
            //   )
            //   cache.writeQuery({
            //     query: ManagerWriteUpsDocument,
            //     variables: { id: id },
            //     data: updatedOrg,
            //   })
            // },
          })
          .then(() => {
            setEditMode(false)
            setSaving(false)
          })
          .catch((err) => {
            setSaving(false)
            console.log("Error ", err)
          })
        }
      }

      // if any opinion changed, flag as edited
      if (!editedOpinionFlag && resultRef!.current?.state?.editedOpinion[identifier]){
        editedOpinionFlag = true
      }
    })
    // notes section
    // 1. delete notes
    let noteIdsArray = Array.from(noteIdsToDelete)
    if(noteIdsArray.length > 0) {
      Promise.all([
        ...noteIdsArray.map(noteID => deleteDnaNote({ variables: {input: {id: noteID}}}))
      ]).then(results => {
        if(results[0] && results[0].data){
          setEditMode(false)
          setSaving(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error deleting dna notes", err.message)
      })
    }

    // 2. update notes
    let currentNotes = _.cloneDeep(resultRef!.current?.state?.currentDnaNotes)
    let initialNotes = _.cloneDeep(resultRef!.current?.state?.initialDnaNotes)
    let editedNotes = _.cloneDeep(resultRef!.current?.state?.editedDnaNote)
    let editedNoteFlag:boolean = false
    Object.keys(editedNotes || {}).map((identifier) => {
      const [, stringId] = identifier.split("|")
      const noteId = parseInt(stringId)
      let initialDnaNote = initialNotes?.find(note=>note.id === noteId)
      
      let currentDnaNote = currentNotes?.find(note=>note.id === noteId)
      let dnaNoteEditedFlag = dnaNoteHasChanged(currentDnaNote, initialDnaNote)
      if(!initialDnaNote) {
        // new created
        if(currentDnaNote && dnaNoteEditedFlag && !!currentDnaNote.body){
          console.log("submit new note")
          let researchCode: ResearchCategoryCode = (currentDnaNote.researchCategories? (currentDnaNote.researchCategories[0]).code : code )|| code
          let updateData  = {
            body: currentDnaNote.body,
            confidential: false,
            orgTags: [id],
            researchCategories: [researchCode]
          } as CreateManagerNoteInput
          createDnaNote({ variables: {
            input: updateData,
          },
          optimisticResponse: {
            __typename: "Mutation",
            createManagerNote: {
              __typename: "ManagerNotePayload",
              note: currentDnaNote
            }
          },
          awaitRefetchQueries: true,
          refetchQueries:[{query: ManagerWriteUpsDocument, variables:{ id: id, filters:{
            researchCategory: code,
            offset,
            limit: LOAD_LIMIT
          }}}]
        })
        .then((result) => {
          console.log("success")
          setEditMode(false)
          setSaving(false)
        })
        .catch((err) => {
          setSaving(false)
          console.log("Error ", err)
        })
        }
      }else {
        if(currentDnaNote && dnaNoteEditedFlag){
          // updateDnaNote
          let patch  = {
            body: currentDnaNote.body,
            orgTags: [id],
          } as UpdateManagerNoteFields
          updateDnaNote({ variables: {
            input: {
              id: noteId,
              patch
            },
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateManagerNote: {
              __typename: "ManagerNotePayload",
              note: currentDnaNote
            }
          },
          awaitRefetchQueries: true,
          refetchQueries:[{query: ManagerWriteUpsDocument, variables:{ id: id, filters:{
            researchCategory: code,
            offset,
            limit: LOAD_LIMIT
          }}}]
        })
        .then((result) => {
          console.log("success")
          setEditMode(false)
          setSaving(false)
        })
        .catch((err) => {
          setSaving(false)
          console.log("Error ", err)
        })
        }else if(dnaNoteEditedFlag){
          // delete
        }else if (currentDnaNote) {
          // no material change
          console.log('no change, quit')
          return
        }else {
          // not possible
          console.log('no change but weird.')
        }
      }
      // if any note  changed, flag as edited
      if (!editedNoteFlag && resultRef!.current?.state?.editedDnaNote[identifier] && dnaNoteEditedFlag){
        editedNoteFlag = true
      }
    })
    // handle noChange submit, do not call api, quit editMode
    if ((!resultRef!.current?.state?.editedFact || !factualEditedFlag) && (!resultRef!.current?.state?.editedOpinion || !editedOpinionFlag) && (!resultRef!.current?.state?.editedDnaNote || !editedNoteFlag) && noteIdsArray.length < 1){
      console.log('no updates')
      handleEdit() // no change, like cancel edit.
      setSaving(false)
    }else {
      // setEditMode(false)
      // setSaving(false)
    }
  }

  const handleDelete = (writeUp: Fact | Opinion) => {
    if(!auth.checkPermissions(["edit:writeups"])){
      return
    }
    setDeleting(true)
    const deleteData = {
      id: writeUp.id,
    } as DeleteInput

    if(writeUp.__typename === "Fact"){
      deleteFactMutation({ variables: {
          input: deleteData,
        },
        // optimisticResponse: {
        //   __typename: "Mutation",
        //     deleteFact: {
        //       __typename: "Status",
        //       status: "test",
        //       message: ""
        //     }
        // },
        update: (cache, { data }) => {
          const org: any = cache.readQuery({
            query: ManagerWriteUpsDocument,
            variables: { id: id, filters:{
              researchCategory: code,
              offset,
              limit: LOAD_LIMIT
            }},
          })
          let updatedOrg = iassign(
            org,
            currentOrg => currentOrg?.org?.writeUps?.facts,
            (selectedOrg) => {
              let rows = _.cloneDeep(selectedOrg as any[])
              _.remove(rows, (v:Fact) => v?.id === writeUp.id)
              return rows
            }
          )
          cache.writeQuery({
            query: ManagerWriteUpsDocument,
            variables: { id: id, filters:{
              researchCategory: code,
              offset,
              limit: LOAD_LIMIT
            }},
            data: updatedOrg,
          })
          setDeleting(false)
        },
      })
    } else if (writeUp.__typename === "Opinion") {
      if (!(writeUp.id < 0)) {
        // exclude new created opinion
        deleteOpinionMutation({
          variables: {
            input: deleteData,
          },
          // optimisticResponse: {
          //   __typename: "Mutation",
          //     deleteOpinion: {
          //       __typename: "Status",
          //       status: "test",
          //       message: ""
          //     }
          // },
          update: (cache, { data }) => {
            const org: any = cache.readQuery({
              query: ManagerWriteUpsDocument,
              variables: { id: id, filters:{
                researchCategory: code,
                offset,
                limit: LOAD_LIMIT
              }},
            })
            let updatedOrg = iassign(
              org,
              currentOrg => currentOrg?.org?.writeUps?.opinions,
              (selectedOrg) => {
                let rows = _.cloneDeep(selectedOrg as any[])
                _.remove(rows, (v:Opinion) => v?.id === writeUp.id)
                return rows
              }
            )
            cache.writeQuery({
              query: ManagerWriteUpsDocument,
              variables: { id: id, filters:{
                researchCategory: code,
                offset,
                limit: LOAD_LIMIT
              }},
              data: updatedOrg,
            })
            setDeleting(false)
          },
        })
      }
    }
  }

  const handleManagerNoteDelete = (noteId: number) => {
    // work for dnaNotes only
    let newSet = _.clone(noteIdsToDelete)
    newSet.add(noteId)
    setNoteIdsToDelete(newSet)
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <RouteLeavingGuard
        when={editMode}
        navigate={(path) => history.push(path)}
      />
      { data && data.org && data.org.__typename == "Manager" &&
        <ButtonDropdown isOpen={historyDropdown} toggle={() => setHistoryDropdown(!historyDropdown)} className="mr-1">
          <DropdownToggle caret>
            <FontAwesomeIcon
              icon={["fas", "history"]}
              size="sm"
              className="mr-2"
            />
            View History
          </DropdownToggle>
          <DropdownMenu>
            {/* currently only show existing facts*/}
            {_.some(data.org.writeUps?.facts, (wu) => wu?.researchCategory?.code === code) && <DropdownItem key="fact" onClick={() => resultRef.current?.openHistory('fact')}>{WRITEUPS_ANCHOR_LIST[0].title}</DropdownItem>}
            {resultRef.current?.state.opinionList[`ss|244`] && <DropdownItem key={'ss|244'} onClick={() => resultRef.current?.openHistory("ss|244")}>{`Callan-Internal`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`ss|16467`] && <DropdownItem key={'ss|16467'} onClick={() => resultRef.current?.openHistory("ss|16467")}>{`Callan-IAG`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`ss|18737`] && <DropdownItem key={'ss|18737'} onClick={() => resultRef.current?.openHistory("ss|18737")}>{`Callan-UMA`}</DropdownItem>
            }
            {resultRef && _.map(resultRef.current?.state.sortedExistingOpinionIds, (opinionInfo, idx) => {
              let {clientId, opinionId} = opinionInfo
              let identifier = `ss|${clientId}`
              let opinions = resultRef.current?.state.opinionList[identifier]
              let clientName = ""
              if(opinions && opinions[0]?.client?.name){
                clientName = opinions[0]?.client?.name
              }
              if(clientId === `244` || clientId === `16467` || clientId === `18737`) {
                return <React.Fragment key={`${clientId}-${idx}`}></React.Fragment>
              }
              return <DropdownItem key={`${clientId}`} onClick={() => resultRef.current?.openHistory(identifier)}>{`${clientName}`}</DropdownItem>
            }
            )}
          </DropdownMenu>
        </ButtonDropdown>
      }
      {errorMessage && 
        <span className="text-accent-red py-2 ml-2 d-flex">
          <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
          &nbsp; {`Save Error: ${errorMessage}`}
        </span>
      }
      {auth.checkPermissions(["edit:writeups"]) &&
        <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={onSubmit} cancelEdit={handleEdit}/>
      }
    </div>
  )

  if (loading || currentUserLoading) {
    return (
      <>
        <Container fluid autoFocus>
          <Row>
            <Col>
              {heading}
              <div className='pane pane-table'>
                <PlaceHolder />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  if (error || currentUserError) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <div className="pane pane-table">
                <p>{error?.message}</p>
                <p>{currentUserError?.message}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  if (currentUserData && currentUserData.me && data && data.org && data.org.__typename == "Manager") {
    const currentUser = currentUserData.me as User
    const manager = data.org
    const factWriteUps = _.groupBy(manager.writeUps?.facts, "researchCategory.code")
    const opinionWriteUps = _.groupBy(manager.writeUps?.opinions, "researchCategory.code")
    let {legacyNotes, dnaNotes} = sortAndFilterNotes(manager?.notes)
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <Result
                facts={factWriteUps[code] as Fact[]}
                opinions={opinionWriteUps[code] as Opinion[]}
                dnaNotes={dnaNotes}
                legacyNotes={legacyNotes}
                editMode={editMode}
                managerType={managerType}
                code={code}
                auth={auth}
                ref={resultRef}
                currentUser={currentUser}
                handleDelete={(writeUp:Fact | Opinion) => handleDelete(writeUp)}
                handleManagerNoteDelete={(noteId:number) => handleManagerNoteDelete(noteId)}
                setEditMode={(value:boolean) => setEditMode(value)}
                clients={_.compact(clientData?.orgs?.filter((client: any) =>  client.type.code === "FUNDS")) as ClientSelection[]}
                deleting={deleting}
                forceUpdate={forceUpdateFlag}
                refetchQuery={() =>refetch({id, filters:{
                  researchCategory: code,
                  offset,
                  limit: LOAD_LIMIT
                }})}
                setOffset={setOffset}
                offset={manager?.notes?.length || 0}
                fetchMore={fetchMore}
                managerId={id}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  return <div>data doesn't exist</div>
}

interface Props {
  facts: Fact[]
  opinions: Opinion[]
  dnaNotes?: DnaNote[]
  legacyNotes?: LegacyNote[]
  editMode: boolean
  code: ResearchCategoryCode
  auth: any
  managerType?: ManagerType
  currentUser: User
  handleDelete: (writeUp:Fact | Opinion) => void
  handleManagerNoteDelete: (noteId: number) => void
  setEditMode: (value:boolean) => void
  clients: ClientSelection[]
  deleting: boolean
  forceUpdate: boolean
  refetchQuery: () => Promise<ApolloQueryResult<ManagerWriteUpsQuery>>
  fetchMore: any
  setOffset: (offset: number) => void
  offset: number
  managerId: number
  // onChange: (oldState:AssetsByClientQuery, newState:AssetsByClientQuery) => void
}

interface ResultState {
  currentFacts: Fact[]
  currentOpinions: Opinion[]
  currentDnaNotes: DnaNote[]
  editedFact: boolean
  editedOpinion: { [key: string]: boolean }
  editedDnaNote: { [key: string]: boolean } // {[`note|${id}`]: boolean}
  initialFacts: Fact[]
  initialOpinions: Opinion[]
  initialDnaNotes: DnaNote[]
  legacyNotes: LegacyNote[]
  historyModal: boolean
  historySelection: string
  historyId: number
  addOpinionDropdown: boolean
  addClientDropdown: boolean
  nextOpinionId: number
  opinionList: OpinionList
  sortedNewOpinionIds: {opinionId: number, clientId: string}[] // new added 
  sortedExistingOpinionIds: {opinionId: number, clientId: string}[] // existing ones
  tableLength: number
  activeAnchorLinks: {[id:string]: boolean}
  loadingMore: boolean
  offset: number
  showTableIncrement: boolean
}

interface OpinionList {
  [key: string]: Opinion[]
}

export const ClientsLookup = {
  client244: {
    id: 244,
    name: "Callan - Internal",
    __typename: "Manager",
  },
  client16467: {
    id: 16467,
    name: "Callan - IAG",
    __typename: "Manager",
  },
  client18737: {
    id: 18737,
    name: "Callan - UMA",
    __typename: "Manager",
  },
}

class Result extends Component<Props, ResultState> {
  constructor(props: Props) {
    super(props)
    this.openHistory = this.openHistory.bind(this)
    this.state = {
      currentFacts: this.props.facts || [],
      currentOpinions: this.props.opinions || [],
      currentDnaNotes: this.props.dnaNotes || [],
      editedFact: false,
      editedOpinion: {},
      editedDnaNote: {},
      initialFacts: this.props.facts || [],
      initialOpinions: this.props.opinions || [],
      initialDnaNotes: this.props.dnaNotes || [],
      legacyNotes: this.props.legacyNotes || [], // not editable
      historyModal: false,
      historySelection: "",
      historyId: 0,
      addOpinionDropdown: false,
      addClientDropdown: false,
      nextOpinionId: -1,
      opinionList: _.groupBy(
        this.props.opinions || [],
        // `ss|` here for avoiding reordering by client.id number
        (opinion) => `ss|${opinion?.client?.id}` || "0"
      ) as OpinionList,
      sortedNewOpinionIds: [] as {opinionId: number, clientId: string}[],
      sortedExistingOpinionIds: [] as {opinionId: number, clientId: string}[],
      tableLength: INITIAL_TABLE_LENGTH,
      activeAnchorLinks: {},
      loadingMore: false,
      offset: this.props.offset,
      showTableIncrement: true
    }
  }

  componentDidMount = () => {
    this.setSortAll(this.props.opinions, this.props.dnaNotes || [])
  }

  componentDidUpdate(prevProps:Props) {
    if(!_.isEqual(prevProps.facts, this.props.facts) || !_.isEqual(prevProps.opinions, this.props.opinions) || !_.isEqual(prevProps.dnaNotes, this.props.dnaNotes)  || !_.isEqual(prevProps.legacyNotes, this.props.legacyNotes)){
      this.setState({
        currentFacts: this.props.facts || [],
        currentOpinions: this.props.opinions || [],
        currentDnaNotes: this.props.dnaNotes || [],
        initialFacts: this.props.facts || [],
        initialOpinions: this.props.opinions || [],
        initialDnaNotes: this.props.dnaNotes || [],
        opinionList: _.groupBy(
          this.props.opinions || [],
          (opinion) => `ss|${opinion?.client?.id}` || "0"
        ) as OpinionList,
      }, ()=> {
        this.setSortAll(this.props.opinions, this.props.dnaNotes || [])}, )
    }else if (prevProps.editMode && !this.props.editMode) {
      if(this.props.forceUpdate) {
        this.props.refetchQuery().then(()=>this.setTableLength(5))
      }else {
        this.setTableLength(5)
      }
    }else if(!prevProps.editMode && this.props.editMode){
      this.addNote()
    }else if (!prevProps.editMode && !this.props.editMode){
      if(this.props.forceUpdate) {
        this.props.refetchQuery().then(()=>this.setTableLength(5))
      }
    }
  }

  sortOpinionIds = (opinions: Opinion[]) => {
    let opinionList = _.groupBy(
      opinions || [],
      (opinion) => `ss|${opinion?.client?.id}` || "0"
    ) as OpinionList
    let keys = Object.keys(opinionList)
    let allIdsMap = keys.map(identifier=>{
      let [, clientId] = identifier.split("|")
      let allVersions = opinionList[identifier] as Opinion[]
      return {clientId, clientName: allVersions[0]?.client?.name, opinionId:  allVersions[0].id}
    })
    let newOpinions = allIdsMap.filter(opinion=>opinion?.opinionId< 1)
    let existingOpinions = allIdsMap.filter(opinion=> opinion?.opinionId > 0)
    let sortedNewOpinions = _.sortBy(newOpinions, o=>o.opinionId)
    let sortedExistingOpinions = _.sortBy(existingOpinions,  o=>o.clientName)
    let sortedNewOpinionIds = sortedNewOpinions.map(o=>({opinionId: o.opinionId, clientId: o.clientId}))
    let sortedExistingOpinionIds = sortedExistingOpinions.map(o=>({opinionId: o.opinionId, clientId: o.clientId}))
    return {sortedNewOpinionIds, sortedExistingOpinionIds}
  }

  setSortOpinionIds = (opinions: Opinion[]) => {
    let {sortedNewOpinionIds, sortedExistingOpinionIds} = this.sortOpinionIds(opinions)
    this.setState({
      sortedNewOpinionIds,
      sortedExistingOpinionIds
    })
  }

  setSortAll = (opinions: Opinion[], dnaNotes?: DnaNote[]) => {
    let {sortedNewOpinionIds, sortedExistingOpinionIds} = this.sortOpinionIds(opinions)
    let currentDnaNotes = dnaNotes || this.state.currentDnaNotes
    let {legacyNotes} = this.props
    let {tableLength} = this.state
    let dataLength = (currentDnaNotes || []).length + (legacyNotes || []).length

    let initialTableLength = Math.min(dataLength, tableLength)
    this.setState({
      sortedNewOpinionIds,
      sortedExistingOpinionIds,
      tableLength: initialTableLength,
      currentDnaNotes,
      showTableIncrement: dataLength >= tableLength
    })
  }

  resetForm = () => {
    this.setState({
      currentFacts: this.state.initialFacts,
      currentOpinions: this.state.initialOpinions,
      currentDnaNotes: this.props.dnaNotes || [],
      editedFact: false,
      editedDnaNote: {},
      editedOpinion: {},
      opinionList: _.groupBy(
        this.state.initialOpinions || [],
        (opinion) => `ss|${opinion?.client?.id}` || "0"
      ) as OpinionList,
    }, ()=>this.setSortAll(this.state.initialOpinions, this.state.initialDnaNotes))
  }

  handleFactChange = (
    value: any,
    property: string,
    id: number
  ) => {
    if(!this.props.editMode) {
      return
    }
    if(!this.state.editedFact) {
      this.handleFocus("fact")
      return
    }
    let newState = iassign(
      this.state.currentFacts,
      currentState => currentState,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable)
        var selectedRow = _.find(rows, (o) => {return (o.id === id)})
        if(selectedRow){
          _.set(selectedRow, property, value);
        }
        return rows
      }
    )
    this.setState({ currentFacts: newState, editedFact: true})
  }

  handleOpinionChange = (
    value: any,
    property: string,
    id: number,
    identifier: string
    ) => {
      if(!this.props.editMode) {
        return
      }else if(!this.state.editedOpinion[identifier]) {
        this.handleFocus(identifier)
        return
      }
      let editedOpinion = {} as {[key: string]: boolean}
      let newState = iassign(
        this.state.currentOpinions,
        currentState => currentState,
        selectedTable => {
          let rows = _.cloneDeep(selectedTable)
          var selectedRow = _.find(rows, (o) => {return (o.id === id)})
          if(selectedRow){
            _.set(selectedRow, property, value);
          }
        editedOpinion = iassign(
          this.state.editedOpinion,
          [`ss|${selectedRow?.client?.id}`],
          () => true
        )
        return rows
      }
    )
    this.setState({ 
      currentOpinions: newState, 
      editedOpinion, 
      opinionList: _.groupBy(
        newState || [],
        (opinion) => `ss|${opinion?.client?.id}` || "0"
        ) as OpinionList
    }, ()=>this.setSortOpinionIds(newState))
  }

  handleNoteChange = (
    value:string | PersonSelection,
    property: string,
    id: number
  ) => {
    if(!this.props.editMode) {
      return
    }else {
      // ???
      let editedNote = {} as {[key: string]: boolean}
      let newState = iassign(
        this.state.currentDnaNotes,
        currentState => currentState,
        selectedTable => {
          let rows = _.cloneDeep(selectedTable)
          var selectedRow = _.find(rows, (o) => {return (o.id === id)})
          if(selectedRow){
            _.set(selectedRow, property, value);
          }
          editedNote = iassign(
          this.state.editedDnaNote,
          [`note|${selectedRow?.id}`],
          () => true
          )
          return rows
      })
      this.setState({
        currentDnaNotes: newState,
        editedDnaNote: editedNote
      })
    }
  }

  handleFocus = (type: string) => {
    // this function works for first focus, to switch to currentUser and current date
    if(!this.props.editMode) {
      return
    }
    if(type=== "fact" && !this.state.editedFact){
      let rows = _.cloneDeep(this.state.currentFacts)
      let newRow = _.cloneDeep(this.getLatest(rows)) as Fact
      if(newRow){
        newRow.author = this.props.currentUser?.person || newRow.author
        newRow.date = moment().format(DATE_API_FORMAT)
        newRow.lastUpdated = moment().format("YYYY-MM-DD HH:mm:ss")
        newRow.id = -1
        newRow.__typename = "Fact"
      } else {
        newRow = {
          __typename: "Fact",
          id: -1,
          author: this.props.currentUser.person,
          date: moment().format(DATE_API_FORMAT),
          lastUpdated: moment().format("YYYY-MM-DD HH:mm:ss"),
          text: "",
          bullets: "",
          researchCategory: {
            __typename: "ResearchCategoryLookup",
            value: "",
            code: this.props.code
          }
        } as Fact
      }
      rows.push(newRow)
      this.setState({currentFacts: rows, editedFact: true})
    } else if (!this.state.editedOpinion[type] && type !== "fact"){
      // opinion 
      let rows = _.cloneDeep(this.state.currentOpinions)
      let newRow = _.cloneDeep(this.getLatest(this.state.opinionList[type])) as Opinion
      if(newRow){
        newRow.__typename = "Opinion"
        newRow.author = this.props.currentUser.person
        newRow.date = moment().format(DATE_API_FORMAT)
        newRow.lastUpdated = moment().format("YYYY-MM-DD HH:mm:ss")
        newRow.id = this.state.nextOpinionId // new version needs new id
      }else {
        const [ , clientId] = type.split("|") 
        const client = _.find(ClientsLookup, ["id", parseInt(clientId)]) || {id: this.state.nextOpinionId, name: "Choose Client", __typename: "Manager"}
        newRow = {
        __typename: "Opinion",
        id: this.state.nextOpinionId,
        author: this.props.currentUser.person,
        date: moment().format(DATE_API_FORMAT),
        lastUpdated: moment().format("YYYY-MM-DD HH:mm:ss"),
        meritsText: "",
        meritsBullets: "",
        considerationsText: "",
        considerationsBullets: "",
        status: {
          __typename: "statusLookup",
          code: "" as StatusCode, // no default value
          value: "Please Select",
        },
        researchCategory: {
          __typename: "ResearchCategoryLookup",
          value: "",
          code: this.props.code
        },
        client: client
        }
      }
      rows.push(newRow)
      const editedOpinion = iassign(
        this.state.editedOpinion,
        [type],
        () => true
      )
      this.setState({
        currentOpinions: rows,
        editedOpinion: editedOpinion,
        nextOpinionId: this.state.nextOpinionId - 1,
        opinionList: _.groupBy(
          rows|| [],
          (opinion) => `ss|${opinion?.client?.id}` || "0"
        ) as OpinionList,
      }, ()=>this.setSortOpinionIds(rows))
    } else if(type !== "fact"){
      console.log('not a new ', type)
    }
  }

  handleNoteFocus = (type: string) => {
    // this function works for first focus, to switch to currentUser and current date
    if(!this.props.editMode) {
      return
    }
    if(!this.state.editedDnaNote[type]) {
      const [, id] = type.split("|")
      let rows = _.cloneDeep(this.state.currentDnaNotes)
      let dnaNotes = _.filter(rows, note=>note.legacy === false)
      let note = dnaNotes.find(note=>note.id === parseInt(id)) // existing 
      if(note) {
        note.author = this.props.currentUser.person
        // NOTE: updateDate here is in fact DateTime
        note.updateDate = moment().format("YYYY-MM-DD HH:mm:ss")
        rows = rows.map(el=> {
          if(el.id === parseInt(id)) {
            return note as DnaNote
          }
          return el
        })
      }else {
        note = {
          __typename: "ManagerNote",
          id: this.state.nextOpinionId,
          author: this.props.currentUser.person,
          createDate: moment().format(DATE_API_FORMAT),
          title: "",
          body: "",
          legacy: false,
          researchCategories: [{
            __typename: "ResearchCategoryLookup",
            value: "",
            code: this.props.code
          }
          ],
        }
        rows.unshift(note)
      }
      
      const editedDnaNote = iassign(
        this.state.editedDnaNote,
        [id],
        () => true
      )
      this.setState({
        currentDnaNotes: rows,
        editedDnaNote: editedDnaNote,
        nextOpinionId: this.state.nextOpinionId - 1,
        tableLength: this.state.tableLength + 1
      })
    }else {
      console.log('not a new ', type)
    }
  }

  createNewClient = () => {
    this.handleFocus(`ss|${this.state.nextOpinionId}`)
  }

  addNote = () => {
    console.log('add note')
    this.handleNoteFocus(`note|${this.state.nextOpinionId}`)
  }

  removeNote = (noteId: number) => {
    let {currentDnaNotes} = this.state
    let newNotes = _.filter(currentDnaNotes, note=>note.id !== noteId)
    this.setState({currentDnaNotes: newNotes}, ()=>this.props.handleManagerNoteDelete(noteId))
  }

  openHistory = (
    type:string
  ) => {
    if(type === "fact"){
      this.setState({historyId: this.getLatest(this.state.currentFacts)?.id || 0, historySelection: "fact", historyModal: true})
    } else {
      this.setState({historyId: this.getLatest(this.state.opinionList[type])?.id || 0, historySelection: type, historyModal: true})
    }
    this.forceUpdate()
  }

  getLatest = (list: Fact[] | Opinion []) => {
    if(!list || _.isUndefined(list)||list.length === 0){
      return undefined
    }
    if(list[0].__typename === "Fact"){
      const factList = list as Fact[]
      return _.maxBy(factList, (f) => moment(f.lastUpdated).valueOf()) as Fact
    } else if (list[0].__typename === "Opinion"){
      const opinionList = list as Opinion[]
      return _.maxBy(opinionList, (f) => moment(f.lastUpdated).valueOf()) as Opinion
    }
  }

  // find the latest saved version before current round of edit, if no previous version return current edit version 
  getInitial = (list: Fact[] | Opinion []) => {
    if(!list || _.isUndefined(list)||list.length === 0){
      return undefined
    }
    if(list[0].__typename === "Fact"){
      const factList = list as Fact[]
      return _.maxBy(factList, (f) => f.id) as Fact
    } else if (list[0].__typename === "Opinion"){
      const opinionList = list as Opinion[]
      return _.maxBy(opinionList, (f) => f.id) as Opinion
    }
  }

  handleEdit = () => {
    this.props.setEditMode(!this.props.editMode)
  }

  opinionComponent = (opinionList:OpinionList, identifier: string, subtype?: "header"|"editor") => {
    const foundOpinions = opinionList[identifier] || []
    const currentOpinion = this.getLatest(foundOpinions) as Opinion
    let version = foundOpinions.length
    if(identifier === "ss|244") {
      // split header and editor for 244 callan representative opinion
      return (
        <WriteUpComponent
          key={`${identifier}-${subtype}`}
          type="callanRepOpinion"
          subtype={subtype || "header"}
          writeUp={currentOpinion}
          editMode={this.props.editMode}
          edited={this.state.editedOpinion[identifier] || false}
          version={version}
          handleUpdate={(value: any,property: string)=>this.handleOpinionChange(value,property,currentOpinion?.id || 0,identifier)}
          handleFocus={()=>this.handleFocus(identifier)}
          openHistory={()=>this.openHistory(identifier)}
          currentUser={this.props.currentUser}
      />
      )
    }
    if(!currentOpinion) return(<></>)
    return(
      <WriteUpComponent
        key={`opinion-${currentOpinion?.id || identifier}-version-${version}`}
        type="opinion"
        writeUp={currentOpinion}
        editMode={this.props.editMode}
        edited={this.state.editedOpinion[identifier] || false}
        version={version}
        handleUpdate={(value: any,property: string)=>this.handleOpinionChange(value,property,currentOpinion?.id || 0,identifier)}
        handleFocus={()=>this.handleFocus(identifier)}
        openHistory={()=>this.openHistory(identifier)}
        currentUser={this.props.currentUser}
        clients={this.props.clients} // needs clients list for all non-244
        clientList={true}
      />
    )
  }

  setTableLength = (length: number) => this.setState({tableLength: length})

  noteComponents = () => {
    let {currentDnaNotes, legacyNotes, tableLength} = this.state
    // show DnaNote first, only show legacy notes if there's more space.
    let currentDnaLength = currentDnaNotes.length
    let showDnaLength = Math.min(tableLength, currentDnaLength)
    let showLegacyLength = Math.min(Math.max(tableLength - currentDnaLength, 0), legacyNotes.length)
    return (
      <div className="writeup-note-contents" key={currentDnaNotes.length}>
        {currentDnaNotes.slice(0, showDnaLength).map(note=>this.dnaNoteComponent(note))}
        {legacyNotes.slice(0, showLegacyLength).map(note=>this.legacyNoteComponent(note))}
      </div>)
  }

  dnaNoteComponent = (note: DnaNote) => {
    // return <div>Dna Notes Under Construction</div>
    if(!note) return <></>
    let id = note?.id || this.state.nextOpinionId
    return (
      <WriteUpComponent
        key={`dnaNote-${id}`}
        type="dnaNote"
        writeUp={note}
        editMode={this.props.editMode}
        edited={false}
        version={0}
        handleUpdate={(value: any,property: string)=>this.handleNoteChange(value, property, id)}
        handleManagerNoteDelete={()=>this.removeNote(id)}
        handleFocus={()=>this.handleNoteFocus(`note|${id}`)}
        openHistory={()=>{}}
        currentUser={this.props.currentUser}
        clientList={false}
      />
    )
  }

  legacyNoteComponent = (note: LegacyNote) => {
    if(!note) return <></>
    return (
      <WriteUpComponent
        key={`legacy-${note?.id}`}
        type="legacyNote"
        writeUp={note}
        editMode={this.props.editMode}
        edited={false}
        version={0}
        handleUpdate={()=>{}}
        handleFocus={()=>{}}
        openHistory={()=>{}}
        currentUser={this.props.currentUser}
        clientList={false}
      />
    )
  }

  getWriteUpsAnchorLists = () => {
    if (this.props.editMode) {
      return WRITEUPS_ANCHOR_LIST
    }
    let {currentFacts, currentDnaNotes, legacyNotes, opinionList} = this.state
    let factualLinkShow = currentFacts && currentFacts.length > 0
    let callanRepOpinion = opinionList[`ss|244`]
    let callanRepOpinionLinkShow = callanRepOpinion && callanRepOpinion.length > 0
    let opinionListLength = Object.keys(opinionList).length
    let clientOpinionLinkShow = callanRepOpinionLinkShow? (opinionListLength > 1) : (opinionListLength > 0)
    let privateNoteLinkShow = !_.isEmpty(currentDnaNotes) || !_.isEmpty(legacyNotes)

    let DictionaryShowMap: {[anchorId: string]: boolean} = {
      [WRITEUPS_ANCHOR_LIST[0].anchorId]: factualLinkShow,
      [WRITEUPS_ANCHOR_LIST[1].anchorId]: callanRepOpinionLinkShow,
      [WRITEUPS_ANCHOR_LIST[2].anchorId]: clientOpinionLinkShow,
      [WRITEUPS_ANCHOR_LIST[3].anchorId]: privateNoteLinkShow

    }
    return WRITEUPS_ANCHOR_LIST.map((anchorLink) =>{
      const {anchorId} = anchorLink
      const showFlag = DictionaryShowMap[anchorId]
      return {...anchorLink, hidden: !showFlag} 
    })
  }

  toggleActiveAnchorLink = (id:string) => {
    let {activeAnchorLinks} = this.state
    let currentIdState = !!activeAnchorLinks[id]
    let newState = {
      ...activeAnchorLinks, [id]: !currentIdState
    }
    this.setState({activeAnchorLinks: newState})
  }

  getNoNoteCover = (type: "write-ups" | "notes" | "all") => {
    let displayMap = {
      "write-ups": {"content": "opinions or factual information","height": 220},
      "notes": {"content": "private notes","height": 220},
      "all": {"content": "opinions or factual information","height": 700}
    }
    let {setEditMode} = this.props
    let content = displayMap[type].content
    let height = displayMap[type].height
    return (
      <>
        {(type === "write-ups") && (<div className="writeup-fact-container">
          <div className="d-flex justify-content-between align-items-center px-2 writeup-fact-header-container">
            <h3 className="writeup-fact-header headline my-3">Write-ups</h3>
          </div>
        </div>)}
        <div className='d-flex flex-column w-100 align-items-center justify-content-center background-gray-10 mt-2' style={{ height }}>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Button onClick={() => setEditMode(true)} color="primary" className="d-flex">
                Edit
                <FontAwesomeIcon
                  icon="pen"
                  size="xs"
                  className="ml-2"
                />
              </Button>
              <p className="text-center d-flex mt-2 mb-0">
                No {`${content}`} has been entered.
              </p>
              <p>
                <span className="fake-link" onClick={() => setEditMode(true)}>Click edit</span> to add some.
              </p>
            </div>
        </div>
      </>
    )
  }

  loadMore = () =>{
    if(this.state.loadingMore){
      return
    }
    this.setState({loadingMore: true})
    let currentVariables = {
      id: this.props.managerId,
      filters:{
        offset: this.state.offset,
        limit: LOAD_LIMIT,
        researchCategory: this.props.code
      }
    }
    this.props.fetchMore({
      variables: currentVariables,
      updateQuery: (previousResult:ManagerWriteUpsQuery, { fetchMoreResult } :any) => {
        if(!fetchMoreResult){
          return previousResult
        }
        const {legacyNotes: previousLegacyNotes,  currentDnaNotes: previousCurrentDnaNotes, initialDnaNotes: previousInitialDnaNotes} = this.state
        const newNotes =((fetchMoreResult.org as Manager)?.notes || []) as ManagerNoteFragment[]
        let newLength = newNotes.length
        const {legacyNotes: newLegacyNotes,  dnaNotes: newDnaNotes} = sortAndFilterNotes(newNotes)
        const returnedState = {
          legacyNotes: [
            ...previousLegacyNotes, ...newLegacyNotes
          ],
          currentDnaNotes: [
            ...previousCurrentDnaNotes,  ...newDnaNotes
          ],
          initialDnaNotes: [
            ...previousInitialDnaNotes, ...newDnaNotes
          ],
          offset: this.props.offset + newNotes.length
        }
        this.setState({
          ...returnedState,
          loadingMore: false,
          tableLength: returnedState.legacyNotes.length + returnedState.initialDnaNotes.length,
          offset: returnedState.legacyNotes.length + returnedState.initialDnaNotes.length,
          showTableIncrement: newLength === LOAD_LIMIT
        })
        
        return previousResult; // ???
      }
    })
  }

  render() {
    const {facts, opinions , editMode, dnaNotes, legacyNotes} = this.props
    const {tableLength, showTableIncrement} = this.state
    const currentFact = this.getLatest(this.state.currentFacts) as Fact
    const {opinionList, sortedExistingOpinionIds, sortedNewOpinionIds} = this.state
    const writeUpList =
      this.state.historySelection === "fact"
        ? this.state.currentFacts
        : opinionList[this.state.historySelection]
    let showNoWriteUpCover = !editMode && _.isEmpty(facts) && _.isEmpty(opinions)
    let showNoPrivateNoteCover = !editMode && _.isEmpty(dnaNotes) && _.isEmpty(legacyNotes)
    let showAllContentCover = showNoPrivateNoteCover && showNoWriteUpCover
    let noAllContentCover = this.getNoNoteCover("all")
    let noWriteUpCover = this.getNoNoteCover('write-ups')
    let noPrivateNotesCover = this.getNoNoteCover('notes')
    return (
      <div className={classnames("pane pane-table", {cover: showAllContentCover})}>
        <WriteUpHistory
          auth={this.props.auth}
          editMode={this.props.editMode}
          historyId={this.state.historyId}
          show={this.state.historyModal}
          toggle={(value: boolean) => this.setState({ historyModal: value })}
          selectWriteUp={(id: number) => this.setState({ historyId: id })}
          writeUpList={writeUpList}
          handleDelete={(writeUp: Fact | Opinion) => {
            this.props.handleDelete(writeUp)
          }}
          deleting={this.props.deleting}
        />
        <Row>
          {showAllContentCover? (<></>): 
          (<Col md={2} key={`left-column`} className="">
            <AnchorLinkMenu list={this.getWriteUpsAnchorLists()} linkProps={{offset: -50}}
            handleSetActive={this.toggleActiveAnchorLink}
            handleSetInactive={this.toggleActiveAnchorLink}
            />
          </Col>)
          }
          <Col md={showAllContentCover? 12: 9} key={`right-Column`}>
            {showAllContentCover? 
            noAllContentCover:
          (<>
            {showNoWriteUpCover?
              noWriteUpCover:
            <>
            <WriteUpComponent
              key={"fact"}
              type="fact"
              writeUp={currentFact}
              editMode={this.props.editMode}
              edited={this.state.editedFact}
              version={this.state.currentFacts.length}
              handleUpdate={(value: any,property: string)=>this.handleFactChange(value,property,currentFact?.id || -1)}
              handleFocus={()=>this.handleFocus("fact")}
              openHistory={()=>this.openHistory("fact")}
              currentUser={this.props.currentUser}
            />
            <div className="writeup-opinion-container" key="callan-rep"
            id={WRITEUPS_ANCHOR_LIST[1].anchorId}>
              <div className="d-flex justify-content-between align-items-center px-2 writeup-opinion-header-container">
                <h3 className="headline my-3 writeup-opinion-header">{WRITEUPS_ANCHOR_LIST[1].title}
                </h3>
               {this.opinionComponent(opinionList, "ss|244", "header")}
              </div>
              {this.opinionComponent(opinionList, "ss|244", "editor")}
            </div>
            <div className="writeup-opinion-container" key="client-opinions"
            id={WRITEUPS_ANCHOR_LIST[2].anchorId}>
              <div className="d-flex justify-content-between align-items-center px-2 writeup-opinion-header-container mb-1">
                <h3 className="writeup-opinion-header headline my-3">{WRITEUPS_ANCHOR_LIST[2].title}</h3>
                {this.props.editMode &&
                <Button 
                  className="float-right my-1 mr-1 blue-text"
                  color="secondary"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.createNewClient()}
                  }>Add Client</Button>
                }
              </div>
              {this.opinionComponent(opinionList, "ss|16467")}
              {this.opinionComponent(opinionList, "ss|18737")}
              {sortedNewOpinionIds.map((opinion, idx) => {
                let {opinionId, clientId} = opinion
                if(clientId === "244" || clientId === "16467" || clientId === "18737") return (<React.Fragment key={`other-${opinionId}`}></React.Fragment>)
                return <React.Fragment key={`other-${opinionId}`}>{this.opinionComponent(opinionList, `ss|${clientId}`)}</React.Fragment>
              })}
              {sortedExistingOpinionIds.map((opinion, idx) => {
                let {opinionId, clientId} = opinion
                if(clientId === "244" || clientId === "16467" || clientId === "18737") return (<React.Fragment key={`other-${opinionId}`}></React.Fragment>)
                return <React.Fragment key={`other-${opinionId}`}>{this.opinionComponent(opinionList, `ss|${clientId}`)}</React.Fragment>
              })}
            </div>
            </>}
                <div className="writeup-note-container private-note" key="private-notes" id={WRITEUPS_ANCHOR_LIST[3].anchorId}>
                  <div className="d-flex justify-content-between align-items-center px-2 writeup-note-header-container internal-note">
                    <h3 className="headline my-3 writeup-note-header">{WRITEUPS_ANCHOR_LIST[3].title}</h3>
                    <div className="text-center internal-use"> {"INTERNAL USE ONLY"}</div>
                    {this.props.editMode ?
                      (<Button 
                        className="float-right my-1 blue-text mr-1"
                        color="secondary"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.addNote()}
                        }>Add Note
                      </Button>) : (<div></div>)
                    }
                    {/* add note button*/}
                  </div>
                {showNoPrivateNoteCover?
                  noPrivateNotesCover:
                <>
                  {this.noteComponents()}
                  <div className="row justify-content-center">
                    {showTableIncrement && (<Button
                      color="secondary"
                      className="mt-2"
                      disabled={!showTableIncrement || this.state.loadingMore}
                      onClick={() => {
                        let newLength = tableLength + TABLE_INCREMENT
                        if (newLength < this.state.initialDnaNotes.length + this.state.legacyNotes.length){
                          this.setTableLength(tableLength + TABLE_INCREMENT)
                        }else {
                          console.log('load more')
                          this.loadMore()
                        }
                      }}>
                      {"Show more notes"}
                      {this.state.loadingMore && (<FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />)}
                    </Button>)}
                    {!showTableIncrement && !this.state.loadingMore && (<Button
                      color="secondary"
                      className="mt-2"
                      disabled={!showTableIncrement}>
                      {"No more notes"}
                    </Button>)}
                  </div>
                 {tableLength < INITIAL_TABLE_LENGTH && (<div className="dummy-block"></div>)}
                </>
                }
              </div>
            </>)}
          </Col>
        </Row>
      </div>
    )
  }
}

export default ManagerWriteUpsSwitcher

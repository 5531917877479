import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import EditButtons from "../../ui/EditButtons"
import exportTables from "../../../helpers/exportTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

interface HistoryModalProps {
  isOpen: boolean
  title: string
  toggle: () => void
  handleSubmit?: () => void
  saving?: boolean
  showDownload?: boolean
  xlSize?: boolean
}

export const HistoryModal: React.FC<HistoryModalProps> = ({
  isOpen,
  title,
  toggle,
  children,
  handleSubmit,
  saving,
  showDownload,
  xlSize,
}) => {
  return (
    <Modal size={xlSize ? "xl" : "lg"} isOpen={isOpen} toggle={toggle} zIndex={1500} style={{maxHeight: "90vh"}}>
      <ModalHeader className="fee-modal-header full-width-header">
        <div className="d-flex justify-content-between">
          <div>
            History | {title}
          </div>
          <div onClick={() => toggle()}>
            <FontAwesomeIcon
              icon="times"
              className="ml-auto"
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="">
        <div className="overflow-y-auto px-3">
          {children}
        </div>
      </ModalBody>
      <ModalFooter className={classNames({"justify-content-between": showDownload})}>
        {showDownload &&
          <Button color="link btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
            History
            <FontAwesomeIcon icon="download" size="sm" className="ml-2 text-callan-blue" />
          </Button>
        }
        {handleSubmit && (
          <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => toggle()} saving={saving || false} onSubmit={handleSubmit} disableOnError={true}/>
        )}
        {!handleSubmit && <Button color="primary" onClick={toggle}>Done</Button>}
      </ModalFooter>
    </Modal>
  )
}
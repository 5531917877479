import { QueryResult } from '@apollo/client'
import { useApolloClient } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import classnames from 'classnames'
import React, { Component, useEffect } from 'react'
import { Redirect, Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, NavItem, NavLink, UncontrolledAlert } from 'reactstrap'
import _ from "lodash"

import Auth from '../../Auth/Auth'
import { appProps } from '../../queries/extended/appProps'
import { ManagerRouteProps } from '../../queries/extended/types/Managers'
import { MANAGER_DETAIL_QUERY } from '../../queries/Org'
import { ManagerType } from "../../helpers/helpers"
import {
  ManagerDetails,
  ManagerDetails_overview_Manager,
  ManagerDetailsVariables,
} from '../../queries/types/ManagerDetails'
import { CrumbRoute } from '../../routes/crumb-route'
import { managerTypeBreakdown } from "../../helpers/helpers"
import { WithTopNav } from '../ui/LayoutWrapper'
import LoadingOverlay from '../ui/Loading'
import QueryManagerAssets from './ManagerAssets'
import QueryManagerDocuments from './ManagerDocuments'
import ManagerInteractions from './ManagerInteractions'
import ManagerProfile from './ManagerProfile'
import ManagerOverview from './ManagerOverview'
import ManagerPeople from './ManagerPeople'
import ManagerProducts from './ManagerProducts'
import ManagerWriteUps from './ManagerWriteUps'
import { registerRecentManager } from "../../helpers/session"
import ErrorDisplay from '../Shared/ErrorDisplay'



const getActiveTab = (props: any, url: string) => {
  if (props.match.url.indexOf(`${url}/profile`) === 0) {
    return 'Profile'
  }
  if (props.match.url.indexOf(`${url}/overview`) === 0) {
    return 'Overview'
  }
  if (props.match.url.indexOf(`${url}/products`) === 0) {
    return 'Products'
  }
  if (props.match.url.indexOf(`${url}/people`) === 0) {
    return 'People'
  }
  if (props.match.url.indexOf(`${url}/assets`) === 0) {
    return 'Assets'
  }
  if (props.match.url.indexOf(`${url}/interactions`) === 0) {
    return 'Interactions'
  }
  if (props.match.url.indexOf(`${url}/write-ups`) === 0) {
    return 'Write-ups'
  }
  if (props.match.url.indexOf(`${url}/documents`) === 0) {
    return 'Documents'
  }
  return 'Menu'
}

const ManagerDetailComponent: React.FC<appProps &
  ManagerRouteProps &
  ManagerOverviewProps> = props => {
  const history = useHistory()
  const { match, auth } = props
  const {data, ...propsWithoutData} = props
  if (!data) {
    return (
      <Container fluid>
        <UncontrolledAlert color="danger">
          <h4>404: Org Not Found</h4>
          <p>No Org exists with this id.</p>
        </UncontrolledAlert>
      </Container>
    )
  }

  if (data.__typename !== 'Manager') {
    return (
      <Container fluid>
        <UncontrolledAlert color="danger">
          <h4>400: Org Not Supported</h4>
          <p>Org type {data.__typename} is not currently supported</p>
        </UncontrolledAlert>
      </Container>
    )  }
  const { type, subtype } = match.params
  const urlWithType = subtype
    ? match.url.slice(0, -subtype.length - 1)
    : match.url
  const urlWithoutType = type
    ? urlWithType.slice(0, -type.length - 1)
    : urlWithType

  const managerTypes = _.get(data, "managerTypes", [])
  const managerType:ManagerType = managerTypeBreakdown(managerTypes)

  registerRecentManager(data.id, data.name)

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline">{data.name}
            {data.isInactive && (
              <span className="inactive-tag">Inactive</span>
            )}
            </h2>
            <Nav className="sub-nav sub-nav-primary collapsed" tabs role="group" aria-label="Manager Subpage">
              <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                <DropdownToggle caret>
                  { getActiveTab(props, urlWithoutType) }
                </DropdownToggle>
                <DropdownMenu>
                  {/* temp fix for CAL-1855*/}
                {auth.checkPermissions(["view:interactions"]) &&
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/profile`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/profile`)}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                }
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/overview`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/overview`)}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/products`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/products`)}
                    >
                      Products
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/people`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/people`)}
                    >
                      People
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/assets`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/assets`)}
                    >
                      Assets
                    </NavLink>
                  </NavItem>
                  {auth.checkPermissions(["view:interactions"]) &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/interactions`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/interactions`)}
                      >
                        Interactions
                      </NavLink>
                    </NavItem>
                  }
                  {/* comment in CAL-1707 Client role doesn't see write-up tab */}
                  {auth.checkPermissions(["edit:writeups"]) &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/write-ups`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/write-ups`)}
                      >
                        Write-ups
                      </NavLink>
                    </NavItem>
                  }
                  {(auth.checkPermissions(["view:documents"]) || auth.checkPermissions(["view:my_documents"]) || auth.checkPermissions(["view:manager_documents"]) || auth.checkPermissions(["view:all_documents"])) &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/documents`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/documents`)}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        {auth.checkPermissions(["view:interactions"]) &&
        <CrumbRoute
          title="profile"
          path={`${urlWithoutType}/profile`}
          render={() => (
            <ManagerProfile
              id={data.id}
              managerType={managerType}
              auth={auth}
            />
          )}
        />
        }
        <CrumbRoute
          title="products"
          path={`${urlWithoutType}/products`}
          render={() => (
            <ManagerProducts
              data={data as ManagerDetails_overview_Manager}
              managerType={managerType}
              {...propsWithoutData}
            />
          )}
        />
        <CrumbRoute
          title="people"
          path={`${urlWithoutType}/people`}
          render={() => (
            <ManagerPeople
              id={data.id}
              managerType={managerType}
              auth={auth}
            />
          )}
        />
        <CrumbRoute
          title="assets"
          path={`${urlWithoutType}/assets`}
          render={() => (
            <QueryManagerAssets
              data={data as ManagerDetails_overview_Manager}
              managerType={managerType}
              {...propsWithoutData}
            />
          )}
        />
        {auth.checkPermissions(["view:interactions"]) &&
          <CrumbRoute
            title="interactions"
            path={`${urlWithoutType}/interactions/:id([A-Za-z0-9]+)?`}
            render={() => <ManagerInteractions managerId={data.id} {...props}/>}
          />
        }
        {auth.checkPermissions(["view:writeups"]) &&
          <CrumbRoute
            title="write-ups"
            path={`${urlWithoutType}/write-ups/:subtype(overall|people)?`}
            render={() => <ManagerWriteUps id={data.id} auth={auth} />}
          />
        }
        {(auth.checkPermissions(["view:documents"]) || auth.checkPermissions(["view:my_documents"]) || auth.checkPermissions(["view:manager_documents"]) || auth.checkPermissions(["view:all_documents"])) &&
          <CrumbRoute
            title="documents"
            path={`${urlWithoutType}/documents`}
            render={() => <QueryManagerDocuments managerId={data.id} {...props} />}
          />
        }
        <CrumbRoute
          exact
          title="overview"
          path={`${urlWithoutType}/overview/:subtype(summary|ownership|employees|governance|esg|diversity)?`}
          render={() => (
            <ManagerOverview
              data={data as ManagerDetails_overview_Manager}
              managerType={managerType}
              {...propsWithoutData}
            />
          )}
        />
        <Redirect to={`${urlWithoutType}/overview/summary`} />
      </Switch>
    </>
  )
}
class QueryManagerDetails extends Component<
  ManagerRouteProps & Partial<ManagerDetailsVariables> & appProps
> {
  render() {
    const id = parseInt(this.props.match.params.managerId)
    const { auth, history, location, match } = this.props
    const newProps = { auth, history, location, match }
    return (
      <Query<ManagerDetails, ManagerDetailsVariables>
        query={MANAGER_DETAIL_QUERY}
        variables={{ id }}
        fetchPolicy="cache-and-network"
        notifyOnNetworkStatusChange={true}
      >
        {params => {
          const allProps = { ...params, ...newProps }
          if (!params) {
            return <>Null</>
          }
          return <>{ManagerDetailsOverview(allProps)}</>
        }}
      </Query>
    )
  }
}

interface authProps {
  auth: Auth
}

interface ManagerOverviewQueryChildren {
  (
    result: QueryResult<ManagerDetails, ManagerDetailsVariables> &
      authProps &
      ManagerRouteProps
  ): React.ReactNode
}

interface ManagerOverviewProps {
  data: ManagerDetails_overview_Manager
}

const ManagerDetailsOverview: ManagerOverviewQueryChildren = function(params) {
  const client = useApolloClient()
  useEffect(() => {
    // Reset cache as some graphql objects are keyed in such a way as
    // objects from different managers would overlap
    client.resetStore()
  }, [])
  const { loading, error, data, history, match, location } = params
  const auth = params.auth as Auth
  const routeProps = { history, match, location }
  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <div>
        <ErrorDisplay error={error}/>
      </div>
    )
  }
  if (!loading && data) {
    return (
      <ManagerDetailComponent
        data={data.overview as ManagerDetails_overview_Manager}
        auth={auth}
        {...routeProps}
      />
    )
  }
}

export default WithTopNav(QueryManagerDetails)

import { GridApi } from "@ag-grid-enterprise/all-modules"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import iassign from "immutable-assign"
import _ from "lodash"
import moment from "moment"
import React, { Component, useState } from "react"
import {
  Col,
  Nav,
  NavItem,
  NavLink, Row, Table, Container, Button
} from "reactstrap"
import Auth from '../../Auth/Auth'
import { SetOptionProps } from "../../Context/EditButtonContext"

import { glidePathSeriesRelatedColumnDef } from "../../helpers/columnDefs"
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, FormInputField } from "../../helpers/constant"
import exportTables from "../../helpers/exportTable"
import { isGlidePathVersionActive as isVersionActive, } from "../../helpers/helpers"
import { GlidePathVehicleLookup, GlidePathVersion, OpenEndedTargetDate } from "../../__generated__/graphql"
import SortableTable from "../Shared/SortableTable"
import { FormInput } from "../ui/Forms/FormInput"
import { GetLookupDataToOptions } from '../ui/LookupOptions'

interface VersionProps {
  version: GlidePathVersion
  editMode: boolean
  searchYear: number
  setShowCalendar: (value:boolean) => void
  setOptions: ({showButtons}:SetOptionProps) => void
  auth: Auth
}

interface VersionDetailState {
  currentState: any
  initialState: any
  tab: string
}

const vehicleList = [
  { code: "EXCH", value: "Exchange traded funds"} ,
  { code: "COLL", value: "Collective trusts" },
  { code: "MF", value: "Mutual funds" },
  { code: "OTH", value: "Other" },
]


class GlidePathVersionDetail extends Component<VersionProps> {
  constructor(props: any) {
    super(props)
    const { version } = props
    const formattedData = _.cloneDeep(version)
    this.state = {
      currentState: formattedData,
      initialState: formattedData,
      tab: "summary"
    }
  }

  state: VersionDetailState

  resetForm = () => {
    this.setState({ currentState: this.state.initialState })
  }

  handleInputChange = (value: any, property: string) => {
    let path = property.split(".")
    let oldState = _.cloneDeep(this.state.currentState)
    if(!_.get(oldState, path)){
      _.set(oldState, path, value)
    }
    let newState = iassign(
      oldState,
      path,
      () => value
    )
    // for Status
    if(property === "active" && value === true) {
      newState = iassign(
        newState,
        (currentState) => currentState?.terminationDate,
        () => null
      )
    }
    this.setState({currentState: newState})
  }

  handleVehicleChange = (value: any, property: string, code: string) => {
    let newState = _.cloneDeep(this.state.currentState)
    newState = iassign(
      newState,
      currentState => currentState?.vehicles,
      (rows) => {
        let rowsClone = _.cloneDeep(rows) || []
        if(property === "present"){
          if(value){
            // Add row
            const newRow = {
              __typename: "GlidePathVehicleLookup",
              code: code,
              value: "",
              otherExplanation: "",
            }
            rowsClone.unshift(newRow)
          } else {
            // Remove row
            _.remove(rowsClone, (v:GlidePathVehicleLookup) => v.code === code)
          }
        } else {
          // Set value
          var selectedRow = _.find(rowsClone, (o) => o.code === code)
          if(selectedRow){
            _.set(selectedRow, property, value)
          }
        }
        return rowsClone
      }
    )
    this.setState({currentState: newState})
  }

  handleSeriesChange = (
    value: any,
    property: string,
    table: string,
    vintageYear: number,
  ) => {
    let newState = _.cloneDeep(this.state.currentState)
    newState = iassign(
      newState,
      [table],
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as any[])
        var selectedYear = _.find(rows, (o) => o?.year === this.props.searchYear)
        if(!selectedYear){
          let newRow
          if(table === "proprietaryNonProprietary"){
            newRow = {
              __typename: "GlidePathProprietaryNonProprietary",
              year: this.props.searchYear,
              structureData: []
            }
          } else if (table === "activePassiveExposure") {
            newRow = {
              __typename: "GlidePathActivePassiveExposure",
              year: this.props.searchYear,
              structureData: []
            }
          } else {
          // tacticalAssetAllocation
            newRow = {
              __typename: "GlidePathTacticalAllocation",
              year: this.props.searchYear,
              structureData: []
            }
          }
          rows.push(newRow)
          selectedYear= newRow
        }
        var selectedVintage = _.find(selectedYear.structureData, (o) => o.vintageYear === vintageYear)
        if(selectedVintage){
          _.set(selectedVintage, property, value)
        } else{
          let newVintage
          if(table === "proprietaryNonProprietary"){
            newVintage = {
              __typename: "GlidePathProprietaryNonProprietaryData",
              percentNonProprietary: null,
              percentProprietary: null,
              vintageYear: vintageYear,
            }
          } else if (table === "activePassiveExposure") {
            newVintage = {
              __typename: "GlidePathActivePassiveExposureData",
              percentPassive: null,
              percentActive: null,
              vintageYear: vintageYear,
            }
          } else {
          // tacticalAssetAllocation
            newVintage = {
              __typename: "GlidePathTacticalAllocationData",
              equityExposureAboveStrategicAllocation: null,
              equityExposureBelowStrategicAllocation: null,
              trackingError: null,
              vintageYear: vintageYear,
            }
          }
          _.set(newVintage, property, value)
          selectedYear.structureData.push(newVintage)
        }

        return rows
      }
    )
    this.setState({ currentState: newState })
  }
  
  render = () => {
    return (
      <Col md="8" lg="9" className="pl-1 d-flex">
        <div className="pane flex-grow-1 d-flex flex-direction-column">
          <h2 className="headline">{this.props.version.name}
            {!isVersionActive(this.props.version.terminationDate) &&
              <span className="inactive-tag m-2">Inactive</span>
            }
          </h2>
          <Nav className="sub-nav sub-nav-primary" tabs role="group" aria-label="Manager Subpage">
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.tab === "summary"
                })}
                onClick={() => this.setState({tab: "summary"}, () => {
                  this.props.setShowCalendar(false)
                  this.props.setOptions({showButtons: true})
                })}
              >
                Summary
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.tab === "structure"
                })}
                onClick={() => this.setState({tab: "structure"}, () => {
                  this.props.setShowCalendar(true)
                  this.props.setOptions({showButtons: true})
                })}
              >
                Structure
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.tab === "related"
                })}
                onClick={() => this.setState({tab: "related"}, () => {
                  this.props.setShowCalendar(false)
                  this.props.setOptions({showButtons: false})
                })}
              >
                Related Products/Vehicles
              </NavLink>
            </NavItem>       
          </Nav>
          {this.state.tab === "summary" &&
            <GlidePathVersionSummary
              version={this.state.currentState}
              editMode={this.props.editMode}
              handleInputChange={(value, property) => this.handleInputChange(value, property)}
              handleVehicleChange={(value, property, code) => this.handleVehicleChange(value, property,code)}
              auth={this.props.auth}
            />
          }
          {this.state.tab === "structure" &&
            <GlidePathVersionStructure
              version={this.state.currentState}
              editMode={this.props.editMode}
              handleSeriesChange={(value, property, table, vintageYear) => this.handleSeriesChange(value, property, table, vintageYear)}
              searchYear={this.props.searchYear}
            />
          }
          {this.state.tab === "related" &&
            <GlidePathVersionRelated
              version={this.state.currentState}
            />
          }
        </div>
      </Col>
    )
  }
}

interface VersionSummaryInput extends FormInputField {
  displayData?: {
    property: string
    value: any
  }
}

const VersionSummaryInputList: VersionSummaryInput[] = [
  {
    property: "name",
    label: "Series Name",
    type: "text",
    readonly: true
  },
  { property: "inceptionYear", label: "Inception Year", type: "number", subtype: "year" },
  // active property is added to parent component VersionHolder
  {
    property: "active",
    label: "Status",
    type: "select",
    subtype: "single",
  },
  { property: "terminationDate",
    label: "Termination Date",
    type: "date",
    required: true,
    displayData: {
      property: "active",
      value: false,
    }
  },
  { property: "", label: "", type: "" },
  { property: "philosophy.code", label: "Glide Path Philosophy", type: "select", subtype: "single", optionSource: "GlidePathPhilosophyCode" },
  { property: "implementationPhilosophy.code", label: "Implementation Philosophy", type: "select", subtype: "single", optionSource: "GlidePathImplementationCode" },
  { property: "primaryBenchmark", label: "Primary Benchmark", type: "text" },
  { property: "secondaryBenchmark", label: "Secondary Benchmark", type: "text" },
  { property: "", label: "", type: "" },
  {
    property: "tacticalAllocation",
    label: "Tactical Allocation",
    type: "checkbox",
    subtype: "boolean",
  },
  {
    property: "tacticalDescription",
    label: "Tactical Allocation Description",
    type: "textarea",
    rows: 8,
    // displayData:{
    //   property: "tacticalAllocation",
    //   value: true
    // },
  },
]

const VersionSummaryTextBlocks: FormInputField[] = [
  {
    property: "lossPreventionDescription",
    label: "Design Features to minimize potential losses",
    type: "textarea",
  },
  {
    property: "inflationPreventionDescription",
    label: "Design Features to minimize inflation",
    type: "textarea",
  },
  {
    property: "alternativeAssetsDescription",
    label: "Use of Alternative assets in design",
    type: "textarea",
  },
  {
    property: "illiquidAssetsDescription",
    label: "Use of Illiquid assets in design",
    type: "textarea",
  },
]

const ActiveBooleanOptions = GetLookupDataToOptions({
  data: [
    {
      code: "true",
      value: "Active",
    },
    {
      code: "false",
      value: "Inactive",
    },
  ],
  multiple: true,
})

interface SummaryProps {
  version: GlidePathVersion
  editMode: boolean
  handleInputChange: (value:any, property:string) => void
  handleVehicleChange: (value:any, property:string, code:string) => void
  auth: Auth
}

class GlidePathVersionSummary extends Component<SummaryProps> {
  render = () => {
    const currentState = this.props.version
    const editMode = this.props.editMode
    return (
      <>
        <Container fluid className="exportable-form" data-export-name="Summary">
          <Row>
            <Col className='p-0'>
              <h2 className='headline underline mt-3'>Series Summary</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              {VersionSummaryInputList.map(
                (
                  {
                    property,
                    label,
                    type,
                    subtype,
                    placeholder,
                    optionSource,
                    readonly,
                    tooltip,
                    subClasses,
                    displayData,
                    required,
                    rows,
                  },
                  idx
                ) => {
                  if (displayData && _.get(currentState, displayData.property) !== displayData.value) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }
                  let propertyVal: any = _.get(currentState, property);
                  let options, errorText
                  let onChangeCallback = (value: any) => this.props.handleInputChange(value, property);

                  if (property === "active") {
                    if(!this.props.auth || !this.props.auth.checkPermissions(["view:all_clients"])) {
                      readonly = true
                    }
                    propertyVal = propertyVal?.toString()
                    const invalidDate = _.get(
                      currentState,
                      "terminationDate"
                    )
                    options = ActiveBooleanOptions
                    if(propertyVal === "true" && invalidDate){
                      errorText = `GlidePath Version will be inactive on ${moment(invalidDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`
                    }
                    onChangeCallback = (value: any) =>{
                      this.props.handleInputChange(value === "true", property)
                    }
                  }else if (property === "terminationDate") {
                    if(!this.props.auth || !this.props.auth.checkPermissions(["view:all_clients"])) {
                      readonly = true
                    }
                  }

                  return (
                    <React.Fragment key={idx}>
                      <FormInput
                        key={idx}
                        property={property}
                        displayName={label}
                        type={type}
                        subtype={subtype}
                        placeholder={placeholder}
                        idx={idx}
                        editMode={editMode}
                        propertyVal={propertyVal}
                        updateValue={onChangeCallback}
                        optionSource={optionSource}
                        readonly={readonly}
                        tooltip={tooltip}
                        subClasses={subClasses}
                        options={options}
                        required={required}
                        rows={rows}
                      />
                      {!!errorText &&
                        <div className="text-danger row">
                          {errorText}
                        </div>
                      }
                    </React.Fragment>
                  );
                }
              )}
              {vehicleList.map((vehicle, idx) => {
                const dataObject = _.find(currentState.vehicles, (v) => v?.code === vehicle.code);
                if (!editMode && !dataObject) {
                  return <React.Fragment key={'vehicle' + idx}></React.Fragment>;
                }
                return (
                  <div className='mb-4' key={'vehicle' + idx}>
                    <FormInput
                      key={idx * 2}
                      property={vehicle.code}
                      displayName={vehicle.value}
                      type={'checkbox'}
                      subtype={'boolean'}
                      idx={idx * 2}
                      editMode={editMode}
                      propertyVal={!!dataObject}
                      updateValue={(value) => this.props.handleVehicleChange(value, 'present', vehicle.code)}
                    />
                    {!!dataObject &&
                      (editMode || (dataObject?.otherExplanation && dataObject?.otherExplanation?.length > 0)) &&
                      vehicle.code === 'OTH' && (
                        <>
                          <br />
                          <FormInput
                            key={idx * 2 + 1}
                            property={'vehicle'}
                            displayName={vehicle.value + ' Details'}
                            type={'text'}
                            idx={idx * 2 + 1}
                            editMode={editMode}
                            propertyVal={dataObject?.otherExplanation}
                            updateValue={(value) =>
                              this.props.handleVehicleChange(value, 'otherExplanation', vehicle.code)
                            }
                          />
                        </>
                      )}
                  </div>
                );
              })}
            </Col>
            <Col sm={6}>
              {VersionSummaryTextBlocks.map(
                ({ property, label, type, subtype, placeholder, optionSource, required }, idx) => {
                  let propertyVal: any = _.get(currentState, property);
                  let onChangeCallback = (value: any) => this.props.handleInputChange(value, property);
                  return (
                    <FormInput
                      key={idx}
                      property={property}
                      displayName={label}
                      type={type}
                      subtype={subtype}
                      optionSource={optionSource}
                      placeholder={placeholder}
                      idx={idx}
                      editMode={editMode}
                      propertyVal={propertyVal}
                      updateValue={onChangeCallback}
                      rows={10}
                      required={required}
                    />
                  );
                }
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

interface StructureProps {
  version: GlidePathVersion
  editMode: boolean
  handleSeriesChange: (value:any, property:string, table:string, vintageYear:number) => void
  searchYear: number
}

class GlidePathVersionStructure extends Component<StructureProps> {

  possibleYears = () => {
    const currentState = this.props.version
    return _.uniq(currentState.activePassiveExposure?.map((ape) => ape?.year).concat(currentState.proprietaryNonProprietary?.map((ape) => ape?.year)).concat(currentState.tacticalAssetAllocation?.map((ape) => ape?.year))).sort().reverse()
  }

  render = () => {
    const currentState = this.props.version
    const editMode = this.props.editMode
    const activePassiveExposure = _.find(currentState.activePassiveExposure, (ape) => ape?.year === this.props.searchYear)
    const proprietaryNonProprietary = _.find(currentState.proprietaryNonProprietary, (ape) => ape?.year === this.props.searchYear)
    const tacticalAssetAllocation = _.find(currentState.tacticalAssetAllocation, (ape) => ape?.year === this.props.searchYear)
    const years = currentState.series?.map((s) => {
      const series = s as OpenEndedTargetDate
      return series?.targetDate?.vintageYear
    }).sort().reverse() || []
    return(
      <>
        <div className="table-container">
          <Table hover className="table-bordered-internal exportable" data-export-name="SeriesStructure">
            <thead>
              <tr className="table-title row-border-olive-100">
                <th></th>
                <th colSpan={2} className="py-2">Exposure</th>
                <th colSpan={2} className="py-2">Vehicles</th>
                <th colSpan={3} className="py-2">
                  Dynamic (Tactical) Asset Allocation
                  <div className="table-subtitle">
                    (Max Public Equity Exposure)
                  </div>
                </th>
              </tr>
              <tr>
                <th>Year</th>
                <th className="text-right">% Active</th>
                <th className="text-right">% Passive</th>
                <th className="text-right">% Proprietary</th>
                <th className="text-right">% Non-Proprietary</th>
                <th className="text-right"><span className="text-olive-100">Above</span> Strategic Allocation <div className="font-weight-normal">(Include REITs)</div></th>
                <th className="text-right"><span className="text-olive-100">Below</span> Strategic Allocation <div className="font-weight-normal">(Include REITs)</div></th>
                <th className="text-right">Tracking Error</th>
              </tr>
            </thead>
            <tbody>
              {years.map((row, idx) => {
                const ape = _.find(activePassiveExposure?.structureData, (o) => o?.vintageYear === row)
                const pnp = _.find(proprietaryNonProprietary?.structureData, (o) => o?.vintageYear === row)
                const taa = _.find(tacticalAssetAllocation?.structureData, (o) => o?.vintageYear === row)
                return(
                  <VersionStructureRow
                    activePassiveExposure={ape}
                    proprietaryNonProprietary={pnp}
                    tacticalAssetAllocation={taa}
                    row={row || 0}
                    key={(this.props.searchYear || 0)+ " " + idx}
                    editMode={editMode}
                    updateValue={(value:any, property:string, table:string, vintageYear:number) => this.props.handleSeriesChange(value, property, table, vintageYear)}
                  />
                )
              })}
            </tbody>
          </Table>
        </div>
      </>
    )
  }
}

interface AllocationRowProps {
  activePassiveExposure: any
  proprietaryNonProprietary: any
  tacticalAssetAllocation: any
  row: number
  editMode: boolean
  updateValue: (value:any, property:string, table:string, vintageYear:number) => void
}

const AllocationInputList:FormInputField[] = [
  { property: "percentActive", label: "", type: "float", subtype: "percent"},
  { property: "percentPassive", label: "", type: "float", subtype: "percent"},
  { property: "percentProprietary", label: "", type: "float", subtype: "percent"},
  { property: "percentNonProprietary", label: "", type: "float", subtype: "percent"},
  { property: "equityExposureAboveStrategicAllocation", label: "", type: "float", subtype: "percent"},
  { property: "equityExposureBelowStrategicAllocation", label: "", type: "float", subtype: "percent"},
  { property: "trackingError", label: "", type: "float", subtype: "percent"},
]

const VersionStructureRow = ({activePassiveExposure, proprietaryNonProprietary, tacticalAssetAllocation, row, editMode, updateValue}: AllocationRowProps) => {
  return (
    <tr>
      <td>
        {row}
      </td>
      {AllocationInputList.map(({property, label, type, subtype, placeholder, optionSource, readonly}, idx) => {
        let propertyVal, onChangeCallback, dataObject, table:string
        if(idx < 2){
          dataObject = activePassiveExposure
          table = "activePassiveExposure"
        } else if(idx < 4) {
          dataObject = proprietaryNonProprietary
          table = "proprietaryNonProprietary"
        } else {
          dataObject = tacticalAssetAllocation
          table = "tacticalAssetAllocation"
        }
        propertyVal = _.get(dataObject, property)
        onChangeCallback = (value:any) => updateValue(value, property, table, row)
        return(
          <td key={idx} className={classnames({"table-data-gray-10": idx >= 4})}>
            <FormInput
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              placeholder={placeholder}
              idx={row + idx}
              editMode={editMode}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              readonly={readonly}
            />
          </td>
        )
      })}
    </tr>
  )
}

interface seriesRelated {
  id: number
  name: string
  type: string
  vehicleType: string
  aum?: {
    date: string
    aum: number
  }
  url?: string
}

interface RelatedProps {
  version: GlidePathVersion
}

class GlidePathVersionRelated extends Component<RelatedProps> {

  render = () => {
    const currentState = this.props.version
    const series = _.get(currentState, 'series', []) || []
    const tableData = series.reduce(
      (
        result: seriesRelated[],
        seriesEntry: any
      ) => {
        const series:seriesRelated = {
          id: seriesEntry.product.id,
          name: seriesEntry.product.name,
          type: "Product",
          aum: seriesEntry.product.latestAum,
          vehicleType: "",
          url: "/products/" + seriesEntry.product.id
        }
        const rest: seriesRelated[] = seriesEntry.product.vehicles.reduce(
          (
            result: seriesRelated[],
            vehicleEntry: any
          ) => {
            const vehicle:seriesRelated = {
              id: vehicleEntry.vehicle.fundid,
              name: vehicleEntry.vehicle.name,
              type: "Vehicle",
              vehicleType: vehicleEntry.vehicle.category.value,
              aum: {aum: vehicleEntry.openEndedVehicle?.latestAssetsUnderManagement, date: ""},
              // url: "/glidepaths/" + glidepath.id + "/series/" + vehicleEntry.id
            }
            result = result.concat(vehicle)
            return result
          },
          [series]
        )
        result = result.concat(rest)
        return result
      },
      []
    )
    const colDef = glidePathSeriesRelatedColumnDef
    // const onReady = (api:GridApi) => {
    //   api.setFilterModel({
    //     'inactive': {
    //       filterType: 'set',
    //       values: ["Active"]
    //     },
    //   })
    //   api.setSortModel([
    //     {colId: 'name', sort:"asc"},
    //   ])
    // }
    return(
      <>
          <SortableTable
            // loading={false}
            filterText={""}
            columnDefs={colDef}
            tableData={tableData}
            // onReady={onReady}
          />
      </>
    )
  }
}

export default GlidePathVersionDetail

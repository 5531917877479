import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import _, { capitalize, cloneDeep, compact, find, get, isArray, isNil, isUndefined, some, uniq } from "lodash"
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Query } from '@apollo/client/react/components'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, ListGroupItem, Nav, NavItem, NavLink, Row } from 'reactstrap'

import { DeleteCashFlowTransactionInput, DeletePerformanceKey, PerformancePeriodType, ProductStructureCode, ProductVehiclesQuery, UpdateVehicleInput, useDeleteVehicleCashflowTransactionMutation, useDeleteVehicleReturnPerformanceMutation, useUpdateVehiclePerformanceBareMutation, useVehiclePerformanceCashflowHistoricLazyQuery, VehicleFields, VehiclePerformanceCashflowQuery, VehiclePerformanceCashflowSummaryQuery, VehiclePerformanceOpenCashflowQuery, VehiclePerformanceReturnsQuery, VehicleUpdateFields } from '../../../__generated__/graphql'
import Auth from '../../../Auth/Auth'
import { appDate, CalendarContext } from "../../../Context/CalendarContext"
import { DATE_API_FORMAT } from '../../../helpers/constant'
import { ProductVehiclePerformanceReturnRouteProps } from '../../../queries/extended/types/Product'
import { VEHICLE_PERFORMANCE_CASHFLOW_SUMMARY, VEHICLE_PERFORMANCE_CASHFLOWS, VEHICLE_PERFORMANCE_OPEN_CASHFLOWS, VEHICLE_PERFORMANCE_RETURNS } from '../../../queries/Vehicles'
import { CalendarPicker } from '../../CalendarPicker'
import { OptionItem, Picker } from '../../Shared/Picker'
import RouteLeavingGuard from '../../Shared/RouteLeavingGuard'
import EditButtons from '../../ui/EditButtons'
import PlaceHolder from '../../ui/PlaceHolder'
import ProductPerformanceVehiclesCashflow from './VehiclesCashFlow'
import ProductPerformanceVehiclesCashflowSummary from './VehiclesCashflowSummary'
import ProductPerformanceVehiclesOpenEndedValuations from './VehiclesOpenEndedValuations'
import ProductPerformanceVehiclesReturns from './VehiclesReturns'
import { useRef } from 'react'
import { RefObject } from 'react'
import exportTables from '../../../helpers/exportTable'
import ErrorDisplay from '../../Shared/ErrorDisplay'

export const AllowedReturnVehicleTypes = ["OpenEndedMutualFund", "OpenEndedVariableAnnuity", "OpenEndedPrivateNonRegisteredHedgeFund", "OpenEndedSeparateAccount", "OpenEndedPooledVehicle", "OpenEndedSeparateAccountStableValue", "OpenEndedExchangeTradedFund", "OpenEndedCollectiveInvestmentFundComposite", "OpenEndedCollectiveInvestmentFund", "OpenEndedCollectiveInvestmentFundStableValue", "OpenEndedCollectiveInvestmentFundStableValueComposite", "OpenEndedPooledVehicleRealAssets", "OpenEndedPooledVehiclePrivateEquity","OpenEndedPooledVehiclePrivateCredit", "OpenEndedSeparateAccountRealAssets", "OpenEndedCollectiveInvestmentFundRealAssets", "OpenEndedCollectiveInvestmentFundCompositeRealAssets", "ClosedEndedPrivateNonRegisteredRealAssets"]

export const OpenEndedValuationsCashflowTabTypes = ["OpenEndedPooledVehicleRealAssets", "OpenEndedPooledVehiclePrivateEquity","OpenEndedPooledVehiclePrivateCredit", "OpenEndedSeparateAccountRealAssets", "OpenEndedCollectiveInvestmentFundRealAssets", "OpenEndedCollectiveInvestmentFundCompositeRealAssets"]
export const AllowedCashflowVehicleTypes = ["OpenEndedPooledVehicleRealAssets","OpenEndedSeparateAccountRealAssets","ClosedEndedPrivateNonRegisteredPrivateCredit","ClosedEndedPrivateNonRegisteredMulitAssetClass","ClosedEndedPrivateNonRegisteredPrivateEquity","ClosedEndedPrivateNonRegisteredRealAssets"]

const firstHistoricalDate = moment(appDate).subtract(30,"years")

interface ProductPerformanceVehiclesProps extends ProductVehiclePerformanceReturnRouteProps {
  productId: number
  auth: Auth
  productVehicles: ProductVehiclesQuery
}

interface IdVehicleFields extends Omit<VehicleFields, 'fundid'> {
  id: string
}

interface IdVehicle {
  vehicle?: IdVehicleFields | null
}

const ProductPerformanceVehicles: React.FC<ProductPerformanceVehiclesProps> = (props) => {
  const { productId, history, location, auth } = props
  const context = useContext(CalendarContext)
  const [editMode, setEditMode] = useState(false)
  const [editCancelled, setEditCancelled] = useState(false)
  const [clearChanges, setClearChanges] = useState(false)
  const [saving, setSaving] = useState(false)
  const [search, setSearch] = useState("")
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [historicEndDate, setHistoricEndDate] = useState(firstHistoricalDate)
  const [vehicleFetchMore, setvehicleFetchMore] = useState()
  const [showCalendar, setShowCalendar] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [screen, setScreen] = useState(context.period === "historical" ? "historical" :"single")
  const [openPeriod, setOpenPeriod] = useState(false)
  const [downloadingHistory, setDownloadingHistory] = useState(false)
  const data = props.productVehicles
  const [period, setPeriod] = useState<string>("")

  const [stateDiff, setStateDiff] = useState<VehicleUpdateFields>({})
  const [removedTransactions, setRemovedTransactions] = useState<DeleteCashFlowTransactionInput[]>([])
  const [removedPerformances, setRemovedPerformances] = useState<DeletePerformanceKey[]>([])

  const [updateVehiclePerformanceReturn] = useUpdateVehiclePerformanceBareMutation()
  const [deleteVehicleTransaction] = useDeleteVehicleCashflowTransactionMutation()
  const [deleteVehiclePerformance] = useDeleteVehicleReturnPerformanceMutation()

  let vehicleId = ""
  let activeTab = ''

  const urlParts = location.pathname.match(/^\/products\/(\d+)\/performance\/vehicles\/([^\/<>{}]+)\/(returns|cashflow|summary_cashflow)/)
  if (urlParts){
    vehicleId = decodeURIComponent(urlParts[2])
    activeTab = decodeURIComponent(urlParts[3])
  }

  const [getHistoricCashflow, { loading:historicCashflowLoading, error:historicCashflowError, data:historicCashflowData }] = useVehiclePerformanceCashflowHistoricLazyQuery({
    fetchPolicy: "no-cache",
    variables: { id: vehicleId }
  })

  if (historicCashflowError && downloadingHistory) {
    setDownloadingHistory(false)
  }

  if (historicCashflowData && downloadingHistory) {
    setDownloadingHistory(false)
    const downloadUrl = historicCashflowData.vehicle?.vehicle?.cashFlowsHistory?.signedURL

    if (!isNil(downloadUrl)) {
      window.location.href = downloadUrl
    }
  }

  const selectVehicle = (id:string) => {
    history.push(`/products/${productId}/performance/vehicles/${id}/returns`)
    setHistoricEndDate(firstHistoricalDate)
  }

  const handleSubmit = () => {
    setSaving(true)
    let submissionData:UpdateVehicleInput = {
      id: vehicleId,
      patch: stateDiff
    }

    const currentPeriod: any = period || "quarter"
    const periodType = period === "month" ? PerformancePeriodType.Monthly : PerformancePeriodType.Quarterly
    const endDate: string = moment().subtract(1, currentPeriod).endOf(currentPeriod).format(DATE_API_FORMAT) 
    updateVehiclePerformanceReturn({ variables: { input: submissionData, date: searchDate}})
      .then(result => {
        if (result && result.data) {
          setEditCancelled(false)
          setEditMode(false)
          setSaving(false)
          setClearChanges(true)
          setStateDiff({})
          setHistoricEndDate(firstHistoricalDate)
        }
      })
      .catch(err => {
        console.error("Error updating vehicle return", err.message)
        setSaving(false)
      })

    if (removedTransactions.length > 0) {
      deleteVehicleTransaction({ variables: { input: { id: vehicleId, transactions: removedTransactions }}})
        .then(result => {
          if (result && result.data) {
            setEditCancelled(false)
            setEditMode(false)
            setSaving(false)
            setClearChanges(true)
            setStateDiff({})
            setHistoricEndDate(firstHistoricalDate)
          }
        })
        .catch(err => {
          console.error("Error updating vehicle return", err.message)
          setSaving(false)
        })
    }

    if (removedPerformances.length > 0) {

      deleteVehiclePerformance({ variables: { input: { id: vehicleId, patch: removedPerformances }}})
        .then(result => {
          if (result && result.data) {
            setEditCancelled(false)
            setEditMode(false)
            setSaving(false)
            setClearChanges(true)
            setStateDiff({})
            setHistoricEndDate(firstHistoricalDate)
          }
        })
        .catch(err => {
          console.error("Error updating vehicle return", err.message)
          setSaving(false)
        })
    }
  }

  const vehicleRef = useRef<ProductPerformanceVehiclesReturns>(null)

  const loadMore = () => {
    let newDate = moment(historicEndDate).subtract(5,"years")
    vehicleRef.current?.fetchMore(newDate)

    setHistoricEndDate(newDate)
  }

  const updateStateDiff = (newDiff:VehicleUpdateFields) => {
    setStateDiff(newDiff)
  }

  const downloadHistoricData = () => {
    if (activeTab === "cashflow") {
      getHistoricCashflow()
      setDownloadingHistory(true)
    }
  }

  const cancelEdit = () => {
    setEditCancelled(true)
    setClearChanges(true)
    setStateDiff({})
  }

  const setEditingMode = (val:boolean) => {
    setEditMode(val)
    if (val === true) {
      setEditCancelled(false)
      setClearChanges(false)
    }
  }

  const removeTransaction = (removedTransactionInput:DeleteCashFlowTransactionInput) => {
    let removed = cloneDeep(removedTransactions)
    removed?.push(removedTransactionInput)
    setRemovedTransactions(removed)
  }

  const canEdit = (vehicleType:string) => {
    const editManager = auth.checkPermissions(['edit:manager'])
    const editReturnsCalc = auth.checkPermissions(['edit:returns_calculated'])
    const editReturns = auth.checkPermissions(['edit:returns'])
    const viewReturns = auth.checkPermissions(['view:returns'])
    if (editManager) {

      if (activeTab === "returns") {
        if (['OpenEndedMutualFund','OpenEndedExchangeTradedFund'].includes(vehicleType)) {
          return editReturnsCalc
        }

        if (['OpenEndedSeparateAccountRealAssets','OpenEndedPooledVehicleRealAssets','OpenEndedPooledVehiclePrivateCredit','OpenEndedPooledVehiclePrivateEquity', "OpenEndedCollectiveInvestmentFundRealAssets", "OpenEndedCollectiveInvestmentFundCompositeRealAssets","ClosedEndedPrivateNonRegisteredRealAssets"].includes(vehicleType)) {
          return editReturns
        }

        if (['OpenEndedVariableAnnuity','OpenEndedSeparateAccount','OpenEndedCollectiveInvestmentFundComposite','OpenEndedCollectiveInvestmentFund','OpenEndedPooledVehicle','OpenEndedPrivateNonRegisteredHedgeFund','OpenEndedCollectiveInvestmentFundStableValue','OpenEndedCollectiveInvestmentFundStableValueComposite','OpenEndedSeparateAccountStableValue'].includes(vehicleType)) {
          if (period === 'quarter') {
            return editReturnsCalc
          } else {
            return editReturns
          }
        }

        return false
      }
      return true
    }

    return false
  }
  

  const heading = (productType:string, vehicleType: string) => {
    const showMonthly:boolean = !["OpenEndedSeparateAccountRealAssets","OpenEndedPooledVehicleRealAssets","OpenEndedPooledVehiclePrivateEquity","OpenEndedPooledVehiclePrivateCredit","ClosedEndedPrivateNonRegisteredRealAssets"].includes(vehicleType)

    const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.key === 'Enter' && e.preventDefault()
    }
    
    const setPeriodMode = (val:string) => {
      setPeriod(val)
      setHistoricEndDate(firstHistoricalDate)
    }

    return (
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="mr-2 pr-3 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyDown={handleEnterKeyDown}
            />
            {search === "" && (
              <span className="o-88 absolute center-v right-1 pe-none">
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="2x"
                  className="fontawesome-icon dark-icon-color text-gray-50"
                />
              </span>
            )}
          </FormGroup>
        </Form>

        { activeTab !== "returns" && (
          <CalendarPicker
            updateValue={(searchDate) => setSearchDate(searchDate)}
            editMode={editMode}
            setEditMode={(value:boolean) => setEditMode(value)}
            updatePeriod={setPeriod}
            hasHistorical={false}
            hasMonthly={showMonthly && activeTab === "returns"}
          />)
        }
        { activeTab === "returns" && showMonthly && (
          <ButtonDropdown isOpen={openPeriod} toggle={() => setOpenPeriod(!openPeriod)} className="mr-1">
            <DropdownToggle caret>
              { capitalize(period === "quarter" ? "Quarterly" : "Monthly") }
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setPeriodMode("quarter")}>Quarterly</DropdownItem>
              <DropdownItem onClick={() => setPeriodMode("month")}>Monthly</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        )}
        { activeTab === "returns" && (
          <Button color="secondary" className="ml-2 btn-load-more" onClick={loadMore}>
            {loadingMore && "Loading"}
            {!loadingMore && "Load 5 More Years"}
          </Button>
        )}

        {showCalendar && screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={loadMore} >
            {loadingMore && "Loading"}
            {!loadingMore && "Load 5 More Years"}
          </Button>
        }
        { activeTab === 'cashflow' && !downloadingHistory &&
          <Button onClick={downloadHistoricData}  color="secondary btn-thin" icon="download" className="ml-1 text-callan-blue ">
            Export XLSX
            <img src='/assets/XLS.svg' className="ml-2"/>
          </Button>
        }
        { activeTab === 'cashflow' && downloadingHistory &&
          <Button onClick={()=>{}} disabled className="ml-1">Cash Flow History<FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin /></Button>
        }
        { activeTab !== 'cashflow' &&
          <div className="border-left ml-2 pl-2">
            <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
              Export CSV
              <img src='/assets/CSV.svg' className="ml-2"/>
            </Button>
          </div>
        }

        { canEdit(vehicleType) &&
          <EditButtons
            editMode={editMode}
            setEditMode={setEditingMode}
            saving={saving}
            onSubmit={handleSubmit}
            cancelEdit={cancelEdit}
          />
        }
      </div>
    )
  }

  if (!!data && data.product?.product?.vehicles && isArray(data.product?.product?.vehicles) ) {
    const productType = data.product.__typename
    let newVehicleId = vehicleId
    let vehicleName = ''
    let vehicleType = ''

    let loadedVehicles = compact(data.product.product.vehicles)

    if (loadedVehicles.length > 0) {
      const selectedVehicle = find(loadedVehicles, v => {
        const selectedVehicleId = _.get(v?.vehicle, "id")
        return selectedVehicleId === vehicleId
      })

      // sort loaded vehicles by default sort before fetching first
      loadedVehicles = loadedVehicles.sort((vehicleA, vehicleB) => {
        const nameA = (vehicleA.vehicle?.name || "").toLocaleLowerCase().trim()
        const nameB = (vehicleB.vehicle?.name || "").toLocaleLowerCase().trim()

        const editableA = vehicleA.vehicle?.vehicleDataCollection?.performanceNeeded
        const editableB = vehicleB.vehicle?.vehicleDataCollection?.performanceNeeded

        return editableA === editableB ? nameA.localeCompare(nameB) : (!!editableA ? -1 : 1)
      })
      if (vehicleId === "" && loadedVehicles[0]?.vehicle) {
        newVehicleId = _.get(loadedVehicles[0], "vehicle.id", "")
        const newVehicleType = loadedVehicles[0].__typename
        // setSelectedVehicleId(vehicleId)

        const tab = AllowedReturnVehicleTypes.includes(newVehicleType) ? 'returns' : 'cashflow'
        if (activeTab !== tab) {
          history.replace(`/products/${productId}/performance/vehicles/${newVehicleId}/${tab}`)
          return (<></>)
        }

      }

      if (selectedVehicle) {
        vehicleName = selectedVehicle.vehicle?.name || ''
        vehicleType = selectedVehicle.__typename || ''
      }

      const showMonthly:boolean = !["OpenEndedSeparateAccountRealAssets","OpenEndedPooledVehicleRealAssets","OpenEndedPooledVehiclePrivateEquity","OpenEndedPooledVehiclePrivateCredit","ClosedEndedPrivateNonRegisteredRealAssets"].includes(vehicleType)

      // If monthly is not available, set it to quarterly
      if (period !== "quarter" && (activeTab !== "returns" || !showMonthly)) {
        setPeriod('quarter')
      } else if (period === "" && AllowedReturnVehicleTypes.includes(vehicleType)) {
        setPeriod('month')
      }

      if (editMode && !canEdit(vehicleType)) {
        setEditMode(false)
      }
    }

    let vehicles = loadedVehicles

    if (search !== "") {
      vehicles = vehicles.filter(v => v.vehicle?.name?.toLowerCase().includes(search.toLowerCase()))
    }


    let endDate = moment(searchDate)

    let queryPeriod = period === "month" ? PerformancePeriodType.Monthly : PerformancePeriodType.Quarterly

    return (
      <>
        {heading(productType, vehicleType)}
        <Row>
          <VehiclesSidebar
            vehicles={vehicles as IdVehicle[]}
            selectVehicle={(id:string) => selectVehicle(id)}
            selectedVehicleId={vehicleId}
          />
          <RouteLeavingGuard
            when={editMode}
            navigate={path => { setEditMode(false); history.push(path)}}
          />
          <VehicleDisplay
            vehicleRef={vehicleRef}
            key={`vehicle-display-${period}-${queryPeriod}`}
            id={vehicleId}
            name={vehicleName}
            editMode={editMode}
            setEditMode={setEditMode}
            period={queryPeriod}
            queryDate={endDate.format(DATE_API_FORMAT)}
            historicEndDate={historicEndDate.format(DATE_API_FORMAT)}
            handleChange={updateStateDiff}
            productType={productType}
            editCancelled={editCancelled}
            clearChanges={clearChanges}
            removeTransaction={removeTransaction}
            removePerformance={setRemovedPerformances}
            vehicleType={vehicleType}
            setPeriod={setPeriod}
            productStructure={data.product?.product?.structure?.code || null}
            { ...props}
          />
        </Row>
      </>
    )
  }

  return <div>data doesn't exist.</div>

}


interface VehiclesSidebarProps {
  vehicles: IdVehicle[]
  selectedVehicleId: string
  selectVehicle: (id:string) => void
}

const VehiclesSidebar:React.FC<VehiclesSidebarProps> = (props:VehiclesSidebarProps) => {
  const TERMINATED_FILTER_ID = 99
  const EDITABLE_FILTER_ID = 98
  const [sortBy, setSortBy] = useState(1)
  const [activeFilters, setActiveFilters] = useState<number[]>([])
  let { vehicles } = props

  let pickerFilters: OptionItem[] = []
  pickerFilters = [
    {
      id: EDITABLE_FILTER_ID,
      name: "Editable",
    }
  ]
  // Add the possible options to the
  let categories = uniq(props.vehicles.map((vehicle:IdVehicle) => get(vehicle, "vehicle.category.value", ""))).sort()

  categories.map((category:string) => { if (category && category !== '') { pickerFilters.push({id: pickerFilters.length+1, name: category})}})
  pickerFilters.push({ id: TERMINATED_FILTER_ID, name: 'Show Terminated'})

  const sortOptions:OptionItem[] = [
    {
      id: 1,
      name: "Editable",
    },
    {
      id: 2,
      name: "Name ( A to Z )",
    },
    {
      id: 3,
      name: "Name ( Z to A )",
    },
  ]


  const onFilter = (filterId: number) => {
    let newActiveFilters = cloneDeep(activeFilters)
    const index = newActiveFilters.indexOf(filterId)
    if (index === -1) {
      newActiveFilters.push(filterId)
    } else {
      newActiveFilters.splice(index, 1)
    }

    setActiveFilters(newActiveFilters)
  }

  const onSort = (sortId: number) => {
    setSortBy(sortId)
  }

  vehicles = vehicles
    .filter((vehicle: IdVehicle) => {
      if (!activeFilters.includes(TERMINATED_FILTER_ID)) {
        return vehicle?.vehicle?.status?.code !== "t"
      }
      return true
    })
    .filter((vehicle: IdVehicle) => {
      if (activeFilters.includes(EDITABLE_FILTER_ID)) {
        return vehicle?.vehicle?.vehicleDataCollection?.performanceNeeded
      }
      return true
    })

  vehicles = vehicles
    .filter((vehicle: IdVehicle) => {
      const activeFiltersWithoutTerminated = activeFilters.filter(i =>  ![TERMINATED_FILTER_ID, EDITABLE_FILTER_ID].includes(i))

      if (activeFiltersWithoutTerminated.length > 0) {
        // Inverse as filters remove options
        return some(activeFiltersWithoutTerminated, (id:number) => {
          const matchingFilter = find(pickerFilters, {id: id})
          return matchingFilter?.name === vehicle.vehicle?.category?.value
        })

      }
      return true
    })
    .sort((vehicleA: IdVehicle, vehicleB: IdVehicle) => {
      const nameA = (vehicleA.vehicle?.name || "").toLocaleLowerCase().trim()
      const nameB = (vehicleB.vehicle?.name || "").toLocaleLowerCase().trim()

      const editableA = vehicleA.vehicle?.vehicleDataCollection?.performanceNeeded
      const editableB = vehicleB.vehicle?.vehicleDataCollection?.performanceNeeded

      switch (sortBy) {
        case 1:
          return editableA === editableB ? nameA.localeCompare(nameB) : (!!editableA ? -1 : 1)
        case 2:
          return nameA.localeCompare(nameB)
        case 3:
          return nameB.localeCompare(nameA)
        default:
          throw new Error(`unrecognized sort choice ${sortBy}`)
      }
    })

  return (
    <Picker
      filters={pickerFilters}
      activeFilters={activeFilters}
      sortOptions={sortOptions}
      activeSort={sortBy}
      onFilter={onFilter}
      onSort={onSort}
      searching={false}
      listClass="interaction-list"
      toolbarClass="interaction-toolbar"
    >
      {vehicles.map((v: IdVehicle, idx) => {
        if (!v.vehicle) {
          return <></>
        }

        const vehicle = v.vehicle

        const aum = get(v, "openEndedVehicle.latestAssetsUnderManagement")
        const category = get(vehicle, "category.value")
        let status = "N/A"
        if(vehicle?.status?.code === "c") status = "Closed"
        if(vehicle?.status?.code === "o") status = "Open"
        if(vehicle?.status?.code === "t") status = "Terminated"

        return (
          <ListGroupItem
            tag="a"
            key={idx}
            className={classNames({
              active: vehicle.id === props.selectedVehicleId,
              "picker-list-item-characteristics": true,
            })}
            onClick={() => { props.selectVehicle(vehicle.id) }}
          >
            <h5>
              {vehicle?.name}
            </h5>
            {
              vehicle.vehicleDataCollection?.performanceNeeded && (
                <FontAwesomeIcon
                  icon={["fas", "pen"]}
                  size="sm"
                  className="fontawesome-icon dark-icon-color text-gray-50 picker-list-item-icon"
                />
              )
            }
            <dl>
              <>
                <dt>Vehicle Category:</dt>
                <dd>{ category }</dd>
              </>
              <dt>Identifier:</dt>
              <dd>{vehicle?.identifiers?.ticker || vehicle?.identifiers?.cusip || "—"}</dd>
              <dt>Vehicle ID:</dt>
              <dd>{vehicle?.id || '-'}</dd>
            </dl>
            <div className={classNames({
              "list-group-item-status": true,
              "background-olive-10": status === "Open",
              "background-orange-30": status === "Terminated",
              "background-gray-20": status === "Closed",
            })}>
              {status}
            </div>
          </ListGroupItem>
        )
      })}
    </Picker>
  )
}


interface VehicleDisplayProps extends ProductVehiclePerformanceReturnRouteProps {
  id: string
  name: string
  editMode: boolean
  // onChange: (v:Vehicle) => void
  // onProductNoteChange: (notes:ProductInteractionNotesFragment[]) => void
  setEditMode: (mode:boolean) => void
  setPeriod: (period: string) => void
  period: PerformancePeriodType
  queryDate: string
  handleChange: (newDiff:VehicleUpdateFields) => void
  removeTransaction: (removedTransactionInput:DeleteCashFlowTransactionInput) => void
  removePerformance: (removedPerformanceInput:DeletePerformanceKey[]) => void
  productType: string
  editCancelled: boolean
  clearChanges: boolean
  historicEndDate: string
  vehicleType: string
  auth: Auth
  productStructure: ProductStructureCode | null
  vehicleRef: RefObject<ProductPerformanceVehiclesReturns>
}


const VehicleDisplay = ((props:VehicleDisplayProps) => {
  const match = useRouteMatch() || props.match
  const {history, editMode, setEditMode, setPeriod, queryDate, period, id, name, handleChange, editCancelled, removeTransaction, historicEndDate, vehicleType, removePerformance, auth, clearChanges, productStructure } =  props
  const params = useParams() as {
    subtype?: string
    type?: string
    productId?: string
    tertiaryType?: string
    vehicleId?: string
  }
  const tertiaryType = get(props, "match.params.tertiaryType", "")

  let urlWithoutSubtype =
      params && tertiaryType
        ? match.url.slice(0, -tertiaryType.length - 1)
        : match.url
  const periodType = period === PerformancePeriodType.Monthly ? "month" : "quarter"
  return (
    <Col md="8" lg="9" className="pl-1">
      <div className="pane">
        <Row>
          <Col>
            <h2 className="headline underline">{ name }</h2>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Nav tabs>
              { AllowedReturnVehicleTypes.includes(vehicleType) && (
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: (tertiaryType as string) === "returns" || (isUndefined(tertiaryType) && AllowedReturnVehicleTypes.includes(vehicleType))
                    })}
                    onClick={() => { setPeriod('month'); history.push(`${urlWithoutSubtype}/returns`)}}
                  >Returns</NavLink>
                </NavItem>
              )}
              { AllowedCashflowVehicleTypes.includes(vehicleType) && (
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: (tertiaryType as string) === "cashflow" || (isUndefined(tertiaryType) && !AllowedReturnVehicleTypes.includes(vehicleType))
                    })}
                    onClick={() => history.push(`${urlWithoutSubtype}/cashflow`)}
                  >Cash Flow</NavLink>
                </NavItem>
              )}
              { AllowedCashflowVehicleTypes.includes(vehicleType) && productStructure && productStructure === ProductStructureCode.FOF && (
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: (tertiaryType as string) === "summary_cashflow"
                    })}
                    onClick={() => history.push(`${urlWithoutSubtype}/summary_cashflow`)}
                  >Summary Cash Flow</NavLink>
                </NavItem>
              )}
            </Nav>
            <Switch>
              <Route
                path="/products/:productId(\d+)/:type(performance)/:subtype(vehicles)/:vehicleId/returns"
                render={() => {
                  return (
                    <Query<VehiclePerformanceReturnsQuery> query={VEHICLE_PERFORMANCE_RETURNS} variables={{ id, startDate: historicEndDate, endDate: moment().subtract(1, periodType).endOf(periodType).format(DATE_API_FORMAT), period }} fetchPolicy="network-only" notifyOnNetworkStatusChange={true}>
                    {
                      ({loading, error, data, fetchMore}) => {

                        if (loading) {
                          return <PlaceHolder />
                        }

                        if (error) {
                          return (
                            <div className='pane pane-table'>
                              <p>{error.message}</p>
                            </div>
                          );
                        }

                        if (data && data.vehicle_returns?.vehicle && AllowedReturnVehicleTypes.includes(vehicleType)) {
                          return (
                            <ProductPerformanceVehiclesReturns
                              id={id}
                              ref={props.vehicleRef}
                              editMode={editMode}
                              startDate={historicEndDate}
                              endDate={queryDate}
                              period={period}
                              handleChange={handleChange}
                              handleDelete={removePerformance}
                              data={data}
                              editCancelled={editCancelled}
                              clearChanges={clearChanges}
                              auth={auth}
                              fetchMore={fetchMore}
                            />
                          )
                        }

                        return <></>
                      }
                    }
                    </Query>
                  )
                }}
              />
              <Route
                path="/products/:productId(\d+)/:type(performance)/:subtype(vehicles)/:vehicleId/cashflow"
                render={(props) => {
                  if (OpenEndedValuationsCashflowTabTypes.includes(vehicleType)) {
                    return (
                      <Query<VehiclePerformanceOpenCashflowQuery> query={VEHICLE_PERFORMANCE_OPEN_CASHFLOWS} variables={{ id, date: queryDate, period }} fetchPolicy="network-only" notifyOnNetworkStatusChange={true}>
                      {
                        ({loading, error, data}) => {

                          if (loading) {
                            return <PlaceHolder />
                          }

                          if (error) {
                            return (
                              <div className='pane pane-table'>
                                <p>{error.message}</p>
                              </div>
                            );
                          }

                          if (data && data.vehicle_open_cashflows?.vehicle && AllowedCashflowVehicleTypes.includes(vehicleType)) {
                            return (<ProductPerformanceVehiclesOpenEndedValuations
                              id={id}
                              editMode={editMode}
                              date={queryDate}
                              handleChange={(newDiff) => handleChange({ cashFlows: newDiff })}
                              removeTransaction={removeTransaction}
                              data={data}
                              editCancelled={editCancelled}
                              clearChanges={clearChanges}
                            />)

                          }

                          return <></>
                        }
                      }
                      </Query>
                    )
                  }
                  return (
                    <Query<VehiclePerformanceCashflowQuery> query={VEHICLE_PERFORMANCE_CASHFLOWS} variables={{ id, date: queryDate, period }} fetchPolicy="network-only" notifyOnNetworkStatusChange={true}>
                    {
                      ({loading, error, data}) => {

                        if (loading) {
                          return <PlaceHolder />
                        }

                        if (error) {
                          return (
                            <div className='pane pane-table'>
                              <p>{error.message}</p>
                            </div>
                          );
                        }

                        if (data && data.vehicle_cashflows?.vehicle && AllowedCashflowVehicleTypes.includes(vehicleType)) {
                          return (
                            <ProductPerformanceVehiclesCashflow
                              id={id}
                              editMode={editMode}
                              date={queryDate}
                              handleChange={(newDiff) => handleChange({ cashFlows: newDiff })}
                              removeTransaction={removeTransaction}
                              data={data}
                              editCancelled={editCancelled}
                              clearChanges={clearChanges}
                            />
                          )
                        }

                        return <></>
                      }
                    }
                    </Query>
                  )
                }}

              />
              <Route
                path="/products/:productId(\d+)/:type(performance)/:subtype(vehicles)/:vehicleId/summary_cashflow/:summary_tab(total|vintage)?"
                render={(props) => {
                  const prevQuarter = moment(queryDate).subtract(1, "quarter")
                  return (
                    <Query<VehiclePerformanceCashflowSummaryQuery> query={VEHICLE_PERFORMANCE_CASHFLOW_SUMMARY} variables={{ id, date: queryDate, prevQuarter: prevQuarter.format(DATE_API_FORMAT) }} fetchPolicy="network-only" notifyOnNetworkStatusChange={true}>
                    {
                      ({loading, error, data, refetch}) => {
                        if (loading) {
                          return <PlaceHolder />
                        }

                        if (error) {
                          return (
                            <ErrorDisplay error={error}/>
                          );
                        }

                        if (data && data.vehicle_cashflow_summary?.vehicle && AllowedCashflowVehicleTypes.includes(vehicleType)) {
                          return (
                            <ProductPerformanceVehiclesCashflowSummary
                              id={id}
                              editMode={editMode}
                              date={queryDate}
                              period={period}
                              handleChange={(newDiff) => handleChange({ cashFlowsSummary: newDiff })}
                              editCancelled={editCancelled}
                              data={data}
                              clearChanges={clearChanges}
                              history={history}
                              location={props.location}
                              match={props.match}
                              refetch={refetch}
                            />
                          )
                        }

                        return <></>
                      }
                    }
                    </Query>
                  )
                }}
              />
              {/* {
                AllowedReturnVehicleTypes.includes(vehicleType) && (
                  <Redirect
                    from="/products/:productId(\d+)/performance/:subtype(vehicles)/:vehicleId"
                    to="/products/:productId(\d+)/performance/vehicles/:vehicleId/returns"
                  />
                )
              }
              {
                AllowedCashflowVehicleTypes.includes(vehicleType) && (
                  <Redirect
                    from="/products/:productId(\d+)/performance/:subtype(vehicles)/:vehicleId"
                    to="/products/:productId(\d+)/performance/vehicles/:vehicleId/cashflow"
                  />
                )
              } */}
            </Switch>
          </Col>
        </Row>
      </div>
    </Col>
  )
})

// interface VehicleDisplayState {
//   currentState: IdVehicle
//   initialState: IdVehicle
// }
// class VehicleDisplay extends Component<VehicleDisplayProps,VehicleDisplayState> {
//   constructor(props: VehicleDisplayProps) {
//     super(props)
//     const { vehicle } = props

//     this.state = {
//       currentState: vehicle,
//       initialState: vehicle,
//     }
//   }

//   render() {
//     const { vehicle } = this.state.currentState


//     console.log({subtype, url: match.url, urlWithoutSubtype})

//     return (
//       <Col md="8" lg="9" className="pl-1">
//         <div className="pane">
//           <Row>
//             <Col>
//               <h2 className="headline underline">{ vehicle?.name }</h2>
//             </Col>
//           </Row>
//           <Row>
//             <Col md="12">
//               <Nav tabs>
//                 <NavItem>
//                   <NavLink href="#">Returns</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink href="#">Cash Flow</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink href="#">Summary Cash Flow</NavLink>
//                 </NavItem>
//               </Nav>
//               <Switch>
//                 <Route
//                   path="/products/:productId(\d+)/performance/:subtype(vehicles)"
//                   render={(props) => <ProductPerformanceVehicles productId={id} {...props} />}
//                 />
//                 <Route
//                   path="/products/:productId(\d+)/performance/:subtype(portfolios)"
//                   render={(props) => <ProductPerformanceClient productId={id} {...props} />}
//                 />
//                 <Redirect
//                   from="/products/:productId(\d+)/performance"
//                   to="/products/:productId(\d+)/performance/vehicles"
//                 />
//               </Switch>
//             </Col>
//           </Row>
//         </div>
//       </Col>
//     )
//   }
// }

export default ProductPerformanceVehicles
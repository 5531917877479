import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import React, { useEffect, useState } from "react"
import { Button, Table } from "reactstrap"
import _ from "lodash"

import { FormInputSubClasses } from "../../helpers/constant"
import { FormInput } from "../ui/Forms/FormInput"

type columnDefItem = {
  field: string
  type: string
  title: string
  className?: string
  subtype?: string
  optionSource?: string
  required?: boolean
  subClasses?: { [name in FormInputSubClasses]?: string }
  widthClass?: string
}

interface MaterialChangesTableRowProps {
  data: any
  columnDef: columnDefItem[]
  idx: number
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

type NullableMaterialChangesData = {
  date: Date | null
  description: string | null
  uniqueId?: string| number
}

type MaterialChangesTableProps = {
  data: NullableMaterialChangesData[]
  handleChange: (state: any) => void
  editMode: boolean
}

const MaterialChangesColumnDef: columnDefItem[] = [
  {
    field: "date",
    type: "date",
    title: "Date of Change",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "w-25 align-top pl-1"
    },
    widthClass: "col-3"
  },
  {
    field: "description",
    type: "textarea",
    title: "Description of Change",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "row pl-1"
    },
    widthClass: "col-8"
  }
]

const MaterialChangesTableRow = ({
  data,
  columnDef,
  idx,
  editMode,
  updateValue,
  action
}: MaterialChangesTableRowProps) => {
  return (
    <tr
      className={
        editMode
          ? "full-width hover-actions editing"
          : "full-width hover-actions"
      }
    >
      {columnDef.map((header, index) => {
        let widthClass = index === 0 ? "w-25 align-top" : ""
        // }
        return (
          <td key={header.field} className={widthClass}>
            <FormInput
              key={header.field}
              property={header.field}
              displayName={""}
              type={header.type}
              idx={idx}
              editMode={editMode}
              propertyVal={data[header.field]}
              updateValue={value =>
                updateValue(value, header.type, header.field)
              }
              required={header.required}
              optionSource={header.optionSource}
              // subClasses={header.subClasses || {}}
            />
          </td>
        )
      })}
      {editMode && (
        <td className="col-2 actions">
          <Button
            color="link"
            disabled={!editMode}
            className="ml-auto"
            onClick={() => action.remove(idx)}
          >
            <FontAwesomeIcon icon="trash" className="ml-2 text-blue-100" />
          </Button>
        </td>
      )}
    </tr>
  )
}

const getInitialState = (data: NullableMaterialChangesData[]) =>{
  return _.orderBy(data, ["date"], ["desc"])
}

const MaterialChangesTableDisplay = (seed: MaterialChangesTableProps) => {
  const tableName = "Material-Changes"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(()=>getInitialState(data))
  const [currentId, setId] = useState(1)

  useEffect(() => {
    if(!editMode) {
      setData(getInitialState(data))
      setId(1)
    }
  }, [editMode])

  const generateId = () => {
    let nextId = currentId - 1
    setId(nextId)
    return nextId
  }

  const onAddMaterialChangesRow = (row: NullableMaterialChangesData) => {
    let newData = [row, ...tableState]
    setData(newData)
    handleChange(newData)
  }

  const onRemoveMaterialChangesRow = (rowIndex: number) => {
    let newData = [
      ...tableState.slice(0, rowIndex),
      ...tableState.slice(rowIndex + 1)
    ]
    setData(newData)
    handleChange(newData)
  }
  const actionMap = {
    add: onAddMaterialChangesRow,
    remove: onRemoveMaterialChangesRow
  }

  const onHandleChangeMaterialChangesRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = tableState.map((el, index) => {
      if (idx === index) {
        return { ...tableState[index], [property]: value }
      } else {
        return el
      }
    })
    setData(newData)
    handleChange(newData)
  }

  return (
    <div
      className={classnames(
        `${tableName}-table row form-group pl-0 table-responsive`,
        {
          "col-sm-12": editMode,
          "col-sm-11": !editMode
        }
      )}
    >
      <div className={"py-2"}>
        {
          "Discuss any material changes to the product, including personnel changes."
        }
      </div>
      {editMode && (
        <div>
          <Button
            color="link"
            className="ml-auto"
            onClick={() => actionMap.add({ date: null, description: null, uniqueId: generateId() })}
          >
            Add Row
            <FontAwesomeIcon
              icon="plus-circle"
              className="ml-2 text-blue-100 btn-thin"
            />
          </Button>
        </div>
      )}
      <Table
        hover
        responsive
        size="sm"
        key={`MaterialChanges-table`}
        className={"exportable"}
        data-export-name="MaterialChanges"
      >
        <thead className="table-border-bottom">
          <tr className="table-header">
            {MaterialChangesColumnDef.map((headerDef, idx) => {
              let widthClass = headerDef.widthClass
              if (!editMode) {
                widthClass = idx === 0 ? "col-3" : "col-9"
              }
              return (
                <th
                  key={`MaterialChanges-header-${idx}`}
                  // className={widthClass}
                >
                  {headerDef.title}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody key={0}>
          {tableState.map((row: any, idx: number) => {
            return (
              <MaterialChangesTableRow
                data={row}
                columnDef={MaterialChangesColumnDef}
                key={row.uniqueId}
                idx={idx}
                editMode={editMode}
                action={actionMap}
                updateValue={(value, type, property) =>
                  onHandleChangeMaterialChangesRow(idx, value, type, property)
                }
              />
            )
          })}
        </tbody>
      </Table>
      <div
        className="border-bottom-line-break row my-2"
        key={"materialChanges"}
      ></div>
    </div>
  )
}

export default MaterialChangesTableDisplay

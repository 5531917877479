import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import _, { compact } from "lodash"
import React, { FormEvent, useContext, useState } from "react"
import { match, RouteComponentProps, useHistory } from "react-router-dom"
import { Button, Col, Container, Form, Row, Table, UncontrolledAlert } from "reactstrap"
import Auth from "../../Auth/Auth"
import { EditButtonContext } from "../../Context/EditButtonContext"
import { FormInputField, FormInputSubClasses } from "../../helpers/constant"
import { getNewStateObject } from "../../helpers/helpers"
import { reShapeObject } from '../../helpers/object'
import { appProps } from "../../queries/extended/appProps"
import { Maybe, RecordKeeperDetailsQuery, RecordKeeperOverviewFragment, useMeQuery, useRecordKeeperDetailsQuery, useUpdateRecordKeeperDetailsMutation } from "../../__generated__/graphql"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import EditButtons from "../ui/EditButtons"
import { FormInput } from "../ui/Forms/FormInput"
import PlaceHolder from "../ui/PlaceHolder"

interface RecordKeeperRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.recordKeeperId
    recordKeeperId: string
  }> {}

interface RecordKeeperOverviewDetailFragment {
  data: RecordKeeperOverviewFragment
  recordKeeperId: number
}

type idProps = {
  recordKeeperId: number
  data: RecordKeeperDetailsQuery
  auth: Auth
  match: match<{ recordKeeperId: string }>
}

interface RecordKeeperOverviewInput extends FormInputField {}

type ColumnDefItem = {
  field: string
  type: string
  title: string
  readonly?: boolean
  className?: string
  subtype?: string
  optionSource?: string
  required?: boolean
  subClasses?: { [name in FormInputSubClasses]?: string }
  widthClass?: string
  placeholder?: string
  tooltip?: { icon: string; id: string }
}

const RecordKeeperSummaryInputList: RecordKeeperOverviewInput[] = [
  {
    property: "",
    label: "Record Keeper Details",
    type: "",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 mb-2",
    },
  },
  {
    property: "generalInfo.name",
    label: "Record Keeper Name",
    type: "text",
    readonly: true,
    // tooltip: {
    //   icon: "question-circle",
    //   id: "recordKeeperNameTooltip",
    // },
  },
  {
    property: "generalInfo.id",
    label: "Record Keeper ID",
    type: "text",
    readonly: true,
    // tooltip: {
    //   icon: "question-circle",
    //   id: "recordKeeperIdTTooltip",
    // },
  },
  { property: "", label: "", type: "" },
  { property: "generalInfo.address.street[0]", label: "Street1", type: "text", readonly: true },
  { property: "generalInfo.address.street[1]", label: "Street2", type: "text", readonly: true },
  { property: "generalInfo.address.city", label: "City", type: "text", readonly: true },
  { property: "generalInfo.address.state", label: "State", type: "select", subtype: "single", optionSource: "StateCode", readonly: true },
  { property: "generalInfo.address.zip", label: "Zip Code", type: "text", readonly: true },
  { property: "generalInfo.address.country", label: "Country", type: "select", subtype: "single", optionSource: "AddressCountryCode", readonly: true },
  { property: "", label: "", type: "" },
  { property: "generalInfo.website", label: "Firm Website", type: "text", subtype: "url" },
]

const CommonClientsColDef: ColumnDefItem[] = [
  {
    field: "name",
    type: "string",
    title: "Clients In Common",
    readonly: true,
    className: "text-left width-50-percent",
  },
  {
    field: "aum",
    type: "number",
    title: "Assets Invested ($M)",
    subtype: "currency",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "recordKeeperAssetInvestedTooltip",
    },
    className: "text-right width-20-percent",
  },
  {
    field: "fundType.code",
    type: "select",
    title: "Plan Type",
    optionSource: "FundTypeCode",
    readonly: true,
    className: "text-left width-30-percent",
  },
]

type CommonClientsComponentTableRow = {
  data: any
  columnDef: typeof CommonClientsColDef
  idx: number
  editMode?: boolean
}

const getInitialData = (data: RecordKeeperDetailsQuery) => {
  let plans = ((data?.generalInfo as RecordKeeperOverviewFragment)?.plans || []) as RecordKeeperOverviewFragment["plans"]
  let filteredPlans = compact(
    plans?.map((plan, idx) => {
      if (!plan) {
        return
      }
      let { assets } = plan
      let orderedAssets = _.orderBy(assets, "date", "desc")
      let latestAsset: Maybe<number> | undefined = 0
      if (orderedAssets?.length > 0) {
        latestAsset = orderedAssets[0]?.value
      }
      let result = { ...plan, aum: latestAsset }
      return result
    })
  )

  return filteredPlans
}

const CommonClientsComponentTableRow = (rowData: CommonClientsComponentTableRow) => {
  const { data, columnDef, idx, editMode } = rowData
  return (
    <tr className={editMode ? "hover-actions editing" : "hover-actions"}>
      {columnDef.map((header, index) => {
        let { field, type, placeholder, readonly, subtype, tooltip, optionSource } = header
        let value = _.get(data, field)
        return (
          <td key={field}>
            <FormInput
              property={field}
              displayName={""}
              type={type}
              subtype={subtype || ""}
              idx={`${header.field}-${idx}`}
              editMode={!!editMode}
              propertyVal={value}
              updateValue={(props) => {}}
              optionSource={optionSource}
              placeholder={placeholder}
              tooltip={tooltip}
              readonly={readonly}
            />
          </td>
        )
      })}
    </tr>
  )
}

const TABLE_INCREMENT = 10

const CommonClientsComponent: React.FC<idProps> = (props) => {
  const { data } = props
  let tableName = "clients-in-common"

  const [filteredPlans, setData] = useState(() => getInitialData(data))
  const [tableLength, setTableLength] = useState(TABLE_INCREMENT)

  const [expanded, expandBox] = useState(false)
  const showExpandedButton: boolean = tableLength < filteredPlans.length

  const toggle = () => {
    if (!expanded) {
      setTableLength(filteredPlans.length)
    } else {
      setTableLength(TABLE_INCREMENT)
    }
    expandBox(!expanded)
  }

  return (
    <div className={classnames(`${tableName}-table row form-group pl-0`)}>
      <Table hover size="sm" key={`${tableName}`} className={""}>
        <thead className="">
          <tr className="table-header">
            {CommonClientsColDef.map((header, index) =>
              {
                let {tooltip} = header
                if(tooltip) {
                  return (
                  <th key={`{tableName}-header-${index}`} id={`${tooltip.id}Container`}className={classnames(header.className, "form-section-title headline underline small-font-size")}>
                    <div className="d-inline-flex align-items-center tooltip-icon" id={`${tooltip.id}`}>
                      {header.title}
                      <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                      className={`ml-1`}
                      />
                      </div>
                    </th>)
                }else {
                  return (<th key={`{tableName}-header-${index}`} className={classnames(header.className, "form-section-title headline underline small-font-size")}>
                  {header.title}
                </th>)
                }
              })
            }
          </tr>
        </thead>
        <tbody key={0}>
          {filteredPlans?.slice(0, tableLength).map((plan: any, idx: number) => {
            return <CommonClientsComponentTableRow data={plan} columnDef={CommonClientsColDef} key={idx} idx={idx} editMode={false} />
          })}
          <div className="expand-link">
            <Button color="link" onClick={() => toggle()}>
              <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} size="sm" />
              <span className="pl-2 expand-text">{expanded ? "Collapse" : "Expand"}</span>
            </Button>
          </div>
        </tbody>
      </Table>
    </div>
  )
}

const PreShapeActions = {
  "generalInfo.address.street": {
    destinationPath: "generalInfo.address.street",
    action: (address: string) => {
      const splitAddress = (address || "").split(/\r?\n/)
      return [splitAddress[0], splitAddress.slice(1).join("")]
    }
  },
}

const getInitialState = (data: RecordKeeperDetailsQuery) => {
  return reShapeObject(_.cloneDeep(data), PreShapeActions) as typeof data
}

const RecordKeeperOverviewMain: React.FC<idProps> = (props) => {
  const { data, recordKeeperId, auth, match } = props
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const { resetErrors } = useContext(EditButtonContext)
  const formattedData = getInitialState(data)
  const [initialState, setInitial] = useState(formattedData)
  const [currentState, setState] = useState(formattedData)
  const history = useHistory()

  const [updateRecordKeeperOverview] = useUpdateRecordKeeperDetailsMutation()

  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleEdit = (value: boolean) => {
    setEditMode(value)
  }

  const handleInputChange = (value: any, property: any) => {
    let newState = getNewStateObject({
      state: currentState,
      newValue: value,
      property,
    }) as typeof currentState
    setState(newState)
  }

  const getEditableFields = (data: RecordKeeperDetailsQuery) => {
    return {
      generalInfo: { website: _.get(data, "generalInfo.website") },
    }
  }

  const handleSubmit = () => {
    setSaving(true)
    // the only editable field here is 'website'
    let patch = getEditableFields(currentState)
    if (patch?.generalInfo?.website === getEditableFields(initialState).generalInfo.website) {
      console.log("no edit")
      setSaving(false)
      handleEdit(false)
    } else {
      console.log("preparing", patch)
      let input = {
        id: recordKeeperId,
        patch,
      }
      updateRecordKeeperOverview({
        variables: {
          input,
        },
      })
        .then((result) => {
          setSaving(false)
          console.log(888, result.data)
          if (result.data?.updateOrg) {
            let { generalInfo } = result.data?.updateOrg
            let newData = { generalInfo } as RecordKeeperDetailsQuery
            setInitial(newData)
            setState(newData)
            setEditMode(false)
          }
        })
        .catch((err) => {
          setSaving(false)
          console.error(err.message)
        })
      setSaving(false)
    }
  }
  return (
    <>
      <RouteLeavingGuard when={editMode} navigate={(path) => history.push(path)} />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid className={"px-0"}>
          <Row>
            <Col>
              {/** CAL-2050*/}
              {/* <div className="pane pane-toolbar sticky-top">
                {auth.checkPermissions(["edit:manager"]) && (
                  <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} cancelEdit={() => setState(initialState)} />
                )}
              </div> */}
              <div className="pane mb-4">
                <Row>
                  <Col sm="4" className="px-3" key={0}>
                    {RecordKeeperSummaryInputList.map(({ property, label, type, subtype, placeholder, subClasses, readonly, required, tooltip, optionSource, options }, idx) => {
                      let propertyVal: any = _.get(currentState, property)
                      let onChangeCallback = (value: any) => handleInputChange(value, property)
                      return (
                        <div key={idx}>
                          <FormInput
                            key={idx}
                            property={property}
                            displayName={label}
                            subClasses={subClasses}
                            type={type}
                            subtype={subtype}
                            placeholder={placeholder}
                            idx={idx}
                            editMode={editMode}
                            propertyVal={propertyVal}
                            updateValue={onChangeCallback}
                            optionSource={optionSource}
                            options={options}
                            readonly={readonly}
                            required={required}
                            tooltip={tooltip}
                            defaultOptions={propertyVal}
                          />
                        </div>
                      )
                    })}
                  </Col>
                  <Col sm="8" className="pl-5 pr-3" key={1}>
                    <CommonClientsComponent {...props} key={`Common-Clients-Component`} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

const RecordKeeperOverview: React.FC<appProps & RecordKeeperRouteProps & RecordKeeperOverviewDetailFragment> = (props) => {
  const { recordKeeperId, auth, match } = props
  const { loading, error, data } = useRecordKeeperDetailsQuery({
    variables: { id: recordKeeperId },
    fetchPolicy: "no-cache",
  })
  const { loading: userLoading, error: userError, data: userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (user === null) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!!data && data.generalInfo?.__typename === "RecordKeeper") {
    return <RecordKeeperOverviewMain data={data} auth={auth} match={match} recordKeeperId={recordKeeperId} />
  } else {
    return <div>data doesn't exist.</div>
  }
}

export default RecordKeeperOverview

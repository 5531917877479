import React, { useState, useEffect, useRef } from 'react'
import { History } from 'history'
import {
  useRouteMatch,
  useHistory,
  match,
  Switch,
  Route,
  Redirect,
  useParams,
} from 'react-router-dom'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'
import { debounce } from 'lodash'

import Auth from '../../../Auth/Auth'
import PlaceHolder from '../../ui/PlaceHolder'
import { HeaderControls, VehiclePicker, VehicleDetails } from '.'
import {
  useProductVehiclesQuery,
  VehicleDetailsQuery,
  useVehicleDetailsQuery,
  useUpdateVehicleMutation,
  VehicleUpdateFields,
  VehicleFields,
  StableValue,
  StableValueFeesInput,
  HedgeFundVehicle,
  HedgeFundVehicleFeesInput,
  PooledVehicleFeesInput,
  SeparateAccountFeesInput,
  VehicleFeeInput,
  Maybe,
  StableValueSeparateAccountFeesInput,
  StableValueCollectiveInvestmentFundFeesInput,
  ClosedEndedVehicleFeesInput,
  FeeBasisCode,
  MutualFundFeesInput,
  MutualFundFeeInput,
  VehicleEntryRedemptionFeesInput,
  ExchangeTradedFundFeesInput,
  VariableAnnuityFeesInput,
  CollectiveInvestmentFundFeesInput,
  CollectiveInvestmentFundCompositeFeesInput,
  PrivateVehicleFeesInput,
  AlternativeFeeInput,
  RealAssetsVehicleFeesInput,
  MutualFund,
  ShareClassCode,
  ClosedEndedVehicle,
  ProductVehiclesQuery,
  useClientPortfolioFeesQuery,
  useUpdateClientPortfolioFeesMutation,
  ClientPortfolioFeesQuery,
  ClientPortfolio,
  ClientPortfolioFeeScheduleItem,
  ClientPortfolioDatedFee,
} from '../../../__generated__/graphql'
import { VehicleType } from './VehicleFees'
import {
  AltFee,
  EntryAndRedemptionFees,
  FeeData,
  PrivateFeeData,
} from './OtherFees/getFeeData'
import { checkIfSet, previousQuarterEnd } from './helper'
import RouteLeavingGuard from '../../Shared/RouteLeavingGuard'
import { PortfolioPicker } from '../ProductCharacteristicsClient'
import { PortfolioFees } from './PortfolioFees'
import { DATE_API_FORMAT } from '../../../helpers/constant'

export type Vehicle = NonNullable<VehicleDetailsQuery['vehicle']>
export type ProductVehiclesPortfolio = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<ProductVehiclesQuery['product']>['product']
          >['vehicles']
        >[0]
      >['vehicle']
    >['relatedClientPortfolioManagerDataCollect']
  >[0]
>
export type Portfolio = NonNullable<ClientPortfolioFeesQuery['clientPortfolio']>

const twoQuartersAgoEnd = moment()
  .subtract(2, 'quarter')
  .endOf('quarter')
  .format(DATE_API_FORMAT)

const pooledVehicleVariants: VehicleType[] = [
  'OpenEndedPooledVehicle',
  'OpenEndedPooledVehiclePrivateEquity',
  'OpenEndedPooledVehicleRealAssets',
  'OpenEndedPooledVehiclePrivateCredit',
]

const separateAccountVariants: VehicleType[] = [
  'OpenEndedSeparateAccount',
  'OpenEndedSeparateAccountRealAssets',
  'OpenEndedSeparateAccountStableValue',
]

interface ProductVehicleProps {
  auth: Auth
  productId: number
}

interface VehicleContainerProps {
  auth: Auth
  history: History
  match: match
  vehicles: Vehicle[]
}

interface PortfolioContainerProps {
  history: History
  match: match
  portfolios: Portfolio[]
}

interface ClosedEndedManagementFeeItem {
  details: string
  feeBasis: {
    code: Maybe<FeeBasisCode>
    value: Maybe<string>
  }
  feesByAum: {
    aumTier: number
    rate: number
  }[]
  feesByYear: {
    rate: number
    yearNumber: number
  }[]
}

interface VehicleUpdateData extends VehicleFields, Partial<FeeData> {
  feeSchedule?: {
    date: string
    feeBalance: {
      managementFee: number
      performanceFee?: number
    }
    feeSchedule: {
      accountSize: number
      managementFee: number
      performanceFee?: number
    }[]
  }
  stableValueFees?: {
    date: string
    annualizedSubAdvisedPctOfApplicableSubadvisedAssets: Maybe<number>
    annualizedWrapPctOfApplicableWrappedAssets: Maybe<number>
  }
  stableValueSeparateAccountFees?: {
    date: string
    annualizedSubAdvisedPctOfTotalPortfolio: Maybe<number>
    annualizedWrapPctOfTotalPortfolio: Maybe<number>
  }
  stableValueCollectiveInvestmentFundFees?: {
    date: string
    allInFee: Maybe<number>
  }
  closedEndedVehicleFees?: {
    managementFees?: {
      investmentPeriod: ClosedEndedManagementFeeItem
      postInvestmentPeriod: ClosedEndedManagementFeeItem
    },
    catchUpFee?: {
      date: string,
      value: string
    },
    clawBackProvisions?: boolean,
  }
}

function getEntryAndRedemptionFees(
  fee: EntryAndRedemptionFees
): VehicleEntryRedemptionFeesInput {
  let formattedFees: VehicleEntryRedemptionFeesInput = {}
  if (fee.entryFees !== undefined) {
    formattedFees.entryFees = fee.entryFees
  }
  if (fee.entryFeePct !== undefined) {
    formattedFees.entryFeePct = parseFloat(fee.entryFeePct)
  }
  if (fee.redemptionFees !== undefined) {
    formattedFees.redemptionFees = fee.redemptionFees
  }
  if (fee.redemptionFeePct !== undefined) {
    formattedFees.redemptionFeePct = parseFloat(fee.redemptionFeePct)
  }
  if (fee.redemptionDays !== undefined) {
    formattedFees.redemptionDays = parseInt(fee.redemptionDays, 10)
  }
  return formattedFees
}

function getAltFee(fee: AltFee): AlternativeFeeInput[] {
  let formattedFee: AlternativeFeeInput = {
    date: fee.date,
    rate: isNaN(parseFloat(fee.rate)) ? null : parseFloat(fee.rate),
    details: fee.details,
  }
  return [formattedFee]
}

function getPrivateVehicleFees(fees: PrivateFeeData): PrivateVehicleFeesInput {
  let formattedFees: PrivateVehicleFeesInput = {}
  if (fees.carry) {
    formattedFees.carry = getAltFee(fees.carry)
  }
  if (fees.offset) {
    formattedFees.offset = getAltFee(fees.offset)
  }
  if (fees.waterfall) {
    formattedFees.waterfall = fees.waterfall
  }
  if (fees.waterfallFurtherDetails) {
    formattedFees.waterfallFurtherDetails = fees.waterfallFurtherDetails
  }
  if (fees.hurdle) {
    formattedFees.hurdle = [
      {
        date: previousQuarterEnd,
        hurdle: isNaN(parseFloat(fees.hurdle.hurdle))
          ? null
          : parseFloat(fees.hurdle.hurdle),
        hurdleReturnType: fees.hurdle.hurdleReturnType || null,
        hurdleCalculationType: fees.hurdle.hurdleCalculationType || null,
      },
    ]
  }
  return formattedFees
}

function getUpdateVehicleData(vehicleData: Vehicle): VehicleUpdateFields {
  let vehicle = {
    fundid: vehicleData.vehicle?.id,
    ...vehicleData.vehicle,
  } as VehicleUpdateData

  const commonVehicleFees: Maybe<VehicleFeeInput>[] | null | undefined =
    vehicle.datedFees

  let stableValueFees: StableValueFeesInput | undefined
  const stableValue = (vehicleData as StableValue)?.stableValue
  if (stableValue) {
    stableValueFees = {
      putProvisions: stableValue.putProvision,
    }
  }
  if (vehicle.stableValueFees) {
    const {
      date,
      annualizedWrapPctOfApplicableWrappedAssets,
      annualizedSubAdvisedPctOfApplicableSubadvisedAssets,
    } = vehicle.stableValueFees
    const fees = {
      date,
      annualizedWrapPctOfApplicableWrappedAssets,
      annualizedSubAdvisedPctOfApplicableSubadvisedAssets,
    }
    if (stableValueFees) {
      stableValueFees.fees = [fees]
    } else {
      stableValueFees = {
        fees: [fees],
      }
    }
  }
  let stableValueSeparateAccountFees:
    | StableValueSeparateAccountFeesInput
    | undefined
  if (vehicle.stableValueSeparateAccountFees) {
    const {
      date,
      annualizedSubAdvisedPctOfTotalPortfolio,
      annualizedWrapPctOfTotalPortfolio,
    } = vehicle.stableValueSeparateAccountFees
    const fees = {
      date,
      annualizedSubAdvisedPctOfTotalPortfolio,
      annualizedWrapPctOfTotalPortfolio,
    }
    stableValueSeparateAccountFees = {
      fees: [fees],
    }
  }

  let stableValueCollectiveInvestmentFundFees:
    | StableValueCollectiveInvestmentFundFeesInput
    | undefined
  if (vehicle.stableValueCollectiveInvestmentFundFees) {
    const { date, allInFee } = vehicle.stableValueCollectiveInvestmentFundFees
    const fees = { date, allInFee }
    stableValueCollectiveInvestmentFundFees = {
      fees: [fees],
    }
  }

  let hedgeFundVehicleFees: HedgeFundVehicleFeesInput | undefined
  const hedgeFundVehicle = (vehicleData as HedgeFundVehicle)?.hedgeFundVehicle
  if (hedgeFundVehicle) {
    hedgeFundVehicleFees = {
      subjectTo25PercentLimit: hedgeFundVehicle.subjectTo25PercentLimit,
      unrelatedBusinessIncomeTax: hedgeFundVehicle.unrelatedBusinessIncomeTax,
      subscriptionPeriod: hedgeFundVehicle.subscriptionPeriod?.code,
      redemptionPeriod: hedgeFundVehicle.redemptionPeriod?.code,
      lockupPeriod: hedgeFundVehicle.lockupPeriod?.code,
      noticePeriod: hedgeFundVehicle.noticePeriod,
      initialPayout: hedgeFundVehicle.initialPayout,
      finalPayout: hedgeFundVehicle.finalPayout,
      gateFundProvision: hedgeFundVehicle.gateFundProvision,
      gateInvestorProvision: hedgeFundVehicle.gateInvestorProvision,
      gateProvisionExplanation: hedgeFundVehicle.gateProvisionExplanation,
    }
  }
  let pooledVehicleFees: PooledVehicleFeesInput | undefined
  let separateAccountFees: SeparateAccountFeesInput | undefined
  if (vehicle.feeSchedule) {
    if (vehicleData.__typename === 'OpenEndedPrivateNonRegisteredHedgeFund') {
      if (hedgeFundVehicleFees) {
        hedgeFundVehicleFees.feeSchedule = [vehicle.feeSchedule]
      } else {
        hedgeFundVehicleFees = {
          feeSchedule: [vehicle.feeSchedule],
        }
      }
    } else if (pooledVehicleVariants.indexOf(vehicleData.__typename) !== -1) {
      pooledVehicleFees = {
        feeSchedule: [vehicle.feeSchedule],
      }
    } else if (separateAccountVariants.indexOf(vehicleData.__typename) !== -1) {
      separateAccountFees = {
        feeSchedule: [vehicle.feeSchedule],
      }
    }
  }

  if (vehicle.hedgeFundVehicle) {
    const {
      highWaterMark,
      custodyIncludedInFee,
      mostFavoredNation,
      rebates,
      catchupFee,
      hurdleBenchmark,
      redemptionFees,
      redemptionDays,
      redemptionFeePct,
    } = vehicle.hedgeFundVehicle
    if (!hedgeFundVehicleFees) {
      hedgeFundVehicleFees = {}
    }
    if (highWaterMark !== undefined) {
      hedgeFundVehicleFees.highWaterMark = highWaterMark
    }
    if (custodyIncludedInFee !== undefined) {
      hedgeFundVehicleFees.custodyIncludedInFee = custodyIncludedInFee
    }
    if (mostFavoredNation !== undefined) {
      hedgeFundVehicleFees.mostFavoredNation = mostFavoredNation
    }
    if (rebates !== undefined) {
      hedgeFundVehicleFees.rebates = rebates
    }
    if (catchupFee) {
      hedgeFundVehicleFees.catchupFee = [
        {
          date: previousQuarterEnd,
          catchupFee: isNaN(parseFloat(catchupFee.value))
            ? null
            : parseFloat(catchupFee.value),
        },
      ]
    }
    if (hurdleBenchmark) {
      hedgeFundVehicleFees.hurdleBenchmark = [
        {
          date: previousQuarterEnd,
          benchmark: hurdleBenchmark.benchmark || null,
          benchmarkAdd: isNaN(parseFloat(hurdleBenchmark.benchmarkAdd))
            ? null
            : parseFloat(hurdleBenchmark.benchmarkAdd),
        },
      ]
    }
    if (redemptionFees !== undefined) {
      hedgeFundVehicleFees.redemptionFees = redemptionFees
    }
    if (redemptionFeePct !== undefined) {
      hedgeFundVehicleFees.redemptionFeePct = isNaN(+redemptionFeePct)
        ? null
        : +redemptionFeePct
    }
    if (redemptionDays !== undefined) {
      hedgeFundVehicleFees.redemptionDays = isNaN(+redemptionDays)
        ? null
        : +redemptionDays
    }
  }

  if (vehicle.pooledVehicle) {
    const entryRedemptionFees = getEntryAndRedemptionFees(vehicle.pooledVehicle)
    if (pooledVehicleFees) {
      pooledVehicleFees.entryRedemptionFees = entryRedemptionFees
    } else {
      pooledVehicleFees = {
        entryRedemptionFees,
      }
    }
  }

  let closedEndedVehicleFees: ClosedEndedVehicleFeesInput | undefined
  if (vehicle.closedEndedVehicleFees?.managementFees) {
    const managementFees = vehicle.closedEndedVehicleFees.managementFees
    const {
      feeBasis: { code: investmentPeriodCode },
      ...investmentPeriod
    } = managementFees.investmentPeriod
    const {
      feeBasis: { code: postInvestmentPeriodCode },
      ...postInvestmentPeriod
    } = managementFees.postInvestmentPeriod

    closedEndedVehicleFees = {
      managementFees: {
        investmentPeriod: {
          feeBasis: investmentPeriodCode,
          feesByAum: investmentPeriod.feesByAum.map(({ aumTier, rate }) => ({
            aumTier: +aumTier,
            rate: +rate,
          })),
          feesByYear: investmentPeriod.feesByYear.map(
            ({ yearNumber, rate }) => ({ yearNumber, rate: +rate })
          ),
          details: investmentPeriod.details,
        },
        postInvestmentPeriod: {
          feeBasis: postInvestmentPeriodCode,
          feesByAum: postInvestmentPeriod.feesByAum.map(
            ({ aumTier, rate }) => ({ aumTier: +aumTier, rate: +rate })
          ),
          feesByYear: postInvestmentPeriod.feesByYear.map(
            ({ yearNumber, rate }) => ({ yearNumber, rate: +rate })
          ),
          details: postInvestmentPeriod.details,
        },
      },
    }
  }

  const closedEndedVehicle = (vehicleData as ClosedEndedVehicle)
    ?.closedEndedVehicle
  if (closedEndedVehicle) {
    const fees = vehicle?.closedEndedVehicle
    let result: any = {}
    if (fees?.catchupFee) {
      const catchupFee = fees?.catchupFee
      if(catchupFee){
        result.catchupFee = [{
          date: catchupFee?.date,
          catchupFee: isNaN(parseFloat(catchupFee?.value))
        ? null
        : parseFloat(catchupFee?.value),
        }]
      }
    }
    if (
      fees?.clawBackProvisions !== undefined &&
      fees?.clawBackProvisions !== null
    ) {
      result.clawBackProvisions = fees?.clawBackProvisions
    }
    if (closedEndedVehicleFees) {
      closedEndedVehicleFees.vintageFirstCashFlow =
        closedEndedVehicle.vintageFirstCashFlow
      if(result){
        closedEndedVehicleFees = {
          ...closedEndedVehicleFees,
          ...result,
        }
      }
    } else {
      closedEndedVehicleFees = {
        vintageFirstCashFlow: closedEndedVehicle.vintageFirstCashFlow,
        ...result,
      }
    }
  }

  let mutualFundFees: MutualFundFeesInput | undefined
  if (vehicle.mutualFund) {
    const entryRedemptionFees: VehicleEntryRedemptionFeesInput =
      getEntryAndRedemptionFees(vehicle.mutualFund)
    mutualFundFees = { entryRedemptionFees }
    if (vehicle.mutualFund.datedFees) {
      const {
        managementFee,
        fee12b1,
        administrativeOperatingExpenses,
        subAdvisor,
        totalExpense,
      } = vehicle.mutualFund.datedFees
      const datedFees: MutualFundFeeInput[] = [
        {
          date: previousQuarterEnd,
        },
      ]
      if (managementFee) {
        datedFees[0].managementFee = parseFloat(managementFee.value)
      }
      if (fee12b1) {
        datedFees[0].fee12b1 = parseFloat(fee12b1.value)
      }
      if (administrativeOperatingExpenses) {
        datedFees[0].administrativeOperatingExpenses = parseFloat(
          administrativeOperatingExpenses.value
        )
      }
      if (subAdvisor) {
        datedFees[0].subAdvisor = parseFloat(subAdvisor.value)
      }
      if (totalExpense) {
        datedFees[0].totalExpense = parseFloat(totalExpense.value)
      }
      mutualFundFees.datedFees = datedFees
    }
  }

  let exchangeTradedFundFees: ExchangeTradedFundFeesInput | undefined
  if (vehicle.exchangeTradedFund?.datedFees.totalExpense) {
    const totalExpense = vehicle.exchangeTradedFund.datedFees.totalExpense
    const value = isNaN(parseFloat(totalExpense.value))
      ? null
      : parseFloat(totalExpense.value)
    exchangeTradedFundFees = {
      datedFees: [
        {
          date: previousQuarterEnd,
          totalExpense: value,
        },
      ],
    }
  }

  let variableAnnuityFees: VariableAnnuityFeesInput | undefined
  if (vehicle.variableAnnuity) {
    const entryRedemptionFees: VehicleEntryRedemptionFeesInput =
      getEntryAndRedemptionFees(vehicle.variableAnnuity)
    variableAnnuityFees = { entryRedemptionFees }
    if (vehicle.variableAnnuity.datedFees) {
      const { totalExpense } = vehicle.variableAnnuity.datedFees
      if (totalExpense) {
        variableAnnuityFees.datedFees = [
          {
            date: previousQuarterEnd,
            totalExpense: parseFloat(totalExpense.value),
          },
        ]
      }
    }
  }

  let collectiveInvestmentFundFees:
    | CollectiveInvestmentFundFeesInput
    | undefined
  if (vehicle.collectiveInvestmentFund) {
    const entryRedemptionFees: VehicleEntryRedemptionFeesInput =
      getEntryAndRedemptionFees(vehicle.collectiveInvestmentFund)
    collectiveInvestmentFundFees = { entryRedemptionFees }
    if (vehicle.collectiveInvestmentFund.datedFees) {
      const { managementFee, totalExpense } =
        vehicle.collectiveInvestmentFund.datedFees
      const datedFees: MutualFundFeeInput[] = [
        {
          date: previousQuarterEnd,
        },
      ]
      if (managementFee) {
        datedFees[0].managementFee = parseFloat(managementFee.value)
      }
      if (totalExpense) {
        datedFees[0].totalExpense = parseFloat(totalExpense.value)
      }
      collectiveInvestmentFundFees.datedFees = datedFees
    }
  }

  let collectiveInvestmentFundCompositeFees:
    | CollectiveInvestmentFundCompositeFeesInput
    | undefined
  if (vehicle.collectiveInvestmentFundComposite) {
    const entryRedemptionFees: VehicleEntryRedemptionFeesInput =
      getEntryAndRedemptionFees(vehicle.collectiveInvestmentFundComposite)
    collectiveInvestmentFundCompositeFees = { entryRedemptionFees }
  }

  let privateVehicleFees: PrivateVehicleFeesInput | undefined
  if (vehicle.privateCreditVehicle || vehicle.privateEquityVehicle) {
    if (vehicle.privateCreditVehicle) {
      privateVehicleFees = getPrivateVehicleFees(vehicle.privateCreditVehicle)
    }
    if (vehicle.privateEquityVehicle) {
      privateVehicleFees = getPrivateVehicleFees(vehicle.privateEquityVehicle)
    }
  }

  let realAssetsVehicleFees: RealAssetsVehicleFeesInput | undefined
  if (vehicle.realAssetsVehicle) {
    realAssetsVehicleFees = {}
    const fees = vehicle.realAssetsVehicle
    if (fees.hurdle) {
      realAssetsVehicleFees.hurdle = [
        {
          date: previousQuarterEnd,
          hurdle: isNaN(parseFloat(fees.hurdle.hurdle))
            ? null
            : parseFloat(fees.hurdle.hurdle),
          hurdleReturnType: fees.hurdle.hurdleReturnType || null,
          hurdleCalculationType: fees.hurdle.hurdleCalculationType || null,
        },
      ]
    }

    if (fees.performanceFee) {
      realAssetsVehicleFees.performanceFee = getAltFee(fees.performanceFee)
    }
    if (fees.acquisitionFee) {
      realAssetsVehicleFees.acquisitionFee = getAltFee(fees.acquisitionFee)
    }
    if (fees.dispositionFee) {
      realAssetsVehicleFees.dispositionFee = getAltFee(fees.dispositionFee)
    }
    if (fees.waterfall) {
      realAssetsVehicleFees.waterfall = fees.waterfall
    }
    if (fees.waterfallFurtherDetails) {
      realAssetsVehicleFees.waterfallFurtherDetails = fees.waterfallFurtherDetails
    }
    if (fees.carry) {
      realAssetsVehicleFees.carry = getAltFee(fees.carry)
    }
  }

  let shareClassCode: ShareClassCode | undefined | null
  const mutualFundVehicle = (vehicleData as MutualFund)?.mutualFund
  if (mutualFundVehicle) {
    if (mutualFundVehicle.shareClass?.code !== undefined) {
      shareClassCode = mutualFundVehicle.shareClass.code || null
    }
  }

  return {
    graphName: vehicle.graphName || null,
    type: vehicle.type?.code || null,
    identifiers: {
      cusip: vehicle.identifiers?.cusip || null,
      ticker: vehicle.identifiers?.ticker || null,
      isin: vehicle.identifiers?.isin || null,
      sedol: vehicle.identifiers?.sedol || null,
    },
    capitalStructure: vehicle.capitalStructure || null,
    status: vehicle.status?.code || null,
    inceptionDate: vehicle.inceptionDate || null,
    closeDate: vehicle.closeDate || null,
    privatePlacement: vehicle.privatePlacement,
    isRegisteredWithSecuritiesAndExchangeComission:
      vehicle.isRegisteredWithSecuritiesAndExchangeComission,
    domicile: vehicle.domicile?.code || null,
    countryOfIncorporation: vehicle.countryOfIncorporation?.code || null,
    stateOfIncorporation: vehicle.stateOfIncorporation?.code || null,
    currency: vehicle.currency?.code || null,
    frequencyOfValuation: vehicle.frequencyOfValuation?.code || null,
    frequencyOfDistribution: vehicle.frequencyOfDistribution?.code || null,
    liquidityOnPurchases: vehicle.liquidityOnPurchases?.code || null,
    liquidityOnSales: vehicle.liquidityOnSales?.code || null,
    dividendReinvest: vehicle.dividendReinvest,
    employeeRetirementIncomeSecurityActTaxExempt:
      vehicle.employeeRetirementIncomeSecurityActTaxExempt,
    unitedStatesTaxable: vehicle.unitedStatesTaxable,
    unitedStatesTaxExempt: vehicle.unitedStatesTaxExempt,
    lendSecurities: vehicle.lendSecurities,
    offsetGainsWithLosses: vehicle.offsetGainsWithLosses,
    is529: vehicle.is529,
    shareClassCode,
    commonVehicleFees,
    stableValueFees,
    hedgeFundVehicleFees,
    pooledVehicleFees,
    separateAccountFees,
    stableValueSeparateAccountFees,
    stableValueCollectiveInvestmentFundFees,
    closedEndedVehicleFees,
    mutualFundFees,
    exchangeTradedFundFees,
    variableAnnuityFees,
    collectiveInvestmentFundFees,
    collectiveInvestmentFundCompositeFees,
    privateVehicleFees,
    realAssetsVehicleFees,
  }
}

const VehicleContainer: React.FC<VehicleContainerProps> = ({
  history,
  match,
  vehicles,
  auth,
}) => {
  const [search, setSearch] = useState('')
  const [editing, setEditing] = useState(false)
  const vehicle = useRef<Vehicle>({} as Vehicle)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setVehicleState] = useState<Vehicle | null>(null)

  const { fundid } = useParams<{ fundid: string }>()

  const { error, loading, data } = useVehicleDetailsQuery({
    variables: {
      fundid: fundid as string,
      viewPrivate: auth.checkPermissions(['view:vehicle_private']),
    },
    fetchPolicy: 'no-cache',
  })

  const [updateVehicle] = useUpdateVehicleMutation()

  useEffect(() => {
    if (
      data?.vehicle &&
      vehicle.current?.vehicle?.id !== data.vehicle.vehicle?.id
    ) {
      vehicle.current = data.vehicle
      setVehicleState(data.vehicle) // This will cause a re-render
    }
  }, [data?.vehicle])

  const setVehicle = (vehicleData: Vehicle) => {
    vehicle.current = vehicleData
  }

  const save = () => {
    // wait for 600ms before calling save to make sure the state is fully updated
    // before sending the request
    setTimeout(() => {
      if (vehicle.current?.vehicle) {
        let patch = getUpdateVehicleData(vehicle.current)
        if (!auth.checkPermissions(['edit:vehicle_private'])) {
          // remove private fields
          let { graphName, ...rest } = patch
          patch = rest
        }
        updateVehicle({
          variables: {
            input: {
              id: vehicle.current?.vehicle?.id as string,
              patch,
            },
          },
        })
      }
    }, 600)
  }

  return (
    <>
      <RouteLeavingGuard
        when={editing}
        navigate={(path) => history.push(path)}
      />
      <Row>
        <Col>
          <HeaderControls
            type="vehicle"
            search={search}
            setSearch={setSearch}
            setEditing={setEditing}
            editing={editing}
            save={save}
          />
        </Col>
      </Row>
      <Row>
        <VehiclePicker
          history={history}
          match={match}
          search={search}
          vehicles={vehicles}
          selectedVehicle={fundid}
        />
        <Col md={8} lg={9} className="pl-1">
          {error ? (
            <div>{error.message}</div>
          ) : loading ? (
            <PlaceHolder />
          ) : vehicle ? (
            <VehicleDetails
              key={vehicle?.current?.vehicle?.id}
              vehicle={vehicle.current}
              setVehicle={setVehicle}
              editing={editing}
              auth={auth}
            />
          ) : (
            <div>Data doesn't exist</div>
          )}
        </Col>
      </Row>
    </>
  )
}

const PortfolioContainer: React.FC<PortfolioContainerProps> = ({
  history,
  match,
  portfolios,
}) => {
  const [search, setSearch] = useState('')
  const [editing, setEditing] = useState(false)
  const [portfolio, setPortfolio] = useState<Portfolio | null>(null)

  const { portfolioId } = useParams<{ portfolioId: string }>()

  const { error, loading, data } = useClientPortfolioFeesQuery({
    variables: {
      id: parseInt(portfolioId),
    },
  })

  const [updateClientPortfolio] = useUpdateClientPortfolioFeesMutation()

  useEffect(() => {
    if (data?.clientPortfolio) {
      setPortfolio(data.clientPortfolio)
    }
  }, [data?.clientPortfolio])

  useEffect(() => {
    setEditing(false)
  }, [portfolioId])

  const save = async () => {
    const feeScheduleAdd = (portfolio?.feeSchedule
      ?.filter(
        (fee) =>
          (fee?.date === '9999-12-31' || fee?.date === twoQuartersAgoEnd) &&
          checkIfSet(fee.accountSize && checkIfSet(fee.managementFee))
      )
      ?.map((item) => ({
        date: item?.date,
        accountSize: item?.accountSize,
        managementFee: item?.managementFee,
      })) || []) as ClientPortfolioFeeScheduleItem[]
    const feeScheduleRemove = ['9999-12-31', twoQuartersAgoEnd]
    const datedFeeAdd = portfolio?.datedFees
      ?.filter(
        (datedFee): datedFee is ClientPortfolioDatedFee =>
          datedFee !== null &&
          datedFee !== undefined &&
          datedFee?.date === previousQuarterEnd
      )
      .map((item) => {
        return {
          date: item.date,
          totalExpense: item.totalExpense,
          revenueShare: item.revenueShare,
          annualizedSubAdvisedPctOfApplicableSubadvisedAssets:
            item.annualizedSubAdvisedPctOfApplicableSubadvisedAssets,
          annualizedWrapPctOfApplicableWrappedAssets:
            item.annualizedWrapPctOfApplicableWrappedAssets,
          annualizedSubAdvisedPctOfTotalPortfolio:
            item.annualizedSubAdvisedPctOfTotalPortfolio,
          annualizedWrapPctOfTotalPortfolio:
            item.annualizedWrapPctOfTotalPortfolio,
        }
      })
    const datedFeeRemove = [previousQuarterEnd]
    await updateClientPortfolio({
      variables: {
        input: {
          id: parseInt(portfolioId),
          patch: {
            feeSchedule: {
              add: feeScheduleAdd,
              remove: feeScheduleRemove,
            },
            datedFees: {
              add: datedFeeAdd,
              remove: datedFeeRemove,
            },
          },
        },
      },
    })
  }

  const selectedPortfolio = portfolios.find(
    (portfolio) => portfolio.id === parseInt(portfolioId)
  )

  return (
    <>
      <RouteLeavingGuard
        when={editing}
        navigate={(path) => history.push(path)}
      />
      <Row>
        <Col>
          <HeaderControls
            type="portfolio"
            search={search}
            setSearch={setSearch}
            setEditing={setEditing}
            editing={editing}
            save={save}
          />
        </Col>
      </Row>
      <Row>
        <PortfolioPicker
          history={history}
          match={match}
          search={search}
          portfolios={portfolios as ClientPortfolio[]}
          selectedPortfolio={selectedPortfolio as ClientPortfolio}
        />
        <Col md={8} lg={9} className="pl-1">
          {error ? (
            <div>{error.message}</div>
          ) : loading ? (
            <PlaceHolder />
          ) : portfolio ? (
            <PortfolioFees
              portfolio={portfolio}
              setPortfolio={setPortfolio}
              editing={editing}
            />
          ) : (
            <div>Portfolio not found</div>
          )}
        </Col>
      </Row>
    </>
  )
}

export const ProductVehicles: React.FC<ProductVehicleProps> = ({
  productId,
  auth,
}) => {
  const match = useRouteMatch<{ type: string }>()
  const history = useHistory()

  const { data, loading, error } = useProductVehiclesQuery({
    variables: {
      id: productId,
    },
    fetchPolicy: 'no-cache',
  })

  const vehicleTypeToCollectClientFees = [
    'OpenEndedPooledVehicle',
    'OpenEndedCollectiveInvestmentFundComposite',
    'OpenEndedSeparateAccount',
    'OpenEndedPooledVehiclePrivateEquity',
    'OpenEndedPooledVehicleRealAssets',
    'OpenEndedSeparateAccountRealAssets',
    'OpenEndedCollectiveInvestmentFundStableValueComposite',
    'OpenEndedSeparateAccountStableValue',
    'OpenEndedCollectiveInvestmentFundCompositeRealAssets',
    'OpenEndedPooledVehiclePrivateCredit',
  ]

  const portfolios: ProductVehiclesPortfolio[] = []

  data?.product?.product?.vehicles?.forEach((vehicle) => {
    vehicle?.vehicle?.relatedClientPortfolioManagerDataCollect?.forEach(
      (portfolio) => {
        if (
          portfolio?.relatedVehicle?.__typename &&
          vehicleTypeToCollectClientFees.indexOf(
            portfolio.relatedVehicle.__typename
          ) !== -1 &&
          portfolio?.clientSpecificDataCollectionFields
            ?.questionnaireFeesRequired
        ) {
          portfolios.push({
            ...portfolio,
            name:
              portfolio?.clientSpecificDataCollectionFields?.nameOverride ||
              portfolio.name,
          })
        }
      }
    )
  })

  portfolios.sort((portfolioA, portfolioB) => {
    const nameA =
      portfolioA?.clientSpecificDataCollectionFields?.nameOverride || ''
    const nameB =
      portfolioB?.clientSpecificDataCollectionFields?.nameOverride || ''
    return nameA.localeCompare(nameB)
  })

  const canEditClientPortfolio = auth.checkPermissions([
    'edit:client_portfolios',
  ])
  const canSeeClientPortfolio = auth.checkPermissions([
    'view:client_portfolios',
  ])
  const clientCanSeeClientPortfolio = auth.checkPermissions([
    'view:my_client_portfolio',
  ])
  const clientPortfolioPermissionCheck =
    canEditClientPortfolio ||
    canSeeClientPortfolio ||
    clientCanSeeClientPortfolio

  const defaultPath = () => {
    const vehicles = data?.product?.product?.vehicles
    if (vehicles && vehicles.length > 0 && match.params.type === 'vehicles') {
      const fundid = vehicles.sort((vehicleA, vehicleB) => {
        const nameA = vehicleA?.vehicle?.name || ''
        const nameB = vehicleB?.vehicle?.name || ''
        return nameA.localeCompare(nameB)
      })[0]?.vehicle?.id
      if (fundid) {
        return `${match.path}/${fundid}`
      }
    }
    if (portfolios.length > 0 && match.params.type === 'portfolios') {
      const portfolioId = portfolios[0]?.id
      if (portfolioId) {
        return `${match.path}/${portfolioId}`
      }
    }
    return `${match.path}/0`
  }

  const urlWithoutType =
    match.params && match.params.type
      ? match.url.replace(`/${match.params.type}`, '')
      : match.url
  const heading = (
    <Nav className="sub-nav sub-nav-secondary" tabs>
      <NavItem>
        <NavLink
          className={classnames({
            active: match.url.indexOf(`${urlWithoutType}/vehicles`) === 0,
          })}
          onClick={() => history.push(`${urlWithoutType}/vehicles`)}
        >
          Vehicles
        </NavLink>
      </NavItem>
      {clientPortfolioPermissionCheck && portfolios.length > 0 && (
        <NavItem>
          <NavLink
            className={classnames({
              active: match.url.indexOf(`${urlWithoutType}/portfolios`) === 0,
            })}
            onClick={() => history.push(`${urlWithoutType}/portfolios`)}
          >
            Client Portfolio Fees
          </NavLink>
        </NavItem>
      )}
    </Nav>
  )

  return (
    <Container fluid>
      <Row>
        <Col>
          {heading}
          <Switch>
            <Route path="/products/:productId(\d+)/vehicles/:fundid">
              {loading ? (
                <div className="pane">
                  <PlaceHolder />
                </div>
              ) : error ? (
                <div className="pane">
                  <p>{error?.message}</p>
                </div>
              ) : data?.product?.product?.vehicles ? (
                <VehicleContainer
                  history={history}
                  match={match}
                  vehicles={data.product?.product?.vehicles as Vehicle[]}
                  auth={auth}
                />
              ) : (
                <div>Data doesn't exist</div>
              )}
            </Route>
            {clientPortfolioPermissionCheck && (
              <Route path="/products/:productId(\d+)/portfolios/:portfolioId">
                {loading ? (
                  <div className="pane">
                    <PlaceHolder />
                  </div>
                ) : error ? (
                  <div className="pane">
                    <p>{error?.message}</p>
                  </div>
                ) : data?.product?.product?.vehicles ? (
                  <PortfolioContainer
                    history={history}
                    match={match}
                    portfolios={portfolios}
                  />
                ) : (
                  <div>Data doesn't exist</div>
                )}
              </Route>
            )}
            {data && <Redirect from={match.path} to={defaultPath()} />}
          </Switch>
        </Col>
      </Row>
    </Container>
  )
}

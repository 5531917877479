import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import numbro from 'numbro'
import omitDeep from 'omit-deep-lodash'
import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container, Form, Row } from 'reactstrap'

import Auth from "../../../Auth/Auth"
import exportTables from '../../../helpers/exportTable'
import { getNewStateObject } from '../../../helpers/helpers'
import { getProductType } from '../../../helpers/productFunction'
import {
  ClosedEnded,
  DealPolicies,
  DealPolicyInput,
  FinancingType,
  Limitations,
  LongShortPolicy,
  OpenEnded,
  Product, ProductGuidelinesDocument, ProductGuidelinesQuery,
  RealAssets,
  RiskStatisticsTargets,
  UpdateProductGuidelinesInput, useProductGuidelinesQuery,
  useUpdateProductGuidelinesMutation
} from '../../../__generated__/graphql'
import ErrorDisplay from '../../Shared/ErrorDisplay'
import RouteLeavingGuard from '../../Shared/RouteLeavingGuard'
import EditButtons from '../../ui/EditButtons'
import PlaceHolder from '../../ui/PlaceHolder'
import DealLimitationsTable from './DealLimitations'
import FinancingTypeTable from './FinancingTypeTable'
import LimitationsTable from './Limitations'
import LongShortPolicyTable from './LongShortPolicyTable'
import ManagementPolicyTable, { ManagementPolicyTableRowDef } from './ManagementPolicyTable'
import RiskStatisticTargetsTable from './RiskStatisticTargetsTable'
import { VolatilityComponent } from './Volatility'

const getManagementPolicyTableData = (
  currentState: any,
  productType: { [type: string]: boolean }
) => {
  let result = {} as any
  let defaultValue = {
    low: null,
    high: null,
    target: null,
    explanation: null
  }
  ManagementPolicyTableRowDef.map(row => {
    let { field } = row
    let propertyArray = field.split(".")
    let productTypeForField = propertyArray[0] || "product"
    if (productType[productTypeForField]) {
      _.set(result, field, _.get(currentState, field) || defaultValue)
    }
  })
  return result
}

const getInitialState = (data: ProductGuidelinesQuery) => {
  const { product } = data.product as Product
  let state = { __typename: data.product?.__typename, product } as any
  const { openEnded } = data.product as OpenEnded
  const { closedEnded } = data.product as ClosedEnded
  const { realAssets } = data.product as RealAssets

  if (openEnded) {
    state.openEnded = openEnded
  } else if (closedEnded) {
    state.closedEnded = closedEnded
  }
  if (realAssets) {
    state.realAssets = realAssets
    // } else if (privateEquity) {
    //   state.privateEquity = privateEquity
    // } else if (privateCredit) {
    //   state.privateCredit = privateCredit
    // } else if (hedgeFund) {
    //   state.hedgeFund = hedgeFund
  }

  return state
}

const showManagementPolicyTable = (productType: {
  [name: string]: boolean
}) => {
  if (productType["passive"] || productType["hedgeFund"]) {
    return false
  }
  return !!(productType["openEnded"] || productType["realAssets"])
}

const showPrivateOnly = (productType: { [name: string]: boolean }) =>
  productType["closedEnded"] &&
  (productType["privateEquity"] || productType["privateCredit"])

const ProductOverviewGuidelines: React.FC<{ productId: number, auth: Auth }> = props => {
  const { productId, auth } = props
  const { loading, error, data } = useProductGuidelinesQuery({
    variables: { id: productId },
    // fetchPolicy: 'network-only'
  })

  if (loading) {
    return <PlaceHolder />
  }
  if (error) {
    return (
      <ErrorDisplay error={error}/>
    )
  }
  if (!data || !data.product) {
    return <p>Product Data not found</p>
  }
  return <Display data={data} productId={productId} auth={auth}/>
}

const getDiffState = (initialState: any, currentState: any, properties: string[]) => {
  let result: any = {}
  properties.map(property => {
    if(property !== "__typename" && currentState?.hasOwnProperty(property) && !_.isEqual(currentState[property], initialState[property])) {
      result[property] = currentState[property]
    }
  })
  return result
}

const Display: React.FC<{
  data: ProductGuidelinesQuery
  productId: number
  auth: Auth
}> = ({ data, productId, auth }) => {
  const [productType] = useState<{ [type: string]: boolean }>(
    getProductType(data as any)
  )
  const history = useHistory()
  const [currentState, setState] = useState(getInitialState(data))
  const [initialState, setInitialState] = useState(currentState)
  const [mgmtPolicyTableData, setMgmtPolicyTableData] = useState(
    getManagementPolicyTableData(currentState, productType)
  )
  const [updateProductGuidelines] = useUpdateProductGuidelinesMutation()
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const handleChangeTable = (newValue: any, property: string) => {
    let newState = getNewStateObject({
      state: currentState,
      newValue,
      property
    })
    setState(newState)
  }
  useEffect(() => {
    const newState = getManagementPolicyTableData(currentState, productType)
    setMgmtPolicyTableData(newState)
  }, [currentState.product, currentState.realAssets])

  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const getInputs = (state: any) => {
    let newState = {} as any
    let product = state.product
    if (!_.isEmpty(product?.volatility)) {
      newState = { ...newState, volatility: product.volatility }
    }
    if (!_.isEmpty(product?.riskManagementPolicy)) {
      newState["riskManagementPolicy"] = product.riskManagementPolicy
    }
    if (productType["openEnded"]) {
      let { openEnded } = state
      if (!_.isEmpty(openEnded?.longShortPolicy)) {
        newState["longShortPolicy"] = openEnded.longShortPolicy
      }
      if (!_.isEmpty(openEnded?.riskStatisticsTargets)) {
        newState["riskStatisticsTargets"] = openEnded.riskStatisticsTargets
      }
    } else if (productType["closedEnded"]) {
      let { closedEnded } = state
      if (!_.isEmpty(closedEnded?.financingType)) {
        let { shortTerm, longTerm } = closedEnded.financingType
        newState["financingType"] = {
          shortTerm: { ...shortTerm, basis: shortTerm.basis.code },
          longTerm: { ...longTerm, basis: longTerm.basis.code }
        }
      }
      if (!_.isEmpty(closedEnded?.dealPolicies)) {
        // do not send null unless modified from initial
        let dealPolicies =  closedEnded.dealPolicies
        let numberOfInvestments = dealPolicies.numberOfInvestments
        let diffState = getDiffState(initialState?.closedEnded?.dealPolicies, dealPolicies, 
          Object.keys(dealPolicies))
        if(!_.isEmpty(numberOfInvestments)) {
          let diff = getDiffState(initialState?.closedEnded?.dealPolicies.numberOfInvestments, dealPolicies.numberOfInvestments, Object.keys(numberOfInvestments))
          let newValue = ["lower", "higher", "average", "median", "explanation"].reduce((acc: any, property, idx) => {
            if(diff.hasOwnProperty(property)) {
              if(idx === 4) {
                acc[property] = diff[property] || null
              }else {
                acc[property] = parseFloat(numbro(Math.floor(diff[property] || 0)).format()) || null
              }
            }
            return acc
          }, {})
          if(!_.isEmpty(newValue)) {
            newState["dealPolicies"] = {...diffState, numberOfInvestments: newValue}
          }else {
            _.unset(diffState, ["numberOfInvestments"])
            newState["dealPolicies"] = diffState
          }
        }else {
          newState["dealPolicies"] = diffState
        }
      }
    }
    if (productType["realAssets"]) {
      let realAssets = state.realAssets
      if (!_.isEmpty(realAssets?.propertyLeverage)) {
        newState["riskManagementPolicy"] = {
          ...newState["riskManagementPolicy"],
          propertyLeverage: realAssets?.propertyLeverage
        }
      }
      if (!_.isEmpty(realAssets?.limitations)) {
        // naming inconsistency
        newState["limitation"] = realAssets.limitations
      }
    }
    return newState
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let inputState = getInputs(currentState)
    let state = omitDeep(inputState, "__typename")
    let input = { id: productId, patch: state } as UpdateProductGuidelinesInput
    let variables = { input }
    updateProductGuidelines({ variables,
      update: (cache, { data }) => {
        cache.writeQuery({
          query: ProductGuidelinesDocument,
          variables: { id: productId },
          data: data?.updateProductGuidelines,
        })
      }, })
      .then(result => {
        setSaving(false)
        if (result.data?.updateProductGuidelines) {
          const data = result.data?.updateProductGuidelines as any
          let newState = getInitialState(data)
          setState(newState)
          setInitialState(newState)
          setEditMode(false)
          console.log("success")
        }
      })
      .catch(err => {
        setSaving(false)
        console.log(err.message)
      })
  }
  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid className={"px-0"}>
          <Row>
            <Col>
              <div className="pane pane-toolbar sticky-top">
                <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
                  Export CSV
                  <img src='/assets/CSV.svg' className="ml-2"/>
                </Button>
                {auth.checkPermissions(["edit:manager"]) &&
                  <EditButtons 
                    editMode={editMode} 
                    setEditMode={setEditMode} 
                    saving={saving} 
                    onSubmit={handleSubmit} 
                    cancelEdit={() => setState(initialState)}
                  />
                }
              </div>
              {/* <div className="pane px-4 py-2">
                <span
                  className={"bg-success"}
                >{`ProductType: ${currentState.__typename}`}</span>
              </div> */}
              <div className="pane">
                <Row>
                  <Col sm="11" className="pl-3 pr-2">
                    {(productType["openEnded"] || productType["hedgeFund"]) && (
                      <RiskStatisticTargetsTable
                        data={
                          (_.get(
                            currentState,
                            "openEnded.riskStatisticsTargets"
                          ) || {}) as RiskStatisticsTargets
                        }
                        editMode={editMode}
                        handleChange={(newState: any, property: string) =>
                          handleChangeTable(
                            newState,
                            "openEnded.riskStatisticsTargets." + property
                          )
                        }
                      />
                    )}
                    {showManagementPolicyTable(productType) && (
                      <ManagementPolicyTable
                        data={mgmtPolicyTableData}
                        editMode={editMode}
                        handleChange={(newState: any, property: string) =>
                          handleChangeTable(newState, property)
                        }
                      />
                    )}
                    {!productType["passive"] && productType["openEnded"] && (
                      <LongShortPolicyTable
                        data={
                          (_.get(currentState, "openEnded.longShortPolicy") ||
                            {}) as LongShortPolicy
                        }
                        editMode={editMode}
                        handleChange={(newState: any, property: string) =>
                          handleChangeTable(
                            newState,
                            "openEnded.longShortPolicy." + property
                          )
                        }
                      />
                    )}
                    {productType["hedgeFund"] && (
                      <VolatilityComponent
                        data={_.get(currentState, "product.volatility") || {}}
                        editMode={editMode}
                        handleChange={(newState: any, property: string) =>
                          handleChangeTable(
                            newState,
                            "product.volatility." + property
                          )
                        }
                      />
                    )}
                    {productType["realAssets"] && (
                      <LimitationsTable
                        data={
                          (_.get(currentState, "realAssets.limitations") ||
                            {}) as Limitations
                        }
                        editMode={editMode}
                        handleChange={(newState: any, property: string) =>
                          handleChangeTable(
                            newState,
                            "realAssets.limitations." + property
                          )
                        }
                        dealPoliciesData={(_.get(currentState, "closedEnded.dealPolicies") ||
                          {}) as DealPolicies}
                        handleDealPoliciesChange={(newState: any, property: string) =>
                          handleChangeTable(
                            newState,
                            "closedEnded.dealPolicies." + property
                          )
                        }
                      />
                    )}
                    {showPrivateOnly(productType) && (
                      <FinancingTypeTable
                        data={
                          (_.get(currentState, "closedEnded.financingType") ||
                            {}) as FinancingType
                        }
                        editMode={editMode}
                        handleChange={(newState: any, property: string) =>
                          handleChangeTable(
                            newState,
                            "closedEnded.financingType." + property
                          )
                        }
                      />
                    )}
                    {showPrivateOnly(productType) && (
                      <DealLimitationsTable
                        data={
                          (_.get(currentState, "closedEnded.dealPolicies") ||
                            {}) as DealPolicies
                        }
                        editMode={editMode}
                        handleChange={(newState: any, property: string) =>
                          handleChangeTable(
                            newState,
                            "closedEnded.dealPolicies." + property
                          )
                        }
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export default ProductOverviewGuidelines

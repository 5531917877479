import { disableFragmentWarnings, gql } from "@apollo/client"

import {
  FILE_BASIC_INFO_FRAGMENT,
  MANAGER_COMPLIANCE_FRAGMENT,
  MANAGER_ESG_FRAGMENT,
  MANAGER_DIVERSITY_FRAGMENT,
  MANAGER_ASSETS_CLOSED_END,
  MANAGER_ASSETS_VEHICLES,
  FACT_FRAGMENT,
  OPINION_FRAGMENT,
  MANAGER_OWNERSHIP_DIVERSE_TABLES_FRAGMENT,
  MANAGER_NOTE_FRAGMENT,
  INTERACTION_SIDEBAR_FRAGMENT,
  MANAGER_ESG_SCORE_FRAGMENT,
  CALLAN_DEI_SCORES,
} from "./Fragments"
import { PERSON_SUMMARY_FRAGMENT } from "./PersonFragments"
import { BREADCRUMB_GLIDE_PATH_FRAGMENT } from "./GlidepathFragments"
import { PLAN_PORTFOLIO_FRAGMENT } from "./ClientFragments"

disableFragmentWarnings()
export const MANAGER_PROFILE_QUERY = gql`
  query ManagerProfile(
    $id: Int!
    $startDate: Date
    $endDate: Date
    $showOpinions: Boolean!
  ) {
    org(id: $id) {
      id
      name
      ... on Manager {
        structure {
          parentCompany
          yearFounded
          minorityOwned
          womanOwned
          minorityWomanOwned
          disabledOwned
          ownershipType {
            code
            value
          }
        }
        ...CallanDeiScores
        callanEsgScores {
          weightedScore
          completeAnswer
        }
        address {
          city
          state
          country
        }
        writeUps {
          facts {
            id
            researchCategory {
              code
              value
            }
            text
            bullets
            date
            lastUpdated
          }
          opinions @include(if: $showOpinions) {
            id
            status {
              code
              value
            }
            researchCategory {
              code
              value
            }
            meritsText
            meritsBullets
            considerationsText
            considerationsBullets
            date
            lastUpdated
            client {
              id
              name
              shortName
            }
          }
        }
        interactions {
          id
          type
          date
          contacts {
            id
            firstName
            lastName
          }
          callanPeople {
            id
            firstName
            lastName
          }
          subject
          notes {
            hasNotes
          }
        }
        totalAssetsUnderManagement(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          assetsUnderManagement
          numberOfAccounts
        }
        realAssetsAssetsByCapitalType(
          startDate: $startDate
          endDate: $endDate
        ) {
          quarterEndDate
          assetsUnderManagement
          numberOfAccounts
          capitalType {
            code
            value
          }
        }
        closedEndedFundraising(startDate: $startDate, endDate: $endDate) {
          year
          netAssetValue
          uncalledCapital
        }
        assetsByClient(
          startDate: $startDate
          endDate: $endDate
          categoryType: TOT
        ) {
          quarterEndDate
          assetsUnderManagement
          numberOfAccounts
          clientType {
            value
          }
          categoryType {
            value
          }
        }
        assetsByVehicle(
          startDate: $startDate
          endDate: $endDate
          assetClass: TOT
        ) {
          quarterEndDate
          assetsUnderManagement
          numberOfAccounts
          vehicleType {
            value
          }
          assetClass {
            value
          }
        }
        assetsByRegion(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          assetsUnderManagement
          numberOfAccounts
          region {
            value
          }
        }
        products {
          product {
            name
            id
            capitalStructure
            assetClass {
              id
              shortName
              fullName
              parent {
                id
                shortName
              }
            }
            activePassive

            inactive
            latestAum {
              date
              aum
            }
          }
          ... on ClosedEndedHedgeFund {
            closedEnded {
              vintageYearFirstCashflow
              fundRaising {
                date
                committedCapital
              }
              projectedCloseDates
            }
          }
          ... on ClosedEndedPrivateCredit {
            closedEnded {
              vintageYearFirstCashflow
              fundRaising {
                date
                committedCapital
              }
              projectedCloseDates
            }
          }
          ... on ClosedEndedPrivateEquity {
            closedEnded {
              vintageYearFirstCashflow
              fundRaising {
                date
                committedCapital
              }
              projectedCloseDates
            }
          }
          ... on ClosedEndedRealAssets {
            closedEnded {
              vintageYearFirstCashflow
              fundRaising {
                date
                committedCapital
              }
              projectedCloseDates
            }
          }
        }
        employees {
          id
          firstName
          lastName
          title
          active
          background {
            keyPerson
            yearStartedInIndustry
            yearStartedWithFirm
          }
        }
        employeeTurnover(startDate: $startDate, endDate: $endDate) {
          date
          assetClass {
            id
            fullName
            shortName
          }
          type {
            value
          }
          count
          gained
          lost
        }
        employeeBreakdownByGender(startDate: $startDate, endDate: $endDate) {
          date
          type {
            value
          }
          count
        }
        employeeBreakdownByJobCategory(
          startDate: $startDate
          endDate: $endDate
        ) {
          date
          type {
            value
          }
          count
        }
        employeeBreakdownByDiversityProfile(
          startDate: $startDate
          endDate: $endDate
        ) {
          date
          type {
            value
          }
          count
        }
        documents {
          id
          name
          versionId
          mimeType
          description
          url
          type {
            value
          }
          subType {
            value
          }
          versions {
            id
            updated
          }
        }
        managerRelatedClientExposureTotal {
          discretionaryFlag {
            code
            value
          }
          clientAssets
          numberOfClients
        }
        managerRelatedClientPlanExposure {
          clientAssets
          plan {
            id
            name
            fundType {
              value
            }
            reportsAndTotalFunds {
              reportId
              totalFundPid
            }
            client {
              id
              name
            }
          }
          vehicle {
            vehicle {
              fundid
              name
            }
          }
        }
      }
    }
    employeeRaceTypes: __type(name: "EmployeeRaceType") {
      enumValues {
        code: name
        value: description
      }
    }
    employeeGenderTypes: __type(name: "EmployeeGenderType") {
      enumValues {
        code: name
        value: description
      }
    }
  }
  ${CALLAN_DEI_SCORES}
  ${PLAN_PORTFOLIO_FRAGMENT}
`
export const MANAGER_ASSETS_ACCOUNTS_LARGEST_FRAGMENT = gql`
  fragment ManagerAssetsByAccountsLargestFragment on ManagerLargestAccounts {
    quarterEndDate
    accounts {
      rank
      type {
        code
        value
      }
      size
    }
  }
`

export const MANAGER_ASSETS_BY_ACCOUNTS = gql`
  query AssetsByAccount($id: Int!, $startDate: Date, $endDate: Date) {
    assets: org(id: $id) {
      id
      name
      ... on Manager {
        accountTurnover(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          gained {
            accounts
            percentChange
            assets
          }
          lost {
            accounts
            percentChange
            assets
          }
        }
        largestAccounts(startDate: $startDate, endDate: $endDate) {
          ...ManagerAssetsByAccountsLargestFragment
        }
        accountRanges(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          lessThanOneMillion {
            assetsInRange
            accountsInRange
          }
          oneMillionToTenMillion {
            assetsInRange
            accountsInRange
          }
          tenMillionToOneHundredMillion {
            assetsInRange
            accountsInRange
          }
          oneHundredMillionToFiveHundredMillion {
            assetsInRange
            accountsInRange
          }
          moreThanFiveHundredMillion {
            assetsInRange
            accountsInRange
          }
        }
        managerTypes {
          code
        }
      }
    }
  }
  ${MANAGER_ASSETS_ACCOUNTS_LARGEST_FRAGMENT}
`

export const UPDATE_MANAGER_ASSETS_BY_ACCOUNTS_MUTATION = gql`
  mutation UpdateManagerAssetsByAccounts(
    $input: ManagerAssetsInput!
    $startDate: Date
    $endDate: Date
  ) {
    assets: upsertManagerAssets(input: $input) {
      org {
        id
        ... on Manager {
          accountTurnover(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            gained {
              accounts
              percentChange
              assets
            }
            lost {
              accounts
              percentChange
              assets
            }
          }
          largestAccounts(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            accounts {
              rank
              type {
                code
                value
              }
              size
            }
          }
          accountRanges(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            lessThanOneMillion {
              assetsInRange
              accountsInRange
            }
            oneMillionToTenMillion {
              assetsInRange
              accountsInRange
            }
            tenMillionToOneHundredMillion {
              assetsInRange
              accountsInRange
            }
            oneHundredMillionToFiveHundredMillion {
              assetsInRange
              accountsInRange
            }
            moreThanFiveHundredMillion {
              assetsInRange
              accountsInRange
            }
          }
          managerTypes {
            code
          }
        }
      }
    }
  }
`

export const MANAGER_ASSETS_BY_LOCATION_QUERY = gql`
  query AssetsByLocation($id: Int!, $startDate: Date, $endDate: Date) {
    assets: org(id: $id) {
      id
      name
      ... on Manager {
        assetsByRegion(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          updateDate
          assetsUnderManagement
          numberOfAccounts
          region {
            code
            value
          }
          origin {
            code
            value
          }
        }
        assetsByAssetClass(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          updateDate
          assetsUnderManagement
          assetClass {
            code
            value
          }
          region {
            code
            value
          }
          origin {
            code
            value
          }
        }
        otherAssetExplanation(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          region
        }
        managerTypes {
          code
        }
      }
    }
  }
`

export const UPDATE_MANAGER_ASSETS_BY_LOCATION_MUTATION = gql`
  mutation UpdateManagerAssetsByLocation(
    $input: ManagerAssetsInput!
    $startDate: Date
    $endDate: Date
  ) {
    assets: upsertManagerAssets(input: $input) {
      org {
        id
        ... on Manager {
          assetsByRegion(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            updateDate
            assetsUnderManagement
            numberOfAccounts
            region {
              code
              value
            }
            origin {
              code
              value
            }
          }
          assetsByAssetClass(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            updateDate
            assetsUnderManagement
            assetClass {
              code
              value
            }
            region {
              code
              value
            }
            origin {
              code
              value
            }
          }
          otherAssetExplanation(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            region
          }
          managerTypes {
            code
          }
        }
      }
    }
  }
`

export const MANAGER_ASSETS_CLOSED_END_QUERY = gql`
  query AssetsClosedEnd($id: Int!) {
    assets: org(id: $id) {
      id
      name
      ...ManagerAssetsClosedEndFragment
    }
  }
  ${MANAGER_ASSETS_CLOSED_END}
`

export const UPDATE_MANAGER_ASSETS_CLOSED_END_MUTATION = gql`
  mutation UpdateAssetsClosedEnd($input: ManagerAssetsInput!) {
    assets: upsertManagerAssets(input: $input) {
      org {
        id
        ...ManagerAssetsClosedEndFragment
      }
    }
  }
  ${MANAGER_ASSETS_CLOSED_END}
`

export const MANAGER_COMPLIANCE_QUERY = gql`
  query ManagerCompliance($id: Int!) {
    org(id: $id) {
      id
      ...ManagerComplianceFragment
    }
  }
  ${MANAGER_COMPLIANCE_FRAGMENT}
`

export const MANAGER_PEOPLE_QUERY = gql`
  query ManagerEmployees($id: Int!) {
    org(id: $id) {
      id
      ... on Manager {
        employees {
          ...PersonSummaryFragment
        }
        products {
          product {
            id
            name
            inactive
          }
        }
      }
    }
  }
  ${PERSON_SUMMARY_FRAGMENT}
`

export const MANAGER_SIMPLE_PEOPLE_QUERY = gql`
  query ManagerSimpleEmployees($id: Int!) {
    org(id: $id) {
      id
      name
      # CAL-2561
      employees {
        id
        lastName
        firstName
        active
      }
    }
  }
`

export const MANAGER_PRODUCTS_QUERY = gql`
  query ManagerProducts($id: Int!) {
    org(id: $id) {
      id
      ... on Manager {
        products {
          product {
            name
            id
            assetClass {
              id
              shortName
              fullName
              parent {
                id
                shortName
              }
            }
            # investmentStrategy {
            #   code
            #   value
            # }
            activePassive

            inactive
            manager {
              id
              name
            }
            name
            latestAum {
              date
              aum
            }
          }
        }
      }
    }
  }
`

export const MANAGER_GLIDE_PATHS_QUERY = gql`
  query ManagerGlidePaths($id: Int!) {
    org(id: $id) {
      id
      ... on Manager {
        glidePaths {
          id
          name
          dataCollect
          latestAum {
            date
            aum
          }
          versions {
            id
            name
            latestAum {
              date
              aum
            }
            philosophy {
              code
              value
            }
            terminationDate
            series {
              product {
                id
                name
                latestAum {
                  date
                  aum
                }
                activePassive
                inactive
              }
            }
          }
        }
      }
    }
  }
`

export const MANAGER_BREADCRUMB_GLIDE_PATHS_QUERY = gql`
  query ManagerBreadcrumbGlidePaths($id: Int!) {
    org(id: $id) {
      id
      ... on Manager {
        glidePaths {
          ...BreadcrumbGlidePathFragment
        }
      }
    }
  }
  ${BREADCRUMB_GLIDE_PATH_FRAGMENT}
`

export const MANAGER_INTERACTIONS_QUERY = gql`
  query ManagerInteractions($id: Int!) {
    org(id: $id) {
      id
      ... on Manager {
        interactions {
          ...InteractionSidebarFragment
        }
      }
    }
  }
  ${INTERACTION_SIDEBAR_FRAGMENT}
`

export const MANAGER_DOCUMENTS_QUERY = gql`
  query ManagerDocuments($id: Int!) {
    org(id: $id) {
      id
      ... on Manager {
        documents {
          ...FileBasicInfoFragment
        }
      }
    }
  }
  ${FILE_BASIC_INFO_FRAGMENT}
`

export const UPDATE_MANAGER_COMPLIANCE_MUTATION = gql`
  mutation UpdateManagerCompliance($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      org {
        id
        ...ManagerComplianceFragment
      }
    }
  }
  ${MANAGER_COMPLIANCE_FRAGMENT}
`

export const MANAGER_ESG_QUERY = gql`
  query ManagerESG($id: Int!) {
    org(id: $id) {
      id
      ...ManagerEsgScoreFragment
      ...ManagerEsgFragment
      ... on Manager {
        #callanEsgScores
        documents {
          ...FileBasicInfoFragment
        }
      }
    }
  }
  ${MANAGER_ESG_SCORE_FRAGMENT}
  ${MANAGER_ESG_FRAGMENT}
  ${FILE_BASIC_INFO_FRAGMENT}
`

export const MANAGER_ESG_MUTATION = gql`
  mutation UpdateManagerEsg($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      org {
        id
        ...ManagerEsgFragment
        ...ManagerEsgScoreFragment
      }
    }
  }
  ${MANAGER_ESG_FRAGMENT}
  ${MANAGER_ESG_SCORE_FRAGMENT}
`

export const MANAGER_DIVERSITY_QUERY = gql`
  query ManagerDiversity($id: Int!) {
    org(id: $id) {
      id
      ...ManagerDiversityFragment
    }
  }
  ${MANAGER_DIVERSITY_FRAGMENT}
`

export const MANAGER_DIVERSITY_MUTATION = gql`
  mutation UpdateManagerDiversity($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      org {
        id
        ...ManagerDiversityFragment
      }
    }
  }
  ${MANAGER_DIVERSITY_FRAGMENT}
`

export const DELETE_ESG_UNPRI_SCORE = gql`
  mutation deleteUNPRI($input: DeleteUnpriModuleScoreItemInput!) {
    deleteUnpriModuleScoreItem(input: $input) {
      status
    }
  }
`

export const MANAGER_ASSETS_BY_VEHICLE = gql`
  query ManagerAssetsByVehicle($id: Int!, $startDate: Date, $endDate: Date) {
    assets: org(id: $id) {
      id
      name
      ... on Manager {
        ...ManagerAssetsVehiclesFragment
      }
    }
  }
  ${MANAGER_ASSETS_VEHICLES}
`

export const UPSERT_MANAGER_ASSETS_BY_VEHICLE = gql`
  mutation UpdateManagerAssetsByVehicle(
    $input: ManagerAssetsInput!
    $startDate: Date
    $endDate: Date
  ) {
    assets: upsertManagerAssets(input: $input) {
      org {
        id
        ... on Manager {
          ...ManagerAssetsVehiclesFragment
        }
      }
    }
  }
  ${MANAGER_ASSETS_VEHICLES}
`

export const MANAGER_ALL_PRODUCTS = gql`
  query ManagerAllProducts($id: Int!) {
    assets: org(id: $id) {
      id
      ... on Manager {
        products {
          __typename
          product {
            id
            name
            inactive
          }
        }
      }
    }
  }
`

export const MANAGER_WRITE_UPS_QUERY = gql`
  query ManagerWriteUps($id: Int!, $filters: ManagerNoteFilters) {
    org(id: $id) {
      id
      ... on Manager {
        writeUps {
          facts {
            ...FactFragment
          }
          opinions {
            ...OpinionFragment
          }
        }
        notes(filters: $filters) {
          ...ManagerNoteFragment
        }
        employees {
          id
          firstName
          lastName
          title
        }
      }
    }
  }
  ${FACT_FRAGMENT}
  ${OPINION_FRAGMENT}
  ${MANAGER_NOTE_FRAGMENT}
`

export const CREATE_FACT_MUTATION = gql`
  mutation CreateFact($input: CreateFactInput!) {
    fact: createFact(input: $input) {
      ...FactFragment
    }
  }
  ${FACT_FRAGMENT}
`

export const UPDATE_FACT_MUTATION = gql`
  mutation UpdateFact($input: UpdateFactInput!) {
    fact: updateFact(input: $input) {
      ...FactFragment
    }
  }
  ${FACT_FRAGMENT}
`

export const DELETE_FACT_MUTATION = gql`
  mutation DeleteFact($input: DeleteInput!) {
    deleteFact(input: $input) {
      status
      message
    }
  }
`

export const CREATE_OPINION_MUTATION = gql`
  mutation CreateOpinion($input: CreateOpinionInput!) {
    opinion: createOpinion(input: $input) {
      ...OpinionFragment
    }
  }
  ${OPINION_FRAGMENT}
`

export const UPDATE_OPINION_MUTATION = gql`
  mutation UpdateOpinion($input: UpdateOpinionInput!) {
    opinion: updateOpinion(input: $input) {
      ...OpinionFragment
    }
  }
  ${OPINION_FRAGMENT}
`

export const DELETE_OPINION_MUTATION = gql`
  mutation DeleteOpinion($input: DeleteInput!) {
    deleteOpinion(input: $input) {
      status
      message
    }
  }
`

export const CREATE_MANAGER_NOTE_MUTATION = gql`
  mutation CreateManagerNote($input: CreateManagerNoteInput!) {
    createManagerNote(input: $input) {
      note {
        ...ManagerNoteFragment
      }
    }
  }
  ${MANAGER_NOTE_FRAGMENT}
`

export const UPDATE_MANAGER_NOTE_MUTATION = gql`
  mutation UpdateManagerNote($input: UpdateManagerNoteInput!) {
    updateManagerNote(input: $input) {
      note {
        ...ManagerNoteFragment
      }
    }
  }
  ${MANAGER_NOTE_FRAGMENT}
`

export const DELETE_MANAGER_NOTE_MUTATION = gql`
  mutation DeleteManagerNote($input: DeleteInput!) {
    deleteManagerNote(input: $input) {
      status
      message
    }
  }
`

export const MANAGER_OWNERSHIP_DIVERSE_TABLES_QUERY = gql`
  query ManagerOwnershipDiverseTables(
    $id: Int!
    $filters: EmployeeDiversityFilter
  ) {
    org(id: $id) {
      id
      ... on Manager {
        ...ManagerOwnershipDiversifyFragment
      }
    }
  }
  ${MANAGER_OWNERSHIP_DIVERSE_TABLES_FRAGMENT}
`

export const MANAGER_OWNERSHIP_DIVERSE_TABLES_MUTATION = gql`
  mutation updateManagerOwnershipDiverseTables(
    $input: EmployeeDemographicsInput!
    $filters: EmployeeDiversityFilter = { startYear: 2020, endYear: 2020 }
  ) {
    upsertEmployeeDemographics(input: $input) {
      org {
        id
        ... on Manager {
          ...ManagerOwnershipDiversifyFragment
        }
      }
    }
  }
  ${MANAGER_OWNERSHIP_DIVERSE_TABLES_FRAGMENT}
`

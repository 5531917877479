import classnames from 'classnames'
import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { Label } from 'reactstrap'

import { FormInputSubClasses } from '../../helpers/constant'
import { getNewStateObject } from '../../helpers/helpers'
import { FormInput } from '../ui/Forms/FormInput'

type BenchmarkComponentProps = {
  data: any
  handleChange: (value: any) => void
  editMode: boolean
  hidden?: boolean
  options: JSX.Element
  hasTargetAdded: boolean
}

type DefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  placeholder?: string
  divWidthClass?: string
}

const BenchmarkInput: DefItem[] = [
  {
    field: "primaryBenchmark.code",
    type: "select",
    subtype: "single",
    title: "",
    placeholder: "Select benchmark",
    subClasses: {
      wrapperClasses: "no-export-form",
      inputWrapperClasses: "row px-3"
    },
    divWidthClass: "mx-3"
  },
  {
    field: "targetBenchmarkAdd",
    type: "float",
    subtype: "percent",
    title: "+",
    placeholder: "Select %",
    subClasses: {
      wrapperClasses: "no-export-form",
      inputWrapperClasses: "col-sm-9 px-0",
      inputClasses: "w-50",
      labelClasses: "ml-3"
    },
    divWidthClass: ""
  }
]

export const PrimaryBenchmarkComponent = (props: BenchmarkComponentProps) => {
  const componentName = "PrimaryBenchmark"
  const { data, editMode, handleChange, options, hasTargetAdded } = props
  const [currentData, setData] = useState(data)

  useEffect(() => {
    setData(props.data)
  }, [props.data, props.hasTargetAdded])

  const updateValue = (value: any, type: string, property: string) => {
    let newData = getNewStateObject({
      state: currentData,
      newValue: value,
      property: `${property}`,
      type
    })
    setData(newData)
    handleChange(newData)
  }
  return (
    <div className={"form-group form-inline"} key={`${componentName}`}>
      <div
        className={classnames(" row col-sm-5 pl-0 mr-4")}
        key={`${componentName}`}
      >
        <Label className={"col-form-label"}>Benchmark</Label>
      </div>
      {BenchmarkInput.map((el, index) => {
        let {
          field,
          type,
          title,
          subtype,
          placeholder,
          subClasses,
          divWidthClass,
        } = el
        let value = _.get(data, field)
        let optionElements = <></>
        if (type === "select") {
          optionElements = options
        }
        if (field === "targetBenchmarkAdd" && !hasTargetAdded) {
          return <Fragment key={index}></Fragment>
        }
        return (
          <div className={`${divWidthClass || ""}`} key={index}>
            <FormInput
              key={index}
              property={field}
              displayName={title}
              type={type}
              subtype={subtype}
              idx={index}
              editMode={editMode}
              propertyVal={value}
              placeholder={placeholder}
              subClasses={subClasses}
              options={optionElements}
              updateValue={value => updateValue(value, type, field)}
            />
          </div>
        )
      })}
    </div>
  )
}

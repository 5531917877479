import { disableFragmentWarnings, gql } from "@apollo/client"
import { DASHBOARD_STOPLIGHT_FRAGMENT, REPORT_SCHEDULE_FRAGMENT } from "./DashboardFragments"
import { INTERACTION_SIDEBAR_FRAGMENT } from "./Fragments"

disableFragmentWarnings()

const CONSULTANT_DASHBOARD_PLAN_FRAGMENT = gql`
  fragment ConsultantDashboardPlanFragment on Plan {
    id
    name
    report {
      id
      client {
        id
        name
      }
    }
    client {
      id
      name
    }
  }
`

const CONSULTANT_CLIENTLIST_LIST_MEMBER_FRAGMENT = gql`
  fragment ConsultantClientListListMemberFragment on ListMember {
    order
    group
    item {
      ... on ListGroup {
        id
      }
      ... on Plan {
        ...ConsultantDashboardPlanFragment
      }
    }
  }
  ${CONSULTANT_DASHBOARD_PLAN_FRAGMENT}
`

export const CONSULTANT_CLIENTLIST_FETCH_FRAGMENT = gql`
  fragment ConsultantClientListFetchFragment on ClientList {
    list {
      id
      name
      type
      active
      items {
        ...ConsultantClientListListMemberFragment
      }
    }
  }
  ${CONSULTANT_CLIENTLIST_LIST_MEMBER_FRAGMENT}
`

const CONSULTANT_DASHBOARD_PLANLIST_FRAGMENT = gql`
  fragment ConsultantDashboardPlanListFragment on Plan {
    id
    name
  }
`

const CONSULTANT_PLANLIST_LIST_MEMBER_FRAGMENT = gql`
  fragment ConsultantPlanListListMemberFragment on ListMember {
    order
    group
    item {
      ... on ListGroup {
        id
      }
      ... on Plan {
        ...ConsultantDashboardPlanFragment
      }
    }
  }
  ${CONSULTANT_DASHBOARD_PLANLIST_FRAGMENT}
`

export const CONSULTANT_PLANLIST_FETCH_FRAGMENT = gql`
  fragment ConsultantPlanListFetchFragment on PlanList {
    list {
      id
      name
      type
      active
      items {
        ...ConsultantPlanListListMemberFragment
      }
    }
  }
  ${CONSULTANT_PLANLIST_LIST_MEMBER_FRAGMENT}
`

const NOTABLE_INTERACTION_SIDEBAR_FRAGMENT = gql`
  fragment NotableInteractionSidebarFragment on Interaction {
    primaryManagerAssociation {
      id
      name
    }
    otherManagerAssociations {
      id
      name
    }
    productAssociations {
      product {
        id
        name
      }
    }
    ...InteractionSidebarFragment
  }
  ${INTERACTION_SIDEBAR_FRAGMENT}
`
export const CONSULTANT_NOTABLEINTERACTIONS_PLAN_FETCH_FRAGMENT = gql`
  fragment ConsultantNotableInteractionsPlanFetchFragment on Plan {
    ...ConsultantDashboardPlanFragment
    interactions(filter: $interactionFilters) {
      ...NotableInteractionSidebarFragment
    }
  }
  ${NOTABLE_INTERACTION_SIDEBAR_FRAGMENT}
`

export const CONSULTANT_NOTABLEINTERACTIONS_FETCH_FRAGMENT = gql`
  fragment ConsultantNotableInteractionsFetchFragment on NotableInteractions {
    list {
      id
      name
      type
      owner {
        id
        firstName
        lastName
      }
    }
    trailingMonths
    importance
    managerProductFilter
    timePeriod
    assetClass
    hasNotes
  }
  ${INTERACTION_SIDEBAR_FRAGMENT}
`

export const CONSULTANT_DASHBOARD_LAYOUT_COMPONENT_SETTING = gql`
  fragment ConsultantDashboardComponentSettingsFragment on ComponentSettings {
    ... on ClientList {
      ...ConsultantClientListFetchFragment
    }
    ... on PlanList {
      ...ConsultantPlanListFetchFragment
    }
    ... on NotableInteractions {
      ...ConsultantNotableInteractionsFetchFragment
    }
    ... on Stoplight {
      ...DashboardStoplightFragment
    }
    ... on ReportSchedule {
      ...ReportScheduleFragment
    }
  }
  ${CONSULTANT_CLIENTLIST_FETCH_FRAGMENT}
  ${CONSULTANT_NOTABLEINTERACTIONS_FETCH_FRAGMENT}
  ${CONSULTANT_PLANLIST_FETCH_FRAGMENT}
  ${DASHBOARD_STOPLIGHT_FRAGMENT}
  ${REPORT_SCHEDULE_FRAGMENT}
`

export const INTERACTIONS_FOR_PLAN_LIST = gql`
  query InteractionsForPlanList(
    $id: Int!
    $interactionFilters: InteractionFilters
  ) {
    list(id: $id) {
      id
      items {
        order
        item {
          ... on ListGroup {
            id
          }
          __typename
          ... on Plan {
            ...ConsultantNotableInteractionsPlanFetchFragment
          }
        }
      }
    }
  }
`

export const CONSULTANT_DASHBOARD_COMPONENT_FRAGMENT = gql`
  fragment ConsultantDashboardComponentFragment on Component {
    id
    name
    type
    exportTypes
    draftSettings {
      ...ConsultantDashboardComponentSettingsFragment
    }
    liveSettings {
      ...ConsultantDashboardComponentSettingsFragment
    }
  }
  ${CONSULTANT_DASHBOARD_LAYOUT_COMPONENT_SETTING}
`

export const CONSULTANT_DASHBOARD_LAYOUT_FRAGMENT = gql`
  fragment ConsultantDashboardLayOutFragment on Dashboard {
    id
    name
    layout {
      sections {
        type
        components {
          ...ConsultantDashboardComponentFragment
        }
      }
    }
  }
  ${CONSULTANT_DASHBOARD_COMPONENT_FRAGMENT}
`

import classNames from "classnames"
import { clone, compact, remove, sortBy } from "lodash"
import { Dispatch, SetStateAction, memo, useRef, useState } from "react"
import { DndProvider, XYCoord, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { Button, DropdownMenu, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, UncontrolledDropdown } from "reactstrap"
import { ClientPlanOrderFragment, ClientReportOrderFragment, ReportsFragment } from "../../../__generated__/graphql"

interface TabOrderSelectorProps {
  orgId: number,
  selectedReport: ReportsFragment,
  currentPlan?: ClientPlanOrderFragment,
  editMode: boolean,
  reportTabOrder: ClientReportOrderFragment[]
  setReportTabOrder: Dispatch<SetStateAction<ClientReportOrderFragment[]>>
  planTabOrder: ClientPlanOrderFragment[]
  setPlanTabOrder: Dispatch<SetStateAction<ClientPlanOrderFragment[]>>
}

export const TabOrderSelector: React.FC<TabOrderSelectorProps> = ({ orgId, selectedReport, currentPlan, editMode, reportTabOrder, setReportTabOrder, planTabOrder, setPlanTabOrder }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const selectedReportId = selectedReport?.id

  const toggleModal = () => setModalOpen(!modalOpen)

  const openModal = () => {
    if(editMode) {
      setModalOpen(true)
    }
  }

  if (!selectedReport) {
    return (<></>)
  }

  return (
    <>
      <FormGroup className="form-group row no-gutters">
        <Label className="col-form-label col-sm-4" for="tab-order">Tab Order</Label>
        <div className="exportable-form-input col-sm-8">
          <div onClick={openModal} className={classNames({"fake-link": editMode }, "border-0")}>
            <Nav className="report-tab-order-selector sub-nav sub-nav-primary collapsed" tabs role="group" aria-label="Tab Report Order">
              <UncontrolledDropdown className="nav-tabs-dropdown pr-0" direction="down">
                <DropdownMenu>
                  { planTabOrder.map((rep, idx) => (
                    <NavItem key={'tab-order-' + idx}>
                      <span className={classNames('px-3 mr-0 nav-link', {
                        disabled: !editMode,
                        active: rep.id === currentPlan?.id
                      })}>{idx + 1}</span>
                    </NavItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </div>
        </div>
      </FormGroup>
      <DndProvider backend={HTML5Backend}>
        <TabOrderModal
          orgId={orgId}
          reports={reportTabOrder}
          selectedReport={selectedReport}
          editMode={editMode}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          setReports={setReportTabOrder}
          currentPlan={currentPlan}
          planTabOrder={planTabOrder}
          setPlanTabOrder={setPlanTabOrder}
        />
      </DndProvider>
    </>
  )
}

interface TabOrderModalProps {
  orgId: number,
  reports: ClientReportOrderFragment[],
  selectedReport: ReportsFragment,
  editMode: boolean,
  modalOpen: boolean,
  toggleModal: () => void,
  setReports: Dispatch<SetStateAction<ClientReportOrderFragment[]>>
  planTabOrder: ClientPlanOrderFragment[]
  setPlanTabOrder: Dispatch<SetStateAction<ClientPlanOrderFragment[]>>
  currentPlan?: ClientPlanOrderFragment,
}
const TabOrderModal = memo(({ reports, editMode, modalOpen, toggleModal, setReports, selectedReport, currentPlan, planTabOrder, setPlanTabOrder }:TabOrderModalProps) => {
  const [sortedReports, setSortedReports] = useState(sortBy(compact(reports), 'order'))
  const [sortedPlans, setSortedPlans] = useState(sortBy(compact(planTabOrder), 'order'))

  const moveReport = (id: string, atIndex: number) => {
    const report = sortedReports.find((r) => `${r.id}` === `${id}`) as ClientReportOrderFragment
    const newReports = clone(sortedReports)

    remove(newReports, (r) => r.id === report.id)
    newReports.splice(atIndex, 0, report)
    setSortedReports(newReports)
  }

  const movePlan = (id: string, atIndex: number) => {
    const plan = sortedPlans.find((r) => `${r.id}` === `${id}`) as ClientPlanOrderFragment
    const newPlans = clone(sortedPlans)

    remove(newPlans, (r) => r.id === plan.id)
    newPlans.splice(atIndex, 0, plan)
    setSortedPlans(newPlans)
  }

  const setOrder = () => {
    setReports(sortedReports)
    setPlanTabOrder(sortedPlans)
    toggleModal()
  }

  const cancelMove = () => {
    setSortedReports(sortBy(compact(reports), 'order'))
    setSortedPlans(sortBy(compact(planTabOrder), 'order'))
    toggleModal()
  }

  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} size="xl" zIndex={1500}>
      <ModalHeader toggle={toggleModal}>Reorder Plan Report Tabs</ModalHeader>
      <ModalBody>
        <h2 className='headline mb-3'>{selectedReport.client?.name}</h2>
        <Nav className="sub-nav sub-nav-primary collapsed report-tab-order-dnd" tabs role="group" aria-label="Tab Report Order">
          <UncontrolledDropdown className="nav-tabs-dropdown pr-0" direction="down">
            <DropdownMenu className='reorder-plan-tabs'>
              <NavItem className='p-relative'>
                <span className='nav-item-subtext pl-0 pb-1'>LOCKED</span>
                <span className='px-0 mr-2 ml-0 nav-link disabled'>Documents</span>
              </NavItem>
              <div className='draggable-tabs-wrapper'>
                { sortedPlans.map((rep, idx) => (
                  <DraggableNavItem
                    key={'draggable-plan-tab-' + rep.id}
                    report={rep}
                    editMode={editMode}
                    active={rep.id === currentPlan?.id}
                    index={idx}
                    moveReport={movePlan}
                    type="plan"
                  />
                ))}
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Nav className="sub-nav sub-nav-primary collapsed report-tab-order-dnd" tabs role="group" aria-label="Tab Report Order">
          <UncontrolledDropdown className="nav-tabs-dropdown pr-0" direction="down">
            <DropdownMenu className='reorder-plan-tabs'>
              <div className='draggable-tabs-wrapper'>
                { sortedReports.map((rep, idx) => (
                  <DraggableNavItem
                    key={'draggable-report-tab-' + rep.id}
                    report={rep}
                    editMode={editMode}
                    active={rep.id === selectedReport.id}
                    index={idx}
                    moveReport={moveReport}
                    type="report"
                  />
                ))}
              </div>
              <NavItem className='p-relative'>
                <span className='nav-item-subtext pl-0 py-1'>LOCKED</span>
                <span className='px-0 mr-2 ml-0 nav-link disabled'>Fund Documents + Reports</span>
              </NavItem>
              <NavItem className='p-relative'>
                <span className='nav-item-subtext pl-0 py-1'>LOCKED</span>
                <span className='px-0 mr-2 ml-0 nav-link disabled'>Manger Documents</span>
              </NavItem>
              <NavItem className='p-relative'>
                <span className='nav-item-subtext pl-0 py-1'>LOCKED</span>
                <span className='px-0 mr-2 ml-0 nav-link disabled'>Interactions</span>
              </NavItem>
              <NavItem className='p-relative'>
                <span className='nav-item-subtext pl-0 py-1'>LOCKED</span>
                <span className='px-0 mr-2 ml-0 nav-link disabled'>Manger Data</span>
              </NavItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={cancelMove}>Cancel</Button>
        <Button color="primary" onClick={setOrder} className='ml-1'>Apply</Button>
      </ModalFooter>
    </Modal>
  )
})

interface DragItem {
  index: number
  id: string
  type: string
}


interface DraggableNavItemProps {
  report: ClientReportOrderFragment | ClientPlanOrderFragment,
  editMode: boolean,
  index: number,
  moveReport: (id: string, atIndex: number) => void
  active: boolean
  type: "plan" | "report"
}

const DraggableNavItem = ({ report, editMode, index, moveReport, active, type }:DraggableNavItemProps) => {
  const ref = useRef<HTMLDivElement>(null)

  let name = report.name
  if(type === 'plan' && "shortName" in report && report.shortName) {
    name = report.shortName
  }

  const [{ handlerId }, drop] = useDrop({
    accept: `${type}-TAB`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Setup drag detection
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.left - hoverBoundingRect.right) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.right

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }

      // update order
      moveReport(item.id, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: `${type}-TAB`,
    item: () => {
      return { id: report.id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <div className='report-tab-order-tab' ref={ref} data-handler-id={handlerId} style={{ opacity: isDragging ? 0 : 1 }}>
      <NavItem aria-hidden={false}>
        <span className={classNames('px-1 mx-2 nav-link', {
          disabled: !editMode,
          active: active
        })}>{name}</span>
      </NavItem>
    </div>
  )
}

import React, { createContext, useContext } from 'react'
import { useLocation } from 'react-router'

export interface ContextProps {
  pathname: string
}

const IState: ContextProps = {
  pathname: ""
}

export const PathnameContext = createContext<ContextProps>(IState)
export const usePathname = () => useContext(PathnameContext)

export const PathnameProvider = (props: { children: any }) => {
  const { children } = props
  const { pathname } = useLocation()
  return (
    <PathnameContext.Provider value={{pathname}}>
      {children}
    </PathnameContext.Provider>
  )
}
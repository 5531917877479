import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { CustomInput, FormGroup, Label } from 'reactstrap'

import { keys } from '../../helpers/object'

export type optionProps = {
  code: string
  value: string
  __typename?: string
  isSelected: boolean
}

export type optionStateProps = {
  code: string
  value: string
  __typename?: string
  isSelected?: boolean
}

type CheckboxProps = {
  label: optionProps
  isSelected: boolean
  onCheckboxChange: (input: any) => void
  disabled: boolean
  idx: number
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const { label, isSelected, onCheckboxChange, disabled, idx } = props

  return (
    <>
      <div className="form-check">
        <CustomInput
          key={label.code}
          type="checkbox"
          label={label.value}
          className={"square-checkbox"}
          name={label.code}
          checked={isSelected || false}
          onChange={onCheckboxChange}
          disabled={disabled}
          id={label.code + "." + idx}
        />
      </div>
    </>
  )
}
/**
 * buildState is for easier comparison between localState vs options
 * @return format {[code: string]: boolean}[], includes only selected ones.
 */
const buildState = (options: optionProps[]) => {
  return options.reduce((opts, opt) => {
    if (opt.isSelected) {
      return { ...opts, [opt.code]: true }
    }
    return opts
  }, {} as { [name: string]: boolean })
}

const buildInitialState = (
  options: optionProps[],
  optionSource: optionProps[]
) => {
  return optionsWithState(options, optionSource)
}
const optionsWithState = (
  list: optionProps[],
  allPossibleOptions: { code: string; value: string }[]
) => {
  if (_.isNull(list)) {
    list = []
  }
  const selected = list.reduce(
    (acc, el) => ({ ...acc, [el.code]: true }),
    {} as { [name: string]: boolean }
  )
  const result = allPossibleOptions.map(el => {
    let isSelected = selected[el.code] || false
    return {
      code: el.code,
      value: el.value,
      isSelected
    } as optionProps
  })

  return result
}

export const CheckBoxes: React.FC<{
  optionSource: optionProps[]
  options: optionProps[]
  cb: (input: optionProps[]) => any
  disabled: boolean
  label?: string
}> = ({ optionSource, options, cb, disabled, label }) => {
  /**
   *localState is for local state
   * format {code:string, value: string, isSelected: boolean}[], includes all possible options.
   */
  // options are selected items, optionSource is all possible option
  const [localState, setLocal] = useState(() =>
    buildInitialState(options, optionSource)
  )

  useEffect(() => {
    let currentCheckedCodes = buildState(localState)
    let result =
      options?.every(el => el.isSelected === currentCheckedCodes[el.code]) &&
      keys(currentCheckedCodes).length === options.length
    if (!result) {
      setLocal(() => buildInitialState(options, optionSource))
    }
  }, [options, optionSource])

  const handleCheckboxChange = (
    changeEvent: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name } = changeEvent.target

    let newLocalState = localState.map((el: optionProps, idx) => {
      if (el.code === name) {
        return { ...el, isSelected: !el.isSelected }
      }
      return el
    })
    let newCallbackState = newLocalState.filter(el => el.isSelected)

    setLocal(newLocalState)
    cb(newCallbackState)
  }

  const createCheckbox = (
    option: optionProps,
    idx: number,
    disabled: boolean = false
  ) => (
    <Checkbox
      label={option}
      idx={idx}
      isSelected={option.isSelected}
      onCheckboxChange={handleCheckboxChange}
      key={option.code}
      disabled={disabled}
    />
  )

  const createCheckboxes = () =>
    localState.map((opt, idx) => (
      <Fragment key={idx}>{createCheckbox(opt, idx, disabled)}</Fragment>
    ))

  return (
    <FormGroup>
      <Label>{label}</Label>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">{createCheckboxes()}</div>
        </div>
      </div>
    </FormGroup>
  )
}

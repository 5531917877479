import classnames from 'classnames'
import _ from 'lodash'
import React, { Component, useState, useContext, useRef, RefObject } from 'react'
import { Alert, Table, Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import iassign from 'immutable-assign'
import moment from 'moment'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import { useAssetsClosedEndQuery, useUpdateAssetsClosedEndMutation, AssetsClosedEndQuery, ManagerAssetsInput, ClosedEndedFundraising } from '../../__generated__/graphql'
import PlaceHolder from '../ui/PlaceHolder'
import { FormInputField, DATE_API_FORMAT } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import { excludePropertyArray, convertLookupToString, reShapeObject } from '../../helpers/object'
import { appDate } from '../../Context/CalendarContext'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { useHistory } from 'react-router-dom'
import ErrorDisplay from '../Shared/ErrorDisplay'
import exportTables from '../../helpers/exportTable'

interface Props {
  data: AssetsClosedEndQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<Result>
  fillBackToYear: number
}

interface idProps {
  id: number
  auth: Auth
}

const PostShapeActions = {
  // this action has side effect, it excludes structure.id
  "year": {
    destinationPath: "year",
    action: (year: any) => {
      return moment(year, "YYYY").endOf('year').format(DATE_API_FORMAT)
    }
  },
}

const PreShapeActions = {
  // this action has side effect, it excludes structure.id
  "year": {
    destinationPath: "year",
    action: (year: any) => {
      return parseInt(moment(year, DATE_API_FORMAT).format("YYYY"))
    }
  },
}

const ManagerAssetsClosedEnd: React.FC<idProps> = ({ id, auth }: idProps) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [lastYear, setLastYear] = useState(2000)
  const resultRef = useRef<Result>(null)
  const [updateManagerAssetsClosedEnd] = useUpdateAssetsClosedEndMutation()
  const history = useHistory()

  let { data, loading, error, fetchMore } =
  useAssetsClosedEndQuery({
    variables: {
      id
    },
  })
  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let currentAssets = resultRef!.current?.state.currentState.assets
    let initialAssets = resultRef!.current?.state.initialState.assets

    if(!currentAssets || currentAssets.__typename != "Manager"){
      return
    }
    if(!initialAssets || initialAssets.__typename != "Manager"){
      return
    }
    let assets = initialAssets
    let closedEndedFundraising = currentAssets.closedEndedFundraising.filter(x => {
      if(assets && assets.__typename == "Manager" && assets.closedEndedFundraising){
        return !_.find(assets.closedEndedFundraising, (o) => JSON.stringify(o) == JSON.stringify(x))
      }
      return true
    })

    const formattedClosedEndedFundraising = convertLookupToString(closedEndedFundraising, true)
    const updateData = {
      id: currentAssets.id,
      patch: {
        closedEndedFundraising: _.map(formattedClosedEndedFundraising, (fabr) => {return excludePropertyArray(reShapeObject(_.cloneDeep(fabr), PostShapeActions), ["__typename", "updateDate"])}),
      }
    } as ManagerAssetsInput
    updateManagerAssetsClosedEnd({ variables: { input: updateData } })
      .then(result => {
        setSaving(false)
        if (result && result.data) {
          let unformattedNewData = { assets: result.data.assets?.org }
          const formattedData = iassign(
            unformattedNewData,
            currentState => (currentState?.assets as any)?.closedEndedFundraising,
            selectedTable => {
              let rows = _.cloneDeep(selectedTable as ClosedEndedFundraising[])
              rows = _.map(rows, (row) =>{
                return reShapeObject(row, PreShapeActions) as ClosedEndedFundraising
              })
              return rows
            }
          )
          let previousState = resultRef!.current?.state
          resultRef!.current?.setState({ ...previousState, currentState: formattedData, initialState: formattedData })
          setEditMode(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error testManagerSummary", err.message)
        // throw new Error(`${err.message}`)
      })
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>
        {auth.checkPermissions(["edit:manager"]) &&
          <>
            {editMode && !saving &&
              <Button color="link" className="mr-auto" onClick={() => setLastYear(lastYear-1)}>
                <FontAwesomeIcon
                  icon="plus-circle"
                  className="mr-2 text-blue-100"
                />
                Add Row
              </Button>
            }
            <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} />
          </>
        }
      </div>
    </>
  )

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error) {
    return (
      <Container fluid>
        <ErrorDisplay error={error}/>
      </Container>
    );
  }
  if (data && data.assets && data.assets.__typename === "Manager") {
    let tempLastYear = lastYear
    let oldData = _.cloneDeep(data)
    let path = ["assets", "closedEndedFundraising"]
    if(!_.get(oldData, path)) {
      _.set(oldData, path, [])
    }
    const formattedData = iassign(
      oldData,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as ClosedEndedFundraising[])
        rows = _.map(rows, (row: ClosedEndedFundraising) =>{
          let newRow = reShapeObject(row, PreShapeActions) as ClosedEndedFundraising
          if(newRow.year < tempLastYear) tempLastYear = newRow.year
          return newRow
        })
        return rows
      }
    )
    if(tempLastYear < lastYear) setLastYear(tempLastYear)
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Result
              ref={resultRef}
              editMode={editMode}
              setEditMode={setEditMode}
              data={formattedData}
              fillBackToYear={lastYear}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

const AssetClosedInputList:FormInputField[] = [
  { property: "raisedCapital", label: "", type: "number", subtype: "currency" },
  { property: "contributedCapital", label: "", type: "number", subtype: "currency" },
  { property: "uncalledCapital", label: "", type: "number", subtype: "currency" },
  { property: "netAssetValue", label: "", type: "number", subtype: "currency" },
]

class Result extends Component<Props> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data
  }

  resetForm = () => {
    this.setState({ currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    year: string,
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["assets", table]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable) as ClosedEndedFundraising[] 
        var selectedRow = _.find(rows, (o) => {return (o.year === year)})
        if(selectedRow){
          _.set(selectedRow, property, value);
        } else {
          const newRow: any = {
            __typename: "ClosedEndedFundraising",
            year: year,
            raisedCapital: 0,
            contributedCapital: 0,
            uncalledCapital: 0,
            netAssetValue: 0,
          }
          _.set(newRow, property, value);
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ currentState: newState })
  }

  fillBackTo(assetClosedEnd:any, year:number){
    let years = assetClosedEnd.map((ace:any)=>ace.year)
    const currentYear = appDate.quarter() == 4 ? (appDate.year() + 1) : appDate.year()
    const defaultYears = Array.from(Array(currentYear-year), (_, i) => i + year)
    years = _.uniq(_.union(years, defaultYears)).sort().reverse()
    const data = years.map((dataYear:number) => {
      var currentRow = _.find(assetClosedEnd, (o) => {return (o.year === dataYear)})
      if(currentRow){
        return currentRow
      } else {
        return {
          __typename: "ClosedEndedFundraising",
          year: dataYear,
          raisedCapital: undefined,
          contributedCapital: undefined,
          uncalledCapital: undefined,
          netAssetValue: undefined,
        }
      }
    })
    return data
  }

  render() {
    const assetClosedEnd = _.get(this.state.currentState, 'assets.closedEndedFundraising', [])
    const filteredClosedEnd = this.fillBackTo(assetClosedEnd, this.props.fillBackToYear)
    return(
      <>
      <div className="pane pane-table">
        <div className="pane-title">
          <h3>Closed-End Fundraising Assets ($M)</h3>
        </div>
        <Row>
          <Col md="12">
            <div className="table-container">
              <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.assets?.name}-client`}>
                <thead>
                  <tr>
                    <th>Calendar Year Ended</th>
                    <th>Raised</th>
                    <th>Contributed</th>
                    <th>Uncalled</th>
                    <th>NAV</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClosedEnd.map((arr:any, row:number) => {
                      return (
                        <AssetClassRow
                          key={row}
                          data={arr}
                          row={row}
                          editMode={this.props.editMode}
                          updateValue={(value, property, year) => this.handleInputChange(value,property,"closedEndedFundraising",year)}
                        />
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </>
    )
  }
}

interface AssetClassRowProps {
  data: any
  row: number
  editMode: boolean
  updateValue: (value:any, property:string, year:string) => void
}

const AssetClassRow = ({data, row, editMode, updateValue}: AssetClassRowProps) => {
  const year = data.year
  if(!editMode && data.raisedCapital == undefined && data.contributedCapital == undefined && data.uncalledCapital == undefined && data.netAssetValue == undefined ){
    return(
      <></>
    )
  }
  return (
    <tr>
      <td className="text-right">{year}</td>
      {AssetClosedInputList.map(({property, label, type, subtype, placeholder, optionSource, readonly}, idx) => {
        let propertyVal, onChangeCallback
        propertyVal = _.get(data, property)
        onChangeCallback = (value:any) => updateValue(value, property, year)

        return(
          <td key={idx}>
            <FormInput
              property={property+"-ac"}
              displayName={label}
              type={type}
              subtype={subtype}
              placeholder={placeholder}
              idx={idx + (row*AssetClosedInputList.length)}
              editMode={editMode}
              showZero={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              readonly={readonly}
            />
          </td>
        )
      })}
    </tr>
  )
}

export default ManagerAssetsClosedEnd

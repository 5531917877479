import classnames from 'classnames'
import { get, set, cloneDeep, unset, has} from 'lodash'
import React, { Component } from 'react'
import { Table, Row, Col} from 'reactstrap'
import iassign from 'immutable-assign'
import moment from 'moment'
import omitDeep from 'omit-deep-lodash'

import {  ManagerAssetsByVehicleQuery, PrivateEquityAssetsByStrategyType, PrivateEquityStrategyCode, ManagerPrivateEquityAssetsByStrategyTypeInput} from '../../../__generated__/graphql'
import { listQuarters } from "../../../helpers/helpers"
import { appDate } from '../../../Context/CalendarContext'
import { DATE_API_FORMAT } from '../../../helpers/constant'
import { FormInput } from '../../ui/Forms/FormInput'
import { GroupedAsset, GroupedAssets, AssetsByAssetClassTableProps, HistoricalDisplayValueType, HistoricalAssetsByAssetClassTableProps } from '../ManagerAssetsVehicles'

const firstHistoricalDate = moment(appDate).subtract(5,"years")

const convertPEByStrategyCellToInput = (updatedCell:PrivateEquityAssetsByStrategyType):ManagerPrivateEquityAssetsByStrategyTypeInput => {
  let newInputCell = omitDeep(updatedCell, '__typename')
  set(newInputCell, 'strategy', newInputCell.strategy.code)
  unset(newInputCell, 'updatedDate')
  return newInputCell
}

export class PrivateEquityAssetsByStrategyTypeTable extends Component<AssetsByAssetClassTableProps, { currentState: GroupedAsset, initialState: GroupedAsset }> {
  constructor(props: any) {
    super(props)

    const assets:PrivateEquityAssetsByStrategyType[] = get(props.data, "assets.privateEquityAssetsByStrategyType", [])
    let groupedData:GroupedAsset = {}
    assets.map((entry:PrivateEquityAssetsByStrategyType) => {
      let strategyType = entry.strategy.code

      if (strategyType) {
        set(groupedData, [strategyType], entry)
      }
    })

    this.state = {
      currentState: groupedData,
      initialState: groupedData
    }
  }

  static getDerivedStateFromProps(props: AssetsByAssetClassTableProps, state:{ currentState: GroupedAsset, initialState: GroupedAsset }) {
    const assets:PrivateEquityAssetsByStrategyType[] = get(props.data, "assets.privateEquityAssetsByStrategyType", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:PrivateEquityAssetsByStrategyType) => {
      let strategyType = entry.strategy.code

      if (strategyType) {
        set(groupedData, [strategyType], entry)
      }
    })
    return {
      currentState: props.editMode ? state.currentState : groupedData,
      initialState: groupedData,
    }
  }

  handleInputChange = (
    value: any,
    property: string,
    strategyType: PrivateEquityStrategyCode
  ) => {
    let updatedCell:PrivateEquityAssetsByStrategyType
    let oldState = cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      selectedRow => {
        let row:GroupedAsset = cloneDeep(selectedRow)
        let cell:PrivateEquityAssetsByStrategyType
        let selectedCell = get(selectedRow, [strategyType]) as PrivateEquityAssetsByStrategyType
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            strategy: {code: strategyType as PrivateEquityStrategyCode, __typename: "PrivateEquityStrategyLookup"},
            numberOfAccounts: null,
            quarterEndDate: this.props.searchDate,
            __typename: "PrivateEquityAssetsByStrategyType",
          }
        }

        set(cell, property, value)
        set(row, [strategyType], cell)
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () => {
      this.props.onChange(convertPEByStrategyCellToInput(updatedCell),"PrivateEquityAssetsByStrategyType")
    })
  }




  render() {
    const { editMode, managerType} = this.props
    const rowOrder:{ code: PrivateEquityStrategyCode, display: string}[] = [
      { code: PrivateEquityStrategyCode.BUYOUT, display: 'Buyout'},
      { code: PrivateEquityStrategyCode.DISRES, display: 'Distressed/Restructuring' },
      { code: PrivateEquityStrategyCode.DIVER, display: 'Diversified' },
      { code: PrivateEquityStrategyCode.GROEQ, display: 'Growth Equity' },
      { code: PrivateEquityStrategyCode.SPSIT, display: 'Special Situations' },
      { code: PrivateEquityStrategyCode.VC, display: 'Venture Capital'},
      { code: PrivateEquityStrategyCode.PVENER, display: 'Private Energy and Mining'},
      { code: PrivateEquityStrategyCode.MEZZ, display: 'Mezzanine'},
      { code: PrivateEquityStrategyCode.DISTR, display: 'Distressed/Trading' },
      { code: PrivateEquityStrategyCode.COLEND, display: 'Senior Direct Corporate Lending' },
      { code: PrivateEquityStrategyCode.SPFIN, display: 'Specialty Finance/ABL' },
      { code: PrivateEquityStrategyCode.OPP, display: 'Opportunistic' }
    ]

    return(
      <Row>
        <Col md={5}>
          <div className="table-container">
          <Table hover className="table-bordered-internal">
            <thead>
              <tr>
                <th>Strategy</th>
                <th className="text-right"># Funds</th>
                <th className="text-right width-120">Net Assets $(M)</th>
              </tr>
            </thead>
            <tbody>
              {rowOrder.map((row, idx) => {
                let entry = get(this.state.currentState, [row.code as string])

                return (
                  <tr key={`PrivateEquityStrategyCode-${row.code}`}>
                    <td className="text-left">{row.display}</td>
                    <td className="text-right">
                      <FormInput
                        property="numberOfAccounts"
                        type="number"
                        idx={`PrivateEquityStrategyCode-${row.code}-numberOfAccounts`}
                        editMode={editMode}
                        propertyVal={entry?.numberOfAccounts}
                        updateValue={(value:any) => this.handleInputChange(value, "numberOfAccounts", row.code)}
                      />
                    </td>
                    <td className="text-right">
                      <FormInput
                        property="assetsUnderManagement"
                        type="number"
                        subtype="currency"
                        idx={`PrivateEquityStrategyCode-${row.code}-assetsUnderManagement`}
                        editMode={editMode}
                        propertyVal={entry?.assetsUnderManagement}
                        updateValue={(value:any) => this.handleInputChange(value, "assetsUnderManagement", row.code)}
                      />
                    </td>
                  </tr>

                )
              })}
            </tbody>
          </Table>
        </div>
        </Col>
      </Row>

    )
  }
}

interface HistoricalPrivateEquityAssetsByStrategyTypeTableState {
  currentState: GroupedAssets
  initialState: GroupedAssets
  displayValue: HistoricalDisplayValueType
  historicalDate: moment.Moment
}
export class HistoricalPrivateEquityAssetsByStrategyTypeTable extends Component<HistoricalAssetsByAssetClassTableProps, HistoricalPrivateEquityAssetsByStrategyTypeTableState> {

  constructor(props: any) {
    super(props)

    const assets:PrivateEquityAssetsByStrategyType[] = get(props.data, "assets.privateEquityAssetsByStrategyType", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:PrivateEquityAssetsByStrategyType) => {
      let strategyType = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate

      if (strategyType) {
        set(groupedData, [quarterEndDate, strategyType], entry)
      }
    })

    this.state = {
      currentState: groupedData,
      initialState: groupedData,
      displayValue: "assetsUnderManagement",
      historicalDate: moment(firstHistoricalDate),
    }
  }

  static getDerivedStateFromProps(props: HistoricalAssetsByAssetClassTableProps, state:HistoricalPrivateEquityAssetsByStrategyTypeTableState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    const assets:PrivateEquityAssetsByStrategyType[] = get(props.data, "assets.privateEquityAssetsByStrategyType", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:PrivateEquityAssetsByStrategyType) => {
      let strategyType = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate

      if (strategyType) {
        set(groupedData, [quarterEndDate, strategyType], entry)
      }
    })
    return {
      currentState: resetDate && !props.editMode ? groupedData : state.currentState,
      initialState: resetDate ? groupedData : state.initialState,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
    }
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    quarterEndDate: string,
    strategyType: PrivateEquityStrategyCode
  ) => {
    let updatedCell:PrivateEquityAssetsByStrategyType
    let oldState = cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      selectedRow => {
        let row:GroupedAssets = cloneDeep(selectedRow)
        let cell:PrivateEquityAssetsByStrategyType
        let selectedCell = get(selectedRow, [quarterEndDate, strategyType]) as PrivateEquityAssetsByStrategyType
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            strategy: {code: strategyType as PrivateEquityStrategyCode, __typename: "PrivateEquityStrategyLookup"},
            numberOfAccounts: null,
            quarterEndDate: quarterEndDate,
            __typename: "PrivateEquityAssetsByStrategyType",
          }
        }

        set(cell, property, value)
        set(row, [quarterEndDate, strategyType], cell)
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () => {
      this.props.onChange(convertPEByStrategyCellToInput(updatedCell),"PrivateEquityAssetsByStrategyType")
    })
  }

  setDisplayValue = (displayValue:HistoricalDisplayValueType) => {
    this.setState({
      displayValue: displayValue
    })
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  loadMore = (fetchMoreResult:ManagerAssetsByVehicleQuery) =>{
    const newInitialState = this.state.initialState
    const newCurrentState = cloneDeep(this.state.currentState)

    const newAssets:PrivateEquityAssetsByStrategyType[] = get(fetchMoreResult, "assets.privateEquityAssetsByStrategyType", [])

    newAssets.map((entry:PrivateEquityAssetsByStrategyType) => {
      let strategy = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate

      if (strategy && !has(this.state.currentState, [quarterEndDate, strategy])) {
        set(newInitialState, [quarterEndDate, strategy], entry)
        set(newCurrentState, [quarterEndDate, strategy], entry)
      }
    })

    this.setState({
      initialState: newInitialState,
      currentState: newCurrentState,
      historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
    })
  }

  render() {
    const displayValue = this.state.displayValue
    const headingOrder:{ code: PrivateEquityStrategyCode, display: string}[] = [
      { code: PrivateEquityStrategyCode.BUYOUT, display: 'Buyout'},
      { code: PrivateEquityStrategyCode.DISRES, display: 'Distressed/Restructuring' },
      { code: PrivateEquityStrategyCode.DIVER, display: 'Diversified' },
      { code: PrivateEquityStrategyCode.GROEQ, display: 'Growth Equity' },
      { code: PrivateEquityStrategyCode.SPSIT, display: 'Special Situations' },
      { code: PrivateEquityStrategyCode.VC, display: 'Venture Capital'},
      { code: PrivateEquityStrategyCode.PVENER, display: 'Private Energy and Mining'},
      { code: PrivateEquityStrategyCode.MEZZ, display: 'Mezzanine'},
      { code: PrivateEquityStrategyCode.DISTR, display: 'Distressed/Trading' },
      { code: PrivateEquityStrategyCode.COLEND, display: 'Senior Direct Corporate Lending' },
      { code: PrivateEquityStrategyCode.SPFIN, display: 'Specialty Finance/ABL' },
      { code: PrivateEquityStrategyCode.OPP, display: 'Opportunistic' }
    ]


    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))
    const heading = () =>{
      return(
      <div className="w-100 d-flex justify-content-between">
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets ($M)</li>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
      </div>)
    }


    return (
      <>
        <div className="pane pane-table">
          <Table hover className="table-condensed">
            <thead>
              <tr>
                <td colSpan={1000}>{heading()}</td>
              </tr>
            </thead>
          </Table>
          <div className="table-container">
            <Table hover className="table-bordered-internal table-condensed">
              <thead>
                <tr>
                  <th>Date</th>
                  {headingOrder.map((heading) => {
                    return(<th key={heading.code}>{heading.display}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {allDates.map((date:string, row:number) => {
                  return (
                    <tr key={`PrivateEquityAssetsByStrategyType-${displayValue}-${date}`} className="fadein">
                      <td className="nowrap">
                        {date}
                      </td>
                      {headingOrder.map((heading, idx) => {
                        let propertyVal = get(this.state.currentState, [date, heading.code,displayValue])

                        return(
                          <td key={`PrivateEquityAssetsByStrategyType-${idx}`}>
                            <FormInput
                              property={displayValue}
                              displayName={""}
                              type={"number"}
                              subtype={displayValue == "assetsUnderManagement" ? "currency" : ""}
                              placeholder={""}
                              idx={`PrivateEquityAssetsByStrategyType-${date}-${heading.code}`}
                              editMode={this.props.editMode}
                              propertyVal={propertyVal}
                              updateValue={(value:any) => this.handleInputChange(value,this.state.displayValue,date, heading.code )}
                            />
                          </td>
                        )
                      })}
                    </tr>
                  )
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    )
  }
}


import { disableFragmentWarnings, gql } from "@apollo/client"

disableFragmentWarnings()

export const LIST_SIMPLE_FRAGMENT = gql`
  fragment ListSimpleFragment on List {
    id
    name
    owner {
      id
      firstName
      lastName
    }
    category {
      code
      value
    }
    subCategory {
      code
      value
    }
    type
    active
    lastSavedDate
    isCurrentUsersFavorite
  }
`

// Not sure about pid
export const LIST_DETAIL_MEMBER_FRAGMENT = gql`
  fragment ListDetailItemsFragment on ListMember {
    order
    group
    type
    fromList {
      id
      name
    }
    item {
      ... on ClientPortfolio {
        id
        portfolioName: name
      }
      ... on Org {
        id
        orgName: name
      }
      ... on Plan {
        id
        planName: name
      }
      ... on Product {
        product {
          id
          productName: name
        }
      }
      ... on GlidePathVersion {
        id
        glidePathName: name
      }
      ... on Vehicle {
        vehicle {
          id: fundid
          vehicleName: name
        }
      }
      ... on ListGroup {
        id
      }
      ... on ListHeader {
        id
        text
      }
    }
  }
`

export const LIST_DETAIL_EXCLUDE_LIST_FRAGMENT = gql`
  fragment ListDetailExcludeListFragment on List {
    id
    name
    type
    items {
      ...ListDetailItemsFragment
    }
  }
  ${LIST_DETAIL_MEMBER_FRAGMENT}
`

export const LIST_DETAIL_INCLUDE_LIST_FRAGMENT = gql`
  fragment ListDetailIncludeListFragment on List {
    id
    name
    type
  }
`

export const LIST_DETAIL_FRAGMENT = gql`
  fragment ListDetailFragment on List {
    id
    name
    type
    active
    category {
      code
      value
    }
    subCategory {
      code
      value
    }
    isCurrentUsersFavorite
    itemIdTypes
    items {
      ...ListDetailItemsFragment
    }
    primaryExcludeList {
      ...ListDetailExcludeListFragment
    }
    excludeList {
      ...ListDetailExcludeListFragment
    }
    includeLists {
      ...ListDetailIncludeListFragment
    }
    owner {
      id
      firstName
      lastName
    }
    personAccess {
      person {
        id
        firstName
        lastName
      }
      editable
    }
    departmentAccess {
      editable
      department {
        code
        value
      }
    }
    orgAccess {
      editable
      org {
        id
        name
      }
    }
    dynamicFilters {
      org {
        owner {
          id
          firstName
          lastName
        }
        type
        subType
        accountType
      }
      plan {
        client {
          id
          name
        }
        fundType
        owner {
          id
          firstName
          lastName
        }
        accountType
      }
      portfolio {
        plan {
          id
          name
        }
        composite
        assetClass {
          id
          fullName
        }
        parentMix {
          id
          fullName
        }
        dataType
      }
      product {
        manager {
          id
          name
        }
        assetClass {
          id
          fullName
        }
        category
      }
      closedEndedProductListFilters {
        vintageYearFirstCashFlow {
          startDate
          endDate
        }
        lastFund
      }
      vehicle {
        product {
          product {
            id
            name
          }
        }
        category
        status
      }
    }
  }
  ${LIST_DETAIL_MEMBER_FRAGMENT}
  ${LIST_DETAIL_EXCLUDE_LIST_FRAGMENT}
  ${LIST_DETAIL_INCLUDE_LIST_FRAGMENT}
`

export const LIST_PRIMARY_EXCLUDE_FRAGMENT = gql`
  fragment ListPrimaryExcludeFragment on List {
    id
    primaryExcludeList {
      ...ListDetailExcludeListFragment
    }
  }
  ${LIST_DETAIL_EXCLUDE_LIST_FRAGMENT}
`

export const LIST_SEARCH_FRAGMENT = gql`
  fragment ListSearchFragment on List {
    id
    name
    type
    personAccess {
      person {
        id
        firstName
        lastName
      }
      editable
    }
    departmentAccess {
      editable
      department {
        code
        value
      }
    }
    orgAccess {
      editable
      org {
        id
        name
      }
    }
  }
`

export const LIST_QUICK_VIEW_FRAGMENT = gql`
  fragment ListQuickViewFragment on List {
    id
    name
    items {
      ...ListDetailItemsFragment
    }
  }
  ${LIST_DETAIL_MEMBER_FRAGMENT}
`

export const LIST_CATEGORY_MAP_FRAGMENT = gql`
  fragment ListCategoryMapFragment on ListCategoryMapItem {
    category
    subCategory
  }
`

export const REPORT_SETTINGS_LIST_FRAGMENT = gql`
  fragment ReportSettingsListFragment on List {
    id
    name
  }
`

export const REPORT_LIST_HEADER_FRAGMENT = gql`
  fragment ReportListHeaderFragment on ListHeader {
    id
    text
  }
`

import { cloneDeep, isEmpty } from "lodash";
import React from "react"

// import { initialState, IState, reducer } from './reducer';

export interface ContextError {
  [key: string]: string[]
}

export interface SetOptionProps {
  showButtons:boolean
}

export interface WarningProps {
  heading?: string
  message: string
}

export interface FuncProps {
  setError: (field:string, error:string[]) => void
  resetErrors: () => void
  setOptions: ({showButtons}:SetOptionProps) => void
  setWarningModal: (options?:WarningProps) => void
}
export interface ContextProps extends FuncProps {
  errors: ContextError
  showButtons: boolean
  warningModal?: WarningProps
}


const initialState:ContextProps = {
  errors: {},
  showButtons: true,
  warningModal: undefined,
  setError: (field:string, error:string[]) => {},
  resetErrors: () => {},
  setOptions: ({showButtons}:SetOptionProps) => {},
  setWarningModal: (options?:WarningProps) => {}
}

const IState:ContextProps = {
  errors: initialState.errors,
  showButtons: initialState.showButtons,
  warningModal: initialState.warningModal,
  setError: initialState.setError,
  resetErrors: initialState.resetErrors,
  setOptions: initialState.setOptions,
  setWarningModal: initialState.setWarningModal,
}

export const EditButtonContext = React.createContext<ContextProps>(IState)

// Provider for sidebar
export class EditButtonProvider extends React.Component {
  constructor(props:any) {
    super(props);
    this.state = {
      errors: initialState.errors,
      showButtons: initialState.showButtons,
      warningModal: initialState.warningModal,
      setError: this.setError,
      resetErrors: this.resetErrors,
      setOptions: this.setOptions,
      setWarningModal: this.setWarningModal
    }
  }

  state:ContextProps

  setError = (field:string, error:string[]) => {
    if(isEmpty(error)){
      this.setState((previousState: ContextProps) => {
        var previousErrors = cloneDeep(previousState.errors)
        delete previousErrors[field]
        return { errors: previousErrors}
      })
    } else {
      this.setState((previousState: ContextProps) => {
        var previousErrors = cloneDeep(previousState.errors)
        previousErrors[field] = error
        return { errors: previousErrors}
      })
    }
  }

  resetErrors = () => {
    this.setState({ errors: {}, showButtons: true })
  }

  setOptions = (options:SetOptionProps) => {
    this.setState(options)
  }

  setWarningModal = (options?:WarningProps) => {
    this.setState({ warningModal: options })
  }

  render() {
    return (
      <EditButtonContext.Provider
        value={this.state}
      >
        {this.props.children}
      </EditButtonContext.Provider>
    )
  }
}

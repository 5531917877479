import React from "react"
import {
  Row,
  Col,
  ListGroupItem
} from "reactstrap"
import { SearchResultGroupFragment } from '../../../__generated__/graphql'

export const GroupResult = (props:SearchResultGroupFragment) => {
  const { groupId, groupName, groupClass, assetClass, groupStatus, source } = props

  return (
    <ListGroupItem tag="a" href={`/groups/${groupId}/overview`}>
      <div className="category group">
        <div className="category-text">
          <h6>Group</h6>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md="3">
            <h3>{groupName}</h3>
            <h5 className="d-inline mr-1">Group Source:</h5>
            <p className="d-inline">{source}</p>
          </Col>
          <Col md="9">
            <Row>
              <Col md="2">
                <h5>Group ID</h5>
                <p>{groupId}</p>
              </Col>
              <Col md="2">
                <h5>Class</h5>
                <p>{groupClass?.value}</p>
              </Col>
              <Col md="3">
                <h5>Asset Class</h5>
                <p>{assetClass?.parent?.shortName}</p>
              </Col>
              <Col md="3">
                <h5>Strategy Type</h5>
                <p>{assetClass?.shortName}</p>
              </Col>
              <Col md="2">
                <h5>Status</h5>
                <p>{groupStatus?.value }</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroupItem>
  )
}
import React, { Component } from 'react';
import { filter, compact, sortBy} from 'lodash'
import { UncontrolledDropdown, DropdownMenu, Row, Col, Form, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClientAllPlansQuery } from '../../../__generated__/graphql'
import classNames from 'classnames'

interface PlanPickerProps {
  clientId: number
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (plan_id:number) => void
}

interface PlanSelection {
  id: number
  name: string
}

const PlanPicker: React.FC<PlanPickerProps> = ({ clientId, title, children, dropdownClass, onClick }: PlanPickerProps) => {
  const { data } = useClientAllPlansQuery({
    variables: { id: clientId }
  })

  let plans = compact((data?.client && ("plans" in data.client)) ? data?.client?.plans : []) || []

  return (
    <UncontrolledDropdown className={classNames("headline-dropdown plan-picker-dropdown", dropdownClass)}>
      {children}
      <DropdownMenu>
        <h5>{title}</h5>
        <PlansSearch key={plans.length} data={plans} onClick={onClick} />
      </DropdownMenu>
    </UncontrolledDropdown>
  )

}

interface PlansSearchProps {
  data:PlanSelection[]
  onClick: (plan_id:number) => void

}
interface PlansSearchState {
  plans:PlanSelection[]
  search: string
}

export class PlansSearch extends Component<PlansSearchProps> {
  state: PlansSearchState = {
    plans: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredPlans = filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p.name.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      plans: filteredPlans
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="6">
            <Form>
              <FormGroup row className="relative m-0">
                <Input
                  type="text"
                  placeholder="Find plan by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          { sortBy(this.state.plans, [(obj)=>(obj.name).toLowerCase()]).map((plan) => {
            if (!plan) { return <></> }
            return (<ListGroupItem tag="a" key={`pp-plan-${plan?.id}`} onClick={() => this.props.onClick(plan.id)}>{plan?.name}</ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

export default PlanPicker
import _ from "lodash"
import React, { Component, useState, useContext } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap"
import moment from 'moment'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numbro from 'numbro'

import PlaceHolder from "./../ui/PlaceHolder"
import {
  GlidePathReturnsFragment,
  useGlidePathReturnsQuery,
  GlidePathReturnsQuery,
} from "../../__generated__/graphql"
import { DATE_API_FORMAT } from "../../helpers/constant"
import { CalendarContext, appDate } from '../../Context/CalendarContext'
import { CalendarPicker } from '../CalendarPicker'
import { GraphData, SimpleLineOptions } from "../../helpers/highCharts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import exportTables from "../../helpers/exportTable";

// const s = printSchema
interface idProps {
  id: number
}

const firstHistoricalDate = moment(appDate).subtract(5,"years")

/**
 * query ManagerSummary by managerId, return editable form
 * @param {id:number} idProps for query ManagerSummary
 * **/
const GlidePathReturns: React.FC<idProps> = ({ id }: idProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [screen, setScreen] = useState(context.period == "historical" ? "historical" :"single")
  const [loadingMore, setLoadingMore] = useState(false)
  const [historicalDate, setHistoricalDate] = useState(firstHistoricalDate)
  const { data, error, loading, fetchMore } = useGlidePathReturnsQuery({
    variables: {
      id,
      startDate: screen == "single" ? searchDate : appDate.format(DATE_API_FORMAT),
      endDate: screen == "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT)
    },
    fetchPolicy: 'network-only'
  })

  const loadMore = () =>{
    if(loadingMore){
      return
    }
    setLoadingMore(true)
    fetchMore({
      variables: {
        id: id,
        startDate: historicalDate.format(DATE_API_FORMAT),
        endDate: moment(historicalDate).subtract(5, "years").format(DATE_API_FORMAT)
      },
      updateQuery: (previousResult:GlidePathReturnsQuery, { fetchMoreResult } :any) => {
        if(!fetchMoreResult){
          return previousResult
        }
        const previousVersions = previousResult.glidePath?.versions
        let newVersions = fetchMoreResult.glidePath?.versions
        if(!previousVersions || !newVersions){
          return previousResult
        }
        const combinedVersions = previousVersions.map((version, idx) => {
          let versionClone = _.cloneDeep(version)
          if(versionClone && versionClone.series && Array.isArray(versionClone.series)){
            const matchingVersion = _.find(newVersions, (o) => {return o.id == versionClone?.id} )
            const seriesList = _.map(matchingVersion.series, (series) => {
              const matchingSeries = _.find(versionClone?.series, (o) => {return o?.product?.id == series.product.id})
              let seriesClone = _.cloneDeep(series)
              if(matchingSeries && matchingSeries.__typename == "OpenEndedTargetDate" && matchingSeries.targetDate && Array.isArray(matchingSeries.targetDate.performance)){
                seriesClone.targetDate.performance = [...seriesClone.targetDate.performance, ...matchingSeries.targetDate.performance]
              }
              return seriesClone
            })
            versionClone.series = seriesList
          }
          return versionClone
        })
        const returnedState = {
          glidePath: {
            id: previousResult.glidePath?.id,
            name: previousResult.glidePath?.name,
            versions: combinedVersions,
            __typename: previousResult.glidePath?.__typename
          },
        } as GlidePathReturnsQuery
        setHistoricalDate(moment(historicalDate).subtract(5, "years"))
        setLoadingMore(false)
        return returnedState;
      }
    })
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <CalendarPicker
        updateValue={(searchDate) => setSearchDate(searchDate)}
        hasHistorical={true}
        updateType={(type:string) => setScreen(type)}
      />
      {screen == "historical" &&
        <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>loadMore()}>
          {loadingMore && "Loading"}
          {!loadingMore && "Load 5 More Years"}
        </Button>
      }
      <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables()}>
        Export CSV
        <img src='/assets/CSV.svg' className="ml-2"/>
      </Button>
    </div>
  )
  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (data && data.glidePath && data.glidePath.versions) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Result
              data={data?.glidePath as GlidePathReturnsFragment}
              screen={screen}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

interface Props {
  data: GlidePathReturnsFragment
  screen: string
}

interface yearData{
  year: number
  value: number
}

interface historicalData{
  [key: string]: {
    [key: string]: number
  }
}

class Result extends Component<Props> {
  singleView(){
    const versions = this.props.data.versions || []
    const years = versions.reduce((result:yearData[], entry) => {
      entry?.series?.forEach((series) =>{
        if(
            series?.__typename == "OpenEndedTargetDate" &&
            series.targetDate?.performance &&
            series.targetDate?.performance.length > 0 &&
            series.targetDate?.vintageYear &&
            series.targetDate.performance[0]?.value?.active &&
            !_.find(result, (o) => {return o.year == series.targetDate?.vintageYear})
          ){
          result.push({
            year: series.targetDate?.vintageYear,
            value: series.targetDate.performance[0]?.value?.active
          })
        }
      })
      return result
    }, [])
    const sortedYears = _.sortBy(years, [sc => sc?.year])
    let graphData:GraphData = {
      name: "Returns",
      data: []
    }
    const xAxis = sortedYears.map((dataObject) => {
      graphData.data.push(dataObject.value)
      return dataObject.year.toString()
    })
    return(
      <>
        <div className='pane'>
          <Row className="">
            <Col sm={4}>
              <div className="pane-title">
                <h3>Glide Path Returns</h3>
              </div>
              <div className="table-container">
                <Table hover className="table-bordered-internal exportable" data-export-name="glidepathreturns">
                  <thead>
                    <tr className="table-title row-border-olive-100">
                      <th colSpan={2}></th>
                    </tr>
                    <tr>
                      <th className="text-left">Vintage Year</th>
                      <th className="text-right">Return</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedYears.map(({year,value}, row) => {
                      return (
                        <tr key={row}>
                          <td className="text-left">{year}</td>
                          <td className="text-right">
                            {numbro(value).format("0.00")}%
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col sm={8}>
              <HighchartsReact
                options={SimpleLineOptions({heading: this.props.data.name || "", subHeading: "", xAxis: xAxis, data: [graphData]})}
                highcharts={Highcharts}
              />
            </Col>
          </Row>
        </div>
      </>
    )
  }

  historicalView() {
    const versions = this.props.data.versions || []
    let years:number[] = []
    const tableData = versions.reduce((result:historicalData, entry) => {
      entry?.series?.forEach((series) =>{
        if(
            series?.__typename == "OpenEndedTargetDate" &&
            series.targetDate?.performance &&
            series.targetDate?.performance.length > 0 &&
            series.targetDate?.vintageYear
          ){
          years.push(series.targetDate?.vintageYear)
          series.targetDate?.performance.reduce((seriesResult:historicalData, seriesEntry) => {
            seriesResult[seriesEntry?.endDate] = seriesResult[seriesEntry?.endDate] || {}
            if(!!series.targetDate?.vintageYear && seriesEntry?.value?.active){
              seriesResult[seriesEntry?.endDate][series.targetDate.vintageYear] = seriesResult[seriesEntry?.endDate][series.targetDate.vintageYear] || seriesEntry?.value?.active
            }

            return seriesResult
          }, result)
        }
      })
      return result
    }, {})
    years = _.uniq(years).sort()
    let quarters = Object.keys(tableData).sort().reverse()
    return(
      <>
        <div className='pane'>
          <div className="pane-title">
            <h3>Glide Path Returns</h3>
          </div>
          <div className="table-container">
            <Table hover className="table-bordered-internal exportable" data-export-name="GlidePathReturns">
              <thead>
                <tr className="table-title row-border-olive-100">
                  <th colSpan={years.length+1}></th>
                </tr>
                <tr>
                  <th className="text-left">Quarter</th>
                  {years.map((year:number, idx:number) => (
                    <th key={idx} className="text-right">{year}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {quarters.map((quarter:string, row:number) => (
                  <tr key={row}>
                    <td className="text-left text-nowrap">{quarter}</td>
                    {years.map((year:number, idx:number) => (
                      <td key={idx} className="text-right">{!!tableData[quarter][year] ? numbro(tableData[quarter][year]).format("0.00") + "%" : ""}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    )
  }

  render() {
    if(!this.props.data){
      return <>No Data</>
    }
    if(this.props.screen == "single") return this.singleView()
    if(this.props.screen == "historical") return this.historicalView()
    return <>No Data</>
  }
}

export default GlidePathReturns


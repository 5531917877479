import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import drilldown from 'highcharts/modules/drilldown.js';
import highchartsFullScreen from "highcharts/modules/full-screen";
import { first } from 'lodash';
import moment from 'moment';
import numbro from 'numbro';
import { currencyFormat } from '../Components/Report/Shared/ReportComponent';
import { FinancialSummaryPeriod } from '../__generated__/graphql';

export interface GraphData {
  name: string
  data: number[]
}

export interface PieData {
  name: string
  y: number
  drilldown?: string
}

export interface DrillDownData {
  id: string,
  data: PieData[]
}

export const graphColors = [
  {name: "graph-blue120", hex: "#00466E"},
  {name: "graph-green100", hex: "#669966"},
  {name: "graph-periwinkle100", hex: "#5F96FF"},
  {name: "graph-orange100", hex: "#F28015"},
  {name: "graph-teal80", hex: "#006B6B"},
  {name: "graph-lt-green100", hex: "#9FB400"},
  {name: "graph-teal50", hex: "#00AAAA"},
  {name: "graph-red100", hex: "#7F2024"},
  {name: "graph-blue70", hex: "#6B90AD"},
  {name: "graph-orange120", hex: "#D25C1B"},
  {name: "graph-teal30", hex: "#8BC4C3"},
  {name: "graph-yellow100", hex: "#DDDA23"},
  {name: "graph-blue100", hex: "#0069A0"},
  {name: "graph-blue140", hex: "#06354F"},
  {name: "graph-gray80", hex: "#616971"},
  {name: "graph-periwinkle120", hex: "#1F6CFF"},
  {name: "graph-teal100", hex: "#003E36"},
  {name: "graph-green60", hex: "#A3C2A3"},
  {name: "graph-orange80", hex: "#F2A052"},
  {name: "graph-red80", hex: "#B2595D"},
  {name: "graph-red120", hex: "#5F0004"},
  {name: "graph-gray100", hex: "#242525"},
  {name: "graph-gray90", hex: "#464C51"},
  {name: "graph-gray70", hex: "#848B91"},
  {name: "graph-periwinkle80", hex: "#91B7FF"},
  {name: "graph-periwinkle60", hex: "#C4D9FF"},
  {name: "graph-gray50", hex: "#A3A6A8"},
  {name: "graph-red60", hex: "#CC999B"},
  {name: "graph-red30", hex: "#E5CFCF"},
  {name: "graph-gray30", hex: "#DBDBDB"},
]

interface HighchartsProps {
  heading: string
  subHeading?: string
  data: GraphData[]
  xAxis?: string[]
  startDate?: number
  colours?: string[]
  monthInterval?: number
}

interface HighchartsPieProps {
  heading: string
  subHeading?: string
  data: PieData[]
  dataName: string
  colours?: string[]
  innerSize?: number| string
  simpleTooltip?: boolean
  drilldown?: DrillDownData[]
  onDrillDown?: (e:any) => void
}

highchartsFullScreen(Highcharts)
drilldown(Highcharts)
HC_more(Highcharts);
Highcharts.setOptions({
	lang: {
  	thousandsSep: ','
  }
})

Highcharts.dateFormats = {
  q: function(timestamp) {
    var date = new Date(timestamp),
      quarter = (Math.floor(date.getUTCMonth() / 3) + 1);
    return quarter.toString();
  }
};

// const getSeriesInterval = (startDate:number | null | undefined, monthInterval:number = 3) => {
//   if(!startDate){
//     return {}
//   }
//   return {
//     pointStart: startDate,
//     pointInterval: monthInterval,
//     pointIntervalUnit: 'month',
//   }
// }

const quarterInterval = (startDate: number, headingsCount:number ) => {
  let startMoment = moment(startDate)
  let quarterCounter = 0
  let dateList = Array.apply(null, Array(headingsCount))
  let returnList = dateList.map((entry) => {
    const currentDate = moment(startMoment).add(quarterCounter, 'quarters')
    quarterCounter += 1
    return currentDate.format("Q[Q]YY")
  })
  return returnList
}

export const PercentAreaOptions = ({heading, subHeading, data, xAxis, colours: inColours}:HighchartsProps) => {
  const colours = inColours || graphColors.map((color) => color.hex)
  return {
    "title": {
      "text": heading,
      "align": "left",
      "x": 10,
      "useHTML": true
    },
    "subtitle": {
      "text": subHeading || "",
      "align": "left",
      "x": 10
    },
    "plotOptions": {
      "series": {
        "allowPointSelect": true,
        "states": {
          "select": {
            "color": "#EFFFEF",
            "borderColor": "black",
            "dashStyle": "dot"
          }
        },
        "marker": {
          "enabled": false
        },
        "lineWidth": 3,
        "stacking": "percent",
        "animation": false,
        "events": {}
      }
    },
    "exporting": {},
    "credits": {
      "enabled": false
    },
    "colors": colours,
    "legend": {
      "align": "left",
      "verticalAlign": "top",
      "itemMarginBottom": 5,
      "x": 0,
      "symbolRadius": 2,
    },
    "chart": {
      "type": "area",
      "polar": false
    },
    "series": data,
    "yAxis": {
      "title": {
        "text": "% Weight"
      }
    },
    "tooltip": {
      "valueDecimals": 2,
      "valueSuffix": "%"
    },
    "annotations": [],
    "xAxis": {
      "categories": xAxis,
      "tickmarkPlacement": 'on',
      "title": {
        "text": "Years Until Retirement "
      }
    },
    "stockTools": {
      "gui": {
        "buttons": [
          "simpleShapes",
          "lines",
          "crookedLines"
        ],
        "enabled": false
      }
    },
    "navigation": {
      "events": {},
      "bindingsClassName": "tools-container"
    }
  }
}


export const SimpleLineOptions = ({heading, subHeading, data, xAxis}:HighchartsProps) => {
  return {
    "title": {
      "text": heading,
      "align": "left",
      "x": 10
    },
    "subtitle": {
      "text": subHeading,
      "align": "left",
      "x": 10
    },
    "plotOptions": {
      "series": {
        "allowPointSelect": true,
        "states": {
          "select": {
            "color": "#EFFFEF",
            "borderColor": "black",
            "dashStyle": "dot"
          }
        },
        "marker": {
          "enabled": false
        },
        "lineWidth": 3,
        "events": {}
      }
    },
    "exporting": {},
    "credits": {
      "enabled": false
    },
    "colors": [
      "#055F8E",
      "#A4CBDE"
    ],
    "legend": {
      "align": "left",
      "verticalAlign": "top",
      "itemMarginBottom": 10,
      "x": 0,
      "symbolRadius": 2
    },
    "chart": {
      "type": "column",
      "polar": false
    },
    "series": data,
    "yAxis": {
      "title": {
        "text": "% Change"
      }
    },
    "xAxis": {
      "categories": xAxis,
      "tickmarkPlacement": 'on',
      "title": {
        "text": "Year"
      }
    },
    "tooltip": {
      "valueDecimals": 2,
      "valueSuffix": "%"
    },
    "stockTools": {
      "gui": {
        "buttons": [
          "simpleShapes",
          "lines",
          "crookedLines"
        ],
        "enabled": false
      }
    },
    "navigation": {
      "events": {},
      "bindingsClassName": "tools-container"
    },
    "labels": {
      "items": []
    },
    "pane": {
      "background": []
    },
    "responsive": {
      "rules": []
    }
  }
}

export const ProfileAssetPieOptions = ({heading, subHeading, data, dataName, colours: inColours, innerSize, simpleTooltip, drilldown, onDrillDown}:HighchartsPieProps) => {
  const colours = inColours || graphColors.map((color) => color.hex)
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      style: {
        fontFamily: '"Lato", "Segoe UI", arial, sans-serif'
      },
      events:  {
        drilldown: (e:any) => onDrillDown ? onDrillDown(e) : undefined,
        drillup: (e:any) => onDrillDown ? onDrillDown(e) : undefined,
      }
    },
    title: {
        text: heading
    },
    tooltip: {
      pointFormat: simpleTooltip ? "${point.y:,.2f}" : "AUM: ${point.y:,.2f}M"
    },
    accessibility: {
      point: {
        valueSuffix: 'M',
        valuePrefix: '$'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
            enabled: false
        },
        showInLegend: true,
        innerSize
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: dataName,
      colorByPoint: true,
      data: data
    }],
    colors: colours,
    legend: false,
    drilldown: {
      series: drilldown,
      drillUpButton: {
        position: {
          align: "left"
        }
      }
    }
  }
}

export const ProfileAssetPieDefaults = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    style: {
      fontFamily: '"Lato", "Segoe UI", arial, sans-serif'
    }
  },
  accessibility: {
    point: {
      valueSuffix: 'M',
      valuePrefix: '$'
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
          enabled: false
      },
      showInLegend: true,
    }
  },
  credits: {
    enabled: false
  },
  series: [{
    colorByPoint: true,
  }],
  legend: false,
  drilldown: {
    drillUpButton: {
      position: {
        align: "left"
      }
    }
  }
}


export const ProfileEmployeePieOptions = ({heading, subHeading, data, dataName, colours: inColours, innerSize}:HighchartsPieProps) => {
  const colours = inColours || graphColors.map((color) => color.hex)
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      style: {
        fontFamily: '"Lato", "Segoe UI", arial, sans-serif'
      }
    },
    title: {
        text: heading
    },
    tooltip: {
      pointFormat: "Count: {point.y}"
    },
    // accessibility: {
    //   point: {
    //     valueSuffix: 'M',
    //     valuePrefix: '$'
    //   }
    // },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
            enabled: false
        },
        showInLegend: true,
        innerSize
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: dataName,
      colorByPoint: true,
      data: data
    }],
    colors: colours,
    legend: false
  }
}

export const ProfileLineOptions = ({heading, subHeading, data, startDate, colours: inColours}:HighchartsProps) => {
  const colours = inColours || graphColors.map((color) => color.hex)
  return {
    chart: {
      type: 'line',
      style: {
        fontFamily: '"Lato", "Segoe UI", arial, sans-serif'
      }
    },
    title: {
        text: heading
    },
    tooltip: {
      pointFormat: "AUM: ${point.y:,.2f}M",
      xDateFormat: '%m/%d/%Y'
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      }
    },
    plotOptions: {
      line: {
        marker:{
          radius: 2
        }
      },
      // series: {
      //   pointStart: startDate,
      //   pointInterval: 3,
      //   pointIntervalUnit: 'month',
      // }
    },
    yAxis: {
      min: 0,
      tickAmount:6,
      title: false,
      labels: {
        format: '${value:,.0f}M'
      }
    },
    xAxis: {
      // categories: quarterInterval(startDate || 0, first(data)?.data.length || 0),
      type: 'datetime',
      // labels: {
      //   format: '{value:%Y-%m-%d}',
      //   rotation: 45,
      //   align: 'left'
      // }
      // categories: xAxis,
      // tickmarkPlacement: 'on',
      // title: {
      //   text: "Year"
      // }
    },
    credits: {
      enabled: false
    },
    series: data,
    colors: colours,
    legend: false
  }
}

export const ProfileColOptions = ({heading, subHeading, data, xAxis, startDate, colours: inColours}:HighchartsProps) => {
  const colours = inColours || graphColors.map((color) => color.hex)
  return {
    chart: {
      type: 'line',
      style: {
        fontFamily: '"Lato", "Segoe UI", arial, sans-serif'
      }
    },
    title: {
        text: heading
    },
    tooltip: {
      valueDecimals: 0,
      valueSuffix: "M",
      valuePrefix: "$",
      xDateFormat: '%m/%d/%Y'
    },
    accessibility: {
      point: {
        valuePrefix: '$',
        valueSuffix: 'M',
      }
    },
    plotOptions: {
      line: {
        marker:{
          radius: 2
        }
      },
      series: {
        pointStart: startDate,
        pointInterval: 3,
        pointIntervalUnit: 'month',
      }
    },
    yAxis: {
      title: false
    },
    xAxis: {type: 'datetime'},
    credits: {
      enabled: false
    },
    series: data,
    colors: colours,
    legend: false
  }
}

export const ProfileStackedColumnOptions = ({heading, subHeading, data, xAxis, startDate, colours: inColours}:HighchartsProps) => {
  const colours = inColours || graphColors.map((color) => color.hex)
  return {
    chart: {
      type: 'column',
      style: {
        fontFamily: '"Lato", "Segoe UI", arial, sans-serif'
      }
    },
    title: {
        text: heading
    },
    tooltip: {
      xDateFormat: '%m/%d/%Y'
    },
    accessibility: {
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      // series: {
      //   pointStart: startDate,
      //   pointInterval: 3,
      //   pointIntervalUnit: 'month',
      // }
    },
    yAxis: {
      title: false,
      labels: {
        format: "${value:,.0f}M"
      }
    },
    xAxis: {
      categories: quarterInterval(startDate || 0, first(data)?.data.length || 0),
      // type: 'datetime',
      // labels: {
      //   rotation: 45,
      //   align: 'left',
      //   step: 1,
      // }
      // categories: xAxis,
      // tickmarkPlacement: 'on',
      // title: {
      //   text: "Year"
      // }
    },
    credits: {
      enabled: false
    },
    series: data,
    colors: colours,
    legend: false
  }
}

interface WealthChartProps {
  openData: (number|Date|string)[][]
  closeData: (number|Date|string)[][]
  period: FinancialSummaryPeriod
}

export const WealthLineOptions = ({openData, closeData, period}:WealthChartProps) => {
  // Taken from www.highcharts.com/demo/highcharts/line-ajax
  const pointClick = (passedThis:any) => {
    if (passedThis.series.options.className.indexOf('popup-on-click') !== -1) {
      const chart = passedThis.series.chart;
      const date = Highcharts.dateFormat('%A, %b %e, %Y', passedThis.x);
      const text = `<b>${date}</b><br/>${passedThis.series.name}: ${numbro(passedThis.y).format(currencyFormat)}`;

      const anchorX = passedThis.plotX + passedThis.series.xAxis.pos;
      const anchorY = passedThis.plotY + passedThis.series.yAxis.pos;
      const align = anchorX < chart.chartWidth - 200 ? 'left' : 'right';
      const x = align === 'left' ? anchorX + 10 : anchorX - 10;
      const y = anchorY - 30;
      if (!chart.sticky) {
        chart.sticky = chart.renderer
          .label(text, x, y, 'callout',  anchorX, anchorY)
          .attr({
            align,
            fill: 'rgba(0, 0, 0, 0.75)',
            padding: 10,
            zIndex: 7 // Above series, below tooltip
          })
          .css({
            color: 'white'
          })
          .on('click', function () {
            chart.sticky = chart.sticky.destroy();
          })
          .add();
    } else {
        chart.sticky
          .attr({ align, text })
          .animate({ anchorX, anchorY, x, y }, { duration: 250 });
      }
    }
  }
  return {
    chart: {
      scrollablePlotArea: {
          minWidth: 700
      },
    },
    title: {
        text: 'PORTFOLIO VALUE BY DAY',
        align: 'left'
    },
    subtitle: {
        text: '* Non-trading day',
        align: 'left'
    },
    xAxis: {
        tickInterval: (period === FinancialSummaryPeriod.Monthly ? 7 : 30) * 24 * 3600 * 1000, // one week
        type: 'datetime',
        tickWidth: 0,
        gridLineWidth: 1,
        labels: {
            align: 'center',

            format: '{value:%m/%d/%Y}'
        }
    },
    yAxis: [{ // left y axis
        title: {
            text: null
        },
        labels: {
            align: 'right',
            format: '${value:.,0f}'
        },
        showFirstLabel: false
    }],
    legend: {
        align: 'left',
        verticalAlign: 'top',
        borderWidth: 0,
        itemStyle: {
          "color": "#616971",
        }
    },
    tooltip: {
        shared: true,
        crosshairs: true
    },
    plotOptions: {
        series: {
            cursor: 'pointer',
            className: 'popup-on-click',
            marker: {
                lineWidth: 1
            }
        }
    },
    series: [{
        name: 'Open',
        lineWidth: 4,
        marker: {
          radius: 4,
          enabled: true,
        },
        data: openData,
        point:{
          events: {
            click(e:any) {
              pointClick(this)
            }
          }
        }
    }, {
        name: 'Close',
        data: closeData,
        point:{
          events: {
            click(e:any) {
              pointClick(this)
            }
          }
        }
    }],
    colors: graphColors.map((color) => color.hex),
    credits: {
      enabled: false
    },
  }

}
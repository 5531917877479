import { ComponentType } from "react"

import {
  DebtMaturity,
  DebtMaturityInput,
  FundRaising,
  FundRaisingInput,
  PerformanceCompositeDispersion,
  PerformanceCompositeDispersionInput,
  ValueAddedWeight,
  ValueAddedWeightInput,
  GeneralPartnersCommitment,
  InvestorRoster,
  SearchTypes,
} from "../__generated__/graphql"

/*base on GeneralOrgInfo interface */
export const GeneralOrgInfoInputKeys = {
  name: true,
  shortName: true,
  phone: true,
  fax: true,
  website: true,
  type: true,
  subType: true,
  isConfidential: true,
  industry: true,
  address: true,
  isInactive: true,
  owner: true,
}
/*base on OrgStructure interface */
export const OrgStructureInputKeys = {
  yearFounded: true,
  parentCompany: true,
  yearParentFounded: true,
  firmStructureDescription: true,
  ownershipType: true,
  employeeOwners: true,
  minorityOwned: true,
  minorityType: true,
  womanOwned: true,
  disabledOwned: true,
  history: true,
  jointVentureAffiliations: true,
}

export const DATE_API_FORMAT = "YYYY-MM-DD"
export const DATE_TIME_API_FORMAT = "YYYY-MM-DD HH:mm:ss"

export const DATE_DISPLAY_FORMAT = "MM/DD/YYYY"
export const DATE_TIME_DISPLAY_FORMAT = "MM/DD/YYYY HH:mm:ss"

export const DATE_TEXT_FORMAT = "MMM DD, YYYY"

export const DATE_TOOLTIP_FORMAT = "MM/YYYY"

const formInputSubClasses = [
  "wrapperClasses",
  "labelClasses",
  "inputWrapperClasses",
  "inputClasses",
] as const
export type FormInputSubClasses = typeof formInputSubClasses[number]

// Interface for form field lists
export interface FormInputField {
  property: string
  label: string
  type: string
  subtype?: string
  placeholder?: string
  optionSource?: string // for select boxes only
  options?: JSX.Element | {code: string, value: string}[] // for select options that have already been loaded
  Component?: ComponentType<any> // for table
  optionSourceFunction?: (variables?: any) => JSX.Element | null // for select boxes like Asset Classes
  readonly?: boolean
  // add the ability to override default formInput style,usually width, from outside the module.
  subClasses?: { [name in FormInputSubClasses]?: string }
  tooltip?: { icon: string; id: string }
  // validation
  required?: boolean
  validations?: any // Validation object, passed directly onto the input, see here for options: https://availity.github.io/availity-reactstrap-validation/components/validators/
  rows?: number
  noFormat?: boolean // allow no format setting for NumberFieldInput
  showZero?: boolean  // show zero values in NumberFieldInput
  containerId?: string // add to monthpicker popover.container to show above modal
  searchTypes?: SearchTypes[]
  // used for textLink helper function
  textLinkParams?: {
    property?: string, // to get property value from state and then passed to input as text params
    url: string,
  },
  debounceInput?: boolean,
  charactersLimit?: number
  hideLimit?: boolean
  optionFilterRule?:  { [name: string]: boolean | number }
  dataValues?: {name: string, value: string}[]
  exportTableName?: string
}

export interface ConfigType {
  [property: string]: boolean
}

export interface ConfigTypeUnion {
  [property: string]:
    | ConfigType
    | boolean
    | { [propertyName: string]: ConfigType | boolean }
}

export type ValueAddedItemUnion = ValueAddedWeight | ValueAddedWeightInput

export type PerformanceItemUnion =
  | PerformanceCompositeDispersionInput
  | PerformanceCompositeDispersion

export type FundRaisingItemUnion = FundRaising | FundRaisingInput

export type DebtMaturityScheduleItemUnion = DebtMaturity | DebtMaturityInput

const headerSizeClasses = ["h2", "h3"] as const
export type HeaderSizeClass = typeof headerSizeClasses[number]

const numberInputFormat = ["M", "B", "T", "%", "months", "quarters"] as const
export type NumberInputTrailingStyleType = typeof numberInputFormat[number]

export type GeneralPartnersCommitmentUnion = GeneralPartnersCommitment

export type InvestorRosterUnion = InvestorRoster

import React, { Component, createRef } from "react"
import { AgPromise, GridApi, ICellEditorComp, ICellEditorParams } from "@ag-grid-community/core"
import { FormInput } from "../ui/Forms/FormInput"
import { MonthPickerBody } from "../ui/Forms/MonthPicker"
import { Input } from "reactstrap"
import moment from "moment"

interface CustomFormDatePickerComponentProps extends ICellEditorParams{
  updateValue: (params: any) => void
  period: "month" | "quarter"
}

interface CustomFormDatePickerComponentState {
  value: any
}

export class AgGridDatePickerEditor extends Component<CustomFormDatePickerComponentProps, CustomFormDatePickerComponentState> implements ICellEditorComp{
  eGui!: HTMLDivElement
  eInput!: HTMLInputElement | null
  divRef: any
  inputRef: any
  // cell!: HTMLElement
  // oldWidth!: string
  constructor(props: CustomFormDatePickerComponentProps) {
    super(props)
    this.divRef = createRef()
    this.inputRef = createRef()
    // this.cell = props.eGridCell
    // this.oldWidth = this.cell.style.width
    // this.cell.style.width = "130px"
    this.eGui = this.divRef?.current
    this.state = {
      value: this.props.value,
    }
    this.updateValue = this.updateValue.bind(this)
  }

  componentDidMount() {
    this.focusInput();
  }

  focusInput() {
    let input = this.inputRef?.current;
    if(input) {
      input.focus();
      // Timeout used as a workaround to fix the issue with ag-grid not selecting the input text
      setTimeout(() => {
        input.select();
      }, 0);
    }
  }

  isEmpty = (val: any) => {
    return val === undefined || val === null || val === ""
  }

  isPopup = () => true

  isCancelAfterEnd = () => {
    // let value = this.getValue()
    // if(this.isEmpty(value)) {
    //   return true
    // }
    return false
  }

  getValue = () => {
    const newValue = moment(this.state.value).endOf("month").format("YYYY-MM-DD")
    return newValue
  }

  getGui = () => {
    return this.divRef?.current
  }

  destroy = () => {
  }

  updateValue = (value: any, form?: boolean) => {
    const oldValue = this.state.value
    if(value){
      this.setState({value: value}, () => {
        if(!form && moment(value).month() !== moment(oldValue).month()){
          // Timeout used so that if sorting is used the value is updated after the grid sorts
          setTimeout(() => {
            this.props.api.stopEditing()
          }, 0);
        }
      })
    }
  }

  handleEnterKeyDown = (event: any) => {
    if(event.key === "Enter") {
      event.preventDefault()
    }
  }

  render(){
    const {rowIndex, colDef, period} = this.props
    const property = colDef.field || "ag-grid-form-editor"
    return (
      <div ref={this.divRef} id={`container-${rowIndex}`} className="ag-custom-component-popup w-100 month-picker calendar-dropdown p-2 background-white">
        <FormInput
          property={property}
          displayName={""}
          type={"date"}
          // subtype={formSubtype}
          idx={`${property}-${rowIndex}`}
          editMode={true}
          propertyVal={this.state.value}
          updateValue={(value) => this.updateValue(value, true)}
          inputRef={this.inputRef}
          validateAlsoOnChange={true}
          inputProps={{handleEnterKeyDown: this.handleEnterKeyDown}}
          subClasses={{
            inputClasses: "pl-2 pr-2 mx-0 text-left w-100 disable-browser-picker",
            inputWrapperClasses: "mx-0 px-0 w-100",
            wrapperClasses: "w-100 mx-0 px-0"
          }}
          immediateUpdate={true}
        />
        <MonthPickerBody
          period={period}
          value={this.state.value}
          updateValue={this.updateValue}
          // divRef={this.divRef}
        />
      </div>
    )
  }
}

export default AgGridDatePickerEditor
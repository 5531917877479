import iassign from "immutable-assign"
import _ from "lodash"
import React, { Component, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { History} from 'history'
import { Query } from '@apollo/client/react/components'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PlaceHolder from "./../ui/PlaceHolder"
import moment from 'moment'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import {
  GlidePathSummaryFragment,
  GlidePathSeriesChanges,
  UpdateGlidePathOverviewInput,
  useUpdateGlidePathSummaryMutation,
  useUpdateGlidePathUnauthSummaryMutation,
  GlidePathUnauthSummaryQuery,
  GlidePathSummaryQuery,
  GlidePathUnauthSummaryDocument,
  GlidePathSummaryDocument,
  UpdateGlidePathSummaryMutationFn,
  UpdateGlidePathUnauthSummaryMutationFn,
} from "../../__generated__/graphql"
import { FormInputField, DATE_API_FORMAT } from "../../helpers/constant"
import {
  convertLookupToString,
  excludePropertyArray,
  reShapeObject,
} from "../../helpers/object"
import { FormInput } from "../ui/Forms/FormInput"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import { GetLookupDataToOptions } from '../ui/LookupOptions'
import exportTables from "../../helpers/exportTable"

// const s = printSchema
interface idProps {
  id: number
  auth: Auth
}

interface GlidePathOverviewInput extends FormInputField {
  callanOnly?: boolean
  permissions?: string[]
}

const GlidePathOverview: React.FC<idProps> = ({ id, auth }: idProps) => {
  let queryString = GlidePathUnauthSummaryDocument
  let [updateUnauthFunction] = useUpdateGlidePathUnauthSummaryMutation()
  let [updateFunction] = useUpdateGlidePathSummaryMutation()
  if(auth.checkPermissions(["view:target_date"])){
    queryString = GlidePathSummaryDocument as any
  }
  return(
    <Query<GlidePathUnauthSummaryQuery | GlidePathSummaryQuery> query={queryString} variables={{ id: id }} fetchPolicy="cache-and-network">
      {
        (results) => {
          return(<Holder
            id={id}
            auth={auth}
            data={results.data}
            error={results.error}
            loading={results.loading}
            updateFunction={auth.checkPermissions(["view:target_date"]) ? updateFunction : updateUnauthFunction}
          />)
        }
      }
    </Query>
  )
}

interface HolderProps {
  id: number
  auth: Auth
  data: GlidePathSummaryQuery | GlidePathUnauthSummaryQuery | undefined
  error: any
  loading: boolean
  updateFunction: UpdateGlidePathSummaryMutationFn | UpdateGlidePathUnauthSummaryMutationFn
}

/**
 * query ManagerSummary by managerId, return editable form
 * @param {id:number} idProps for query ManagerSummary
 * **/
const Holder: React.FC<HolderProps> = ({ id, auth, data, error, loading, updateFunction }: HolderProps) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const resultRef = useRef<Result>(null)
  const updateGlidePath = updateFunction

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    const currentGlidePath = resultRef!.current?.state.currentState
    if(!currentGlidePath){
      return
    }
    setSaving(true)
    const { id, ...rest } = currentGlidePath
    const patch = { ...rest }
    let formattedPatch = convertLookupToString(
      patch,
      false,
    )
    let excludeArray = ["__typename", "manager", "name", "shortName"]
    if(!auth.checkPermissions(["edit:target_date"])){
      excludeArray = excludeArray.concat(["type"])
    }
    if(auth.checkPermissions(["view:all_clients"])) {
      // property edited by Callan Only
      formattedPatch = reShapeObject(formattedPatch, {
        "dataCollect": {
          destinationPath: "dataCollect",
          action: (a: string| boolean) =>!!(a === "true" || a === true)
        }
      })
    }else {
      excludeArray = excludeArray.concat(["dataCollect"])
    }

    const updateData = excludePropertyArray(formattedPatch, excludeArray)
    const input = { id, patch: updateData } as UpdateGlidePathOverviewInput

    updateGlidePath({ variables: { input } })
      .then(result => {
        setSaving(false)
        if (result && result.data && result.data.glidePath) {
          const formattedData = _.cloneDeep(result.data.glidePath)
          resultRef!.current?.setState({ currentState: formattedData, initialState: formattedData })
          setEditMode(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log(err)
        console.log("Error testManagerSummary", err.message)
        // throw new Error(`${err.message}`)
      })
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <Button color="secondary btn-thin" className="ml-3 text-callan-blue" onClick={()=> exportTables()}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>  
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} />
        }
      </div>
    </>
  )
  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error && !data) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (data) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Result
              ref={resultRef}
              editMode={editMode}
              history={history}
              data={data?.glidePath as GlidePathSummaryFragment}
              auth={auth}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

const ActiveBooleanOptions = GetLookupDataToOptions({
  data: [
    {
      code: "true",
      value: "Active",
    },
    {
      code: "false",
      value: "Inactive",
    },
  ],
  multiple: true,
})

const GlidePathOverviewInputList: GlidePathOverviewInput[] = [
  {
    property: "manager.name",
    label: "Organization Name",
    type: "text",
    readonly: true
  },
  { property: "name", label: "Glide Path Name", type: "text", readonly: true },
  { property: "shortName", label: "Glide Short Name", type: "text", readonly: true, callanOnly: true },
  // CAL-1686 Status
  { property: "dataCollect", label: "Status", type: "select", subtype: "single", readonly: true, callanOnly: true },
  { property: "", label: "", type: "" },
  {
    property: "type",
    label: "Type",
    type: "text",
    required: true,
    tooltip: {
      icon: "question-circle",
      id: "typeTooltip"
    },
    permissions: ["view:target_date"]
  },
  {
    property: "yearsToTermination",
    label: "Yrs to Termination",
    type: "number",
    required: true,
    subClasses: { inputClasses: "text-left"},
    tooltip: {
      icon: "question-circle",
      id: "yearsToTerminationTooltip"
    }
  },
  {
    property: "eqExposureRetirement",
    label: "% EQ Exp Retirement",
    type: "float",
    subtype: "percent",
    required: true,
    subClasses: { inputClasses: "text-left"},
    tooltip: {
      icon: "question-circle",
      id: "eqExposureRetirementTooltip"
    }
  },
  {
    property: "eqExposureTermination",
    label: "% EQ Exp Termination",
    type: "float",
    subtype: "percent",
    required: true,
    subClasses: { inputClasses: "text-left"},
    tooltip: {
      icon: "question-circle",
      id: "eqExposureTerminationTooltip"
    }
  },
  { property: "", label: "", type: "" },
  {
    property: "retirementIncomeFeature",
    label: "Retirement Income Feature",
    type: "checkbox",
    subtype: "boolean",
  },
  {
    property: "constructionEvaluationFrequency.code",
    label: "Construction Eval Freq",
    type: "select",
    subtype: "single",
    optionSource: "GlidePathConstructionEvaluationFrequencyCode",
    tooltip: {
      icon: "question-circle",
      id: "constructionEvaluationFrequencyTooltip"
    }
  }
]

const GlidePathOverviewTextBlocks: FormInputField[] = [
  {
    property: "designDescription",
    label: "Glide Path Design Description",
    type: "textarea",
    required: true,
    tooltip: {
      icon: "question-circle",
      id: "designDescriptionTooltip"
    }
  },
  {
    property: "rebalancingProcessDescription",
    label: "Rebalancing Process Description",
    type: "textarea",
    required: true
  }
]

interface Props {
  editMode: boolean
  history: History
  data: GlidePathSummaryFragment
  auth: Auth
}

class Result extends Component<Props> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (value: any, property: string) => {
    let path = property.split(".")
    if(property.startsWith("seriesChanges")){
      // seriesChanges["+reference+"]."+property, get the reference
      let reference = path[0].split("[")[1].split("]")[0]
      path = ["seriesChanges", reference, path[1]]
    }
    let newState = _.cloneDeep(this.state.currentState)
    newState = iassign(
      newState,
      path,
      () => value
    )
    this.setState({currentState: newState})
  }

  addSeriesChangesRow = () => {
    let oldState = _.cloneDeep(this.state.currentState)
    if(!oldState?.seriesChanges){
      _.set(oldState, "seriesChanges", [])
    }
    let newState = iassign(
      oldState,
      currentState => currentState?.seriesChanges,
      (rows) => {
        let rowsClone = _.cloneDeep(rows) || []
        const newRow = {
          __typename: "GlidePathSeriesChanges",
          year: moment().year(),
          changes: ""
        } as GlidePathSeriesChanges
        rowsClone.unshift(newRow)
        return rowsClone
      }
    )
    this.setState({currentState: newState})
  }

  removeRow(idx:number) {
    let oldState = _.cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      currentState => currentState?.seriesChanges,
      (rows) => {
        let rowsClone = _.cloneDeep(rows)
        rowsClone?.splice(idx, 1)
        return rowsClone
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  render() {
    return(
      <div className='pane exportable-form' export-data-name="Overview">
        <Row>
          <Col>
            <h3 className="headline underline mb-3">Overview</h3>
          </Col>
        </Row>
        <Row>
          <Col sm="5" className="px-4">
            {GlidePathOverviewInputList.map(
              (
                {
                  property,
                  label,
                  type,
                  subtype,
                  placeholder,
                  optionSource,
                  required,
                  readonly,
                  subClasses,
                  tooltip,
                  permissions,
                },
                idx
              ) => {
                let propertyVal: any = _.get(this.state.currentState, property)
                let onChangeCallback = (value: any) =>
                  this.handleInputChange(value, property)
                if(permissions && !this.props.auth.checkPermissions(permissions)){
                  return(<React.Fragment key={idx}></React.Fragment>)
                }
                let options: any

                if(property === "dataCollect") {
                  if(this.props.auth.checkPermissions(["view:all_clients"])) {
                    readonly = false
                  }
                  propertyVal = propertyVal && propertyVal !== "false" ? "true": "false"
                  // editable only for Callan User
                  options = ActiveBooleanOptions
                }
                return (
                  <FormInput
                    key={idx}
                    property={property}
                    displayName={label}
                    type={type}
                    subtype={subtype}
                    optionSource={optionSource}
                    placeholder={placeholder}
                    idx={idx}
                    editMode={this.props.editMode}
                    propertyVal={propertyVal}
                    updateValue={onChangeCallback}
                    rows={10}
                    required={required}
                    readonly={readonly}
                    subClasses={subClasses}
                    tooltip={tooltip}
                    options={options}
                  />
                )
              }
            )}
          </Col>
          <Col sm="7" className="px-4">
            {GlidePathOverviewTextBlocks.map(
              (
                {
                  property,
                  label,
                  type,
                  subtype,
                  placeholder,
                  optionSource,
                  required,
                  tooltip
                },
                idx
              ) => {
                let propertyVal: any = _.get(this.state.currentState, property)
                let onChangeCallback = (value: any) =>
                  this.handleInputChange(value, property)
                return (
                  <FormInput
                    key={idx}
                    property={property}
                    displayName={label}
                    type={type}
                    subtype={subtype}
                    optionSource={optionSource}
                    placeholder={placeholder}
                    idx={idx}
                    editMode={this.props.editMode}
                    propertyVal={propertyVal}
                    updateValue={onChangeCallback}
                    rows={10}
                    required={required}
                    tooltip={tooltip}
                  />
                )
              }
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <SeriesChanges
              seriesChanges={this.state.currentState.seriesChanges}
              editMode={this.props.editMode}
              updateValue={this.handleInputChange}
              addSerialChangesRow={this.addSeriesChangesRow}
              removeRow={(id:number) => this.removeRow(id)}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

interface SeriesChangesProps {
  seriesChanges: any
  editMode: boolean
  updateValue: (value:any, property:string) => void
  addSerialChangesRow: () => void
  removeRow: (id:number) => void
}

const SeriesChanges = ({seriesChanges, editMode, updateValue, addSerialChangesRow, removeRow}: SeriesChangesProps) => {
  const sortedChanges = _.sortBy(seriesChanges, [sc => sc?.year]).reverse()
  return (
    <Row>
      <Col className="text-center mt-4" sm="12">
        <h3 className="headline underline mb-3 text-left">Series Changes</h3>
      </Col>
      <Col sm="12">
        <Table>
          <thead>
            <tr>
              <th className="text-left">Year</th>
              <th className="text-left">Description of Change</th>
            </tr>
          </thead>
          <tbody>
            {editMode &&
              <tr>
                <td colSpan={3} className="text-left">
                  <Button color="link" className="ml-auto" onClick={() => addSerialChangesRow()}>
                    <FontAwesomeIcon
                      icon="plus-circle"
                      className="mr-2 text-blue-100"
                    />
                    Add Row
                  </Button>
                </td>
              </tr>
            }
            {sortedChanges.map(({year, changes}:any, idx:number) => {
              const reference = _.findIndex(seriesChanges, {year: year, changes: changes})
              let onChangeCallback = (value: any, property:string) =>
                updateValue(value, "seriesChanges["+reference+"]."+property)
              return(
                <tr key={idx + "." + year} className={ editMode ? "full-width hover-actions editing" : "full-width hover-actions" }>
                  <td className="w-25 align-top">
                    <FormInput
                      key={"seriesChanges.year"}
                      property={"seriesChanges.year"}
                      displayName={""}
                      type={"number"}
                      subtype={"year"}
                      idx={2*reference +1}
                      editMode={editMode}
                      propertyVal={year}
                      updateValue={(value:any) => {onChangeCallback(value, "year")}}
                      placeholder={"dd/mm/yyyy"}
                    />
                  </td>
                  <td>
                    <FormInput
                      key={"seriesChanges.changes"}
                      property={"seriesChanges.changes"}
                      displayName={""}
                      type={"textarea"}
                      idx={2*reference +1}
                      editMode={editMode}
                      propertyVal={changes}
                      updateValue={(value:any) => {onChangeCallback(value, "changes")}}
                      placeholder={"Description of material changes to the manager"}
                    />
                  </td>
                  {editMode &&
                    <td className="actions">
                      <Button color="link" className="btn-thin" disabled={!editMode} onClick={() => removeRow(reference) } >
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                    </td>
                  }
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default GlidePathOverview


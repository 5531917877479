import React, { Component } from 'react';
import { filter, compact, sortBy} from 'lodash'
import { UncontrolledDropdown, DropdownMenu, Row, Col, Form, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useManagerAllProductsQuery } from '../../../__generated__/graphql'
import classNames from 'classnames'

interface ProductPickerProps {
  managerId: number
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (product_id:number) => void
}

interface ProductSelection {
  id: number
  name: string
}

const ProductPicker: React.FC<ProductPickerProps> = ({ managerId, title, children, dropdownClass, onClick }: ProductPickerProps) => {
  const { data } = useManagerAllProductsQuery({
    variables: { id: managerId }
  })

  let products = compact((data?.assets && ("products" in data.assets)) ? data.assets?.products?.map(p => p?.product) : []) || []
  products = products.filter(p => !p?.inactive)

  return (
    <UncontrolledDropdown className={classNames("headline-dropdown product-picker-dropdown", dropdownClass)}>
      {children}
      <DropdownMenu>
        <h5>{title}</h5>
        <ProductsSearch key={products.length} data={products} onClick={onClick} />
      </DropdownMenu>
    </UncontrolledDropdown>
  )

}

interface ProductsSearchProps {
  data:ProductSelection[]
  onClick: (product_id:number) => void

}

export class ProductsSearch extends Component<ProductsSearchProps> {
  state = {
    products: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredProducts = filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p.name.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      products: filteredProducts
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="6">
            <Form>
              <FormGroup row className="relative m-0">
                <Input
                  type="text"
                  placeholder="Find Product by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          {/* fix CAL-1416 sort alphabetically case-insensitive */}
          { sortBy(this.state.products, [(obj)=>(obj.name).toLowerCase()]).map((product) => {
            if (!product) { return <></> }

            return (<ListGroupItem tag="a" key={`pp-product-${product?.id}`} onClick={() => this.props.onClick(product.id)}>{product?.name}</ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

export default ProductPicker
import { ApolloQueryResult } from '@apollo/client'
import classNames from 'classnames'
import { cloneDeep, compact, get, isNil, set } from 'lodash'
import React, { Component } from 'react'

import { CashFlowsSummaryInput, CashFlowSummary, ClientPortfolioPerformanceCashflowSummaryQuery, ClosedEndedPrivateNonRegisteredMulitAssetClass, ClosedEndedPrivateNonRegisteredPrivateCredit, ClosedEndedPrivateNonRegisteredPrivateEquity, ClosedEndedPrivateNonRegisteredRealAssets, PerformancePeriodType, PortfolioCurrencyCode } from '../../../__generated__/graphql'
import { ProductClientPortfolioPerformanceReturnRouteProps } from '../../../queries/extended/types/Product'
import { blankCashFlowSummary, TotalTable, VintageTable } from './VehiclesCashflowSummary'

// const AllowedVehicleTypes = ['ClosedEndedPrivateNonRegisteredMulitAssetClass', 'ClosedEndedPrivateNonRegisteredPrivateCredit', 'ClosedEndedPrivateNonRegisteredPrivateEquity', 'ClosedEndedPrivateNonRegisteredRealAssets']


type AllowedVehicleType = ClosedEndedPrivateNonRegisteredMulitAssetClass | ClosedEndedPrivateNonRegisteredPrivateCredit | ClosedEndedPrivateNonRegisteredPrivateEquity | ClosedEndedPrivateNonRegisteredRealAssets

interface ProductPerformanceClientsCashflowSummaryProps extends ProductClientPortfolioPerformanceReturnRouteProps {
  id: number
  editMode: boolean
  date: string
  period: PerformancePeriodType
  data: ClientPortfolioPerformanceCashflowSummaryQuery
  handleChange: (newDiff:CashFlowsSummaryInput) => void
  editCancelled: boolean
  clearChanges: boolean
  refetch: (variables?: Record<string, any> | undefined) => Promise<ApolloQueryResult<ClientPortfolioPerformanceCashflowSummaryQuery>>
}

interface ProductPerformanceClientsState {
  stateDiff: CashFlowsSummaryInput
  activeTab: 'total' | 'vintage'
}

class ProductPerformanceClientsCashflowSummary extends Component<ProductPerformanceClientsCashflowSummaryProps,ProductPerformanceClientsState> {
  constructor(props: ProductPerformanceClientsCashflowSummaryProps) {
    super(props)

    let tab = props.match.params.summaryTab || 'total'
    this.state = {
      stateDiff: {},
      activeTab: tab
    }
  }

  static getDerivedStateFromProps(nextProps:ProductPerformanceClientsCashflowSummaryProps, prevState:ProductPerformanceClientsState){
    if(nextProps.editCancelled || nextProps.clearChanges) {
      return { stateDiff: {} };
    }

    return null;
  }

  blankCashFlowSummary:CashFlowSummary = {
    __typename: 'CashFlowSummary',
    periodEndDate: this.props.date,
    currency: null,
    totalPartnerships: null,
    activePartnerships: null,
    liquidatedPartnerships: null,
    activeCompanies: null,
    committedCapital: null,
    paidInCapital: null,
    uncalledCapital: null,
    distributedCapital: null,
    netAssetValue: null,
    grossIRR: null,
    netIRR: null
  }

  setStateDiff = (newDiff:CashFlowsSummaryInput) => {
    this.setState({stateDiff: newDiff}, () => {
      this.props.handleChange(newDiff)
    })
  }

  onTotalChange = (property: keyof CashFlowSummary, value:number) => {
    const cashFlowSummary = this.props.data.portfolio_cashflow_summary?.cashFlowsSummary?.cashFlowSummary || blankCashFlowSummary(this.props.date)
    let cashFlowSummaryInput = cloneDeep(this.state.stateDiff)

    if (isNil(cashFlowSummaryInput.cashFlowSummary?.periodEndDate)) {
      set(cashFlowSummaryInput, ['cashFlowSummary', 'periodEndDate'], cashFlowSummary?.periodEndDate)
      set(cashFlowSummaryInput, ['cashFlowSummary', 'currency'], cashFlowSummary?.currency?.code || PortfolioCurrencyCode.USADOL)
    }
    set(cashFlowSummaryInput, ['cashFlowSummary', property], value)

    this.setStateDiff(cashFlowSummaryInput as CashFlowsSummaryInput)
  }

  onVintageChange = (year: number, property: keyof CashFlowSummary, value:number | string | null) => {
    const cashFlowSummary = this.props.data.portfolio_cashflow_summary?.cashFlowsSummary?.cashFlowSummary || blankCashFlowSummary(this.props.date)
    let cashFlowSummaryInput = cloneDeep(this.state.stateDiff)

    let cashFlowSummaryVintageYearInput = cloneDeep(cashFlowSummaryInput.cashFlowSummaryVintageYear)
      if (isNil(cashFlowSummaryVintageYearInput)) {
        cashFlowSummaryVintageYearInput = []
      }

    let yearDataIndex = cashFlowSummaryVintageYearInput.findIndex(yearD => yearD?.vintageYear === year)

    let cashFlowVintageInput = cloneDeep(get(cashFlowSummaryVintageYearInput, yearDataIndex))
    if (yearDataIndex < 0 || isNil(cashFlowVintageInput)) {
      cashFlowVintageInput = {
        vintageYear: year,
        cashFlowSummary: {
          periodEndDate: cashFlowSummary?.periodEndDate,
          currency: cashFlowSummary?.currency?.code || PortfolioCurrencyCode.USADOL
        }
      }
    }

    set(cashFlowVintageInput, ["cashFlowSummary", property], value)
    if (yearDataIndex < 0) {
      cashFlowSummaryVintageYearInput.push(cashFlowVintageInput)
    } else {
      cashFlowSummaryVintageYearInput[yearDataIndex] = cashFlowVintageInput
    }

    set(cashFlowSummaryInput, 'cashFlowSummaryVintageYear', cashFlowSummaryVintageYearInput)

    this.setStateDiff(cashFlowSummaryInput as CashFlowsSummaryInput)
  }

  switchTab = (tab: "total" | "vintage") => {
    this.setState({activeTab: tab})
  }

  render() {
    const { editMode, date, data } = this.props
    const portfolio = data.portfolio_cashflow_summary

    const cashflowSummary = portfolio?.cashFlowsSummary

    return (
      <>
      <ul className={"horizontal-picker"}>
        <li className={classNames("horizontal-picker-button",{ active: this.state.activeTab === "total" })}  onClick={() => {
          this.switchTab('total')
        }} >Total</li>
        <li className={classNames("horizontal-picker-button",{ active: this.state.activeTab === "vintage" })}  onClick={() => {
          this.switchTab('vintage')
          // this.setState({ activeTab: "vintage"})
        }} >Vintage</li>
      </ul>
      <div className="table-container" key="client-portfolio-cashflow-summary">
        { this.state.activeTab === "total" &&
          <TotalTable
            cashflowSummary={cashflowSummary?.cashFlowSummary || this.blankCashFlowSummary}
            editMode={editMode}
            updateValue={this.onTotalChange}
            date={date}
            key="portfolio-cashflow-summary-total-table"
          />
        }
        { this.state.activeTab === "vintage" &&
          <VintageTable
            vintageYears={compact(cashflowSummary?.cashFlowSummaryVintageYear)}
            editMode={editMode}
            updateValue={this.onVintageChange}
            editCancelled={this.props.editCancelled}
            date={date}
            key="portfolio-cashflow-summary-vintage-table"
          />
        }
      </div>
      </>
    )

  }
}

export default ProductPerformanceClientsCashflowSummary
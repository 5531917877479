import React, { Component, RefObject } from "react"
import _, { get } from "lodash"
import iassign from "immutable-assign"
import {
  Row,
  Col,
  ListGroupItem,
  Table,
  Button,
  Input,
  UncontrolledAlert,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import { match } from "react-router-dom"
import { History } from "history"
import moment from "moment"
import { Query } from '@apollo/client/react/components'
import { reShapeObject } from "../../helpers/object"
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, FormInputField } from "../../helpers/constant"
import { FormInput } from "../ui/Forms/FormInput"
import {
  Person,
  PersonProduct,
  PersonInvestment,
  PersonQuery,
  PersonDocument,
} from "../../__generated__/graphql"
import PlaceHolder from "../ui/PlaceHolder"
import { GetLookupDataToOptions } from "../ui/LookupOptions"
import { ManagerType } from "../../helpers/helpers"
import { Picker, OptionItem } from "./Picker"
import ErrorDisplay from "./ErrorDisplay"

const CHARACTER_LIMIT = 200;

interface PersonProps {
  person: Person
  editMode: boolean
  managerType?: ManagerType
  productList?: any
  productId?: number
}

interface CountryData {
  value: string;
  data: {   
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
  }
}

interface PersonInput extends FormInputField {
  displayTypes?: string[]
  displayData?: {
    property: string
    value: any
  }
  withExtension?: boolean
}

const PersonalInput: PersonInput[] = [
  {
    property: "salutation",
    label: "Salutation",
    type: "text",
  },
  {
    property: "firstName",
    label: "First Name",
    type: "text",
  },
  {
    property: "lastName",
    label: "Last Name",
    type: "text",
  },
  {
    property: "designation",
    label: "Designations",
    type: "text",
  },
  {
    property: "title",
    label: "Title",
    type: "text",
  },
  {
    property: "background.keyPerson",
    label: "Key Executive",
    type: "checkbox",
    subtype: "boolean",
  },
]

const ContactInput: PersonInput[] = [
  {
    property: "phone",
    label: "Work",
    type: "text",
    subtype: "phone",
    withExtension: true
  },
  {
    property: "mobile",
    label: "Cell",
    type: "text",
    subtype: "phone",
  },
  {
    property: "fax",
    label: "Fax",
    type: "text",
    subtype: "phone",
  },
  {
    property: "email",
    label: "Email",
    type: "text",
    subtype: "email",
    subClasses: {
      labelClasses: "col-sm-2",
      inputWrapperClasses: "col-sm-10"
    }
  },
]

const AddressInput: PersonInput[] = [
  { property: "street[0]", label: "Street 1", type: "text" },
  { property: "street[1]", label: "Street 2", type: "text" },
  { property: "city", label: "City", type: "text" },
  { property: "state.code", label: "State", type: "select", subtype: "single", optionSource: "StateCode" },
  { property: "zipcode", label: "Zip Code", type: "text", subtype: "zipcode" },
  { property: "country.code", label: "Country", type: "select", subtype: "single", optionSource: "AddressCountryCode" },
]

interface TimeInput extends FormInputField {
  allocationType: string
}
const AllocationInput: TimeInput[] = [
  {
    property: "approximationTime.code",
    label: "Firm Management",
    type: "select",
    subtype: "single",
    optionSource: "ApproximationTypeCode",
    allocationType: "FIRM",
  },
  {
    property: "approximationTime.code",
    label: "Investment Sourcing",
    type: "select",
    subtype: "single",
    optionSource: "ApproximationTypeCode",
    allocationType: "SOURCE",
  },
  {
    property: "approximationTime.code",
    label: "Investment Due Diligence",
    type: "select",
    subtype: "single",
    optionSource: "ApproximationTypeCode",
    allocationType: "IDD",
  },
  {
    property: "approximationTime.code",
    label: "Capital Raising/Marketing",
    type: "select",
    subtype: "single",
    optionSource: "ApproximationTypeCode",
    allocationType: "MKT",
  },
  {
    property: "approximationTime.code",
    label: "Other",
    type: "select",
    subtype: "single",
    optionSource: "ApproximationTypeCode",
    allocationType: "OTHER",
  },
]

const BackgroundInput: PersonInput[] = [
  {
    property: "background.yearStartedInIndustry",
    label: "Year Started in Industry",
    type: "number",
    subtype: "year",
  },
  {
    property: "background.yearStartedWithFirm",
    label: "Year Started with Firm",
    type: "number",
    subtype: "year",
  },
  {
    property: "active",
    label: "Status with Firm",
    type: "select",
    subtype: "single",
  },
  {
    property: "terminatedDate",
    label: "Date Ended with Firm",
    type: "date",
    required: true,
    displayData: {
      property: "active",
      value: false,
    },
  },
  {
    property: "reasonLeftFirm",
    label: "Reason for leaving",
    type: "textarea",
    displayData: {
      property: "active",
      value: false,
    },
    charactersLimit: CHARACTER_LIMIT
  },
  {
    property: "background.ownershipAmt.code",
    label: "Company Ownership",
    type: "select",
    subtype: "single",
    optionSource: "OwnershipAmountCode",
  },
  {
    property: "background.employeeContract",
    label: "Employment Contract",
    type: "radio",
    subtype: "boolean",
  },
]

const ActiveBooleanOptions = GetLookupDataToOptions({
  data: [
    {
      code: "true",
      value: "Active",
    },
    {
      code: "false",
      value: "Inactive",
    },
  ],
  multiple: true,
})

const BooleanOptions = GetLookupDataToOptions({
  data: [
    {
      code: "true",
      value: "Yes",
    },
    {
      code: "false",
      value: "No",
    },
  ],
})

export const PreShapeActions = {
  street: {
    destinationPath: "street",
    action: (address: string) => {
      const splitAddress = (address || "").split(/\r?\n/)
      return [splitAddress[0], splitAddress.slice(1).join("")]
    },
  },
  "background.bio": {
    destinationPath: "background.bio",
    action: (value: string) => {
      return value || ""
    },
  },
  "background.employeeContract": {
    destinationPath: "background.employeeContract",
    action: (value: boolean) => {
      return value || false
    },
  },
  "background.keyPerson": {
    destinationPath: "background.keyPerson",
    action: (value: boolean) => {
      return value || false
    },
  },
  "background.ownershipAmt": {
    destinationPath: "background.ownershipAmt",
    action: (value: any) => {
      return value || {code: null, value: null, __typename: "OwnershipAmount"}
    },
  },
  // "employer": {
  //   destinationPath: "employer",
  //   action: (value: any) => {
  //     if(!value){
  //       return {
  //         id: IDBCursor,
  //         name:
  //       }
  //     }
  //     return value
  //   }
  // },
}

interface QueryPersonProps {
  personId?: string
  editMode: boolean
  managerType?: ManagerType
  productList?: any
  productId?: number
  detailRef: RefObject<PersonDetail>
}

export class QueryPeopleDetails extends Component<QueryPersonProps> {
  render() {
    const { personId, editMode, managerType, productList, productId, detailRef } = this.props
    return (
      <Query<PersonQuery>
        query={PersonDocument}
        variables={{ id: personId }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange={true}
      >
        {(results) => {
          if (results.loading) {
            return (
              <Col md="8" lg="9" className="pl-1">
                <div className="pane">
                  <PlaceHolder />
                </div>
              </Col>
            )
          }

          if (results.error) {
            var error=results.error
            return (
              <UncontrolledAlert color="danger">
                <h4>Error Fetching Interaction</h4>
                <ErrorDisplay error={error}/>
              </UncontrolledAlert>
            )
          }
          if(!results.data || !results.data.person){
            return (<>No Person</>)
          }
          return(<PersonDetail
            ref={detailRef}
            person={results.data.person as Person}
            editMode={editMode}
            managerType={managerType}
            productList={productList}
            productId={productId}
          />)
        }}
      </Query>
    )
  }
}

interface PersonDetailState {
  currentState: any
  initialState: any
  newProductId: number
}

export class PersonDetail extends Component<PersonProps> {
  constructor(props: any) {
    super(props)
    const { person } = props
    const formattedData = reShapeObject(_.cloneDeep(person), PreShapeActions)
    this.state = {
      currentState: formattedData,
      initialState: formattedData,
      newProductId: props.productId || -1,
    }
  }

  state: PersonDetailState

  resetForm = () => {
    this.setState({ currentState: this.state.initialState })
  }

  handleInputChange = (value: any, property: string) => {
    let path = property.split(".")
    if(property.startsWith("street")) {
      path = ["street", property[7]]
    }
    let newState = iassign(
      this.state.currentState,
      path,
      () => value
    )
    if(value === true && property === "active"){
      newState = iassign(
        newState,
        (currentState) => currentState?.terminatedDate,
        () => null
      )
    }
    this.setState({ currentState: newState })
  }

  handlePhoneNumberInputChange = (property: "mobile" | "fax" | "phone" | "extension", phoneNumber: string) => {
    let newState

    if (property) {
      newState = _.set(this.state.currentState, property, phoneNumber)
    }
    
    this.setState({ currentState: newState })
  }

  handleTimeInputChange = (
    value: any,
    property: string,
    allocationType: string
  ) => {
    const newState = iassign(
      this.state.currentState,
      (currentState) => currentState?.allocationTimes,
      (selectedTable) => {
        let rows = _.cloneDeep(selectedTable)
        if (!!value) {
          var selectedRow = _.find(rows, (o) => {
            return o.allocationType.code === allocationType
          })
          if (selectedRow) {
            _.set(selectedRow, property, value)
          } else {
            const newRow = {
              allocationType: { code: allocationType, __typename: "Lookup" },
              approximationTime: { code: value, __typename: "Lookup" },
              __typename: "PersonAllocationTime",
            }
            rows.push(newRow)
          }
        } else {
          _.remove(rows, (o: any) => {
            return o.allocationType.code === allocationType
          })
        }

        return rows
      }
    )
    this.setState({ currentState: newState })
  }

  handleEquityInputChange = (value: any, property: string, row: number) => {
    const newState = iassign(
      this.state.currentState,
      (currentState) => currentState?.privateEquityInvestments,
      (selectedTable) => {
        let rows = _.cloneDeep(selectedTable)
        var selectedRow = rows[row]
        if (selectedRow) {
          _.set(selectedRow, property, value)
        } else {
          const newRow = {
            investmentName: "",
            investmentSourced: false,
            investmentLed: false,
            investmentBoard: false,
            __typename: "PersonInvestment",
          }
          _.set(newRow, property, value)
          rows.push(newRow)
        }

        return rows
      }
    )
    this.setState({ currentState: newState })
  }

  addEquityRow = () => {
    const newState = iassign(
      this.state.currentState,
      (currentState) => currentState?.privateEquityInvestments,
      (selectedTable) => {
        let rows = _.cloneDeep(selectedTable)
        const newRow = {
          investmentId: null,
          investmentName: "",
          investmentSourced: false,
          investmentLed: false,
          investmentBoard: false,
          __typename: "PersonInvestment",
        }
        rows.push(newRow)
        return rows
      }
    )
    this.setState({ currentState: newState, newProductId: -1 })
  }

  removeEquityRow = (row: number) => {
    const newState = iassign(
      this.state.currentState,
      (currentState) => currentState?.privateEquityInvestments,
      (selectedTable) => {
        let rows = _.cloneDeep(selectedTable)
        rows.splice(row, 1)
        return rows
      }
    )
    this.setState({ currentState: newState })
  }

  handleProductInputChange = (
    value: any,
    property: string,
    productId: number
  ) => {
    const newState = iassign(
      this.state.currentState,
      (currentState) => currentState?.products,
      (selectedTable) => {
        let rows = _.cloneDeep(selectedTable)
        var selectedRow = _.find(rows, (o) => {
          return o.product.product.id === productId
        })
        if (selectedRow) {
          _.set(selectedRow, property, value)
        } else {
          console.log("Product not found to update")
        }

        return rows
      }
    )
    this.setState({ currentState: newState })
  }

  addProductRow = () => {
    const foundProduct = _.find(this.props.productList, (prod) => {
      return prod.product.id === this.state.newProductId
    })
    if (!foundProduct) {
      return
    }
    const newState = iassign(
      this.state.currentState,
      (currentState) => currentState?.products,
      (selectedTable) => {
        let rows = _.cloneDeep(selectedTable)
        const newRow = {
          active: true,
          carriedInterest: null,
          dateLeftProduct: null,
          investCommMem: null,
          prodTitleNum: {
            code: null,
            value: null,
            __typename: "PersonProductTitle",
          },
          product: {
            product: {
              id: foundProduct.product.id,
              name: foundProduct.product.name,
              __typename: "ProductFields",
            },
            __typename: "OpenEndedEquity",
          },
          reasonLeftProduct: null,
          yearStartedWithProduct: null,
          __typename: "PersonProduct",
        }
        rows.push(newRow)

        return rows
      }
    )
    this.setState({ currentState: newState, newProductId: -1 })
  }

  removeProductRow = (productId: number) => {
    const newState = iassign(
      this.state.currentState,
      (currentState) => currentState?.products,
      (selectedTable) => {
        let rows = _.cloneDeep(selectedTable)
        _.remove(rows, (prod: any) => {
          return prod.product.product.id == productId
        })
        return rows
      }
    )
    this.setState({ currentState: newState, newProductId: -1 })
  }

  render() {
    const sortedProducts = _.sortBy(this.state.currentState.products, [
      (o) => {
        const prosProd = _.find(this.props.person.products, (p: any)=> {
          return o.product?.product?.id === p.product?.product?.id
        })
        if(!prosProd){
          return 4
        }
        return prosProd.active ? 2 : 3
      },
    ])
    let productOptions = this.props.productList
      .filter((prod: any) => {
        return (
          !prod.product.inactive &&
          _.findIndex(this.state.currentState.products, (p: any) => {
            return p.product?.product?.id == prod?.product?.id
          }) == -1
        )
      })
      .sort((a:any, b: any) => {
        const nameA = a.product.name.toUpperCase()
        const nameB = b.product.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      .map((opt: any) => (
        <option key={opt.product.id} value={opt.product.id}>
          {opt.product.name} {opt.product.inactive && "(Inactive)"}
        </option>
      ))

    productOptions.unshift(
      <option key={-1} value="-1">
        Select Product
      </option>
    )
    return (
      <Col md="8" lg="9" className="pl-1">
        <div className="pane">
          <Row>
            <Col>
              <h5 className="mini">PEOPLE</h5>
              <h2 className="headline underline">
                {this.state.currentState.firstName}{" "}
                {this.state.currentState.lastName}
                {!this.state.currentState.active && (
                  <div className="inactive-tag">Inactive</div>
                )}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <h3 className="headline gray-underline underline mt-4">
                Personal
              </h3>
              <Row>
                <Col md="2" className="d-flex justify-content-center">
                  <FontAwesomeIcon
                    icon={["fal", "user"]}
                    size="2x"
                    className="large mt-2"
                  />
                </Col>
                <Col>
                  {PersonalInput.map(
                    (
                      {
                        property,
                        label,
                        type,
                        subtype,
                        placeholder,
                        optionSource,
                        readonly,
                        tooltip,
                        subClasses,
                        displayData,
                      },
                      idx
                    ) => {
                      if (
                        displayData &&
                        _.get(this.state.currentState, displayData.property) !=
                          displayData.value
                      ) {
                        return
                      }
                      let propertyVal: any = _.get(
                        this.state.currentState,
                        property
                      )
                      let onChangeCallback = (value: any) =>
                        this.handleInputChange(value, property)
                      return (
                        <FormInput
                          key={idx}
                          property={property}
                          displayName={label}
                          type={type}
                          subtype={subtype}
                          placeholder={placeholder}
                          idx={idx}
                          editMode={this.props.editMode}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                          optionSource={optionSource}
                          readonly={readonly}
                          tooltip={tooltip}
                          subClasses={subClasses}
                        />
                      )
                    }
                  )}
                </Col>
              </Row>
              <h3 className="headline gray-underline underline mt-5">
                Contact
              </h3>
              <Row>
                <Col md="2" className="d-flex justify-content-center">
                  <FontAwesomeIcon
                    icon={["fal", "phone"]}
                    size="2x"
                    className="large mt-2"
                  />
                </Col>
                <Col>
                  {ContactInput.map(
                    (
                      {
                        property,
                        label,
                        type,
                        subtype,
                        placeholder,
                        optionSource,
                        readonly,
                        tooltip,
                        subClasses,
                        displayData,
                        withExtension,
                      },
                      idx
                    ) => {
                      if (
                        displayData &&
                        _.get(this.state.currentState, displayData.property) !=
                          displayData.value
                      ) {
                        return
                      }
                      let propertyVal: any = _.get(
                        this.state.currentState,
                        property
                      )
                      let onChangeCallback = (value: any) => this.handleInputChange(value, property)
                                     
                      if (property === "mobile" || property === "fax" || property === "phone" || property === "extension" ) {
                        return (                        
                          <Row key={idx}>
                            <Col sm="9">
                               <FormInput
                                  key={idx}
                                  idx={idx +1}
                                  type={'text'}
                                  propertyVal={propertyVal}
                                  property={property}
                                  displayName={label}
                                  placeholder={placeholder}
                                  editMode={this.props.editMode}
                                  updateValue={(value: CountryData) => this.handleInputChange(value, property)}
                                />
                            </Col>
                            {withExtension && 
                              <Col sm="3">
                                <FormInput
                                  key={idx}
                                  property={"extension"}
                                  displayName={""}
                                  type={"text"}
                                  placeholder={"Extension"}
                                  idx={idx +1}
                                  editMode={this.props.editMode}
                                  propertyVal={get(this.state.currentState, "extension")}
                                  updateValue={(value:any) => this.handleInputChange(value, "extension")}
                                  />
                              </Col>
                              }
                          </Row>
                        )
                      }
                      return (
                        <Row key={idx}>
                          <Col sm="9">
                            <FormInput
                              key={idx}
                              property={property}
                              displayName={label}
                              type={type}
                              subtype={subtype}
                              placeholder={placeholder}
                              idx={idx}
                              editMode={this.props.editMode}
                              propertyVal={propertyVal}
                              updateValue={onChangeCallback}
                              optionSource={optionSource}
                              readonly={readonly}
                              tooltip={tooltip}
                              subClasses={subClasses}
                            />
                          </Col>
                        </Row>
                      )
                    }
                  )}
                </Col>
              </Row>
              <h3 className="headline gray-underline underline mt-5">
                Address
              </h3>
              <Row>
                <Col md="2" className="d-flex justify-content-center">
                  <FontAwesomeIcon
                    icon={["fal", "map-marker-alt"]}
                    size="2x"
                    className="large mt-2"
                  />
                </Col>
                <Col>
                  {AddressInput.map(
                    (
                      {
                        property,
                        label,
                        type,
                        subtype,
                        placeholder,
                        optionSource,
                        readonly,
                        tooltip,
                        subClasses,
                        displayData,
                      },
                      idx
                    ) => {
                      if (
                        displayData &&
                        _.get(this.state.currentState, displayData.property) !=
                          displayData.value
                      ) {
                        return
                      }
                      let propertyVal: any = _.get(
                        this.state.currentState,
                        property
                      )
                      let onChangeCallback = (value: any) =>
                        this.handleInputChange(value, property)
                      return (
                        <FormInput
                          key={idx}
                          property={property}
                          displayName={label}
                          type={type}
                          subtype={subtype}
                          placeholder={placeholder}
                          idx={idx}
                          editMode={this.props.editMode}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                          optionSource={optionSource}
                          readonly={readonly}
                          tooltip={tooltip}
                          subClasses={subClasses}
                        />
                      )
                    }
                  )}
                </Col>
              </Row>
              {(this.props.managerType?.PE || this.props.managerType?.RA) && (
                <>
                  <h3 className="headline gray-underline underline mt-5">
                    Private Equity Investments
                  </h3>
                  <div className="table-container">
                    <Table hover>
                      <thead>
                        <tr className="uppercase unbordered text-gray-80">
                          <th></th>
                          <th className="p-0" colSpan={3}>
                            All Investments
                          </th>
                          {this.props.editMode && <th></th>}
                        </tr>
                        <tr className="uppercase unbordered text-gray-80">
                          <th className="text-left">Investment Name</th>
                          <th>Sourced</th>
                          <th>Led</th>
                          <th>Board Seats</th>
                          {this.props.editMode && <th></th>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.currentState.privateEquityInvestments.map(
                          (investment: PersonInvestment, idx: number) => {
                            let onChangeCallback = (
                              value: any,
                              property: string
                            ) =>
                              this.handleEquityInputChange(value, property, idx)
                            let onDelete = () => this.removeEquityRow(idx)
                            return (
                              <EquityRow
                                key={idx}
                                investment={investment}
                                editMode={this.props.editMode}
                                row={idx}
                                handleInputChange={onChangeCallback}
                                onDelete={onDelete}
                              />
                            )
                          }
                        )}
                        {this.props.editMode && (
                          <tr className="no-hover">
                            <td colSpan={5} className="text-left">
                              <Button
                                color="link"
                                onClick={() => this.addEquityRow()}
                              >
                                <FontAwesomeIcon
                                  icon="plus-circle"
                                  className="mr-2 text-blue-100"
                                />
                                Add Row
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </Col>
            <Col md="7">
              <h3 className="headline underline gray-underline mt-4">Bio</h3>
              <FormInput
                property={"background.bio"}
                displayName={""}
                type={"textarea"}
                idx={1}
                editMode={this.props.editMode}
                propertyVal={_.get(this.state.currentState, "background.bio")}
                updateValue={(value: any) =>
                  this.handleInputChange(value, "background.bio")
                }
              />
              <h3 className="headline underline gray-underline mt-4">
                Background &amp; Details
              </h3>
              <Row>
                <Col md="2" className="d-flex justify-content-center">
                  <FontAwesomeIcon
                    icon={["fal", "briefcase"]}
                    size="2x"
                    className="large mt-2"
                  />
                </Col>
                <Col>
                  {BackgroundInput.map(
                    (
                      {
                        property,
                        label,
                        type,
                        subtype,
                        placeholder,
                        optionSource,
                        readonly,
                        tooltip,
                        subClasses,
                        displayData,
                        required,
                        charactersLimit,
                      },
                      idx
                    ) => {
                      if (
                        displayData &&
                        _.get(this.state.currentState, displayData.property) !=
                          displayData.value
                      ) {
                        return
                      }
                      let propertyVal: any = _.get(
                        this.state.currentState,
                        property
                      )
                      let options, errorText
                      let onChangeCallback = (value: any) => {
                        this.handleInputChange(value, property)
                      }
                      if (property == "active") {
                        propertyVal = propertyVal.toString()
                        options = ActiveBooleanOptions
                        const invalidDate = _.get(
                          this.state.currentState,
                          "terminatedDate"
                        )
                        if(propertyVal === "true" && invalidDate){
                          errorText = `Person will be inactive ${moment(invalidDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`
                        }
                        onChangeCallback = (value: any) =>{
                          this.handleInputChange(value === "true", property)
                        }
                      }
                      return (
                        <React.Fragment key={idx}>
                          <FormInput
                            key={idx}
                            property={property}
                            displayName={label}
                            type={type}
                            subtype={subtype}
                            placeholder={placeholder}
                            idx={idx}
                            editMode={this.props.editMode}
                            propertyVal={propertyVal}
                            updateValue={onChangeCallback}
                            optionSource={optionSource}
                            readonly={readonly}
                            tooltip={tooltip}
                            subClasses={subClasses}
                            options={options}
                            required={required}
                            charactersLimit={charactersLimit}
                          />
                          {!!errorText &&
                            <div className="text-danger">
                              {errorText}
                            </div>
                          }
                        </React.Fragment>
                      )
                    }
                  )}
                </Col>
              </Row>
               <h3 className="headline gray-underline underline mt-5">
                Time Allocation
              </h3>
              <Row>
                <Col md="2" className="d-flex justify-content-center">
                  <FontAwesomeIcon
                    icon={["fal", "clock"]}
                    size="2x"
                    className="large mt-2"
                  />
                </Col>
                <Col>
                  {AllocationInput.map(
                    (
                      {
                        property,
                        label,
                        type,
                        subtype,
                        placeholder,
                        optionSource,
                        readonly,
                        tooltip,
                        subClasses,
                        allocationType,
                      },
                      idx
                    ) => {
                      var selectedRow = _.find(
                        this.state.currentState.allocationTimes,
                        (o) => {
                          return o.allocationType.code === allocationType
                        }
                      )

                      let propertyVal: any = _.get(selectedRow, property)

                      let onChangeCallback = (value: any) =>
                        this.handleTimeInputChange(
                          value,
                          property,
                          allocationType
                        )
                      return (
                        <FormInput
                          key={idx}
                          property={property}
                          displayName={label}
                          type={type}
                          subtype={subtype}
                          placeholder={placeholder}
                          idx={idx}
                          editMode={this.props.editMode}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                          optionSource={optionSource}
                          readonly={readonly}
                          tooltip={tooltip}
                          subClasses={subClasses}
                        />
                      )
                    }
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="headline gray-underline underline mt-4">
                Products
              </h3>
              <div className="table-container">
                <Table hover>
                  <thead>
                    <tr className="uppercase unbordered text-gray-80">
                      <th className="text-left">Product</th>
                      <th className="text-left">Product Role</th>
                      <th className="text-right">Start Year</th>
                      <th className="text-left">End date</th>
                      <th className="text-right">Status with Product</th>
                      <th className="text-left">Reason for Leaving Product</th>
                      {(this.props.managerType?.PE ||
                        this.props.managerType?.RA ||
                        this.props.managerType?.PEFOF) && (
                        <>
                          <th className="text-left">Inv. Committee Member</th>
                          <th className="text-left">
                            Carried Interest Participant
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedProducts.map(
                      (product: PersonProduct, idx: number) => {
                        let onChangeCallback = (value: any, property: string) =>
                          this.handleProductInputChange(
                            value,
                            property,
                            product?.product?.product?.id || 0
                          )
                        let onDelete = () =>
                          this.removeProductRow(
                            product?.product?.product?.id || 0
                          )
                        return (
                          <ProductRow
                            key={idx}
                            product={product}
                            editMode={this.props.editMode}
                            row={idx}
                            handleInputChange={onChangeCallback}
                            data={this.state.currentState}
                            managerType={this.props.managerType}
                            // onDelete={onDelete}
                          />
                        )
                      }
                    )}
                    {this.props.editMode && (
                      <tr>
                        <td
                          colSpan={
                            this.props.managerType?.PE ||
                            this.props.managerType?.RA ||
                            this.props.managerType?.PEFOF
                              ? 5
                              : 4
                          }
                        >
                          <Input
                            type="select"
                            value={this.state.newProductId}
                            onChange={(e) =>
                              this.setState({
                                newProductId: parseInt(e.target.value),
                              })
                            }
                            placeholder={"Select Product"}
                          >
                            {productOptions}
                          </Input>
                        </td>
                        <td
                          colSpan={
                            this.props.managerType?.PE ||
                            this.props.managerType?.RA ||
                            this.props.managerType?.PEFOF
                              ? 3
                              : 2
                          }
                        >
                          <Button
                            color="link"
                            className="ml-auto"
                            onClick={() => this.addProductRow()}
                          >
                            Add
                            <FontAwesomeIcon
                              icon="plus-circle"
                              className="ml-2 text-blue-100"
                            />
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    )
  }
}

interface EquityRowProps {
  investment: PersonInvestment
  row: number
  editMode: boolean
  handleInputChange: (value: any, property: string) => void
  onDelete: () => void
}

const PrivateEquityInput: FormInputField[] = [
  {
    property: "investmentName",
    label: "",
    type: "text",
  },
  {
    property: "investmentSourced",
    label: "",
    type: "checkbox",
    subtype: "boolean",
  },
  {
    property: "investmentLed",
    label: "",
    type: "checkbox",
    subtype: "boolean",
  },
  {
    property: "investmentBoard",
    label: "",
    type: "checkbox",
    subtype: "boolean",
  },
]

const EquityRow = ({
  investment,
  row,
  editMode,
  handleInputChange,
  onDelete,
}: EquityRowProps) => {
  return (
    <tr className={ editMode ? "hover-actions editing unbordered" : "hover-actions unbordered" }>
      {PrivateEquityInput.map(
        (
          {
            property,
            label,
            type,
            subtype,
            placeholder,
            optionSource,
            readonly,
            tooltip,
            subClasses,
          },
          idx
        ) => {
          let options
          let propertyVal: any = _.get(investment, property)
          let onChangeCallback = (value: any) =>
            handleInputChange(value, property)
          return (
            <td key={idx}>
              <FormInput
                key={idx}
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={row}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                readonly={readonly}
                tooltip={tooltip}
                subClasses={subClasses}
                options={options}
              />
            </td>
          )
        }
      )}
      {editMode && (
        <td className="actions">
          <Button color="link" className="btn-thin" onClick={onDelete}>
            <FontAwesomeIcon icon="trash" className="mr-2"/>
          </Button>
        </td>
      )}
    </tr>
  )
}

interface ProductRowProps {
  product: PersonProduct
  row: number
  editMode: boolean
  handleInputChange: (value: any, property: string) => void
  data: any
  managerType?: ManagerType
}

const ProductInputList: PersonInput[] = [
  {
    property: "product.product.name",
    label: "",
    type: "text",
    readonly: true,
  },
  {
    property: "prodTitleNum.code",
    label: "",
    type: "select",
    subtype: "single",
    optionSource: "ProductTitleCode",
    required: true
  },
  {
    property: "yearStartedWithProduct",
    label: "",
    type: "number",
    subtype: "year",
    subClasses: {
      inputClasses: "text-right"
    }
  },
  {
    property: "dateLeftProduct",
    label: "",
    type: "date",
  },
  {
    property: "active",
    label: "",
    type: "select",
    subtype: "single",
    readonly: true
  },
  {
    property: "reasonLeftProduct",
    label: "",
    type: "text",
  },
  {
    property: "investCommMem",
    label: "",
    type: "select",
    subtype: "single",
    displayTypes: ["PE", "RA", "PEFOF"],
  },
  {
    property: "carriedInterest",
    label: "",
    type: "select",
    subtype: "single",
    displayTypes: ["PE", "RA", "PEFOF"],
  },
]

const ProductRow = ({
  product,
  row,
  editMode,
  handleInputChange,
  data,
  managerType,
}: ProductRowProps) => {
  return (
    <tr>
      {ProductInputList.map(
        (
          {
            property,
            label,
            type,
            subtype,
            placeholder,
            optionSource,
            readonly,
            tooltip,
            subClasses,
            displayData,
            displayTypes,
            required,
          },
          idx
        ) => {
          if (
            displayTypes &&
            !_.find(displayTypes, (type: string) => _.get(managerType, type))
          ) {
            return
          }
          if (
            displayData &&
            _.get(product, displayData.property) != displayData.value
          ) {
            return <td key={idx}></td>
          }
          let options
          let propertyVal: any = _.get(product, property)
          let onChangeCallback = (value: any) =>
          handleInputChange(value, property)
          if (property == "active") {
            const invalidDate = _.get(
              product,
              "dateLeftProduct"
            )
            const active = !invalidDate || moment(invalidDate, DATE_API_FORMAT).diff(moment()) > 0
            return(
              <td key={idx}>
                <span className={classnames("ml-auto", "badge", "badge-pill", { "background-olive-10": active, "background-gray-20": !active })}>{active ? "Active" : "Inactive"}</span>
              </td>
            )
          } else if (
            property == "investCommMem" ||
            property == "carriedInterest"
          ) {
            if (propertyVal == null) propertyVal = ""
            propertyVal = propertyVal.toString()
            options = BooleanOptions
            onChangeCallback = (value: any) =>
              handleInputChange(value && value == "true", property)
          }
          return (
            <td key={idx}>
              <FormInput
                key={idx}
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={row}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                readonly={readonly}
                tooltip={tooltip}
                subClasses={subClasses}
                options={options}
                required={required}
              />
            </td>
          )
        }
      )}
    </tr>
  )
}

interface PersonPickerProps {
  people: Person[]
  productId?: number
  search: string
  history: History
  match: match
  type: "product" | "manager"
  selectedPerson?: Person
  initialKeyExecFilter?: boolean
}

interface PersonPickerState {
  pickerFilters: OptionItem[]
  activeFilters: number[]
  pickerSortOptions: OptionItem[]
  activeSort: number
}

export class PersonPicker extends Component<PersonPickerProps> {
  state: PersonPickerState
  constructor(props: PersonPickerProps) {
    super(props)
    let pickerFilters: OptionItem[] = []
    let activeFilters: number[] = []
    if (props.type === "manager") {
      pickerFilters = [
        {
          id: 1,
          name: "Key Executive",
        },
        {
          id: 2,
          name: "Active With Firm",
        },
      ]
      if(props.initialKeyExecFilter){
        activeFilters = [1,2]
      } else {
        activeFilters = [2]
      }
    } else if (props.type === "product") {
      pickerFilters = [
        {
          id: 1,
          name: "Active With Product",
        }
      ]
      activeFilters = [1]
    }
    this.state = {
      pickerFilters,
      activeFilters: activeFilters,
      pickerSortOptions: [
        {
          id: 1,
          name: "Name ( A to Z )",
        },
        {
          id: 2,
          name: "Name ( Z to A )",
        },
      ],
      activeSort: 1,
    }
    if(!this.props.selectedPerson){
      const sortedObjects = this.sortPeople()
      if(sortedObjects.length > 0){
        this.props.history.replace(`${this.props.match.url}/${_.get(_.first(sortedObjects),"id")}`)
      }
    }
  }

  onFilter = (filterId: number) => {
    const { activeFilters } = this.state
    const index = activeFilters.indexOf(filterId)
    if (index === -1) {
      activeFilters.push(filterId)
    } else {
      activeFilters.splice(index, 1)
    }
    this.setState({ activeFilters })
  }

  onSort = (sortId: number) => {
    this.setState({ activeSort: sortId })
  }

  sortPeople = (): Person[] => {
    const { people, productId, search } = this.props
    const { activeFilters, activeSort } = this.state
    return people
      .filter((person: Person) => {
        if(person.showInQuest !== 1){
          return false
        }
        if (search) {
          const name =
            person.firstName?.toLowerCase().trim() +
            " " +
            person.lastName?.toLowerCase().trim()
          if (!name.includes(this.props.search.toLowerCase().trim()))
            return false
        } else if (activeFilters.length > 0 || this.props.type === "product") {
          if (this.props.type === "manager") {
            if (
              activeFilters.indexOf(1) !== -1 &&
              !person.background?.keyPerson
            )
              return false
            if (activeFilters.indexOf(2) !== -1 && !person.active)
              return false
          } else if (this.props.type === "product") {
            const productReference = person.products?.find(
              (product) => product?.product?.product?.id === productId
            )
            if (!productReference) {
              return false
            } else {
              if (activeFilters.indexOf(1) !== -1 && !productReference.active)
                return false
            }
          }
        }
        return true
      })
      .sort((personA: Person, personB: Person) => {
        const nameA =
          personA.firstName?.toLowerCase().trim() +
          " " +
          personA.lastName?.toLowerCase().trim()
        const nameB =
          personB.firstName?.toLowerCase().trim() +
          " " +
          personB.lastName?.toLowerCase().trim()
        switch (activeSort) {
          case 1:
            return nameA.localeCompare(nameB)
          case 2:
            return nameB.localeCompare(nameA)
          default:
            throw new Error(`unrecognized sort choice ${activeSort}`)
        }
      })
  }

  personListItem = (person: Person, productId?: number) => {
    const productReference = productId
      ? person.products?.find(
          (product) => product?.product?.product?.id === productId
        )
      : null
    return (
      <ListGroupItem
        tag="a"
        key={person.id}
        className={classnames({
          active: this.props.selectedPerson?.id === person.id,
        })}
        onClick={() =>
          this.props.history.push(`${this.props.match.path}/${person.id}`)
        }
      >
        <div className="d-flex">
          <h5>
            {person.firstName} {person.lastName}
          </h5>
          <span className={classnames("ml-auto", "badge", "badge-pill", { "background-olive-10": person.active, "background-gray-20": !person.active })}>{person.active ? "Active" : "Inactive"}</span>
        </div>
        <dl>
          {person.employer?.name && (
            <>
              <dt>Manager</dt>
              <dd>{person.employer?.name}</dd>
            </>
          )}
          {person.title && (
            <>
              <dt>Title</dt>
              <dd>{person.title}</dd>
            </>
          )}
          {this.props.type === "product" && (
            <>
              <dt>Status With Product</dt>
              <dd>
                {productReference
                  ? productReference.active
                    ? "Active"
                    : "Inactive"
                  : "No Association"}
              </dd>
            </>
          )}
        </dl>
      </ListGroupItem>
    )
  }

  emptyMessage() {
    return `There are no people associated with this ${this.props.type} with the current filters. Please adjust your filters${this.props.type == "product" ? " or visit the People page for this Manager" : ""}.`
  }

  render() {
    const { productId: selectedId, search } = this.props
    const {
      pickerFilters,
      activeFilters,
      pickerSortOptions,
      activeSort,
    } = this.state
    const sortedPeople = this.sortPeople()
    return (
      <Picker
        filters={pickerFilters}
        activeFilters={activeFilters}
        sortOptions={pickerSortOptions}
        activeSort={activeSort}
        onFilter={this.onFilter}
        onSort={this.onSort}
        searching={search ? true : false}
      >
        {sortedPeople.length > 0 && sortedPeople.map((person) => this.personListItem(person, selectedId))}
        {sortedPeople.length == 0 &&
          <div className="p-3 text-gray-70">
            {this.emptyMessage()}
          </div>
        }
      </Picker>
    )
  }
}

export const BlankPerson: Person = {
  active: true,
  allocationTimes: [],
  background: {
    bio: "",
    employeeContract: false,
    keyPerson: false,
    ownershipAmt: {
      code: null,
      value: null,
      __typename: "OwnershipAmount",
    },
    yearStartedInIndustry: null,
    yearStartedWithFirm: null,
    __typename: "PersonBackground",
  },
  city: null,
  country: {
    code: null,
    value: null,
    __typename: "AddressCountryCodeLookup",
  },
  designation: null,
  email: null,
  employer: {
    id: -9999,
    name: "",
  },
  extension: null,
  fax: null,
  firstName: null,
  id: -9999,
  lastName: null,
  mobile: null,
  phone: null,
  privateEquityInvestments: [],
  products: [],
  reasonLeftFirm: null,
  salutation: null,
  state: {
    code: null,
    value: null,
    __typename: "StateLookup",
  },
  street: "",
  terminatedDate: null,
  title: null,
  zipcode: null,
  __typename: "Person",
}

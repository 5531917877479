import { GridApi, GridReadyEvent } from '@ag-grid-community/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gql } from '@apollo/client'
import { History, Location } from 'history'
import { compact, uniq, uniqBy } from 'lodash'
import React, { useContext, useState } from 'react'
import { match } from 'react-router-dom'
import { Button, Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { EditButtonContext } from '../../Context/EditButtonContext'
import { ListListColumnDef } from "../../helpers/columnDefs"
import { FileFullDetailsFragment, ListFavoriteInput, ListFilters, ListSimpleFragment, useFavoriteListMutation, useListListQuery, useMeDepartmentQuery, useUnfavoriteListMutation } from '../../__generated__/graphql'
import SortableTable from "../Shared/SortableTable"
import { FormInput } from '../ui/Forms/FormInput'
import { WithTopNav } from '../ui/LayoutWrapper'
import PlaceHolder from '../ui/PlaceHolder'
import { AddListModal } from './ListNewModal'
import {CsvExportLimitModal} from '../Shared/CsvExportLimitModal'



type ListListProps = {
  history: History
  location: Location
  auth: Auth
  match: match
}

export type documentTableData = {
  clientId: number
  reportId: number
  associations: string[]
} & FileFullDetailsFragment

const ListList: React.FC<ListListProps> = ({ history, location, auth, match }) => {
  const authAccess = auth.checkPermissions(["edit:lists"])
  if(!authAccess){
    history.replace('/')
  }

  const [addListModalOpen, setAddListModalOpen] = useState(false)
  const [csvExportModalOpen, setCsvExportModalOpen] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  // const addFileInputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState("")
  const { resetErrors } = useContext(EditButtonContext)

  const { loading:userLoading, error:userError, data:userData } = useMeDepartmentQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  const [favoriteList] = useFavoriteListMutation()
  const [unfavoriteList] = useUnfavoriteListMutation()

  let listsFilter: ListFilters = {limit: 5000}
  if(!showAll && user?.person){
    const person = user.person
    listsFilter.owner = [person?.id]
    listsFilter.people = [person?.id]
    listsFilter.orgs = compact(user.appMetadata?.firms)
    listsFilter.departments = person.department?.code ? [parseInt(person.department.code.substring(1))] : []
  }

  const { loading, data, error } = useListListQuery({
    fetchPolicy: "cache-and-network",
    variables: { filters: listsFilter },
    skip: !(userData || showAll)
  })

  const selectList = (id:string) => {
    resetErrors()
    history.push(`/lists/${id}`)
  }

  const openFileModal = () => {
    setAddListModalOpen(!addListModalOpen)
    // addFileInputRef?.current?.click()
  }

  const openCsvExportModal = () => {
    setCsvExportModalOpen(!csvExportModalOpen)
  }

  const exportToCsv =() => {
    let exportedRows = 0;
    console.log("test")
    gridApi?.exportDataAsCsv({
      onlySelected: gridApi?.getSelectedRows().length > 0,
      shouldRowBeSkipped: () => {
        exportedRows++
        if(exportedRows > 1000){
          openCsvExportModal()
        }
        return exportedRows > 1000
      }
    })
  }
  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const heading = (
    <>
      <div className="mb-4">
        <h2 className="headline">Lists</h2>
      </div>
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="pr-2 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Find list by name or ID"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="wide-search"
              onKeyDown={handleEnterKeyDown}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        <div className="border-right">
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={openFileModal} disabled={!data}>
            New List
          </Button>
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
        <FormInput
          property={"showAll"}
          type={"checkbox"}
          subtype={"boolean"}
          label={"Show All Lists"}
          idx={1}
          editMode={true}
          propertyVal={showAll}
          updateValue={(value) => setShowAll(value)}
          subClasses={{wrapperClasses: "no-gutters ml-3 flex-basis-260"}}
        />
        {user &&
          <AddListModal
            user={user}
            modalOpen={addListModalOpen}
            setModalOpen={setAddListModalOpen}
            auth={auth}
          />
        }
        <CsvExportLimitModal
          modalOpen={csvExportModalOpen}
          setModalOpen={setCsvExportModalOpen}
        />
      </div>
    </>
  )

  if ((loading) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data) {
    const toggleFavorite = (row:ListSimpleFragment) => {
      if(!userData?.me?.person?.id || !row.id){
        return
      }
      const favoriteInput:ListFavoriteInput = {
        listIds: [row.id],
        personId: userData.me.person.id,
      }
      const favoriteFunction = row.isCurrentUsersFavorite ? unfavoriteList : favoriteList
      favoriteFunction({
        variables: {input: favoriteInput},
        update: (cache) => {
          cache.writeFragment({
            id: `List:${row.id}`,
            fragment: gql`
              fragment ListWithFavorite on List {
                isCurrentUsersFavorite
              }
            `,
            data: {
              isCurrentUsersFavorite: !row.isCurrentUsersFavorite,
              __typename: "List"
            },
          })
        },
      })
    }
    let colDef = ListListColumnDef(toggleFavorite, user, auth.checkPermissions(["edit:lists"]))
    const onReady = (event:GridReadyEvent) => {
      setGridApi(event.api)
      event.api!.setFilterModel({
        'active': {
          filterType: 'set',
          values: ["Active"]
        }
      })
    }

    const combinedLists = uniqBy([...(data.me?.person?.favoriteLists || []), ...(data.lists || [])], 'id')

    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column">
        {heading}
        <SortableTable
          loading={loading}
          filterText={search}
          columnDefs={colDef}
          tableData={combinedLists}
          rowId={"id"}
          onReady={onReady}
          pageSize={100}
        />
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}



export default WithTopNav(ListList)

import React, { Component } from 'react';
import moment from 'moment'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../helpers/constant'

import {
  ITooltipParams,
} from "@ag-grid-community/core"
import classnames from 'classnames'
import _ from 'lodash'

export default class MessageTooltip extends Component<ITooltipParams, any> {
  getReactContainerClasses() {
    return ['custom-tooltip'];
  }

  render() {
    const value = this.props.value
    if(!value || !value?.length) return <></>
    let message: any = ""
    if(_.isString(value)) {
      message = <span className={"text-light"}>{value}</span>
    }else if(_.isArray(value)){
      message = (<>{value.map((row, key) => {
        if(_.isArray(row)) {
          return (<p key={key} className="">{row.map((el, rowKey) => {
            return (<span className={classnames("text-light", {"underline-dotted pb-1": rowKey === row.length - 1 && key < value.length - 1})} key={rowKey}>{el}<br/></span>)
          })}</p>)
        }else {
          return (<span className={"text-light"} key={key}>{row}<br/></span>)
        }})}</>)
    }
    if(message) {
      return (
        <div
          className={"custom-tooltip with-arrow bg-dark"}
          >
          <p
            className={"wrap"}
          >
            {message}
          </p>
        </div>
      )
    }else {
      return (<></>)
    }
  }
}
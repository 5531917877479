import classnames from "classnames"
import React from "react"
import { Redirect, Switch } from "react-router"
import { useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
  DropdownToggle,
} from "reactstrap"

import { useProductBasicQuery } from "../../__generated__/graphql"
import { appProps } from "../../queries/extended/appProps"
import { ProductRouteProps } from "../../queries/extended/types/Product"
import { CrumbRoute } from "../../routes/crumb-route"
import { WithTopNav } from "../ui/LayoutWrapper"
import LoadingOverlay from "../ui/Loading"
import ProductOverview from "./ProductOverview"
import ProductAssets from "./ProductAssets"
import ProductPeople from "./ProductPeople"
import ProductCharacteristics from "./ProductCharacteristics"
import ProductPicker from "../ui/Pickers/ProductPicker"
import ProductInteractions from "./ProductInteractions"
import ProductWriteUps from "./ProductWriteUps"
import ProductProfile from "./ProductProfile"
import ProductPerformance from "./ProductPerformance/ProductPerformance"
import ProductDocuments from "./ProductDocuments"
import { registerRecentProduct } from "../../helpers/session"
import { ProductVehicles } from "./ProductVehicles"
import ErrorDisplay from "../Shared/ErrorDisplay"
import ProductAnalysis from "./ProductAnalysis"


const QueryProductDetails: React.FC<appProps & ProductRouteProps> = (props) => {
  const { match, auth } = props
  const { productId } = match.params
  const history = useHistory()

  const { loading, error, data } = useProductBasicQuery({
    variables: { id: parseInt(productId) },
  })

  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <ErrorDisplay error={error}/>
    )
  }
  if (!data || data.product === null || data.product?.product == null) {
    return <p>Product Data not found</p>
  }
  const { type, subtype } = match.params
  const urlWithType = subtype
    ? match.url.slice(0, -subtype.length - 1)
    : match.url
  const urlWithoutType = type
    ? urlWithType.slice(0, -type.length - 1)
    : urlWithType

  registerRecentProduct(
    data.product.product.id,
    data.product.product.name,
    data.product.product?.manager?.name || "",
    data.product.product?.assetClass?.parent?.id || 0,
  )

  const parentAssetClass = data.product.product.assetClass?.parent?.id

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <Col xs="10" sm="8" md="6">
                <Breadcrumb>
                  <BreadcrumbItem
                    className="headline-breadcrumbs"
                    key="test"
                    onClick={() =>
                      history.push(
                        `/managers/${data.product?.product?.manager?.id}`
                      )
                    }
                  >
                    {data.product?.product?.manager?.name}
                  </BreadcrumbItem>
                </Breadcrumb>
                {data.product?.product?.manager && (
                  <ProductPicker
                    managerId={data.product.product.manager.id}
                    title={`View another product by ${data.product.product
                      .manager.name || ""}`}
                    onClick={(productId) => {
                      window.location.href = `/products/${productId}`
                    }}
                  >
                  <div className='dropdown'>
                    <DropdownToggle caret>
                      <h2 className="headline">
                        {data.product.product.name || ""}
                      </h2>
                    </DropdownToggle>
                    {data.product.product.inactive && (
                      <span className="inactive-tag">Inactive</span>
                    )}
                  </div>
                  </ProductPicker>
                )}
              </Col>
            </Row>
            <Nav
              className="sub-nav sub-nav-primary"
              tabs
              role="group"
              aria-label="Product Subpage"
            >
              {/* temp fix for CAL-1855*/}
              {auth.checkPermissions(["view:interactions"]) &&
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      match.url.indexOf(`${urlWithoutType}/profile`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/profile`)}
                >
                  Profile
                </NavLink>
              </NavItem>
              }
              {data.product.product.layout && auth.checkPermissions(["view:product_analysis"]) &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutType}/analysis`) === 0
                    })}
                    onClick={() => history.push(`${urlWithoutType}/analysis`)}
                  >
                    Analysis
                  </NavLink>
                </NavItem>
              }
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active:
                      match.url.indexOf(`${urlWithoutType}/overview`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/overview`)}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active:
                      match.url.indexOf(`${urlWithoutType}/vehicles`) === 0 || 
                      match.url.indexOf(`${urlWithoutType}/portfolios`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/vehicles`)}
                >
                  Vehicles
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active: match.url.indexOf(`${urlWithoutType}/people`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/people`)}
                >
                  People
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active: match.url.indexOf(`${urlWithoutType}/assets`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/assets`)}
                >
                  Assets
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active:
                      match.url.indexOf(`${urlWithoutType}/performance`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/performance`)}
                >
                  Performance
                </NavLink>
              </NavItem>
              {parentAssetClass !== 76 &&
                <NavItem>
                  <NavLink
                    className={classnames("", {
                      active:
                        match.url.indexOf(`${urlWithoutType}/characteristics`) ===
                        0,
                    })}
                    onClick={() =>
                      history.push(`${urlWithoutType}/characteristics`)
                    }
                  >
                    Characteristics
                  </NavLink>
                </NavItem>
              }
              {auth.checkPermissions(["view:interactions"]) &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        match.url.indexOf(`${urlWithoutType}/interactions`) === 0,
                    })}
                    onClick={() => {
                      console.log("nav interactions")
                      history.push(`${urlWithoutType}/interactions`)
                    }}
                  >
                    Interactions
                  </NavLink>
                </NavItem>
              }
              {auth.checkPermissions(["edit:writeups"]) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        match.url.indexOf(`${urlWithoutType}/write-ups`) === 0,
                    })}
                    onClick={() => history.push(`${urlWithoutType}/write-ups`)}
                  >
                    Write-ups
                  </NavLink>
                </NavItem>
              )}
              {(auth.checkPermissions(["view:documents"]) || auth.checkPermissions(["view:my_documents"]) || auth.checkPermissions(["view:manager_documents"]) || auth.checkPermissions(["view:all_documents"])) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        match.url.indexOf(`${urlWithoutType}/documents`) === 0,
                    })}
                    onClick={() => history.push(`${urlWithoutType}/documents`)}
                  >
                    Documents
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        {auth.checkPermissions(["view:interactions"]) &&
        <CrumbRoute
          title="profile"
          path={`${urlWithoutType}/profile`}
          render={() => <ProductProfile {...props} id={parseInt(productId)} />}
        />
        }
        {data.product.product.layout && auth.checkPermissions(["view:product_analysis"]) &&
          <CrumbRoute
            title="analysis"
            path={`${urlWithoutType}/analysis`}
            render={(props) => <ProductAnalysis productId={parseInt(productId)} auth={auth} {...props} />}
          />
        }
        <CrumbRoute
          title="vehicles"
          path={`${urlWithoutType}/:type(vehicles|portfolios)`}
          render={() => <ProductVehicles productId={parseInt(productId)} auth={auth} />}
        />
        <CrumbRoute
          title="people"
          path={`${urlWithoutType}/people`}
          render={() => <ProductPeople id={parseInt(productId)} auth={auth} />}
        />
        <CrumbRoute
          title="assets"
          path={`${urlWithoutType}/assets/:subtype(client_type|location|vehicle|accounts)?`}
          render={() => (
            <ProductAssets data={data} {...props} id={parseInt(productId)} />
          )}
        />
        <CrumbRoute
          title="performance"
          path={`${urlWithoutType}/:type(performance)/:subtype(vehicles|portfolios)?`}
          render={() => (
            <ProductPerformance
              data={data}
              {...props}
              id={parseInt(productId)}
            />
          )}
        />
        {parentAssetClass !== 76 &&
          <CrumbRoute
            title="characteristics"
            path={`${urlWithoutType}/characteristics/:subtype(vehicles|portfolios)?`}
            render={() => (
              <ProductCharacteristics
                data={data}
                {...props}
                id={parseInt(productId)}
              />
            )}
          />
        }
        {auth.checkPermissions(["view:interactions"]) &&
          <CrumbRoute
            title="interactions"
            path={`${urlWithoutType}/interactions/:id([A-Za-z0-9]+)?`}
            render={() => (
              <ProductInteractions
                managerId={data?.product?.product?.manager?.id || 0}
                productId={parseInt(productId)}
                history={history}
                location={props.location}
                auth={props.auth}
              />
            )}
          />
        }
        {auth.checkPermissions(["view:writeups"]) && (
          <CrumbRoute
            title="write-ups"
            path={`${urlWithoutType}/write-ups/:subtype(overall|people|philosophy|portfolio|long|short)?`}
            render={() => (
              <ProductWriteUps
                id={data.product?.product?.id || 0}
                auth={auth}
              />
            )}
          />
        )}
        {(auth.checkPermissions(["view:documents"]) || auth.checkPermissions(["view:my_documents"]) || auth.checkPermissions(["view:manager_documents"]) || auth.checkPermissions(["view:all_documents"])) && (
          <CrumbRoute
            title="documents"
            path={`${urlWithoutType}/documents`}
            render={() => (
              <ProductDocuments
                productId={parseInt(productId)}
                auth={auth}
                history={history}
                location={props.location}
                managerId={data.product?.product?.manager?.id}
              />
            )}
          />
        )}
        <CrumbRoute
          title="overview"
          path={`${urlWithoutType}/overview/:subtype(summary|guidelines|management|employees|gpcommitments|process|esg|outlook)?`}
          render={() => (
            <ProductOverview data={data} {...props} id={parseInt(productId)} />
          )}
        />
        <Redirect to={`${urlWithoutType}/overview/summary`} />
      </Switch>
    </div>
  )
}
export default WithTopNav(QueryProductDetails)

import React, { Component } from 'react';
import moment from 'moment'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../helpers/constant'

import {
  ITooltipParams,
} from "@ag-grid-community/core"

export default class AumTooltip extends Component<ITooltipParams, any> {
  getReactContainerClasses() {
    return ['custom-tooltip'];
  }

  render() {
    const date = moment(this.props.value, DATE_API_FORMAT)
    return (
      <div
        className="custom-tooltip with-arrow"
      >
        <p>
          <span>As of {date.format(DATE_DISPLAY_FORMAT)}</span>
        </p>
      </div>
    );
  }
}
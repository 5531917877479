import classnames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Nav, NavItem, NavLink, Container, Row, Col } from 'reactstrap'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom'

import Auth from '../../Auth/Auth'
import { ManagerDetailProps } from '../../queries/extended/types/Managers'
import { ManagerDetails_overview } from '../../queries/types/ManagerDetails'
import QueryManagerAssetsClientType from './ManagerAssetsClientType'
import ManagerAssetsLocation from './ManagerAssetsLocation'
import ManagerAssetsClosedEnd from './ManagerAssetsClosedEnd'
import { ManagerType } from "../../helpers/helpers"
import ManagerAssetsAccounts from './ManagerAssetsAccounts'
import ManagerAssetsVehicles from "./ManagerAssetsVehicles"

interface ManagerOverviewProps {
  data: ManagerDetails_overview
  managerType?: ManagerType
  auth: Auth
}

const QueryManagerAssets: React.FC<ManagerOverviewProps &
  ManagerDetailProps> = props => {
  const id = props.data.id
  const managerType = props.managerType
  const params = useParams() as { subtype?: string }
  const history = useHistory()
  const match = props.match
  const auth = props.auth
  const subtype: string = _.get(props, "match.params.subtype", "")
  let urlWithoutSubtype =
      params && subtype
        ? match.url.slice(0, -subtype.length - 1)
        : match.url
  const handleRedirect = (tab: string) => {
    history.push(urlWithoutSubtype + "/" + tab)
  }
  return (
    <>
    <Container fluid>
        <Row>
          <Col>
            <Nav className="sub-nav sub-nav-secondary" tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutSubtype}/clientType`) === 0
                  })}
                  onClick={() => handleRedirect("clientType")}
                >
                  Client Type
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutSubtype}/location`) === 0
                  })}
                  onClick={() => handleRedirect("location")}
                >
                  Location
                </NavLink>
              </NavItem>
              {(managerType?.LGSH || managerType?.PE || managerType?.RA || managerType?.PEFOF) &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutSubtype}/assetClass`) === 0
                    })}
                    onClick={() => handleRedirect("assetClass")}
                  >
                    Asset Class
                  </NavLink>
                </NavItem>
              }
              {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutSubtype}/accounts`) === 0
                    })}
                    onClick={() => handleRedirect("accounts")}
                  >
                    Accounts
                  </NavLink>
                </NavItem>
              }
              {(managerType?.PE || managerType?.PEFOF || managerType?.RA) &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutSubtype}/closedEnd`) === 0
                    })}
                    onClick={() => handleRedirect("closedEnd")}
                  >
                    Closed-End Fundraising
                  </NavLink>
                </NavItem>
              }
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        <Route
          exact
          path="/managers/:managerId(\d+)/assets/:subtype(clientType)"
          component={() => <QueryManagerAssetsClientType id={props.data.id} managerType={managerType} auth={auth}/>}
        />
        <Route
          exact
          path="/managers/:managerId(\d+)/assets/:subtype(location)"
        component={() => <ManagerAssetsLocation id={props.data.id} auth={auth}/>}
        />
        {(managerType?.LGSH || managerType?.PE || managerType?.RA || managerType?.PEFOF) &&
          <Route
            exact
            path="/managers/:managerId(\d+)/assets/:subtype(assetClass)"
            component={() => <ManagerAssetsVehicles id={props.data.id} managerType={managerType} auth={auth}/>}
          />
        }
        {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
          <Route
            exact
            path="/managers/:managerId(\d+)/assets/:subtype(accounts)"
            component={() => <ManagerAssetsAccounts id={props.data.id} auth={auth}/>}
          />
        }
        {(managerType?.PE || managerType?.PEFOF || managerType?.RA) &&
          <Route
            exact
            path="/managers/:managerId(\d+)/assets/:subtype(closedEnd)"
            component={() => <ManagerAssetsClosedEnd id={props.data.id} auth={auth}/>}
          />
        }
        <Redirect
          from="/managers/:managerId(\d+)/assets"
          to="/managers/:managerId(\d+)/assets/clientType"
        />
      </Switch>
    </>
  )
}

export default QueryManagerAssets

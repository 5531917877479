import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'

import { Maybe } from '../../__generated__/graphql'
import { FormInputSubClasses, PerformanceItemUnion, DATE_API_FORMAT } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import { IconName } from '@fortawesome/fontawesome-svg-core'

type columnDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  optionSource?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  tdClass?: string
}

interface PerformanceTableRowProps {
  data: any
  columnDef: columnDefItem[]
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

type PerformanceTableProps = {
  data: Maybe<PerformanceItemUnion>[]
  handleChange: (state: any) => void
  editMode: boolean
}

const PerformanceColumnDef: columnDefItem[] = [
  {
    field: "quarterEndDate",
    type: "date",
    subtype: "quarter",
    title: "",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    tdClass: "medium"
  },
  {
    field: "compositeAccounts",
    type: "number",
    title: "",
    tdClass: "shrink"
  },
  {
    field: "minReturn",
    type: "float",
    title: "",
    tdClass: "shrink"
  },
  {
    field: "maxReturn",
    type: "float",
    title: "",
    tdClass: "shrink"
  },
  {
    field: "weightedStandardDeviation",
    type: "float",
    title: "",
    tdClass: "shrink"
  },
  {
    field: "accountsExplanation",
    type: "text",
    title: "",
    tdClass: "wide"
  }
]

const PerformanceTableRow = ({
  data,
  columnDef,
  idx,
  editMode,
  updateValue,
  action
}: PerformanceTableRowProps) => {
  return (
    <tr className={editMode ? "hover-actions editing" : "hover-actions"} key={`performance-row-${idx}`}>
      {columnDef.map((header, index) => {
        return (
          <td key={`${idx}-${index}`} className={header.tdClass || ""}>
            <FormInput
              key={header.field}
              property={header.field}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={`${header.field}-${idx}-${index}`}
              editMode={editMode}
              propertyVal={data[header.field]}
              placeholder={""}
              updateValue={value =>
                updateValue(value, header.type, header.field)
              }
              subClasses={header.subClasses}
              required={true}
              // CAL-2016 add a year check for four digit type.
              // need to be consistent with year check in getInputs method
              inputProps={{
                min: moment("1000-01-01").format(DATE_API_FORMAT)
              }}
            />
          </td>
        )
      })}
      {editMode && (
        <td className="actions shrink">
          <Button
            color="link"
            size="sm"
            className="ml-auto btn-thin"
            onClick={() => action.remove(idx)}
          >
            <FontAwesomeIcon icon="trash" className="ml-2 text-blue-100" />
          </Button>
        </td>
      )}
    </tr>
  )
}

const PerformanceRowDefault = {
  quarterEndDate: "",
  compositeAccounts: 0.0,
  accountsExplanation: "",
  minReturn: 0.0,
  maxReturn: 0.0,
  weightedStandardDeviation: 0.0
}

const getInitialData = (data: any) => {
  return _.orderBy(data, [i => moment(i?.quarterEndDate, DATE_API_FORMAT).valueOf()], ['desc'])
}

const PerformanceTableDisplay = (seed: PerformanceTableProps) => {
  const tableName = "Performance-Table"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(() => getInitialData(data))

  const TABLE_INCREMENT = 10
  const [tableLength, setTableLength] = useState(TABLE_INCREMENT)
  const showTableIncrement: boolean = tableLength < tableState.length

  useEffect(() => {
    // fix rows jumping around after date input
    if(!editMode) {
      const newSortedData = getInitialData(data)
      setData(newSortedData)
    }
  }, [editMode])

  const onAddPerformanceRow = (row: PerformanceItemUnion) => {
    let newData = [row, ...tableState]
    setData(newData)
    handleChange(newData)
  }

  const onRemovePerformanceRow = (rowIndex: number) => {
    let newData = [
      ...tableState.slice(0, rowIndex),
      ...tableState.slice(rowIndex + 1)
    ]
    setData(newData)
    handleChange(newData)
  }

  const actionMap = {
    add: onAddPerformanceRow,
    remove: onRemovePerformanceRow
  }

  const onHandleChangePerformanceRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = tableState.map((el, index) => {
      if (idx === index) {
        return { ...tableState[index], [property]: value }
      } else {
        return el
      }
    })
    setData(newData)
    handleChange(newData)
  }

  return (
    <div className={`${tableName} row form-group pl-0`}>
      <Table
        striped
        borderless
        responsive
        hover
        size="sm"
        key={`Performance-table`}
        className={"exportable"}
        data-export-name={"Performance Composite Dispersion"}
      >
        <thead className="table-border-bottom">
          <tr className="table-header">
            <th key={`${tableName}-header-0`}>Quarter</th>
            <th key={`${tableName}-header-1`}>
              Quarter End # of Accounts in Composite
            </th>
            <th id="trailingMinReturnTooltipContainer" key={`${tableName}-header-2`}>
              <div className="d-inline-flex align-items-center tooltip-icon" id="trailingMinReturnTooltip">
                Trailing 1 yr Min Return
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  size="sm"
                />
              </div>
            </th>
            <th id="trailingMaxReturnTooltipContainer" key={`${tableName}-header-3`}>
              <div className="d-inline-flex align-items-center tooltip-icon" id="trailingMaxReturnTooltip">
                Trailing 1 yr Max Return
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  size="sm"
                />
              </div>
            </th>
            <th id="trailingWtdStandardTooltipContainer" key={`${tableName}-header-4`}>
              <div className="d-inline-flex align-items-center tooltip-icon" id="trailingWtdStandardTooltip">
                Trailing 1 yr Wtd Standard Dev
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  size="sm"
                />
              </div>
            </th>
            <th key={`${tableName}-header-5`}>
              Different # of Accounts in the Comp Explanation
            </th>
            {editMode && (
              <th key={`Performance-header-2`}>
                <Button
                  color="link"
                  size="sm"
                  className="ml-auto btn-thin"
                  onClick={() => onAddPerformanceRow(PerformanceRowDefault)}
                >
                  Add Row
                  <FontAwesomeIcon
                    icon={"plus-circle"}
                    size="xs"
                    className="ml-2 text-blue-100"
                  />
                </Button>
              </th>
            )}
          </tr>
        </thead>
        <tbody key={0}>
          {tableState.slice(0, tableLength).map((row: any, idx: number) => {
            return (
              <PerformanceTableRow
                data={row}
                columnDef={PerformanceColumnDef}
                key={`${row.quarterEndDate}-${idx}`}
                idx={idx}
                editMode={editMode}
                action={actionMap}
                updateValue={(value, type, property) =>
                  onHandleChangePerformanceRow(idx, value, type, property)
                }
              />
            )
          })}
          {showTableIncrement &&
            <Button
              color="secondary"
              className="ml-2 mt-2 btn-load-more"
              onClick={() => setTableLength(tableLength + TABLE_INCREMENT)}
            >
              {"Load More"}
            </Button>
          }
          <tr
            key={`${tableName}-${tableState.length}`}
            className={`border-top pl-0`}
          ></tr>
        </tbody>
      </Table>
    </div>
  )
}

export default PerformanceTableDisplay

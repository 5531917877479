import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import { FormInputSubClasses } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { FormInput } from "../../ui/Forms/FormInput"
import { DealLimitationsTableRow } from "./DealLimitations"

// constants setting here
const EXPLANATION_CHARACTER_LIMIT = 200

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  tdClass?: string
  optionSource?: string
  charactersLimit?: number
  placeholder?: string
  tooltip?: { icon: string; id: string }
}

type LimitationsTableProps = {
  data: any
  handleChange: (state: any, property: string) => void
  editMode?: boolean
  dealPoliciesData: any
  handleDealPoliciesChange: (state: any, property: string) => void
}

const LimitationsTableHeaderDef: RowDefItem[] = [
  {
    field: "lower",
    type: "float",
    title: "Lower",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "higher",
    type: "float",
    title: "Higher",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "explanation",
    type: "text",
    title: "Explanation",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1 pt-3 mt-2"
    },
    tdClass: "w-50",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
  }
]

const LimitationsTableUpperRowDef: RowDefItem[] = [
  {
    field: "dealSize",
    type: "float",
    subtype: "currency",
    title: "Deal Size ($)",
    tooltip: {
      icon: "question-circle",
      id: "dealSizeTooltip"
    }
  },
  {
    field: "numberOfInvestments",
    type: "float",
    title: "Number of Investments",
  },
  {
    field: "developmentLimits",
    type: "float",
    title: "Development Limits",
  },
  {
    field: "singleAssetLimits",
    type: "float",
    title: "Single Asset Limits",
  }
]

export const LimitationsTableLowerRowDef: RowDefItem[] = [
  {
    field: "cash",
    type: "text",
    title: "Cash",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Cash"
  },
  {
    field: "geography",
    type: "text",
    title: "Geography",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Geography"
  },
  {
    field: "propertyType",
    type: "text",
    title: "Property Type",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Property Type"
  },
  {
    field: "jointVenture",
    type: "text",
    title: "Joint Venture",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Joint Venture"
  },
  {
    field: "nonCoreAllocation",
    type: "text",
    title: "Non-Core Allocation",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Non-Core Allocation"
  },
  {
    field: "other",
    type: "text",
    title: "Other",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Other"
  }
]

interface LimitationsTableRowProps {
  data: any
  index: number
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

const LimitationsTableRow = ({
  data,
  index,
  rowDef,
  editMode,
  updateValue
}: LimitationsTableRowProps) => {
  let { field, title, subClasses, type, placeholder, charactersLimit } = rowDef
  const value = _.get(data, field)
  if (_.isUndefined(value)) {
    return <tr key={`${index}`} className={"invisible"}></tr>
  }
  return (
    <tr key={`${index}`}>
      <td key={`Management-${field}-0`} className={"text-left"}>
        {title}
      </td>
      <td key={`Management-${field}-1`}/>
      <td key={`Management-${field}-2`}/>
      <td key={`Management-${field}-3`}>
        <FormInput
          property={field + "l"}
          displayName={""}
          type={type}
          idx={index}
          editMode={editMode}
          propertyVal={value}
          placeholder={placeholder}
          subClasses={subClasses}
          updateValue={value => updateValue(index, value, type, field)}
          charactersLimit={charactersLimit}
        />
      </td>
    </tr>
  )
}

const LimitationsTable = (seed: LimitationsTableProps) => {
  const tableName = "LimitationsTable"
  const widthClassName = "col-sm-9"
  const { data, editMode, handleChange, dealPoliciesData, handleDealPoliciesChange } = seed
  const [dealPoliciesTableState, setDealPoliciesData] = useState(dealPoliciesData)
  const [tableState, setData] = useState(data)
  useEffect(() => {
    setDealPoliciesData(seed.dealPoliciesData)
    setData(seed.data)
  }, [seed.data, seed.dealPoliciesData])

  if (!data) {
    return <div>No data</div>
  }

  const onHandleChangeLimitationRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData =
      getNewStateObject({
        state: tableState,
        newValue: value,
        property: `${property}`,
        type
      }) || {}
    handleChange(_.get(newData, property), property)
    setData(newData)
  }

  const onHandleChangeDealLimitationRoleRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData =
      getNewStateObject({
        state: dealPoliciesTableState,
        newValue: value,
        property: `${property}.${LimitationsTableHeaderDef[idx].field}`,
        type
      }) || {}
    setDealPoliciesData(newData)
    handleDealPoliciesChange(_.get(newData, property), property)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size w-100 py-2 mb-2"
        }
        key={`${tableName}-0`}
      >
        {`Limitations`}
      </div>
      <div
        className={`${tableName}-table ${widthClassName} row form-group pl-3`}
      >
        <Table striped borderless hover size="sm" key={`Limitations-table`}>
          <thead>
            <tr className="table-header">
              <th key={0}>Target At Entry</th>
              {LimitationsTableHeaderDef.map((header, headerIdx) => (
                <th key={headerIdx + 1}>{header.title}</th>
              ))}
            </tr>
          </thead>
          <tbody key={0}>
            {LimitationsTableUpperRowDef.map((rowDef, rowIndex) => {
              return (
                <DealLimitationsTableRow
                  index={rowIndex}
                  key={`deal-limitation-${rowIndex}`}
                  data={dealPoliciesTableState}
                  rowDef={rowDef}
                  editMode={!!editMode}
                  updateValue={onHandleChangeDealLimitationRoleRow}
                  colDef={LimitationsTableHeaderDef}
                />
              )
            })}
            {LimitationsTableLowerRowDef.map((rowDef, rowIndex) => {
              return (
                <LimitationsTableRow
                  index={rowIndex}
                  key={`limitation-${rowIndex}`}
                  data={tableState}
                  rowDef={rowDef}
                  editMode={!!editMode}
                  updateValue={onHandleChangeLimitationRow}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default LimitationsTable

import React, { Component, RefObject } from 'react';
import { Query } from '@apollo/client/react/components'
import classnames from 'classnames';
import iassign from 'immutable-assign';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import numbro from 'numbro';
import { Col, Table, UncontrolledAlert } from 'reactstrap';

import { appDate } from '../../Context/CalendarContext';
import { DATE_API_FORMAT } from '../../helpers/constant';
import { listQuarters } from '../../helpers/helpers';
import { CLIENT_PORTFOLIO_CHARACTERISTICS_QUERY } from '../../queries/Portfolio';
import { VEHICLE_CHARACTERISTICS_QUERY } from '../../queries/Vehicles';
import {
  ClientPortfolio,
  ClientPortfolioCharacteristicsQuery,
  VehicleCharacteristicsQuery,
  VehicleFields,
} from '../../__generated__/graphql';
import { FormInput } from '../ui/Forms/FormInput';
import PlaceHolder from './../ui/PlaceHolder';
import ProductCharacteristicsHoldings from './ProductCharacteristicsHoldings';
import {
  qualityStringMapping,
  qualityStringToNumericMapping,
  tableLengths,
  VehicleCharacteristicsField,
  VehicleCharacteristicsTable,
} from './ProductCharacteristicsVehiclesFields';
import TabMenu from '../ui/TabMenu';
import ErrorDisplay from '../Shared/ErrorDisplay';
import { DEFAULT_CHARACTERISTICS_FILTER } from './ProductCharacteristicsVehicles'

interface IdVehicleFields extends Omit<VehicleFields, 'fundid'> {
  id: string;
}

const firstHistoricalDate = moment(appDate).subtract(3, 'years');
interface IdVehicle {
  vehicle: IdVehicleFields;
}

interface QueryVehicleProps {
  vehicle: IdVehicle;
  editMode: boolean;
  tableDefinitions: VehicleCharacteristicsTable[];
  searchDate: string;
  historicalDate?: string;
  detailRef: RefObject<ProductCharacteristicsVehiclesDetail>;
  setShowEditMode: (value: boolean) => void;
  setHistoricalDate: (value: Moment) => void;
  tab: string
  setTab: (value: string) => void
}

interface Totals {
  value: number;
  ctd: number;
  ctdts: number;
  rctdts: number;
}
const historicalStartDate = appDate.format(DATE_API_FORMAT);

interface QueryVehicleState {
  loadingMore: boolean;
  historicalDate: string;
}

class QueryProductCharacteristicsDetails extends Component<QueryVehicleProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      loadingMore: false,
      historicalDate: props.historicalDate,
    };
  }

  state: QueryVehicleState;

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate).format(DATE_API_FORMAT) });
  };

  render() {
    const id = this.props.vehicle.vehicle.id;
    const { editMode, historicalDate, tableDefinitions, searchDate, detailRef, setShowEditMode } = this.props;
    let firstDate = !historicalDate ? searchDate : historicalStartDate
    let secondDate = !historicalDate ? searchDate : historicalDate
    let startDate = firstDate
    let endDate = secondDate
    if(moment(firstDate).isAfter(secondDate)) {
      startDate = secondDate
      endDate = firstDate
    }
    return (
      <Query<VehicleCharacteristicsQuery>
        query={VEHICLE_CHARACTERISTICS_QUERY}
        variables={{
          id,
          // startDate has to be earlier than endDate.
          startDate,
          endDate,
          filters: {
            startDate,
            endDate,
            ...DEFAULT_CHARACTERISTICS_FILTER
          },
        }}
        fetchPolicy='network-only'
        notifyOnNetworkStatusChange={true}
      >
        {(results) => {
          if (results.loading && !this.state.loadingMore) {
            return (
              <Col md='8' lg='9' className='pl-1'>
                <div className='pane'>
                  <PlaceHolder />
                </div>
              </Col>
            );
          }

          if (results.error) {
            console.log({error: results.error})
            return (
              <Col md='8' lg='9' className='pl-1'>
                <UncontrolledAlert color='danger'>
                  <h4>Error Fetching VEHICLE_CHARACTERISTICS</h4>
                  <p>{results.error.message}</p>
                </UncontrolledAlert>
              </Col>
            );
          }
          if (!results.data || !results.data.vehicle) {
            return <>No Vehicle</>;
          }
          const loadMore = () => {
            if (this.state.loadingMore || !historicalDate) {
              return;
            }
            let historicalTracker: string;
            if (!this.state.historicalDate) {
              historicalTracker = this.props.historicalDate as string;
            } else {
              historicalTracker = this.state.historicalDate;
            }
            const historicalMoment = moment(historicalTracker);
            this.setState({ loadingMore: true });
            let endDate = historicalMoment.format(DATE_API_FORMAT)
            let startDate = moment(historicalTracker).subtract(3, 'years').format(DATE_API_FORMAT)
            results.fetchMore({
              variables: {
                id: id,
                endDate,
                startDate,
                filters: {
                  startDate,
                  endDate,
                  ...DEFAULT_CHARACTERISTICS_FILTER
                }
              },
              updateQuery: (previousResult: VehicleCharacteristicsQuery, { fetchMoreResult }: any) => {
                if (!fetchMoreResult) {
                  return previousResult;
                }
                const previousVehicles = previousResult.vehicle;
                let currentVehicle = _.cloneDeep(detailRef!.current?.state?.currentState);
                let initialVehicle = _.cloneDeep(detailRef!.current?.state?.initialState);
                let newVehicles = fetchMoreResult.vehicle.vehicle;
                if (!previousVehicles || !newVehicles) {
                  return previousResult;
                }
                if (currentVehicle) {
                  Object.keys(currentVehicle?.characteristics || {}).reduce((result: any, characteristic: string) => {
                    if(characteristic === "__typename") return result
                    const newChar = _.get(newVehicles, ["characteristics"], [])
                    // If matching the current vehicle add it to the current state
                    if (currentVehicle?.characteristics)
                      _.set(currentVehicle, ["characteristics"], [
                        ...newChar,
                        ...(currentVehicle.characteristics || [])
                      ]);
                    if (initialVehicle?.characteristics)
                      _.set(initialVehicle, ["characteristics"], [
                        ...newChar,
                        ...(initialVehicle.characteristics || [])
                      ]);
                    return result;
                  }, {});
                  Object.keys(currentVehicle?.weightedExposures || {}).reduce((result: any, property: string) => {
                    if(property === "__typename") return result
                    // TODO: need fixing.
                    const newChar = _.get(newVehicles, ["weightedExposures", property], [])
                    // If matching the current vehicle add it to the current state
                    if (currentVehicle?.weightedExposures) {
                      if(!_.get(currentVehicle, ["weightedExposures", property])) {
                        if(newChar) {
                          _.set(currentVehicle, ["weightedExposures", property], newChar)
                        }
                      }else {
                        if(newChar) {
                          _.set(currentVehicle, ["weightedExposures", property], [
                            ...newChar,
                            ...(_.get(currentVehicle, ["weightedExposures", property], [])),
                          ]);
                        }
                      }
                    }
                    if(initialVehicle && !initialVehicle?.weightedExposures) {
                      if(newChar) {
                        _.set(initialVehicle, ["weightedExposures", property], newChar)
                      }
                    }
                    if (initialVehicle?.weightedExposures) {
                      if(!_.get(initialVehicle, ["weightedExposures", property])) {
                        if(newChar) {
                          _.set(initialVehicle, ["weightedExposures", property], newChar)
                        }
                      }else {
                        if(newChar) {
                          _.set(initialVehicle, ["weightedExposures", property], [
                            ...newChar,
                            ...(_.get(initialVehicle, ["weightedExposures", property], [])),
                          ]);
                        }
                      }
                    }
                    return result;
                  }, {});
                }
                detailRef!.current?.setState({ currentState: currentVehicle, initialState: initialVehicle }, () => {
                  // TODO: needs further investigation.
                  setTimeout(() => {
                    this.setState({
                      loadingMore: false,
                      historicalDate: moment(historicalTracker).subtract(3, 'years').format(DATE_API_FORMAT),
                    },);
                  }, 2000)
                });
                
                return previousResult
              },
            });
          };
          return (
            <ProductCharacteristicsVehiclesDetail
              ref={detailRef}
              vehicle={results.data.vehicle.vehicle as IdVehicleFields}
              editMode={editMode}
              tableDefinitions={tableDefinitions}
              searchDate={searchDate}
              historicalDate={!!historicalDate ? this.state.historicalDate || historicalDate : undefined}
              setShowEditMode={setShowEditMode}
              tab={this.props.tab}
              setTab={(value: string) => this.props.setTab( value )}
              loadMore={loadMore}
              loadingMore={this.state.loadingMore}
            />
          );
        }}
      </Query>
    );
  }
}

interface QueryClientProps {
  portfolio: ClientPortfolio;
  editMode: boolean;
  tableDefinitions: VehicleCharacteristicsTable[];
  searchDate: string;
  historicalDate?: string;
  detailRef: RefObject<ProductCharacteristicsVehiclesDetail>;
  setShowEditMode: (value: boolean) => void;
  setHistoricalDate: (value: Moment) => void;
  tab: string
  setTab: (value: string) => void
}

export class QueryProductCharacteristicsClientDetails extends Component<QueryClientProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      loadingMore: false,
      historicalDate: props.historicalDate,
    };
  }

  state: QueryVehicleState;

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) });
  };

  render() {
    const id = this.props.portfolio.id;
    const { editMode, tableDefinitions, searchDate, historicalDate, detailRef, setShowEditMode } = this.props;
    let firstDate = !historicalDate ? searchDate : historicalStartDate
    let secondDate = !historicalDate ? searchDate : historicalDate
    let startDate = firstDate
    let endDate = secondDate
    if(moment(firstDate).isAfter(secondDate)) {
      startDate = secondDate
      endDate = firstDate
    }
    return (
      <Query<ClientPortfolioCharacteristicsQuery>
        query={CLIENT_PORTFOLIO_CHARACTERISTICS_QUERY}
        variables={{
          id,
          startDate,
          endDate,
          filters: {
            startDate,
            endDate,
            ...DEFAULT_CHARACTERISTICS_FILTER,
          }
        }}
        fetchPolicy='network-only'
        notifyOnNetworkStatusChange={true}
      >
        {(results) => {
          if (results.loading && !this.state.loadingMore) {
            return (
              <Col md='8' lg='9' className='pl-1'>
                <div className='pane'>
                  <PlaceHolder />
                </div>
              </Col>
            );
          }

          if (results.error) {
            var error=results.error
            return (
              <Col md='8' lg='9' className='pl-1'>
                <UncontrolledAlert color='danger'>
                  <h4>Error Fetching CLIENT_PORTFOLIO_CHARACTERISTICS</h4>
                  <ErrorDisplay error={error}/>
                </UncontrolledAlert>
              </Col>
            );
          }
          if (!results.data || !results.data.portfolio) {
            return <>No Portfolio</>;
          }
          const loadMore = () => {
            if (this.state.loadingMore || !historicalDate) {
              return;
            }
            let historicalTracker;
            if (!this.state.historicalDate) {
              historicalTracker = this.props.historicalDate;
            } else {
              historicalTracker = this.state.historicalDate;
            }
            const historicalMoment = moment(historicalTracker);
            this.setState({ loadingMore: true });
            let endDate = historicalMoment.format(DATE_API_FORMAT)
            let startDate = moment(historicalTracker).subtract(3, 'years').format(DATE_API_FORMAT)
            results.fetchMore({
              variables: {
                id: id,
                startDate,
                endDate,
                filters: {
                  startDate,
                  endDate,
                  ...DEFAULT_CHARACTERISTICS_FILTER
                }
              },
              updateQuery: (previousResult: ClientPortfolioCharacteristicsQuery, { fetchMoreResult }: any) => {
                if (!fetchMoreResult) {
                  return previousResult;
                }
                let currentPortfolio = _.cloneDeep(detailRef!.current?.state?.currentState);
                let initialPortfolio = _.cloneDeep(detailRef!.current?.state?.initialState);
                let newPortfolio: ClientPortfolioCharacteristicsQuery["portfolio"] = fetchMoreResult.portfolio;
                if (!previousResult || !newPortfolio) {
                  return previousResult;
                }
                if (currentPortfolio) {
                  Object.keys(currentPortfolio?.characteristics || {}).reduce(
                    (result: any, characteristic: string) => {
                      if(characteristic === "__typename") return result
                      const newChar = _.get(newPortfolio, ["characteristics"], []) || []
                      // If matching the current vehicle add it to the current state
                      if (currentPortfolio?.characteristics)
                        _.set(currentPortfolio, ["characteristics"], [
                          ...newChar,
                          ...(currentPortfolio.characteristics || []),
                        ]);
                      if (initialPortfolio?.characteristics)
                        _.set(initialPortfolio, ["characteristic"], [
                          ...newChar,
                          ...(initialPortfolio.characteristics || []),
                        ]);
                      return result;
                    },
                    {}
                  );
                }
                Object.keys(currentPortfolio?.weightedExposures || {}).reduce((result: any, property: string) => {
                  if(property === "__typename") return result
                  // TODO: need fixing.
                  const newChar = _.get(newPortfolio, ["weightedExposures", property], [])
                  // If matching the current vehicle add it to the current state
                  if (currentPortfolio?.weightedExposures) {
                    if(!_.get(currentPortfolio, ["weightedExposures", property])) {
                      if(newChar) {
                        _.set(currentPortfolio, ["weightedExposures", property], newChar)
                      }
                    }else {
                      if(newChar) {
                        _.set(currentPortfolio, ["weightedExposures", property], [
                          ...newChar,
                          ...(_.get(currentPortfolio, ["weightedExposures", property], [])),
                        ]);
                      }
                    }
                  }
                  if(initialPortfolio && !initialPortfolio?.weightedExposures) {
                    if(newChar) {
                      _.set(initialPortfolio, ["weightedExposures", property], newChar)
                    }
                  }
                  if (initialPortfolio?.weightedExposures) {
                    if(!_.get(initialPortfolio, ["weightedExposures", property])) {
                      if(newChar) {
                        _.set(initialPortfolio, ["weightedExposures", property], newChar)
                      }
                    }else {
                      if(newChar) {
                        _.set(initialPortfolio, ["weightedExposures", property], [
                          ...newChar,
                          ...(_.get(initialPortfolio, ["weightedExposures", property], [])),
                        ]);
                      }
                    }
                  }
                  return result;
                }, {});
                detailRef!.current?.setState({ currentState: currentPortfolio, initialState: initialPortfolio}, () => {
                  setTimeout(() => {
                    this.setState({
                      loadingMore: false,
                      historicalDate: moment(historicalMoment).subtract(3, 'years').format(DATE_API_FORMAT),
                    });
                  }, 3000)
                });
                return previousResult;
              },
            });
          };
          return (
            <ProductCharacteristicsVehiclesDetail
              ref={detailRef}
              vehicle={results.data.portfolio as ClientPortfolio}
              editMode={editMode}
              tableDefinitions={tableDefinitions}
              searchDate={searchDate}
              historicalDate={!!historicalDate ? this.state.historicalDate || historicalDate : undefined}
              setShowEditMode={setShowEditMode}
              tab={this.props.tab}
              setTab={(value: string) => this.props.setTab( value )}
              loadMore={loadMore}
              loadingMore={this.state.loadingMore}
            />
          );
        }}
      </Query>
    );
  }
}

interface VehicleDetailState {
  currentState: IdVehicleFields | ClientPortfolio;
  initialState: IdVehicleFields | ClientPortfolio;
  compressedTabs: boolean;
  selectedHistoricalData: string;
  tabOpen: boolean;
}

interface VehicleProps {
  vehicle: IdVehicleFields | ClientPortfolio;
  editMode: boolean;
  tableDefinitions: VehicleCharacteristicsTable[];
  searchDate: string;
  historicalDate?: string;
  setShowEditMode: (value: boolean) => void;
  setTab: (value: string) => void;
  tab: string;
  loadMore: () => void;
  loadingMore: boolean;
}

const CharacteristicsTables = ["miscPortfolioInfo", "portfolioCharacteristics"]

enum CharacteristicsTablesEnum {
  characteristics = "characteristics",
  weightedExposures = "weightedExposures"
}

const WeightedExposuresWithActive = ["bondSectorExposures", "durationExposures", "qualityExposures"]

const miscPortfolioInfoFieldNameToCtdNameMapping: { [key: string]: string } = {
  privatePlacements144a: "privatePlacements144aCtd",
  totalEmergMktExposure: "totalEmergMktExposureCtd",
  convertible: "convertibleCtd",
  nonInvestmentGradeDebt: "nonInvestmentGradeDebtCtd",
  inflationLinkedSecurities: "inflationLinkedSecuritiesCtd",
  totalNonUsDeveloped: "totalNonUsDevelopedCtd",
  // totalGrossShortExposure: "totalGrossShortExposureCtd", TODO: wait for Matt's answer to CAL-3031.
}

export class ProductCharacteristicsVehiclesDetail extends Component<VehicleProps> {
  constructor(props: any) {
    super(props);
    const { vehicle } = props;
    const formattedData = _.cloneDeep(vehicle);
    this.state = {
      currentState: formattedData,
      initialState: formattedData,
      compressedTabs: false,
      selectedHistoricalData: 'value',
      tabOpen: false,
    };
  }

  static getDerivedStateFromProps(props: VehicleProps, state: VehicleDetailState) {
    const { vehicle } = props;
    const resetDate = !props.historicalDate || moment(props.historicalDate).diff(firstHistoricalDate, 'd') === 0;
    const formattedData = _.cloneDeep(vehicle);
    let selectedHistoricalData = state.selectedHistoricalData
    const selectedDefinition = _.find(props.tableDefinitions, (o) => o.tableName === props.tab);
    if (selectedDefinition) {
      const hasCtd = !!_.find(selectedDefinition?.fields, (o) => o?.contributionToDuration === true);
      const hasCtdts = !!_.find(selectedDefinition?.fields, (o) => o?.contributionToDts === true);
      const hasRctdts = !!_.find(selectedDefinition?.fields, (o) => o?.relativeContributionToDts === true)
      if(!hasCtdts && !hasRctdts) {
        // miscPortfolioInfo has ctd only for now.
        if(selectedHistoricalData !== "value") {
          if(hasCtd) {
            selectedHistoricalData = "contributionToDuration"
          }else {
            selectedHistoricalData = "value"
          }
        }
      }
    }
    return (state = {
      currentState: resetDate && !props.editMode && !props.loadingMore ? formattedData : state.currentState,
      initialState: resetDate && !props.loadingMore ? formattedData : state.initialState,
      compressedTabs: state.compressedTabs,
      selectedHistoricalData: selectedHistoricalData,
      tabOpen: state.tabOpen,
    });
  }
  
  refetch = () => {
    this.setState({initialState: this.state.currentState})
  }

  totalLength = this.props.tableDefinitions.reduce((total, entry) => { return total += _.get(tableLengths, entry.tableName) }, 142)
  // 4 is the length added when one is selected, 50 padding at end of navs, 88 for holding tab

  state: VehicleDetailState;

  loadMore = () => {
    this.props.loadMore();
  };

  resetForm = () => {
    this.setState({ currentState: this.state.initialState });
  };

  componentDidMount() {
    window.addEventListener('resize', () => this.resize());
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resize());
  }

  // Resize based on pre calculations on how much space each tab takes up
  resize() {
    const collapsible = document.getElementById('collapse-picker-menu');
    if (this.totalLength <= (collapsible?.clientWidth || 0)) {
      if (this.state.compressedTabs) this.setState({ compressedTabs: false });
    } else {
      if (!this.state.compressedTabs) this.setState({ compressedTabs: true });
    }
  }

  handleWeightedExposuresChange = (value: any, property: string, code: string, date: string, tableName: string) => {
    let oldState: any = _.cloneDeep(this.state.currentState)
    if(!_.get(oldState, ['weightedExposures', tableName])) {
      _.set(oldState, ['weightedExposures', tableName], [])
    }
    let newState = iassign(
      oldState,
      ['weightedExposures', tableName],
      (rows) => {
        let rowsClone = _.cloneDeep(rows || []) as any[]
        let idx = _.findIndex(rowsClone, (o) => o?.type?.code?.toString() === code?.toString() && o?.date === date)
        let selectedRow =  _.cloneDeep(rowsClone[idx])
        if(idx < 0) {
          selectedRow = {
            date: date,
            type: { code: code, __typename: 'Lookup' },
            value: WeightedExposuresWithActive.includes(tableName)? {active: value}: value
          }
        }
        if(property === "value") {
          if(WeightedExposuresWithActive.includes(tableName)) {
            // if(!_.get(selectedRow, [property], "active")) {
            //   _.set(selectedRow, [property], {active: value})
            // }else {
              _.set(selectedRow, [property, "active"], value)
            // }
          } else {
            _.set(selectedRow, [property], value)
          }
        }else {
          _.set(selectedRow, [property], value)
        }
        if(idx < 0) {
          rowsClone.push(selectedRow)
        }else {
          rowsClone[idx] = selectedRow
        }
        return rowsClone
      }
    )
    this.setState({ currentState: newState })
  }

  handleVehicleChange = (value: any, property: string, inCode: string, date: string, type: string, tableName?: string) => {
    if(type === CharacteristicsTablesEnum.weightedExposures) {
      this.handleWeightedExposuresChange(value, property, inCode, date, tableName || "bondSectorExposures")
      return
    }
    let oldState: any = _.cloneDeep(this.state.currentState)
    let newValue = value
    if(property === "value" && inCode === 'qualityRating') {
      let match = qualityStringToNumericMapping?.find((o) => o?.code === value)
      newValue = match? match.value : null
    }
    let newState = iassign(
      oldState,
      ["characteristics"],
      (rows) => {
        let rowsClone = _.cloneDeep(rows || []) as any[]
        let idx= _.findIndex(rowsClone, (o) => o?.date === date);
        let selectedRow = rowsClone[idx];
        let code = inCode
        if(property !== "value" && tableName === "miscPortfolioInfo") {
          // use property instead for ctd under misPortfolioInfo tab.
          code = property
        }
        if (!selectedRow) {
          selectedRow = {
            date: date,
            [code]: newValue,
            __typename: "Characteristics"
          }
          rowsClone.push(selectedRow);
        }else {
          selectedRow[code] = newValue
          rowsClone[idx] = selectedRow
        }
        
        return rowsClone;
      }
    );
    this.setState({ currentState: newState });
  };

  getData = (selectedTable: any, type: CharacteristicsTablesEnum, fieldName: string) => {
    if(type === CharacteristicsTablesEnum.characteristics) {
      let result =  _.get(selectedTable, [0, fieldName], null)
      return result
    }else {
      return _.find(selectedTable, (o) => o?.type?.code === fieldName)
    }
  }

  singleView = (
    selectedDefinition: VehicleCharacteristicsTable,
    hasCtd: boolean,
    hasCtdts: boolean,
    hasRctdts: boolean,
    selectedTable: any,
    type: CharacteristicsTablesEnum
  ) => {
    let totalValue: Totals = { value: 0, ctd: 0, ctdts: 0, rctdts: 0 };
    let subtotals: { [key: string]: Totals } = {
      'Subtotal- Industrial': { value: 0, ctd: 0, ctdts: 0, rctdts: 0 },
      'Subtotal- Utility': { value: 0, ctd: 0, ctdts: 0, rctdts: 0 },
      'Subtotal- Financial Institutions': { value: 0, ctd: 0, ctdts: 0, rctdts: 0 },
    };
    let lastSubtotal = '';
    return (
      <div className='table-container'>
        <Table hover className='table-bordered-internal table-condensed exportable' data-export-name={`${this.props.vehicle.name}-${this.props.tab}`}>
          <thead>
            <tr>
              <th data-export-value={this.selectTabName()}></th>
              <th className='text-right'>Weight</th>
              {hasCtd && <th className='text-right'>Contribution to Duration</th>}
              {!hasCtd && !hasCtdts && !hasRctdts && <th className='filler-col'></th>}
              {hasCtdts && <th className='text-right'>Contribution To Duration Time Spread</th>}
              {!hasCtdts && !hasRctdts && <th className='filler-col'></th>}
              {hasRctdts && <th className='text-right'>Relative Contribution To Duration Time Spread</th>}
            </tr>
          </thead>
          <tbody>
            {selectedDefinition?.fields.map((field: VehicleCharacteristicsField, row: number) => {
              const data = this.getData(selectedTable, type, field.fieldName)
              const tableName = selectedDefinition.tableName
              let property = field.fieldName
              let valuePropertyVal = _.cloneDeep(data)
              switch (type) {
                case "weightedExposures":
                  if(WeightedExposuresWithActive.includes(tableName)) {
                    valuePropertyVal = data?.value?.active
                  } else {
                    valuePropertyVal = data?.value
                  }
                  break
                case 'characteristics':
                default:
                  break
              }
              if(selectedDefinition.tableName === 'portfolioCharacteristics'&& property === 'qualityRating') {
                let nearestMatch = qualityStringToNumericMapping?.find((o) => !_.isNull(valuePropertyVal) &&  o?.value <= valuePropertyVal)
                valuePropertyVal = nearestMatch?.code || ""
              }
              // console.log(670, {selectedTable, tableName: selectedDefinition.tableName, field: property, valuePropertyVal, original: data, property})
              let ctdProperty = "contributionToDuration"
              let ctdPropertyVal = data?.contributionToDuration
              if(field?.contributionToDuration && selectedDefinition.tableName === 'miscPortfolioInfo') {
                if(miscPortfolioInfoFieldNameToCtdNameMapping.hasOwnProperty(property)) {
                  ctdProperty = miscPortfolioInfoFieldNameToCtdNameMapping[property]
                  ctdPropertyVal = this.getData(selectedTable, type, ctdProperty)
                }
              }
              const ctdtsPropertyVal = data?.contributionToDurationTimeSpread
              const rctdtsPropertyVal = data?.relativeContributionToDurationTimeSpread
              let showSubtotal = <></>;
              totalValue['value'] += valuePropertyVal || 0;
              totalValue['ctd'] += ctdPropertyVal || 0;
              totalValue['ctdts'] += ctdtsPropertyVal || 0;
              totalValue['rctdts'] += rctdtsPropertyVal || 0;

              if (field.subtotal) {
                subtotals[field.subtotal]['value'] =
                  (subtotals[field.subtotal]['value'] || 0) + (valuePropertyVal || 0);
                subtotals[field.subtotal]['ctd'] = (subtotals[field.subtotal]['ctd'] || 0) + (ctdPropertyVal || 0);
                subtotals[field.subtotal]['ctdts'] =
                  (subtotals[field.subtotal]['ctdts'] || 0) + (ctdtsPropertyVal || 0);
                subtotals[field.subtotal]['rctdts'] =
                  (subtotals[field.subtotal]['rctdts'] || 0) + (rctdtsPropertyVal || 0);
                if (lastSubtotal !== '' && lastSubtotal !== field.subtotal) {
                  showSubtotal = (
                    <tr>
                      <td className={'text-left'}>{lastSubtotal}</td>
                      <td className={'text-right'}>{numbro(subtotals[lastSubtotal]['value'] || 0).format('0.00')}%</td>
                      {hasCtd && (
                        <td className={'text-right'}>{numbro(subtotals[lastSubtotal]['ctd'] || 0).format('0.00')}</td>
                      )}
                      {hasCtdts && (
                        <td className={'text-right'}>{numbro(subtotals[lastSubtotal]['ctdts'] || 0).format('0.00')}</td>
                      )}
                      {hasRctdts && (
                        <td className={'text-right'}>
                          {numbro(subtotals[lastSubtotal]['rctdts'] || 0).format('0.00')}
                        </td>
                      )}
                    </tr>
                  );
                }
                lastSubtotal = field.subtotal;
              }

              if (
                !this.props.editMode &&
                !valuePropertyVal &&
                valuePropertyVal !== 0 &&
                !ctdPropertyVal &&
                ctdPropertyVal !== 0 &&
                !ctdtsPropertyVal &&
                ctdtsPropertyVal !== 0 &&
                !rctdtsPropertyVal &&
                rctdtsPropertyVal !== 0
              ) {
                return <React.Fragment key={selectedDefinition.tableName + row}>{showSubtotal}</React.Fragment>;
              }
              let valueType = 'float';
              let valueSubtype = 'percent';
              let noFormat = false;
              let editDecimalPlaces = undefined;
              if (field.format === 'percent') {
                editDecimalPlaces = 4;
                // Use Defaults
              } else if (field.format === 'float') {
                valueSubtype = '';
                editDecimalPlaces = 4;
              } else if (field.format === 'dollars') {
                valueSubtype = 'currency';
              } else if (field.format === 'years') {
                valueSubtype = 'yearCount';
              } else if (field.format === 'multiplier') {
                valueSubtype = 'multiplier';
              } else if (field.format === 'qualitySelector') {
                valueType = 'select';
                valueSubtype = 'single';
              } else if (field.format === 'months') {
                valueType = 'number';
                valueSubtype = 'monthCount';
              } else if (field.format === 'number') {
                valueType = 'number';
                valueSubtype = '';
                noFormat = true;
              } else if (field.format === 'sqft') {
                valueType = 'number';
                valueSubtype = 'sqftCount';
              }
              let inputClasses = ""
              if(valueType === 'select') {
                inputClasses = 'quality-selector'
              }
              return (
                <React.Fragment key={selectedDefinition.tableName + row}>
                  {showSubtotal}
                  <tr>
                    <td className={'text-left text-nowrap'}>{field.label}</td>
                    <td>
                      <FormInput
                        property={'value'}
                        displayName={''}
                        type={valueType}
                        subtype={valueSubtype}
                        placeholder={''}
                        idx={row}
                        editMode={this.props.editMode}
                        showZero={true}
                        propertyVal={valuePropertyVal}
                        updateValue={(value) =>
                          this.handleVehicleChange(value, 'value', field.fieldName, this.props.searchDate, type, tableName)
                        }
                        noFormat={noFormat}
                        editDecimalPlaces={editDecimalPlaces}
                        options={qualityStringMapping}
                        subClasses={{inputClasses}}
                      />
                    </td>
                    {hasCtd && (
                      <td>
                        {field.contributionToDuration && (
                          <FormInput
                            property={ctdProperty}
                            displayName={''}
                            type={'float'}
                            subtype={''}
                            placeholder={''}
                            idx={row}
                            editMode={this.props.editMode}
                            showZero={true}
                            editDecimalPlaces={4}
                            propertyVal={ctdPropertyVal}
                            updateValue={(value) =>
                              this.handleVehicleChange(
                                value,
                                ctdProperty,
                                field.fieldName,
                                this.props.searchDate,
                                type,
                                tableName
                              )
                            }
                          />
                        )}
                      </td>
                    )}
                    {hasCtdts && (
                      <td>
                        {field.contributionToDts && (
                          <FormInput
                            property={'contributionToDurationTimeSpread'}
                            displayName={''}
                            type={'float'}
                            subtype={''}
                            placeholder={''}
                            idx={row}
                            showZero={true}
                            editMode={this.props.editMode}
                            editDecimalPlaces={4}
                            propertyVal={ctdtsPropertyVal}
                            updateValue={(value) =>
                              this.handleVehicleChange(
                                value,
                                'contributionToDurationTimeSpread',
                                field.fieldName,
                                this.props.searchDate,
                                type,
                                tableName
                              )
                            }
                          />
                        )}
                      </td>
                    )}
                    {hasRctdts && (
                      <td>
                        {field.relativeContributionToDts && (
                          <FormInput
                            property={'relativeContributionToDurationTimeSpread'}
                            displayName={''}
                            type={'float'}
                            subtype={''}
                            placeholder={''}
                            idx={row}
                            showZero={true}
                            editMode={this.props.editMode}
                            editDecimalPlaces={4}
                            propertyVal={rctdtsPropertyVal}
                            updateValue={(value) =>
                              this.handleVehicleChange(
                                value,
                                'relativeContributionToDurationTimeSpread',
                                field.fieldName,
                                this.props.searchDate,
                                type,
                                tableName
                              )
                            }
                          />
                        )}
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
            {lastSubtotal !== '' && (
              <tr>
                <td className={'text-left'}>{lastSubtotal}</td>
                <td className={'text-right'}>{numbro(subtotals[lastSubtotal]['value'] || 0).format('0.00')}%</td>
                {hasCtd && (
                  <td className={'text-right'}>{numbro(subtotals[lastSubtotal]['ctd'] || 0).format('0.00')}</td>
                )}
                {hasCtdts && (
                  <td className={'text-right'}>{numbro(subtotals[lastSubtotal]['ctdts'] || 0).format('0.00')}</td>
                )}
                {hasRctdts && (
                  <td className={'text-right'}>{numbro(subtotals[lastSubtotal]['rctdts'] || 0).format('0.00')}</td>
                )}
              </tr>
            )}
            {selectedDefinition.totalAll && (
              <tr>
                <td className={'text-left'}>Calculated Total</td>
                <td
                  className={classnames({
                    'text-right': true,
                    'has-error': totalValue['value'] > 0 && numbro(totalValue['value']).difference(100) >= 0.01,
                  })}
                >
                  {numbro(totalValue['value']).format('0.00')}%
                </td>
                {hasCtd && <td className={'text-right'}>{numbro(totalValue['ctd'] || 0).format('0.00')}</td>}
                {hasCtdts && <td className={'text-right'}>{numbro(totalValue['ctdts'] || 0).format('0.00')}</td>}
                {hasRctdts && <td className={'text-right'}>{numbro(totalValue['rctdts'] || 0).format('0.00')}</td>}
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  historicalView = (
    selectedDefinition: VehicleCharacteristicsTable,
    hasCtd: boolean,
    hasCtdts: boolean,
    hasRctdts: boolean,
    selectedTable: any,
    type: CharacteristicsTablesEnum
  ) => {
    const quarters = listQuarters(this.props.historicalDate || '', appDate.format(DATE_API_FORMAT));
    const valueType = this.state.selectedHistoricalData;

    let visibleFields: string[] = []
    // result?.map((entry: any, row: number) => {
    //   const valuePropertyVal =
    //     selectedDefinition.tableName === 'portfolioCharacteristics'
    //       ? entry?.value?.numeric?.active
    //       : entry?.value
    //   const ctdPropertyVal = entry?.contributionToDuration
    //   const ctdtsPropertyVal = entry?.contributionToDurationTimeSpread
    //   const rctdtsPropertyVal = entry?.relativeContributionToDurationTimeSpread
    //   if (
    //     !this.props.editMode &&
    //     (!valuePropertyVal || valuePropertyVal === 0) &&
    //     (!ctdPropertyVal || ctdPropertyVal === 0) &&
    //     (!ctdtsPropertyVal || ctdtsPropertyVal === 0) &&
    //     (!rctdtsPropertyVal || rctdtsPropertyVal === 0)
    //   ) {
    //     return;
    //   }
    //   visibleFields = _.union(visibleFields, [entry?.type.code]);
    // });
    let lastSubtotal = ''
    return (
      <>
        {(hasCtd || hasCtdts || hasRctdts) && (
          <ul className={'horizontal-picker'}>
            <li
              className={classnames('horizontal-picker-item', { active: valueType === 'value' })}
              onClick={() => this.setState({ selectedHistoricalData: 'value' })}
            >
              Value
            </li>
            {hasCtd && (
              <li
                className={classnames('horizontal-picker-item', { active: valueType === 'contributionToDuration' })}
                onClick={() => this.setState({ selectedHistoricalData: 'contributionToDuration' })}
              >
                Contribution To Duration
              </li>
            )}
            {hasCtdts && (
              <li
                className={classnames('horizontal-picker-item', {
                  active: valueType === 'contributionToDurationTimeSpread',
                })}
                onClick={() => this.setState({ selectedHistoricalData: 'contributionToDurationTimeSpread' })}
              >
                Contribution To Duration Time Spread
              </li>
            )}
            {hasRctdts && (
              <li
                className={classnames('horizontal-picker-item', {
                  active: valueType === 'relativeContributionToDurationTimeSpread',
                })}
                onClick={() => this.setState({ selectedHistoricalData: 'relativeContributionToDurationTimeSpread' })}
              >
                Relative Contribution To Duration Time Spread
              </li>
            )}
          </ul>
        )}
        <div className='table-container'>
          <Table
            hover
            className='table-bordered-internal table-condensed exportable' data-export-name={`${this.props.vehicle.name}-${this.props.tab}`}
            key={selectedDefinition.tableName + valueType}
          >
            <thead>
              <tr>
                <th>Date</th>
                {selectedDefinition?.fields.map((field: VehicleCharacteristicsField, idx: number) => {
                  let showSubtotal = <></>
                  if (field.subtotal) {
                    if (lastSubtotal !== '' && lastSubtotal !== field.subtotal) {
                      showSubtotal = (
                        <th>{lastSubtotal}</th>
                      );
                    }
                    lastSubtotal = field.subtotal
                  }
                  return(
                    <React.Fragment key={idx}>
                      {showSubtotal}
                      <th>{field.label}</th>
                    </React.Fragment>
                  )
                })}
                {lastSubtotal !== '' && (
                  <th>{lastSubtotal}</th>
                )}
                {selectedDefinition.totalAll && (
                  <th>
                    Calculated Total
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {quarters.map((quarter: any, row: number) => {
                return (
                  <tr key={row}>
                    {this.historicalRow(quarter, selectedTable, selectedDefinition, valueType, visibleFields, type)}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  historicalRow = (
    quarter: string,
    selectedTable: any,
    selectedDefinition: any,
    inFieldType: string,
    visibleFields: string[],
    type: CharacteristicsTablesEnum
  ) => {
    let totalValue: number = 0;
    let subtotals: { [key: string]: number } = {};
    let lastSubtotal = '';

    return (
      <>
        <td>{quarter}</td>
        {selectedDefinition?.fields.map((field: VehicleCharacteristicsField, idx: number) => {
          const quarterDataSet = _.filter(selectedTable, (o) => o.date === quarter);
          const data  = this.getData(quarterDataSet, type, field.fieldName)
          const tableName = selectedDefinition.tableName
          let property = field.fieldName
          let propertyVal = _.cloneDeep(data)
          let fieldType = inFieldType
          switch (type) {
            case "weightedExposures":
              if(WeightedExposuresWithActive.includes(tableName) && fieldType === "value" ) {
                propertyVal = _.get(data, [fieldType, "active"])
              } else {
                propertyVal = _.get(data, [fieldType])
              }
              break
            case 'characteristics':
              if(selectedDefinition.tableName === 'miscPortfolioInfo'&& fieldType === 'contributionToDuration') {
                if(miscPortfolioInfoFieldNameToCtdNameMapping.hasOwnProperty(property)) {
                  fieldType = miscPortfolioInfoFieldNameToCtdNameMapping[property]
                  propertyVal = this.getData(quarterDataSet, type, fieldType)
                }
              } else if(selectedDefinition.tableName === 'portfolioCharacteristics'&& property === 'qualityRating') {
                let nearestMatch = qualityStringToNumericMapping?.find((o) => !_.isNull(propertyVal) &&  o?.value <= propertyVal)
                propertyVal = nearestMatch?.code || ""
              }
              break
            default:
              break
          }

          totalValue += propertyVal || 0
          let showSubtotal = <></>
          if (field.subtotal) {
            subtotals[field.subtotal] = subtotals[field.subtotal] || 0
            subtotals[field.subtotal] += propertyVal || 0
            if (lastSubtotal !== '' && lastSubtotal !== field.subtotal) {
              showSubtotal = (
                <td className={'text-right'}>{numbro(subtotals[lastSubtotal] || 0).format('0.00')}{fieldType === "value" && "%"}</td>
              );
            }
            lastSubtotal = field.subtotal
          }

          let displayInput = true;
          if (fieldType === 'contributionToDuration' && !field.contributionToDuration) displayInput = false;
          if (fieldType === 'contributionToDurationTimeSpread' && !field.contributionToDts) displayInput = false;
          if (fieldType === 'relativeContributionToDurationTimeSpread' && !field.relativeContributionToDts)
            displayInput = false;

          let valueType = 'float';
          let valueSubtype = 'percent';
          let noFormat = false;
          if (fieldType === 'contributionToDuration') {
            valueSubtype = '';
          } else if (fieldType === 'contributionToDurationTimeSpread') {
            valueSubtype = '';
          } else if (fieldType === 'relativeContributionToDurationTimeSpread') {
            valueSubtype = '';
          } else if (field.format === 'percent') {
            // Use Defaults
          } else if (field.format === 'float') {
            valueSubtype = '';
          } else if (field.format === 'dollars') {
            valueSubtype = 'currency';
          } else if (field.format === 'years') {
            valueSubtype = 'yearCount';
          } else if (field.format === 'multiplier') {
            valueSubtype = 'multiplier';
          } else if (field.format === 'qualitySelector') {
            valueType = 'select';
            valueSubtype = 'single';
          } else if (field.format === 'months') {
            valueType = 'number';
            valueSubtype = 'monthCount';
          } else if (field.format === 'number') {
            valueType = 'number';
            valueSubtype = '';
            noFormat = true;
          } else if (field.format === 'sqft') {
            valueType = 'number';
            valueSubtype = 'sqftCount';
          }
          let inputClasses = ""
          if(valueType === 'select') {
            inputClasses = 'quality-selector'
          }

          return (
            <React.Fragment key={idx}>
              {showSubtotal}
              <td>
                {displayInput && (
                  <FormInput
                    property={fieldType}
                    displayName={''}
                    type={valueType}
                    subtype={valueSubtype}
                    placeholder={''}
                    idx={quarter + '.' + idx}
                    editMode={this.props.editMode}
                    showZero={true}
                    propertyVal={propertyVal}
                    updateValue={(value) => this.handleVehicleChange(value, fieldType, field.fieldName, quarter, type, tableName)}
                    options={qualityStringMapping}
                    noFormat={noFormat}
                    subClasses={{inputClasses}}
                  />
                )}
              </td>
            </React.Fragment>
          );
        })}
        {lastSubtotal !== '' && (
          <td className={'text-right'}>{numbro(subtotals[lastSubtotal] || 0).format('0.00')}{inFieldType === "value" && "%"}</td>
        )}
        {selectedDefinition.totalAll && (
          <td
            className={classnames({
              'text-right': true,
              'has-error': inFieldType === "value" && totalValue > 0 && numbro(totalValue).difference(100) >= 0.01,
            })}
          >
            {numbro(totalValue).format('0.00')}{inFieldType === "value" && "%"}
          </td>
        )}
      </>
    );
  };

  mainContent = () => {
    const selectedDefinition = _.find(this.props.tableDefinitions, (o) => o.tableName === this.props.tab);
    if (selectedDefinition) {
      const hasCtd = !!_.find(selectedDefinition?.fields, (o) => o?.contributionToDuration === true);
      const hasCtdts = !!_.find(selectedDefinition?.fields, (o) => o?.contributionToDts === true);
      const hasRctdts = !!_.find(selectedDefinition?.fields, (o) => o?.relativeContributionToDts === true);
      let selectedTable;
      let tableName = selectedDefinition?.tableName || ''

      let type = CharacteristicsTables.includes(tableName)? CharacteristicsTablesEnum.characteristics: CharacteristicsTablesEnum.weightedExposures 
      if(type === "characteristics") {
        selectedTable = _.get(this.state.currentState, [type?.toString()], [])
        // console.log("chars", {selectedTable})
      }else {
        selectedTable = _.get(this.state.currentState, [type?.toString(), tableName], [])
        // console.log("weighted", {selectedTable})
      }
      // console.log(988, {type, selectedDefinition, tableName, selectedTable, state: this.state.currentState})
      if (this.props.historicalDate) {
        return this.historicalView(selectedDefinition, hasCtd, hasCtdts, hasRctdts, selectedTable, type);
      } else {
        // console.log("singleView", {selectedTable})
        return this.singleView(selectedDefinition, hasCtd, hasCtdts, hasRctdts, selectedTable, type);
      }
    } else if (this.props.tab === 'holdings') {
      return <ProductCharacteristicsHoldings portfolio={this.props.vehicle} />;
    } else {
      return <div>No Tab Data</div>;
    }
  };

  selectTabName = () => {
    const selectedDefinition = _.find(this.props.tableDefinitions, (o) => o.tableName === this.props.tab);
    if (selectedDefinition) {
      return selectedDefinition.tabName;
    } else if (this.props.tab === 'holdings') {
      return 'Holdings';
    }
    return '';
  };

  handleItemClick = (name: string) => this.props.setTab(name);

  render = () => {
    const displayHolding = (this.props.vehicle.__typename === "VehicleFields" && this.props.vehicle.vehicleDataCollection?.holdingsRequired) || (this.props.vehicle.__typename === "ClientPortfolio" && this.props.vehicle.clientSpecificDataCollectionFields?.questionnaireHoldingsRequired)

    let menuItems = this.props.tableDefinitions.map((definition) => { return { ...definition, tableLength: _.get(tableLengths, definition.tableName) }})
    if(displayHolding){
      menuItems.push({
        tableLength: "any",
        tableName: "holdings",
        tabName: "Holdings",
        fields: []
      })
    }

    return (
      <Col md='8' lg='9' className='pl-1'>
        <div className='pane'>
          <h2 className='headline'>{this.state.currentState.name}</h2>

          <TabMenu
            menuItems={menuItems}
            selected={this.selectTabName()}
            onClick={this.handleItemClick}
            setTab={this.props.setTab}
          />

          {this.mainContent()}
        </div>
      </Col>
    );
  };
}

export default QueryProductCharacteristicsDetails;

import classNames from "classnames"
import { compact, get, isNull } from "lodash"
import React, { useEffect, useState } from "react"
import { defaultStyles, FileIcon } from "react-file-icon"
import { useHistory } from "react-router-dom"
import { Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'

import { AutocompleteClientFragment, AutocompleteFileFragment, AutocompleteManagerFragment, AutocompletePeopleFragment, AutocompletePlanFragment, AutocompleteProductFragment, AutocompleteQueryVariables, AutocompleteTargetDateFragment, AutocompleteVehicleFragment, SearchAutocompleteFragment, SearchTypes, useAutocompleteQuery, AutocompleteCustodianFragment, AutocompleteRecordKeeperFragment, AutocompleteIndexFragment, AutocompleteGroupFragment, QueryAutocompleteArgs, PlanClientPortfolioFragment } from '../../__generated__/graphql'
import { SearchDisplayToTypes, SearchTypeDisplays } from './SearchEnums'


interface AutocompleteProps {
  query: string,
  type: SearchTypeDisplays | null
  updateAutocomplete: (value: string) => void
}

export type AutocompleteItemTypes = AutocompleteManagerFragment | AutocompleteClientFragment | AutocompleteProductFragment | AutocompleteTargetDateFragment | AutocompleteVehicleFragment | AutocompletePeopleFragment | AutocompleteFileFragment | AutocompletePlanFragment | AutocompleteCustodianFragment | AutocompleteRecordKeeperFragment | AutocompleteIndexFragment | AutocompleteGroupFragment | PlanClientPortfolioFragment //PlanClientPortfolioFragment not actually returned by autocomplete query but used in same way

export const SearchAutocomplete:React.FC<AutocompleteProps> = (props) => {
  const { query, type, updateAutocomplete } = props
  const history = useHistory()

  let autocompleteVars:QueryAutocompleteArgs = { q: query, filters: { person: { orgTypeCode: ['BROKER','INVADV','INVMGR','INVCON']}} }
  if (type) {
    let searchType = get(SearchDisplayToTypes,type, undefined) as SearchTypes | undefined
    autocompleteVars.types = searchType? [searchType] : []
  }
  
  const { data } = useAutocompleteQuery({ variables: autocompleteVars})

  if (!!data && data.autocomplete) {
    const onClick = (item:AutocompleteItemTypes, linkUrl: string) => {
      updateAutocomplete("")
      history.push(linkUrl)
    }

    return (
      <ListGroup className="horizontal with-category autocomplete">
        <SearchRows
          rows={data.autocomplete as SearchAutocompleteFragment[]}
          handleClick={onClick}
        />
      </ListGroup>
    )
  }
  return <></>
}

interface SearchRowsProps {
  rows: SearchAutocompleteFragment[]
  handleClick: (item:AutocompleteItemTypes, url:string) => void
  includedRows?: string[]
}

export const SearchRows:React.FC<SearchRowsProps> = ({rows, handleClick, includedRows}) => {
  const [showRows, setShowRows] = useState(false)
  const hideShowRows = () => setTimeout(() => {
    setShowRows(false)
  }, 1000)

  useEffect(() => {
    setShowRows(true)
  }, [rows])

  const hasIncludes = includedRows !== undefined
  let results = rows.map((d) => {
    if (d == null) {
      return <ListGroupItem className="horizontal with-category autocomplete"></ListGroupItem>
    }

    if(d.__typename === "ManagerAutocomplete") {
      const included = includedRows?.includes(`Manager:${d.id}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/managers/${d.id}/profile`)} key={`manager-auto-${d.id}`}>
          <div className="category manager">
            <div className="category-text">
              <h6>Manager</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md={hasIncludes ? 6 : 8}>
                <h3>{d.name}</h3>
                <p>&nbsp;</p>
              </Col>
              <Col md={hasIncludes ? 3 : 4}>
                <h5>Manager ID</h5>
                <p>{d.id}</p>
              </Col>
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "CustodianAutocomplete") {
      const included = includedRows?.includes(`Custodian:${d.id}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/custodians/${d.id}/overview`)} key={`custodian-${d.id}`}>
          <div className="category custodian">
            <div className="category-text">
              <h6>Custodian</h6>
            </div>
          </div>
          <div className="w-100">
          <Row>
              <Col md="8">
                <h3>{d.custodianName}</h3>
                <p>&nbsp;</p>
              </Col>
              <Col md="4">
                <h5>Custodian ID</h5>
                <p>{d.id}</p>
              </Col>
            </Row>
          </div>
        </ListGroupItem>
      )
    } else if (d.__typename === "RecordKeeperAutocomplete") {
      const included = includedRows?.includes(`RecordKeeper:${d.id}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/recordkeepers/${d.id}/overview`)} key={`record_keeper-${d.id}`}>
          <div className="category record-keeper">
            <div className="category-text">
              <h6>RK</h6>
            </div>
          </div>
          <div className="w-100">
          <Row>
              <Col md="8">
                <h3>{d.recordKeeperName}</h3>
                <p>&nbsp;</p>
              </Col>
              <Col md="4">
                <h5>Record Keeper ID</h5>
                <p>{d.id}</p>
              </Col>
            </Row>
          </div>
        </ListGroupItem>
      )
    } else if (d.__typename === "ClientAutocomplete") {
      const included = includedRows?.includes(`Client:${d.id}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/clients/${d.id}`)} key={`client-auto-${d.id}`}>
          <div className="category client">
            <div className="category-text">
              <h6>Client</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md={hasIncludes ? 6 : 8}>
                <h3>{d.clientName}</h3>
                <p>&nbsp;</p>
              </Col>
              <Col md={hasIncludes ? 3 : 4}>
                <h5>Client ID</h5>
                <p>{d.id}</p>
              </Col>
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "PeopleAutocomplete") {
      const included = includedRows?.includes(`Person:${d.id}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/managers/${d.firmId}/people/${d.id}`)} key={`person-auto-${d.id}`}>
          <div className="category person">
            <div className="category-text">
              <h6>Person</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md={hasIncludes ? 9 : 12}>
                <h3>{d.lastName}, { d.firstName }</h3>
                <p>MANAGER: {d.firmName}</p>
              </Col>
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "ProductAutocomplete") {
      // TODO check if this works
      const included = includedRows?.includes(`Product:${d.id}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/products/${d.id}/profile`)} key={`product-auto-${d.id}`}>
          <div className="category product">
            <div className="category-text">
              <h6>Product</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md={hasIncludes ? 6 : 8}>
                <h3>{ d.productName }</h3>
                <p>MANAGER: {d.managerName}</p>
              </Col>
              <Col md={hasIncludes ? 3 : 4}>
                <h5>Product ID</h5>
                <p>{d.id}</p>
              </Col>
              {hasIncludes &&
              <Col md={3}>
                {included && <h5>Included</h5>}
              </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "VehicleAutocomplete") {
      const included = includedRows?.includes(`Vehicle:${d.vehicleId}`)
      const cusip = (!!d.cusip || !!d.ticker) ? `${d.cusip}/${d.ticker}` : ''
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => {hideShowRows();handleClick(d,`/products/${d.productId}/vehicles/${d.vehicleId}`)}} key={`vehicle-auto-${d.vehicleId }`}>
          <div className="category vehicle">
            <div className="category-text">
              <h6>Vehicle</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md={hasIncludes ? 6 : 8}>
                <h3>{ d.vehicleName }</h3>
                <p>MANAGER: {d.managerName}</p>
              </Col>
              <Col md={hasIncludes ? 3 : 2}>
                <h5>Vehicle ID</h5>
                <p>{d.vehicleId}</p>
              </Col>
              {!hasIncludes &&
                <Col md="2" className="pr-0">
                  <h5>CUSIP</h5>
                  <p className="text-break">{cusip}</p>
                </Col>
              }
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "TargetDateAutocomplete") {
      const included = includedRows?.includes(`GlidePathVersion:${d.id}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/glidepaths/${d.glideId}/overview`)} key={`target-date-auto-${d.glideId}`}>
          <div className="category target">
            <div className="category-text">
              <h6>Target DT</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md={hasIncludes ? 9 : 12}>
                <h3>{ d.versionName }</h3>
                <p>MANAGER: {d.managerName}</p>
              </Col>
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "FileAutocomplete") {
      const included = includedRows?.includes(`File:${d.fileid}`)
      const nameList = (d.managerNames || []).concat(d.clientNames || [] ,d.productNames || [], d.fundNames || [])
      const firstName = nameList.shift()
      const extension = d.fileName?.split(".")?.pop() || ""
      const style = get(defaultStyles, extension)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/documents/${d.fileid}`)} key={`file-auto-${d.fileid }`}>
          <div className="category document">
            <div className="category-text">
              <h6>Document</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md="6">
                <h3>{d.description || d.fileName}</h3>
                <p>{d.type}/{d.subType}</p>
              </Col>
              <Col md={hasIncludes ? 3 : 4}>
                <h5>Associated to</h5>
                <p>{firstName}
                {nameList.length > 0 &&
                  <>
                    {" "}and {nameList.length} others
                  </>
                }
                </p>
              </Col>
              {!hasIncludes &&
                <Col md="2">
                  <div className="document-search-icon-container">
                    <FileIcon extension={extension} {...style}  />
                  </div>
                </Col>
              }
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "PlanAutocomplete") {
      const included = includedRows?.includes(`Plan:${d.id}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,``)} key={`plan-auto-${d.id}`}>
          <div className="category plan">
            <div className="category-text">
              <h6>Plan</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md={hasIncludes ? 6 : 8}>
                <h3>{d.planName}</h3>
                <p>&nbsp;</p>
              </Col>
              <Col md={hasIncludes ? 3 : 4}>
                <h5>Plan ID</h5>
                <p>{d.id}</p>
              </Col>
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "IndexAutocomplete") {
      const included = includedRows?.includes(`Index:${d.indexId}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/indexes/${d.indexId}/overview`)} key={`index-auto-${d.indexId}`}>
          <div className="category index">
            <div className="category-text">
              <h6>Index</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md="6">
                <h3>{d.indexName}</h3>
                <p>INDEX PROVIDER: {d.providerName}</p>
              </Col>
              <Col md="3">
                <h5>Index ID</h5>
                <p>{d.indexId}</p>
              </Col>
              {!hasIncludes &&
                <Col md="3">
                  <h5>Bloomberg ID</h5>
                  <p>{d.bloombergId}</p>
                </Col>
              }
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    } else if (d.__typename === "GroupAutocomplete") {
      const included = includedRows?.includes(`Group:${d.groupId}`)
      return (
        <ListGroupItem className={classNames({included: included})} tag="a" onClick={() => handleClick(d,`/groups/${d.groupId}/overview`)} key={`group-auto-${d.groupId}`}>
          <div className="category group">
            <div className="category-text">
              <h6>Group</h6>
            </div>
          </div>
          <div className="w-100">
            <Row>
              <Col md={hasIncludes ? 6 : 8}>
                <h3>{d.groupName}</h3>
                <p>&nbsp;</p>
              </Col>
              <Col md={hasIncludes ? 3 : 4}>
                <h5>Group ID</h5>
                <p>{d.groupId}</p>
              </Col>
              {hasIncludes &&
                <Col md={3}>
                  {included && <h5>Included</h5>}
                </Col>
              }
            </Row>
          </div>
          {!included && hasIncludes &&
            <div className="hover-overlay">
              <Button className="add-button">
                Add
              </Button>
            </div>
          }
        </ListGroupItem>
      )
    }

    return <></>
  })

  return (
    <>
      {showRows && results}
    </>
  )
}
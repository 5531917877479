import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap"
import Icon from "../Components/ui/Icon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Auth from "../Auth/Auth"
import history from "../history"

interface LoginProps {
  auth: Auth
}

class Login extends Component<LoginProps, any> {
  constructor(props: LoginProps) {
    super(props)
    sessionStorage.setItem("callback-url", window.location.pathname)
    this.state = {
      loading: true,
    }
  }

  async checkSSO() {
    const { auth } = this.props
    if (auth) {
      const renewResult = await auth.renewToken()
      if (renewResult) {
        let location = sessionStorage.getItem("callback-url") || "/"
        sessionStorage.removeItem("callback-url")
        history.replace(location)
      } else {
        this.setState({ loading: false })
      }
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidMount(): void {
    this.checkSSO()
  }

  render() {
    const { login } = this.props.auth
    return this.state.loading ? (
      <div />
    ) : (
      <div className="login-container app d-flex justify-content-center align-items-center">
        <div className="login-card">
          <Row>
            <Col md="4" className="bg-dark">
              <div className="login-logo">
                <Icon width={25} height={25} />
                <img
                  className="light-text-color ml-2"
                  src="/img/callan_logo.svg"
                  width="93px"
                  height="27px"
                  alt="callan_logo"
                />
                <h5 className="mini text-gray-50">Applications</h5>
              </div>
            </Col>
            <Col md="8" className="bg-white p-3 pb-5 pt-5">
              <p className="intro text-gray-80 mb-0"><strong>Callan</strong><i>DNA</i></p>
              <p className="intro text-gray-80">You must be signed in to access this page</p>
              <Button
                color="primary"
                className="col-md-6 justify-space-between"
                size="md"
                onClick={login.bind(this)}
              >
                Sign In
                <FontAwesomeIcon
                  icon="chevron-right"
                  size="xs"
                  className="ml-2"
                />
              </Button>
              <p className="intro text-gray-80 mb-0 mt-2">By logging in you are agreeing to our <a href="https://www.callan.com/dna-service-terms/" target="_blank" rel="noreferrer">Terms and Conditions</a>, updated October 2023.</p>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default Login

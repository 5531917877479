import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { History, Location } from 'history'
import React, { useContext, useState } from 'react'
import { match, useHistory } from 'react-router-dom'
import { Button, ButtonDropdown, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { ImportReportListColumnDef, PlanModalColumnDef, ReportListColumnDef } from "../../helpers/columnDefs"
import { AccountTypeCode, FileFullDetailsFragment, PlanFilters, ReportFilters, ReportSimpleFragment, useCreateReportMutation, useMeDepartmentQuery, usePlanListQuery, useReportListQuery, CreateReportInput, ReportCategoryCode, PlanSimpleFragment, useMeQuery, ReportSubcategoryCode, TrainingFrequencyCode } from '../../__generated__/graphql'
import SortableTable from "../Shared/SortableTable"
import { WithTopNav } from '../ui/LayoutWrapper'
import PlaceHolder from '../ui/PlaceHolder'
import { compact, filter, sortBy } from 'lodash';
import { FormInput } from '../ui/Forms/FormInput';
import { getRecentReports } from '../../helpers/session';
import { appDate } from '../../Context/CalendarContext';
import EditButtons from '../ui/EditButtons';
import { TemporaryAlertContext } from '../../Context/TemporaryAlertContext';

type ReportListProps = {
  history: History
  location: Location
  auth: Auth
  match: match
}

export type documentTableData = {
  clientId: number
  reportId: number
  associations: string[]
} & FileFullDetailsFragment

enum ModalType {
  closed = "closed",
  fund = "fund",
  executive = "executive",
}

const ReportList: React.FC<ReportListProps> = ({ history, location, auth, match }) => {
  const authAccess = auth.checkPermissions(["edit:lists"])
  if(!authAccess){
    history.replace('/')
  }

  // const [addListModalOpen, setAddListModalOpen] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  // const addFileInputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState("")
  const [newReportDropdownOpen, setNewReportDropdownOpen] = useState(false)
  const [openModalType, setOpenModalType] = useState<ModalType>(ModalType.closed)
  // used so that filter changes update the grid
  const [filterUpdates, setFilterUpdates] = useState(0)
  // const { resetErrors } = useContext(EditButtonContext)

  const { loading:userLoading, error:userError, data:userData } = useMeDepartmentQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  // const [favoriteList] = useFavoriteListMutation()
  // const [unfavoriteList] = useUnfavoriteListMutation()

  let reportFilter: ReportFilters = {limit: 1000}
  // if(!showAll && user?.person){
  //   const person = user.person
  //   reportFilter.owner = [person?.id]
  //   reportFilter.people = [person?.id]
  //   reportFilter.orgs = compact(user.appMetadata?.firms)
  //   reportFilter.departments = person.department?.code ? [parseInt(person.department.code.substring(1))] : []
  // }

  const { loading, data, error } = useReportListQuery({
    fetchPolicy: "cache-and-network",
    variables: { filters: reportFilter },
  })

  // const selectList = (id:string) => {
  //   resetErrors()
  //   history.push(`/lists/${id}`)
  // }

  // const openFileModal = () => {
  //   setAddListModalOpen(!addListModalOpen)
  //   addFileInputRef?.current?.click()
  // }

  const handleShowAll = (value:boolean) => {
    if(gridApi){
      if(value){
        gridApi.setFilterModel({})
      } else {
        gridApi.setFilterModel({
          'owner': {
            filterType: 'set',
            values: [`${user?.person?.firstName} ${user?.person?.lastName}`]
          }
        })
      }
    }
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const heading = (
    <>
      <div className="mb-4">
        <h2 className="headline">Reports</h2>
      </div>
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="pr-2 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Find Reports by name"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="wide-search"
              onKeyDown={handleEnterKeyDown}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        {/* <div className="border-right">
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={openFileModal} disabled={!data}>
            New Report
          </Button>
        </div> */}
        <FormInput
          property={"showAll"}
          type={"checkbox"}
          subtype={"boolean"}
          label={"Show All Reports"}
          idx={1}
          editMode={true}
          propertyVal={Object.keys(gridApi?.getFilterModel() || {}).length === 0}
          updateValue={(value) => handleShowAll(value)}
          subClasses={{
            wrapperClasses: "no-gutters mx-3 pr-2 flex-basis-160 border-right",
            inputWrapperClasses: "col-sm-3",
            labelClasses: "col-sm-9",
          }}
        />
        <ButtonDropdown isOpen={newReportDropdownOpen} toggle={()=> setNewReportDropdownOpen(!newReportDropdownOpen)} className="mr-1">
          <DropdownToggle caret>
            New Report
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem disabled onClick={() => setOpenModalType(ModalType.fund)}>Fund Profile...</DropdownItem>
            <DropdownItem onClick={() => setOpenModalType(ModalType.executive)}>Executive Summary...</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    </>
  )

  if ((loading) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data && user) {
    const toggleFavorite = (row:ReportSimpleFragment) => {
      // if(!userData?.me?.person?.id || !row.id){
      //   return
      // }
      // const favoriteInput:ListFavoriteInput = {
      //   listIds: [row.id],
      //   personId: userData.me.person.id,
      // }
      // const favoriteFunction = row.isCurrentUsersFavorite ? unfavoriteList : favoriteList
      // favoriteFunction({
      //   variables: {input: favoriteInput},
      //   update: (cache) => {
      //     cache.writeFragment({
      //       id: `List:${row.id}`,
      //       fragment: gql`
      //         fragment ListWithFavorite on List {
      //           isCurrentUsersFavorite
      //         }
      //       `,
      //       data: {
      //         isCurrentUsersFavorite: !row.isCurrentUsersFavorite,
      //         __typename: "List"
      //       },
      //     })
      //   },
      // })
    }
    let colDef = ReportListColumnDef(toggleFavorite, user, auth.checkPermissions(["edit:lists"]))
    const onReady = (event: GridReadyEvent) => {
      setGridApi(event.api)
      event.api!.setFilterModel({
        'owner': {
          filterType: 'set',
          values: [`${user.person?.firstName} ${user.person?.lastName}`]
        }
      })
    }
    const filteredReports = filter(data.reports, (r) => r.active)
    const sortedReports = sortBy(filteredReports, (report) => {
      return [report.owner?.id === user?.person?.id ? 0 : 1]
    })

    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column">
        {heading}
        <SortableTable
          loading={loading}
          filterText={search}
          columnDefs={colDef}
          tableData={sortedReports}
          rowId={"id"}
          onReady={onReady}
          onFilterChanged={() => setFilterUpdates(filterUpdates + 1)}
        />
        <NewReportModal
          modalOpen={openModalType !== ModalType.closed}
          toggleModal={() => setOpenModalType(ModalType.closed)}
          modalType={openModalType}
        />
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

interface NewReportModalProps {
  modalOpen: boolean
  toggleModal: () => void
  modalType: ModalType
}

export const NewReportModal: React.FC<NewReportModalProps> = (props) => {
  const { modalOpen, toggleModal, modalType } = props
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const [search, setSearch] = useState("")
  const [saving, setSaving] = useState(false)
  const [errorStr, setErrorStr] = useState("")
  const history = useHistory()
  const { addAlert } = useContext(TemporaryAlertContext)

  const [createReportMutation] = useCreateReportMutation()
  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })

  let planFilter: PlanFilters = {limit: 5_000, accountType: [AccountTypeCode.CUST, AccountTypeCode.FCUST]}

  const { loading, data, error } = usePlanListQuery({
    fetchPolicy: "cache-and-network",
    variables: { filters: planFilter },
    errorPolicy: "all",
  })

  let colDef = PlanModalColumnDef()
  const onReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    event.api!.setFilterModel({
      'accountType.code': {
        filterType: 'set',
        values: [`Active`]
      }
    })
  }

  const sortedPlans = compact(sortBy(data?.plans, (plan) => {
    return plan?.name?.toLowerCase()
  }))

  const createReport = () => {
    setSaving(true)
    // TODO fix this to actually create a report
    const selectedPlan = gridApi?.getSelectedRows()[0] as PlanSimpleFragment
    // if(selectedReport) {
    //   setSelectedReport(selectedReport)
    //   setModalType(ModalType.ChooseComponent)
    // }
    const createReportInput:CreateReportInput = {
      active: true,
      category: modalType === ModalType.fund ? ReportCategoryCode.FPROF : ReportCategoryCode.EXEC,
      client: selectedPlan.client?.id || 0,
      // contacts: [],
      // dataEntryAnalyst: Int,
      // dueDate: appDate.format(DATE_API_FORMAT),
      // exportSettings: ReportExportSettingsInput,
      frequency: TrainingFrequencyCode.QTR,
      name: `${selectedPlan.shortName || selectedPlan.name} ${modalType === ModalType.fund ? "Fund Profile" : "Executive Summary"}`,
      owner: userData?.me?.person?.id || 0,
      plans: [selectedPlan.id],
      shortName: `${selectedPlan.shortName || selectedPlan.name} ${modalType === ModalType.fund ? "Fund Profile" : "Executive Summary"}`,
      subCategory: ReportSubcategoryCode.QTR,
      template: 1,
    }

    createReportMutation({ variables: { input: createReportInput } })
      .then((result) => {
        setSaving(false)
        history.push(`/reports/${result.data?.createReport?.report?.id}`)
      })
      .catch((error) => {
        console.error(error.message)
        addAlert({title: "Error | Creating Report.", message: error.message, color: "error"})
        setSaving(false)
      })
  }

  return (<Modal
    size="lg"
    className="mt-5"
    isOpen={modalOpen}
    toggle={toggleModal}
    zIndex={1500}
    key="modal"
  >
    <ModalHeader className='full-width-header'>
      <div className="d-flex justify-content-between w-100">
        <div>
          New Executive Summary Report
        </div>
        <div onClick={toggleModal}>
          <FontAwesomeIcon
            icon="times"
            className="ml-auto"
          />
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div>
        <h4>Choose plan for this report.</h4>
        <div>
          <FormGroup row className="relative no-gutters">
            <Input
              type="text"
              placeholder="Find by plan name or ID"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="wide-search m-0"
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </div>
        <SortableTable
          loading={loading}
          filterText={search}
          columnDefs={colDef}
          tableData={sortedPlans}
          rowId={"id"}
          singleRowSelection={true}
          onReady={onReady}
          subClasses={{tableContainerClasses: "short-table"}}
          // onFilterChanged={() => setFilterUpdates(filterUpdates + 1)}
        />
      </div>
    </ModalBody>
    <ModalFooter>
      <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => toggleModal()} saving={saving} onSubmit={createReport} saveText={"Create Report"}/>
    </ModalFooter>
  </Modal>
  )
}


export default WithTopNav(ReportList)

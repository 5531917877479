import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import { History } from "history"
import _ from "lodash"
import moment from "moment"
import numbro from 'numbro'
import React, { Component, RefObject, useContext, useRef, useState } from "react"
import {
  match, Route, RouteComponentProps, Switch, useHistory, useParams, useRouteMatch
} from "react-router-dom"
import {
  Button, Col, Container, Form,
  FormGroup,
  Input, ListGroupItem, Modal, ModalBody,
  ModalFooter, ModalHeader, Row
} from "reactstrap"
import Auth from '../../Auth/Auth'
import { AgGridContext } from "../../Context/AgGridContext"
import { CalendarContext } from "../../Context/CalendarContext"
import { EditButtonContext, SetOptionProps } from "../../Context/EditButtonContext"
import exportTables from "../../helpers/exportTable"
import { isGlidePathVersionActive as isVersionActive, } from "../../helpers/helpers"
import {
  convertLookupToString,
  excludePropertyArray
} from "../../helpers/object"
import {
  GlidePathVersion, UpdateGlidePathVersionInput, useGlidePathVersionsQuery, useUpdateGlidePathVersionMutation
} from "../../__generated__/graphql"
import { CalendarPicker } from '../CalendarPicker'
import { OptionItem, Picker } from "../Shared/Picker"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import EditButtons from '../ui/EditButtons'
import PlaceHolder from "../ui/PlaceHolder"
import GlidePathVersionDetail from "./GlidePathVersionDetail"

interface GlidePathSeriesProps extends RouteComponentProps {
  glidepathId: number
  auth: Auth
}

const GlidePathSeries: React.FC<GlidePathSeriesProps> = ({
  glidepathId,
  auth,
}: GlidePathSeriesProps) => {
  const context = useContext(CalendarContext)
  const gridContext = useContext(AgGridContext)
  const [searchYear, setSearchYear] = useState(moment(context.year).year())
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [search, setSearch] = useState("")
  const [modal, setModal] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const resultRef = useRef<GlidePathVersionDetail>(null)
  const [updateVersion] = useUpdateGlidePathVersionMutation()
  let match = useRouteMatch()
  const history = useHistory()
  let { data, loading, error } = useGlidePathVersionsQuery({
    variables: {
      id: glidepathId,
      // startDate: appDate.format(DATE_API_FORMAT),
      // endDate: appDate.format(DATE_API_FORMAT),
    },
  })
  const { resetErrors, setOptions } = useContext(EditButtonContext)


  // let { data: productList, loading: productLoading, error: productError } = useManagerProductListQuery({ variables: {id}})
  // console.log(productList)

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const updateStateFromResult = (result: any) => {
    setSaving(false)
    if (result && result.data) {
      let unformattedNewData = _.cloneDeep(result.data.glidePathVersion || {}) //reShapeObject(
      //   _.cloneDeep(result.data.person || {}),
      //   PreShapeActions
      // )
      setEditMode(false)
      // if (newPerson != "") {
      //   setNewPerson("")
      //   history.push(`${match.path}/${result.data.person?.id}`)
      // }
      _.set(unformattedNewData, "active", isVersionActive(unformattedNewData?.terminationDate))
      resultRef!.current?.setState({
        currentState: unformattedNewData,
        initialState: unformattedNewData,
      })
    }
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    let currentVersion = resultRef!.current?.state.currentState
    let initialVersion = resultRef!.current?.state.initialState
    if (!currentVersion) {
      return
    }
    if (!initialVersion) {
      return
    }
    setSaving(true)

    let formattedData = convertLookupToString(currentVersion, false, [
      "GlidePathImplementationPhilosophyLookup",
      "GlidePathPhilosophyLookup",
    ])

    formattedData.activePassiveExposure = _.find(formattedData.activePassiveExposure, (o) => o.year === searchYear)
    formattedData.proprietaryNonProprietary = _.find(formattedData.proprietaryNonProprietary, (o) => o.year === searchYear)
    formattedData.tacticalAssetAllocation = _.find(formattedData.tacticalAssetAllocation, (o) => o.year === searchYear)

    const formattedRemoveNull = formattedData //_.pickBy(formattedDataRemove, _.identity);

    // TODO: Stop removing proprietaryNonProprietary, activePassiveExposure, tacticalAssetAllocation
    const updateData = {
      id: currentVersion.id,
      patch: excludePropertyArray(formattedRemoveNull, ["__typename", "active", "latestAum", "id", "series", "value"]),
    } as UpdateGlidePathVersionInput

    updateVersion({ variables: { input: updateData } })
      .then(updateStateFromResult)
      .catch((err) => {
        setSaving(false)
        console.log("Error updateGlidePathSeries", err.message)
        // throw new Error(`${err.message}`)
      })
  }

  const createNewPerson = () => {
    if (search !== "") {
      setSearch("")
      setEditMode(true)
      history.push(`${match.path}/new`)
      setShowCreate(false)
    } else {
      setShowCreate(true)
    }
  }

  const openPopover = () => {
    if (search !== "") {
      setModal(true)
      setShowCreate(false)
    } else {
      setShowCreate(!showCreate)
    }
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  function onBtnExport() {
    gridContext.agGrid!.exportDataAsCsv();
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      {/* <Button
        color="light btn-thin"
        className="mr-1"
        onClick={openPopover}
        id={"create-series-button"}
      >
        <FontAwesomeIcon icon="plus-circle" />
      </Button>
      <Popover
        placement={"top"}
        isOpen={showCreate}
        target={"create-series-button"}
        toggle={() => setShowCreate(!showCreate)}
      >
        <PopoverHeader>
          Add Series
          <Button className="close" onClick={() => setShowCreate(!showCreate)}>
            &times;
          </Button>
        </PopoverHeader>
        <PopoverBody>
          Enter name into search bar and click create to add a new Series.
        </PopoverBody>
      </Popover> */}
      <Form className="mr-2 pr-3 border-right">
        <FormGroup row className="relative m-0 mr-1">
          <Input
            type="text"
            placeholder="Search Results"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyDown={handleEnterKeyDown}
          />
          {search === "" && (
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          )}
          {false && search !== "" && (
            <Button
              className="absolute center-v right-1 pt-1 pb-1 pl-2 pr-2"
              color="primary"
              onClick={openPopover}
            >
              Create
            </Button>
          )}
          <Modal
            isOpen={modal}
            toggle={() => {
              setModal(!modal)
            }}
            zIndex={1500}
          >
            <ModalHeader
              toggle={() => {
                setModal(!modal)
              }}
            >
              Create New Series?
            </ModalHeader>
            <ModalBody>
              Are you sure you want to create <b>{search}</b> as a new series.
              Series cannot be deleted.
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  setModal(!modal)
                }}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button color="primary" onClick={createNewPerson}>
                Create New Series
              </Button>
            </ModalFooter>
          </Modal>
        </FormGroup>
      </Form>
      {showCalendar &&
        <CalendarPicker
          updateValue={(searchYear) => setSearchYear(moment(searchYear).year())}
          editMode={editMode}
          setEditMode={(value:boolean) => setEditMode(value)}
          onlyYear={true}
          defaultPeriod={"year"}
        />
      }
      <Button color="secondary btn-thin" className="ml-3 text-callan-blue" onClick={()=> gridContext.agGrid ? onBtnExport() : exportTables()}>
        Export CSV
        <img src='/assets/CSV.svg' className="ml-2"/>
      </Button>  
      {auth.checkPermissions(["edit:manager"]) &&
        <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} hideOnContext={true}/>
      }
    </div>
  )

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (data && data.glidePath && data.glidePath.versions) {
    let versions = _.cloneDeep(data.glidePath.versions) as GlidePathVersion[]
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Result
              detailRef={resultRef}
              editMode={editMode}
              versions={versions}
              search={search}
              match={match}
              history={history}
              searchYear={searchYear}
              setShowCalendar={(value:boolean) => setShowCalendar(value)}
              resetErrors={resetErrors}
              setOptions={setOptions}
              auth={auth}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

interface Props {
  versions: any
  editMode: boolean
  search: string
  detailRef: RefObject<GlidePathVersionDetail>
  match: any
  history: any
  searchYear: number
  setShowCalendar: (value:boolean) => void
  resetErrors: () => void
  setOptions: ({showButtons}:SetOptionProps) => void
  auth: Auth
}

class Result extends Component<Props> {
  render() {
    return (
      <Row>
        <Switch>
          <Route path={`${this.props.match.path}/:versionId?`}>
            <VersionHolder
              detailRef={this.props.detailRef}
              versions={this.props.versions}
              editMode={this.props.editMode}
              search={this.props.search}
              history={this.props.history}
              match={this.props.match}
              searchYear={this.props.searchYear}
              setShowCalendar={this.props.setShowCalendar}
              key={"test"}
              resetErrors={this.props.resetErrors}
              setOptions={this.props.setOptions}
              auth={this.props.auth}
            />
          </Route>
        </Switch>
      </Row>
    )
  }
}

function VersionHolder({
  detailRef,
  versions,
  editMode,
  search,
  history,
  match,
  searchYear,
  setShowCalendar,
  resetErrors,
  setOptions,
  auth,
}: any) {
  let { versionId } = useParams() as {versionId?: string}
  if (versionId === "new") {
    versionId = "0"
  }
  const version = _.find(versions, (o) => {
    return o.id == versionId
  })
  if (!version) {
    return (
      <>
        <VersionPicker
          versions={versions}
          search={search}
          history={history}
          match={match}
          setShowCalendar={setShowCalendar}
          resetErrors={resetErrors}
        />
        <Col md="8" lg="9" className="pl-1">
          <div className="pane">
            <Row>
              <Col>
                <h3>No Series Selected</h3>
              </Col>
            </Row>
          </div>
        </Col>
      </>
    )
  }

  let formattedData =_.cloneDeep(version)
  _.set(formattedData, "active", isVersionActive(version?.terminationDate))

  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={(path) => history.push(path)}
      />
      <VersionPicker
        versions={versions}
        search={search}
        history={history}
        match={match}
        setShowCalendar={setShowCalendar}
        selectedVersion={version}
        resetErrors={resetErrors}
      />
      <GlidePathVersionDetail
        ref={detailRef}
        version={formattedData}
        editMode={editMode}
        key={versionId}
        searchYear={searchYear}
        setShowCalendar={setShowCalendar}
        setOptions={setOptions}
        auth={auth}
      />
    </>
  )
}

interface VersionPickerProps {
  versions: GlidePathVersion[]
  search: string
  history: History
  match: match
  setShowCalendar: (value:boolean) => void
  selectedVersion?: GlidePathVersion
  resetErrors: () => void
}

interface VersionPickerState {
  pickerFilters: OptionItem[]
  activeFilters: number[]
  pickerSortOptions: OptionItem[]
  activeSort: number
}

export class VersionPicker extends Component<VersionPickerProps> {
  state: VersionPickerState
  constructor(props: VersionPickerProps) {
    super(props)
    let pickerFilters: OptionItem[] = []
    let activeFilters: number[] = []
    pickerFilters = [
      {
        id: 1,
        name: "Active",
      }
    ]
    activeFilters = [1]
    this.state = {
      pickerFilters,
      activeFilters: activeFilters,
      pickerSortOptions: [
        {
          id: 1,
          name: "Name ( A to Z )",
        },
        {
          id: 2,
          name: "Name ( Z to A )",
        },
      ],
      activeSort: 1,
    }
    if(!this.props.selectedVersion){
      const sortedObjects = this.sortVersions()
      if(sortedObjects.length > 0){
        this.props.history.push(`${this.props.match.url}/${_.get(_.first(sortedObjects),"id")}`)
      }
    }
  }

  onFilter = (filterId: number) => {
    const { activeFilters } = this.state
    const index = activeFilters.indexOf(filterId)
    if (index === -1) {
      activeFilters.push(filterId)
    } else {
      activeFilters.splice(index, 1)
    }
    this.setState({ activeFilters })
  }

  onSort = (sortId: number) => {
    this.setState({ activeSort: sortId })
  }

  sortVersions = (): GlidePathVersion[] => {
    const { versions, search } = this.props
    const { activeFilters, activeSort } = this.state
    return versions
      .filter((version: GlidePathVersion) => {
        if (search) {
          const name = (version.name || "").toLocaleLowerCase().trim()
          if (!name.includes(this.props.search.toLowerCase().trim()))
            return false
        } else if (activeFilters.length > 0) {
          if (
            activeFilters.indexOf(1) !== -1 &&
            !!version.terminationDate && moment().isAfter(version.terminationDate)
          )
            return false
        }
        return true
      })
      .sort((versionA: GlidePathVersion, versionB: GlidePathVersion) => {
        const nameA = (versionA.name || "").toLocaleLowerCase().trim()
        const nameB = (versionB.name || "").toLocaleLowerCase().trim()
        switch (activeSort) {
          case 1:
            return nameA.localeCompare(nameB)
          case 2:
            return nameB.localeCompare(nameA)
          default:
            throw new Error(`unrecognized sort choice ${activeSort}`)
        }
      })
  }

  versionListItem = (version: GlidePathVersion) => {
    return (
      <ListGroupItem
        tag="a"
        key={version.id}
        className={classnames({
          active: this.props.selectedVersion?.id === version.id,
        })}
        onClick={() =>{
            this.props.resetErrors()
            if(this.props.history.location.pathname !== `${this.props.match.path}/${version.id}`) this.props.setShowCalendar(false)
            this.props.history.push(`${this.props.match.path}/${version.id}`)
          }
        }
      >
        <h5>
          {version.name}
        </h5>
        <dl>
          {version.implementationPhilosophy && (
            <>
              <dt>Implementation:</dt>
              <dd>{version.implementationPhilosophy.value}</dd>
            </>
          )}
          {version.latestAum?.aum && (
            <>
              <dt>AUM:</dt>
              <dd>{ `${numbro(version.latestAum.aum || 0).format("$0,0.00")}M`}</dd>
            </>
          )}
          {version.inceptionYear && (
            <>
              <dt>Inception:</dt>
              <dd>{version.inceptionYear}</dd>
            </>
          )}
        </dl>
        <div className="list-group-item-status">
          {!isVersionActive(version.terminationDate) ? "Inactive" : "Active"}
        </div>
      </ListGroupItem>
    )
  }

  emptyMessage() {
    return `There are no series associated with this target date fund with the current filters. Please adjust your filters.`
  }

  render() {
    const { search } = this.props
    const {
      pickerFilters,
      activeFilters,
      pickerSortOptions,
      activeSort,
    } = this.state
    const sortedVersions = this.sortVersions()
    return (
      <Picker
        filters={pickerFilters}
        activeFilters={activeFilters}
        sortOptions={pickerSortOptions}
        activeSort={activeSort}
        onFilter={this.onFilter}
        onSort={this.onSort}
        searching={search ? true : false}
      >
        {sortedVersions.length > 0 && sortedVersions.map((version) => this.versionListItem(version))}
        {sortedVersions.length === 0 &&
          <div className="p-3 text-gray-70">
            {this.emptyMessage()}
          </div>
        }
      </Picker>
    )
  }
}


export default GlidePathSeries

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Button, Table } from "reactstrap"

import {
  Maybe
} from "../../__generated__/graphql"
import {
  FormInputSubClasses,
  GeneralPartnersCommitmentUnion
} from "../../helpers/constant"
import { getNewStateObject } from "../../helpers/helpers"
import { FormInput } from "../ui/Forms/FormInput"

type GPTableProps = {
  data: Maybe<GeneralPartnersCommitmentUnion>[]
  handleChange: (state: any) => void
  editMode: boolean
}

type columnDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  optionSource?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  required?: boolean
  tdClass?: string
  tooltip?: string
}

interface GPTableRowProps {
  data: any
  columnDef: columnDefItem[]
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

const GPTableColumnDef: columnDefItem[] = [
  {
    field: "participantType.code",
    type: "select",
    title: "Participant Type",
    optionSource: "ParticipantTypeCode",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    tdClass: "medium",
    tooltip: "participantType"
  },

  {
    field: "legalName",
    type: "text",
    title: "Legal Name/Entity",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    tdClass: "wide",
    required: true,
    tooltip: "legalName"
  },
  {
    field: "currentTitle",
    type: "text",
    title: "Current Title",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    tooltip: "currentTitle"
  },
  {
    field: "investmentTeamMember",
    type: "checkbox",
    subtype: "boolean",
    title: "Investment Team Member",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "justify-content-center col-sm-12"
    },
    required: true,
    tooltip: "investmentTeamMember"
  },
  {
    field: "carriedInterest",
    type: "float",
    title: "% Carried Interest",
    subtype: "percent",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    }
  },
  {
    field: "sponsorCommitType.code",
    type: "select",
    title: "Sponsor Commit Cash/Non-Cash",
    optionSource: "SponsorCommitTypeCode",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    tooltip: "sponsorCommitCash"
  },
  {
    field: "percentOfLimitedPartnersCommitment",
    type: "float",
    title: "Sponsor Commit (% of Total Fund LP Commitments)",
    subtype: "percent",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    tooltip: "sponsorCommitPercent"
  },
  {
    field: "percentOfSponsorCommitment",
    type: "float",
    title: "Mgmt Co Commit (% of Total Sponsor Commitments)",
    subtype: "percent",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    tooltip: "mgmtCommitPercent"
  }
]

const GPTableRow = ({
  data,
  columnDef,
  idx:rowIdx,
  editMode,
  action,
  updateValue
}: GPTableRowProps) => {
  return (
    <tr
      className={
        editMode
          ? "full-width hover-actions editing"
          : "full-width hover-actions"
      }
    >
      {columnDef.map((header, idx) => {
        return (
          <td key={`row-${rowIdx}-${header.field}`} className={header.tdClass || ""}>
            <FormInput
              key={`input-${rowIdx}-${header.field}`}
              property={header.field}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={`input-${rowIdx}-${header.field}`}
              editMode={editMode}
              propertyVal={_.get(data, header.field)}
              optionSource={header.optionSource}
              placeholder={"-"}
              updateValue={value =>
                updateValue(value, header.type, header.field)
              }
              required={header.required}
              subClasses={header.subClasses}
            />
          </td>
        )
      })}
      {editMode && (
        <td className={`actions`}>
          <Button
            color="link"
            size="sm"
            className="ml-auto"
            onClick={() => action.remove(rowIdx)}
          >
            <FontAwesomeIcon icon="trash" className="ml-2 text-blue-100" />
          </Button>
        </td>
      )}
    </tr>
  )
}

const GPTableDisplay = (seed: GPTableProps) => {
  const tableName = "GP-Table"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)
  useEffect(() => {
    setData(seed.data)
  }, [seed.data])

  const onAddGPTableRow = (row: GeneralPartnersCommitmentUnion) => {
    const newData = [...tableState, row] as GeneralPartnersCommitmentUnion[]
    setData(newData)
    handleChange(newData)
  }
  const onRemoveGPTableRow = (colIndex: number) => {
    let newData = [
      ...tableState.slice(0, colIndex),
      ...tableState.slice(colIndex + 1)
    ]
    setData(newData)
    handleChange(newData)
  }

  const actionMap = {
    add: onAddGPTableRow,
    remove: onRemoveGPTableRow
  }

  const onHandleChangeGPTableRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = tableState.map((el, index) => {
      if (idx === index) {
        return getNewStateObject({
          state: el as { [name: string]: any },
          newValue: value,
          property,
          type
        }) as GeneralPartnersCommitmentUnion
      } else {
        return el
      }
    })
    setData(newData)
    handleChange(newData)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size py-2 mb-2"
        }
        key={`${tableName}-0`}
      >
        {`GP Commitments`}
      </div>

      <div
        className={classNames(
          `${tableName}-table row form-group pl-0`,
          {
            "col-sm-12": editMode,
            "col-sm-11": !editMode
          },
          "table-container"
        )}
      >
        <Table striped hover borderless size="sm" key={`${tableName}-table`} className={"exportable"} data-export-name={"GP Commitments"}>
          <thead className="">
            <tr className="table-header">
              {GPTableColumnDef.map((header, index) => {
                const tooltip = header.tooltip
                return(
                  <th key={`${tableName}-header-${index}`}>
                    <div className="position-relative" id={tooltip ? `${tooltip}TooltipContainer` : undefined}>
                      {header.title}
                      {tooltip &&
                        <div className="d-inline-flex align-items-center tooltip-icon" id={`${tooltip}Tooltip`}>
                          <FontAwesomeIcon
                            icon={"question-circle"}
                            size="sm"
                          />
                        </div>
                      }
                    </div>
                  </th>
                )
              })}
              {editMode && (
                <th key={`Performance-header-edit`}>
                  <Button
                    color="link"
                    className="ml-auto"
                    onClick={() =>
                      onAddGPTableRow({
                        participantType: null,
                        investmentTeamMember: false,
                        legalName: ""
                      } as any)
                    }
                  >
                    Add Row
                    <FontAwesomeIcon
                      icon="plus-circle"
                      className="ml-2 text-blue-100"
                    />
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody key={0}>
            {tableState.map((row: any, idx: number) => {
              return (
                <GPTableRow
                  columnDef={GPTableColumnDef}
                  data={row}
                  key={idx}
                  idx={idx}
                  editMode={editMode}
                  action={actionMap}
                  updateValue={(value, type, property) =>
                    onHandleChangeGPTableRow(idx, value, type, property)
                  }
                />
              )
            })}
            <tr
              key={`{tableName}-${tableState.length}`}
              className={`border-top pl-0`}
            ></tr>
          </tbody>
        </Table>
        <div
          className="border-bottom-line-break row my-2"
          key={"Performance"}
        ></div>
      </div>
    </>
  )
}

export default GPTableDisplay

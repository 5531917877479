import moment from "moment"
import { DATE_API_FORMAT } from "../../../helpers/constant"

export const formatCurrency = (number: number, editing: boolean = false) => {
  if (editing) {
    return number.toString()
  } else {
    return Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(number)
  }
}

export const formatPercent = (
  number: number | string,
  editing: boolean = false
) => {
  if (editing) {
    if (typeof number === "string") {
      return number
    } else return number.toString()
  }
  let value: number
  if (typeof number === "string") {
    if (!number) return number
    value = +number
    if (isNaN(value)) return number
  } else {
    value = number
  }
  return Intl.NumberFormat("en-us", {
    style: "percent",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }).format(value)
}

export const previousQuarterEnd = moment()
  .subtract(1, "quarter")
  .endOf("quarter")
  .format(DATE_API_FORMAT)

export const checkIfSet = (value: any) =>
  !(value === undefined || value === null)

export const getMaxDate = (dates?: Array<{ date?: string } | null> | null) => {
  if (!dates || dates.length === 0) return previousQuarterEnd
  let filtered = dates.filter((date) => date !== null && date.date) as Array<{
    date: string
  }>
  let maxDate = filtered[0].date
  filtered.forEach((date) => {
    if (date.date > maxDate) {
      maxDate = date.date
    }
  })
  return maxDate
}

import React from 'react'
import {Prompt} from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface RouteGuardProps {
  when: boolean
  shouldBlockNavigation?: (path:string) => boolean
  navigate: (path:string) => void
}

interface RouteGuardState {
  modalVisible: boolean
  lastLocation: { pathname: string }
  confirmedNavigation: false
}

export default class RouteLeavingGuard extends React.Component<RouteGuardProps, any> {
  constructor (props:RouteGuardProps) {
    super(props)

    this.state = {
      modalVisible: false,
      lastLocation: {pathname: ""},
      confirmedNavigation: false,
    }
  }


  static getDerivedStateFromProps(nextProps:RouteGuardProps, prevState:RouteGuardState){
    if(!nextProps.when){
      return { confirmedNavigation: false }
    }
    return null;
  }

  showModal = (location: any) => this.setState({
    modalVisible: true,
    lastLocation: location,
  })
  closeModal = (callback?: (() => void) | undefined) => this.setState({
    modalVisible: false
  }, callback)
  handleBlockedNavigation = (nextLocation: any) => {
    const {confirmedNavigation} = this.state
    const {shouldBlockNavigation} = this.props
    if (!confirmedNavigation && (!shouldBlockNavigation || shouldBlockNavigation(nextLocation))){
        this.showModal(nextLocation)
        return false
    }

    return true
  }
  handleConfirmNavigationClick = () => this.closeModal(() => {
    const {navigate} = this.props
    const {lastLocation} = this.state
    if (lastLocation) {
      this.setState({
        confirmedNavigation: true
      }, () => {
        // Navigate to the previous blocked location with your navigate function
        navigate(lastLocation.pathname)
        this.setState({confirmedNavigation: false})
      })
    }
  })
  componentDidMount = () => {
    window.addEventListener('beforeunload', this.prompt)
  }
  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.prompt)
  }
  prompt = (event:any) => {
    if (this.props.when) {
      event.preventDefault();
      event.returnValue = 'Test';
    }
  }
  render() {
   const {when} = this.props
   const {modalVisible} = this.state
   return (
     <>
        <Prompt
          when={when}
          message={this.handleBlockedNavigation}
          // message={"If you leave this page unsaved changes will be lost."}
        />
        <GuardModal
          open={modalVisible}
          stay={() => this.closeModal()}
          leave={this.handleConfirmNavigationClick}
        />
     </>
   )
 }
}

export type ModalScript = {header: string, body: string, leaveButtonContent: string, stayButtonContent: string}

/**
 * default Script for RouteLeavingGuard
 */
const guardModalScript: ModalScript = {
  header: "Warning",
  body: "If you leave this page unsaved changes will be lost.",
  leaveButtonContent: "Leave Page",
  stayButtonContent: "Stay on Page"
}

interface GuardModalProps {
  open: boolean
  stay: () => void
  leave: () => void
  script?: ModalScript
  hide?: {leaveButton?: boolean, stayButton?: boolean}
}

export const GuardModal = ({open, stay, leave, script, hide}:GuardModalProps) => {
  let {header, body, leaveButtonContent, stayButtonContent} = script || guardModalScript
  let hideLeave = !!hide?.leaveButton
  let hideStay = !!hide?.stayButton
  return(
    <Modal isOpen={open} zIndex={1500}>
      <ModalHeader toggle={stay}>{header}</ModalHeader>
      <ModalBody>
        {body}
      </ModalBody>
      <ModalFooter>
        {!hideLeave &&
        <Button color="secondary" onClick={leave} className="mr-2">{leaveButtonContent}</Button>}
        {!hideStay &&
        <Button color="primary" onClick={stay}>{stayButtonContent}</Button>}
      </ModalFooter>
    </Modal>
  )
}

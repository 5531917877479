import _ from 'lodash'
import { Interaction, InteractionFragment, InteractionNoteDetailFragment, InteractionNoteInput, Maybe, MeFragment, OrganizationInteractionNotesFragment, OrgInteractionNoteFields, ProductInteractionNoteFields, ProductInteractionNotesFragment } from '../__generated__/graphql'

type InteractionType = {
  __typename: "Interaction"
} & InteractionFragment

export const getManagers = (data: Maybe<InteractionType>) => {
  if (!data) {
    return []
  }
  let managerList = []
  managerList.push(data.primaryManagerAssociation)
  managerList = managerList.concat(data.otherManagerAssociations)
  return managerList
}

/**
 * Convert InteractionNoteDetailFragment to InteractionNoteInput
 */
 export const toInteractionNoteInput = (note: Maybe<InteractionNoteDetailFragment> | undefined) => {
  return (note && note.author) ?
  {
    body: note.body || "",
    author: note.author.id,
  } as InteractionNoteInput
  : undefined
}

/**
* Convert OrganizationInteractionNotesFragment to OrgInteractionNoteFields
*/
export const toOrgInteractionNoteFields = (notes: Maybe<OrganizationInteractionNotesFragment> | undefined, hasInternalOpinion?: boolean) => {
  const fields: OrgInteractionNoteFields = {
    summary: toInteractionNoteInput(notes?.summary),
    observation: toInteractionNoteInput(notes?.observation),
  }
  if (hasInternalOpinion){
    fields.internalOpinion = toInteractionNoteInput(notes?.internalOpinion)
  }
  return fields
}

/**
 * Convert ProductInteractionNotesFragment to ProductInteractionNoteFields
 */
export const toProductInteractionNoteFields = (notes: ProductInteractionNotesFragment, hasInternalOpinion?: boolean) => {
  if (!notes?.product?.product?.id) return undefined
  const fields: ProductInteractionNoteFields = {
    productId: notes.product.product.id,
    summary: toInteractionNoteInput(notes?.summary),
    observation: toInteractionNoteInput(notes?.observation),
  }
  if (hasInternalOpinion){
    fields.internalOpinion = toInteractionNoteInput(notes?.internalOpinion)
  }
  return fields
}

/**
 * Compares a ProductInteractionNotesFragment and a ProductInteractionNoteFields 
 * to see if it is the same note (referring to the same product)
 */
export const IsSameProductInteractionNote = (a: ProductInteractionNotesFragment, b: ProductInteractionNoteFields) => {
  return !!a.product?.product?.id && a.product?.product?.id === b.productId
}

/**
 * Checks if a ProductInteractionNoteFields has changed from the original 
 * ProductInteractionNotesFragment
 */
export const productInteractionNoteHasChanged = (a: ProductInteractionNotesFragment, b: ProductInteractionNoteFields) => {
  return (
    a.summary?.body != b.summary?.body ||
    a.observation?.body != b.observation?.body ||
    a.internalOpinion?.body != b.internalOpinion?.body
  )
}

/**
 * Creates a ProductInteractionNoteFields with only the fields that changed from the original
 * ProductInteractionNotesFragment.
 * This is so the update only affects fields that actually changed
 */
export const diffProductNote = (a: ProductInteractionNotesFragment, b: ProductInteractionNoteFields) => {
  // do not save notes if only content change is from undefined to one or multiple consecutive line breaks
  const updatedSectionsNote: ProductInteractionNoteFields = {productId: b.productId}
  if (a?.summary?.body !== b?.summary?.body){
    if((!a?.summary?.body) && b?.summary?.body && containsOnlyLineBreaks(b?.summary?.body)){
    }else{
      updatedSectionsNote.summary = b?.summary as InteractionNoteInput
    }
  }
  if (a?.observation?.body !== b?.observation?.body){
    if((!a?.observation?.body) &&  b?.observation?.body && containsOnlyLineBreaks(b?.observation?.body)){
    }else{
      updatedSectionsNote.observation = b?.observation as InteractionNoteInput
    }
  }
  if (a?.internalOpinion?.body !== b?.internalOpinion?.body){
    if((!a?.internalOpinion?.body) && b?.internalOpinion?.body && containsOnlyLineBreaks(b?.internalOpinion?.body)){
    }else{
      updatedSectionsNote.internalOpinion = b?.internalOpinion as InteractionNoteInput
    }
  } 
  return updatedSectionsNote
}

/**
 * Helper method for diffOrgNote
 * check if a string contains only line breaks, need to check for null/undefined before using it.
 * @params {string} check for null/undefined before using this method.
 * @return {boolean}
 */

export const containsOnlyLineBreaks = (content: string) => {
  const regex = /^[\r\n]+$/
  return !!content?.match(regex)
}

/**
 * Creates a new OrgInteractionNoteFields with only the fields that changed from the original
 * OrganizationInteractionNotesFragment.
 * This is so the update only affects fields that actually changed
 */
export const diffOrgNote = (a: OrganizationInteractionNotesFragment | null, b: OrgInteractionNoteFields | null) => {
   // do not save notes if only content change is from undefined to one or multiple consecutive line breaks
  const updatedSectionsNote: OrgInteractionNoteFields = {}
  if (a?.summary?.body !== b?.summary?.body){
    if((!a?.summary?.body) && b?.summary?.body && containsOnlyLineBreaks(b?.summary?.body)){
      if(a?.summary?.author?.id !== b?.summary?.author && b?.summary?.author) {
        // CAL-2098
        updatedSectionsNote.summary = b?.summary as InteractionNoteInput
      }
    }else{
      updatedSectionsNote.summary = b?.summary as InteractionNoteInput
    }
  }else if(a?.summary?.author?.id !== b?.summary?.author && b?.summary?.author) {
    updatedSectionsNote.summary = b?.summary as InteractionNoteInput
  }
  if (a?.observation?.body !== b?.observation?.body){
    if((!a?.observation?.body) &&  b?.observation?.body && containsOnlyLineBreaks(b?.observation?.body)){
      if(a?.observation?.author?.id !== b?.observation?.author && b?.observation?.author) {
        updatedSectionsNote.observation = b?.observation as InteractionNoteInput
      }
    }else{
      updatedSectionsNote.observation = b?.observation as InteractionNoteInput
    }
  }else if(a?.observation?.author?.id !== b?.observation?.author && b?.observation?.author) {
    updatedSectionsNote.observation = b?.observation as InteractionNoteInput
  }

  if (a?.internalOpinion?.body !== b?.internalOpinion?.body){
    if((!a?.internalOpinion?.body) && b?.internalOpinion?.body && containsOnlyLineBreaks(b?.internalOpinion?.body)){
      if(a?.internalOpinion?.author?.id !== b?.internalOpinion?.author && b?.internalOpinion?.author) {
        updatedSectionsNote.internalOpinion = b?.internalOpinion as InteractionNoteInput
      }
    }else{
      updatedSectionsNote.internalOpinion = b?.internalOpinion as InteractionNoteInput
    }
  } else if(a?.internalOpinion?.author?.id !== b?.internalOpinion?.author && b?.internalOpinion?.author) {
    updatedSectionsNote.internalOpinion = b?.internalOpinion as InteractionNoteInput
  }

  if (updatedSectionsNote){
    updatedSectionsNote.raw = b?.raw
  }
  console.log(a, b, updatedSectionsNote)
  return updatedSectionsNote
}

export const hyphenNateType = (type: Interaction["type"]) => {
  const addHyphen = (type: string) => type.split(" ").join("-")
  switch (type) {
    // CAL-1557
    case "On Site": 
    case "Off Site":
    case "In House":
      return addHyphen(type)
    case "Email":
    case "Conference Call":
    case "Client":
    case "Video Call":
    case "Webcast":
      return type
    default:
      return "No Type"
  }
}
// Had to define all these to correctly map the mess of differently named types to the names which were to be displayed.
import { SearchTypes } from "../../__generated__/graphql";

// List of Display Names
export enum SearchTypeDisplays {
  Client = "Clients",
  Manager = "Managers",
  Custodian = "Custodians",
  "Record Keeper" = "Record Keepers",
  People = "People",
  Products = "Products",
  Vehicles = "Vehicles",
  "Target Date" = "Target Date",
  Documents = "Documents",
  Plan = "Plan",
  "Portfolio" = "Portfolios",
  "Index" = "Indices",
  Group = "Groups",
}

// The order of search type
export const SearchTypeDisplaysOrder = [
  'Clients',
  'Managers',
  'Custodians',
  'Record Keepers',
  'People',
  'Products',
  'Vehicles',
  'Target Date',
  'Groups',
  'Indices',
  'Documents'
]

//
export const SearchTypeListDisplaysOrder = [
  'Portfolios',
  'Clients',
  'Managers',
  'Custodians',
  'Record Keepers',
  'Plan',
  'Products',
  'Vehicles',
  'Indices',
  'Target Date',
  'Groups',
]

// Map the Display version back to the SearchTypes enum
export enum SearchDisplayToTypes {
  Clients = SearchTypes.Client,
  Managers = SearchTypes.Manager,
  Custodians = SearchTypes.Bank,
  "Record Keepers" = SearchTypes.RecordKeeper,
  People = SearchTypes.People,
  Products = SearchTypes.Product,
  Vehicles = SearchTypes.Vehicle,
  "Target Date" = SearchTypes.GlidePath,
  Documents = SearchTypes.File,
  Plan = SearchTypes.Plan,
  // Portfolios = SearchTypes.ClientPortfolio,
  Indices = SearchTypes.Index,
  Groups = SearchTypes.Group,
}

import React, { Component, createRef } from "react"
import { AgPromise, GridApi, ICellEditorComp, ICellEditorParams } from "@ag-grid-community/core"
import { FormInput } from "../ui/Forms/FormInput"
import classNames from "classnames"

interface CustomFormEditorComponentProps extends ICellEditorParams{
  updateValue: (params: any) => void
  inputProps?: any
  formType: string
  formSubtype?: string
  leftAlignValue?: boolean
}

interface CustomFormEditorComponentState {
  value: any
}

export class AgGridFormEditor extends Component<CustomFormEditorComponentProps, CustomFormEditorComponentState> implements ICellEditorComp{
  eGui!: HTMLDivElement
  eInput!: HTMLInputElement | null
  inputRef: any
  // cell!: HTMLElement
  // oldWidth!: string
  constructor(props: CustomFormEditorComponentProps) {
    super(props)
    this.inputRef = createRef()
    // this.cell = props.eGridCell
    // this.oldWidth = this.cell.style.width
    // this.cell.style.width = "130px"
    this.eGui = this.inputRef?.current
    this.state = {
      value: this.props.value,
    }
    this.updateValue = this.updateValue.bind(this)
  }

  componentDidMount() {
    this.focusInput();
  }

  focusInput() {
    let input = this.inputRef?.current;
    if(input) {
      input.focus();
      // Timeout used as a workaround to fix the issue with ag-grid not selecting the input text
      setTimeout(() => {
        input.select();
      }, 0);
    }
  }

  isEmpty = (val: any) => {
    return val === undefined || val === null || val === ""
  }

  isPopup = () => false

  isCancelAfterEnd = () => {
    // let value = this.getValue()
    // if(this.isEmpty(value)) {
    //   return true
    // }
    return false
  }

  getValue = () => {
    // // use the form input as blur is when the value is set in the state
    // if(this.props.formType === "date" && !["month", "quarter"].includes(this.props.formSubtype || "")) {
    //   return this.inputRef?.current.value
    // }
    return this.state.value
  }

  getGui = () => {
    return this.inputRef?.current
  }

  destroy = () => {
  }

  updateValue = (value: any) => {
    this.setState({value})
  }

  handleEnterKeyDown = (event: any) => {
    if(event.key === "Enter") {
      event.preventDefault()
    }
  }

  render(){
    const {formType, formSubtype, rowIndex, inputProps, colDef} = this.props
    const property = colDef.field || "ag-grid-form-editor"
    const hasCheckbox = !!colDef.checkboxSelection
    return (
      <div id={`container-${rowIndex}`} className={classNames("ag-custom-component-popup", {"w-100": !hasCheckbox, "with-checkbox": hasCheckbox})}>
        <FormInput
          property={property}
          displayName={""}
          type={formType}
          subtype={formSubtype}
          idx={`${property}-${rowIndex}`}
          editMode={true}
          propertyVal={this.state.value}
          updateValue={this.updateValue}
          inputRef={this.inputRef}
          validateAlsoOnChange={true}
          inputProps={inputProps}
          subClasses={{
            inputClasses: classNames("pl-2 pr-2 mx-0 w-100", {"text-right": !this.props.leftAlignValue, "text-left": !!this.props.leftAlignValue}),
            inputWrapperClasses: "mx-0 px-0 w-100",
            wrapperClasses: "w-100 mx-0 px-0"
          }}
          immediateUpdate={true}
        />
      </div>
    )
  }
}

export default AgGridFormEditor
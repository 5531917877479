import {
  GetLookupDataToOptions
} from "../ui/LookupOptions"

interface VehicleCharacteristicsInterface {
  // Key is asset class id
  [key: string]: VehicleCharacteristicsTable[]
}

export interface VehicleCharacteristicsTable {
  assetClassName?: string
  tableName: string
  tabName: string
  totalAll?: boolean
  fields: VehicleCharacteristicsField[]
}

export interface VehicleCharacteristicsField {
  fieldName: string
  label: string
  format: string
  subtotal?: string
  contributionToDuration?: boolean
  contributionToDts?: boolean
  relativeContributionToDts?: boolean
}

export const qualityMappingOptions = GetLookupDataToOptions({
  data: [
    {
      code: 190,
      value: '"AAA" Quality',
    },
    {
      code: 180,
      value: '"AA+" Quality',
    },
    {
      code: 170,
      value: '"AA" Quality',
    },
    {
      code: 160,
      value: '"AA-" Quality',
    },
    {
      code: 150,
      value: '"A+" Quality',
    },
    {
      code: 140,
      value: '"A" Quality',
    },
    {
      code: 130,
      value: '"A-" Quality',
    },

    {
      code: 120,
      value: '"BBB+" Quality',
    },
    {
      code: 110,
      value: '"BBB" Quality',
    },
    {
      code: 100,
      value: '"BBB-" Quality',
    },
    {
      code: 90,
      value: '"BB+" Quality',
    },
    {
      code: 80,
      value: '"BB" Quality',
    },
    {
      code: 70,
      value: '"BB-" Quality',
    },
    {
      code: 60,
      value: '"B+" Quality',
    },
    {
      code: 50,
      value: '"B" Quality',
    },
    {
      code: 40,
      value: '"B-" Quality',
    },
    {
      code: 35,
      value: '"CCC+" Quality',
    },
    {
      code: 30,
      value: '"CCC" Quality',
    },
    {
      code: 25,
      value: '"CCC-" Quality',
    },
    {
      code: 20,
      value: '"CC" Quality',
    },
    {
      code: 10,
      value: '"C" Quality',
    },
    {
      code: 0,
      value: '"D" Quality',
    },
  ], sort:false}) as JSX.Element

export const qualityStringMapping = GetLookupDataToOptions({
  data: [
    {
      code: "AAA",
      value: '"AAA" Quality',
    },
    {
      code: "AA+",
      value: '"AA+" Quality',
    },
    {
      code: "AA",
      value: '"AA" Quality',
    },
    {
      code: "AA-",
      value: '"AA-" Quality',
    },
    {
      code: "A+",
      value: '"A+" Quality',
    },
    {
      code: "A",
      value: '"A" Quality',
    },
    {
      code: "A-",
      value: '"A-" Quality',
    },
    {
      code: "BBB+",
      value: '"BBB+" Quality',
    },
    {
      code: "BBB",
      value: '"BBB" Quality',
    },
    {
      code: "BBB-",
      value: '"BBB-" Quality',
    },
    {
      code: "BB+",
      value: '"BB+" Quality',
    },
    {
      code: "BB",
      value: '"BB" Quality',
    },
    {
      code: "BB-",
      value: '"BB-" Quality',
    },
    {
      code: "B+",
      value: '"B+" Quality',
    },
    {
      code: "B",
      value: '"B" Quality',
    },
    {
      code: "B-",
      value: '"B-" Quality',
    },
    {
      code: "CCC+",
      value: '"CCC+" Quality',
    },
    {
      code: "CCC",
      value: '"CCC" Quality',
    },
    {
      code: "CCC-",
      value: '"CCC-" Quality',
    },
    {
      code: "CC",
      value: '"CC" Quality',
    },
    {
      code: "C",
      value: '"C" Quality',
    },
    {
      code: "D",
      value: '"D" Quality',
    },
  ], sort:false}) as JSX.Element

export const qualityStringToNumericMapping = [
  {
    code: "AAA",
    value: 190,
  },
  {
    code: "AA+",
    value: 180,
  },
  {
    code: "AA",
    value: 170,
  },
  {
    code: "AA-",
    value: 160,
  },
  {
    code: "A+",
    value: 150,
  },
  {
    code: "A",
    value: 140,
  },
  {
    code: "A-",
    value: 130,
  },

  {
    code: "BBB+",
    value: 120,
  },
  {
    code: "BBB",
    value: 110,
  },
  {
    code: "BBB-",
    value: 100,
  },
  {
    code: "BB+",
    value: 90,
  },
  {
    code: "BB",
    value: 80,
  },
  {
    code: "BB-",
    value: 70,
  },
  {
    code: "B+",
    value: 60,
  },
  {
    code: "B",
    value: 50,
  },
  {
    code: "B-",
    value: 40,
  },
  {
    code: "CCC+",
    value: 35,
  },
  {
    code: "CCC",
    value: 30,
  },
  {
    code: "CCC-",
    value: 25,
  },
  {
    code: "CC",
    value: 20,
  },
  {
    code: "C",
    value: 10,
  },
  {
    code: "D",
    value: 0,
  },
]

export const tableLengths = {
  "bondSectorExposures": 165,
  "corporateSubSectorIndustryExposures": 215,
  "countryExposures": 153,
  "currencyExposures": 160,
  "directional": 104,
  "durationExposures": 145,
  "eventDriven": 117,
  "farmlandSectors": 148,
  "infrastructureSectors": 180,
  "investmentType": 140,
  "miscPortfolioInfo": 145,
  "portfolioCharacteristics": 175,
  "privateEquityGlobalGeographies": 142,
  "privateEquityIndustries": 77,
  "privateEquityUnitedStatesGeographies": 118,
  "propertySectors": 132,
  "qualityExposures": 136,
  "realEstateInvestmentSizeDiversifications": 240,
  "realEstateInvestmentStageDiversifications": 250,
  "realEstateRegionalExposures": 170,
  "realReturnExposures": 176,
  "regionalExposures": 150,
  "relativeValues": 125,
  "sectors": 71,
  "stockSectors": 113,
  "strategies": 85,
  "timberlandRegions": 165,
  "timberlandWoodTypes": 195,
  "unitedStatesGeographicExposures": 180,
  "wrap": 65,
}

export const VehicleCharacteristicsDefinition: VehicleCharacteristicsInterface = {
  "8":[
    {
      "tableName":"bondSectorExposures",
      "tabName":"Bond Sector Exposure",
      "fields":[
        {
          "fieldName":"TREA",
          "label":"US Treasuries",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AGNC",
          "label":"Gov Related",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CORP",
          "label":"Corporate (including 144A)",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ABSY",
          "label":"Asset Backed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MORT",
          "label":"Agency RMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"NRMBS",
          "label":"Non-Agency RMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CMBS",
          "label":"CMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CMO",
          "label":"CMOs",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BLOAN",
          "label":"Bank Loans",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MUNI",
          "label":"Tax-Exempt US Muni",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CONV",
          "label":"Convertibles",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"STOCK",
          "label":"Common Stock",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"durationExposures",
      "tabName":"Duration Exposure",
      "fields":[
        {
          "fieldName":"DU1",
          "label":"Duration < 1",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU13",
          "label":"Duration 1-3",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU35",
          "label":"Duration 3-5",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU57",
          "label":"Duration 5-7",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU710",
          "label":"Duration 7-10",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU10",
          "label":"Duration > 10",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"nonInvestmentGradeDebt",
          "label":"Non-Investment Grade Debt",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"netEmgMktCurrencyExposure",
          "label":"Net Emg Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"privatePlacements144a",
          "label":"144A/Private Placements",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"inflationLinkedSecurities",
          "label":"Inflation-Linked Securities",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent",
          // "contributionToDuration":true
        },
        {
          "fieldName":"durationAroundIndexUpperBound",
          "label":"Duration Around Index Upper Bound",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexLowerBound",
          "label":"Duration Around Index Lower Bound",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveMaturity",
          "label":"Effective Maturity (Wtd Avg Life)",
          "format":"float"
        },
        {
          "fieldName":"effectiveConvexity",
          "label":"Effective Convexity",
          "format":"float"
        },
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveYieldYtw",
          "label":"Effective Yield (YTW)",
          "format":"float"
        },
        {
          "fieldName":"couponRate",
          "label":"Coupon Rate",
          "format":"float"
        },
        {
          "fieldName":"currentYield",
          "label":"Current Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"numDistinctIssuers",
          "label":"Number of Distinct Issuers",
          "format":"number"
        },
        {
          "fieldName":"largestCorpCreditIssuerPct",
          "label":"Largest Corp Credit Issuer (% TMV)",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"qualityExposures",
      "tabName":"Quality Exposure",
      "fields":[
        {
          "fieldName":"QAAA",
          "label":"AAA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAP",
          "label":"AA+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAA",
          "label":"AA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAM",
          "label":"AA- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAP",
          "label":"A+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QA",
          "label":"A Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAM",
          "label":"A- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBP",
          "label":"BBB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBB",
          "label":"BBB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBM",
          "label":"BBB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBP",
          "label":"BB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBB",
          "label":"BB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBM",
          "label":"BB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBP",
          "label":"B+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QB",
          "label":"B Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBM",
          "label":"B- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCP",
          "label":"CCC+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCC",
          "label":"CCC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCM",
          "label":"CCC- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCC",
          "label":"CC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QC",
          "label":"C Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QD",
          "label":"D Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QNA",
          "label":"Not Rated Quality",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    }
  ],
  "58":[
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveMaturity",
          "label":"Effective Maturity (Wtd Avg Life)",
          "format":"float"
        },
        {
          "fieldName":"effectiveConvexity",
          "label":"Effective Convexity",
          "format":"float"
        },
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveYieldYtw",
          "label":"Effective Yield (YTW)",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"sectors",
      "tabName":"Sector",
      "fields":[
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent"
        },
        {
          "fieldName":"GRAIN",
          "label":"Grain",
          "format":"percent"
        },
        {
          "fieldName":"INDMET",
          "label":"Industrial Metals",
          "format":"percent"
        },
        {
          "fieldName":"PREMET",
          "label":"Precious Metals",
          "format":"percent"
        },
        {
          "fieldName":"LIVSTK",
          "label":"Livestock",
          "format":"percent"
        },
        {
          "fieldName":"SOFT",
          "label":"Soft",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "140":[
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"totalGrossExposure",
          "label":"Total Gross Exposure % of Capital (AUM)",
          "format":"percent"
        },
        {
          "fieldName":"totalGrossLongExposure",
          "label":"Total Gross Long Exposure % of Capital (AUM)",
          "format":"percent"
        },
        {
          "fieldName":"totalGrossShortExposure",
          "label":"Total Gross Short Exposure % of Capital (AUM)",
          "format":"percent"
        },
        {
          "fieldName":"totalNetExposurePct",
          "label":"Total Net Exposure % of Capital (AUM)",
          "format":"percent"
        },
        {
          "fieldName":"currentExAnteRisk",
          "label":"Current Ex-Ante Risk (Std. Dev.)",
          "format":"float"
        },
        {
          "fieldName":"currentValueAtRisk",
          "label":"Current Value at Risk\n(1Mon @ 95th Percentile)",
          "format":"float"
        },
        {
          "fieldName":"betaVsRussell3000",
          "label":"Current Beta vs. Russell 3000",
          "format":"float"
        },
        {
          "fieldName":"betaVsMsciAcwi",
          "label":"Current Beta vs. MSCI ACWI",
          "format":"float"
        },
        {
          "fieldName":"betaVsBarclaysUsAgg",
          "label":"Current Beta vs. Barclays US Aggregate",
          "format":"float"
        },
        {
          "fieldName":"betaVsBarclaysGlobalAgg",
          "label":"Current Beta vs. Barclays Global Aggregate",
          "format":"float"
        },
        {
          "fieldName":"physicalAssetsPct",
          "label":"Physical Assets (% of Gross Exposure)",
          "format":"percent"
        },
        {
          "fieldName":"derivativesPctOfGrossNotionalExp",
          "label":"Derivatives (% of Gross Notional Exposures)",
          "format":"percent"
        },
        {
          "fieldName":"subadvisedAssetsOfGrossExposures",
          "label":"Subadvised Assets (% of Gross Exposures)",
          "format":"percent"
        },
        {
          "fieldName":"liquidityLvl1AssetsPct",
          "label":"Liquidity - Level 1 Assets (% of Total Gross Exposure)",
          "format":"percent"
        },
        {
          "fieldName":"liquidityLvl2AssetsPct",
          "label":"Liquidity - Level 2 Assets (% of Total Gross Exposures)",
          "format":"percent"
        },
        {
          "fieldName":"liquidityLvl3AssetsPct",
          "label":"Liquidity - Level 3 Assets (% of Total Gross Exposures)",
          "format":"percent"
        }
      ],
      "totalAll":false
    }
  ],
  "125":[
    {
      "tableName":"realReturnExposures",
      "tabName":"Real Return Exposure",
      "fields":[
        {
          "fieldName":"NATRES",
          "label":"Natural Resources",
          "format":"percent"
        },
        {
          "fieldName":"REIT",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"MLP",
          "label":"Master Limited Partnerships",
          "format":"percent"
        },
        {
          "fieldName":"INFRA",
          "label":"Infrastructure",
          "format":"percent"
        },
        {
          "fieldName":"OTHEQ",
          "label":"Other Equity",
          "format":"percent"
        },
        {
          "fieldName":"INFLBD",
          "label":"Inflation-Linked Securities",
          "format":"percent"
        },
        {
          "fieldName":"NUSILB",
          "label":"Non-US Inflation Linked Bonds",
          "format":"percent"
        },
        {
          "fieldName":"BLOAN",
          "label":"Bank Loans",
          "format":"percent"
        },
        {
          "fieldName":"EMDEBT",
          "label":"Emerging Debt",
          "format":"percent"
        },
        {
          "fieldName":"OTHFI",
          "label":"Other Fixed Income",
          "format":"percent"
        },
        {
          "fieldName":"COMMO",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "16":[
    {
      "tableName":"bondSectorExposures",
      "tabName":"Bond Sector Exposure",
      "fields":[
        {
          "fieldName":"TREA",
          "label":"US Treasuries",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AGNC",
          "label":"Gov Related",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CORP",
          "label":"Corporate (including 144A)",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ABSY",
          "label":"Asset Backed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MORT",
          "label":"Agency RMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"NRMBS",
          "label":"Non-Agency RMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CMBS",
          "label":"CMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CMO",
          "label":"CMOs",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BLOAN",
          "label":"Bank Loans",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PREF",
          "label":"Non-Convert Prefd",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MUNI",
          "label":"Tax-Exempt US Muni",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CONV",
          "label":"Convertibles",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"durationExposures",
      "tabName":"Duration Exposure",
      "fields":[
        {
          "fieldName":"DU1",
          "label":"Duration < 1",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU13",
          "label":"Duration 1-3",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU35",
          "label":"Duration 3-5",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU57",
          "label":"Duration 5-7",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU710",
          "label":"Duration 7-10",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU10",
          "label":"Duration > 10",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"bonds",
          "label":"Bonds",
          "format":"percent"
        },
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"americanDepositoryReceipt",
          "label":"American Depository Receipt",
          "format":"percent"
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent"
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent"
        },
        {
          "fieldName":"nonInvestmentGradeDebt",
          "label":"Non-Investment Grade Debt",
          "format":"percent"
        },
        {
          "fieldName":"privatePlacements144a",
          "label":"144A/Private Placements",
          "format":"percent"
        },
        {
          "fieldName":"durationVariationVsBenchPct",
          "label":"Duration Variation vs Bench (+-%)",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"commonStock",
          "label":"Common Stock",
          "format":"percent"
        },
        {
          "fieldName":"convertible",
          "label":"Convertible",
          "format":"percent"
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexUpperBound",
          "label":"Duration Around Index Upper Bound",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexLowerBound",
          "label":"Duration Around Index Lower Bound",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"largeCapPct",
          "label":"% Large Cap ($wgt) > $15 B",
          "format":"percent"
        },
        {
          "fieldName":"midCapPct",
          "label":"% Mid Cap ($wgt) $3.5 - $15 B",
          "format":"percent"
        },
        {
          "fieldName":"smallCapWgt",
          "label":"% Small Cap ($wgt) $700M - $3.5 B",
          "format":"percent"
        },
        {
          "fieldName":"microCapPct",
          "label":"% Micro Cap ($wgt)< $700 M",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceBookValue",
          "label":"Wtg Avg Price/Book Value",
          "format":"float"
        },
        {
          "fieldName":"avgPriceForecastedEarnings",
          "label":"Wtg Avg Price/Forecasted Earnings",
          "format":"float"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"forecastedEarningsGrowth5yr",
          "label":"5yr Forecasted Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"historicalEarningsGrowth5yr",
          "label":"5yr Historical Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"qualityExposures",
      "tabName":"Quality Exposure",
      "fields":[
        {
          "fieldName":"QAAA",
          "label":"AAA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAP",
          "label":"AA+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAA",
          "label":"AA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAM",
          "label":"AA- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAP",
          "label":"A+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QA",
          "label":"A Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAM",
          "label":"A- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBP",
          "label":"BBB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBB",
          "label":"BBB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBM",
          "label":"BBB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBP",
          "label":"BB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBB",
          "label":"BB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBM",
          "label":"BB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBP",
          "label":"B+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QB",
          "label":"B Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBM",
          "label":"B- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCP",
          "label":"CCC+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCC",
          "label":"CCC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCM",
          "label":"CCC- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCC",
          "label":"CC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QC",
          "label":"C Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QD",
          "label":"D Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QNA",
          "label":"Not Rated Quality",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    }
  ],
  "1":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"americanDepositoryReceipt",
          "label":"American Depository Receipt",
          "format":"percent"
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent"
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent"
        },
        {
          "fieldName":"convertibles",
          "label":"Convertibles",
          "format":"percent"
        },
        {
          "fieldName":"nonConvertPrefd",
          "label":"Non-Convert Prefd",
          "format":"percent"
        },
        {
          "fieldName":"bonds",
          "label":"Bonds",
          "format":"percent"
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"otherMisc",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"largeCapPct",
          "label":"% Large Cap ($wgt) > $15 B",
          "format":"percent"
        },
        {
          "fieldName":"midCapPct",
          "label":"% Mid Cap ($wgt) $3.5 - $15 B",
          "format":"percent"
        },
        {
          "fieldName":"smallCapWgt",
          "label":"% Small Cap ($wgt) $700M - $3.5 B",
          "format":"percent"
        },
        {
          "fieldName":"microCapPct",
          "label":"% Micro Cap ($wgt)< $700 M",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceForecastedEarnings",
          "label":"Wtg Avg Price/Forecasted Earnings",
          "format":"float"
        },
        {
          "fieldName":"avgPriceBookValue",
          "label":"Wtg Avg Price/Book Value",
          "format":"float"
        },
        {
          "fieldName":"forecastedEarningsGrowth5yr",
          "label":"5yr Forecasted Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"historicalEarningsGrowth5yr",
          "label":"5yr Historical Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "4":[
    {
      "tableName":"bondSectorExposures",
      "tabName":"Bond Sector Exposure",
      "fields":[
        {
          "fieldName":"TREA",
          "label":"US Treasuries",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"AGNC",
          "label":"Gov Related",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CORP",
          "label":"Corporate (including 144A)",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ABSY",
          "label":"Asset Backed",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MORT",
          "label":"Agency RMBS",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NRMBS",
          "label":"Non-Agency RMBS",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CMBS",
          "label":"CMBS",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CMO",
          "label":"CMOs",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BLOAN",
          "label":"Bank Loans",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PREF",
          "label":"Non-Convert Prefd",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MUNI",
          "label":"Tax-Exempt US Muni",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONV",
          "label":"Convertibles",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"STOCK",
          "label":"Common Stock",
          "format":"percent",
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"corporateSubSectorIndustryExposures",
      "tabName":"Corp Sub-Sector Ind Exposure",
      "fields":[
        {
          "fieldName":"BASIC",
          "label":"Basic Industry",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CAPGDS",
          "label":"Capital Goods",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONCYC",
          "label":"Consumer Discretionary",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONNC",
          "label":"Consumer Non-Cyclical",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TECH",
          "label":"Technology",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TRANS",
          "label":"Transportation",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COMMUN",
          "label":"Communication Services",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHIND",
          "label":"Other Industrials",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ELEC",
          "label":"Electric",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NATGAS",
          "label":"Natural Gas",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHUTL",
          "label":"Other Utilities",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BNK",
          "label":"Banking",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BAME",
          "label":"Brokerage Asset Manager Exchanges",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"FINCO",
          "label":"Finance Companies",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"INS",
          "label":"Insurance",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"REIT",
          "label":"Real Estate Investment Trust",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"durationExposures",
      "tabName":"Duration Exposure",
      "fields":[
        {
          "fieldName":"DU1",
          "label":"Duration < 1",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU13",
          "label":"Duration 1-3",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU35",
          "label":"Duration 3-5",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU57",
          "label":"Duration 5-7",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU710",
          "label":"Duration 7-10",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU10",
          "label":"Duration > 10",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"nonInvestmentGradeDebt",
          "label":"Non-Investment Grade Debt",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"netEmgMktCurrencyExposure",
          "label":"Net Emg Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"privatePlacements144a",
          "label":"144A/Private Placements",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"inflationLinkedSecurities",
          "label":"Inflation-Linked Securities",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent",
          // "contributionToDuration":true
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexUpperBound",
          "label":"Duration Around Index Upper Bound",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexLowerBound",
          "label":"Duration Around Index Lower Bound",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveRealDurationYrs",
          "label":"Effective Real Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveMaturity",
          "label":"Effective Maturity (Wtd Avg Life)",
          "format":"float"
        },
        {
          "fieldName":"effectiveConvexity",
          "label":"Effective Convexity",
          "format":"float"
        },
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveYieldYtw",
          "label":"Effective Yield (YTW)",
          "format":"float"
        },
        {
          "fieldName":"effectiveRealYield",
          "label":"Effective Real Yield",
          "format":"float"
        },
        {
          "fieldName":"couponRate",
          "label":"Coupon Rate",
          "format":"float"
        },
        {
          "fieldName":"currentYield",
          "label":"Current Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"numDistinctIssuers",
          "label":"Number of Distinct Issuers",
          "format":"number"
        },
        {
          "fieldName":"largestCorpCreditIssuerPct",
          "label":"Largest Corp Credit Issuer (% TMV)",
          "format":"percent"
        },
        {
          "fieldName":"dtsRatio",
          "label":"DTS Ratio",
          "format":"float"
        },
        {
          "fieldName":"dts20Ratio",
          "label":"DTS-20 Ratio",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"qualityExposures",
      "tabName":"Quality Exposure",
      "fields":[
        {
          "fieldName":"QAAA",
          "label":"AAA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAP",
          "label":"AA+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAA",
          "label":"AA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAM",
          "label":"AA- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAP",
          "label":"A+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QA",
          "label":"A Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAM",
          "label":"A- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBP",
          "label":"BBB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBB",
          "label":"BBB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBM",
          "label":"BBB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBP",
          "label":"BB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBB",
          "label":"BB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBM",
          "label":"BB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBP",
          "label":"B+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QB",
          "label":"B Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBM",
          "label":"B- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCP",
          "label":"CCC+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCC",
          "label":"CCC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCM",
          "label":"CCC- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCC",
          "label":"CC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QC",
          "label":"C Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QD",
          "label":"D Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QNA",
          "label":"Not Rated Quality",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    }
  ],
  "49":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"realEstateOperatingCo",
          "label":"Real Estate Operating Co.",
          "format":"percent"
        },
        {
          "fieldName":"otherMisc",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"priceFfo",
          "label":"Price/FFO",
          "format":"float"
        },
        {
          "fieldName":"priceNav",
          "label":"Price/NAV",
          "format":"float"
        },
        {
          "fieldName":"forecastGrowthFfo",
          "label":"Forecast Growth FFO",
          "format":"float"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"propertySectors",
      "tabName":"Property Sectors",
      "fields":[
        {
          "fieldName":"OFFICE",
          "label":"Office",
          "format":"percent"
        },
        {
          "fieldName":"INDUST",
          "label":"Industrial",
          "format":"percent"
        },
        {
          "fieldName":"INDOFF",
          "label":"Mixed Use Off/Ind",
          "format":"percent"
        },
        {
          "fieldName":"RETAIL",
          "label":"Retail",
          "format":"percent"
        },
        {
          "fieldName":"LODRES",
          "label":"Hotels",
          "format":"percent"
        },
        {
          "fieldName":"APART",
          "label":"Multi-Family",
          "format":"percent"
        },
        {
          "fieldName":"RESID",
          "label":"Manuf Homes",
          "format":"percent"
        },
        {
          "fieldName":"SELSTO",
          "label":"Storage",
          "format":"percent"
        },
        {
          "fieldName":"DIVERS",
          "label":"Diversified",
          "format":"percent"
        },
        {
          "fieldName":"HEALTH",
          "label":"Health Care",
          "format":"percent"
        },
        {
          "fieldName":"SPECIA",
          "label":"Specialty",
          "format":"percent"
        },
        {
          "fieldName":"MORTGA",
          "label":"Mortgage",
          "format":"percent"
        },
        {
          "fieldName":"LAND",
          "label":"Land",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"unitedStatesGeographicExposures",
      "tabName":"US Geographic Exposure",
      "fields":[
        {
          "fieldName":"PACIFC",
          "label":"Pacific",
          "format":"percent"
        },
        {
          "fieldName":"SWEST",
          "label":"Southwest",
          "format":"percent"
        },
        {
          "fieldName":"MOUNTN",
          "label":"Mountain",
          "format":"percent"
        },
        {
          "fieldName":"WNCENT",
          "label":"WN Central",
          "format":"percent"
        },
        {
          "fieldName":"ENCENT",
          "label":"EN Central",
          "format":"percent"
        },
        {
          "fieldName":"NEAST",
          "label":"Northeast",
          "format":"percent"
        },
        {
          "fieldName":"MEAST",
          "label":"Mideast",
          "format":"percent"
        },
        {
          "fieldName":"SEAST",
          "label":"Southeast",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "22":[
    {
      "tableName":"bondSectorExposures",
      "tabName":"Bond Sector Exposure",
      "fields":[
        {
          "fieldName":"AGNC",
          "label":"Gov Related",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CORP",
          "label":"Corporate (including 144A)",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TSECUR",
          "label":"Total Securitized",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent",
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"corporateSubSectorIndustryExposures",
      "tabName":"Corp Sub-Sector Ind Exposure",
      "fields":[
        {
          "fieldName":"BASIC",
          "label":"Basic Industry",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CAPGDS",
          "label":"Capital Goods",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONCYC",
          "label":"Consumer Discretionary",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONNC",
          "label":"Consumer Non-Cyclical",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TECH",
          "label":"Technology",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TRANS",
          "label":"Transportation",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COMMUN",
          "label":"Communication Services",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHIND",
          "label":"Other Industrials",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ELEC",
          "label":"Electric",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NATGAS",
          "label":"Natural Gas",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHUTL",
          "label":"Other Utilities",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BNK",
          "label":"Banking",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BAME",
          "label":"Brokerage Asset Manager Exchanges",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"FINCO",
          "label":"Finance Companies",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"INS",
          "label":"Insurance",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"REIT",
          "label":"Real Estate Investment Trust",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"countryExposures",
      "tabName":"Country Exposure",
      "fields":[
        {
          "fieldName":"ANG",
          "label":"Angola",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ARG",
          "label":"Argentina",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"AUL",
          "label":"Australia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"AUS",
          "label":"Austria",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"AZE",
          "label":"Azerbaijan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BAH",
          "label":"Bahrain",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BAN",
          "label":"Bangladesh",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BEL",
          "label":"Belgium",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BER",
          "label":"Bermuda",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BRA",
          "label":"Brazil",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BUL",
          "label":"Bulgaria",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CAN",
          "label":"Canada",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CNI",
          "label":"Channel Islands",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CHI",
          "label":"Chile",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CHN",
          "label":"China",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COL",
          "label":"Colombia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COS",
          "label":"Costa Rica",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COT",
          "label":"Cote D'Ivoire",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"HR",
          "label":"Croatia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CYP",
          "label":"Cyprus",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CZE",
          "label":"Czech Republic",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"DEN",
          "label":"Denmark",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"DOM",
          "label":"Dominican Republic",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ECD",
          "label":"Ecuador",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"EGY",
          "label":"Egypt",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ESL",
          "label":"El Salvador",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"EST",
          "label":"Estonia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"FIN",
          "label":"Finland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"FRA",
          "label":"France",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"GER",
          "label":"Germany",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"GHA",
          "label":"Ghana",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"GRE",
          "label":"Greece",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"GUA",
          "label":"Guatemala",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"HK",
          "label":"Hong Kong",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"HUN",
          "label":"Hungary",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ICE",
          "label":"Iceland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"IND",
          "label":"India",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"INO",
          "label":"Indonesia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"IRE",
          "label":"Ireland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ISR",
          "label":"Israel",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ITA",
          "label":"Italy",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"JAM",
          "label":"Jamaica",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"JPN",
          "label":"Japan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"JOR",
          "label":"Jordan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"KAZ",
          "label":"Kazakhstan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"KEN",
          "label":"Kenya",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"KUW",
          "label":"Kuwait",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"LEB",
          "label":"Lebanon",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"LTH",
          "label":"Lithuania",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"LUX",
          "label":"Luxembourg",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MAL",
          "label":"Malaysia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MUR",
          "label":"Mauritius",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MEX",
          "label":"Mexico",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MON",
          "label":"Mongolia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MOR",
          "label":"Morocco",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NET",
          "label":"Netherlands",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NZE",
          "label":"New Zealand",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NIG",
          "label":"Nigeria",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NOR",
          "label":"Norway",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OMA",
          "label":"Oman",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PAK",
          "label":"Pakistan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PAN",
          "label":"Panama",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PAR",
          "label":"Paraguay",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PER",
          "label":"Peru",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PHI",
          "label":"Philippines",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"POL",
          "label":"Poland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"POR",
          "label":"Portugal",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"QTR",
          "label":"Qatar",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ROM",
          "label":"Romania",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"RUS",
          "label":"Russia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"YUG",
          "label":"Serbia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SIN",
          "label":"Singapore",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SLO",
          "label":"Slovenia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SLV",
          "label":"Slovokia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SAF",
          "label":"South Africa",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SKO",
          "label":"South Korea",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SPA",
          "label":"Spain",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SRI",
          "label":"Sri Lanka",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SUP",
          "label":"Supranational",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SWE",
          "label":"Sweden",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SWI",
          "label":"Switzerland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TAI",
          "label":"Taiwan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"THA",
          "label":"Thailand",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TUN",
          "label":"Tunisia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TUR",
          "label":"Turkey",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"UAE",
          "label":"U.A.E.",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"UKR",
          "label":"Ukraine",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"UK",
          "label":"United Kingdom",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"USA",
          "label":"United States",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"URU",
          "label":"Uruguay",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"VEN",
          "label":"Venezuela",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"VIE",
          "label":"Vietnam",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZAM",
          "label":"Zambia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOA",
          "label":"Other Emerg. Americas",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOE",
          "label":"Other Emerg. Europe",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOP",
          "label":"Other Emerg. Asia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOO",
          "label":"Other Emerg. Mideast/Africa",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOT",
          "label":"Total Other Countries",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"currencyExposures",
      "tabName":"Currency Exposure",
      "fields":[
        {
          "fieldName":"ARGPES",
          "label":"Argentina Peso",
          "format":"percent"
        },
        {
          "fieldName":"AULDOL",
          "label":"Australian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"BRABRL",
          "label":"Brazilian Real",
          "format":"percent"
        },
        {
          "fieldName":"CANDOL",
          "label":"Canadian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"CHIPES",
          "label":"Chilean Peso",
          "format":"percent"
        },
        {
          "fieldName":"CHNYUA",
          "label":"Yuan Renminbi",
          "format":"percent"
        },
        {
          "fieldName":"COLPES",
          "label":"Columbian Peso",
          "format":"percent"
        },
        {
          "fieldName":"CZEKOR",
          "label":"Czech Koruna",
          "format":"percent"
        },
        {
          "fieldName":"DENKRO",
          "label":"Danish Krone",
          "format":"percent"
        },
        {
          "fieldName":"DOMPES",
          "label":"Dominican Peso",
          "format":"percent"
        },
        {
          "fieldName":"ECDSUC",
          "label":"Ecuador Sucre",
          "format":"percent"
        },
        {
          "fieldName":"EGYPOU",
          "label":"Egyptian Pound",
          "format":"percent"
        },
        {
          "fieldName":"ESLCOL",
          "label":"El Salvador Colon",
          "format":"percent"
        },
        {
          "fieldName":"EURECU",
          "label":"Euros",
          "format":"percent"
        },
        {
          "fieldName":"HKDOL",
          "label":"Hong Kong Dollar",
          "format":"percent"
        },
        {
          "fieldName":"HUNFOR",
          "label":"Hungarian Forint",
          "format":"percent"
        },
        {
          "fieldName":"INDRUP",
          "label":"Indian Rupee",
          "format":"percent"
        },
        {
          "fieldName":"INORUP",
          "label":"Indonesian Rupiah",
          "format":"percent"
        },
        {
          "fieldName":"ISRSHE",
          "label":"Israeli Shekel",
          "format":"percent"
        },
        {
          "fieldName":"JORDIN",
          "label":"Jordanian Dinar",
          "format":"percent"
        },
        {
          "fieldName":"JPNYEN",
          "label":"Japanese Yen",
          "format":"percent"
        },
        {
          "fieldName":"LEBPOU",
          "label":"Lebanese Pound",
          "format":"percent"
        },
        {
          "fieldName":"MALRIN",
          "label":"Malaysian Ringgit",
          "format":"percent"
        },
        {
          "fieldName":"MEXPES",
          "label":"Mexican Peso",
          "format":"percent"
        },
        {
          "fieldName":"MORDIR",
          "label":"Moroccan Dirham",
          "format":"percent"
        },
        {
          "fieldName":"NGRXOF",
          "label":"CFA Franc BCEAO",
          "format":"percent"
        },
        {
          "fieldName":"NIGNAI",
          "label":"Nigerian Naira",
          "format":"percent"
        },
        {
          "fieldName":"NORKRO",
          "label":"Norwegian Krone",
          "format":"percent"
        },
        {
          "fieldName":"NZEDOL",
          "label":"New Zealand Dollar",
          "format":"percent"
        },
        {
          "fieldName":"PAKRUP",
          "label":"Pakistan Rupee",
          "format":"percent"
        },
        {
          "fieldName":"PANPAB",
          "label":"Panamanian Balboa",
          "format":"percent"
        },
        {
          "fieldName":"PERSOL",
          "label":"Peruvian Nuevo Sol",
          "format":"percent"
        },
        {
          "fieldName":"PHIPES",
          "label":"Philippine Peso",
          "format":"percent"
        },
        {
          "fieldName":"POLZLY",
          "label":"Polish Zloty",
          "format":"percent"
        },
        {
          "fieldName":"RUSRUR",
          "label":"Russian Ruble",
          "format":"percent"
        },
        {
          "fieldName":"SINDOL",
          "label":"Singapore Dollar",
          "format":"percent"
        },
        {
          "fieldName":"SLVKOR",
          "label":"Slovakian Koruna",
          "format":"percent"
        },
        {
          "fieldName":"SKOWON",
          "label":"Korean Won",
          "format":"percent"
        },
        {
          "fieldName":"SAFRAN",
          "label":"South African Rand",
          "format":"percent"
        },
        {
          "fieldName":"SRILKR",
          "label":"Sri Lanka Rupee",
          "format":"percent"
        },
        {
          "fieldName":"SWEKRO",
          "label":"Swedish Krona",
          "format":"percent"
        },
        {
          "fieldName":"SWIFRA",
          "label":"Swiss Franc",
          "format":"percent"
        },
        {
          "fieldName":"TAIDOL",
          "label":"Taiwanese Dollar",
          "format":"percent"
        },
        {
          "fieldName":"THABAH",
          "label":"Thai Baht",
          "format":"percent"
        },
        {
          "fieldName":"TUNDIN",
          "label":"Tunisian Dinar",
          "format":"percent"
        },
        {
          "fieldName":"TURLIR",
          "label":"Turkish Lira",
          "format":"percent"
        },
        {
          "fieldName":"UKPOU",
          "label":"UK Pound",
          "format":"percent"
        },
        {
          "fieldName":"UKRUAH",
          "label":"Ukrainian Hryvnia",
          "format":"percent"
        },
        {
          "fieldName":"URUPES",
          "label":"Uruguayan Peso",
          "format":"percent"
        },
        {
          "fieldName":"USADOL",
          "label":"US Dollar",
          "format":"percent"
        },
        {
          "fieldName":"VENBOL",
          "label":"Venezuelan Bolivar",
          "format":"percent"
        },
        {
          "fieldName":"VIEDON",
          "label":"Vietnamese Dong",
          "format":"percent"
        },
        {
          "fieldName":"ZOAMIX",
          "label":"Other Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"ZOEMIX",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"ZOOMIX",
          "label":"Other Emerg. Mideast/Africa",
          "format":"percent"
        },
        {
          "fieldName":"ZOPMIX",
          "label":"Other Emerg. Asia",
          "format":"percent"
        },
        {
          "fieldName":"ZOTMIX",
          "label":"Total Other Countries",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"durationExposures",
      "tabName":"Duration Exposure",
      "fields":[
        {
          "fieldName":"DU1",
          "label":"Duration < 1",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU13",
          "label":"Duration 1-3",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU35",
          "label":"Duration 3-5",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU57",
          "label":"Duration 5-7",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU710",
          "label":"Duration 7-10",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU10",
          "label":"Duration > 10",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"nonInvestmentGradeDebt",
          "label":"Non-Investment Grade Debt",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"netEmgMktCurrencyExposure",
          "label":"Net Emg Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"privatePlacements144a",
          "label":"144A/Private Placements",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"inflationLinkedSecurities",
          "label":"Inflation-Linked Securities",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent",
          // "contributionToDuration":true
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"commonStock",
          "label":"Common Stock",
          "format":"percent"
        },
        {
          "fieldName":"convertible",
          "label":"Convertible",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexUpperBound",
          "label":"Duration Around Index Upper Bound",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexLowerBound",
          "label":"Duration Around Index Lower Bound",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveRealDurationYrs",
          "label":"Effective Real Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveMaturity",
          "label":"Effective Maturity (Wtd Avg Life)",
          "format":"float"
        },
        {
          "fieldName":"effectiveConvexity",
          "label":"Effective Convexity",
          "format":"float"
        },
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveYieldYtw",
          "label":"Effective Yield (YTW)",
          "format":"float"
        },
        {
          "fieldName":"effectiveRealYield",
          "label":"Effective Real Yield",
          "format":"float"
        },
        {
          "fieldName":"couponRate",
          "label":"Coupon Rate",
          "format":"float"
        },
        {
          "fieldName":"currentYield",
          "label":"Current Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"numDistinctIssuers",
          "label":"Number of Distinct Issuers",
          "format":"number"
        },
        {
          "fieldName":"largestCorpCreditIssuerPct",
          "label":"Largest Corp Credit Issuer (% TMV)",
          "format":"percent"
        },
        {
          "fieldName":"dtsRatio",
          "label":"DTS Ratio",
          "format":"float"
        },
        {
          "fieldName":"dts20Ratio",
          "label":"DTS-20 Ratio",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"qualityExposures",
      "tabName":"Quality Exposure",
      "fields":[
        {
          "fieldName":"QAAA",
          "label":"AAA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAP",
          "label":"AA+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAA",
          "label":"AA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAM",
          "label":"AA- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAP",
          "label":"A+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QA",
          "label":"A Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAM",
          "label":"A- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBP",
          "label":"BBB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBB",
          "label":"BBB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBM",
          "label":"BBB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBP",
          "label":"BB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBB",
          "label":"BB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBM",
          "label":"BB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBP",
          "label":"B+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QB",
          "label":"B Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBM",
          "label":"B- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCP",
          "label":"CCC+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCC",
          "label":"CCC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCM",
          "label":"CCC- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCC",
          "label":"CC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QC",
          "label":"C Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QD",
          "label":"D Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QNA",
          "label":"Not Rated Quality",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    }
  ],
  "59":[
    {
      "tableName":"farmlandSectors",
      "tabName":"Farmland Sectors",
      "fields":[
        {
          "fieldName":"PERMCP",
          "label":"Permanent Crops",
          "format":"percent"
        },
        {
          "fieldName":"ANCROP",
          "label":"Annual Crops",
          "format":"percent"
        },
        {
          "fieldName":"ANIMAL",
          "label":"Animal Protein",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"jointVentures",
          "label":"Joint Ventures",
          "format":"number"
        },
        {
          "fieldName":"currentLeverage",
          "label":"Current Leverage",
          "format":"percent"
        },
        {
          "fieldName":"avgInvestmentSize",
          "label":"Average Investment Size ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"portfolioDebt",
          "label":"Portfolio Debt ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"portfolioDebtInterestRate",
          "label":"Portfolio Debt Interest Rate",
          "format":"percent"
        },
        {
          "fieldName":"projectedInternalRateOfReturn",
          "label":"Projected Internal Rate of Return",
          "format":"percent"
        },
        {
          "fieldName":"projectedEquityMultiple",
          "label":"Projected Equity Multiple",
          "format":"multiplier"
        },
        {
          "fieldName":"numOfParticipants",
          "label":"Number of Participants",
          "format":"number"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"numOfRealizations",
          "label":"Number of Realizations",
          "format":"number"
        },
        {
          "fieldName":"impliedCapRate",
          "label":"Implied Cap Rate",
          "format":"percent"
        },
        {
          "fieldName":"top5ParticipantsOwnedPct",
          "label":"% Owned by Top 5 Participants",
          "format":"percent"
        },
        {
          "fieldName":"directlyOperatedPct",
          "label":"% Directly Operated",
          "format":"percent"
        },
        {
          "fieldName":"leasedToOperatingPartnerPct",
          "label":"% Leased to Operating Partner",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"realEstateInvestmentSizeDiversifications",
      "tabName":"RE Investment Size Diversification",
      "fields":[
        {
          "fieldName":"BELOWM",
          "label":"< $1 Million",
          "format":"percent"
        },
        {
          "fieldName":"ONE",
          "label":"$ 1 Million - $5 Million",
          "format":"percent"
        },
        {
          "fieldName":"FIVE",
          "label":"$5 Million - $10 Million",
          "format":"percent"
        },
        {
          "fieldName":"TENM",
          "label":"$10 Million - $20 Million",
          "format":"percent"
        },
        {
          "fieldName":"RESML",
          "label":"$20 M - $50 M",
          "format":"percent"
        },
        {
          "fieldName":"RESMD",
          "label":"$50 M - $100M",
          "format":"percent"
        },
        {
          "fieldName":"REHUN",
          "label":"$100 Million - $250 Million",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"realEstateInvestmentStageDiversifications",
      "tabName":"RE Investment Stage Diversification",
      "fields":[
        {
          "fieldName":"DEVNON",
          "label":"Developed/Non-Producing",
          "format":"percent"
        },
        {
          "fieldName":"STBL",
          "label":"Stabilized/Producing",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"unitedStatesGeographicExposures",
      "tabName":"US Geographic Exposure",
      "fields":[
        {
          "fieldName":"PACIFC",
          "label":"Pacific",
          "format":"percent"
        },
        {
          "fieldName":"PACNW",
          "label":"Pacific Northwest",
          "format":"percent"
        },
        {
          "fieldName":"MOUNTN",
          "label":"Mountain",
          "format":"percent"
        },
        {
          "fieldName":"CORN",
          "label":"Cornbelt",
          "format":"percent"
        },
        {
          "fieldName":"LAKE",
          "label":"Lake States",
          "format":"percent"
        },
        {
          "fieldName":"SEAST",
          "label":"Southeast",
          "format":"percent"
        },
        {
          "fieldName":"DELTA",
          "label":"Delta States",
          "format":"percent"
        },
        {
          "fieldName":"APPAL",
          "label":"Appalachian",
          "format":"percent"
        },
        {
          "fieldName":"NPLAI",
          "label":"Northern Plains",
          "format":"percent"
        },
        {
          "fieldName":"SPLAI",
          "label":"Southern Plains",
          "format":"percent"
        },
        {
          "fieldName":"NEAST",
          "label":"Northeast",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "18":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"commonStock",
          "label":"Common Stock",
          "format":"percent"
        },
        {
          "fieldName":"bonds",
          "label":"Bonds",
          "format":"percent"
        },
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent"
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"durationVariationVsBenchPct",
          "label":"Duration Variation vs Bench (+-%)",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexUpperBound",
          "label":"Duration Around Index Upper Bound",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexLowerBound",
          "label":"Duration Around Index Lower Bound",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"largeCapPct",
          "label":"% Large Cap ($wgt) > $15 B",
          "format":"percent"
        },
        {
          "fieldName":"midCapPct",
          "label":"% Mid Cap ($wgt) $3.5 - $15 B",
          "format":"percent"
        },
        {
          "fieldName":"smallCapWgt",
          "label":"% Small Cap ($wgt) $700M - $3.5 B",
          "format":"percent"
        },
        {
          "fieldName":"microCapPct",
          "label":"% Micro Cap ($wgt)< $700 M",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceForecastedEarnings",
          "label":"Wtg Avg Price/Forecasted Earnings",
          "format":"float"
        },
        {
          "fieldName":"avgPriceBookValue",
          "label":"Wtg Avg Price/Book Value",
          "format":"float"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        }
      ],
      "totalAll":false
    }
  ],
  "24":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"americanDepositoryReceipt",
          "label":"American Depository Receipt",
          "format":"percent"
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent"
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent"
        },
        {
          "fieldName":"convertibles",
          "label":"Convertibles",
          "format":"percent"
        },
        {
          "fieldName":"nonConvertPrefd",
          "label":"Non-Convert Prefd",
          "format":"percent"
        },
        {
          "fieldName":"bonds",
          "label":"Bonds",
          "format":"percent"
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"otherMisc",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"largeCapPct",
          "label":"% Large Cap ($wgt) > $15 B",
          "format":"percent"
        },
        {
          "fieldName":"midCapPct",
          "label":"% Mid Cap ($wgt) $3.5 - $15 B",
          "format":"percent"
        },
        {
          "fieldName":"smallCapWgt",
          "label":"% Small Cap ($wgt) $700M - $3.5 B",
          "format":"percent"
        },
        {
          "fieldName":"microCapPct",
          "label":"% Micro Cap ($wgt)< $700 M",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceBookValue",
          "label":"Wtg Avg Price/Book Value",
          "format":"float"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"avgPriceForecastedEarnings",
          "label":"Wtg Avg Price/Forecasted Earnings",
          "format":"float"
        },
        {
          "fieldName":"historicalEarningsGrowth5yr",
          "label":"5yr Historical Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"forecastedEarningsGrowth5yr",
          "label":"5yr Forecasted Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "93":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"realEstateOperatingCo",
          "label":"Real Estate Operating Co.",
          "format":"percent"
        },
        {
          "fieldName":"otherMisc",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"priceFfo",
          "label":"Price/FFO",
          "format":"float"
        },
        {
          "fieldName":"priceNav",
          "label":"Price/NAV",
          "format":"float"
        },
        {
          "fieldName":"forecastGrowthFfo",
          "label":"Forecast Growth FFO",
          "format":"float"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"propertySectors",
      "tabName":"Property Sectors",
      "fields":[
        {
          "fieldName":"OFFICE",
          "label":"Office",
          "format":"percent"
        },
        {
          "fieldName":"INDUST",
          "label":"Industrial",
          "format":"percent"
        },
        {
          "fieldName":"INDOFF",
          "label":"Mixed Use Off/Ind",
          "format":"percent"
        },
        {
          "fieldName":"RETAIL",
          "label":"Retail",
          "format":"percent"
        },
        {
          "fieldName":"LODRES",
          "label":"Hotels",
          "format":"percent"
        },
        {
          "fieldName":"APART",
          "label":"Multi-Family",
          "format":"percent"
        },
        {
          "fieldName":"RESID",
          "label":"Manuf Homes",
          "format":"percent"
        },
        {
          "fieldName":"SELSTO",
          "label":"Storage",
          "format":"percent"
        },
        {
          "fieldName":"DIVERS",
          "label":"Diversified",
          "format":"percent"
        },
        {
          "fieldName":"HEALTH",
          "label":"Health Care",
          "format":"percent"
        },
        {
          "fieldName":"SPECIA",
          "label":"Specialty",
          "format":"percent"
        },
        {
          "fieldName":"MORTGA",
          "label":"Mortgage",
          "format":"percent"
        },
        {
          "fieldName":"LAND",
          "label":"Land",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"unitedStatesGeographicExposures",
      "tabName":"US Geographic Exposure",
      "fields":[
        {
          "fieldName":"PACIFC",
          "label":"Pacific",
          "format":"percent"
        },
        {
          "fieldName":"SWEST",
          "label":"Southwest",
          "format":"percent"
        },
        {
          "fieldName":"MOUNTN",
          "label":"Mountain",
          "format":"percent"
        },
        {
          "fieldName":"WNCENT",
          "label":"WN Central",
          "format":"percent"
        },
        {
          "fieldName":"ENCENT",
          "label":"EN Central",
          "format":"percent"
        },
        {
          "fieldName":"NEAST",
          "label":"Northeast",
          "format":"percent"
        },
        {
          "fieldName":"MEAST",
          "label":"Mideast",
          "format":"percent"
        },
        {
          "fieldName":"SEAST",
          "label":"Southeast",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "45":[
    {
      "tableName":"directional",
      "tabName":"Directional",
      "fields":[
        {
          "fieldName":"GLSE",
          "label":"Global Long-Short Equity",
          "format":"percent"
        },
        {
          "fieldName":"USLSEQ",
          "label":"U. S. Long-Short Equity",
          "format":"percent"
        },
        {
          "fieldName":"NUSLSE",
          "label":"Non-U.S. Long-Short Equity",
          "format":"percent"
        },
        {
          "fieldName":"LNGSHT",
          "label":"Long Short Equity",
          "format":"percent"
        },
        {
          "fieldName":"SHRTST",
          "label":"Short Bias Strategies",
          "format":"percent"
        },
        {
          "fieldName":"GBLMAC",
          "label":"Global Macro",
          "format":"percent"
        },
        {
          "fieldName":"EMRGST",
          "label":"Emerg. Mkts Strategies",
          "format":"percent"
        },
        {
          "fieldName":"FUTURE",
          "label":"Managed Futures",
          "format":"percent"
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"eventDriven",
      "tabName":"Event Driven",
      "fields":[
        {
          "fieldName":"MERGR",
          "label":"Merger Arbitrage",
          "format":"percent"
        },
        {
          "fieldName":"DISTR",
          "label":"Distressed",
          "format":"percent"
        },
        {
          "fieldName":"SPECL",
          "label":"Special Situations",
          "format":"percent"
        },
        {
          "fieldName":"MLTEVT",
          "label":"Multi-Strategy Event Driven",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"largestPosition",
          "label":"Largest Position (%)",
          "format":"percent"
        },
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"avgLiquidityMonths",
          "label":"Wtd Avg Liquidity (Months)",
          "format":"months"
        },
        {
          "fieldName":"longShortNetMktExposure",
          "label":"Long/Short Net Mkt Exposure",
          "format":"percent"
        },
        {
          "fieldName":"longShortGrossMktExposure",
          "label":"Long/Short Gross Mkt Exposure",
          "format":"percent"
        },
        {
          "fieldName":"portfolioLeverage",
          "label":"Portfolio Leverage",
          "format":"percent"
        },
        {
          "fieldName":"avgMgrLeverage",
          "label":"Wtd Avg Mgr Leverage",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"relativeValues",
      "tabName":"Relative Value",
      "fields":[
        {
          "fieldName":"CONVST",
          "label":"Convertible Strategies",
          "format":"percent"
        },
        {
          "fieldName":"NTRL",
          "label":"Market Neutral Equity",
          "format":"percent"
        },
        {
          "fieldName":"FIARB",
          "label":"Fixed Income Arbitrage",
          "format":"percent"
        },
        {
          "fieldName":"RELVAL",
          "label":"Multi-Strategy Relative Value",
          "format":"percent"
        },
        {
          "fieldName":"RELOTH",
          "label":"Relative Value Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "11":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"americanDepositoryReceipt",
          "label":"American Depository Receipt",
          "format":"percent"
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent"
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent"
        },
        {
          "fieldName":"convertibles",
          "label":"Convertibles",
          "format":"percent"
        },
        {
          "fieldName":"nonConvertPrefd",
          "label":"Non-Convert Prefd",
          "format":"percent"
        },
        {
          "fieldName":"bonds",
          "label":"Bonds",
          "format":"percent"
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"otherMisc",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"largeCapPct",
          "label":"% Large Cap ($wgt) > $15 B",
          "format":"percent"
        },
        {
          "fieldName":"midCapPct",
          "label":"% Mid Cap ($wgt) $3.5 - $15 B",
          "format":"percent"
        },
        {
          "fieldName":"smallCapWgt",
          "label":"% Small Cap ($wgt) $700M - $3.5 B",
          "format":"percent"
        },
        {
          "fieldName":"microCapPct",
          "label":"% Micro Cap ($wgt)< $700 M",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceForecastedEarnings",
          "label":"Wtg Avg Price/Forecasted Earnings",
          "format":"float"
        },
        {
          "fieldName":"avgPriceBookValue",
          "label":"Wtg Avg Price/Book Value",
          "format":"float"
        },
        {
          "fieldName":"forecastedEarningsGrowth5yr",
          "label":"5yr Forecasted Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"historicalEarningsGrowth5yr",
          "label":"5yr Historical Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "12":[
    {
      "tableName":"bondSectorExposures",
      "tabName":"Bond Sector Exposure",
      "fields":[
        {
          "fieldName":"AGNC",
          "label":"Gov Related",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CORP",
          "label":"Corporate (including 144A)",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TSECUR",
          "label":"Total Securitized",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"corporateSubSectorIndustryExposures",
      "tabName":"Corp Sub-Sector Ind Exposure",
      "fields":[
        {
          "fieldName":"BASIC",
          "label":"Basic Industry",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CAPGDS",
          "label":"Capital Goods",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONCYC",
          "label":"Consumer Discretionary",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONNC",
          "label":"Consumer Non-Cyclical",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TECH",
          "label":"Technology",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TRANS",
          "label":"Transportation",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COMMUN",
          "label":"Communication Services",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHIND",
          "label":"Other Industrials",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ELEC",
          "label":"Electric",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NATGAS",
          "label":"Natural Gas",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHUTL",
          "label":"Other Utilities",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BNK",
          "label":"Banking",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BAME",
          "label":"Brokerage Asset Manager Exchanges",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"FINCO",
          "label":"Finance Companies",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"INS",
          "label":"Insurance",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"REIT",
          "label":"Real Estate Investment Trust",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"countryExposures",
      "tabName":"Country Exposure",
      "fields":[
        {
          "fieldName":"ANG",
          "label":"Angola",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ARG",
          "label":"Argentina",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"AUL",
          "label":"Australia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"AUS",
          "label":"Austria",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"AZE",
          "label":"Azerbaijan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BAH",
          "label":"Bahrain",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BAN",
          "label":"Bangladesh",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BEL",
          "label":"Belgium",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BER",
          "label":"Bermuda",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BRA",
          "label":"Brazil",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BUL",
          "label":"Bulgaria",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CAN",
          "label":"Canada",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CNI",
          "label":"Channel Islands",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CHI",
          "label":"Chile",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CHN",
          "label":"China",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COL",
          "label":"Colombia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COS",
          "label":"Costa Rica",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COT",
          "label":"Cote D'Ivoire",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"HR",
          "label":"Croatia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CYP",
          "label":"Cyprus",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CZE",
          "label":"Czech Republic",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"DEN",
          "label":"Denmark",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"DOM",
          "label":"Dominican Republic",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ECD",
          "label":"Ecuador",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"EGY",
          "label":"Egypt",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ESL",
          "label":"El Salvador",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"EST",
          "label":"Estonia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"FIN",
          "label":"Finland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"FRA",
          "label":"France",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"GER",
          "label":"Germany",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"GHA",
          "label":"Ghana",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"GRE",
          "label":"Greece",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"GUA",
          "label":"Guatemala",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"HK",
          "label":"Hong Kong",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"HUN",
          "label":"Hungary",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ICE",
          "label":"Iceland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"IND",
          "label":"India",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"INO",
          "label":"Indonesia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"IRE",
          "label":"Ireland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ISR",
          "label":"Israel",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ITA",
          "label":"Italy",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"JAM",
          "label":"Jamaica",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"JPN",
          "label":"Japan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"JOR",
          "label":"Jordan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"KAZ",
          "label":"Kazakhstan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"KEN",
          "label":"Kenya",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"KUW",
          "label":"Kuwait",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"LEB",
          "label":"Lebanon",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"LTH",
          "label":"Lithuania",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"LUX",
          "label":"Luxembourg",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MAL",
          "label":"Malaysia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MUR",
          "label":"Mauritius",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MEX",
          "label":"Mexico",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MON",
          "label":"Mongolia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"MOR",
          "label":"Morocco",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NET",
          "label":"Netherlands",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NZE",
          "label":"New Zealand",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NIG",
          "label":"Nigeria",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NOR",
          "label":"Norway",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OMA",
          "label":"Oman",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PAK",
          "label":"Pakistan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PAN",
          "label":"Panama",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PAR",
          "label":"Paraguay",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PER",
          "label":"Peru",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"PHI",
          "label":"Philippines",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"POL",
          "label":"Poland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"POR",
          "label":"Portugal",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"QTR",
          "label":"Qatar",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ROM",
          "label":"Romania",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"RUS",
          "label":"Russia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"YUG",
          "label":"Serbia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SIN",
          "label":"Singapore",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SLO",
          "label":"Slovenia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SLV",
          "label":"Slovokia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SAF",
          "label":"South Africa",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SKO",
          "label":"South Korea",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SPA",
          "label":"Spain",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SRI",
          "label":"Sri Lanka",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SUP",
          "label":"Supranational",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SWE",
          "label":"Sweden",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"SWI",
          "label":"Switzerland",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TAI",
          "label":"Taiwan",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"THA",
          "label":"Thailand",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TUN",
          "label":"Tunisia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TUR",
          "label":"Turkey",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"UAE",
          "label":"U.A.E.",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"UKR",
          "label":"Ukraine",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"UK",
          "label":"United Kingdom",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"USA",
          "label":"United States",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"URU",
          "label":"Uruguay",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"VEN",
          "label":"Venezuela",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"VIE",
          "label":"Vietnam",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZAM",
          "label":"Zambia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOA",
          "label":"Other Emerg. Americas",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOE",
          "label":"Other Emerg. Europe",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOP",
          "label":"Other Emerg. Asia",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOO",
          "label":"Other Emerg. Mideast/Africa",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ZOT",
          "label":"Total Other Countries",
          "format":"percent",
          "contributionToDuration":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"currencyExposures",
      "tabName":"Currency Exposure",
      "fields":[
        {
          "fieldName":"ARGPES",
          "label":"Argentina Peso",
          "format":"percent"
        },
        {
          "fieldName":"AULDOL",
          "label":"Australian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"BRABRL",
          "label":"Brazilian Real",
          "format":"percent"
        },
        {
          "fieldName":"CANDOL",
          "label":"Canadian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"CHIPES",
          "label":"Chilean Peso",
          "format":"percent"
        },
        {
          "fieldName":"CHNYUA",
          "label":"Yuan Renminbi",
          "format":"percent"
        },
        {
          "fieldName":"COLPES",
          "label":"Columbian Peso",
          "format":"percent"
        },
        {
          "fieldName":"CZEKOR",
          "label":"Czech Koruna",
          "format":"percent"
        },
        {
          "fieldName":"DENKRO",
          "label":"Danish Krone",
          "format":"percent"
        },
        {
          "fieldName":"DOMPES",
          "label":"Dominican Peso",
          "format":"percent"
        },
        {
          "fieldName":"ECDSUC",
          "label":"Ecuador Sucre",
          "format":"percent"
        },
        {
          "fieldName":"EGYPOU",
          "label":"Egyptian Pound",
          "format":"percent"
        },
        {
          "fieldName":"ESLCOL",
          "label":"El Salvador Colon",
          "format":"percent"
        },
        {
          "fieldName":"EURECU",
          "label":"Euros",
          "format":"percent"
        },
        {
          "fieldName":"HKDOL",
          "label":"Hong Kong Dollar",
          "format":"percent"
        },
        {
          "fieldName":"HUNFOR",
          "label":"Hungarian Forint",
          "format":"percent"
        },
        {
          "fieldName":"INDRUP",
          "label":"Indian Rupee",
          "format":"percent"
        },
        {
          "fieldName":"INORUP",
          "label":"Indonesian Rupiah",
          "format":"percent"
        },
        {
          "fieldName":"ISRSHE",
          "label":"Israeli Shekel",
          "format":"percent"
        },
        {
          "fieldName":"JORDIN",
          "label":"Jordanian Dinar",
          "format":"percent"
        },
        {
          "fieldName":"JPNYEN",
          "label":"Japanese Yen",
          "format":"percent"
        },
        {
          "fieldName":"LEBPOU",
          "label":"Lebanese Pound",
          "format":"percent"
        },
        {
          "fieldName":"MALRIN",
          "label":"Malaysian Ringgit",
          "format":"percent"
        },
        {
          "fieldName":"MEXPES",
          "label":"Mexican Peso",
          "format":"percent"
        },
        {
          "fieldName":"MORDIR",
          "label":"Moroccan Dirham",
          "format":"percent"
        },
        {
          "fieldName":"NGRXOF",
          "label":"CFA Franc BCEAO",
          "format":"percent"
        },
        {
          "fieldName":"NIGNAI",
          "label":"Nigerian Naira",
          "format":"percent"
        },
        {
          "fieldName":"NORKRO",
          "label":"Norwegian Krone",
          "format":"percent"
        },
        {
          "fieldName":"NZEDOL",
          "label":"New Zealand Dollar",
          "format":"percent"
        },
        {
          "fieldName":"PAKRUP",
          "label":"Pakistan Rupee",
          "format":"percent"
        },
        {
          "fieldName":"PANPAB",
          "label":"Panamanian Balboa",
          "format":"percent"
        },
        {
          "fieldName":"PERSOL",
          "label":"Peruvian Nuevo Sol",
          "format":"percent"
        },
        {
          "fieldName":"PHIPES",
          "label":"Philippine Peso",
          "format":"percent"
        },
        {
          "fieldName":"POLZLY",
          "label":"Polish Zloty",
          "format":"percent"
        },
        {
          "fieldName":"RUSRUR",
          "label":"Russian Ruble",
          "format":"percent"
        },
        {
          "fieldName":"SINDOL",
          "label":"Singapore Dollar",
          "format":"percent"
        },
        {
          "fieldName":"SLVKOR",
          "label":"Slovakian Koruna",
          "format":"percent"
        },
        {
          "fieldName":"SKOWON",
          "label":"Korean Won",
          "format":"percent"
        },
        {
          "fieldName":"SAFRAN",
          "label":"South African Rand",
          "format":"percent"
        },
        {
          "fieldName":"SRILKR",
          "label":"Sri Lanka Rupee",
          "format":"percent"
        },
        {
          "fieldName":"SWEKRO",
          "label":"Swedish Krona",
          "format":"percent"
        },
        {
          "fieldName":"SWIFRA",
          "label":"Swiss Franc",
          "format":"percent"
        },
        {
          "fieldName":"TAIDOL",
          "label":"Taiwanese Dollar",
          "format":"percent"
        },
        {
          "fieldName":"THABAH",
          "label":"Thai Baht",
          "format":"percent"
        },
        {
          "fieldName":"TUNDIN",
          "label":"Tunisian Dinar",
          "format":"percent"
        },
        {
          "fieldName":"TURLIR",
          "label":"Turkish Lira",
          "format":"percent"
        },
        {
          "fieldName":"UKPOU",
          "label":"UK Pound",
          "format":"percent"
        },
        {
          "fieldName":"UKRUAH",
          "label":"Ukrainian Hryvnia",
          "format":"percent"
        },
        {
          "fieldName":"URUPES",
          "label":"Uruguayan Peso",
          "format":"percent"
        },
        {
          "fieldName":"USADOL",
          "label":"US Dollar",
          "format":"percent"
        },
        {
          "fieldName":"VENBOL",
          "label":"Venezuelan Bolivar",
          "format":"percent"
        },
        {
          "fieldName":"VIEDON",
          "label":"Vietnamese Dong",
          "format":"percent"
        },
        {
          "fieldName":"ZOAMIX",
          "label":"Other Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"ZOEMIX",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"ZOOMIX",
          "label":"Other Emerg. Mideast/Africa",
          "format":"percent"
        },
        {
          "fieldName":"ZOPMIX",
          "label":"Other Emerg. Asia",
          "format":"percent"
        },
        {
          "fieldName":"ZOTMIX",
          "label":"Total Other Countries",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"durationExposures",
      "tabName":"Duration Exposure",
      "fields":[
        {
          "fieldName":"DU1",
          "label":"Duration < 1",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU13",
          "label":"Duration 1-3",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU35",
          "label":"Duration 3-5",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU57",
          "label":"Duration 5-7",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU710",
          "label":"Duration 7-10",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU10",
          "label":"Duration > 10",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"nonInvestmentGradeDebt",
          "label":"Non-Investment Grade Debt",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"netEmgMktCurrencyExposure",
          "label":"Net Emg Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"privatePlacements144a",
          "label":"144A/Private Placements",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"inflationLinkedSecurities",
          "label":"Inflation-Linked Securities",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent",
          // "contributionToDuration":true
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"commonStock",
          "label":"Common Stock",
          "format":"percent"
        },
        {
          "fieldName":"convertible",
          "label":"Convertible",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexUpperBound",
          "label":"Duration Around Index Upper Bound",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexLowerBound",
          "label":"Duration Around Index Lower Bound",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveRealDurationYrs",
          "label":"Effective Real Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveMaturity",
          "label":"Effective Maturity (Wtd Avg Life)",
          "format":"float"
        },
        {
          "fieldName":"effectiveConvexity",
          "label":"Effective Convexity",
          "format":"float"
        },
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveYieldYtw",
          "label":"Effective Yield (YTW)",
          "format":"float"
        },
        {
          "fieldName":"effectiveRealYield",
          "label":"Effective Real Yield",
          "format":"float"
        },
        {
          "fieldName":"couponRate",
          "label":"Coupon Rate",
          "format":"float"
        },
        {
          "fieldName":"currentYield",
          "label":"Current Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"numDistinctIssuers",
          "label":"Number of Distinct Issuers",
          "format":"number"
        },
        {
          "fieldName":"largestCorpCreditIssuerPct",
          "label":"Largest Corp Credit Issuer (% TMV)",
          "format":"percent"
        },
        {
          "fieldName":"dtsRatio",
          "label":"DTS Ratio",
          "format":"float"
        },
        {
          "fieldName":"dts20Ratio",
          "label":"DTS-20 Ratio",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"qualityExposures",
      "tabName":"Quality Exposure",
      "fields":[
        {
          "fieldName":"QAAA",
          "label":"AAA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAP",
          "label":"AA+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAA",
          "label":"AA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAM",
          "label":"AA- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAP",
          "label":"A+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QA",
          "label":"A Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAM",
          "label":"A- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBP",
          "label":"BBB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBB",
          "label":"BBB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBM",
          "label":"BBB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBP",
          "label":"BB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBB",
          "label":"BB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBM",
          "label":"BB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBP",
          "label":"B+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QB",
          "label":"B Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBM",
          "label":"B- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCP",
          "label":"CCC+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCC",
          "label":"CCC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCM",
          "label":"CCC- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCC",
          "label":"CC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QC",
          "label":"C Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QD",
          "label":"D Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QNA",
          "label":"Not Rated Quality",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    }
  ],
  "92":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"realEstateOperatingCo",
          "label":"Real Estate Operating Co.",
          "format":"percent"
        },
        {
          "fieldName":"otherMisc",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"priceFfo",
          "label":"Price/FFO",
          "format":"float"
        },
        {
          "fieldName":"priceNav",
          "label":"Price/NAV",
          "format":"float"
        },
        {
          "fieldName":"forecastGrowthFfo",
          "label":"Forecast Growth FFO",
          "format":"float"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"propertySectors",
      "tabName":"Property Sectors",
      "fields":[
        {
          "fieldName":"OFFICE",
          "label":"Office",
          "format":"percent"
        },
        {
          "fieldName":"INDUST",
          "label":"Industrial",
          "format":"percent"
        },
        {
          "fieldName":"INDOFF",
          "label":"Mixed Use Off/Ind",
          "format":"percent"
        },
        {
          "fieldName":"RETAIL",
          "label":"Retail",
          "format":"percent"
        },
        {
          "fieldName":"LODRES",
          "label":"Hotels",
          "format":"percent"
        },
        {
          "fieldName":"APART",
          "label":"Multi-Family",
          "format":"percent"
        },
        {
          "fieldName":"RESID",
          "label":"Manuf Homes",
          "format":"percent"
        },
        {
          "fieldName":"SELSTO",
          "label":"Storage",
          "format":"percent"
        },
        {
          "fieldName":"DIVERS",
          "label":"Diversified",
          "format":"percent"
        },
        {
          "fieldName":"HEALTH",
          "label":"Health Care",
          "format":"percent"
        },
        {
          "fieldName":"SPECIA",
          "label":"Specialty",
          "format":"percent"
        },
        {
          "fieldName":"MORTGA",
          "label":"Mortgage",
          "format":"percent"
        },
        {
          "fieldName":"LAND",
          "label":"Land",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"unitedStatesGeographicExposures",
      "tabName":"US Geographic Exposure",
      "fields":[
        {
          "fieldName":"PACIFC",
          "label":"Pacific",
          "format":"percent"
        },
        {
          "fieldName":"SWEST",
          "label":"Southwest",
          "format":"percent"
        },
        {
          "fieldName":"MOUNTN",
          "label":"Mountain",
          "format":"percent"
        },
        {
          "fieldName":"WNCENT",
          "label":"WN Central",
          "format":"percent"
        },
        {
          "fieldName":"ENCENT",
          "label":"EN Central",
          "format":"percent"
        },
        {
          "fieldName":"NEAST",
          "label":"Northeast",
          "format":"percent"
        },
        {
          "fieldName":"MEAST",
          "label":"Mideast",
          "format":"percent"
        },
        {
          "fieldName":"SEAST",
          "label":"Southeast",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "131":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"americanDepositoryReceipt",
          "label":"American Depository Receipt",
          "format":"percent"
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent"
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent"
        },
        {
          "fieldName":"convertibles",
          "label":"Convertibles",
          "format":"percent"
        },
        {
          "fieldName":"nonConvertPrefd",
          "label":"Non-Convert Prefd",
          "format":"percent"
        },
        {
          "fieldName":"bonds",
          "label":"Bonds",
          "format":"percent"
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"otherMisc",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"largeCapPct",
          "label":"% Large Cap ($wgt) > $15 B",
          "format":"percent"
        },
        {
          "fieldName":"midCapPct",
          "label":"% Mid Cap ($wgt) $3.5 - $15 B",
          "format":"percent"
        },
        {
          "fieldName":"smallCapWgt",
          "label":"% Small Cap ($wgt) $700M - $3.5 B",
          "format":"percent"
        },
        {
          "fieldName":"microCapPct",
          "label":"% Micro Cap ($wgt)< $700 M",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceForecastedEarnings",
          "label":"Wtg Avg Price/Forecasted Earnings",
          "format":"float"
        },
        {
          "fieldName":"avgPriceBookValue",
          "label":"Wtg Avg Price/Book Value",
          "format":"float"
        },
        {
          "fieldName":"forecastedEarningsGrowth5yr",
          "label":"5yr Forecasted Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"historicalEarningsGrowth5yr",
          "label":"5yr Historical Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "15":[
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "61":[
    {
      "tableName":"currencyExposures",
      "tabName":"Currency Exposure",
      "fields":[
        {
          "fieldName":"USADOL",
          "label":"US Dollar",
          "format":"percent"
        },
        {
          "fieldName":"CANDOL",
          "label":"Canadian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"EURECU",
          "label":"Euros",
          "format":"percent"
        },
        {
          "fieldName":"UKPOU",
          "label":"UK Pound",
          "format":"percent"
        },
        {
          "fieldName":"AULDOL",
          "label":"Australian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"OECD",
          "label":"Other OECD",
          "format":"percent"
        },
        {
          "fieldName":"OECDNO",
          "label":"Other Non-OECD",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"infrastructureSectors",
      "tabName":"Infrastructure Sectors",
      "fields":[
        {
          "fieldName":"AIRPT",
          "label":"Airports",
          "format":"percent"
        },
        {
          "fieldName":"PORT",
          "label":"Ports",
          "format":"percent"
        },
        {
          "fieldName":"RAILRD",
          "label":"Railroads",
          "format":"percent"
        },
        {
          "fieldName":"TOLLRD",
          "label":"Toll Roads",
          "format":"percent"
        },
        {
          "fieldName":"TRANS",
          "label":"Transportation",
          "format":"percent"
        },
        {
          "fieldName":"ELEC",
          "label":"Electric",
          "format":"percent"
        },
        {
          "fieldName":"GASUTL",
          "label":"Gas Utilities",
          "format":"percent"
        },
        {
          "fieldName":"WATER",
          "label":"Water Utilites",
          "format":"percent"
        },
        {
          "fieldName":"MULTI",
          "label":"Multi-Utilities",
          "format":"percent"
        },
        {
          "fieldName":"POWER",
          "label":"Power Generation",
          "format":"percent"
        },
        {
          "fieldName":"PIPE",
          "label":"Pipelines",
          "format":"percent"
        },
        {
          "fieldName":"CARCAP",
          "label":"Carbon Capture",
          "format":"percent"
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent"
        },
        {
          "fieldName":"ENGSTO",
          "label":"Energy Storage/Batteries",
          "format":"percent"
        },
        {
          "fieldName":"ETRANS",
          "label":"Electrified Transport",
          "format":"percent"
        },
        {
          "fieldName":"HYDRO",
          "label":"Hydro",
          "format":"percent"
        },
        {
          "fieldName":"SOLAR",
          "label":"Solar",
          "format":"percent"
        },
        {
          "fieldName":"WIND",
          "label":"Wind",
          "format":"percent"
        },
        {
          "fieldName":"RENEW",
          "label":"Energy Transition - Other",
          "format":"percent"
        },
        {
          "fieldName":"DATAC",
          "label":"Data Center",
          "format":"percent"
        },
        {
          "fieldName":"FIBER",
          "label":"Fiber",
          "format":"percent"
        },
        {
          "fieldName":"TOWER",
          "label":"Towers",
          "format":"percent"
        },
        {
          "fieldName":"COMOTH",
          "label":"Communcations - Other",
          "format":"percent"
        },
        {
          "fieldName":"SOCIAL",
          "label":"Social",
          "format":"percent"
        },
        {
          "fieldName":"WASTE",
          "label":"Waste",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"returnTarget",
          "label":"Return Target",
          "format":"percent"
        },
        {
          "fieldName":"incomeReturnTarget",
          "label":"Income Return Target",
          "format":"percent"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"investmentsWhollyOwned",
          "label":"Investments Wholly Owned",
          "format":"percent"
        },
        {
          "fieldName":"investmentsMajorityOwned",
          "label":"Investments Majority Owned",
          "format":"percent"
        },
        {
          "fieldName":"investmentsMinorityOwned",
          "label":"Investments Minority Owned",
          "format":"percent"
        },
        {
          "fieldName":"avgInvestmentSize",
          "label":"Average Investment Size ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgAgeOfAssets",
          "label":"Average Age of Assets",
          "format":"years"
        },
        {
          "fieldName":"currentLeverage",
          "label":"Current Leverage",
          "format":"percent"
        },
        {
          "fieldName":"projectedInternalRateOfReturn",
          "label":"Projected Internal Rate of Return",
          "format":"percent"
        },
        {
          "fieldName":"projectedEquityMultiple",
          "label":"Projected Equity Multiple",
          "format":"multiplier"
        },
        {
          "fieldName":"discountRate",
          "label":"Discount Rate",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"realEstateInvestmentSizeDiversifications",
      "tabName":"RE Investment Size Diversification",
      "fields":[
        {
          "fieldName":"REBIL",
          "label":"$1 Billion +",
          "format":"percent"
        },
        {
          "fieldName":"REFIV",
          "label":"$500 Million - $1Billion",
          "format":"percent"
        },
        {
          "fieldName":"RETVF",
          "label":"$250 Million - $500 Million",
          "format":"percent"
        },
        {
          "fieldName":"REHUN",
          "label":"$100 Million - $250 Million",
          "format":"percent"
        },
        {
          "fieldName":"REONE",
          "label":"$1 Million - $99 Million",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"realEstateInvestmentStageDiversifications",
      "tabName":"RE Investment Stage Diversification",
      "fields":[
        {
          "fieldName":"BROWN",
          "label":"Brownfield Investment",
          "format":"percent"
        },
        {
          "fieldName":"GREEN",
          "label":"Greenfield Investments",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"USCAN",
          "label":"US & Canada",
          "format":"percent"
        },
        {
          "fieldName":"OECDAM",
          "label":"Other OECD Americas",
          "format":"percent"
        },
        {
          "fieldName":"UK",
          "label":"United Kingdom",
          "format":"percent"
        },
        {
          "fieldName":"OECDEU",
          "label":"OECD Europe",
          "format":"percent"
        },
        {
          "fieldName":"AUL",
          "label":"Australia",
          "format":"percent"
        },
        {
          "fieldName":"OECDAS",
          "label":"Other OECD Asia",
          "format":"percent"
        },
        {
          "fieldName":"OECDNO",
          "label":"Other Non-OECD",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "146":[
    {
      "tableName":"privateEquityGlobalGeographies",
      "tabName":"Global Geography",
      "fields":[
        {
          "fieldName":"USA",
          "label":"United States",
          "format":"percent"
        },
        {
          "fieldName":"CAN",
          "label":"Canada",
          "format":"percent"
        },
        {
          "fieldName":"EUR",
          "label":"Europe",
          "format":"percent"
        },
        {
          "fieldName":"ASP",
          "label":"Asia/Pacific",
          "format":"percent"
        },
        {
          "fieldName":"LTA",
          "label":"Latin America & Caribbean",
          "format":"percent"
        },
        {
          "fieldName":"MDE",
          "label":"Middle East",
          "format":"percent"
        },
        {
          "fieldName":"AFR",
          "label":"Africa",
          "format":"percent"
        },
        {
          "fieldName":"OTH",
          "label":"Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"privateEquityIndustries",
      "tabName":"Industry",
      "fields":[
        {
          "fieldName":"COMMUN",
          "label":"Communication Services",
          "format":"percent"
        },
        {
          "fieldName":"CONCYC",
          "label":"Consumer Discretionary",
          "format":"percent"
        },
        {
          "fieldName":"CONSTA",
          "label":"Consumer Staples",
          "format":"percent"
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent"
        },
        {
          "fieldName":"FINANC",
          "label":"Financial",
          "format":"percent"
        },
        {
          "fieldName":"HEALTH",
          "label":"Health Care",
          "format":"percent"
        },
        {
          "fieldName":"INDEQU",
          "label":"Industrials",
          "format":"percent"
        },
        {
          "fieldName":"PUBUTL",
          "label":"Utilities",
          "format":"percent"
        },
        {
          "fieldName":"RAWMAT",
          "label":"Materials",
          "format":"percent"
        },
        {
          "fieldName":"REALES",
          "label":"Real Estate",
          "format":"percent"
        },
        {
          "fieldName":"TECH",
          "label":"Technology",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"privateEquityUnitedStatesGeographies",
      "tabName":"US Geography",
      "fields":[
        {
          "fieldName":"NORAT",
          "label":"North Atlantic",
          "format":"percent"
        },
        {
          "fieldName":"MIDAT",
          "label":"Mid-Atlantic",
          "format":"percent"
        },
        {
          "fieldName":"SEAST",
          "label":"Southeast",
          "format":"percent"
        },
        {
          "fieldName":"MIDWT",
          "label":"Mid-West",
          "format":"percent"
        },
        {
          "fieldName":"SWROCK",
          "label":"Southwest/Rockies",
          "format":"percent"
        },
        {
          "fieldName":"WESTPN",
          "label":"West/Pacific Northwest",
          "format":"percent"
        },
        {
          "fieldName":"EUROPE",
          "label":"Europe",
          "format":"percent"
        },
        {
          "fieldName":"PACBAS",
          "label":"Pacific Basin",
          "format":"percent"
        },
        {
          "fieldName":"OTH",
          "label":"Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"strategies",
      "tabName":"Strategy",
      "fields":[
        {
          "fieldName": "DIRLND",
          "label": "Direct Lending",
          "format":"percent"
        },
        {
          "fieldName": "DISSP",
          "label": "Distressed/Special Situations",
          "format":"percent"
        },
        {
          "fieldName": "JCMEZZ",
          "label": "Junior Capital/Mezzanine",
          "format":"percent"
        },
        {
          "fieldName": "OPPMLT",
          "label": "Opportunistic/Multistrategy",
          "format":"percent"
        },
        {
          "fieldName": "NICHE",
          "label": "Niche",
          "format":"percent"
        },
        {
          "fieldName": "SPFIN",
          "label": "Specialty Finance/ABL",
          "format":"percent"
        },
        {
          "fieldName": "STRCRD",
          "label": "Structured Credit",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"investmentType",
      "tabName":"Investment Type",
      "fields":[
        {
          "fieldName": "PRIMRY",
          "label": "Primary",
          "format":"percent"
        },
        {
          "fieldName": "SECINT",
          "label": "Secondary",
          "format":"percent"
        },
        {
          "fieldName": "COINV",
          "label": "Co-Investments",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "57":[
    {
      "tableName":"privateEquityGlobalGeographies",
      "tabName":"Global Geography",
      "fields":[
        {
          "fieldName":"USA",
          "label":"United States",
          "format":"percent"
        },
        {
          "fieldName":"CAN",
          "label":"Canada",
          "format":"percent"
        },
        {
          "fieldName":"EUR",
          "label":"Europe",
          "format":"percent"
        },
        {
          "fieldName":"ASP",
          "label":"Asia/Pacific",
          "format":"percent"
        },
        {
          "fieldName":"LTA",
          "label":"Latin America & Caribbean",
          "format":"percent"
        },
        {
          "fieldName":"MDE",
          "label":"Middle East",
          "format":"percent"
        },
        {
          "fieldName":"AFR",
          "label":"Africa",
          "format":"percent"
        },
        {
          "fieldName":"OTH",
          "label":"Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"privateEquityIndustries",
      "tabName":"Industry",
      "fields":[
        {
          "fieldName":"COMMUN",
          "label":"Communication Services",
          "format":"percent"
        },
        {
          "fieldName":"CONCYC",
          "label":"Consumer Discretionary",
          "format":"percent"
        },
        {
          "fieldName":"CONSTA",
          "label":"Consumer Staples",
          "format":"percent"
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent"
        },
        {
          "fieldName":"FINANC",
          "label":"Financial",
          "format":"percent"
        },
        {
          "fieldName":"HEALTH",
          "label":"Health Care",
          "format":"percent"
        },
        {
          "fieldName":"INDEQU",
          "label":"Industrials",
          "format":"percent"
        },
        {
          "fieldName":"PUBUTL",
          "label":"Utilities",
          "format":"percent"
        },
        {
          "fieldName":"RAWMAT",
          "label":"Materials",
          "format":"percent"
        },
        {
          "fieldName":"REALES",
          "label":"Real Estate",
          "format":"percent"
        },
        {
          "fieldName":"TECH",
          "label":"Technology",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"privateEquityUnitedStatesGeographies",
      "tabName":"US Geography",
      "fields":[
        {
          "fieldName":"NORAT",
          "label":"North Atlantic",
          "format":"percent"
        },
        {
          "fieldName":"MIDAT",
          "label":"Mid-Atlantic",
          "format":"percent"
        },
        {
          "fieldName":"SEAST",
          "label":"Southeast",
          "format":"percent"
        },
        {
          "fieldName":"MIDWT",
          "label":"Mid-West",
          "format":"percent"
        },
        {
          "fieldName":"SWROCK",
          "label":"Southwest/Rockies",
          "format":"percent"
        },
        {
          "fieldName":"WESTPN",
          "label":"West/Pacific Northwest",
          "format":"percent"
        },
        {
          "fieldName":"EUROPE",
          "label":"Europe",
          "format":"percent"
        },
        {
          "fieldName":"PACBAS",
          "label":"Pacific Basin",
          "format":"percent"
        },
        {
          "fieldName":"OTH",
          "label":"Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"strategies",
      "tabName":"Strategy",
      "fields":[
        {
          "fieldName":"VENTUR",
          "label":"Venture Capital",
          "format":"percent"
        },
        {
          "fieldName":"BUYOUT",
          "label":"Buyout",
          "format":"percent"
        },
        {
          "fieldName":"GROWTH",
          "label":"Growth",
          "format":"percent"
        },
        {
          "fieldName":"SPSIT",
          "label":"Special Situations",
          "format":"percent"
        },
        {
          "fieldName":"MEZZ",
          "label":"Mezzanine",
          "format":"percent"
        },
        {
          "fieldName":"DISST",
          "label":"Distressed for Control",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"investmentType",
      "tabName":"Investment Type",
      "fields":[
        {
          "fieldName": "PRIMRY",
          "label": "Primary",
          "format":"percent"
        },
        {
          "fieldName": "SECINT",
          "label": "Secondary",
          "format":"percent"
        },
        {
          "fieldName": "COINV",
          "label": "Co-Investments",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "109":[
    {
      "tableName":"currencyExposures",
      "tabName":"Currency Exposure",
      "fields":[
        {
          "fieldName":"USADOL",
          "label":"US Dollar",
          "format":"percent"
        },
        {
          "fieldName":"CANDOL",
          "label":"Canadian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"EURECU",
          "label":"Euros",
          "format":"percent"
        },
        {
          "fieldName":"UKPOU",
          "label":"UK Pound",
          "format":"percent"
        },
        {
          "fieldName":"AULDOL",
          "label":"Australian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"OECD",
          "label":"Other OECD",
          "format":"percent"
        },
        {
          "fieldName":"OECDNO",
          "label":"Other Non-OECD",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"infrastructureSectors",
      "tabName":"Infrastructure Sectors",
      "fields":[
        {
          "fieldName":"AIRPT",
          "label":"Airports",
          "format":"percent"
        },
        {
          "fieldName":"PORT",
          "label":"Ports",
          "format":"percent"
        },
        {
          "fieldName":"RAILRD",
          "label":"Railroads",
          "format":"percent"
        },
        {
          "fieldName":"TOLLRD",
          "label":"Toll Roads",
          "format":"percent"
        },
        {
          "fieldName":"TRANS",
          "label":"Transportation",
          "format":"percent"
        },
        {
          "fieldName":"ELEC",
          "label":"Electric",
          "format":"percent"
        },
        {
          "fieldName":"GASUTL",
          "label":"Gas Utilities",
          "format":"percent"
        },
        {
          "fieldName":"WATER",
          "label":"Water Utilites",
          "format":"percent"
        },
        {
          "fieldName":"MULTI",
          "label":"Multi-Utilities",
          "format":"percent"
        },
        {
          "fieldName":"POWER",
          "label":"Power Generation",
          "format":"percent"
        },
        {
          "fieldName":"PIPE",
          "label":"Pipelines",
          "format":"percent"
        },
        {
          "fieldName":"CARCAP",
          "label":"Carbon Capture",
          "format":"percent"
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent"
        },
        {
          "fieldName":"ENGSTO",
          "label":"Energy Storage/Batteries",
          "format":"percent"
        },
        {
          "fieldName":"ETRANS",
          "label":"Electrified Transport",
          "format":"percent"
        },
        {
          "fieldName":"HYDRO",
          "label":"Hydro",
          "format":"percent"
        },
        {
          "fieldName":"SOLAR",
          "label":"Solar",
          "format":"percent"
        },
        {
          "fieldName":"WIND",
          "label":"Wind",
          "format":"percent"
        },
        {
          "fieldName":"RENEW",
          "label":"Energy Transition - Other",
          "format":"percent"
        },
        {
          "fieldName":"DATAC",
          "label":"Data Center",
          "format":"percent"
        },
        {
          "fieldName":"FIBER",
          "label":"Fiber",
          "format":"percent"
        },
        {
          "fieldName":"TOWER",
          "label":"Towers",
          "format":"percent"
        },
        {
          "fieldName":"COMOTH",
          "label":"Communcations - Other",
          "format":"percent"
        },
        {
          "fieldName":"SOCIAL",
          "label":"Social",
          "format":"percent"
        },
        {
          "fieldName":"WASTE",
          "label":"Waste",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"returnTarget",
          "label":"Return Target",
          "format":"percent"
        },
        {
          "fieldName":"incomeReturnTarget",
          "label":"Income Return Target",
          "format":"percent"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"investmentsWhollyOwned",
          "label":"Investments Wholly Owned",
          "format":"number"
        },
        {
          "fieldName":"investmentsMajorityOwned",
          "label":"Investments Majority Owned",
          "format":"number"
        },
        {
          "fieldName":"investmentsMinorityOwned",
          "label":"Investments Minority Owned",
          "format":"number"
        },
        {
          "fieldName":"avgInvestmentSize",
          "label":"Average Investment Size ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgAgeOfAssets",
          "label":"Average Age of Assets",
          "format":"years"
        },
        {
          "fieldName":"currentLeverage",
          "label":"Current Leverage",
          "format":"percent"
        },
        {
          "fieldName":"projectedInternalRateOfReturn",
          "label":"Projected Internal Rate of Return",
          "format":"percent"
        },
        {
          "fieldName":"projectedEquityMultiple",
          "label":"Projected Equity Multiple",
          "format":"multiplier"
        },
        {
          "fieldName":"discountRate",
          "label":"Discount Rate",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"realEstateInvestmentSizeDiversifications",
      "tabName":"RE Investment Size Diversification",
      "fields":[
        {
          "fieldName":"REBIL",
          "label":"$1 Billion +",
          "format":"percent"
        },
        {
          "fieldName":"REFIV",
          "label":"$500 Million - $1Billion",
          "format":"percent"
        },
        {
          "fieldName":"RETVF",
          "label":"$250 Million - $500 Million",
          "format":"percent"
        },
        {
          "fieldName":"REHUN",
          "label":"$100 Million - $250 Million",
          "format":"percent"
        },
        {
          "fieldName":"REONE",
          "label":"$1 Million - $99 Million",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"realEstateInvestmentStageDiversifications",
      "tabName":"RE Investment Stage Diversification",
      "fields":[
        {
          "fieldName":"BROWN",
          "label":"Brownfield Investment",
          "format":"percent"
        },
        {
          "fieldName":"GREEN",
          "label":"Greenfield Investments",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"USCAN",
          "label":"US & Canada",
          "format":"percent"
        },
        {
          "fieldName":"OECDAM",
          "label":"Other OECD Americas",
          "format":"percent"
        },
        {
          "fieldName":"UK",
          "label":"United Kingdom",
          "format":"percent"
        },
        {
          "fieldName":"OECDEU",
          "label":"OECD Europe",
          "format":"percent"
        },
        {
          "fieldName":"AUL",
          "label":"Australia",
          "format":"percent"
        },
        {
          "fieldName":"OECDAS",
          "label":"Other OECD Asia",
          "format":"percent"
        },
        {
          "fieldName":"OECDNO",
          "label":"Other Non-OECD",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "9":[
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"nonDirectPropertyInvestments",
          "label":"Non-Direct Property Investments",
          "format":"number"
        },
        {
          "fieldName":"jointVentures",
          "label":"Joint Ventures",
          "format":"percent"
        },
        {
          "fieldName":"avgLeverage",
          "label":"Average Leverage",
          "format":"percent"
        },
        {
          "fieldName":"currentLeverage",
          "label":"Current Leverage",
          "format":"percent"
        },
        {
          "fieldName":"avgInvestmentSize",
          "label":"Average Investment Size ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgAgeOfAssets",
          "label":"Average Age of Assets",
          "format":"years"
        },
        {
          "fieldName":"portfolioDebt",
          "label":"Portfolio Debt ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"portfolioDebtInterestRate",
          "label":"Portfolio Debt Interest Rate",
          "format":"percent"
        },
        {
          "fieldName":"propertyDebt",
          "label":"Property Debt ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"projectedInternalRateOfReturn",
          "label":"Projected Internal Rate of Return",
          "format":"percent"
        },
        {
          "fieldName":"projectedEquityMultiple",
          "label":"Projected Equity Multiple",
          "format":"multiplier"
        },
        {
          "fieldName":"numOfParticipants",
          "label":"Number of Participants",
          "format":"number"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"numOfRealizations",
          "label":"Number of Realizations",
          "format":"number"
        },
        {
          "fieldName":"squareFeetInPortfolio",
          "label":"Square Feet in Portfolio",
          "format":"sqft"
        },
        {
          "fieldName":"occupancy",
          "label":"Occupancy",
          "format":"percent"
        },
        {
          "fieldName":"impliedCapRate",
          "label":"Implied Cap Rate",
          "format":"percent"
        },
        {
          "fieldName":"top5ParticipantsOwnedPct",
          "label":"% Owned by Top 5 Participants",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"propertySectors",
      "tabName":"Property Sectors",
      "fields":[
        {
          "fieldName":"OFFICE",
          "label":"Office",
          "format":"percent"
        },
        {
          "fieldName":"INDUST",
          "label":"Industrial",
          "format":"percent"
        },
        {
          "fieldName":"INDOFF",
          "label":"Mixed Use Off/Ind",
          "format":"percent"
        },
        {
          "fieldName":"RETAIL",
          "label":"Retail",
          "format":"percent"
        },
        {
          "fieldName":"LODRES",
          "label":"Hotels",
          "format":"percent"
        },
        {
          "fieldName":"APART",
          "label":"Multi-Family",
          "format":"percent"
        },
        {
          "fieldName":"RESID",
          "label":"Manuf Homes",
          "format":"percent"
        },
        {
          "fieldName":"SELSTO",
          "label":"Storage",
          "format":"percent"
        },
        {
          "fieldName":"DIVERS",
          "label":"Diversified",
          "format":"percent"
        },
        {
          "fieldName":"HEALTH",
          "label":"Health Care",
          "format":"percent"
        },
        {
          "fieldName":"LAND",
          "label":"Land",
          "format":"percent"
        },
        {
          "fieldName":"STUHOU",
          "label":"Student Housing",
          "format":"percent"
        },
        {
          "fieldName":"SENHOU",
          "label":"Senior Housing",
          "format":"percent"
        },
        {
          "fieldName":"FSRES",
          "label":"For Sale Residential",
          "format":"percent"
        },
        {
          "fieldName":"PARK",
          "label":"Parking",
          "format":"percent"
        },
        {
          "fieldName":"RECORP",
          "label":"Corporate",
          "format":"percent"
        },
        {
          "fieldName":"DATAC",
          "label":"Data Center",
          "format":"percent"
        },
        {
          "fieldName":"MIXUS",
          "label":"Mixed Use",
          "format":"percent"
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"realEstateInvestmentSizeDiversifications",
      "tabName":"RE Investment Size Diversification",
      "fields":[
        {
          "fieldName":"RELRG",
          "label":">$150 M",
          "format":"percent"
        },
        {
          "fieldName":"REMED",
          "label":"$100 M - $150 M",
          "format":"percent"
        },
        {
          "fieldName":"RESMD",
          "label":"$50 M - $100M",
          "format":"percent"
        },
        {
          "fieldName":"RESML",
          "label":"$20 M - $50 M",
          "format":"percent"
        },
        {
          "fieldName":"REMIC",
          "label":"< $20 M",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"realEstateInvestmentStageDiversifications",
      "tabName":"RE Investment Stage Diversification",
      "fields":[
        {
          "fieldName":"DEVFOR",
          "label":"Development/Forward Commitment",
          "format":"percent"
        },
        {
          "fieldName":"LEASE",
          "label":"Leasing",
          "format":"percent"
        },
        {
          "fieldName":"FULLEA",
          "label":"Fully/Substantially Leased",
          "format":"percent"
        },
        {
          "fieldName":"REHAB",
          "label":"Rehabilitation",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"realEstateRegionalExposures",
      "tabName":"RE Regional Exposures",
      "fields":[
        {
          "fieldName":"AFR",
          "label":"Africa",
          "format":"percent"
        },
        {
          "fieldName":"AUL",
          "label":"Australia",
          "format":"percent"
        },
        {
          "fieldName":"BRA",
          "label":"Brazil",
          "format":"percent"
        },
        {
          "fieldName":"CAN",
          "label":"Canada",
          "format":"percent"
        },
        {
          "fieldName":"CENEUR",
          "label":"Central Europe",
          "format":"percent"
        },
        {
          "fieldName":"EEUR",
          "label":"Eastern Europe",
          "format":"percent"
        },
        {
          "fieldName":"FRA",
          "label":"France",
          "format":"percent"
        },
        {
          "fieldName":"GER",
          "label":"Germany",
          "format":"percent"
        },
        {
          "fieldName":"GRCHN",
          "label":"Greater China",
          "format":"percent"
        },
        {
          "fieldName":"IND",
          "label":"India",
          "format":"percent"
        },
        {
          "fieldName":"JPN",
          "label":"Japan",
          "format":"percent"
        },
        {
          "fieldName":"MEX",
          "label":"Mexico",
          "format":"percent"
        },
        {
          "fieldName":"NZE",
          "label":"New Zealand",
          "format":"percent"
        },
        {
          "fieldName":"NORREG",
          "label":"Nordic Region",
          "format":"percent"
        },
        {
          "fieldName":"OTHEUR",
          "label":"Other Europe",
          "format":"percent"
        },
        {
          "fieldName":"LAOTH",
          "label":"Other Latin America",
          "format":"percent"
        },
        {
          "fieldName":"SAOTH",
          "label":"Other Southeast Asia/Pacific",
          "format":"percent"
        },
        {
          "fieldName":"SIN",
          "label":"Singapore",
          "format":"percent"
        },
        {
          "fieldName":"SKO",
          "label":"South Korea",
          "format":"percent"
        },
        {
          "fieldName":"SEUR",
          "label":"Southern Europe",
          "format":"percent"
        },
        {
          "fieldName":"UK",
          "label":"United Kingdom",
          "format":"percent"
        },
        {
          "fieldName":"USA",
          "label":"United States",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"unitedStatesGeographicExposures",
      "tabName":"US Geographic Exposure",
      "fields":[
        {
          "fieldName":"PACIFC",
          "label":"Pacific",
          "format":"percent"
        },
        {
          "fieldName":"SWEST",
          "label":"Southwest",
          "format":"percent"
        },
        {
          "fieldName":"MOUNTN",
          "label":"Mountain",
          "format":"percent"
        },
        {
          "fieldName":"WNCENT",
          "label":"WN Central",
          "format":"percent"
        },
        {
          "fieldName":"ENCENT",
          "label":"EN Central",
          "format":"percent"
        },
        {
          "fieldName":"NEAST",
          "label":"Northeast",
          "format":"percent"
        },
        {
          "fieldName":"MEAST",
          "label":"Mideast",
          "format":"percent"
        },
        {
          "fieldName":"SEAST",
          "label":"Southeast",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "73":[
    {
      "tableName":"bondSectorExposures",
      "tabName":"Bond Sector Exposure",
      "fields":[
        {
          "fieldName":"GICBIC",
          "label":"Traditional GIC/BIC",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SAGIC",
          "label":"Separate Account GIC",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SAGMA",
          "label":"Separate Account GIC-Maturing",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MATSYN",
          "label":"Maturing Synthetic",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CDSYN",
          "label":"Constant Duration Synthetic",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SVPF",
          "label":"Stable Value Pooled Fund",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"corporateSubSectorIndustryExposures",
      "tabName":"Corp Sub-Sector Ind Exposure",
      "fields":[
        {
          "fieldName":"BASIC",
          "label":"Basic Industry",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CAPGDS",
          "label":"Capital Goods",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONCYC",
          "label":"Consumer Discretionary",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"CONNC",
          "label":"Consumer Non-Cyclical",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TECH",
          "label":"Technology",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"TRANS",
          "label":"Transportation",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"COMMUN",
          "label":"Communication Services",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHIND",
          "label":"Other Industrials",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"ELEC",
          "label":"Electric",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"NATGAS",
          "label":"Natural Gas",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"OTHUTL",
          "label":"Other Utilities",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BNK",
          "label":"Banking",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"BAME",
          "label":"Brokerage Asset Manager Exchanges",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"FINCO",
          "label":"Finance Companies",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"INS",
          "label":"Insurance",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        },
        {
          "fieldName":"REIT",
          "label":"Real Estate Investment Trust",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true,
          "contributionToDts":true,
          "relativeContributionToDts":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"durationExposures",
      "tabName":"Duration Exposure",
      "fields":[
        {
          "fieldName":"DU1",
          "label":"Duration < 1",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU13",
          "label":"Duration 1-3",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU35",
          "label":"Duration 3-5",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU57",
          "label":"Duration 5-7",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU710",
          "label":"Duration 7-10",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU10",
          "label":"Duration > 10",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"mktToBookValRatio",
          "label":"Market to Book Value Ratio",
          "format":"float"
        },
        {
          "fieldName":"totalManagedInternally",
          "label":"Total % Managed Internally",
          "format":"percent"
        },
        {
          "fieldName":"grossAnnualCreditingRate",
          "label":"Gross Annual Crediting Rate (%)",
          "format":"percent"
        },
        {
          "fieldName":"yieldOfUnderlyingAssets",
          "label":"Yield of Underlying Assets (%)",
          "format":"percent"
        },
        {
          "fieldName":"numOfWrapProviders",
          "label":"Number of Wrap Providers",
          "format":"number"
        },
        {
          "fieldName":"dtsRatio",
          "label":"DTS Ratio",
          "format":"float"
        },
        {
          "fieldName":"dts20Ratio",
          "label":"DTS-20 Ratio",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"qualityExposures",
      "tabName":"Quality Exposure",
      "fields":[
        {
          "fieldName":"QAAA",
          "label":"AAA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAP",
          "label":"AA+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAA",
          "label":"AA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAM",
          "label":"AA- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAP",
          "label":"A+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QA",
          "label":"A Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAM",
          "label":"A- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBP",
          "label":"BBB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBB",
          "label":"BBB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBM",
          "label":"BBB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBP",
          "label":"BB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBB",
          "label":"BB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBM",
          "label":"BB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBP",
          "label":"B+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QB",
          "label":"B Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBM",
          "label":"B- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCP",
          "label":"CCC+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCC",
          "label":"CCC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCM",
          "label":"CCC- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCC",
          "label":"CC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QC",
          "label":"C Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QD",
          "label":"D Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QNA",
          "label":"Not Rated Quality",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"wrap",
      "tabName":"Wrap",
      "fields":[
        {
          "fieldName":"PTWRAP",
          "label":"Participating Wrap",
          "format":"percent"
        },
        {
          "fieldName":"NPWRAP",
          "label":"Non-Participating Wrap",
          "format":"percent"
        },
        {
          "fieldName":"HYWRAP",
          "label":"Hybrid Wrap",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "76":[
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"commonStock",
          "label":"Common Stock",
          "format":"percent"
        },
        {
          "fieldName":"bonds",
          "label":"Bonds",
          "format":"percent"
        },
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent"
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"durationVariationVsBenchPct",
          "label":"Duration Variation vs Bench (+-%)",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexUpperBound",
          "label":"Duration Around Index Upper Bound",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexLowerBound",
          "label":"Duration Around Index Lower Bound",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"largeCapPct",
          "label":"% Large Cap ($wgt) > $15 B",
          "format":"percent"
        },
        {
          "fieldName":"midCapPct",
          "label":"% Mid Cap ($wgt) $3.5 - $15 B",
          "format":"percent"
        },
        {
          "fieldName":"smallCapWgt",
          "label":"% Small Cap ($wgt) $700M - $3.5 B",
          "format":"percent"
        },
        {
          "fieldName":"microCapPct",
          "label":"% Micro Cap ($wgt)< $700 M",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceForecastedEarnings",
          "label":"Wtg Avg Price/Forecasted Earnings",
          "format":"float"
        },
        {
          "fieldName":"avgPriceBookValue",
          "label":"Wtg Avg Price/Book Value",
          "format":"float"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        }
      ],
      "totalAll":false
    }
  ],
  "60":[
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"returnTarget",
          "label":"Return Target",
          "format":"percent"
        },
        {
          "fieldName":"realReturnTarget",
          "label":"Real Return Target",
          "format":"percent"
        },
        {
          "fieldName":"incomeReturnTarget",
          "label":"Income Return Target",
          "format":"percent"
        },
        {
          "fieldName":"nonDirectPropertyInvestments",
          "label":"Non-Direct Property Investments",
          "format":"number"
        },
        {
          "fieldName":"jointVentures",
          "label":"Joint Ventures",
          "format":"number"
        },
        {
          "fieldName":"avgLeverage",
          "label":"Average Leverage",
          "format":"percent"
        },
        {
          "fieldName":"portfolioDebt",
          "label":"Portfolio Debt ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"portfolioDebtInterestRate",
          "label":"Portfolio Debt Interest Rate",
          "format":"percent"
        },
        {
          "fieldName":"propertyDebt",
          "label":"Property Debt ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"numOfParticipants",
          "label":"Number of Participants",
          "format":"number"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"top5ParticipantsOwnedPct",
          "label":"% Owned by Top 5 Participants",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"timberlandRegions",
      "tabName":"Timberland Regions",
      "fields":[
        {
          "fieldName":"NEAST",
          "label":"Northeast",
          "format":"percent"
        },
        {
          "fieldName":"SOUTH",
          "label":"South",
          "format":"percent"
        },
        {
          "fieldName":"PACNW",
          "label":"Pacific Northwest",
          "format":"percent"
        },
        {
          "fieldName":"LAKE",
          "label":"Lake States",
          "format":"percent"
        },
        {
          "fieldName":"HAWAII",
          "label":"Hawaii",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"timberlandWoodTypes",
      "tabName":"Timberland Wood Types",
      "fields":[
        {
          "fieldName":"HARDWD",
          "label":"Hardwoods",
          "format":"percent"
        },
        {
          "fieldName":"SOFTWD",
          "label":"Softwoods",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ],
  "50":[
    {
      "tableName":"bondSectorExposures",
      "tabName":"Bond Sector Exposure",
      "fields":[
        {
          "fieldName":"TREA",
          "label":"US Treasuries",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AGNC",
          "label":"Gov Related",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CORP",
          "label":"Corporate (including 144A)",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ABSY",
          "label":"Asset Backed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MORT",
          "label":"Agency RMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"NRMBS",
          "label":"Non-Agency RMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CMBS",
          "label":"CMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CMO",
          "label":"CMOs",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BLOAN",
          "label":"Bank Loans",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PREF",
          "label":"Non-Convert Prefd",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MUNI",
          "label":"Tax-Exempt US Muni",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CONV",
          "label":"Convertibles",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"STOCK",
          "label":"Common Stock",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"corporateSubSectorIndustryExposures",
      "tabName":"Corp Sub-Sector Ind Exposure",
      "fields":[
        {
          "fieldName":"BASIC",
          "label":"Basic Industry",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"CAPGDS",
          "label":"Capital Goods",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"CONCYC",
          "label":"Consumer Discretionary",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"CONNC",
          "label":"Consumer Non-Cyclical",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"TECH",
          "label":"Technology",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"TRANS",
          "label":"Transportation",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"COMMUN",
          "label":"Communication Services",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"OTHIND",
          "label":"Other Industrials",
          "format":"percent",
          "subtotal":"Subtotal- Industrial",
          "contributionToDuration":true
        },
        {
          "fieldName":"ELEC",
          "label":"Electric",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true
        },
        {
          "fieldName":"NATGAS",
          "label":"Natural Gas",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true
        },
        {
          "fieldName":"OTHUTL",
          "label":"Other Utilities",
          "format":"percent",
          "subtotal":"Subtotal- Utility",
          "contributionToDuration":true
        },
        {
          "fieldName":"BNK",
          "label":"Banking",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true
        },
        {
          "fieldName":"BAME",
          "label":"Brokerage Asset Manager Exchanges",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true
        },
        {
          "fieldName":"FINCO",
          "label":"Finance Companies",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true
        },
        {
          "fieldName":"INS",
          "label":"Insurance",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true
        },
        {
          "fieldName":"REIT",
          "label":"Real Estate Investment Trust",
          "format":"percent",
          "subtotal":"Subtotal- Financial Institutions",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"countryExposures",
      "tabName":"Country Exposure",
      "fields":[
        {
          "fieldName":"ANG",
          "label":"Angola",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ARG",
          "label":"Argentina",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AUL",
          "label":"Australia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AUS",
          "label":"Austria",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AZE",
          "label":"Azerbaijan",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BAH",
          "label":"Bahrain",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BAN",
          "label":"Bangladesh",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BEL",
          "label":"Belgium",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BER",
          "label":"Bermuda",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BRA",
          "label":"Brazil",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BUL",
          "label":"Bulgaria",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CAN",
          "label":"Canada",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CNI",
          "label":"Channel Islands",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CHI",
          "label":"Chile",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CHN",
          "label":"China",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"COL",
          "label":"Colombia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"COS",
          "label":"Costa Rica",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"COT",
          "label":"Cote D'Ivoire",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"HR",
          "label":"Croatia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CYP",
          "label":"Cyprus",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CZE",
          "label":"Czech Republic",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DEN",
          "label":"Denmark",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DOM",
          "label":"Dominican Republic",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ECD",
          "label":"Ecuador",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EGY",
          "label":"Egypt",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ESL",
          "label":"El Salvador",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EST",
          "label":"Estonia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"FIN",
          "label":"Finland",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"FRA",
          "label":"France",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"GER",
          "label":"Germany",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"GHA",
          "label":"Ghana",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"GRE",
          "label":"Greece",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"GUA",
          "label":"Guatemala",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"HK",
          "label":"Hong Kong",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"HUN",
          "label":"Hungary",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ICE",
          "label":"Iceland",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"IND",
          "label":"India",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"INO",
          "label":"Indonesia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"IRE",
          "label":"Ireland",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ISR",
          "label":"Israel",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ITA",
          "label":"Italy",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"JAM",
          "label":"Jamaica",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"JPN",
          "label":"Japan",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"JOR",
          "label":"Jordan",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"KAZ",
          "label":"Kazakhstan",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"KEN",
          "label":"Kenya",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"KUW",
          "label":"Kuwait",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"LEB",
          "label":"Lebanon",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"LTH",
          "label":"Lithuania",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"LUX",
          "label":"Luxembourg",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MAL",
          "label":"Malaysia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MUR",
          "label":"Mauritius",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MEX",
          "label":"Mexico",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MON",
          "label":"Mongolia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MOR",
          "label":"Morocco",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"NET",
          "label":"Netherlands",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"NZE",
          "label":"New Zealand",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"NIG",
          "label":"Nigeria",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"NOR",
          "label":"Norway",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"OMA",
          "label":"Oman",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PAK",
          "label":"Pakistan",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PAN",
          "label":"Panama",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PAR",
          "label":"Paraguay",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PER",
          "label":"Peru",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PHI",
          "label":"Philippines",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"POL",
          "label":"Poland",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"POR",
          "label":"Portugal",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QTR",
          "label":"Qatar",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ROM",
          "label":"Romania",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"RUS",
          "label":"Russia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"YUG",
          "label":"Serbia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SIN",
          "label":"Singapore",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SLO",
          "label":"Slovenia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SLV",
          "label":"Slovokia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SAF",
          "label":"South Africa",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SKO",
          "label":"South Korea",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SPA",
          "label":"Spain",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SRI",
          "label":"Sri Lanka",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SUP",
          "label":"Supranational",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SWE",
          "label":"Sweden",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"SWI",
          "label":"Switzerland",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"TAI",
          "label":"Taiwan",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"THA",
          "label":"Thailand",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"TUN",
          "label":"Tunisia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"TUR",
          "label":"Turkey",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"UAE",
          "label":"U.A.E.",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"UKR",
          "label":"Ukraine",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"UK",
          "label":"United Kingdom",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"USA",
          "label":"United States",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"URU",
          "label":"Uruguay",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"VEN",
          "label":"Venezuela",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"VIE",
          "label":"Vietnam",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ZAM",
          "label":"Zambia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ZOA",
          "label":"Other Emerg. Americas",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ZOE",
          "label":"Other Emerg. Europe",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ZOP",
          "label":"Other Emerg. Asia",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ZOO",
          "label":"Other Emerg. Mideast/Africa",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ZOT",
          "label":"Total Other Countries",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"currencyExposures",
      "tabName":"Currency Exposure",
      "fields":[
        {
          "fieldName":"ARGPES",
          "label":"Argentina Peso",
          "format":"percent"
        },
        {
          "fieldName":"AULDOL",
          "label":"Australian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"BRABRL",
          "label":"Brazilian Real",
          "format":"percent"
        },
        {
          "fieldName":"CANDOL",
          "label":"Canadian Dollar",
          "format":"percent"
        },
        {
          "fieldName":"CHIPES",
          "label":"Chilean Peso",
          "format":"percent"
        },
        {
          "fieldName":"CHNYUA",
          "label":"Yuan Renminbi",
          "format":"percent"
        },
        {
          "fieldName":"COLPES",
          "label":"Columbian Peso",
          "format":"percent"
        },
        {
          "fieldName":"CZEKOR",
          "label":"Czech Koruna",
          "format":"percent"
        },
        {
          "fieldName":"DENKRO",
          "label":"Danish Krone",
          "format":"percent"
        },
        {
          "fieldName":"DOMPES",
          "label":"Dominican Peso",
          "format":"percent"
        },
        {
          "fieldName":"ECDSUC",
          "label":"Ecuador Sucre",
          "format":"percent"
        },
        {
          "fieldName":"EGYPOU",
          "label":"Egyptian Pound",
          "format":"percent"
        },
        {
          "fieldName":"ESLCOL",
          "label":"El Salvador Colon",
          "format":"percent"
        },
        {
          "fieldName":"EURECU",
          "label":"Euros",
          "format":"percent"
        },
        {
          "fieldName":"HKDOL",
          "label":"Hong Kong Dollar",
          "format":"percent"
        },
        {
          "fieldName":"HUNFOR",
          "label":"Hungarian Forint",
          "format":"percent"
        },
        {
          "fieldName":"INDRUP",
          "label":"Indian Rupee",
          "format":"percent"
        },
        {
          "fieldName":"INORUP",
          "label":"Indonesian Rupiah",
          "format":"percent"
        },
        {
          "fieldName":"ISRSHE",
          "label":"Israeli Shekel",
          "format":"percent"
        },
        {
          "fieldName":"JORDIN",
          "label":"Jordanian Dinar",
          "format":"percent"
        },
        {
          "fieldName":"JPNYEN",
          "label":"Japanese Yen",
          "format":"percent"
        },
        {
          "fieldName":"LEBPOU",
          "label":"Lebanese Pound",
          "format":"percent"
        },
        {
          "fieldName":"MALRIN",
          "label":"Malaysian Ringgit",
          "format":"percent"
        },
        {
          "fieldName":"MEXPES",
          "label":"Mexican Peso",
          "format":"percent"
        },
        {
          "fieldName":"MORDIR",
          "label":"Moroccan Dirham",
          "format":"percent"
        },
        {
          "fieldName":"NGRXOF",
          "label":"CFA Franc BCEAO",
          "format":"percent"
        },
        {
          "fieldName":"NIGNAI",
          "label":"Nigerian Naira",
          "format":"percent"
        },
        {
          "fieldName":"NORKRO",
          "label":"Norwegian Krone",
          "format":"percent"
        },
        {
          "fieldName":"NZEDOL",
          "label":"New Zealand Dollar",
          "format":"percent"
        },
        {
          "fieldName":"PAKRUP",
          "label":"Pakistan Rupee",
          "format":"percent"
        },
        {
          "fieldName":"PANPAB",
          "label":"Panamanian Balboa",
          "format":"percent"
        },
        {
          "fieldName":"PERSOL",
          "label":"Peruvian Nuevo Sol",
          "format":"percent"
        },
        {
          "fieldName":"PHIPES",
          "label":"Philippine Peso",
          "format":"percent"
        },
        {
          "fieldName":"POLZLY",
          "label":"Polish Zloty",
          "format":"percent"
        },
        {
          "fieldName":"RUSRUR",
          "label":"Russian Ruble",
          "format":"percent"
        },
        {
          "fieldName":"SINDOL",
          "label":"Singapore Dollar",
          "format":"percent"
        },
        {
          "fieldName":"SLVKOR",
          "label":"Slovakian Koruna",
          "format":"percent"
        },
        {
          "fieldName":"SKOWON",
          "label":"Korean Won",
          "format":"percent"
        },
        {
          "fieldName":"SAFRAN",
          "label":"South African Rand",
          "format":"percent"
        },
        {
          "fieldName":"SRILKR",
          "label":"Sri Lanka Rupee",
          "format":"percent"
        },
        {
          "fieldName":"SWEKRO",
          "label":"Swedish Krona",
          "format":"percent"
        },
        {
          "fieldName":"SWIFRA",
          "label":"Swiss Franc",
          "format":"percent"
        },
        {
          "fieldName":"TAIDOL",
          "label":"Taiwanese Dollar",
          "format":"percent"
        },
        {
          "fieldName":"THABAH",
          "label":"Thai Baht",
          "format":"percent"
        },
        {
          "fieldName":"TUNDIN",
          "label":"Tunisian Dinar",
          "format":"percent"
        },
        {
          "fieldName":"TURLIR",
          "label":"Turkish Lira",
          "format":"percent"
        },
        {
          "fieldName":"UKPOU",
          "label":"UK Pound",
          "format":"percent"
        },
        {
          "fieldName":"UKRUAH",
          "label":"Ukrainian Hryvnia",
          "format":"percent"
        },
        {
          "fieldName":"URUPES",
          "label":"Uruguayan Peso",
          "format":"percent"
        },
        {
          "fieldName":"USADOL",
          "label":"US Dollar",
          "format":"percent"
        },
        {
          "fieldName":"VENBOL",
          "label":"Venezuelan Bolivar",
          "format":"percent"
        },
        {
          "fieldName":"VIEDON",
          "label":"Vietnamese Dong",
          "format":"percent"
        },
        {
          "fieldName":"ZOAMIX",
          "label":"Other Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"ZOEMIX",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"ZOOMIX",
          "label":"Other Emerg. Mideast/Africa",
          "format":"percent"
        },
        {
          "fieldName":"ZOPMIX",
          "label":"Other Emerg. Asia",
          "format":"percent"
        },
        {
          "fieldName":"ZOTMIX",
          "label":"Total Other Countries",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"durationExposures",
      "tabName":"Duration Exposure",
      "fields":[
        {
          "fieldName":"DU1",
          "label":"Duration < 1",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU13",
          "label":"Duration 1-3",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU35",
          "label":"Duration 3-5",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU57",
          "label":"Duration 5-7",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU710",
          "label":"Duration 7-10",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU10",
          "label":"Duration > 10",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"nonInvestmentGradeDebt",
          "label":"Non-Investment Grade Debt",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"netEmgMktCurrencyExposure",
          "label":"Net Emg Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"privatePlacements144a",
          "label":"144A/Private Placements",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"inflationLinkedSecurities",
          "label":"Inflation-Linked Securities",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent",
          // "contributionToDuration":true
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexUpperBound",
          "label":"Duration Around Index Upper Bound",
          "format":"percent"
        },
        {
          "fieldName":"durationAroundIndexLowerBound",
          "label":"Duration Around Index Lower Bound",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"effectiveDurationYrs",
          "label":"Effective Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveRealDurationYrs",
          "label":"Effective Real Duration (yrs)",
          "format":"years"
        },
        {
          "fieldName":"effectiveMaturity",
          "label":"Effective Maturity (Wtd Avg Life)",
          "format":"float"
        },
        {
          "fieldName":"effectiveConvexity",
          "label":"Effective Convexity",
          "format":"float"
        },
        {
          "fieldName":"qualityRating",
          "label":"Quality Rating",
          "format":"qualitySelector"
        },
        {
          "fieldName":"effectiveYieldYtw",
          "label":"Effective Yield (YTW)",
          "format":"float"
        },
        {
          "fieldName":"effectiveRealYield",
          "label":"Effective Real Yield",
          "format":"float"
        },
        {
          "fieldName":"couponRate",
          "label":"Coupon Rate",
          "format":"float"
        },
        {
          "fieldName":"currentYield",
          "label":"Current Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        },
        {
          "fieldName":"numDistinctIssuers",
          "label":"Number of Distinct Issuers",
          "format":"number"
        },
        {
          "fieldName":"largestCorpCreditIssuerPct",
          "label":"Largest Corp Credit Issuer (% TMV)",
          "format":"percent"
        },
        {
          "fieldName":"dtsRatio",
          "label":"DTS Ratio",
          "format":"float"
        },
        {
          "fieldName":"dts20Ratio",
          "label":"DTS-20 Ratio",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"qualityExposures",
      "tabName":"Quality Exposure",
      "fields":[
        {
          "fieldName":"QAAA",
          "label":"AAA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAP",
          "label":"AA+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAA",
          "label":"AA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAM",
          "label":"AA- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAP",
          "label":"A+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QA",
          "label":"A Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAM",
          "label":"A- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBP",
          "label":"BBB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBB",
          "label":"BBB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBM",
          "label":"BBB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBP",
          "label":"BB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBB",
          "label":"BB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBM",
          "label":"BB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBP",
          "label":"B+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QB",
          "label":"B Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBM",
          "label":"B- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCP",
          "label":"CCC+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCC",
          "label":"CCC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCM",
          "label":"CCC- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCC",
          "label":"CC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QC",
          "label":"C Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QD",
          "label":"D Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QNA",
          "label":"Not Rated Quality",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    }
  ],
  "20":[
    {
      "tableName":"bondSectorExposures",
      "tabName":"Bond Sector Exposure",
      "fields":[
        {
          "fieldName":"TREA",
          "label":"US Treasuries",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"AGNC",
          "label":"Gov Related",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CORP",
          "label":"Corporate (including 144A)",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"ABSY",
          "label":"Asset Backed",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MORT",
          "label":"Agency RMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"NRMBS",
          "label":"Non-Agency RMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CMBS",
          "label":"CMBS",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CMO",
          "label":"CMOs",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"BLOAN",
          "label":"Bank Loans",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CASH",
          "label":"Cash",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"PREF",
          "label":"Non-Convert Prefd",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"MUNI",
          "label":"Tax-Exempt US Muni",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"CONV",
          "label":"Convertibles",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"STOCK",
          "label":"Common Stock",
          "format":"percent"
        },
        {
          "fieldName":"OTHR",
          "label":"Other/Misc",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"durationExposures",
      "tabName":"Duration Exposure",
      "fields":[
        {
          "fieldName":"DU1",
          "label":"Duration < 1",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU13",
          "label":"Duration 1-3",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU35",
          "label":"Duration 3-5",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU57",
          "label":"Duration 5-7",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU710",
          "label":"Duration 7-10",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"DU10",
          "label":"Duration > 10",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"miscPortfolioInfo",
      "tabName":"Misc Portfolio Info",
      "fields":[
        {
          "fieldName":"cash",
          "label":"Cash",
          "format":"percent"
        },
        {
          "fieldName":"realEstateInvestmentTrust",
          "label":"Real Estate Investment Trust",
          "format":"percent"
        },
        {
          "fieldName":"americanDepositoryReceipt",
          "label":"American Depository Receipt",
          "format":"percent"
        },
        {
          "fieldName":"totalNonUsDeveloped",
          "label":"Total Non-US Developed",
          "format":"percent"
        },
        {
          "fieldName":"totalEmergMktExposure",
          "label":"Total Emerg. Mkts Exposure",
          "format":"percent"
        },
        {
          "fieldName":"convertibles",
          "label":"Convertibles",
          "format":"percent"
        },
        {
          "fieldName":"nonConvertPrefd",
          "label":"Non-Convert Prefd",
          "format":"percent"
        },
        {
          "fieldName":"bonds",
          "label":"Bonds",
          "format":"percent"
        },
        {
          "fieldName":"commodities",
          "label":"Commodities",
          "format":"percent"
        },
        {
          "fieldName":"pooledVehicle",
          "label":"Pooled Vehicle (ETF, MF, Cmgld)",
          "format":"percent"
        },
        {
          "fieldName":"otherMisc",
          "label":"Other/Misc",
          "format":"percent"
        },
        {
          "fieldName":"netDevMktCurrencyExposure",
          "label":"Net Dev Mkt Currency Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossLongExposure",
          "label":"Gross Long Exposure",
          "format":"percent"
        },
        {
          "fieldName":"grossShortExposure",
          "label":"Total Gross Short Exposure",
          "format":"percent"
        },
        {
          "fieldName":"annualPercentTurnover",
          "label":"Annual Percent Turnover",
          "format":"percent"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"portfolioCharacteristics",
      "tabName":"Portfolio Characteristic",
      "fields":[
        {
          "fieldName":"medianMarketCap",
          "label":"Wtd Median Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"avgMarketCap",
          "label":"Wtd Avg Market Cap ($M)",
          "format":"dollars"
        },
        {
          "fieldName":"largeCapPct",
          "label":"% Large Cap ($wgt) > $15 B",
          "format":"percent"
        },
        {
          "fieldName":"midCapPct",
          "label":"% Mid Cap ($wgt) $3.5 - $15 B",
          "format":"percent"
        },
        {
          "fieldName":"smallCapWgt",
          "label":"% Small Cap ($wgt) $700M - $3.5 B",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceForecastedEarnings",
          "label":"Wtg Avg Price/Forecasted Earnings",
          "format":"float"
        },
        {
          "fieldName":"microCapPct",
          "label":"% Micro Cap ($wgt)< $700 M",
          "format":"percent"
        },
        {
          "fieldName":"avgPriceBookValue",
          "label":"Wtg Avg Price/Book Value",
          "format":"float"
        },
        {
          "fieldName":"forecastedEarningsGrowth5yr",
          "label":"5yr Forecasted Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"historicalEarningsGrowth5yr",
          "label":"5yr Historical Earnings Growth",
          "format":"float"
        },
        {
          "fieldName":"avgDividendYield",
          "label":"Wtd Avg Dividend Yield",
          "format":"float"
        },
        {
          "fieldName":"numOfHoldings",
          "label":"Number of Holdings",
          "format":"number"
        }
      ],
      "totalAll":false
    },
    {
      "tableName":"qualityExposures",
      "tabName":"Quality Exposure",
      "fields":[
        {
          "fieldName":"QAAA",
          "label":"AAA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAP",
          "label":"AA+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAA",
          "label":"AA Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAAM",
          "label":"AA- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAP",
          "label":"A+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QA",
          "label":"A Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QAM",
          "label":"A- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBP",
          "label":"BBB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBB",
          "label":"BBB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBBM",
          "label":"BBB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBP",
          "label":"BB+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBB",
          "label":"BB Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBBM",
          "label":"BB- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBP",
          "label":"B+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QB",
          "label":"B Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QBM",
          "label":"B- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCP",
          "label":"CCC+ Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCC",
          "label":"CCC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCCCM",
          "label":"CCC- Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QCC",
          "label":"CC Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QC",
          "label":"C Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QD",
          "label":"D Quality",
          "format":"percent",
          "contributionToDuration":true
        },
        {
          "fieldName":"QNA",
          "label":"Not Rated Quality",
          "format":"percent",
          "contributionToDuration":true
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"regionalExposures",
      "tabName":"Regional Exposures",
      "fields":[
        {
          "fieldName":"AMDEV",
          "label":"Developed North America",
          "format":"percent"
        },
        {
          "fieldName":"EURDEV",
          "label":"Dev Europe/Middle East/Africa",
          "format":"percent"
        },
        {
          "fieldName":"PACDEV",
          "label":"Developed Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"AMOTH",
          "label":"Emerg. Americas",
          "format":"percent"
        },
        {
          "fieldName":"EUROTH",
          "label":"Other Emerg. Europe",
          "format":"percent"
        },
        {
          "fieldName":"PROTH",
          "label":"Other Emerg. Asia-Pacific",
          "format":"percent"
        },
        {
          "fieldName":"EMROTH",
          "label":"Emerg. Other",
          "format":"percent"
        }
      ],
      "totalAll":true
    },
    {
      "tableName":"stockSectors",
      "tabName":"Stock Sector",
      "fields":[
        {
          "fieldName":"CONCYC",
          "label":"Consumer Discretionary",
          "format":"percent"
        },
        {
          "fieldName":"CONSTA",
          "label":"Consumer Staples",
          "format":"percent"
        },
        {
          "fieldName":"ENERGY",
          "label":"Energy",
          "format":"percent"
        },
        {
          "fieldName":"FINANC",
          "label":"Financial",
          "format":"percent"
        },
        {
          "fieldName":"HEALTH",
          "label":"Health Care",
          "format":"percent"
        },
        {
          "fieldName":"INDEQU",
          "label":"Industrials",
          "format":"percent"
        },
        {
          "fieldName":"RAWMAT",
          "label":"Materials",
          "format":"percent"
        },
        {
          "fieldName":"TECH",
          "label":"Technology",
          "format":"percent"
        },
        {
          "fieldName":"COMMUN",
          "label":"Communication Services",
          "format":"percent"
        },
        {
          "fieldName":"PUBUTL",
          "label":"Utilities",
          "format":"percent"
        }
      ],
      "totalAll":true
    }
  ]
}
import React, { useState } from "react"
import { JobStatusCode as JqStatus, useJobQuery } from "../../__generated__/graphql"

type JobPollerProps = {
  jobId: number
  onJobStatusUpdated: (status: JqStatus, message: string) => void
}

const CompletedStatuses = [ JqStatus.DONE_OK, JqStatus.DONE_FAIL, JqStatus.DONE_FLOP, JqStatus.DONE_OOPS, JqStatus.DONE_CANCEL ]

export const JobPoller: React.FC<JobPollerProps> = ({ children, jobId, onJobStatusUpdated }) => {
  const [currentStatus, setCurrentStatus] = useState<JqStatus | undefined>(undefined)

  const { data, stopPolling} = useJobQuery({
    fetchPolicy: "no-cache",
    variables: {jobId},
    pollInterval: 2000,
    onCompleted: (data) => {
      if(data.jobStatus?.status?.code && currentStatus !== data.jobStatus?.status?.code){
        setCurrentStatus(data.jobStatus?.status?.code)
        onJobStatusUpdated(data.jobStatus?.status?.code, data.jobStatus?.status?.value || "")
      }
    }
  })

  if(CompletedStatuses.includes(data?.jobStatus?.status?.code as JqStatus)){
    stopPolling()
  }

  return (
    <>
      {children}
    </>
  )
}

import React, { Component } from "react"
import {
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"

export interface OptionItem {
  id: number
  name: string
}

interface ExtendedOptionItem {
  id: number | string
  property?: string // for filter inside array like [{code: "something"}]
  name: string
  value: number | string
}

interface PickerProps {
  filters: OptionItem[]
  activeFilters: number[]
  sortOptions: OptionItem[]
  activeSort: number
  searching: boolean
  onFilter: (filterId: number) => void
  onSort: (sortId: number) => void
  wrapperClass?: string
  toolbarClass?: string
  listClass?: string
}

export class Picker extends Component<PickerProps> {
  state = {
    orderOpen: false,
    filterOpen: false,
  }
  constructor(props: PickerProps) {
    super(props)
  }
  render() {
    let { wrapperClass = "", toolbarClass = "", listClass = "" } = this.props

    const selectedSort = this.props.sortOptions.find(
      (item) => item.id === this.props.activeSort
    )
    return (
      <Col md="4" lg="3" className={classnames("pr-md-0 picker", wrapperClass)}>
        <div
          className={classnames(
            "pane pane-toolbar picker-toolbar sticky-top",
            toolbarClass
          )}
        >
          <ButtonDropdown
            isOpen={this.state.orderOpen}
            toggle={() => this.setState({ orderOpen: !this.state.orderOpen })}
            className="mr-1"
          >
            <DropdownToggle color="link">
              <FontAwesomeIcon icon="sort" className="mr-2" />
              {selectedSort?.name}
            </DropdownToggle>
            <DropdownMenu>
              {this.props.sortOptions.map((sortOption) => (
                <DropdownItem
                  key={sortOption.id}
                  onClick={() => this.props.onSort(sortOption.id)}
                  className={classnames({
                    active: sortOption.id === this.props.activeSort,
                  })}
                >
                  {sortOption.id === this.props.activeSort ? (
                    <FontAwesomeIcon
                      icon="check"
                      className="mr-2 active-icon"
                    />
                  ) : (
                    <span className="mr-4"></span>
                  )}
                  {sortOption.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          { this.props.filters.length > 0 && (
          <ButtonDropdown
            isOpen={this.state.filterOpen}
            toggle={() => this.setState({ filterOpen: !this.state.filterOpen })}
            className="mr-1"
          >
            <DropdownToggle color="link">
              <span className="fa-layers fa-fw mr-2">
                <FontAwesomeIcon icon="filter" />
                {this.props.searching && (
                  <FontAwesomeIcon
                    icon="ban"
                    size="2x"
                    transform="up-1 left-4"
                  />
                )}
              </span>
              {this.props.searching && "Searching"}
              {!this.props.searching && (
                <>
                  Filter
                  {this.props.activeFilters.length > 0 && (
                    <> ({this.props.activeFilters.length})</>
                  )}
                </>
              )}
            </DropdownToggle>

            <DropdownMenu>
              {this.props.filters.map((filter) => (
                <DropdownItem
                  key={filter.id}
                  onClick={() => this.props.onFilter(filter.id)}
                  className={classnames({
                    active: this.props.activeFilters.indexOf(filter.id) !== -1,
                  })}
                >
                  {this.props.activeFilters.indexOf(filter.id) !== -1 ? (
                    <FontAwesomeIcon
                      icon="check"
                      className="mr-2 active-icon"
                    />
                  ) : (
                    <span className="mr-4"></span>
                  )}
                  {filter.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          )}
        </div>
        <div
          className={classnames("pane p-0 sticky-top picker-list", listClass)}
        >
          <ListGroup>{this.props.children}</ListGroup>
        </div>
      </Col>
    )
  }
}


interface BasicFilter {
  filterId: number
  filterName: string
  property?: string
  filterFunction?: (input: any) => boolean
}
export interface Filter extends BasicFilter {
  options: ExtendedOptionItem[]
  classNames?: string
  property: string
  matchFunction?: (option: ExtendedOptionItem, value: any) => boolean
}

type OptionIdType = number | string
export interface ActiveFilter extends Partial<BasicFilter> {
  filterId: number
  activeOptions: Array<OptionIdType>;
}


interface FilterOption {
  filterId: number
  optionId: OptionIdType
}

interface MultiplePickerProps {
  filters: Filter[]
  activeFilters: ActiveFilter[]
  sortOptions: OptionItem[] // {id: number, name: string}
  activeSort: number
  searching: boolean
  onFilter: (filter: FilterOption) => void
  onSort: (sortId: number) => void
  wrapperClass?: string
  toolbarClass?: string
  listClass?: string
  dropDownMenuClass?: string
  columnNumber?: number
  hideFilters?: number[]
  componentPiece?: boolean
  stayOpen?: boolean
}

interface PickerState {
  orderOpen: boolean
  filterOpen: boolean
}

export class MultiplePicker extends Component<MultiplePickerProps, PickerState>{
  constructor(props: MultiplePickerProps) {
    super(props)
    this.state = {
      orderOpen: false,
      filterOpen: false,
    }
  }

  render() {
    let { wrapperClass = "", toolbarClass = "", listClass = "", hideFilters, componentPiece, stayOpen } = this.props

    const selectedSort = this.props.sortOptions.find(
      (item) => item.id === this.props.activeSort
    )
    return (
      <Col className={classnames("pr-md-0 picker", {"col-md-4 col-lg-3": !componentPiece, "position-inherit": componentPiece}, wrapperClass)}>
        <div
          className={classnames(
            {"pane pane-toolbar picker-toolbar sticky-top": !componentPiece, "position-absolute component-filter": componentPiece},
            toolbarClass
          )}
        >
          {!componentPiece &&
            <ButtonDropdown
              isOpen={this.state.orderOpen}
              toggle={() => this.setState({ orderOpen: !this.state.orderOpen })}
              className={classnames("mr-1",{"position-absolute": componentPiece})}
            >
              <DropdownToggle color="link">
                <FontAwesomeIcon icon="sort" className="mr-2" />
                {selectedSort?.name}
              </DropdownToggle>
              <DropdownMenu>
                {this.props.sortOptions.map((sortOption) => (
                  <DropdownItem
                    key={sortOption.id}
                    onClick={() => this.props.onSort(sortOption.id)}
                    className={classnames({
                      active: sortOption.id === this.props.activeSort,
                    })}
                    toggle={!stayOpen}
                  >
                    {sortOption.id === this.props.activeSort ? (
                      <FontAwesomeIcon
                        icon="check"
                        className="mr-2 active-icon"
                      />
                    ) : (
                      <span className="mr-4"></span>
                    )}
                    {sortOption.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          }
          { this.props.filters.length > 0 && (
          <ButtonDropdown
            isOpen={this.state.filterOpen}
            toggle={() => this.setState({ filterOpen: !this.state.filterOpen })}
            className="mr-1"
          >
            <DropdownToggle color="link">
              <span className="fa-layers fa-fw mr-2">
                <FontAwesomeIcon icon="filter" />
                {this.props.searching && (
                  <FontAwesomeIcon
                    icon="ban"
                    size="2x"
                    transform="up-1 left-4"
                  />
                )}
              </span>
              {this.props.searching && "Searching"}
              {!this.props.searching && (
                <>
                  Filter
                  {this.props.activeFilters.length > 0 && this.props.activeFilters.some(filter=>filter.activeOptions.length > 0) && (
                    <> ({this.props.activeFilters.reduce((acc, filter)=>{
                      return acc + filter.activeOptions.length
                    },0)})</>
                  )}
                </>
              )}
            </DropdownToggle>
            <DropdownMenu right={componentPiece} className={classnames({"two-column": this.props.columnNumber === 2, [`${this.props.dropDownMenuClass}`]: this.props.dropDownMenuClass})}>
              {this.props.filters.map((filter, filterIdx) =>{
                let {filterId, filterName: title} = filter
                if(hideFilters && hideFilters.includes(filterId)) return <React.Fragment key={filterIdx} />
                return (
                  <div className={classnames("filter-container px-2", {[`${filter.classNames}`]: !!filter.classNames})} key={filterIdx}>
                    <span className="filter-title pl-2" key={filterIdx}>{title.toUpperCase()}</span>
                    {filter.options.map((option, optionIdx)=>{
                      return (
                        <DropdownItem
                          key={`${filterId}-${optionIdx}`}
                          onClick={() => this.props.onFilter({filterId, optionId: option.id})}
                          className={classnames({
                            active: this.props.activeFilters.findIndex(()=>filter.filterId === filterId) !== -1,
                          }, "filter-item")}
                          toggle={!stayOpen}
                        >
                          {this.props.activeFilters.findIndex((element)=>element.filterId === filterId && element.activeOptions.includes(option.id as any as never)) !== -1 ? (
                            <FontAwesomeIcon
                              icon="check"
                              className="mr-2 active-icon"
                            />
                          ) : (
                            <span className="mr-4"></span>
                          )}
                          {option.name}
                        </DropdownItem>
                      )
                      })
                    }
                  </div>
                )})}
            </DropdownMenu>
          </ButtonDropdown>)}
        </div>
        <div
          className={classnames(listClass, {"pane p-0 picker-list sticky-top": !componentPiece})}
        >
          <ListGroup>{this.props.children}</ListGroup>
        </div>
      </Col>
    )
  }
}

import { GetRowIdParams, GridReadyEvent } from '@ag-grid-community/core'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { compact, flatten, get, isNull } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Container, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { TemporaryAlertContext } from '../../../../Context/TemporaryAlertContext'
import { DataLoadBasicFragment, DataLoadDataFragment, DataLoadPublishMode, DataLoadTemplateFragment, LoadMessage, LoadTypeCode, Maybe, useMeQuery, usePublishDataLoadMutation } from '../../../../__generated__/graphql'
import { ErrorComponent } from '../../../Report/Shared/ReportComponent'
import SortableTable from '../../../Shared/SortableTable'
import EditButtons from '../../../ui/EditButtons'
import LoadingOverlay from '../../../ui/Loading'
import { ImportedFileDataColumnDefMapping as ColumnDefs } from './ImportedFilesConfig'

type ImportedFileDataProps = {
  dataLoadId: number
  template: DataLoadTemplateFragment
}

type PublishDataStateType = DataLoadBasicFragment & {
  mode?: DataLoadPublishMode | null
}

interface PublishModalProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  state: PublishDataStateType
  callBack: (value: any) => void
}

enum publishStatus{
  Ready = 1,
  StartPublishing = 3,
  Error = 5,
  Cancelled = 6,
  Published = 7,
}

export const PublishModal: React.FC<PublishModalProps> = ({
  modalOpen,
  setModalOpen,
  state,
  callBack,
}) => {

  const [publishDataState, setPublishDataState] = useState<PublishDataStateType> (state)
  const [currentStatus, setCurrentStatus] = useState(publishStatus.Ready)

  const [saving, setSaving] = useState(false)
  const [publishDataLoad] = usePublishDataLoadMutation()
  const { addAlert } = useContext(TemporaryAlertContext)

  const updatePublishMode = (value: any) => {
    setPublishDataState({...publishDataState, mode: value})
  }
  const resetState = () => setPublishDataState({...state, mode: null})

  const onSubmit = () => {
    const {id, mode } = publishDataState
    const variables = {input: {id, mode: mode as DataLoadPublishMode }}
    setSaving(true)
    publishDataLoad({variables}).then(result => {
      if(result?.data) {
        const data = result.data?.publishDataLoad
        const status = data?.status?.code
        let isSuccess = status === "_3"
        let isFailed = status === "_4"
        if(isSuccess) {
          addAlert({title: "Success", message: "", color: "userSuccess", timeout: 3000})
          callBack(data)
        }else if(isFailed){
          console.log("70 Publish Failed", data?.messages)
          addAlert({title: "Publish Failed | PLACEHOLDER.", message: data?.messages || "", color: "error", timeout: 3000})
        }
        setModalOpen(false)
      }
    }).catch(err => {
      addAlert({title: "Publish Failed | PLACEHOLDER.", message:  err.message || "", color: "error", timeout: 3000})
    }).finally(() => {
      setSaving(false)
    })
  }

  useEffect(() => {
    if(state.mode && !modalOpen) {
      resetState()
    }
  }, [modalOpen])

  const publishComponent = (
  <div>
    <div className="mx-2">
      <h5 className="mt-2">What would you like to do with the existing table?</h5>
      <Label className="text-gray-50" for="4-static">A table with {`${publishDataState.existingRows || 0}`} entries is already in the database.</Label>
      <CustomInput
        id={"publish-1"}
        className="boolean-radio mt-3 full-width"
        type="radio"
        value="true"
        checked={publishDataState.mode?.toString() === "OVERWRITE"}
        onChange={(value) => value.target.value === "true" && updatePublishMode("OVERWRITE")}
        label="Delete and replace existing"
      />
      <Label className="pl-4 text-gray-50" for="4-static">The existing entries will be deleted and replaced with the new.</Label>
      <CustomInput
        id={"publish-2"}
        className="boolean-radio mt-3 full-width"
        type="radio"
        value="true"
        checked={publishDataState.mode?.toString() === "UPDATE"}
        onChange={(value) => value.target.value === "true" && updatePublishMode("UPDATE")}
        label="Update existing"
      />
      <Label className="pl-4 text-gray-50" for="4-static">Add new entries and update existing entries in the database.</Label>
    </div>
  </div>)

  return (
    <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
      <ModalHeader className="fee-modal-header">
        Publish Option
      </ModalHeader>
      <ModalBody>
        {publishComponent}
      </ModalBody>
      <ModalFooter>
        <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => {setModalOpen(false)}} saving={saving} onSubmit={onSubmit} disabled={isNull(get(publishDataState, "mode"))}/>
      </ModalFooter>
    </Modal>
  )
}

const getInitialState = (state: Maybe<DataLoadDataFragment>) => {
  if(!state) return []
  let {summaryPositions, summaryTransactions} = state as DataLoadDataFragment
  let positions = summaryPositions?.map((position) => {
    if(!position) return null
    return {type: "summaryPositions", ...position}
  }) || []
  let transactions = summaryTransactions?.map((transaction) => {
    if(!transaction) return null
    return {type: "summaryTransactions", ...transaction}
  }) || []
  return compact(flatten([...positions, ...transactions]))
}

const ImportedFileDataTable: React.FC<ImportedFileDataProps> = (props) => {
  const {dataLoadId, template} = props
  const [search, setSearch] = useState("")

  const [gridReadyEvent, setGridApi] = useState<Maybe<GridReadyEvent>>(null)
  const [dataState, setDataState] = useState<Maybe<any[]>>(null)

  // publish button
  const [publishModalOpen, setPublishModalOpen] = useState(false)

  const variables = {id: dataLoadId}
  const { colDef, document } = ColumnDefs[template?.type?.code as LoadTypeCode ]
  const { loading, data, error, refetch } = useQuery(document, { variables, fetchPolicy: "no-cache"})

  const columnDefs = useMemo(() => colDef(template?.assetClass?.id || 0, template?.id), [template?.assetClass?.id || 0, template?.id])

  let {data: currentUserData, loading: currentUserLoading, error: currentUserError } = useMeQuery({fetchPolicy: "cache-first"})

  useEffect(() => {
    if(data?.dataLoad) {
      let newData = data?.dataLoad?.data || null
      let formattedData = getInitialState(newData)
      setDataState(formattedData)
    }
  }, [data])

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params)
  }

  const getRowId = (params: GetRowIdParams) => {
    let data = params.data
    return `${data.id}-${data.type}`
  }

  const onSubmit = () => {
    setPublishModalOpen(true)
  }

  const isPublishDisabled = (state: any[]) => {
    // TODO: How can I tell if the data can be published?
    let isPublished = data?.dataLoad?.status?.code === "_3"
    let isImportFailed = data?.dataLoad?.status?.code === "_2"
    if(isPublished || isImportFailed) { return true}
    let hasError = !!state?.find((row) => row?.messages?.some((message: LoadMessage) => message?.severity?.toString() === "ERROR"))
    return hasError
  }

  const name = 'Imported File Data'
  if(loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if(error) {
    return <ErrorComponent name={name} error={error?.message}/>
  }

  let state = dataState || []
  if(state?.length >=0){
    return (
      <Container fluid className="px-0 d-flex flex-grow-1 flex-direction-column view-port-80">
        <Row className="px-0 pane pane-toolbar sticky-top above-picker w-100">
          <Form className="mr-2 pr-2" onKeyDown={handleEnterKeyDown}>
            <FormGroup row className="relative m-0">
              <Input
                type="text"
                placeholder="Find"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="mid-search"
              />
              <span className="o-88 absolute center-v right-1 pe-none">
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="2x"
                  className="fontawesome-icon dark-icon-color text-gray-50"
                />
              </span>
            </FormGroup>
          </Form>
          <div className="d-flex align-items-center justify-content-between pl-1">
            <Button className="px-3 text-callan-blue pb-2 mr-2" color="secondary btn-thin" onClick={onSubmit} disabled={isPublishDisabled(dataState || [])}>
              <span>Publish</span>
            </Button>
          </div>
        </Row>
        <SortableTable
          filterText={search}
          columnDefs={columnDefs}
          tableData={state} // test
          onReady={onReady}
          editMode={false}
          loading={loading}
          getRowId={getRowId}
        />
        <PublishModal
          modalOpen={publishModalOpen}
          setModalOpen={setPublishModalOpen}
          state={{...data?.dataLoad, id: dataLoadId, mode: null} as PublishDataStateType}
          callBack={refetch}
        />
      </Container>
    )
  }

  return (<div>No Data</div>)

}

export default ImportedFileDataTable
import React, { Component, useState } from 'react'
import { GridApi, GridReadyEvent } from '@ag-grid-community/core'
import { RouteComponentProps } from 'react-router'

import Auth from '../../Auth/Auth'
import { ManagersListColumnDef } from '../../helpers/columnDefs'
import { appProps } from '../../queries/extended/appProps'

import { WithTopNav } from '../ui/LayoutWrapper'
import { AllManagersQuery, AllManagersQueryVariables, OrgType,useAllManagersQuery } from '../../__generated__/graphql'
import { Col, Container, Input, Row } from 'reactstrap'
import PlaceHolder from '../ui/PlaceHolder'
import _ from 'lodash'
import SortableTable from '../Shared/SortableTable'
import PagePermissionCheckFail from '../Shared/PagePermissionCheckFail'
import ErrorDisplay from '../Shared/ErrorDisplay'


interface authProps {
  auth: Auth
}

interface QueryChildren extends React.HTMLProps<HTMLDivElement> {
  (
    result: AllManagersQuery &
      authProps &
      RouteComponentProps
  ): React.ReactNode
  // any
}


const AllManagersComponent: React.FC<RouteComponentProps &
  QueryChildren &
  appProps> = props => {
    // permission check??
  const { auth } = props
  if(!auth.checkPermissions(["view:all_clients"])) {
    return (<PagePermissionCheckFail/>)
  }

  const [filterText, setFilterText] = useState("")
  let tableData: any, colDef: any, onReady: (api:GridReadyEvent) => void

  const { loading, data, error } = useAllManagersQuery({variables: {type: ["Manager"] as OrgType[], offset: 0,  limit: 50} as AllManagersQueryVariables, fetchPolicy: "cache-first"})

  onReady = (event) => {
    event.api!.setFilterModel({
      'isInactive': {
        filterType: 'set',
        values: ["Active"]
      }
    })
  }

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  };
  if (error) {
    return (
      <Container fluid>
        <ErrorDisplay error={error}/>
      </Container>
    );
  };
  if (!data) return <div>data doesn't exist</div>;
  tableData = _.get(data, 'orgs', [])
  console.log({first: tableData[0]})
  colDef = ManagersListColumnDef()

  return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column">
        <Row>
          <Col>
            <div className="pane pane-toolbar">
              <Input
                className="toolbar-search"
                type="text"
                placeholder="Find Managers by name"
                onChange={event =>
                  setFilterText(event.target.value)
                }
              />
            </div>
          </Col>
        </Row>
        <SortableTable
          key={0}
          loading={loading}
          filterText={filterText}
          columnDefs={colDef}
          tableData={tableData}
          onReady={onReady}
        />
      </Container>
  )
}

//TODO double query every time
export default WithTopNav(AllManagersComponent)
import classnames from 'classnames'
import { debounce, get, set } from 'lodash'
import React, { EventHandler, useContext, useEffect, useMemo, useState, useRef } from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { FormGroup, FormText, Input, InputGroup, InputProps, Label, UncontrolledTooltip } from 'reactstrap'
import validate from 'validate.js'
import { EditButtonContext } from '../../../Context/EditButtonContext'
import { Maybe } from '../../../__generated__/graphql'
import { NumberInputTrailingStyleType } from '../../../helpers/constant'
import { InfoTipProps } from './FormInput'

interface TextFieldInputProps {
  idx: string | number,
  property: string,
  displayName: string,
  propertyValue: string,
  editMode: boolean,
  updateValue: (event:any) => void,
  handleEnter?: () => void,
  wrapperClasses?: string,
  labelClasses?: string,
  inputWrapperClasses?: string,
  inputClasses?: string,
  placeholder: string,
  disabled?: boolean,
  required?: boolean
  autoFocus?: boolean
  validateAlsoOnChange?: boolean
  currency: boolean
  trailing?: NumberInputTrailingStyleType | undefined
  infoTip?: InfoTipProps  // description tooltip, attached to label/input
  tooltip?: InputTooltip
  subtype?: string
  inputRef?: React.RefObject<HTMLInputElement>
  inputProps?: InputProps
  charactersLimit?: number
  hideLimit?: boolean
  // used for textLink helper function
  textLinkParams?: Maybe<{ url: string | undefined }>
  displayTooltipAtValue?: boolean
  debounceInput?: boolean
}

export type InputTooltip = {
  icon: string
  id: string
  onClick?: () => void
}

const getInfoTipArgs = (infoTip: InfoTipProps | undefined, element: string, editMode: boolean, disabled: boolean) => {
  if(!infoTip) return null
  const { title, parentElement, placement, cssClasses, enabledInViewMode } = infoTip

  if(!title || (element !== parentElement)) return null
  if(!enabledInViewMode && !editMode) return null
  if(editMode && disabled && !enabledInViewMode) return null

  return {
    // "data-toggle": "tooltip", // bootstrap < v5
    "placement": placement || "top",
    "title": title,
    "placementPrefix": "custom-info-tip",
    enabledInViewMode,
  }
}

export const TextFieldInput: React.FC<TextFieldInputProps> = props => {
  const { idx, property, displayName, propertyValue, editMode, placeholder, labelClasses, inputWrapperClasses, inputClasses, disabled, infoTip, tooltip, currency, trailing, autoFocus, subtype, inputProps, charactersLimit, handleEnter, inputRef, validateAlsoOnChange, displayTooltipAtValue, textLinkParams, hideLimit } = props
  let updateValue: EventHandler<any> = props.updateValue
  let [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || '')
  const treatAsLink = !editMode && textLinkParams && textLinkParams.url

  let shortProperty = property.split(".").pop() || property
  let required = props.required || false
  const context = useContext(EditButtonContext)
  const { setError } = context

  const displayLabel = (displayName !== "")

  const formatField = (value:string) => {
    // Currently don't format but may in the future so leaving it here
    return value
  }

  let [formattedValue, setFormattedValue] = useState(formatField(propertyValue || ''))

  const debouncedUpdate = useRef(debounce((event:any) => props.updateValue(event), 200)).current
  if(props.debounceInput) {
    updateValue = debouncedUpdate
  }

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = event.target.value
    let value: string | null

    value = event.target.value

    if(validateAlsoOnChange){
      runValidation(value)
    }

    setFormattedValue(targetValue)
    updateValue(value)
  }

  const [charactersRemaining, updateCharactersRemaining] = useState(charactersLimit)
  const hasCharactersLimitSetting = !_.isUndefined(charactersLimit)
  const handleChangeWithLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const characters = e.target.value.length
    if (charactersLimit && characters > charactersLimit) {
      return
    }
    if(charactersLimit) {
      updateCharactersRemaining(charactersLimit - characters)
    }
    handleChange(e)
  }

  const infoArgs = useMemo(() => getInfoTipArgs(infoTip, "input", editMode, !!disabled), [infoTip, editMode, disabled])
  const couldShowInfoTip = !!infoArgs

  useEffect(() => {
    if(!editMode) {
      setFormattedValue(formatField(propertyValue || ''))
    }
  }, [props.propertyValue])

  useEffect(() => {
    setFormattedValue(editMode ? (propertyValue || '') : formatField(propertyValue || ''))
    runValidation(propertyValue === "" ? null : propertyValue)
    if(props.editMode) {
       // reset to current value when into editMode.
      if(charactersLimit) {
        updateCharactersRemaining(!!charactersLimit? charactersLimit-(propertyValue?.length||0): charactersLimit)
      }
    }
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [props.editMode, props.required, props.subtype])

  const handleBlur = (event:React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = event.target.value

    setFormattedValue(targetValue === "" ? "" : formatField(targetValue))

    runValidation(targetValue === "" ? null : targetValue)
  }

  const runValidation = (value:string | null) => {
    var inputs:{[key:string]: string | null} = {}
    var constraints:{[key:string]: any} = {}
    if (subtype == "url") {
      // Add http for validation as library does not accept values missing protocol
      inputs[shortProperty] = value?.startsWith("http") ? value : "https://" + value
      set(constraints, [shortProperty, 'url'], {schemes: ["http","https"]})
    } else {
      inputs[shortProperty] = value
    }

    if (subtype == "email") {
      set(constraints, [shortProperty, 'email'], true)
    }

    if (required) {
      set(constraints, [shortProperty, 'presence'], true)
    }

    let validations = validate(inputs, constraints)
    if (validations && props.editMode) {
      setError(`${idx}-${shortProperty}`, get(validations, shortProperty))
      setWrapperClasses(wrapperClasses + ' has-error')
    } else {
      setError(`${idx}-${shortProperty}`, [])
      setWrapperClasses(wrapperClasses.replace(/has\-error/g,''))
    }
  }

  const handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && handleEnter) {
      handleEnter()
    }
  }

  const input = (
    <Input
      id={`${idx}-${shortProperty}`}
      bsSize="sm"
      name={property}
      label={property}
      type={subtype === "textarea" ? "textarea" : "text"}
      value={formattedValue || ""}
      placeholder={editMode ? placeholder : undefined}
      onChange={hasCharactersLimitSetting? handleChangeWithLimit: handleChange}
      disabled={!editMode || disabled}
      onBlur={handleBlur}
      className={classnames(inputClasses||"", {"editing": editMode, "fake-link": treatAsLink })}
      title={propertyValue || ""}
      required={required}
      autoFocus={autoFocus}
      onKeyDown={handleKeyDown}
      innerRef={inputRef}
      {...inputProps}
    >
    </Input>)

  return (
    <FormGroup
      className={classnames("form-group row", wrapperClasses||"")}
      key={idx}
    >
      {displayLabel &&
        <>
          {couldShowInfoTip &&
            <UncontrolledTooltip
              className="tooltip-ontop-modal"
              target={`${idx}-${shortProperty}`}
              {...infoArgs}
            >
            {infoTip?.title}
          </UncontrolledTooltip>}
          <Label
            className={classnames("col-form-label", labelClasses, {"col-sm-4":!labelClasses})}
            for={`${idx}-${shortProperty}`}
            id={property}
          >
            <div
              className={classnames("d-flex w-100", {'tooltip-icon': tooltip, 'justify-content-start': tooltip?.onClick})}
              id={tooltip ? tooltip.id : ""}
            >
              {displayName}
              {textLinkParams?.url && !editMode &&
                <FontAwesomeIcon icon={"external-link"} className="ml-2 cursor-pointer" onClick={() => window.open(textLinkParams?.url ,'_blank')}/>
              }
              {tooltip &&
                <FontAwesomeIcon
                  icon={tooltip.icon as IconName}
                  size="sm"
                  onClick={() => { tooltip.onClick && tooltip.onClick() }}
                />
              }
            </div>
          </Label>
        </>
      }
      <div className={classnames(
          "exportable-form-input",
          inputWrapperClasses || "",
          {
            "col-sm-8": displayLabel && !inputWrapperClasses,
            "col-sm-12": !(displayLabel || inputWrapperClasses),
            "input-group input-group-right": !!trailing,
            "input-group input-group-left": currency,
            "tooltip-icon d-flex after-value": tooltip,
          }
        )}
        id={tooltip ? tooltip.id : ""}
      >
        <InputGroup className={classnames({"fake-link": treatAsLink})} onClick={() => treatAsLink && window.open(textLinkParams?.url ,'_blank')}>
          {input}
          {currency ? (
            <span className="input-group-addon dollar">&#36;</span>
          ) : (
            <></>
          )}
          {trailing ? (
            <span className="input-group-addon dollar">{trailing}</span>
          ) : (
            <></>
          )}
          {displayTooltipAtValue && tooltip &&
            <FontAwesomeIcon
              icon={tooltip.icon as IconName}
              size="sm"
              className={"my-auto"}
              onClick={() => { tooltip.onClick && tooltip.onClick() }}
            />
          }
        </InputGroup>
        {!!editMode && hasCharactersLimitSetting && !hideLimit && (
          <FormText className="text-center">{charactersRemaining} characters remaining</FormText>
        )}
      </div>
      {textLinkParams?.url && !editMode && <div className='form-link-overlay' onClick={() => window.open(textLinkParams.url,'_blank')}/>}
    </FormGroup>
  )
}

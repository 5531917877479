import React, { useState, useEffect } from "react"
import {
  Card,
  CardHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardBody,
  Table,
  Form,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DATE_API_FORMAT } from "../../../helpers/constant"
import moment from "moment"
import {
  useVehicleStableValueFeeQuery,
  StableValue,
  StableValueCollectiveInvestmentFund,
  StableValueSeparateAccount,
} from "../../../__generated__/graphql"
import { checkIfSet, formatPercent, previousQuarterEnd } from "./helper"
import { FeeHistoryRow, FeeHistoryModal } from "./FeeHistoryModal"
import { PercentInput } from "./FeeFormComponents"

interface StableValueFeeProps {
  handleInputChange: (value: any, property: string) => void
  editing: boolean
  fundid: string
}

interface StableValueFeeTypes {
  stableValue?: {
    date?: string | null | undefined
    annualizedWrapPctOfApplicableWrappedAssets?: number | null | undefined
    annualizedSubAdvisedPctOfApplicableSubadvisedAssets?:
      | number
      | null
      | undefined
  }
  stableValueCollectiveInvestmentFund?: {
    date?: string | number | null | undefined
    allInFee?: number | null | undefined
  }
  stableValueSeparateAccount?: {
    date?: string | number | null | undefined
    annualizedWrapPctOfTotalPortfolio?: number | null | undefined
    annualizedSubAdvisedPctOfTotalPortfolio?: number | null | undefined
  }
}

export const StableValueFees: React.FC<StableValueFeeProps> = ({
  fundid,
  editing,
  handleInputChange,
}) => {
  const quarterEndDate = previousQuarterEnd
  const [latestFeeDate, setLatestFeeDate] = useState(quarterEndDate)
  const [showHistory, toggleHistoryModal] = useState(false)
  const [fees, setFees] = useState<StableValueFeeTypes>({})

  const { error, loading, data } = useVehicleStableValueFeeQuery({
    variables: {
      fundid,
    },
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (data?.vehicle) {
      const stableValue = (data?.vehicle as StableValue).stableValue
      const stableValueCollectiveInvestmentFund = (
        data?.vehicle as StableValueCollectiveInvestmentFund
      ).stableValueCollectiveInvestmentFund
      const stableValueSeparateAccount = (
        data?.vehicle as StableValueSeparateAccount
      ).stableValueSeparateAccount

      const dates: string[] = []

      if (stableValue?.fees) {
        stableValue.fees.forEach((item) => dates.push(item?.date as string))
      }

      if (stableValueCollectiveInvestmentFund?.fees) {
        stableValueCollectiveInvestmentFund.fees.forEach((item) =>
          dates.push(item?.date as string)
        )
      }

      if (stableValueSeparateAccount?.fees) {
        stableValueSeparateAccount.fees.forEach((item) =>
          dates.push(item?.date as string)
        )
      }

      const maxDate = dates.sort().pop() || quarterEndDate
      let stableValueFees,
        stableValueCollectiveInvestmentFundFees,
        stableValueSeparateAccountFees
      if (stableValue?.fees) {
        const latestFee = stableValue.fees.find(
          (item) => item?.date === maxDate
        )
        stableValueFees = latestFee || {}
      }

      if (stableValueCollectiveInvestmentFund?.fees) {
        const latestFee = stableValueCollectiveInvestmentFund.fees.find(
          (item) => item?.date === maxDate
        )
        stableValueCollectiveInvestmentFundFees = latestFee || {}
      }

      if (stableValueSeparateAccount?.fees) {
        const latestFee = stableValueSeparateAccount.fees.find(
          (item) => item?.date === maxDate
        )
        stableValueSeparateAccountFees = latestFee || {}
      }

      setFees({
        stableValue: stableValueFees,
        stableValueCollectiveInvestmentFund:
          stableValueCollectiveInvestmentFundFees,
        stableValueSeparateAccount: stableValueSeparateAccountFees,
      })
      setLatestFeeDate(maxDate)
    }
  }, [loading, data, quarterEndDate])

  const toggle = () => toggleHistoryModal(!showHistory)

  const onChangeCallback = (value: StableValueFeeTypes) => {
    setLatestFeeDate(previousQuarterEnd)
    if (value.stableValue) {
      let stableValue: StableValueFeeTypes["stableValue"] = {
        date: previousQuarterEnd,
      }
      if (
        checkIfSet(
          value.stableValue.annualizedSubAdvisedPctOfApplicableSubadvisedAssets
        )
      ) {
        stableValue.annualizedSubAdvisedPctOfApplicableSubadvisedAssets =
          +(value.stableValue
            .annualizedSubAdvisedPctOfApplicableSubadvisedAssets as number)
      }
      if (
        checkIfSet(value.stableValue.annualizedWrapPctOfApplicableWrappedAssets)
      ) {
        stableValue.annualizedWrapPctOfApplicableWrappedAssets = +(value
          .stableValue.annualizedWrapPctOfApplicableWrappedAssets as number)
      }
      handleInputChange(stableValue, "vehicle.stableValueFees")
    }
    if (value.stableValueCollectiveInvestmentFund) {
      let stableValueCollectiveInvestmentFund: StableValueFeeTypes["stableValueCollectiveInvestmentFund"] =
        {
          date: previousQuarterEnd,
        }
      if (checkIfSet(value.stableValueCollectiveInvestmentFund.allInFee)) {
        stableValueCollectiveInvestmentFund.allInFee = +(value
          .stableValueCollectiveInvestmentFund.allInFee as number)
      }
      handleInputChange(
        stableValueCollectiveInvestmentFund,
        "vehicle.stableValueCollectiveInvestmentFundFees"
      )
    }
    if (value.stableValueSeparateAccount) {
      let stableValueSeparateAccount: StableValueFeeTypes["stableValueSeparateAccount"] =
        {
          date: previousQuarterEnd,
        }
      if (
        checkIfSet(
          value.stableValueSeparateAccount
            .annualizedSubAdvisedPctOfTotalPortfolio
        )
      ) {
        stableValueSeparateAccount.annualizedSubAdvisedPctOfTotalPortfolio =
          +(value.stableValueSeparateAccount
            .annualizedSubAdvisedPctOfTotalPortfolio as number)
      }
      if (
        checkIfSet(
          value.stableValueSeparateAccount.annualizedWrapPctOfTotalPortfolio
        )
      ) {
        stableValueSeparateAccount.annualizedWrapPctOfTotalPortfolio = +(value
          .stableValueSeparateAccount
          .annualizedWrapPctOfTotalPortfolio as number)
      }
      handleInputChange(
        stableValueSeparateAccount,
        "vehicle.stableValueSeparateAccountFees"
      )
    }
    setFees(value)
  }

  const getFeeHistoryRows = () => {
    if (!data?.vehicle) {
      return []
    }
    const stableValue = (data?.vehicle as StableValue).stableValue
    const stableValueCollectiveInvestmentFund = (
      data?.vehicle as StableValueCollectiveInvestmentFund
    ).stableValueCollectiveInvestmentFund
    const stableValueSeparateAccount = (
      data?.vehicle as StableValueSeparateAccount
    ).stableValueSeparateAccount

    const fees: FeeHistoryRow[] = []

    if (stableValue?.fees) {
      stableValue.fees.forEach((fee) => {
        fees.push({
          date: fee?.date
            ? moment(fee.date, DATE_API_FORMAT).format("MM/DD/YYYY")
            : null,
          data: [
            [
              "Annualized Wrap (% of applicable wrapped assets)",
              checkIfSet(fee?.annualizedWrapPctOfApplicableWrappedAssets)
                ? formatPercent(
                    fee?.annualizedWrapPctOfApplicableWrappedAssets as number,
                    editing
                  )
                : null,
            ],
            [
              "Annualized Sub-Advised (% of applicable sub-advised)",
              checkIfSet(
                fee?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets
              )
                ? formatPercent(
                    fee?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets as number,
                    editing
                  )
                : null,
            ],
          ],
        })
      })
    }

    if (stableValueSeparateAccount?.fees) {
      stableValueSeparateAccount.fees.forEach((fee) => {
        const data = [
          [
            "Annualized Wrap (% of total portfolio)",
            checkIfSet(fee?.annualizedWrapPctOfTotalPortfolio)
              ? formatPercent(
                  fee?.annualizedWrapPctOfTotalPortfolio as number,
                  editing
                )
              : null,
          ],
          [
            "Annualized Sub-Advised (% of total portfolio)",
            checkIfSet(fee?.annualizedSubAdvisedPctOfTotalPortfolio)
              ? formatPercent(
                  fee?.annualizedSubAdvisedPctOfTotalPortfolio as number,
                  editing
                )
              : null,
          ],
        ]
        const date = fee?.date
          ? moment(fee.date, DATE_API_FORMAT).format("MM/DD/YYYY")
          : null
        const idx = fees.findIndex((item) => item.date === date)
        if (idx === -1) {
          fees.push({
            date,
            data,
          })
        } else {
          //insert the stable value separate account fees in specific order
          fees[idx].data.splice(1, 0, data[0])
          fees[idx].data.splice(3, 0, data[1])
        }
      })
    }

    if (
      data?.vehicle.__typename !==
        "OpenEndedCollectiveInvestmentFundStableValueComposite" &&
      stableValueCollectiveInvestmentFund?.fees
    ) {
      stableValueCollectiveInvestmentFund.fees.forEach((fee) => {
        const data = [
          [
            "All-In-Fee",
            checkIfSet(fee?.allInFee)
              ? formatPercent(fee?.allInFee as number, editing)
              : null,
          ],
        ]
        const date = fee?.date
          ? moment(fee.date, DATE_API_FORMAT).format("MM/DD/YYYY")
          : null
        const idx = fees.findIndex((item) => item.date === date)
        if (idx === -1) {
          fees.push({
            date,
            data,
          })
        } else {
          //all-in-fee goes last
          fees[idx].data.push(data[0])
        }
      })
    }
    /*sort fees in descending order by date and filter out the historical
    rows that are completely blank*/
    return fees
      .sort((a, b) => {
        const dateA = moment(a.date as string, "MM/DD/YYYY")
        const dateB = moment(b.date as string, "MM/DD/YYYY")
        if (dateA === dateB) return 0
        return dateA > dateB ? -1 : 1
      })
      .filter((fee) => fee.data.some((feeData) => feeData[1]))
  }

  const feeHistoryData = {
    headers: ["FEE", "MANAGEMENT FEE"],
    rows: getFeeHistoryRows(),
  }

  const {
    stableValue,
    stableValueCollectiveInvestmentFund,
    stableValueSeparateAccount,
  } = fees
  return (
    <>
      <Card>
        <CardHeader className="with-input">
          <Row style={{ display: "table" }}>
            <Col className={"pl-1"} style={{ display: "table-cell" }}>
              Stable Value Fees{" "}
              {!editing && (
                <FontAwesomeIcon
                  onClick={toggle}
                  icon="history"
                  className="ml-2"
                />
              )}
            </Col>
            <Col className="form-inline">
              <FormGroup>
                <Label className={"text-nowrap"} for="datedFeesDate" sm={2}>
                  As of
                </Label>
                <Col sm={10}>
                  <Input
                    type="date"
                    bsSize="sm"
                    disabled
                    name="datedFeesDate"
                    value={editing ? quarterEndDate : latestFeeDate}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className={"fee-card exportable"} data-export-name={"Stable Value Fees"}>
          <Table
            className={"fee-table"}
            
          >
            <tbody>
              {stableValue && (
                <tr>
                  <td>
                    <Form>
                      <FormGroup row className="fee-group">
                        <Label sm={8} className={"pl-3"}>
                          Annualized Wrap (% of applicable wrapped assets)
                        </Label>
                        <Col sm={4}>
                          <PercentInput
                            value={
                              stableValue.annualizedWrapPctOfApplicableWrappedAssets
                            }
                            disabled={!editing}
                            onChange={(fee) => {
                              onChangeCallback({
                                ...fees,
                                stableValue: {
                                  ...fees.stableValue,
                                  date: previousQuarterEnd,
                                  annualizedWrapPctOfApplicableWrappedAssets:
                                    +fee,
                                },
                              })
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </td>
                </tr>
              )}
              {stableValueSeparateAccount && (
                <tr>
                  <td>
                    <Form>
                      <FormGroup row className="fee-group">
                        <Label className={"pl-3"} sm={8}>
                          Annualized Wrap (% of total portfolio)
                        </Label>
                        <Col sm={4}>
                          <PercentInput
                            value={
                              stableValueSeparateAccount.annualizedWrapPctOfTotalPortfolio
                            }
                            disabled={!editing}
                            onChange={(fee) => {
                              onChangeCallback({
                                ...fees,
                                stableValueSeparateAccount: {
                                  ...fees.stableValueSeparateAccount,
                                  date: previousQuarterEnd,
                                  annualizedWrapPctOfTotalPortfolio: +fee,
                                },
                              })
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </td>
                </tr>
              )}
              {stableValue && (
                <tr>
                  <td>
                    <Form>
                      <FormGroup row className="fee-group">
                        <Label sm={8} className={"pl-3"}>
                          Annualized Sub-Advised (% of applicable sub-advised)
                        </Label>
                        <Col sm={4}>
                          <PercentInput
                            value={
                              stableValue.annualizedSubAdvisedPctOfApplicableSubadvisedAssets
                            }
                            disabled={!editing}
                            onChange={(fee) => {
                              onChangeCallback({
                                ...fees,
                                stableValue: {
                                  ...fees.stableValue,
                                  date: previousQuarterEnd,
                                  annualizedSubAdvisedPctOfApplicableSubadvisedAssets:
                                    +fee,
                                },
                              })
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </td>
                </tr>
              )}
              {stableValueSeparateAccount && (
                <tr>
                  <td>
                    <Form>
                      <FormGroup row className="fee-group">
                        <Label sm={8} className={"pl-3"}>
                          Annualized Sub-Advised (% of total portfolio)
                        </Label>
                        <Col sm={4}>
                          <PercentInput
                            value={
                              stableValueSeparateAccount.annualizedSubAdvisedPctOfTotalPortfolio
                            }
                            disabled={!editing}
                            onChange={(fee) => {
                              onChangeCallback({
                                ...fees,
                                stableValueSeparateAccount: {
                                  ...fees.stableValueSeparateAccount,
                                  date: previousQuarterEnd,
                                  annualizedSubAdvisedPctOfTotalPortfolio: +fee,
                                },
                              })
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </td>
                </tr>
              )}
              {stableValueCollectiveInvestmentFund &&
                data?.vehicle?.__typename !==
                  "OpenEndedCollectiveInvestmentFundStableValueComposite" && (
                  <tr>
                    <td>
                      <Form>
                        <FormGroup row className="fee-group">
                          <Label sm={8} className={"pl-3"}>All-In-Fee</Label>
                          <Col sm={4}>
                            <PercentInput
                              value={
                                stableValueCollectiveInvestmentFund.allInFee
                              }
                              disabled={!editing}
                              onChange={(fee) => {
                                onChangeCallback({
                                  ...fees,
                                  stableValueCollectiveInvestmentFund: {
                                    ...fees.stableValueCollectiveInvestmentFund,
                                    date: previousQuarterEnd,
                                    allInFee: +fee,
                                  },
                                })
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <FeeHistoryModal
        isOpen={showHistory}
        title="Fee Minimums"
        toggle={toggle}
        data={feeHistoryData}
      ></FeeHistoryModal>
    </>
  )
}

import { GetLookupDataToOptions } from "../../ui/LookupOptions"
import { FormInputField } from "../../../helpers/constant"
import { VehicleType } from "./VehicleFees"

const capitalStructureOptions = GetLookupDataToOptions({
  data: [
    {
      code: "OPEN",
      value: "Open Ended",
    },
    {
      code: "CLOSED",
      value: "Closed Ended",
    },
  ],
}) as JSX.Element

export const VehicleDetailInputs: (FormInputField & 
  {
    applicableTypes?: VehicleType[]
    inApplicableTypes?: VehicleType[]
    permissionToSee?: string[]
    permissionToEdit?: string[]
})[] = [
  {
    property: "vehicle.name",
    label: "Name",
    type: "text",
    readonly: true,
  },
  {
    property: "vehicle.graphName",
    label: "Vehicle Graph Name",
    type: "text",
    permissionToSee: ["view:vehicle_private"],
    permissionToEdit: ["edit:vehicle_private"],
  },
  {
    property: "vehicle.id",
    label: "Vehicle ID",
    type: "text",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "vehicleIdTooltip"
    }
  },
  {
    property: "vehicle.category.value",
    label: "Vehicle Category",
    type: "text",
    readonly: true,
  },
  {
    property: "vehicle.type.code",
    label: "Fund Type",
    type: "select",
    subtype: "single",
  },
  {
    property: "vehicle.identifiers.ticker",
    label: "Ticker",
    type: "text",
  },
  {
    property: "vehicle.identifiers.cusip",
    label: "CUSIP",
    type: "text",
  },
  {
    property: "vehicle.capitalStructure",
    label: "Capital Structure",
    type: "select",
    subtype: "single",
    options: capitalStructureOptions,
  },
  {
    property: "vehicle.status.code",
    label: "Status",
    type: "select",
    subtype: "single",
    optionSource: "VehicleStatusCode",
  },
  {
    property: "vehicle.closeDate",
    label: "Final Close Date",
    type: "date",
    subtype: "month",
    // applicable to Closed Ended Vehicles.
    applicableTypes: [
      "ClosedEndedPrivateNonRegisteredMulitAssetClass",
      "ClosedEndedPrivateNonRegisteredPrivateCredit",
      "ClosedEndedPrivateNonRegisteredPrivateEquity",
      "ClosedEndedPrivateNonRegisteredRealAssets",
    ],
    subClasses: {
      inputClasses: "text-right ml-1 px-1",
    }
  },
  {
    property: "vehicle.inceptionDate",
    label: "Inception Date",
    type: "date",
    subtype: "month",
    inApplicableTypes: [
      "ClosedEndedPrivateNonRegisteredMulitAssetClass",
      "ClosedEndedPrivateNonRegisteredPrivateCredit",
      "ClosedEndedPrivateNonRegisteredPrivateEquity",
      "ClosedEndedPrivateNonRegisteredRealAssets",
    ],
    subClasses: {
      inputClasses: "text-right ml-1 px-1",
    }
  },
  {
    property: "vehicle.inceptionDate",
    label: "Vintage- Legal Inception",
    type: "date",
    subtype: "month",
    // applicable to Closed Ended Vehicles
    applicableTypes: [
      "ClosedEndedPrivateNonRegisteredMulitAssetClass",
      "ClosedEndedPrivateNonRegisteredPrivateCredit",
      "ClosedEndedPrivateNonRegisteredPrivateEquity",
      "ClosedEndedPrivateNonRegisteredRealAssets",
    ],
    subClasses: {
      inputClasses: "text-right ml-1 px-1",
    }
  },
  {
    property: "closedEndedVehicle.vintageFirstCashFlow",
    label: "Vintage- First Cash Flow",
    type: "date",
    subClasses: {
      inputClasses: "text-right",
    }
  },
  {
    property: "vehicle.closeDate",
    label: "Close Date (if applicable)",
    type: "date",
    subtype: "month",
    // applicable to Opened Ended Vehicles.
    inApplicableTypes: [
      "ClosedEndedPrivateNonRegisteredMulitAssetClass",
      "ClosedEndedPrivateNonRegisteredPrivateCredit",
      "ClosedEndedPrivateNonRegisteredPrivateEquity",
      "ClosedEndedPrivateNonRegisteredRealAssets",
    ],
    subClasses: {
      inputClasses: "text-right ml-1 px-1",
    }
  },
  {
    property: "mutualFund.shareClass.code",
    label: "Share Class",
    type: "select",
    subtype: "single",
    optionSource: "ShareClassCode",
  },
  {
    property: "vehicle.is529",
    label: "529",
    type: "radio",
    subtype: "boolean",
    applicableTypes: ["OpenEndedMutualFund"],
  },
  {
    property: "vehicle.privatePlacement",
    label: "Private Placement",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "vehicle.isRegisteredWithSecuritiesAndExchangeComission",
    label: "SEC Registered",
    type: "radio",
    subtype: "boolean",
    applicableTypes: [
      "OpenEndedPooledVehicle",
      "OpenEndedVariableAnnuity",
      "OpenEndedCollectiveInvestmentFundComposite",
      "ClosedEndedPrivateNonRegisteredMulitAssetClass",
      "ClosedEndedPrivateNonRegisteredPrivateCredit",
      "OpenEndedPooledVehiclePrivateEquity",
      "ClosedEndedPrivateNonRegisteredPrivateEquity",
      "OpenEndedPooledVehicleRealAssets",
      "ClosedEndedPrivateNonRegisteredRealAssets",
      "OpenEndedPrivateNonRegisteredHedgeFund",
      "OpenEndedCollectiveInvestmentFundStableValue",
      "OpenEndedCollectiveInvestmentFundStableValueComposite",
      "OpenEndedCollectiveInvestmentFund",
      "OpenEndedCollectiveInvestmentFundRealAssets",
      "OpenEndedCollectiveInvestmentFundCompositeRealAssets",
      "OpenEndedPooledVehiclePrivateCredit",
    ],
  },
  { property: "", label: "", type: "" },
  {
    property: "vehicle.domicile.code",
    label: "Domicile",
    type: "select",
    subtype: "single",
    optionSource: "FundDomicileCode",
  },
  {
    property: "vehicle.countryOfIncorporation.code",
    label: "Country of Incorporation",
    type: "select",
    subtype: "single",
    optionSource: "CountryCode",
  },
  {
    property: "vehicle.stateOfIncorporation.code",
    label: "State of Incorporation",
    type: "select",
    subtype: "single",
    optionSource: "StateCode",
  },
  {
    property: "vehicle.currency.code",
    label: "Currency",
    type: "select",
    subtype: "single",
    optionSource: "CurrencyCode",
  },
  { property: "", label: "", type: "" },
  {
    property: "vehicle.frequencyOfValuation.code",
    label: "Frequency of Valuation",
    type: "select",
    subtype: "single",
    optionSource: "TimePeriodCode",
  },
  {
    property: "vehicle.frequencyOfDistribution.code",
    label: "Frequency of Distribution",
    type: "select",
    subtype: "single",
    optionSource: "TimePeriodCode",
  },
  {
    property: "vehicle.liquidityOnPurchases.code",
    label: "Liquidity on Purchases",
    type: "select",
    subtype: "single",
    optionSource: "TimePeriodCode",
  },
  {
    property: "vehicle.liquidityOnSales.code",
    label: "Liquidity on Sales",
    type: "select",
    subtype: "single",
    optionSource: "TimePeriodCode",
  },
  {
    property: "vehicle.dividendReinvest",
    label: "Dividend Reinvestment",
    type: "radio",
    subtype: "boolean",
  },
  { property: "", label: "", type: "" },
  {
    property: "vehicle.employeeRetirementIncomeSecurityActTaxExempt",
    label: "Available to ERISA Tax-Exempt",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "vehicle.unitedStatesTaxable",
    label: "Available to US Taxable",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "vehicle.unitedStatesTaxExempt",
    label: "Available to US Tax-Exempt",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "vehicle.lendSecurities",
    label: "Lend Securities",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "vehicle.offsetGainsWithLosses",
    label: "Offset Gains with Losses",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "stableValue.putProvision",
    label: "Stable Value Put Provision",
    type: "number",
    subtype: "integer",
  },
  { property: "", label: "", type: "" },
  {
    property: "hedgeFundVehicle.subjectTo25PercentLimit",
    label: "Subject to 25% Limit",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "hedgeFundVehicle.unrelatedBusinessIncomeTax",
    label: "UBIT",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "hedgeFundVehicle.subscriptionPeriod.code",
    label: "Subscription Period",
    type: "select",
    subtype: "single",
    optionSource: "HedgeFundPeriodCode",
  },
  {
    property: "hedgeFundVehicle.redemptionPeriod.code",
    label: "Redemption Period",
    type: "select",
    subtype: "single",
    optionSource: "HedgeFundPeriodCode",
  },
  {
    property: "hedgeFundVehicle.lockupPeriod.code",
    label: "Lockup Period",
    type: "select",
    subtype: "single",
    optionSource: "HedgeFundPeriodCode",
  },
  {
    property: "hedgeFundVehicle.noticePeriod.code",
    label: "Notice Period",
    type: "number",
  },
  {
    property: "hedgeFundVehicle.initialPayout",
    label: "Initial Payout",
    type: "number",
  },
  {
    property: "hedgeFundVehicle.finalPayout",
    label: "Final Payout",
    type: "number",
  },
  {
    property: "hedgeFundVehicle.gateFundProvision",
    label: "Fund Gate Provision",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "hedgeFundVehicle.gateInvestorProvision",
    label: "Investor Gate Provision",
    type: "radio",
    subtype: "boolean",
  },
  {
    property: "hedgeFundVehicle.gateProvisionExplanation",
    label: "Explanation for Fund Gate Provision",
    type: "textarea",
  },
]

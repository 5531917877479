import { disableFragmentWarnings, gql } from "@apollo/client"
import { REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT } from "./FootnoteFragments"

disableFragmentWarnings()

export const DASHBOARD_STOPLIGHT_LIST_FRAGMENT = gql`
  fragment DashboardStoplightListFragment on List {
    id
    name
    items {
      order
      group
      item {
        ... on ListGroup {
          id
        }
        ... on ListHeader {
          id
          text
        }
        ... on Vehicle {
          vehicle {
            id: fundid
            vehicleName: name
            UMAProxyFundid
          }
        }
        ... on Product {
          product {
            id
            productName: name
            vehicles {
              vehicle {
                id: fundid
                vehicleName: name
                category {
                  value
                  code
                }
              }
            }
          }
        }
        ... on ClientPortfolio {
          ...ReportCommonClientPortfolioFragment
          serviceStartDate
          serviceEndDate
          performanceSource {
            code
            value
          }
          portfolioName: name
          relatedVehicle {
            vehicle {
              id: fundid
              vehicleName: name
            }
          }
        }
      }
    }
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
`

export const DASHBOARD_STOPLIGHT_STATUS_DETAIL_FRAGMENT = gql`
  fragment DashboardStoplightStatusDetailFragment on StatusDetails {
    researchCategory
    status
    lastChangedDate
    statusLastUpdated
  }
`

export const DASHBOARD_STOPLIGHT_STATUS_FRAGMENT = gql`
  fragment DashboardStoplightStatusFragment on StatusesForList {
    manager {
      id
      name
    }
    product {
      product {
        id
        name
        assetClass {
          id
          shortName
          fullName
          order
        }
        lastActivity
      }
    }
    vehicle {
      vehicle {
        id: fundid
        vehicleName: name
        UMAProxyFundid
      }
    }
    statuses {
      ...DashboardStoplightStatusDetailFragment
    }
  }
  ${DASHBOARD_STOPLIGHT_STATUS_DETAIL_FRAGMENT}
`

export const DASHBOARD_STOPLIGHT_FRAGMENT = gql`
  fragment DashboardStoplightFragment on Stoplight {
    useListOrder
    showVehicle
    showManagerOverall
    showManagerPeople
    showProductOverall
    showProductPeople
    showLongTermPerformance
    showShortTermPerformance
    showPhilosophyProcess
    showProductDynamics
    showLastUpdated
    list {
      id
    }
    client {
      id
    }
  }
`

export const REPORT_SCHEDULE_FRAGMENT = gql`
  fragment ReportScheduleFragment on ReportSchedule {
    _
  }
`

export const REPORT_SCHEDULE_REPORT_FRAGMENT = gql`
  fragment ReportScheduleReportFragment on Report {
    id
    name
    active
    frequency {
      code
      value
    }
    plans {
      id
      name
      consultant {
        id
        firstName
        lastName
      }
      backupConsultant {
        id
        firstName
        lastName
      }
    }
    client {
      id
      name
    }
    dueDates {
      dueDate
      quarterDate
    }
    lastPublished
    owner {
      id
      firstName
      lastName
    }
  }
`
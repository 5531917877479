import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from 'classnames'
import React, { EventHandler, useContext, useEffect, useState } from 'react'
import { CustomInput, FormGroup, Label } from 'reactstrap'

import { EditButtonContext } from '../../../Context/EditButtonContext'
import { InputTooltip } from './TextFieldInput'
import { InfoTipProps } from './FormInput'
import { UncontrolledTooltip } from 'reactstrap/lib'

interface CheckboxBooleanInputProps {
  idx: string | number,
  property: string,
  displayName: string,
  propertyValue: string | boolean | undefined,
  editMode: boolean,
  subtype: string,
  updateValue: (event:any) => void
  required?: boolean
  wrapperClasses?: string,
  labelClasses?: string,
  inputWrapperClasses?: string,
  inputClasses?: string
  tooltip?: InputTooltip
  infoTip?: InfoTipProps
  readonly?: boolean

}

const getInfoTipArgs = (infoTip: InfoTipProps | undefined, element: string, editMode: boolean) => {
  if(!infoTip) return null
  const { title, parentElement, placement, cssClasses, enabledInViewMode } = infoTip

  if(!title || (element !== parentElement)) return null
  if(!enabledInViewMode && !editMode) return null
  return {
    // "data-toggle": "tooltip", // bootstrap < v5
    "placement": placement || "top",
    "title": title,
    "placementPrefix": "custom-info-tip",
    enabledInViewMode,
  }
}

export const CheckboxBooleanInput: React.FC<CheckboxBooleanInputProps> = props => {
  const { idx, property, displayName, propertyValue, editMode, infoTip, tooltip, labelClasses,inputWrapperClasses, inputClasses, subtype, readonly } = props
  let updateValue: EventHandler<any> = props.updateValue
  let booleanValue:boolean = propertyValue === 'true' || propertyValue === true;
  let [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || '')
  const displayLabel = (displayName !== "")

  let required = props.required || false
  let shortProperty = property.split(".").pop() || property
  const showHide = subtype === "show"
  const showOff = subtype === "on"
  const context = useContext(EditButtonContext)
  const { setError } = context

  useEffect(() => {
    if(required){
      if(propertyValue === undefined || propertyValue === null){
        setWrapperClasses(wrapperClasses + ' has-error')
        setError(`${idx}-${shortProperty}`, ["Presence"])
      } else {
        setError(`${idx}-${shortProperty}`, [])
        setWrapperClasses(wrapperClasses.replace(/has-error/g,''))
      }
    }
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [editMode, idx, propertyValue, required])

  const handleBoolCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    updateValue: (value:any) => void
  ) => {
    if (!shortProperty) {
      shortProperty = property
    }
    const value = event.target.checked
    // if (_.get(currentState, property) === undefined) {
    //   _.set(currentState, property, null)
    // }
    updateValue(value)
  }

  const infoArgs = getInfoTipArgs(infoTip, "label", editMode)
  const couldShowInfoTip = infoTip && (infoTip?.enabledInViewMode || editMode)
  const inputId = `${idx}-${shortProperty}-true`
  const inputWrapperId = `${idx}-${shortProperty}-true-wrapper`
  const targetId = infoTip?.parentElement === "div"? inputWrapperId: inputId
  return (
    <FormGroup
      className={classnames("row", wrapperClasses||"")}
      key={idx}
      required={required}
    >
      {displayLabel &&
      <>
        {couldShowInfoTip &&
          <UncontrolledTooltip
            className="tooltip-ontop-modal"
            target={targetId}
            {...infoArgs}
          >
          {infoTip?.title}
        </UncontrolledTooltip>}
        <Label
          className={classnames("col-form-label", {"col-sm-4": !labelClasses}, labelClasses)}
          htmlFor={`${idx}-${shortProperty}-true`}
          id={property}
        >
          <div
          className={classnames("d-flex w-100", {'tooltip-icon': tooltip, 'justify-content-start': tooltip?.onClick})}
            id={tooltip ? tooltip.id : ""}
            onClick={(event) => {event.preventDefault()}}
          >
            {displayName}
            {tooltip &&
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={(event) => {
                  event.preventDefault()
                  if(tooltip.onClick) {
                    tooltip.onClick()
                  }
                }}
              />
            }
          </div>
        </Label>
      </>
      }
      <div
        className={classnames("d-flex align-items-center justify-content-start exportable-form-input", {"col-sm-8":displayLabel &&!inputWrapperClasses,"col-sm-12":!(displayLabel||inputWrapperClasses), "pl-4": !(showHide || showOff ||inputWrapperClasses)}, inputWrapperClasses)}
        id={inputWrapperId}
      >
        <CustomInput
          className={classnames(inputClasses,{
            hidden: false, // (!editMode && propertyValue !== true)
            "boolean-checkbox": !showHide && !showOff,
            "pill-checkbox": showHide || showOff,
            "on-label": showOff,
          })}
          id={inputId}
          bsSize="sm"
          type="checkbox"
          name={`${idx}-${shortProperty}`}
          checked={booleanValue === true}
          onChange={(event:any) => {
            handleBoolCheckboxClick(event, updateValue)
          }}
          value={"Yes"}
          inline
          disabled={!editMode || readonly}
        />
      </div>
    </FormGroup>
  )
}
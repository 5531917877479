import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import { cloneDeep, compact, debounce, find, findIndex, first, get, groupBy, remove, set, sortBy } from "lodash"
import moment from 'moment'
import React, { useEffect, useMemo, useState } from "react"
import { Button, Col, CustomInput, FormGroup, Label, Row, Table } from 'reactstrap'
import { ClientPortfolioDatedFeeFragment } from '../../../__generated__/graphql'
import { FormInput } from "../../ui/Forms/FormInput"
import { DATE_API_FORMAT, FormInputField } from "../../../helpers/constant"
import classNames from "classnames"
import { HistoryModal } from "./HistoryModal"
import { getMaxDate } from "../../Product/ProductVehicles/helper"
import { appDate } from "../../../Context/CalendarContext"


type FeesProps = {
  data: ClientPortfolioDatedFeeFragment[]
  editMode: boolean
  initialState: ClientPortfolioDatedFeeFragment[]
  handleChange: (values: ClientPortfolioDatedFeeFragment[]) => void
  endAssetDate?: string
  relatedVehicleType?: string
}


const DatedFeesInput: FormInputField[] = [
  {
    property: 'annualizedSubAdvisedPctOfApplicableSubadvisedAssets',
    label: 'Annualized Sub-Advised (% of applicable sub-advised)',
    type: 'float',
    subtype: 'percent',
    subClasses: {
      inputClasses: 'text-right',
      labelClasses: 'col-sm-6',
      inputWrapperClasses: 'col-sm-6',
    },
    placeholder: '0.000%',
  },
  {
    property: 'annualizedWrapPctOfApplicableWrappedAssets',
    label: 'Annualized Wrap (% of applicable wrapped assets)',
    type: 'float',
    subtype: 'percent',
    subClasses: {
      inputClasses: 'text-right',
      labelClasses: 'col-sm-6',
      inputWrapperClasses: 'col-sm-6',
    },
    placeholder: '0.000%',
  },
  {
    property: 'annualizedSubAdvisedPctOfTotalPortfolio',
    label: 'Annualized Sub-Advised (% of total portfolio)',
    type: 'float',
    subtype: 'percent',
    subClasses: {
      inputClasses: 'text-right',
      labelClasses: 'col-sm-6',
      inputWrapperClasses: 'col-sm-6',
    },
    placeholder: '0.000%',
  },
  {
    property: 'annualizedWrapPctOfTotalPortfolio',
    label: 'Annualized Wrap (% of total portfolio)',
    type: 'float',
    subtype: 'percent',
    subClasses: {
      inputClasses: 'text-right',
      labelClasses: 'col-sm-6',
      inputWrapperClasses: 'col-sm-6',
    },
    placeholder: '0.000%',
  },
  {
    property: 'totalExpense',
    label: 'Total Expense',
    type: 'float',
    subtype: 'percent',
    subClasses: {
      inputClasses: 'text-right',
      labelClasses: 'col-sm-6',
      inputWrapperClasses: 'col-sm-6',
    },
    placeholder: '0.000%',
  },
]

const DEFAULT_EMPTY_DATED_FEE:ClientPortfolioDatedFeeFragment = {
  date: appDate.format(DATE_API_FORMAT),
  annualizedSubAdvisedPctOfApplicableSubadvisedAssets: null,
  annualizedWrapPctOfApplicableWrappedAssets: null,
  annualizedSubAdvisedPctOfTotalPortfolio: null,
  annualizedWrapPctOfTotalPortfolio: null,
  __typename: "ClientPortfolioDatedFee",
}

interface FeeHistoryModalProps {
  isOpen: boolean
  toggle: () => void
  data: ClientPortfolioDatedFeeFragment[]
  relatedVehicleType?: string
}

export const FeeHistoryModal: React.FC<FeeHistoryModalProps> = ({
  isOpen,
  toggle,
  data,
  relatedVehicleType
}) => {

  const [sortedData, extraRows] = useMemo(() => {
    let extraRows = []
    extraRows.push({values: ['Date','Field','Value']})
    let sortedData = sortBy(data, (data) => moment(data.date).valueOf()).reverse()
    let usedData:ClientPortfolioDatedFeeFragment[] = []
    sortedData.forEach((row) => {
      let hasValues = false
      if(row.annualizedSubAdvisedPctOfApplicableSubadvisedAssets || row.annualizedWrapPctOfApplicableWrappedAssets) hasValues = true
      if(!hasValues && relatedVehicleType === "OpenEndedSeparateAccountStableValue" && (row.annualizedSubAdvisedPctOfTotalPortfolio || row.annualizedWrapPctOfTotalPortfolio)) hasValues = true
      if(!hasValues && relatedVehicleType === "OpenEndedCollectiveInvestmentFundStableValueComposite" && row.totalExpense) hasValues = true
      if(hasValues) {
        usedData.push(row)
        extraRows.push({values: [row.date, "Annualized Sub-Advised (% of applicable sub-advised)", row.annualizedSubAdvisedPctOfApplicableSubadvisedAssets?.toString() || ""]})
        extraRows.push({values: [row.date, "Annualized Wrap (% of applicable wrapped assets)", row.annualizedWrapPctOfApplicableWrappedAssets?.toString() || ""]})
        if(relatedVehicleType === "OpenEndedSeparateAccountStableValue"){
          extraRows.push({values: [row.date, "Annualized Sub-Advised (% of total portfolio)", row.annualizedSubAdvisedPctOfTotalPortfolio?.toString() || ""]})
          extraRows.push({values: [row.date, "Annualized Wrap (% of total portfolio)", row.annualizedWrapPctOfTotalPortfolio?.toString() || ""]})
        } else if (relatedVehicleType === "OpenEndedCollectiveInvestmentFundStableValueComposite"){
          extraRows.push({values: [row.date, "Total Expense", row.totalExpense?.toString() || ""]})
        }
      }
    })
    return [usedData, extraRows]
  }, [data])
  const latestFeeDate = getMaxDate(data)

  return (
    <HistoryModal isOpen={isOpen} toggle={toggle} title={"Fees"} xlSize>
      <div className="exportable-form" data-export-name={"Fees"} data-export-settings={JSON.stringify({extraRows})}><div></div></div>
      {sortedData.map((row, idx) => {
        return (
          <CompositeFeesHistoryTable
            key={idx}
            title={"fee-history"}
            data={row}
            latestFeeDate={latestFeeDate}
            relatedVehicleType={relatedVehicleType}
          />
        )
      })}
    </HistoryModal>
  )
}

interface CompositeFeesHistoryTableProps {
  title: string
  data: ClientPortfolioDatedFeeFragment
  latestFeeDate: string
  relatedVehicleType?: string
}

export const CompositeFeesHistoryTable: React.FC<CompositeFeesHistoryTableProps> = ({
  title,
  data,
  latestFeeDate,
  relatedVehicleType,
}) => {
  const toDate = `As of ${moment(data.date).format("MM/DD/YYYY")}`

  return (
    <div className="">
      <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
        <h5 className="headline mt-3 text-nowrap d-inline-block">
          {toDate}
        </h5>
      </div>
      <div className="mx-3">
        {DatedFeesInput.map((headerDef, idx) => {
          const {property, label, type, subtype, readonly, required, subClasses} = headerDef
          if(["annualizedSubAdvisedPctOfTotalPortfolio","annualizedWrapPctOfTotalPortfolio"].includes(property) && relatedVehicleType !== "OpenEndedSeparateAccountStableValue") return <React.Fragment key={idx}></React.Fragment>
          if(["totalExpense"].includes(property) && relatedVehicleType !== "OpenEndedCollectiveInvestmentFundStableValueComposite") return <React.Fragment key={idx}></React.Fragment>
          let propertyVal = get(data, property)
          if(propertyVal) propertyVal = propertyVal*100
          return (
            <FormInput
              key={idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              idx={`${idx}-${toDate}-composite-member-item-${property}`}
              editMode={false}
              propertyVal={propertyVal}
              readonly={!!readonly}
              updateValue={(value: any) => null}
              required={required}
              subClasses={subClasses || {}}
              baseDecimalPlaces={3}
            />
          )
        })}
      </div>
    </div>
  )
}

type TableKey = "new" | "current"

type TableVisibility = {[key in TableKey]: boolean}

const getCurrentFee = (props: ClientPortfolioDatedFeeFragment[], currentDate: string) => {
  return props.find((fee: ClientPortfolioDatedFeeFragment) => fee?.date === currentDate)
}

const getFeeHistoryData = (props: ClientPortfolioDatedFeeFragment[], currentDate: string) => {
  return props.filter((fee: ClientPortfolioDatedFeeFragment) => fee?.date !== currentDate)
}

export const ClientPortfolioStableValueFees: React.FC<FeesProps> = (props) => {
  let {data, initialState, editMode, handleChange, endAssetDate, relatedVehicleType} = props
  const latestFeeDate = getMaxDate(data)
  const usedDate = editMode ? latestFeeDate : appDate.format(DATE_API_FORMAT)

  const [formattedData, setFormattedData] = useState(data)
  const [currentTableData, setCurrentTableData] = useState(getCurrentFee(formattedData, usedDate))
  // history modal
  const [feeHistoryData, setFeeHistoryData] = useState(getFeeHistoryData(formattedData, usedDate))
  const [showFullHistoryModal, setShowFullHistoryModal] = useState<boolean>(false)

  const [tableVisibility, setTableVisibility] = useState<TableVisibility>({
    new: false,
    current: true,
  })

  const toggleFullHistoryModal = () => setShowFullHistoryModal(!showFullHistoryModal)

  const onChangeCallback = (callback: (values: ClientPortfolioDatedFeeFragment) => ClientPortfolioDatedFeeFragment) => {
    let newValues = callback(currentTableData || DEFAULT_EMPTY_DATED_FEE)
    let newDataset
    let updatedFormattedData = cloneDeep(formattedData)
    let updateIndex = findIndex(updatedFormattedData, {date: appDate.format(DATE_API_FORMAT)})
    if(updateIndex === -1) {
      newValues.date = appDate.format(DATE_API_FORMAT)
      newDataset = compact([newValues, ...updatedFormattedData])
    } else {
      updatedFormattedData[updateIndex] = newValues
      newDataset = compact(updatedFormattedData)
    }
    setFormattedData(newDataset)
    setCurrentTableData(newValues)
    handleChange(newDataset)
  }

  useEffect(() => {
    let newState = data
    const latestFeeDate = getMaxDate(newState)
    setFormattedData(newState)
    setCurrentTableData(getCurrentFee(newState, latestFeeDate))
    if(!editMode) {
      setFeeHistoryData(getFeeHistoryData(newState, latestFeeDate))
    }
  }, [data])

  useEffect(() => {
    if(!editMode) {
      let newState = initialState
      const latestFeeDate = getMaxDate(newState)
      setFormattedData(newState)
      setCurrentTableData(getCurrentFee(newState, latestFeeDate))
      setFeeHistoryData(getFeeHistoryData(newState, latestFeeDate))
      setTableVisibility((prevState) => ({
        ...prevState,
        new: false,
      }))
    }
  }, [editMode, initialState])

  let managementFeeTableData = {editMode, tableVisibility, setTableVisibility, endAssetDate, toggleFullHistoryModal, relatedVehicleType}
  let currentTableProps = {...managementFeeTableData, onChangeCallback, tableKey: "current", data: currentTableData, setData: setCurrentTableData, editMode: editMode, hasFullHistoryButton: feeHistoryData.length > 0, previousDate: feeHistoryData[0]?.date} as StableValueFeeTableProps

  return (
  <Row className={classnames("pl-0")} key={`ClientPortfolioFees`}>
    <Col className={"px-0"}>
      {tableVisibility.current &&
        <StableValueFeeTable
          {...currentTableProps}
        />
      }
    </Col>
    <FeeHistoryModal
      isOpen={showFullHistoryModal}
      toggle={toggleFullHistoryModal}
      data={formattedData}
      relatedVehicleType={relatedVehicleType}
    />
  </Row>)
}

type changeCallback = (values:ClientPortfolioDatedFeeFragment) => ClientPortfolioDatedFeeFragment

export interface StableValueFeeTableProps {
  editMode: boolean,
  tableKey: TableKey,
  data: ClientPortfolioDatedFeeFragment,
  hasFullHistoryButton?: boolean
  onChangeCallback: (changeCallback:changeCallback) => void
  // [property: string]: any
  tableVisibility: TableVisibility
  setTableVisibility: React.Dispatch<React.SetStateAction<TableVisibility>>
  toggleFullHistoryModal?: () => void
  relatedVehicleType?: string
}

const StableValueFeeTable:React.FC<StableValueFeeTableProps> = (props) => {
  let {editMode, data, onChangeCallback, tableKey, tableVisibility, setTableVisibility, toggleFullHistoryModal, hasFullHistoryButton, relatedVehicleType} = props
  const [initialData, setInitialData] = useState(data)
  const showFullHistoryButton = tableKey ==="current" && !!hasFullHistoryButton
  const showEndDate = (tableKey === "current" && tableVisibility["new"]) && data
  let greyClass = ""
  if (tableKey === "current" && tableVisibility["new"]) {
    greyClass = "background-gray-20"
  }

  useEffect(() => {
    if(!editMode) {
      setInitialData(data)
    }
  },[editMode])

  return (
    <div>
      <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
        <div className="white-space-nowrap">
          <h5 className="headline mt-3 text-nowrap d-inline-block">
            {`Stable Value Fees `}
          </h5>
          <h5 className="headline text-uppercase text-gray-70 pl-1 mt-3 d-inline-block">{(showEndDate || tableKey !== "current") && ` (${tableKey})`}</h5>
        </div>
        <div className="mt-3">
          {tableKey === "current" && showFullHistoryButton &&
            (<Button color="link" className="text-callan-blue btn-thin pt-0 pb-0" onClick={toggleFullHistoryModal}>
              {"View Full History"}
              <FontAwesomeIcon icon="history" className="ml-2 text-callan-blue" />
            </Button>)
          }
          {tableKey === "current" &&
            <>
              As of {moment(data?.date || appDate).format("MM/DD/YYYY")}
            </>
          }
        </div>
      </div>
      <div className="mx-3">
        {DatedFeesInput.map((headerDef, idx) => {
          const {property, label, type, subtype, readonly, required, subClasses, placeholder} = headerDef
          if(["annualizedSubAdvisedPctOfTotalPortfolio","annualizedWrapPctOfTotalPortfolio"].includes(property) && relatedVehicleType !== "OpenEndedSeparateAccountStableValue") return <React.Fragment key={idx}></React.Fragment>
          if(["totalExpense"].includes(property) && relatedVehicleType !== "OpenEndedCollectiveInvestmentFundStableValueComposite") return <React.Fragment key={idx}></React.Fragment>
          let propertyVal = get(data, property)
          // This is to get around a rounding issues with how JS handles floats
          if(propertyVal) propertyVal = Math.round(propertyVal*100000)/1000
          return (
            <FormInput
              key={idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              idx={`${tableKey}-composite-member-item-${property}`}
              editMode={editMode}
              propertyVal={propertyVal}
              readonly={!!readonly}
              updateValue={(value: any) => {
                onChangeCallback((updateData) => {
                  let newData = cloneDeep(updateData)
                  set(newData, property, value / 100)
                  return newData
                })
              }}
              required={required}
              subClasses={subClasses || {}}
              baseDecimalPlaces={3}
              placeholder={placeholder}
            />
          )
        })}
      </div>
    </div>)
}





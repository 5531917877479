import React, { useContext } from "react"
import {
  Alert,
} from "reactstrap"
import { TemporaryAlertContext } from "../../Context/TemporaryAlertContext"

export const TemporaryAlert: React.FC = () => {
  const { alerts, hideAlert } = useContext(TemporaryAlertContext)
  let visibleCount = -1
  return(
    <>
      {alerts.map((alert) =>{
        if(alert.show) visibleCount += 1
        let toggle = () => {
          hideAlert(alert.id)
        }
        return (<Alert key={alert.id} isOpen={alert.show} className="alert-fixed" color={alert.color || "primary"} style={{marginTop: (visibleCount === -1 ? 0 : visibleCount) * 100}} toggle={toggle}>
          <div>
            {alert.title &&
              <h4>{alert.title}</h4>
            }
            <p>{alert.message}</p>
          </div>
        </Alert>)

      })}
    </>
  )
}

export default TemporaryAlert
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { cloneDeep, compact, find, flatMap, get, isArray, isNil, isUndefined, remove, some, uniq } from 'lodash'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Query } from '@apollo/client/react/components'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, ListGroupItem, Nav, NavItem, NavLink, Row } from 'reactstrap'

import { ClientPortfolio, ClientPortfolioPerformanceCashflowQuery, ClientPortfolioPerformanceCashflowSummaryQuery, ClientPortfolioUpdateFields, DeleteCashFlowTransactionInput, PerformancePeriodType, ProductVehiclesQuery, UpdateClientPortfolioInput, useClientPortfolioPerformanceCashflowHistoricLazyQuery, useDeleteClientPortfolioCashflowTransactionMutation, useUpdateClientPortfolioPerformanceBareMutation, Vehicle, VehicleFields, VehicleUpdateFields } from '../../../__generated__/graphql'
import Auth from '../../../Auth/Auth'
import { appDate, CalendarContext } from "../../../Context/CalendarContext"
import { DATE_API_FORMAT } from '../../../helpers/constant'
import { ProductClientPortfolioPerformanceReturnRouteProps } from '../../../queries/extended/types/Product'
import { CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_SUMMARY, CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS } from '../../../queries/Portfolio'
import { CalendarPicker } from '../../CalendarPicker'
import { OptionItem, Picker } from '../../Shared/Picker'
import RouteLeavingGuard from '../../Shared/RouteLeavingGuard'
import EditButtons from '../../ui/EditButtons'
import PlaceHolder from '../../ui/PlaceHolder'
import ProductPerformanceClientsCashflow from './ClientsCashFlow'
import ProductPerformanceClientsCashflowSummary from './ClientsCashflowSummary'
import ErrorDisplay from '../../Shared/ErrorDisplay'

export const AllowedReturnVehicleTypes = ["OpenEndedMutualFund", "OpenEndedVariableAnnuity", "OpenEndedPrivateNonRegisteredHedgeFund", "OpenEndedSeparateAccount", "OpenEndedPooledVehicle","OpenEndedPooledVehiclePrivateCredit", "OpenEndedPooledVehiclePrivateEquity", "OpenEndedSeparateAccountStableValue", "OpenEndedExchangeTradedFund", "OpenEndedSeparateAccountRealAssets", "OpenEndedCollectiveInvestmentFundComposite", "OpenEndedCollectiveInvestmentFund", "OpenEndedCollectiveInvestmentFundStableValue", "OpenEndedCollectiveInvestmentFundStableValueComposite", "OpenEndedPooledVehicleRealAssets"]


const firstHistoricalDate = moment(appDate).subtract(30,"years")

interface ProductPerformanceClientsProps extends ProductClientPortfolioPerformanceReturnRouteProps {
  productId: number
  auth: Auth
  productVehicles: ProductVehiclesQuery
}

interface IdVehicleFields extends Omit<VehicleFields, 'fundid'> {
  id: string
}

interface IdVehicle {
  vehicle?: IdVehicleFields | null
}

const ProductPerformanceClients: React.FC<ProductPerformanceClientsProps> = (props) => {
  const { productId, history, location, auth } = props
  const context = useContext(CalendarContext)
  const [editMode, setEditMode] = useState(false)
  const [editCancelled, setEditCancelled] = useState(false)
  const [clearChanges, setClearChanges] = useState(false)
  const [saving, setSaving] = useState(false)
  const [search, setSearch] = useState("")
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [historicEndDate, setHistoricEndDate] = useState(firstHistoricalDate)
  const [showCalendar, setShowCalendar] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [period, setPeriod] = useState("quarter")
  const [screen, setScreen] = useState(context.period == "historical" ? "historical" :"single")
  const [openPeriod, setOpenPeriod] = useState(false)
  const [downloadingHistory, setDownloadingHistory] = useState(false)
  const data = props.productVehicles

  let match = useRouteMatch()

  const [stateDiff, setStateDiff] = useState<ClientPortfolioUpdateFields>({})
  const [removedTransactions, setRemovedTransactions] = useState<DeleteCashFlowTransactionInput[]>([])

  const [updateClientPortfolioPerformance] = useUpdateClientPortfolioPerformanceBareMutation()
  const [deleteClientPortfolioTransaction] = useDeleteClientPortfolioCashflowTransactionMutation()

  let portfolioId:number = 0
  let activeTab = ''

  const urlParts = location.pathname.match(/^\/products\/(\d+)\/performance\/portfolios\/(\d+)\/(cashflow|summary_cashflow)/)
  if (urlParts){
    portfolioId = parseInt(decodeURIComponent(urlParts[2]))
    activeTab = decodeURIComponent(urlParts[3])
  }

  const [getHistoricCashflow, { loading:historicCashflowLoading, error:historicCashflowError, data:historicCashflowData }] = useClientPortfolioPerformanceCashflowHistoricLazyQuery({
    fetchPolicy: "no-cache",
    variables: { id: portfolioId }
  })

  if (historicCashflowError && downloadingHistory) {
    setDownloadingHistory(false)
  }

  if (historicCashflowData && downloadingHistory) {
    setDownloadingHistory(false)
    const downloadUrl = historicCashflowData.clientPortfolio?.cashFlowsHistory?.signedURL

    if (!isNil(downloadUrl)) {
      window.location.href = downloadUrl
    }
  }


  const selectPortfolio = (id:number) => {
    history.push(`/products/${productId}/performance/portfolios/${id}/cashflow`)
  }

  const handleSubmit = () => {
    setSaving(true)
    let submissionData:UpdateClientPortfolioInput = {
      id: portfolioId,
      patch: stateDiff
    }

    updateClientPortfolioPerformance({ variables: { input: submissionData, date: searchDate }})
      .then(result => {
        if (result && result.data) {
          setEditCancelled(false)
          setEditMode(false)
          setSaving(false)
          setClearChanges(true)
          setStateDiff({})
        }
      })
      .catch(err => {
        console.log("Error updating client portfolio", err.message)
        setSaving(false)
      })

    if (removedTransactions.length > 0) {
      deleteClientPortfolioTransaction({ variables: { input: { id: portfolioId, transactions: removedTransactions }}})
        .then(result => {
          if (result && result.data) {
            setEditCancelled(false)
            setEditMode(false)
            setSaving(false)
            setClearChanges(true)
          }
        })
        .catch(err => {
          console.log("Error removing portfolio transaction", err.message)
          setSaving(false)
        })
    }
  }

  const loadMore = () => {
    let newDate = moment(historicEndDate).subtract(5,"years")
    setHistoricEndDate(newDate)
    console.log('loading more')
  }

  const updateStateDiff = (newDiff:VehicleUpdateFields) => {
    setStateDiff(newDiff)
  }

  const downloadHistoricData = () => {
    if (activeTab == "cashflow") {
      setDownloadingHistory(true)
      getHistoricCashflow()
    }
  }

  const cancelEdit = () => {
    setEditCancelled(true)
  }

  const setEditingMode = (val:boolean) => {
    setEditMode(val)
    if (val === true) {
      setEditCancelled(false)
      setClearChanges(false)
    }
  }

  const removeTransaction = (removedTransactionInput:DeleteCashFlowTransactionInput) => {
    let removed = cloneDeep(removedTransactions)
    removed?.push(removedTransactionInput)
    setRemovedTransactions(removed)
  }

  const canEdit = (vehicleType:string) => {
    const editManager = auth.checkPermissions(['edit:client_portfolios'])
    const editReturnsCalc = auth.checkPermissions(['edit:returns_calculated'])
    const editReturns = auth.checkPermissions(['edit:returns'])

    if (editManager) {

      if (activeTab == "returns") {
        if (['OpenEndedMutualFund','OpenEndedExchangeTradedFund'].includes(vehicleType)) {
          return editReturnsCalc
        }

        if (['OpenEndedSeparateAccountRealAssets','OpenEndedPooledVehicleRealAssets','OpenEndedPooledVehiclePrivateCredit','OpenEndedPooledVehiclePrivateCredit'].includes(vehicleType)) {
          return editReturns
        }

        if (['OpenEndedVariableAnnuity','OpenEndedSeparateAccount','OpenEndedCollectiveInvestmentFundComposite','OpenEndedCollectiveInvestmentFund','OpenEndedPooledVehicle','OpenEndedPrivateNonRegisteredHedgeFund','OpenEndedCollectiveInvestmentFundStableValue','OpenEndedCollectiveInvestmentFundStableValueComposite','OpenEndedSeparateAccountStableValue'].includes(vehicleType)) {
          if (period == 'quarter') {
            return editReturnsCalc
          } else {
            return editReturns
          }
        }

        return false
      }
      return true
    }

    return false
  }
  const heading = (productType:string, vehicleType: string) => {
    // const showMonthly:boolean = !["OpenEndedRealAssets", "OpenEndedPrivateCredit", "OpenEndedPrivateEquity"].includes(productType) || productType.match(/^Closed/) == null

    const showMonthly:boolean = !["OpenEndedSeparateAccountRealAssets","OpenEndedPooledVehicleRealAssets","OpenEndedPooledVehiclePrivateEquity","OpenEndedPooledVehiclePrivateCredit"].includes(vehicleType)


    return (
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="mr-2 pr-3 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            {search == "" && (
              <span className="o-88 absolute center-v right-1 pe-none">
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="2x"
                  className="fontawesome-icon dark-icon-color text-gray-50"
                />
              </span>
            )}
          </FormGroup>
        </Form>

        { activeTab != "returns" && (
          <CalendarPicker
            updateValue={(searchDate) => setSearchDate(searchDate)}
            editMode={editMode}
            setEditMode={(value:boolean) => setEditMode(value)}
            updatePeriod={setPeriod}
            hasHistorical={false}
            hasMonthly={false}
          />)
        }

        {showCalendar && screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={loadMore} >
            {loadingMore && "Loading"}
            {!loadingMore && "Load 5 More Years"}
          </Button>
        }
        { activeTab == 'cashflow' && !downloadingHistory &&
          <Button color="secondary btn-thin" className="text-callan-blue ml-1" onClick={downloadHistoricData}>Export XLSX
            <img src='/assets/XLS.svg' className="ml-2"/>
          </Button>
        }
        { activeTab == 'cashflow' && downloadingHistory &&
          <Button onClick={()=>{}} disabled className="ml-1">Downloading Historical <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin /></Button>
        }

        { canEdit(vehicleType) &&
          <EditButtons
            editMode={editMode}
            setEditMode={setEditingMode}
            saving={saving}
            onSubmit={handleSubmit}
            cancelEdit={cancelEdit}
          />
        }
      </div>
    )
  }

  if (!!data && data.product?.product?.vehicles && isArray(data.product?.product?.vehicles) ) {
    const productType = data.product.__typename
    let newPortfolioId = portfolioId
    let portfolioName = ''
    let vehicleType = ''
    let showCashFlow = true
    let showCashFlowSummary = true

    let vehicles = cloneDeep(data.product.product.vehicles) as Vehicle[]
    let portfolios = compact(flatMap(vehicles, (v:Vehicle) => v.vehicle?.relatedClientPortfolioManagerDataCollect))
    let filteredPortfolios = portfolios.filter((portfolio:ClientPortfolio) => portfolio.clientSpecificDataCollectionFields?.questionnaireCashFlowRequired || portfolio.clientSpecificDataCollectionFields?.questionnaireSummaryCashFlowRequired)

    filteredPortfolios = filteredPortfolios.sort((portfolioA, portfolioB) => {
      const nameA = (portfolioA.name || "").toLocaleLowerCase().trim()
      const nameB = (portfolioB.name || "").toLocaleLowerCase().trim()
      return nameA.localeCompare(nameB)
    }) as ClientPortfolio[]

    if (filteredPortfolios.length > 0) {
      const selectedPortfolio = find(filteredPortfolios, v => {
        const selectedPortfolioId = get(v, "id")
        return selectedPortfolioId === portfolioId
      })

      if (portfolioId === 0 && filteredPortfolios[0]) {
        newPortfolioId = get(filteredPortfolios[0], 'id')
        // setSelectedVehicleId(portfolioId)
        history.push(`/products/${productId}/performance/portfolios/${newPortfolioId}/cashflow`)
        return (<></>)
      }

      if (selectedPortfolio) {
        portfolioName = selectedPortfolio.name || ''
        vehicleType = selectedPortfolio.relatedVehicle?.vehicle?.__typename || ''

        showCashFlow = selectedPortfolio.clientSpecificDataCollectionFields?.questionnaireCashFlowRequired || false
        showCashFlowSummary = selectedPortfolio.clientSpecificDataCollectionFields?.questionnaireSummaryCashFlowRequired || false
      }

      if (editMode && !canEdit(vehicleType)) {
        setEditMode(false)
      }
    }

    if (search !== "") {
      vehicles = vehicles.filter(v => v.vehicle?.name?.toLowerCase().includes(search.toLowerCase()))
    }

    let endDate = moment(searchDate)

    let queryPeriod = period == "month" ? PerformancePeriodType.Monthly : PerformancePeriodType.Quarterly

    return (
      <>
        <Row>
          <Col>
            {heading(productType, vehicleType)}
          </Col>
        </Row>
        <Row>
          <ClientPortfoliosSidebar
            portfolios={filteredPortfolios as ClientPortfolio[]}
            selectPortfolio={(id:number) => selectPortfolio(id)}
            selectedPortfolioId={portfolioId}
          />
          <RouteLeavingGuard
            when={editMode}
            navigate={path => history.push(path)}
          />
          <ClientPortfolioDisplay
            key={`vehicle-display-${period}-${queryPeriod}`}
            id={portfolioId}
            name={portfolioName}
            editMode={editMode}
            setEditMode={setEditMode}
            period={queryPeriod}
            queryDate={endDate.format(DATE_API_FORMAT)}
            historicEndDate={historicEndDate.format(DATE_API_FORMAT)}
            handleChange={updateStateDiff}
            productType={productType}
            editCancelled={editCancelled}
            clearChanges={clearChanges}
            removeTransaction={removeTransaction}
            showCashFlow={showCashFlow}
            showCashFlowSummary={showCashFlowSummary}
            { ...props}
          />
        </Row>
      </>
    )
  }

  return <div>data doesn't exist.</div>

}


interface ClientPortfoliosSidebarProps {
  portfolios: ClientPortfolio[]
  selectedPortfolioId: number
  selectPortfolio: (id:number) => void
}

const ClientPortfoliosSidebar:React.FC<ClientPortfoliosSidebarProps> = (props:ClientPortfoliosSidebarProps) => {
  const [sortBy, setSortBy] = useState(1)
  const [activeFilters, setActiveFilters] = useState<number[]>([])
  let { portfolios } = props

  let pickerFilters: OptionItem[] = []
  pickerFilters = []
  // Add the possible options to the
  let categories = uniq(props.portfolios.map((portfolio:ClientPortfolio) => get(portfolio, "relatedVehicle.vehicle.category.value", ""))).sort()
  categories.map((category:string) => { pickerFilters.push({id: pickerFilters.length+1, name: category})})

  const sortOptions:OptionItem[] = [
    {
      id: 1,
      name: "Name ( A to Z )",
    },
    {
      id: 2,
      name: "Name ( Z to A )",
    },
  ]


  const onFilter = (filterId: number) => {
    let newActiveFilters = cloneDeep(activeFilters)
    const index = newActiveFilters.indexOf(filterId)
    if(filterId === 1){
      if (index === -1) {
        newActiveFilters.push(filterId)
      } else {
        newActiveFilters.splice(index, 1)
      }
    } else {
      if (index === -1) {
        remove(newActiveFilters, (id) => id != 1)
        newActiveFilters.push(filterId)
      } else {
        newActiveFilters.splice(index, 1)
      }
    }
    setActiveFilters(newActiveFilters)
  }

  const onSort = (sortId: number) => {
    setSortBy(sortId)
  }


  const objectListItem = (portfolio: ClientPortfolio, idx: number) => {
    const category = get(portfolio, "relatedVehicle.vehicle.type.value") || get(portfolio, "relatedVehicle.vehicle.category.value")
    return (
      <ListGroupItem
        tag="a"
        key={idx}
        className={classNames({
          active: portfolio.id === props.selectedPortfolioId,
          "picker-list-item-characteristics": true,
        })}
        onClick={() => { props.selectPortfolio(portfolio.id) }}
      >
        <h5>
          {portfolio.clientSpecificDataCollectionFields?.nameOverride || portfolio.name}
        </h5>
        <dl>
          <dt>Vehicle Category:</dt>
          <dd>{category}</dd>
          <dt>Vehicle Name:</dt>
          <dd>{portfolio.relatedVehicle?.vehicle?.name}</dd>
        </dl>
      </ListGroupItem>
    )
  }

  const emptyMessage = () => {
    return `There are no portfolios associated with this product.`
  }

  portfolios = portfolios
  .filter((portfolio: ClientPortfolio) => {
    if (activeFilters.length > 0) {
      // Inverse as filters remove options
      return !some(activeFilters, (id:number) => {
        const matchingFilter = find(pickerFilters, {id: id})
        return matchingFilter?.name !== portfolio.relatedVehicle?.vehicle?.category?.value
      })

    }
    return true
  })
  .sort((portfolioA: ClientPortfolio, portfolioB: ClientPortfolio) => {
    const nameA = (portfolioA.name || "").toLocaleLowerCase().trim()
    const nameB = (portfolioB.name || "").toLocaleLowerCase().trim()
    switch (sortBy) {
      case 1:
        return nameA.localeCompare(nameB)
      case 2:
        return nameB.localeCompare(nameA)
      default:
        throw new Error(`unrecognized sort choice ${sortBy}`)
    }
  })



  return (
    <Picker
      filters={pickerFilters}
      activeFilters={activeFilters}
      sortOptions={sortOptions}
      activeSort={sortBy}
      onFilter={onFilter}
      onSort={onSort}
      searching={false}
      listClass="interaction-list"
      toolbarClass="interaction-toolbar"
    >
      {portfolios.length > 0 && portfolios.map((o, idx) => objectListItem(o, idx))}
      {portfolios.length == 0 &&
        <div className="p-3 text-gray-70">
          {emptyMessage()}
        </div>
      }
    </Picker>
  )
}


interface ClientPortfolioDisplayProps extends ProductClientPortfolioPerformanceReturnRouteProps {
  id: number
  name: string
  editMode: boolean
  // onChange: (v:Vehicle) => void
  // onProductNoteChange: (notes:ProductInteractionNotesFragment[]) => void
  setEditMode: (mode:boolean) => void
  period: PerformancePeriodType
  queryDate: string
  handleChange: (newDiff:ClientPortfolioUpdateFields) => void
  removeTransaction: (removedTransactionInput:DeleteCashFlowTransactionInput) => void
  productType: string
  editCancelled: boolean
  clearChanges: boolean
  historicEndDate: string
  auth: Auth
  showCashFlowSummary: boolean
  showCashFlow: boolean
}

interface ClientPortfolioDisplayState {
  currentState: IdVehicle
  initialState: IdVehicle
}

const ClientPortfolioDisplay = ((props:ClientPortfolioDisplayProps) => {
  const match = useRouteMatch() || props.match
  const {history, editMode, queryDate, period, id, name, handleChange, productType, editCancelled, removeTransaction, historicEndDate, auth, clearChanges } =  props
  const params = useParams() as {
    subtype?: string
    type?: string
    productId?: string
    tertiaryType?: string
    portfolioId?: number
  }
  const subtype = get(props, "match.params.subtype")
  const tertiaryType = get(props, "match.params.tertiaryType")

  let urlWithoutSubtype =
      params && tertiaryType
        ? match.url.slice(0, -tertiaryType.length - 1)
        : match.url

  return (
    <Col md="8" lg="9" className="pl-1">
      <div className="pane">
        <Row>
          <Col>
            <h2 className="headline underline">{ name }</h2>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Nav tabs>
              { props.showCashFlow && (
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: tertiaryType === "cashflow" || isUndefined(tertiaryType)
                    })}
                    onClick={() => history.push(`${urlWithoutSubtype}/cashflow`)}
                  >Cash Flow</NavLink>
                </NavItem>
              )}
              { props.showCashFlowSummary && (
                <NavItem>
                  <NavLink
                    className={classNames({
                      active: tertiaryType === "summary_cashflow"
                    })}
                    onClick={() => history.push(`${urlWithoutSubtype}/summary_cashflow`)}
                  >Summary Cash Flow</NavLink>
                </NavItem>
              )}
            </Nav>
            <Switch>
              { props.showCashFlow && (
                <Route
                  path="/products/:productId(\d+)/:type(performance)/:subtype(portfolios)/:portfolioId/cashflow"
                  render={(props) => {
                    return (
                      <Query<ClientPortfolioPerformanceCashflowQuery> query={CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS} variables={{ id, date: queryDate, period }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                      {
                        ({loading, error, data}) => {

                          if (loading) {
                            return <PlaceHolder />
                          }

                          if (error) {
                            return (
                              <ErrorDisplay error={error}/>
                            );
                          }

                          if (data && data.portfolio_cashflows) {
                            return (
                              <ProductPerformanceClientsCashflow
                                id={id}
                                editMode={editMode}
                                date={queryDate}
                                handleChange={(newDiff) => handleChange({ cashFlows: newDiff })}
                                removeTransaction={removeTransaction}
                                data={data}
                                editCancelled={editCancelled}
                                clearChanges={clearChanges}
                              />
                            )
                          }

                          return <></>
                        }
                      }
                      </Query>
                    )
                  }}

                />
              )}
              { props.showCashFlowSummary && (
                <Route
                  path="/products/:productId(\d+)/:type(performance)/:subtype(portfolios)/:portfolioId/summary_cashflow/:summary_tab(total|vintage)?"
                  render={(props) => {
                    return (
                      <Query<ClientPortfolioPerformanceCashflowSummaryQuery> query={CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_SUMMARY} variables={{ id, date: queryDate }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
                      {
                        ({loading, error, data, refetch}) => {

                          if (loading) {
                            return <PlaceHolder />
                          }

                          if (error) {
                            return (
                              <div className='pane pane-table'>
                                <p>{error.message}</p>
                              </div>
                            );
                          }

                          if (data && data.portfolio_cashflow_summary) {
                            return (
                              <ProductPerformanceClientsCashflowSummary
                                id={id}
                                editMode={editMode}
                                date={queryDate}
                                period={period}
                                handleChange={(newDiff) => handleChange({ cashFlowsSummary: newDiff })}
                                editCancelled={editCancelled}
                                data={data}
                                clearChanges={clearChanges}
                                history={history}
                                location={props.location}
                                match={props.match}
                                refetch={refetch}
                              />
                            )
                          }

                          return <></>
                        }
                      }
                      </Query>
                    )
                  }}
                />
              )}
              { props.showCashFlow && (
                <Redirect
                  from="/products/:productId(\d+)/:type(performance)/:subtype(portfolios)/:portfolioId"
                  to="/products/:productId(\d+)/:type(performance)/portfolios/:portfolioId/cashflow"
                />
              )}
              { !props.showCashFlow && props.showCashFlowSummary && (
                <Redirect
                  from="/products/:productId(\d+)/:type(performance)/:subtype(portfolios)/:portfolioId"
                  to="/products/:productId(\d+)/:type(performance)/portfolios/:portfolioId/summary_cashflow/total"
                />
              )}

            </Switch>
          </Col>
        </Row>
      </div>
    </Col>
  )

})


// class ClientPortfolioDisplay extends Component<ClientPortfolioDisplayProps,ClientPortfolioDisplayState> {
//   constructor(props: ClientPortfolioDisplayProps) {
//     super(props)
//     const { vehicle } = props

//     this.state = {
//       currentState: vehicle,
//       initialState: vehicle,
//     }
//   }

//   render() {
//     const { vehicle } = this.state.currentState


//     console.log({subtype, url: match.url, urlWithoutSubtype})

//     return (
//       <Col md="8" lg="9" className="pl-1">
//         <div className="pane">
//           <Row>
//             <Col>
//               <h2 className="headline underline">{ vehicle?.name }</h2>
//             </Col>
//           </Row>
//           <Row>
//             <Col md="12">
//               <Nav tabs>
//                 <NavItem>
//                   <NavLink href="#">Returns</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink href="#">Cash Flow</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink href="#">Summary Cash Flow</NavLink>
//                 </NavItem>
//               </Nav>
//               <Switch>
//                 <Route
//                   path="/products/:productId(\d+)/performance/:subtype(vehicles)"
//                   render={(props) => <ProductPerformanceClients productId={id} {...props} />}
//                 />
//                 <Route
//                   path="/products/:productId(\d+)/performance/:subtype(portfolios)"
//                   render={(props) => <ProductPerformanceClients productId={id} {...props} />}
//                 />
//                 <Redirect
//                   from="/products/:productId(\d+)/performance"
//                   to="/products/:productId(\d+)/performance/vehicles"
//                 />
//               </Switch>
//             </Col>
//           </Row>
//         </div>
//       </Col>
//     )
//   }
// }

export default ProductPerformanceClients
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"

import {
  useVehicleOtherFeesQuery,
  VehicleOtherFeesQuery,
} from "../../../../__generated__/graphql"
import PlaceHolder from "../../../ui/PlaceHolder"
import { VehicleType } from "../VehicleFees"
import { FeeRows } from "./FeeRows"
import { getFeeData } from "./getFeeData"

const HIDE_AS_OF_DATE_COLUMN_FOR_THESE_TYPES: VehicleType[] = [
  "OpenEndedMutualFund",
  "OpenEndedExchangeTradedFund",
  "OpenEndedPooledVehicle",
  "OpenEndedVariableAnnuity",
  "OpenEndedCollectiveInvestmentFundComposite",
  "OpenEndedCollectiveInvestmentFundStableValueComposite",
  "OpenEndedCollectiveInvestmentFundStableValue",
  "OpenEndedCollectiveInvestmentFund",
]

export type OtherFeeVehicleType =
  | NonNullable<VehicleOtherFeesQuery["vehicle"]>["__typename"]
  | undefined

interface OtherFeesProps {
  handleInputChange: (value: any, property: string) => void
  editing: boolean
  fundid: string
}

export const OtherFees: React.FC<OtherFeesProps> = ({
  handleInputChange,
  editing,
  fundid,
}) => {
  const [showHistory, toggleHistoryModal] = useState(false)
  const [feeData, updateData] = useState(getFeeData())
  const toggle = () => toggleHistoryModal(!showHistory)

  const { error, loading, data } = useVehicleOtherFeesQuery({
    variables: {
      fundid,
    },
    fetchPolicy: "no-cache",
  })

  const updateFeeData = (path: string, value: any) => {
    const data = _.set(feeData, path, value)
    handleInputChange(value, `vehicle.${path}`)
    updateData({ ...data })
  }

  useEffect(() => {
    if (data) {
      updateData(getFeeData(data))
    }
  }, [loading, data])
  const vehicleType = data?.vehicle?.__typename
  return (
    <Card>
      {error ? (
        <div>{error.message}</div>
      ) : loading ? (
        <PlaceHolder />
      ) : data ? (
        <>
          <CardHeader>
            <Row style={{ display: "table" }}>
              <Col className={"pl-1"} style={{ display: "table-cell" }}>
                Other Fees{" "}
                {
                  //TODO: implement other fees history modal
                  /* {!editing && (
                  <FontAwesomeIcon
                    onClick={() => toggle()}
                    icon="history"
                    className="ml-2"
                  />
                )} */
                }
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="management-fee-card">
            <Table className="fee-table exportable" data-export-name={"Other Fees"} size="sm">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th></th>
                  <th>FEE</th>
                  {HIDE_AS_OF_DATE_COLUMN_FOR_THESE_TYPES.indexOf(
                    vehicleType as VehicleType
                  ) === -1 ? (
                    <th>AS OF DATE</th>
                  ) : (
                    <th></th>
                  )}
                </tr>
              </thead>
              <tbody>
                <FeeRows
                  editing={editing}
                  vehicleType={vehicleType}
                  feeData={feeData}
                  updateFeeData={updateFeeData}
                />
              </tbody>
            </Table>
          </CardBody>
        </>
      ) : (
        <div>Data not found</div>
      )}
    </Card>
  )
}

import classnames from 'classnames'
import React from 'react'
import _ from 'lodash'
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Nav, NavItem, NavLink, Col, Row, Container } from 'reactstrap'

import { ProductBasicQuery, useProductVehiclesQuery, ClientPortfolio } from '../../../__generated__/graphql'
import { appProps } from '../../../queries/extended/appProps'
import { ProductRouteProps } from '../../../queries/extended/types/Product'

import ProductPerformanceVehicles from './Vehicles'
import ProductPerformanceClients from './Clients'
import Auth from '../../../Auth/Auth'
import PlaceHolder from "../../ui/PlaceHolder"

interface ProductCharacteristicsProps {
  data: ProductBasicQuery,
  id: number
  auth: Auth
}

const ProductPerformance: React.FC<appProps & ProductRouteProps & ProductCharacteristicsProps> = props => {
  const { id, data, auth } = props
  const productType = data.product?.__typename

  const params = useParams() as {
    subtype?: string
    type?: string
    productId?: string
    tertiaryType?: string
    vehicleId?: string
  }
  const history = useHistory()
  const match = useRouteMatch() || props.match
  const subtype = _.get(props, "match.params.subtype")
  let urlWithoutSubtype =
      params && subtype
        ? match.url.slice(0, -subtype.length - 1)
        : match.url

  const { loading, error, data: productVehicles } = useProductVehiclesQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: id }
  })

  if (!productType) return (<>No Product Type, shouldn't happen</>)

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (productVehicles && productVehicles.product && productVehicles.product.product?.vehicles) {
    const hasClient = productVehicles.product.product.vehicles?.some(
      (vehicle: any) => {
        return vehicle?.vehicle?.relatedClientPortfolioManagerDataCollect?.some(
          (relatedClient: ClientPortfolio) => {
            return relatedClient.clientSpecificDataCollectionFields?.questionnaireCashFlowRequired || relatedClient.clientSpecificDataCollectionFields?.questionnaireSummaryCashFlowRequired
          }
      )}
    )
    const canEditClientPortfolio = auth.checkPermissions(["edit:client_portfolios"])
    const canSeeClientPortfolio = auth.checkPermissions(["view:client_portfolios"])
    const clientCanSeeClientPortfolio = auth.checkPermissions(["view:my_client_portfolio"])
    const clientPortfolioPermissionCheck = (canEditClientPortfolio || canSeeClientPortfolio || clientCanSeeClientPortfolio) && hasClient
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <Nav className="sub-nav sub-nav-secondary" tabs>
                <NavItem>
                <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutSubtype}/vehicles`) === 0
                    })}
                    onClick={() => history.push(`${urlWithoutSubtype}/vehicles`)}
                  >
                    Vehicles
                  </NavLink>
                </NavItem>
                {clientPortfolioPermissionCheck &&
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutSubtype}/portfolios`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutSubtype}/portfolios`)}
                    >
                      Client Portfolios
                    </NavLink>
                  </NavItem>
                }
              </Nav>
              <Switch>
                <Route
                  path="/products/:productId(\d+)/:type(performance)/:subtype(vehicles)/:vehicleId?/:tertiaryType(return|cashflow|summary_cashflow)?"
                  render={(props: any) => <ProductPerformanceVehicles productId={id} auth={auth} productVehicles={productVehicles} {...props} />}
                />
                {clientPortfolioPermissionCheck &&
                  (<Route
                    path="/products/:productId(\d+)/:type(performance)/:subtype(portfolios)/:vehicleId?/:tertiaryType(cashflow|summary_cashflow)?"
                    render={(props: any) => <ProductPerformanceClients productId={id} auth={auth} productVehicles={productVehicles} {...props} />}
                  />)
                }
                <Redirect
                  from="/products/:productId(\d+)/:type(performance)"
                  to="/products/:productId(\d+)/:type(performance)/vehicles"
                />
              </Switch>
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            No Data Found
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductPerformance

import React from "react"

import {
  Row,
  Col,
  ListGroupItem
} from "reactstrap"
import { SearchProductResultFragment } from "../../../__generated__/graphql"

export const ProductResult = (props:SearchProductResultFragment) => {
  const { id, name } = props

  let status = ''
  if (typeof props.inactive === 'boolean') {
    status = props.inactive === true ? 'Inactive' : 'Active'
  }

  const { manager, assetClass } = props
  // const location = [manager?.address?.city, manager?.address?.state, manager?.address?.country].filter(l => l).join(', ')
  const assetClassName = assetClass?.parent?.shortName
  const strategy = assetClass?.shortName

  return (
    <ListGroupItem tag="a" href={`/products/${id}/profile`}>
      <div className="category product">
        <div className="category-text">
          <h6>Product</h6>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md="6">
            <h3>{name}</h3>
            <h5 className="d-inline mr-1">Manager:</h5>
            <p className="d-inline">{manager?.name}</p>
          </Col>
          <Col md="6">
            <Row>
              <Col md="3">
                <h5>Product ID</h5>
                <p>{id}</p>
              </Col>
              <Col md="3">
                <h5>Asset Class</h5>
                <p>{assetClassName}</p>
              </Col>
              <Col md="3">
                <h5>Strategy Type</h5>
                <p>{strategy}</p>
              </Col>
              <Col md="3">
                <h5>Status</h5>
                <p>{status}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroupItem>
  )
}
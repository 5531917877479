import { WithTopNav } from "../Components/ui/LayoutWrapper"
import graph from "./TestGraph"

const TestGraph = () => {
  graph()
  return (
    <div>
      <div
        id="floatingBar"
        style={{"width": 1000, "height": 600, "marginLeft": 85}}
      >
      </div>
      <table cellSpacing="0" cellPadding="0" style={{"border": "none"}}>
        <tbody id="floatingBarLegend"></tbody>
      </table>
      <hr />
      <div
        id="scatter"
        style={{"width": 1000, "height": 600, "marginLeft": 85}}
      />
    </div>
  )
}

export default WithTopNav(TestGraph)
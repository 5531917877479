import { disableFragmentWarnings, gql } from '@apollo/client'

import { FILE_BASIC_INFO_FRAGMENT, FILE_FULL_DETAILS_FRAGMENT, FILE_PAYLOAD_FRAGMENT } from './Fragments'

disableFragmentWarnings()

export const ALL_FILES_QUERY = gql`
  query AllFiles($offset: Int, $limit: Int) {
    files(offset: $offset, limit: $limit) {
      ...FileBasicInfoFragment
    }
  }
  ${FILE_BASIC_INFO_FRAGMENT}
`

export const FILE_QUERY = gql`
  query FileDetails($id: ID!, $version: ID, $type: FileTypeMapType) {
    file(id: $id, version: $version) {
      ...FileFullDetailsFragment
    }
    fileTypeMap(type: $type) {
      type
      typeOrder
      subType
      subTypeOrder
    }
  }
  ${FILE_FULL_DETAILS_FRAGMENT}
`

export const SIGNED_FILE_URL_QUERY = gql`
  query GetFileUploadSignedUrl($input: NewSignedUrlInput!) {
    getFileUploadSignedUrl(input: $input) {
      signedUrl{
        id
        url
      }
      error{
        name
        message
      }
    }
  }
`

export const NEW_FILE_UPLOAD_MUTATION = gql`
  mutation UploadNewFile($input: NewFileDataInput!) {
    createFileMetadata(input: $input) {
      ...FilePayloadFragment
    }
  }
  ${FILE_PAYLOAD_FRAGMENT}
`

export const UPDATE_FILE_METADATA_MUTATION = gql`
  mutation UpdateFileMetadata($input: UpdateFileMetadataInput!) {
    updateFileMetadata(input: $input) {
      ...FilePayloadFragment
    }
  }
  ${FILE_PAYLOAD_FRAGMENT}
`

export const REMOVE_FILE_MUTATION = gql`
  mutation RemoveFile($input: DeleteFileInput!) {
    removeFile(input: $input) {
      status
      message
    }
  }
`

export const UPLOAD_NEW_VERSION_MUTATION = gql`
  mutation UploadNewVersion($input: NewFileVersion!) {
    newVersion(input: $input) {
      ...FilePayloadFragment
    }
  }
  ${FILE_PAYLOAD_FRAGMENT}
`

export const DOWNLOAD_VERSION_MUTATION = gql`
  query DownloadVersion($id: ID!, $version: ID) {
    file(id: $id, version: $version) {
      mimeType
      url
      name
    }
  }
`

export const REMOVE_VERSION_MUTATION = gql`
  mutation RemoveVersion($input: DeleteFileVersionInput!) {
    removeVersion(input: $input) {
      ...FilePayloadFragment
    }
  }
  ${FILE_PAYLOAD_FRAGMENT}
`

export const FILE_TYPE_MAPPING = gql`
  query FileTypeMapping($type: FileTypeMapType) {
    fileTypeMap(type: $type) {
      type
      typeOrder
      subType
      subTypeOrder
    }
  }
`

export const GET_ZIP_ARCHIVE = gql`
  query GetZipArchive($input: ZipArchiveInput!) {
    getZipArchiveUrl(input: $input) {
      url
    }
  }
`
import { disableFragmentWarnings, gql } from "@apollo/client"

disableFragmentWarnings()

export const FOOTNOTE_HISTORY_FRAGMENT = gql`
  fragment FootnoteHistoryFragment on FootnoteHistoryItem {
    date
    text
  }
`

export const FOOTNOTE_DETAIL_FRAGMENT = gql`
  fragment FootnoteDetailFragment on Footnote {
    id
    date
    text
    plans {
      id
    }
    clientPortfolioTargets {
      clientPortfolio {
        id
      }
      target {
        targetId: id
      }
    }
    clientPortfolios {
      id
    }
    history {
      ...FootnoteHistoryFragment
    }
  }
  ${FOOTNOTE_HISTORY_FRAGMENT}
`

export const FOOTNOTE_SHOW_FRAGMENT = gql`
  fragment FootnoteShowFragment on Footnote {
    id
    date
    text
    history {
      ...FootnoteHistoryFragment
    }
  }
  ${FOOTNOTE_HISTORY_FRAGMENT}
`

export const FOOTNOTE_SHOW_TARGET_FRAGMENT = gql`
  fragment FootnoteShowTargetFragment on Footnote {
    id
    date
    text
    clientPortfolioTargets {
      clientPortfolio {
        id
      }
      target {
        targetId: id
        name
      }
    }
    history {
      ...FootnoteHistoryFragment
    }
  }
  ${FOOTNOTE_HISTORY_FRAGMENT}
`

export const REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportCommonClientPortfolioFragment on ClientPortfolio {
    id
    name
    isComposite
    relatedVehicle{
      vehicle {
        id: fundid
        product {
          product {
            id
          }
        }
      }
    }
  }
`

export const REPORT_COMMON_VEHICLE_FRAGMENT = gql`
  fragment ReportCommonVehicleFragment on VehicleFields {
    id: fundid
    name
    product {
      product {
        id
      }
    }
  }
`

import React from "react"
import { History } from 'history'
import { match, RouteComponentProps, Switch, useHistory } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, BreadcrumbProps, Col, Container, DropdownToggle, ListGroup, ListGroupItem, Row } from "reactstrap"
import { WithTopNav } from "../ui/LayoutWrapper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { appProps } from "../../queries/extended/appProps"
import ManageUsers from "./Admin/ManageUsers"
import { CrumbRoute } from "../../routes/crumb-route"
import ControlCenterPicker from "../ui/Pickers/ControlCenterPicker"
import ReportDueDates from "./Admin/ReportDueDates"
import Auth from "../../Auth/Auth"
import ImportedFiles from "./Admin/DataImporter/ImportedFiles"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

type DashboardType = "control-panel" | "dna-users" | "report-due-dates"| "imported-files"

type idProps = {
  history: History
  index: number
  auth: Auth
}

type ComponentConfigType = {
  type: DashboardType,
  title: string,
  description: string,
  urlText: string,
  url: string,
  permission: string,
}

interface ControlCenterRouteProps
  extends RouteComponentProps<{
    type: DashboardType
  }> {}

export const ControlCenterComponentConfig: ComponentConfigType[] = [
  {
    type: "dna-users",
    title: "DNA Users",
    description: "Manage user access and view how often external users sign into DNA.",
    urlText: "Manage DNA Users",
    url: "/control-center/dna-users",
    // TODO: which permission should this be?
    permission: "view:users",
    // permission: "add:users",

  },
  {
    type: "report-due-dates",
    title: "Report Due Dates",
    description: "Update report due dates each quarter.",
    urlText: "Report Due Dates",
    url: "/control-center/report-due-dates",
    permission: "edit:report_due_dates",
  },
  {
    type: "imported-files",
    title: "Data Importer",
    description: "Import and publish data to be used for reports. Get templates to prepare data for importing.",
    urlText: "View and import data",
    url: "/control-center/imported-files",
    permission: "use:data_import",
  }
]

const ColumnComponent: React.FC<idProps> = (props) => {
  const {history, index, auth} = props
  let {description, urlText, url, permission} = ControlCenterComponentConfig[index]
  if(auth.checkPermissions([permission]) === false) return <React.Fragment key={index}></React.Fragment>
  return (
    <div className={"description d-flex flex-column justify-content-between"}>
      <div className={"description-text"}>{description}</div>
      <ListGroup className="recent">
        <ListGroupItem
          tag="a"
          key={0}
          className={"pl-0 border-bottom-0"}
          onClick={() => history.push(url)}
          >
          <h3>
            {urlText}
            <FontAwesomeIcon
              icon={["fas", "angle-right"]}
              size="lg"
              className="fontawesome-icon text-blue-70 ml-1"
            />
          </h3>
        </ListGroupItem>
      </ListGroup>
    </div>
  )
}

const ControlPanelComponent: React.FC<{history: History, match: match<{
  type: DashboardType
}>, auth: Auth}> = (props) => {
  const {match, history, auth} = props
  return (
    <DndProvider backend={HTML5Backend}>
      <Container fluid>
        <Col xs="10" sm="8" md="6">
          <div className="mb-4">
            <h2 className="headline">Control Center Dashboard</h2>
          </div>
          </Col>
        <Row>
          {
            ControlCenterComponentConfig.map((item, index) => {
              let { title, permission } = item
              if(auth.checkPermissions([permission]) === false) return <React.Fragment key={index}></React.Fragment>
              return (
                <Col md={4} key={index}>
                  <div className="pane dashboard-pane control-center d-flex flex-column">
                    <h3 className="headline underline green-underline">
                      {title}
                    </h3>
                    <ColumnComponent history={history} index={index} auth={auth}/>
                  </div>
                </Col>
              )
            })
          }
          <Col md={4} key={2}></Col>
        </Row>
      </Container>
    </DndProvider>
  )
}

const ControlCenterDashboardMain: React.FC<appProps & ControlCenterRouteProps> = (props) => {
  const { match, ...otherProps } = props
  const { auth } = props
  const { type } = match.params
  const history = useHistory()
  const urlWithoutType = type ? match.url.slice(0, -type.length - 1) : match.url
  if(!ControlCenterComponentConfig.some(config => auth.checkPermissions([config.permission]))) {
    history.push(`/`)
  }
  if(type === "control-panel") {
    return (<ControlPanelComponent {...props} match={match} history ={history} />)
  }
  let index = ControlCenterComponentConfig.findIndex(config => config.type === type)
  let {title} = ControlCenterComponentConfig[index]

  let viewReportDueDatesPermission = auth.checkPermissions(["edit:report_due_dates"])
  let showManageUsers = auth.checkPermissions(["view:users"])
  let showImportedFiles = auth.checkPermissions(["use:data_import"])
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <Col xs='10' sm='8' md='6'>
                <Breadcrumb>
                  <BreadcrumbItem className='headline-breadcrumbs' key='test' onClick={() => history.push(`/control-center`)}>
                    {`Control Center`}
                  </BreadcrumbItem>
                </Breadcrumb>
                <ControlCenterPicker
                  title={`View another category`}
                  onClick={(type) => {
                    window.location.href = `/control-center/${type}`
                  }}
                  auth={auth}
                >
                  <div className='dropdown'>
                    <DropdownToggle caret>
                      <h2 className='headline'>{title}</h2>
                    </DropdownToggle>
                  </div>
                </ControlCenterPicker>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <DndProvider backend={HTML5Backend}>
        <Switch>
        {showManageUsers && <CrumbRoute
          exact
          title="DNA Users"
          path={`${urlWithoutType}/dna-users`}
          render={(params: BreadcrumbProps) => <ManageUsers {...otherProps} match={params.match} />}
        />}
        {viewReportDueDatesPermission && <CrumbRoute
          exact
          title="Report Due Dates"
          path={`${urlWithoutType}/report-due-dates`}
          render={(params: BreadcrumbProps) => <ReportDueDates {...otherProps} match={params.match} />}
        />}
        {showImportedFiles && <CrumbRoute
          exact
          title="Data Publishing"
          path={`${urlWithoutType}/imported-files`}
          render={(params: BreadcrumbProps) => <ImportedFiles {...otherProps} match={params.match} />}
        />}
      </Switch>
     </DndProvider>
    </>
  )
}

export default WithTopNav(ControlCenterDashboardMain)

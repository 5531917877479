import { disableFragmentWarnings, gql } from "@apollo/client"

import { INTERACTION_FRAGMENT } from './Fragments'

disableFragmentWarnings()

export const GET_INTERACTION = gql`
  query GetInteraction($id: String! $showInternal: Boolean=false) {
    interaction(id: $id) {
      ...InteractionFragment
    }
  }
  ${INTERACTION_FRAGMENT}
`

export const GET_INTERACTION_URL = gql`
  query DownloadInteractionNote($id: String!) {
    download: downloadInteractionNote(id: $id) {
      url
    }
  }
`

export const ADD_INTERACTION_ASSOCIATION = gql`
  mutation AddInteractionAssociation($input: InteractionAssociationsInput!,  $showInternal:Boolean=false) {
    addInteractionAssociation(input: $input) {
      interaction{
        ...InteractionFragment
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`

export const REMOVE_INTERACTION_ASSOCIATION = gql`
  mutation RemoveInteractionAssociation($input: InteractionAssociationsInput!,  $showInternal:Boolean=false) {
    removeInteractionAssociation(input: $input) {
      interaction{
        ...InteractionFragment
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`

export const UPSERT_INTERACTION_ORG_NOTE = gql`
  mutation UpsertInteractionOrgNote($input: OrgInteractionNoteInput!,  $showInternal:Boolean=false) {
    upsertInteractionOrgNote(input: $input) {
      interaction{
        ...InteractionFragment
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`

export const UPSERT_INTERACTION_PRODUCT_NOTE = gql`
  mutation UpsertInteractionProductNote($input: ProductInteractionNoteInput!,  $showInternal:Boolean=false) {
    upsertInteractionProductNote(input: $input) {
      interaction{
        ...InteractionFragment
      }
    }
  }
  ${INTERACTION_FRAGMENT}
`

export const DELETE_INTERACTION_PRODUCT_NOTE = gql`
  mutation DeleteInteractionProductNote($input: DeleteInput!) {
    deleteInteractionProductNote(input: $input) {
      status
      message
    }
  }
`

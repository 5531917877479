import React, { Component, ChangeEvent, useState } from "react"
import {
  Container,
  Row,
  Col,
  ListGroup,
  Input,
  Button,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  Alert
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RouteComponentProps } from 'react-router-dom'
import { parse, ParsedQuery } from 'query-string'
import { merge, compact, pickBy, isEmpty } from 'lodash'
import classnames from 'classnames'
import { SearchQuery, SearchQueryVariables, SearchTypes, SearchFilters, SearchSortBy, SearchSortOrders, SearchSortables, Maybe } from '../../__generated__/graphql'
import { SEARCH_QUERY } from '../../queries/Search'
import { MeQuery } from '../../__generated__/graphql'
import { ME_QUERY } from "../../queries/Person"

import { Query } from '@apollo/client/react/components'

import { WithTopNav } from "../ui/LayoutWrapper"
import {SearchSidebar, FullSearchState, SearchFilterState } from './SearchSidebar'

import { ManagerResult, ClientResult, DocumentResult, PersonResult, ProductResult, TargetDateResult, VehicleResult, RecordKeeperResult, CustodianResult, IndexResult, GroupResult } from './ResultTypes'
import { ApolloError } from "@apollo/client"
import {Location} from 'history'
import { SearchTypeDisplays, SearchDisplayToTypes } from './SearchEnums'

import PlaceHolder from "./../ui/PlaceHolder"
import Auth from "../../Auth/Auth"
import _, { get } from "lodash"
import numbro from "numbro"
import ErrorDisplay from "../Shared/ErrorDisplay"


interface RendererProps {
  data?: SearchQuery
  error?: ApolloError
  loading: boolean
}

interface PaginationProps {
  setPaginationSize: (size:number) => void,
  pageSize: number,
  gotoPage: (page:number) => void,
  currentPage: number,
  totalResults: number,
}

interface SearchResultState {
  query: string
  types: SearchTypeDisplays[],
  filters: SearchFilters,
  sort?: SearchSortBy
  page: number,
  pageSize: number,
}

interface authProps {
  auth: Auth
}

export class SearchResults extends Component<RouteComponentProps & authProps> {
  state:SearchResultState = {
    query: '',
    types: [],
    filters: {
      manager: { inactive: false },
      product: { inactive: false },
      vehicle: { inactive: false },
      person: { inactive: false }
    },
    page: 1,
    pageSize: 25
  }

  constructor (props: RouteComponentProps & authProps) {
    super(props)
    this.state = { ...this.generateSearchQueryVars(this.props.location) }
  }

  componentWillUpdate = (nextProps:RouteComponentProps) => {
    if (this.props.location.search !== nextProps.location.search) {
      this.setState({ ...this.generateSearchQueryVars(nextProps.location) })
    }
  }

  generateSearchQueryVars = (location:Location) => {
    const parsedParams:ParsedQuery = parse(location.search)

    let { q:query = '', filters:filterStr = "" } = {...parsedParams}

    let types:string[] | string = parsedParams.types || ""
    let sortBy:SearchSortables | undefined = parsedParams.sb as SearchSortables
    let sortDirection:SearchSortOrders | undefined = parsedParams.sd as SearchSortOrders

    let page:number = parseInt(parsedParams.page as string) || 1
    let pageSize:number = parseInt(parsedParams.pageSize as string) || 25

    // { q:query = '', types = [], filters:filterStr = "", sb:sortBy = undefined, sd:sortDirection = undefined } = {...parsedParams}

    types = Array.isArray(types) ? types.map(t => decodeURIComponent(t)) : [decodeURIComponent(types)]
    let convertedTypes:SearchTypeDisplays[] = compact(types).map(t => t as unknown as SearchTypeDisplays)

    query = Array.isArray(query) ? query.toString() : query

    let filters = filterStr !== "" ? JSON.parse(decodeURIComponent(filterStr)) : {}

    if (filterStr === "") {
      filters.active = [true]
    }

    let sort:SearchSortBy | undefined
    if (sortBy) {
      sort = { sort: sortBy, order: sortDirection || "asc"}
    }

    return this.convertQueryStateToGQLState(query, { ...{ types: convertedTypes, filters }}, page, pageSize, sort)
  }

  convertQueryStateToGQLState = (query:string, filterState:FullSearchState, page: number, pageSize: number, sort?:SearchSortBy):SearchResultState => {
    let searchFilters:SearchResultState = {
      query: query,
      types: filterState.types || [],
      filters: { manager: {}, client: {}, custodian: {}, recordKeeper: {}, product: {}, targetDate: {}, vehicle: {}, person: {}, document: {}, index: {}},
      page: page,
      pageSize: pageSize
    }

    if(searchFilters.types.length === 0 ||  filterState.types?.includes(SearchTypeDisplays.People)) {
      const baseOrgTypeFilter = 'BROKER,INVADV,INVMGR,INVCON'

      merge(searchFilters.filters, {
        person: { orgTypeCode: baseOrgTypeFilter },
      })
    }

    if (filterState.filters && filterState.filters.managers) {
      let manager = filterState?.filters?.managers.join(',')

      merge(searchFilters.filters, {
        manager: { name: manager },
        client: { name: manager },
        custodian: { name: manager },
        recordKeeper: { name: manager },
        product: { managerName: manager },
        targetDate: { managerName: manager },
        vehicle: { managerName: manager },
        person: { managerName: manager },
        document: { managerName: manager },
        index: { providerName: manager}
      })
    }

    if (filterState.filters && filterState.filters.assetClasses) {
      let assetClass = filterState?.filters?.assetClasses.join(',')

      merge(searchFilters.filters, {
        manager: { assetClass: assetClass },
        client: { assetClass: assetClass },
        custodian: { assetClass: assetClass },
        recordKeeper: { assetClass: assetClass },
        product: { assetClass: assetClass },
        targetDate: { assetClass: assetClass },
        vehicle: { assetClass: assetClass },
        person: { assetClass: assetClass },
        document: { assetClass: assetClass },
        index: { assetClass: assetClass}
      })
    }

    if (filterState.filters && filterState.filters.vehicles) {
      let vehicle = filterState?.filters?.vehicles.join(',')

      merge(searchFilters.filters, {
        manager: { vehicleName: vehicle },
        client: { vehicleName: vehicle },
        custodian: { vehicleName: vehicle },
        recordKeeper: { vehicleName: vehicle },
        product: { vehicleName: vehicle },
        targetDate: { vehicleName: vehicle },
        vehicle: { name: vehicle },
        person: { vehicleName: vehicle },
        document: { vehicleName: vehicle }
      })
    }

    if (filterState.filters && filterState.filters.locations) {
      let locations = filterState?.filters?.locations

      const cities = locations.map((location) => location.split("|")[1]).join(',')
      const states = locations.map((location) => location.split("|")[0]).join(',')
      merge(searchFilters.filters, {
        manager: { city: cities, state: states },
        client: { city: cities, state: states },
        custodian: { city: cities, state: states },
        recordKeeper: { city: cities, state: states },
        product: { city: cities, state: states },
        targetDate: { city: cities, state: states },
        vehicle: { city: cities, state: states },
        person: { city: cities, state: states },
        document: { city: cities, state: states }
      })
    }

    let active = filterState?.filters?.active

    if (active && active.length === 1) {
      merge(searchFilters.filters, {
        manager: { inactive: !active[0] },
        custodian: { inactive: !active[0] },
        recordKeeper: { inactive: !active[0] },
        product: { inactive: !active[0] },
        vehicle: { inactive: !active[0] },
        client: { inactive: !active[0] },
        person: { inactive: !active[0] },
        index: { inactive: !active[0] },
        group: { inactive: !active[0] },
      })
    }

    if (sort) {
      searchFilters.sort = sort
    } else {
      searchFilters.sort = undefined
    }

    return searchFilters
  }

  convertGQLStateToQueryState = ():SearchFilterState => {
    let { filters } = this.state
    let returnState:SearchFilterState = { managers: [], active: [true], assetClasses: [], vehicles: [], locations: [] }

    if (filters.product?.managerName) {
      returnState.managers = filters.product?.managerName.split(',')
    }

    if (filters.product?.assetClass) {
      returnState.assetClasses = filters.product?.assetClass.split(',')
    }

    if (filters.vehicle?.name) {
      returnState.vehicles = filters.vehicle?.name.split(',')
    }

    if (typeof (filters?.product?.inactive) === 'undefined' || filters.product?.inactive === null) {
      returnState.active = [true,false]
    } else if (filters.product?.inactive === true) {
      returnState.active = [false]
    }

    if (filters.manager?.state && filters.manager?.city) {
      const states = filters.manager.state.split(',') || []
      const cities = filters.manager.city.split(',') || []
      returnState.locations = states.map((state, idx) => `${state}|${cities[idx]}`)
    }

    return returnState

  }

  updateURL = (sidebarFilters:FullSearchState) => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const { types, filters } = sidebarFilters

    urlParams.set('q', this.state.query)

    const fixedFilters = pickBy(filters, (v,k) => {
      if (Array.isArray(v)) {
        return !isEmpty(v)
      } else if (k === 'active') {
        return true
      } else {
        return v != null
      }
    })
    urlParams.set('filters', encodeURIComponent(JSON.stringify(fixedFilters)))

    if (types && types.length === 1) {
      urlParams.set('types',encodeURIComponent(types[0]))
    } else if (types && types.length > 1) {
      urlParams.delete('types')
      types.map(k => urlParams.append('types',encodeURIComponent(k)) )
    } else {
      urlParams.delete('types')
    }

    this.props.history.push(`?${urlParams}`)
  }

  updateFilters = (filters:FullSearchState) => {
    this.updateURL(filters)
    // this.setState(this.convertQueryStateToGQLState(this.state.query, filters), () => this.updateURL(filters))

    return null
  }

  updateSort = (e: ChangeEvent<HTMLInputElement>) => {
    const urlParams = new URLSearchParams(this.props.location.search)

    if (e.target.value !== '') {
      urlParams.set('sb', e.target.value)
      urlParams.set('sd', 'asc')
    } else {
      urlParams.set('sb','')
      urlParams.set('sd','')
    }
    this.props.history.push(`?${urlParams}`)
    // this.setState({ sort: sortBy })
  }

  updatePaginationSize = (size:number) => {
    const urlParams = new URLSearchParams(this.props.location.search)

    urlParams.set('pageSize',size.toString())
    this.props.history.push(`?${urlParams}`)
  }

  setPageNumber = (page:number) => {
    if (page > 0) {
      const urlParams = new URLSearchParams(this.props.location.search)

      urlParams.set('page', page.toString())
      this.props.history.push(`?${urlParams}`)
    }
  }

  render() {
    let searchVariables:SearchQueryVariables = {
      q: this.state.query === '' ? '*' : this.state.query,
      types: compact(this.state.types.map((type) => {
        let searchType = get(SearchDisplayToTypes,type) as SearchTypes | undefined
        return searchType? SearchTypes[searchType]: null
      })),
      filters: this.state.filters,
      sortBy: this.state.sort,
      limit: this.state.pageSize,
      offset: this.state.pageSize * (this.state.page - 1)
    }

    return (
      <Query<MeQuery> query={ME_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} >
        { meResults => {
          if (meResults.loading) {
            return <PlaceHolder />
          }

          if (meResults.error) {
            return (
              <Alert color="error">
                <p>There was an error fetching user data</p>
              </Alert>
            )
          }
          const filterTypes = (searchVariables:SearchQueryVariables, type:SearchTypes, permission:string[]) => {
            if (!this.props.auth.checkPermissions(permission)) {
              if(_.isArray(searchVariables?.types)){
                searchVariables.types = (searchVariables?.types as Maybe<SearchTypes>[]).filter(t => t !== type)
              }
            }
            return searchVariables
          }

          searchVariables = filterTypes(searchVariables, SearchTypes.Manager, ["view:all_managers"])
          searchVariables = filterTypes(searchVariables, SearchTypes.Bank, ["view:all_clients"])
          searchVariables = filterTypes(searchVariables, SearchTypes.RecordKeeper, ["view:all_clients"])
          searchVariables = filterTypes(searchVariables, SearchTypes.File, ["search:documents"])
          searchVariables = filterTypes(searchVariables, SearchTypes.Client, ["view:all_clients"])
          return (
            <Query<SearchQuery> query={SEARCH_QUERY} variables={searchVariables} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} errorPolicy="all">
              { results => {
                const { data, error, loading} = results
                const totalHits = data?.search?.hits || 0
                let matches = "\u00A0"
                let pagination = <></>

                if (!!data && data.search && !loading) {
                  matches = `${numbro(totalHits).format("0,0")}${totalHits === 10_000 ? "+" : ""} Matches`

                  pagination = <Pagination
                    setPaginationSize={this.updatePaginationSize}
                    pageSize={this.state.pageSize}
                    gotoPage={this.setPageNumber}
                    currentPage={this.state.page}
                    totalResults={totalHits}
                  />
                }

                if (!meResults.data?.me?.appMetadata?.firms) {
                  return (
                    <Alert color="error">
                      <p>Sorry, you do not have permission to search</p>
                    </Alert>
                  )
                }

                const containsManagers = this.state.types.includes(SearchTypeDisplays.Manager) || ((data?.filtersAvailable?.metaData?.resultCounts?.managers || 0) > 0)
                const containsDocuments = this.state.types.includes(SearchTypeDisplays.Documents) || ((data?.filtersAvailable?.metaData?.resultCounts?.documents || 0) > 0)
                const containsPeople = this.state.types.includes(SearchTypeDisplays.People) || ((data?.filtersAvailable?.metaData?.resultCounts?.people || 0) > 0)
                const containsProducts = this.state.types.includes(SearchTypeDisplays.Products) || ((data?.filtersAvailable?.metaData?.resultCounts?.products || 0) > 0)
                const containsTargetDate = this.state.types.includes(SearchTypeDisplays["Target Date"]) || ((data?.filtersAvailable?.metaData?.resultCounts?.target_dates || 0) > 0)
                const containsVehicles = this.state.types.includes(SearchTypeDisplays.Vehicles) || ((data?.filtersAvailable?.metaData?.resultCounts?.vehicles || 0) > 0)
                // const containsClient = this.state.types.includes(SearchTypeDisplays.Client) || ((data?.filtersAvailable?.metaData?.resultCounts?.clients || 0) > 0)
                console.log({containsManagers,containsDocuments,containsPeople,containsProducts,containsTargetDate,containsVehicles})
                return (
                  <Container fluid>
                    <Row>
                      <SearchSidebar
                        { ...results }
                        types={this.state.types}
                        filters={this.convertGQLStateToQueryState()}
                        updateFilters={this.updateFilters}
                        history={this.props.history}
                        currentUserFirms={compact(meResults.data.me.appMetadata.firms)}
                        auth={this.props.auth}
                      />
                      <Col md="10">
                        <Row>
                          <Col md="10">
                            <h2>Search Results for "{this.state.query}"</h2>
                            <p className="description">{ matches }</p>
                          </Col>
                          <Col md="2" className="d-flex flex-column align-items-center">
                            <h5 className="mini">Sort By</h5>
                            <Input type="select" onChange={this.updateSort} value={this.state.sort?.sort || ""}>
                              <option value="">Relevance</option>
                              <option value="name">Name</option>
                              <option value="type">Type</option>
                              <option value="status">Status (Active/Inactive)</option>
                              {(containsManagers || containsPeople) &&
                                <option value="location">Location</option>
                              }
                              {/* <option value="id">Id</option> */}
                              {containsVehicles &&
                                <>
                                  <option value="ticker">Ticker</option>
                                  <option value="productName">Product Name</option>
                                  <option value="vehicleType">Vehicle Type</option>
                                </>
                              }
                              {(containsVehicles || containsProducts) &&
                                <>
                                  <option value="strategyType">Strategy Type</option>
                                </>
                              }
                              {(containsProducts || containsPeople || containsTargetDate)&&
                                <option value="managerName">Manager Name</option>
                              }
                              {containsProducts &&
                                <option value="assetClass">Asset Class</option>
                              }
                              {/* <option value="title">title</option> */}
                              {containsTargetDate &&
                                <option value="glidepathPhilosophy">Glidepath Philosophy</option>
                              }
                              {containsDocuments &&
                                <>
                                  <option value="updated">Last updated</option>
                                </>
                              }
                            </Input>
                          </Col>
                        </Row>
                        <Renderer { ...{ data, error, loading }} />
                        <Row>
                          { pagination }
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                )
              }}

            </Query>
          )
        }}

      </Query>
    )
  }
}

const Pagination = (props:PaginationProps) => {
  const { currentPage, pageSize, totalResults } = props
  const [paginationSizeOpen, paginationSizeSetOpen] = useState(false);
  const toggle = () => paginationSizeSetOpen(!paginationSizeOpen);

  if ( totalResults < pageSize) {
    return <></>
  }

  const createPaginationNumbers = () => {
    let numbers = []
    let lastRendered = true
    const maxPage = Math.ceil(totalResults / pageSize) // convert to int
    for (let i = 1; i <= maxPage; i++){

      if (i === 1 || i === maxPage || (i >= currentPage - 2 && i <= currentPage + 2)) {
        lastRendered = true
        numbers.push(
          <Button key={i} className={classnames({ active: currentPage === i })} onClick={() => props.gotoPage(i)} color="page-number">
            {i}
          </Button>
        );
      } else if (lastRendered){
        lastRendered = false
        numbers.push(
          <Button key={i} color="page-number">
            ...
          </Button>
        );
      }
    }

    return numbers
  }
  const prevPage = () => {
    if (currentPage > 1) {
      props.gotoPage(currentPage - 1)
    }
  }

  const nextPage = () => {
    if (currentPage < totalResults) {
      props.gotoPage(currentPage + 1)
    }
  }

  return (
    <div className="mb-4">
      <Button onClick={() => prevPage()} color="link" className="mr-1">
        <FontAwesomeIcon
          icon="chevron-left"
          className="ml-2"
        />
      </Button>
      {createPaginationNumbers()}
      <Button onClick={() => nextPage()} color="link" className="mr-1">
        <FontAwesomeIcon
          icon="chevron-right"
          className="ml-2"
        />
      </Button>
      <ButtonDropdown className="mr-1" isOpen={paginationSizeOpen} toggle={toggle}>
          <DropdownToggle caret>
            {pageSize} per page
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=> props.setPaginationSize(5)}>5 per page</DropdownItem>
            <DropdownItem onClick={()=> props.setPaginationSize(10)}>10 per page</DropdownItem>
            <DropdownItem onClick={()=> props.setPaginationSize(25)}>25 per page</DropdownItem>
            <DropdownItem onClick={()=> props.setPaginationSize(50)}>50 per page</DropdownItem>
            <DropdownItem onClick={()=> props.setPaginationSize(100)}>100 per page</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
    </div>
  )
}

const Renderer = ({data, error, loading}:RendererProps) => {
  if (loading) {
    return (
      <div className="pane">
        <PlaceHolder />
      </div>
    )
  }
  if (error && !data) {
    console.log("Search Results error", error.message)
    return <ErrorDisplay error={error}/>
  }

  if (!!data && data.search && data.search.data) {
    let results = data.search.data.map((d) => {
      if (d == null) {
        return <div></div>
      }

      if(d.__typename === "Manager") {
        return (
          <ManagerResult {...d} key={`manager-${d.id}`} />
        )
      } else if (d.__typename === "RecordKeeper") {
        return (
          <RecordKeeperResult {...d} key={`record-keeper-${d.id}`} />
        )
      } else if (d.__typename === "Bank") {
        return (
          <CustodianResult {...d} key={`custodian-${d.id}`} />
        )
      } else if (d.__typename === "Client") {
        return (
          <ClientResult {...d} key={`client-${d.id}`} />
        )
      } else if (d.__typename === "Person") {
        return (
          <PersonResult {...d} key={`person-${d.id}`} />
        )
      } else if (d.__typename === "ProductFields") {
        return (
          <ProductResult {...d} key={`product-${d.id}`} />
        )
      } else if (d.__typename === "VehicleFields") {
        return (
          <VehicleResult {...d} key={`vehicle-${d?.fundid || d.vehicleName}`} />
        )
      } else if (d.__typename === "GlidePathVersion") {
        return (
          <TargetDateResult {...d} key={`date-${d.id}`} />
        )
      } else if (d.__typename === "File") {
        return (
          <DocumentResult {...d} key={`file-${d.fileid}`} />
        )
      } else if (d.__typename === "Index") {
        return (
          <IndexResult {...d} key={`index-${d.indexId}`} />
        )
      } else if (d.__typename === "Group") {
        return (
          <GroupResult {...d} key={`group-${d.groupId}`} />
        )
      }

      return <></>

    })

    return (
      <ListGroup className="horizontal with-category">
        { results }
      </ListGroup>
    )

  }

  return <></>

}
export default WithTopNav(SearchResults)
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import { History } from "history"
import _, { first, get, remove, set } from "lodash"
import moment, { Moment } from "moment"
import React, { Component, RefObject, useContext, useEffect, useMemo, useRef, useState } from "react"
import {
  match, Route, RouteComponentProps, Switch, useHistory, useParams, useRouteMatch
} from "react-router-dom"
import {
  Button, Col, Container, Form,
  FormGroup,
  Input, ListGroupItem, Row
} from "reactstrap"

import Auth from '../../Auth/Auth'
import { appDate, CalendarContext } from "../../Context/CalendarContext"
import { DATE_API_FORMAT } from "../../helpers/constant"
import exportTables from "../../helpers/exportTable"
import {
  convertLookupToString,
  excludePropertyArray
} from "../../helpers/object"
import {
  CharacteristicsFilters,
  CountryCode,
  GicsSectorCodes,
  OpenEndedMutualFund, ProductCharacteristicsQuery, ProductStructureCode, RegionCode, SubstitutionCode, UpdateVehicleInput, useUpdateVehicleCharacteristicsMutation, VehicleFields
} from "../../__generated__/graphql"
import { CalendarPicker, CalendarPickerType } from '../CalendarPicker'
import { OptionItem, Picker } from "../Shared/Picker"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import EditButtons from '../ui/EditButtons'
import QueryProductCharacteristicsDetails, { ProductCharacteristicsVehiclesDetail } from "./ProductCharacteristicsVehiclesDetail"
import { tableLengths, VehicleCharacteristicsDefinition, VehicleCharacteristicsTable } from "./ProductCharacteristicsVehiclesFields"

const CharacteristicsTables = ["miscPortfolioInfo", "portfolioCharacteristics"]

enum CharacteristicsTablesEnum {
  characteristics = "characteristics",
  weightedExposures = "weightedExposures"
}

const WeightedExposuresWithActive = ["bondSectorExposures", "durationExposures", "qualityExposures"]
interface ProductCharacteristicsVehiclesProps extends RouteComponentProps {
  productId: number
  auth: Auth
  productCharacteristics: ProductCharacteristicsQuery
}

interface IdVehicleFields extends Omit<VehicleFields, 'fundid'> {
  id: string
}

interface IdVehicle {
  vehicle: IdVehicleFields
}

interface IdOpenEndedMutualFund extends Omit<OpenEndedMutualFund, 'vehicle'>{
  vehicle: IdVehicleFields
}

const historicalStartDate = appDate.format(DATE_API_FORMAT)
const historicalEndDate = moment(appDate).subtract(3, "years").format(DATE_API_FORMAT)
const editableFilterId = 1

// to ensure one row per date
// see https://callanllc.atlassian.net/browse/CAL-2628?focusedCommentId=81383
export const DEFAULT_CHARACTERISTICS_FILTER: Omit<CharacteristicsFilters, "startDate"| "endDate"> = {
  active: true,
  countries: [CountryCode.ALL],
  regions: [RegionCode.ALL],
  sectors: [GicsSectorCodes.ALL],
  substitutions: [SubstitutionCode.REAL],
  surveyed: true,
}

export const NonNullableCharacteristicsProperties = {country: CountryCode.ALL, region: RegionCode.ALL, sector: GicsSectorCodes.ALL, substitution: SubstitutionCode.REAL}

const ProductCharacteristicsVehicles: React.FC<ProductCharacteristicsVehiclesProps> = ({
  productId,
  auth,
  productCharacteristics,
}: ProductCharacteristicsVehiclesProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [screen, setScreen] = useState<CalendarPickerType>(context.period === "historical" ? "historical" : "single")
  const [showEditMode, setShowEditMode] = useState(true)
  const [showCalendar, setShowCalendar] = useState(true)
  const [search, setSearch] = useState("")
  const resultRef = useRef<ProductCharacteristicsVehiclesDetail>(null)
  const queryRef = useRef<QueryProductCharacteristicsDetails>(null)
  const [updateVehicle] = useUpdateVehicleCharacteristicsMutation()
  let match = useRouteMatch()
  const history = useHistory()
  const [loadingMore, setLoadingMore] = useState(false)
  const [historicalDate, setHistoricalDate] = useState(moment(historicalEndDate))
  const [tab, setTab] = useState<string>('')
  const data = productCharacteristics

  useEffect(() => {
    setHistoricalDate(moment(historicalEndDate))
    if(!editMode && screen === "historical"){
      queryRef.current?.resetHistory()
    }
  }, [screen])

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const handleShowEdit = (value:boolean) => {
    if(value === false){
      setEditMode(false)
    }
    setShowEditMode(value)
  }

  const updateStateFromResult = (result: any) => {
    setSaving(false)
    if (result && result.data) {
      // so result show after save.
      resultRef.current?.refetch()
      setEditMode(false)
    }
    if(screen === "historical"){
      queryRef.current?.resetHistory()
    }
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    let currentVehicle = _.cloneDeep(resultRef!.current?.state?.currentState)
    let initialVehicle = resultRef!.current?.state?.initialState
    if (!currentVehicle || currentVehicle.__typename !== "VehicleFields") {
      return
    }
    if (!initialVehicle) {
      return
    }
    setSaving(true)
    let submissionData:any = {}
    const tables = Object.keys(tableLengths)
    // TODO should split between types.
    tables.forEach((tableName) => {
      let oldTable: any, newTable: any
      let type = (CharacteristicsTables.includes(tableName)? CharacteristicsTablesEnum.characteristics: CharacteristicsTablesEnum.weightedExposures).toString()
      if(type === "characteristics") {
        newTable = _.get(currentVehicle, [type], []) || []
        oldTable = _.get(initialVehicle, [type], []) || []
        if(!_.isEqual(newTable, oldTable)){
          if(!submissionData.hasOwnProperty(type)) {
            _.set(submissionData, [type], [])
          }
        }
        newTable.forEach((row:any, index:number) => {
          let oldValue = oldTable.find((oldRow:any) => oldRow.date === row.date)
          if(row && !_.isEqual(row, oldValue)) {
            let diff: any = {}
            let newResult = Object.keys(row).reduce((acc, property) => {
              if(_.isNull(row[property])){
                if(_.isNull(oldValue[property]) || !oldValue?.hasOwnProperty(property)){
                  // do not update if no old value and new value null.
                }else if(oldValue[property]) {
                  acc[property] = row[property]
                }
              }else if(!oldValue?.hasOwnProperty(property) || !_.isEqual(row[property], oldValue[property])){
                acc[property] = row[property]
              }
              return acc
            }, diff)
            // check nulls
            if(Object.keys(NonNullableCharacteristicsProperties).every((property) => !newResult.hasOwnProperty(property))){
              newResult = {...newResult, ...NonNullableCharacteristicsProperties}
            }
            let oldResult = submissionData[type] ? submissionData[type].find((oldRow:any) => oldRow.date === row.date) : null
            if(oldResult){
              submissionData[type] = submissionData[type].map((oldRow:any) => oldRow.date === row.date ? {...oldRow, ...newResult} : oldRow)
            }else {
              submissionData[type].push({...oldValue, ...newResult})
            }
          }
        })
      }else {
        // weightedExposures, match date & type
        newTable = _.get(currentVehicle, [type, tableName], []) || []
        oldTable = _.get(initialVehicle, [type, tableName], []) || []
        if(!submissionData.hasOwnProperty(type)) {
          _.set(submissionData, [type], {[tableName]: {add: [], remove: []}})
        }else if(!submissionData[type].hasOwnProperty(tableName)) {
          _.set(submissionData, [type, tableName], {add: [], remove: []})
        }
        newTable.forEach((row:any, index:number) => {
          // weightedExposures, match date & type
          let oldValue = oldTable.find((oldRow:any) => oldRow.date === row.date && row?.type?.code && oldRow?.type?.code === row?.type?.code)
          let hasActiveValue = WeightedExposuresWithActive.includes(tableName)
          let valueProperty = hasActiveValue? ["value","active"]: ["value"]
          if(row && !_.isEqual(row, oldValue)) {
            if(_.get(row, valueProperty) == null && _.get(row, "contributionToDuration") == null && _.get(row, "contributionToDurationTimeSpread") == null && _.get(row, "relativeContributionToDurationTimeSpread") == null){
            } else {
              let { type: rowType, value, ...rest} = row
              let formattedData = {...rest, type: rowType.code, value: hasActiveValue? value?.active: value}
              submissionData[type][tableName].add.push(formattedData)
            }
            if(oldValue){
              submissionData[type][tableName].remove.push({date: row.date, type: row.type})
            }
          }
        })
        if(submissionData[type][tableName].add.length === 0 && submissionData[type][tableName].remove.length === 0){
        _.unset(submissionData, [type, tableName])
        }else if(submissionData[type][tableName].add.length === 0){
          delete submissionData[type][tableName].add
        }else if(submissionData[type][tableName].remove.length === 0){
          delete submissionData[type][tableName].remove
        }
      }
    })
    if(submissionData?.weightedExposures){
      _.set(currentVehicle, "weightedExposures",  submissionData?.weightedExposures)
    }
    if(submissionData?.characteristics){
      _.set(currentVehicle, "characteristics",  submissionData?.characteristics)
    }

    // upload only the changed data.
    let formattedData = convertLookupToString(submissionData, false, [])
    const formattedRemoveNull = formattedData //_.pickBy(formattedDataRemove, _.identity);
    const data = excludePropertyArray(formattedRemoveNull, ["__typename", "name", "fundid", "id", "isRepresentative", "holdingStatus", "vehicleDataCollection", "identifiers", "inceptionDate", "status", "category", "active", "surveyed"])
    const updateData = {
      id: currentVehicle.id,
      patch: data,
    } as UpdateVehicleInput

    updateVehicle({ variables: {
      input: updateData,
      startDate: screen === "single" ? searchDate : historicalEndDate,
      endDate: screen === "single" ? searchDate : historicalStartDate,
      filters: {
        startDate: screen === "single" ? searchDate : historicalEndDate,
        endDate: screen === "single" ? searchDate : historicalStartDate,
        ...DEFAULT_CHARACTERISTICS_FILTER, 
      }
    } })
    .then(updateStateFromResult)
    .catch((err) => {
      setSaving(false)
      console.error("Error update Product Characteristics", err.message)
    })
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top above-picker">
      <Form className="mr-2 pr-3 border-right">
        <FormGroup row className="relative m-0 mr-1">
          <Input
            type="text"
            placeholder="Search Results"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyDown={handleEnterKeyDown}
          />
          {search === "" && (
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          )}
        </FormGroup>
      </Form>
      {showCalendar &&
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          editMode={editMode}
          setEditMode={(value:boolean) => setEditMode(value)}
          updateType={(type:CalendarPickerType) => setScreen(type)}
          hasHistorical={true}
          defaultType={screen}
        />
      }
      {showCalendar && screen === "historical" &&
        <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>resultRef.current?.loadMore()}>
          {loadingMore && "Loading"}
          {!loadingMore && "Load 3 More Years"}
        </Button>
      }
      {tab !== "holdings" &&
        <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables()}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
      }
      {showEditMode && auth.checkPermissions(["edit:manager"]) &&
        <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} />
      }
    </div>
  )

  // show Strategy Tab for some PC/PE when matching rules.
  const showStrategyTab = (product: ProductCharacteristicsQuery["product"]) => {
    const parentId = product?.product?.assetClass?.parent?.id
    const assetClassId = product?.product?.assetClass?.id
    const structureCode = product?.product?.structure?.code
    if(parentId !== 57 && parentId !== 146){
      return false
    }
    if (structureCode === ProductStructureCode.FOF){
      return true
    }
    if(!assetClassId) return false
    if([157, 220, 221, 222, 223].includes(assetClassId)){
      return true
    }
    return false
  }

  if (data && data.product && data.product.product?.vehicles) {
    let vehicles = _.cloneDeep(data.product.product.vehicles) as IdVehicle[]
    let tableDef = VehicleCharacteristicsDefinition[data.product.product.assetClass?.id || "0"] || VehicleCharacteristicsDefinition[data.product.product.assetClass?.parent?.id || "0"]
    if (!showStrategyTab(data.product)){
      remove(tableDef, {"tableName": "strategies"})
    }
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <Result
              detailRef={resultRef}
              queryRef={queryRef}
              editMode={editMode}
              vehicles={vehicles}
              search={search}
              match={match}
              history={history}
              searchDate={searchDate}
              historicalDate={screen === "historical" ? historicalDate.format(DATE_API_FORMAT) : undefined}
              setShowEditMode={(value:boolean) => handleShowEdit(value)}
              setShowCalendar={(value:boolean) => setShowCalendar(value)}
              tableDefinitions={tableDef}
              setHistoricalDate={(value:Moment) => setHistoricalDate(value)}
              tab={tab}
              setTab={setTab}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

interface Props {
  vehicles: IdVehicle[]
  editMode: boolean
  search: string
  detailRef: RefObject<ProductCharacteristicsVehiclesDetail>
  queryRef: RefObject<QueryProductCharacteristicsDetails>
  match: any
  history: any
  searchDate: string
  historicalDate?: string
  setShowEditMode: (value:boolean) => void
  setShowCalendar: (value:boolean) => void
  tableDefinitions: VehicleCharacteristicsTable[]
  setHistoricalDate: (value:Moment) => void
  tab: string
  setTab: (value: string) => void
}

class Result extends Component<Props> {
  render() {
    return (
      <Row>
        <Switch>
          <Route path={`${this.props.match.path}/:vehicleId?`}>
            <VehicleHolder
              detailRef={this.props.detailRef}
              queryRef={this.props.queryRef}
              vehicles={this.props.vehicles}
              editMode={this.props.editMode}
              search={this.props.search}
              history={this.props.history}
              match={this.props.match}
              searchDate={this.props.searchDate}
              historicalDate={this.props.historicalDate}
              setShowEditMode={this.props.setShowEditMode}
              setShowCalendar={this.props.setShowCalendar}
              tableDefinitions={this.props.tableDefinitions}
              setHistoricalDate={this.props.setHistoricalDate}
              key={"test"}
              tab={this.props.tab}
              setTab={this.props.setTab}
            />
          </Route>
        </Switch>
      </Row>
    )
  }
}

function VehicleHolder({
  detailRef,
  queryRef,
  vehicles,
  editMode,
  search,
  history,
  match,
  searchDate,
  setShowEditMode,
  setShowCalendar,
  tableDefinitions,
  historicalDate,
  setHistoricalDate,
  tab,
  setTab,
}: any) {
  let { vehicleId } = useParams() as {vehicleId?: string}
  if (vehicleId === "new") {
    vehicleId = "0"
  }
  const vehicle = _.find(vehicles, (o) => {
    return o.vehicle.id === vehicleId
  })
  const editable: boolean = useMemo(() => vehicle?.vehicle?.vehicleDataCollection?.characteristicsRequired || vehicle?.vehicle?.vehicleDataCollection?.holdingsRequired, [vehicleId])
  const handleShowEditChange = (value:boolean) => {
    if(editable){
      setShowEditMode(value)
    }
    setShowCalendar(value)
  }
  // reset after change not on click incase user cancels navigation
  useEffect(() => {
    setShowCalendar(true)
    setHistoricalDate(moment(historicalEndDate))
    setShowEditMode(!!editable)
    setTab(get(first(tableDefinitions), 'tableName'))
  }, [vehicleId])
  if (!vehicle) {
    return (
      <>
        <VehiclePicker
          vehicles={vehicles}
          search={search}
          history={history}
          match={match}
        />
        <Col md="8" lg="9" className="pl-1">
          <div className="pane">
            <Row>
              <Col>
                <h3>No Vehicle Selected</h3>
              </Col>
            </Row>
          </div>
        </Col>
      </>
    )
  }
  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={(path) => history.push(path)}
      />
      <VehiclePicker
        selectedVehicle={vehicle}
        vehicles={vehicles}
        search={search}
        history={history}
        match={match}
      />
      <QueryProductCharacteristicsDetails
        ref={queryRef}
        detailRef={detailRef}
        vehicle={vehicle}
        editMode={editMode}
        key={vehicleId}
        tableDefinitions={tableDefinitions}
        searchDate={searchDate}
        historicalDate={historicalDate}
        setShowEditMode={(value:boolean) => handleShowEditChange(value)}
        setHistoricalDate={setHistoricalDate}
        tab={tab}
        setTab={setTab}
      />
    </>
  )
}

interface VehiclePickerProps {
  selectedVehicle?: IdVehicle
  vehicles: IdVehicle[]
  search: string
  history: History
  match: match
}

interface VehiclePickerState {
  pickerFilters: OptionItem[]
  activeFilters: number[]
  pickerSortOptions: OptionItem[]
  activeSort: number
}

let showTerminatedFilterId: number

export class VehiclePicker extends Component<VehiclePickerProps> {
  state: VehiclePickerState
  constructor(props: VehiclePickerProps) {
    super(props)
    let pickerFilters: OptionItem[] = []
    let activeFilters: number[] = []
    pickerFilters = [
      {
        id: editableFilterId,
        name: "Editable",
      }
    ]
    // Add the possible options to the
    _.uniq(props.vehicles.map((vehicle:IdVehicle) => _.get(vehicle, "vehicle.category.value", ""))).sort().map((category:string) => { return pickerFilters.push({id: pickerFilters.length+1, name: category})})
    showTerminatedFilterId = pickerFilters.length + 1

    pickerFilters.push({
        id: showTerminatedFilterId,
        name: "Show Terminated",
    })

    activeFilters = []
    this.state = {
      pickerFilters,
      activeFilters: activeFilters,
      pickerSortOptions: [
        {
          id: 1,
          name: "Editable",
        },
        {
          id: 2,
          name: "Name ( A to Z )",
        },
        {
          id: 3,
          name: "Name ( Z to A )",
        },
      ],
      activeSort: 1,
    }
    if(!props.selectedVehicle){
      const sortedObjects = this.sortObjects()
      if(sortedObjects.length > 0){
        props.history.push(`${props.match.url}/${_.get(_.first(sortedObjects),"vehicle.id")}`)
      }
    }
  }

  onFilter = (filterId: number) => {
    let { activeFilters } = this.state
    const index = activeFilters.indexOf(filterId)
    if (index === -1) {
      activeFilters.push(filterId)
    } else {
      activeFilters.splice(index, 1)
    }
    this.setState({ activeFilters })
  }

  onSort = (sortId: number) => {
    this.setState({ activeSort: sortId })
  }

  sortObjects = (): IdVehicle[] => {
    const { vehicles, search } = this.props
    const { activeFilters, activeSort } = this.state
    return vehicles
      .filter((vehicle: IdVehicle) => {
        if (activeFilters.includes(editableFilterId)) {
          return (vehicle.vehicle?.vehicleDataCollection?.characteristicsRequired || vehicle.vehicle?.vehicleDataCollection?.holdingsRequired)
        }
        return true
      })
      .filter((vehicle: IdVehicle) => {
        if (!activeFilters.includes(showTerminatedFilterId)) {
          return (vehicle.vehicle?.status?.code !== "t")
        }
        return true
      })
      .filter((vehicle: IdVehicle) => {
        const activeFiltersWithoutTerminated = activeFilters.filter(i => ![editableFilterId, showTerminatedFilterId].includes(i))
        if (search) {
          const name = (vehicle.vehicle?.name || "").toLocaleLowerCase().trim()
          if (!name.includes(this.props.search.toLowerCase().trim()))
            return false
        } else if (activeFiltersWithoutTerminated.length > 0) {
          // Inverse as filters remove options
          return _.some(activeFilters, (id:number) => {
            const matchingFilter = _.find(this.state.pickerFilters, {id: id})
            return matchingFilter?.name === vehicle.vehicle?.category?.value
          })

        }
        return true
      })
      .sort((vehicleA: IdVehicle, vehicleB: IdVehicle) => {
        const nameA = (vehicleA.vehicle?.name || "").toLocaleLowerCase().trim()
        const nameB = (vehicleB.vehicle?.name || "").toLocaleLowerCase().trim()
        const editableA = vehicleA.vehicle?.vehicleDataCollection?.characteristicsRequired || vehicleA.vehicle?.vehicleDataCollection?.holdingsRequired
        const editableB = vehicleB.vehicle?.vehicleDataCollection?.characteristicsRequired || vehicleB.vehicle?.vehicleDataCollection?.holdingsRequired
        switch (activeSort) {
          case 1:
            return editableA === editableB ? nameA.localeCompare(nameB) : (!!editableA ? -1 : 1)
          case 2:
            return nameA.localeCompare(nameB)
          case 3:
            return nameB.localeCompare(nameA)
          default:
            throw new Error(`unrecognized sort choice ${activeSort}`)
        }
      })
  }

  objectListItem = (vehicle: IdVehicle | IdOpenEndedMutualFund, idx: number) => {
    const aum = _.get(vehicle, "openEndedVehicle.latestAssetsUnderManagement")
    const category = _.get(vehicle, "vehicle.category.value")
    let status = "N/A"
    if(vehicle.vehicle?.status?.code === "c") status = "Closed"
    if(vehicle.vehicle?.status?.code === "o") status = "Open"
    if (vehicle.vehicle?.status?.code === "t") status = "Terminated"

    const editable = vehicle.vehicle?.vehicleDataCollection?.characteristicsRequired || vehicle.vehicle?.vehicleDataCollection?.holdingsRequired
    return (
      <ListGroupItem
        tag="a"
        key={`${idx}-${vehicle.vehicle?.id}`}
        className={classnames({
          active: this.props.selectedVehicle?.vehicle?.id === vehicle.vehicle?.id,
          "picker-list-item-characteristics": true,
          "picker-list-item-faded": !editable,
        })}
        onClick={() =>{
            this.props.history.push(`${this.props.match.url}/${vehicle.vehicle?.id}`)
          }
        }
      >
        <h5>
          {vehicle.vehicle?.name}
        </h5>
        {!!editable && !!vehicle.vehicle?.isRepresentative &&
          <FontAwesomeIcon
            icon={["fas", "pen"]}
            size="sm"
            className="fontawesome-icon dark-icon-color text-gray-50 picker-list-item-icon"
          />
        }
        {!!editable && !vehicle.vehicle?.isRepresentative &&
          <FontAwesomeIcon
            icon={["far", "pen"]}
            size="sm"
            className="fontawesome-icon dark-icon-color text-gray-50 picker-list-item-icon"
          />
        }
        <dl>
          <>
            <dt>Vehicle Category:</dt>
            <dd>{category}</dd>
          </>
          <dt>Identifier:</dt>
          <dd>{vehicle.vehicle?.identifiers?.ticker || vehicle.vehicle?.identifiers?.cusip || "—"}</dd>
          <dt>Vehicle ID:</dt>
          <dd>{vehicle.vehicle.id || '-' }</dd>
        </dl>
        <div className={classnames({
          "list-group-item-status": true,
          "background-olive-10": status === "Open",
          "background-orange-30": status === "Terminated",
          "background-gray-20": status === "Closed",
        })}>
          {status}
        </div>
      </ListGroupItem>
    )
  }

  emptyMessage() {
    return `There are no vehicles associated with this product.`
  }

  render() {
    const { search } = this.props
    const {
      pickerFilters,
      activeFilters,
      pickerSortOptions,
      activeSort,
    } = this.state

    const sortedObjects = this.sortObjects()

    return (
      <Picker
        filters={pickerFilters}
        activeFilters={activeFilters}
        sortOptions={pickerSortOptions}
        activeSort={activeSort}
        onFilter={this.onFilter}
        onSort={this.onSort}
        searching={search ? true : false}
      >
        {sortedObjects.length > 0 && sortedObjects.map((o, idx) => this.objectListItem(o, idx))}
        {sortedObjects.length === 0 &&
          <div className="p-3 text-gray-70">
            {this.emptyMessage()}
          </div>
        }
      </Picker>
    )
  }
}


export default ProductCharacteristicsVehicles

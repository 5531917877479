import {
  Col,
  ListGroupItem, Row
} from "reactstrap"
import { SearchResultOrgFragment } from '../../../__generated__/graphql'

export const ManagerResult = (props :SearchResultOrgFragment) => {
  const { id, name, address } = props
  // const {street = '', city, state, zip, country} = address
  const status = props.isInactive ? 'Inactive' : 'Active'
  const addressStr = [address?.city, address?.state].filter(item => item && item !== '').join(', ')

  return (
    <ListGroupItem tag="a" href={`/managers/${id}/profile`}>
      <div className="category manager">
        <div className="category-text">
          <h6>Manager</h6>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md="3">
            <h3>{name}</h3>
            <p>&nbsp;</p>
          </Col>
          <Col md="9">
            <Row>
              <Col md="6">
                <h5>Manager ID</h5>
                <p>{id}</p>
              </Col>
              <Col md="4">
                <h5>Location</h5>
                <p>{addressStr}</p>
              </Col>
              <Col md="2">
                <h5>Status</h5>
                <p>{status}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroupItem>
  )
}
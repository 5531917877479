import { IconName } from '@fortawesome/fontawesome-svg-core'
import { faLoveseat } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import _, { compact } from "lodash"
import React, { FormEvent, useContext, useState } from "react"
import { match, RouteComponentProps, useHistory } from "react-router-dom"
import { Button, Col, Container, Form, Row, Table, UncontrolledAlert } from "reactstrap"
import Auth from "../../Auth/Auth"
import { EditButtonContext } from "../../Context/EditButtonContext"
import { FormInputField, FormInputSubClasses } from "../../helpers/constant"
import exportTables from '../../helpers/exportTable'
import { getNewStateObject } from "../../helpers/helpers"
import { reShapeObject } from '../../helpers/object'
import { appProps } from "../../queries/extended/appProps"
import { Maybe, GroupDetailsQuery, GroupOverviewFragment, useMeQuery, useGroupDetailsQuery, useUpdateGroupDetailsMutation } from "../../__generated__/graphql"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import EditButtons from "../ui/EditButtons"
import { FormInput } from "../ui/Forms/FormInput"
import PlaceHolder from "../ui/PlaceHolder"

interface GroupRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.groupId
    groupId: string
  }> {}

interface GroupOverviewDetailFragment {
  data: GroupOverviewFragment
  groupId: string
}

type idProps = {
  groupId: string
  data: GroupDetailsQuery
  auth: Auth
  match: match<{ groupId: string }>
}

interface GroupOverviewInput extends FormInputField {}

type ColumnDefItem = {
  field: string
  type: string
  title: string
  readonly?: boolean
  className?: string
  subtype?: string
  optionSource?: string
  required?: boolean
  subClasses?: { [name in FormInputSubClasses]?: string }
  widthClass?: string
  placeholder?: string
  tooltip?: { icon: string; id: string }
}

const GroupSummaryInputList: GroupOverviewInput[] = [
  {
    property: "generalInfo.longName",
    label: "Group Name",
    type: "text",
    readonly: true,
    // tooltip: {
    //   icon: "question-circle",
    //   id: "groupNameTooltip",
    // },
  },
  {
    property: "generalInfo.id",
    label: "Group ID",
    type: "text",
    readonly: true,
    // tooltip: {
    //   icon: "question-circle",
    //   id: "groupIdTTooltip",
    // },
  },
  {
    property: "generalInfo.status.value",
    label: "Status",
    type: "text",
    readonly: true,
  },
  {
    // read-only
    property: "generalInfo.assetClass.parent.value",
    label: "Asset Class",
    type: "text",
  },
  {
    // read-only
    property: "generalInfo.assetClass.value",
    label: "Strategy Type",
    type: "text",
    subtype: "single",
    required: true,
  },
  // {
  //   property: "generalInfo.assetClass.parent.code",
  //   label: "Asset Class",
  //   type: "select",
  //   subtype: "single",
  //   // optionSourceFunction: GetLookupDataToOptions
  // },
  // {
  //   property: "generalInfo.assetClass.code",
  //   label: "Strategy Type",
  //   type: "select",
  //   subtype: "single",
  //   required: true,
  //   // optionSourceFunction: GetLookupDataToOptions
  // },
  // {
  //   property: "generalInfo.type",
  //   label: "Type",
  //   type: "select",
  //   subtype: "single",
  //   optionSource: "GroupTypeCode",
  //   readonly: true,
  // },
  {
    property: "generalInfo.type.value",
    label: "Type",
    type: "text",
    readonly: true,
  },
  {
    property: "generalInfo.source",
    label: "Source",
    type: "text",
    readonly: true,
  },
  {
    property: "generalInfo.vehicleTypes",
    label: "Vehicle Type(s)",
    type: "text",
    readonly: true,
  },
]

const GroupPhilosophyInputList: GroupOverviewInput[] = [
  {
    property: "generalInfo.philosophy",
    label: "Philosophy",
    type: "textarea",
    readonly: true,
  },
]

const PreShapeActions = {
}

const getInitialState = (data: GroupDetailsQuery) => {
  return reShapeObject(_.cloneDeep(data), PreShapeActions) as typeof data
}

const GroupOverviewMain: React.FC<idProps> = (props) => {
  const { data, groupId, auth, match } = props
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const { resetErrors } = useContext(EditButtonContext)
  const formattedData = getInitialState(data)
  const [initialState, setInitial] = useState(formattedData)
  const [currentState, setState] = useState(formattedData)
  const history = useHistory()

  const [updateGroupOverview] = useUpdateGroupDetailsMutation()

  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleEdit = (value: boolean) => {
    setEditMode(value)
  }

  const handleInputChange = (value: any, property: any) => {
    let newState = getNewStateObject({
      state: currentState,
      newValue: value,
      property,
    }) as typeof currentState
    setState(newState)
  }

  const getEditableFields = (data: GroupDetailsQuery) => {
    return {generalInfo: _.get(data, "generalInfo")}
  }

  const handleSubmit = () => {
    // setSaving(true)
    // // the only editable field here is 'website'
    // let patch = getEditableFields(currentState)
    // if (false) {
    //   console.log("no edit")
    //   setSaving(false)
    //   handleEdit(false)
    // } else {
    //   console.log("preparing", patch)
    //   let input = {
    //     id: groupId,
    //     patch,
    //   }
    //   updateGroupOverview({
    //     variables: {
    //       input,
    //     },
    //   })
    //     .then((result) => {
    //       setSaving(false)
    //       if (result.data?.updateGroup) {
    //         let { generalInfo } = result.data?.updateGroup
    //         let newData = { generalInfo } as GroupDetailsQuery
    //         setInitial(newData)
    //         setState(newData)
    //         setEditMode(false)
    //       }
    //     })
    //     .catch((err) => {
    //       setSaving(false)
    //       console.error(err.message)
    //     })
    //   setSaving(false)
    // }
  }
  return (
    <>
      <RouteLeavingGuard when={editMode} navigate={(path) => history.push(path)} />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid className={"px-0"}>
          <div className="pane pane-toolbar sticky-top">
                <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
                Export CSV
                <img src='/assets/CSV.svg' className="ml-2"/>
                </Button>
          </div>
          <Row>
            <Col>
              {/** CAL-2050*/}
              {/* <div className="pane pane-toolbar sticky-top">
                {auth.checkPermissions(["edit:manager"]) && (
                  <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} cancelEdit={() => setState(initialState)} />
                )}
              </div> */}
              <div className="pane mb-4 exportable-form" data-export-name="Group Details">
              <div className="row form-section-title headline underline small-font-size pb-2 mb-2 text-left">Group Details</div>
                <Row>
                  <Col sm="4" className="px-3" key={0}>
                    {GroupSummaryInputList.map(({ property, label, type, subtype, placeholder, subClasses, readonly, required, tooltip, optionSource, options }, idx) => {
                      let propertyVal: any = _.get(currentState, property)
                      let onChangeCallback = (value: any) => handleInputChange(value, property)
                      if(property === "generalInfo.vehicleTypes") {
                        let chainedString =  (propertyVal as {code: any, shortValue: string, value: string}[]).reduce((acc, type) => {
                          if(type.value) {
                            acc = acc.concat([type.value])
                          }
                          return acc
                        }, [] as string[])
                        propertyVal = chainedString.join(", ")
                      }
                      return (
                        <div key={idx}>
                          <FormInput
                            key={idx}
                            property={property}
                            displayName={label}
                            subClasses={subClasses}
                            type={type}
                            subtype={subtype}
                            placeholder={placeholder}
                            idx={idx}
                            editMode={editMode}
                            propertyVal={propertyVal}
                            updateValue={onChangeCallback}
                            optionSource={optionSource}
                            options={options}
                            readonly={readonly}
                            required={required}
                            tooltip={tooltip}
                            defaultOptions={propertyVal}
                          />
                        </div>
                      )
                    })}
                  </Col>
                  <Col sm="7" className="px-4">
                    {GroupPhilosophyInputList.map(
                      (
                        {
                          property,
                          label,
                          type,
                          subtype,
                          placeholder,
                          optionSource,
                          required,
                          tooltip
                        },
                        idx
                      ) => {
                        let propertyVal: any = _.get(currentState, property)
                        let onChangeCallback = (value: any) =>
                          handleInputChange(value, property)
                        return (
                          <FormInput
                            key={idx}
                            property={property}
                            displayName={label}
                            type={type}
                            subtype={subtype}
                            optionSource={optionSource}
                            placeholder={placeholder}
                            idx={idx}
                            editMode={editMode}
                            propertyVal={propertyVal}
                            updateValue={onChangeCallback}
                            rows={10}
                            tooltip={tooltip}
                            required={required}
                          />
                        )
                      }
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

const GroupOverview: React.FC<appProps & GroupRouteProps & GroupOverviewDetailFragment> = (props) => {
  const { groupId, auth, match } = props
  const { loading, error, data } = useGroupDetailsQuery({
    variables: { id: groupId },
    fetchPolicy: "no-cache",
  })
  const { loading: userLoading, error: userError, data: userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (user === null) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!!data && data.generalInfo?.__typename === "Group") {
    return <GroupOverviewMain data={data} auth={auth} match={match} groupId={groupId} />
  } else {
    return <div>data doesn't exist.</div>
  }
}

export default GroupOverview

import React from "react"
import {
  Row,
  Col,
  ListGroupItem
} from "reactstrap"
import { SearchResultIndexFragment } from '../../../__generated__/graphql'

export const IndexResult = (props:SearchResultIndexFragment) => {
  const { indexId, indexName, org, identifiers, assetClass, status } = props

  return (
    <ListGroupItem tag="a" href={`/indexes/${indexId}/overview`}>
      <div className="category index">
        <div className="category-text">
          <h6>Index</h6>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md="3">
            <h3>{indexName}</h3>
            <h5 className="d-inline mr-1">Index Provider:</h5>
            <p className="d-inline">{org?.name}</p>
          </Col>
          <Col md="9">
            <Row>
              <Col md="2">
                <h5>Index ID</h5>
                <p>{indexId}</p>
              </Col>
              <Col md="2">
                <h5>Bloomberg ID</h5>
                <p>{identifiers?.bloomberg}</p>
              </Col>
              <Col md="3">
                <h5>Asset Class</h5>
                <p>{assetClass?.parent?.shortName}</p>
              </Col>
              <Col md="3">
                <h5>Strategy Type</h5>
                <p>{assetClass?.shortName}</p>
              </Col>
              <Col md="2">
                <h5>Status</h5>
                <p>{ status?.value }</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroupItem>
  )
}
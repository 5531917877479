import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { clone, compact, first, get, intersection, isEqual, remove, some, sortBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'

import { ApolloQueryResult } from '@apollo/client'
import { ClientPortfolioSimpleFragment, ComponentExportType, ComponentOverrideSettings, ComponentType, DataPointStatus, FeeType, LayoutSectionType, ManagerPerformancePeriodicity, Maybe, MeReportFragment, NewManagerPerformanceDataQuery, Quartile, ReportColumnDataFragment, ReportManagerPerformanceBaseDataRowFragment, ReportManagerPerformanceComponentQuery, ReportManagerPerformanceComponentQueryVariables, ReportManagerPerformanceDataFragment, ReportManagerPerformanceDataRowFragment, ReportManagerPerformanceFetchFragment, ReportingPerformancePeriod, ReportsFragment, useDeletePerformanceOrderMutation, useListSimpleQuery, useMeReportQuery, useNewManagerPerformanceDataQuery, usePlanSimplePortfoliosListQuery, useReportManagerPerformanceComponentQuery, useUpdateUserMetadataMutation } from '../../../__generated__/graphql'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import { excludePropertyArray } from '../../../helpers/object'
import { convertComponentForMutation, expandableDataList } from '../../../helpers/report'
import { useStoredTab } from '../../../helpers/usehook'
import { FormInput } from '../../ui/Forms/FormInput'
import { ApprovalsReportDisplay } from '../Shared/ReportApprovals'
import { AggregatedAfterPublishProps, AggregatedComponentProps, AggregatedExportSettingsProps, BaseComponent, ErrorComponent, FootnotableComponent, FootnoteLink, IndividualComponentProps, LoadingComponent, OptionsModal, ReportDisplayType, ReportTab, TemplateComponent, ToolbarProps, getPortfolioRowLinks } from '../Shared/ReportComponent'
import { QuartileDisplay, ReportDataPoint } from '../Shared/ReportDataPoint'
import { AdditionalReportingEdit, AggregatedReportEditProps, ReportEditField, ReportEditFootnoteFields, ReportEditHeading, ReportEditInfoTotalField, ReportEditInstances, SimpleReportEdit } from '../Shared/ReportEdit'

const ManagerPerformance: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportManagerPerformanceFetchFragment>
      expectedTypename={"ManagerPerformance"}
      reactComponent={ManagerPerformanceDisplay}
      {...props}
    />
  )
}


// Used to store state that must be shared with export
type componentState = {
  tab?: ReportTab
  openItems?: (number | string)[]
  shownQuartiles?: ShownQuartiles
}

const ManagerPerformanceDisplay: React.FC<IndividualComponentProps<ReportManagerPerformanceFetchFragment>> = (props) => {
  const {componentNumber, settings, report, setReportState, overwriteDate, editMode, selected, handleSelect, component, sectionNumber, setEditedDraftLayout, portfolioId, instanceType} = props
  const [initSettings, setInitSettings] = useState(settings)
  const reportState = props.reportState as componentState
  const usedDate = overwriteDate || settings.date
  const currentDate = moment(usedDate, DATE_API_FORMAT)
  const draftView = props.view === ReportDisplayType.Draft

  const primaryTab = {label: currentDate.format("[Q]Q YYYY"), value: usedDate, primary: true}
  const tabs = sortBy([primaryTab, ...(settings.monthlyOptions?.dates?.map((date) => ({label: moment(date).format("MMM YYYY"), value: date})) || [])] as ReportTab[], (tab) => (tab.primary ? 0 : 1) - moment(tab.value).valueOf() )
  const mostRecentTab = tabs[0]

  const {fetchTab, storeTab} = useStoredTab(component.id, tabs)

  const tab = reportState?.tab || mostRecentTab
  const searchDate = tab?.value || usedDate

  const setTab = (value:any) => {
    storeTab(value)
    setReportState({tab: value})
  }

  useEffect(() => {
    const storedTab = fetchTab()
    if (storedTab && storedTab.value !== usedDate) {
      setReportState({ tab: storedTab })
    } else {
      setReportState({tab: mostRecentTab})
    }
  }, [])

  useEffect(()=>{
    if(editMode === false && initSettings !== settings) setInitSettings(settings)
  }, [editMode])

  useEffect(() => {
    if (!tabs.some((t) => t.value === searchDate) || !settings.monthlyOptions?.show) {
      setTab(mostRecentTab)
    }
  }, [settings])

  const usePreview = (initSettings !== settings)
  const useNew = component.id < 1

  let usedSettings = {}
  if(usePreview || useNew) {
    let convertedSettings = excludePropertyArray(convertComponentForMutation(settings, ComponentType.ManagerPerformance), ["__typename", "timeSpan"])
    if(!tab.primary){
      convertedSettings["groupMedians"] = false
    }
    usedSettings = {
      managerPerformance: {...convertedSettings, date: searchDate}
    }
  }

  const baseQueryVariables:ReportManagerPerformanceComponentQueryVariables = {
    id: props.component.id,
    liveView: (props.view === ReportDisplayType.Live) && !usePreview,
    draftView: (props.view === ReportDisplayType.Draft) && !usePreview,
    preview: usePreview,
    settings: usedSettings,
  }

  if (!tab.primary) {
    baseQueryVariables.date = tab.value
  }

  const {loading: baseLoading, data: baseData, error: baseError, stopPolling: baseStopPolling, startPolling: baseStartPolling, refetch: baseRefetch} = useReportManagerPerformanceComponentQuery({
    variables: baseQueryVariables,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    onCompleted: () => {
      // setRefreshing(false)
    },
    skip: useNew
  })

  const { loading: newLoading, data: newData, error: newError, stopPolling: newStopPolling, startPolling: newStartPolling, refetch: newRefetch } = useNewManagerPerformanceDataQuery({
    variables: {settings: usedSettings},
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    skip: !useNew,
  })

  let loading, error, refetch, startPolling
  if(useNew) {
    loading = newLoading
    error = newError
    refetch = newRefetch
    startPolling = newStartPolling
  } else {
    loading = baseLoading
    error = baseError
    refetch = baseRefetch
    startPolling = baseStartPolling
  }

  const {data: userData} = useMeReportQuery()

  const allDone = (usedData: ReportManagerPerformanceDataFragment):boolean => {
    const singleRowLoading = (row: ReportManagerPerformanceDataRowFragment) => {
      return some(row?.columnData as ReportColumnDataFragment[], (dataPoint) => {
        return dataPoint?.status === DataPointStatus.LOADING
      })
    }

    const anyRowsLoading = (rows: ReportManagerPerformanceDataRowFragment[]):boolean => {
      return some(rows || [], (row) => {
        return singleRowLoading(row) ||
          (row.benchmarks && row.benchmarks?.length > 0 && anyRowsLoading(compact(row.benchmarks))) ||
          ("children" in row && anyRowsLoading(compact((row as any).children)))
      })
    }

    return !anyRowsLoading((usedData?.rows || []) as ReportManagerPerformanceDataRowFragment[])
  }

  let usedData = baseData?.components?.[0]?.draftData as ReportManagerPerformanceDataFragment
  if(useNew && newData?.componentPreviewData) {
    usedData = newData?.componentPreviewData as ReportManagerPerformanceDataFragment
  } else if (usePreview && baseData?.components?.[0]?.previewData){
    usedData = baseData?.components?.[0]?.previewData as ReportManagerPerformanceDataFragment
  } else if (props.view === ReportDisplayType.Live){
    usedData = baseData?.components?.[0]?.liveData as ReportManagerPerformanceDataFragment
  }

  // Stop polling for the other type
  if(useNew){
    baseStopPolling()
  } else {
    newStopPolling()
  }

  if(usedData && allDone(usedData) && !loading || error){
    if(useNew){
      newStopPolling()
    } else {
      baseStopPolling()
    }
  }

  const asOfDate = moment(tab.value).format(DATE_DISPLAY_FORMAT)

  const baseHolderValues = {name: props.component.name || "", rightText: `As of ${asOfDate}`, tabs: settings?.monthlyOptions?.show ? tabs : undefined, currentTab: tab, onTabChange: (tab:ReportTab) => setTab(tab), componentNumber, editMode, selected, onClick: handleSelect, sectionNumber, setEditedDraftLayout, portfolioId, instanceType}

  if(error && !loading){
    return <ErrorComponent error={error?.message} {...baseHolderValues}/>
  }

  if(usedData && userData?.me?.id){
    return (
      <ManagerPerformanceDisplayWithId
        key={component.id}
        refetchId={refetch}
        {...props}
        tab={tab}
        tabs={tabs}
        draftView={draftView}
        setTab={setTab}
        user={userData?.me}
        usedDate={usedDate}
        data={usedData as ReportManagerPerformanceDataFragment}
        startPolling={startPolling}
      />
    )
  }
  return <LoadingComponent {...baseHolderValues}/>
}

type ManagerPerformanceDisplayWithIdProps = IndividualComponentProps<ReportManagerPerformanceFetchFragment> & {
  // orderId: number
  refetchId: () => Promise<ApolloQueryResult<ReportManagerPerformanceComponentQuery>> | Promise<ApolloQueryResult<NewManagerPerformanceDataQuery>>
  tab: ReportTab
  tabs: ReportTab[]
  draftView: boolean
  setTab: (value:any) => void
  setReportState: (value: componentState) => void
  reportState: componentState
  user: MeReportFragment
  usedDate: string
  data: ReportManagerPerformanceDataFragment
  startPolling: (pollInterval: number) => void
}

export type ShownQuartiles = {
  top: boolean
  second: boolean
  third: boolean
  bottom: boolean
  showRankings: boolean
}

const QuartileMapping = {
  top: Quartile.TOP_QUARTILE,
  second: Quartile.SECOND_QUARTILE,
  third: Quartile.THIRD_QUARTILE,
  bottom: Quartile.BOTTOM_QUARTILE,
}

const ManagerPerformanceDisplayWithId: React.FC<ManagerPerformanceDisplayWithIdProps> = ({component, auth, report, settings, selected, handleSelect, refetchId, tab, tabs, draftView, setTab, editMode, sectionNumber, componentNumber, setEditedDraftLayout, editedDraftLayout, setSelectedComponentId, view, setReportState, reportState, user, hideSingleExport, clientId, usedDate, portfolioId, instanceType, data, startPolling}) => {
  let name = component.name || ""
  const openItems = reportState?.openItems || []
  const setOpenItems = (value:(number | string)[]) => {
    setReportState({openItems: value})
  }
  const [refreshing, setRefreshing] = useState(false)
  const [optionModalOpen, setOptionModalOpen] = useState(false)
  const asOfDate = moment(tab.value).format(DATE_DISPLAY_FORMAT)
  const shownQuartiles = reportState?.shownQuartiles || {top: true, second: true, third: true, bottom: true, showRankings: true}
  const [quartilesCopy, setQuartilesCopy] = useState<ShownQuartiles>(shownQuartiles)
  const searchDate = tab?.value || usedDate
  const [updateUserMetadata] = useUpdateUserMetadataMutation()
  const [deletePerformanceOrder] = useDeletePerformanceOrderMutation()

  const setShownQuartiles = (value:ShownQuartiles) => {
    setReportState({shownQuartiles: value})
    updateUserMetadata({variables: {
      input: {
        id: user.id,
        patch: {
          showPortfolioRankings: value.showRankings,
          visibleQuartiles: compact(Object.keys(QuartileMapping).map((quartile) => {
            if(value && value[quartile as keyof ShownQuartiles]) return QuartileMapping[quartile as keyof typeof QuartileMapping]
            return undefined
          })) as Quartile[]
        }
      }
    }})
  }

  useEffect(()=>{
    if(!reportState.openItems) setReportState({openItems: openItems})
    if(!reportState.shownQuartiles) setReportState({shownQuartiles: shownQuartiles})
  }, [])

  useEffect(()=>{
    setQuartilesCopy(shownQuartiles)
  }, [shownQuartiles])


  const submitOptions = () => {
    setShownQuartiles(quartilesCopy)
    setOptionModalOpen(!optionModalOpen)
  }

  const toggleOpenModal = () => {
    setOptionModalOpen(!optionModalOpen)
    setQuartilesCopy(shownQuartiles)
  }
//   if(!data && loading) return <LoadingComponent name={name} rightText={`As of ${asOfDate}`} tabs={settings?.monthlyOptions?.show ? tabs : undefined} currentTab={tab} onTabChange={(tab) => setTab(tab)} componentNumber={componentNumber} setEditedDraftLayout={setEditedDraftLayout}/>
//   if(error) return <ErrorComponent name={name} error={error?.message} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>

//   if(data && data.list){
//     let [mappedData, totalData] = splitReportList(data.list as ReportsListFragment, settings.totalFundPid || undefined)
    let allOpenableItems = expandableDataList(data?.rows || [])
    const allExpanded = isEqual(intersection(allOpenableItems, openItems), allOpenableItems)

    let tooltipProps:ToolbarProps = {
      refreshFunction: () => {
        if(data.orderId){
          setRefreshing(true)
          deletePerformanceOrder({
            variables: { input: { performanceOrderID: data.orderId } },
          })
          .then(() => {
            refetchId().then(() => {
              startPolling(5000)
            })
          })
        }
      },
      openOptionsModal: (settings.rankings && tab.primary) ?() => toggleOpenModal() : undefined,
    }

    const optionsModal = (
      <OptionsModal show={optionModalOpen} setShow={toggleOpenModal} onSubmit={submitOptions}>
        <div className='d-flex ml-4'>
          <QuartileDisplay quartile="top" className='mt-2 pr-1'/>
          <FormInput
            key={"top-quartile"}
            property={"top-quartile"}
            displayName={"Top Quartile"}
            type={"checkbox"}
            subtype={"show"}
            idx={1}
            editMode={true}
            propertyVal={quartilesCopy.top}
            updateValue={(value) => setQuartilesCopy({
              ...quartilesCopy,
              top: value,
            })}
            subClasses={{wrapperClasses: "no-gutters w-100", labelClasses: "col-sm-8", inputWrapperClasses: "col-sm-4"}}
          />
        </div>
        <div className='d-flex ml-4'>
          <QuartileDisplay quartile="second" className='mt-2 pr-1'/>
          <FormInput
            key={"second-quartile"}
            property={"second-quartile"}
            displayName={"2nd Quartile"}
            type={"checkbox"}
            subtype={"show"}
            idx={2}
            editMode={true}
            propertyVal={quartilesCopy.second}
            updateValue={(value) => setQuartilesCopy({
              ...quartilesCopy,
              second: value,
            })}
            subClasses={{wrapperClasses: "no-gutters w-100", labelClasses: "col-sm-8", inputWrapperClasses: "col-sm-4"}}
          />
        </div>
        <div className='d-flex ml-4'>
          <QuartileDisplay quartile="third" className='mt-2 pr-1'/>
          <FormInput
            key={"third-quartile"}
            property={"third-quartile"}
            displayName={"3rd Quartile"}
            type={"checkbox"}
            subtype={"show"}
            idx={3}
            editMode={true}
            propertyVal={quartilesCopy.third}
            updateValue={(value) => setQuartilesCopy({
              ...quartilesCopy,
              third: value,
            })}
            subClasses={{wrapperClasses: "no-gutters w-100", labelClasses: "col-sm-8", inputWrapperClasses: "col-sm-4"}}
          />
        </div>
        <div className='d-flex ml-4'>
          <QuartileDisplay quartile="bottom" className='mt-2 pr-1'/>
          <FormInput
            key={"bottom-quartile"}
            property={"bottom-quartile"}
            displayName={"Bottom Quartile"}
            type={"checkbox"}
            subtype={"show"}
            idx={4}
            editMode={true}
            propertyVal={quartilesCopy.bottom}
            updateValue={(value) => setQuartilesCopy({
              ...quartilesCopy,
              bottom: value,
            })}
            subClasses={{wrapperClasses: "no-gutters w-100", labelClasses: "col-sm-8", inputWrapperClasses: "col-sm-4"}}
          />
        </div>
        <div className='d-flex ml-4'>
          <FormInput
            key={"portfolio-ranking"}
            property={"portfolio-ranking"}
            displayName={"Portfolio Rankings"}
            type={"checkbox"}
            subtype={"show"}
            idx={5}
            editMode={true}
            propertyVal={quartilesCopy.showRankings}
            updateValue={(value) => setQuartilesCopy({
              ...quartilesCopy,
              showRankings: value,
            })}
            subClasses={{wrapperClasses: "no-gutters w-100", labelClasses: "col-sm-8", inputWrapperClasses: "col-sm-4 pl-2"}}
          />
        </div>
      </OptionsModal>
    )

    const rightText = (
      <div className='d-flex'>
        As of {asOfDate}
      </div>
    )

    const baseHolderValues = {name, rightText: `As of ${asOfDate}`, tabs: settings?.monthlyOptions?.show ? tabs : undefined, currentTab: tab, onTabChange: (tab:ReportTab) => setTab(tab), componentNumber, editMode, selected, onClick: handleSelect, sectionNumber, setEditedDraftLayout, editedDraftLayout, portfolioId, instanceType}

    if(componentNumber === -1 && component.exportTypes?.includes(ComponentExportType.PPTX)){
      tooltipProps['exportOptions'] = {
        name: name,
        slides: [{
          title: name,
          sections: [{
            components: [component.id],
            type: LayoutSectionType.SingleColumnSection
          }]
        }],
        settings: {
          live: view === ReportDisplayType.Live,
          footerName: report?.exportSettings?.footerName || report?.client?.name,
          componentOverrideSettings: {
            managerPerformance: [
              {
                componentId: component.id,
                expandedRows: openItems.map((id) => id.toString()),
                selectedDate: tab.primary ? undefined : tab.value,
                visibleQuartiles: compact(Object.keys(QuartileMapping).map((quartile) => {
                  if(shownQuartiles && shownQuartiles[quartile as keyof ShownQuartiles]) return QuartileMapping[quartile as keyof typeof QuartileMapping]
                  return undefined
                })) as Quartile[],
                showPortfolioRankings: shownQuartiles?.showRankings,
              }
            ]
          }
        }
      }
    }
    if(componentNumber === -1 && component.exportTypes?.includes(ComponentExportType.XLSX)){
      tooltipProps['exportExcelOptions'] = {
        name: name,
        componentId: component.id,
        settings: {
          live: !draftView,
          componentOverrideSettings: {
            managerPerformance:
            {
              selectedDate: tab.primary ? undefined : tab.value,
              expandedRows: openItems.map((id) => id.toString()),
            }
          }
        }
      }
    }

    return (
      <TemplateComponent
        componentTypeName='Manager Performance'
        tooltipProps={tooltipProps}
        report={report}
        componentId={component.id}
        setSelectedComponentId={setSelectedComponentId}
        view={view}
        auth={auth}
        hideExport={hideSingleExport}
        {...baseHolderValues}
      >
        {optionsModal}
        <Table hover className={classNames("report-table", {"table-has-rankings": settings?.rankings})}>
          <thead>
            <tr className="row-border-olive-100">
              <th className="text-left">
                Portfolio
                <div
                  className="fake-link d-inline-block ml-2"
                  onClick={() =>
                    allExpanded
                      ? setOpenItems([])
                      : setOpenItems(allOpenableItems)
                  }
                >
                  {allExpanded ? "Collapse All" : "Expand All"}
                </div>
              </th>
              {data.columns?.map((heading, idx) => {
                  return (
                    <th className="text-right" key={idx}>
                      {heading?.text}
                    </th>
                  )
              })}
            </tr>
          </thead>
          <tbody>
            {data.rows?.map((row: Maybe<ReportManagerPerformanceDataRowFragment>, idx) => {
              if(!row) return <React.Fragment key={idx} />
              return (
                <ManagerPerformanceRow
                  key={idx}
                  openItems={openItems}
                  currentHierarchy={[]}
                  setOpenItems={setOpenItems}
                  settings={settings}
                  searchDate={searchDate}
                  refreshing={refreshing}
                  draftView={draftView}
                  shownQuartiles={shownQuartiles}
                  primaryTab={!!tab.primary}
                  report={report}
                  view={view}
                  clientId={clientId}
                  row={row}
                  columns={data.columns || []}
                />
              )
            })}
          </tbody>
        </Table>
      </TemplateComponent>
    )
  // }

  return <ErrorComponent error={"No data returned"} {...baseHolderValues}/>
}

interface ManagerPerformanceRowProps {
  currentHierarchy: (number | string)[]
  settings: ReportManagerPerformanceFetchFragment
  openItems: (number | string)[]
  setOpenItems: (newOpenItems: (number | string)[]) => void
  searchDate: string
  refreshing: boolean
  draftView: boolean
  shownQuartiles: ShownQuartiles
  primaryTab: boolean
  report?: ReportsFragment
  view: ReportDisplayType
  clientId?: number
  row: ReportManagerPerformanceDataRowFragment
  columns: ReportManagerPerformanceDataFragment["columns"]
}

const ManagerPerformanceRow: React.FC<ManagerPerformanceRowProps> =({ currentHierarchy, openItems, setOpenItems, settings, searchDate, refreshing, draftView, shownQuartiles, primaryTab, report, clientId, view, row, columns}) => {
  let children = (row as any)?.children as ReportManagerPerformanceDataRowFragment[]
  const hasTargets = !!row.benchmarks && row.benchmarks.length > 0
  const hasSubgroup = !!children && children.length > 0
  const expandable = hasSubgroup
  const depth = currentHierarchy.length
  const isOpen = openItems.includes(row?.rowId || "")
  let showBoth = row.benchmarks?.some((child) => child?.feeType === FeeType.NET)

  const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(!expandable) return
    if(isOpen){
      let removedArray = clone(openItems)
      remove(removedArray, (id) => row.rowId === id)
      setOpenItems(removedArray)
    } else {
      setOpenItems(compact([...openItems, row.rowId]))
    }
  }

  const rowDisplay = (row: ReportManagerPerformanceBaseDataRowFragment, benchmark: boolean, lastSubtitle?: boolean) =>{
    let links: FootnoteLink[] = []
    if(!row.header){
      links = getPortfolioRowLinks({componentId: row.rowId, reportId: report?.id, planId: first(report?.plans)?.id, clientId, view, productId: row.productId || undefined})
    }
    return (
      <tr key={row?.rowId} className={classNames({
        "report-tr-subgroup": (depth > 0 || (benchmark || (showBoth && row.feeType === FeeType.NET))),
        "has-subtitle": (hasTargets || (showBoth && row.feeType === FeeType.GROSS)),
        "report-tr-total": row.total,
        "report-tr-both-bottom": showBoth && row.feeType === FeeType.NET,
        "report-tr-subtitle": benchmark && row.feeType !== FeeType.NET,
        "report-tr-title bold-data": !benchmark || (showBoth && row.feeType === FeeType.NET),
        "report-tr-subtitle-last": lastSubtitle,
        "report-tr-group": row.group,
      })}>
        <td className="text-left" onClick={(e) => handleExpand(e)}>
          <div className={classNames("report-table-item", {"has-subgroup": expandable})}>
            <div style={{marginLeft: depth * 10}} className="d-flex">
              <div className={`report-table-caret report-table-depth-${depth} `}>
                {expandable && !benchmark &&
                  <FontAwesomeIcon
                    icon={isOpen ? "caret-down" : "caret-right"}
                    size="sm"
                  />
                }
              </div>
              <div>
                <div className={classNames("report-table-title", {"report-table-total": row.total})}>
                  <FootnotableComponent
                    preDefinedFootnote={row.footnote}
                    errors={row.errors}
                    showErrors={draftView}
                    warnings={row.warnings}
                    showWarnings={draftView}
                    selectedDate={searchDate}
                    links={links}
                  >
                    {row.name}
                    {row.showFeeBadge &&
                      <span className={classNames("ml-2 badge text-uppercase text-text-color", "badge-pill", { "background-blue-30": row.feeType === FeeType.NET, "background-olive-10": row.feeType === FeeType.GROSS })}>
                        {row.feeType === FeeType.NET ? "Net" : "Gross"}
                      </span>
                    }
                  </FootnotableComponent>
                </div>
              </div>
            </div>
          </div>
        </td>
        {columns && columns?.map((column, idx) => {
          // const column = columns[idx]
          let dataPoint = undefined
          if(row.columnData && row.columnData[idx]){
            dataPoint = row.columnData[idx]
          }
          return(
            <ReportDataPoint
              key={idx}
              dataPoint={dataPoint || undefined}
              column={column || undefined}
              draftView={draftView}
              shownQuartiles={shownQuartiles}
              emptyValue={(column?.text === "1 Month" && row.group) ? "" : undefined}
            />
          )
        })}
      </tr>
    )
  }

  return (
    <>
      {rowDisplay(row, false)}
      {row.benchmarks?.map((benchmark, idx) => {
        if(benchmark){
          return <React.Fragment key={idx}>{rowDisplay(benchmark, true, row.benchmarks?.length === idx + 1)}</React.Fragment>
        } else {
          return <React.Fragment key={idx}></React.Fragment>
        }
      })}
      {isOpen && hasSubgroup && children.map((row: Maybe<ReportManagerPerformanceDataRowFragment>, idx) => {
        if(!row) return <React.Fragment key={idx} />
        return(
          <ManagerPerformanceRow
            key={idx}
            openItems={openItems}
            currentHierarchy={[...currentHierarchy, row.rowId]}
            setOpenItems={setOpenItems}
            settings={settings}
            searchDate={searchDate}
            refreshing={refreshing}
            draftView={draftView}
            shownQuartiles={shownQuartiles}
            primaryTab={primaryTab}
            report={report}
            view={view}
            clientId={clientId}
            row={row}
            columns={columns}
          />
        )
      })}
    </>
  )
}

// EDIT STARTS HERE
const ManagerPerformanceInfoFields = (handleInputChange: (value: any, property: string) => void):ReportEditField[] => {
  return [
    {
      property: "name",
      label: "Name",
      type: "text",
      required: true,
    },
    // {
    //   property: "approval",
    //   label: "Approval",
    //   type: "select",
    // },
    {
      property: "date",
      label: "As of date",
      type: "date",
      subtype: "quarter",
      required: true,
      afterChange: (value:any)=> {
        handleInputChange([], "monthlyOptions.dates")
      },
    },
    {
      property: "list.id",
      label: "List",
      type: "select",
      required: true,
    }
  ]
}

const ManagerPerformanceReportingFields = (settings: ReportManagerPerformanceFetchFragment, handleInputChange: (value: any, property: string) => void):ReportEditField[] => {
  const isQuarter = [2,5,8,11].includes(moment(settings.date, DATE_API_FORMAT).month())
  return [
    {
      property: "performancePeriodicity",
      label: "Returns used",
      type: "select",
      optionSource: "ManagerPerformancePeriodicity",
      optionFilterRule: isQuarter ? undefined : {"Monthly": true},
      required: true,
      afterChange: (value:any)=> {
        handleInputChange([], "monthlyOptions.dates")
        handleInputChange(false, "monthlyOptions.show")
        handleInputChange(false, "managerPerformancePeriod.lastMonth")
      },
    },
    {
      property: "actualPerformance",
      label: "Actual Portfolio Performance Only",
      type: "radio",
      subtype: "boolean",
      required: true,
    },
    {
      property: "performanceType",
      label: "Performance type",
      type: "select",
      optionSource: "ManagerPerformanceType",
      required: true,
    },
  ]
}

const ManagerPerformanceTableTopFields:ReportEditField[] = [
  {
    property: "rankings",
    label: "Rankings",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "groupMedians",
    label: "Group Medians",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
]

const ManagerPerformanceReportingPeriodFields:ReportEditField[] = [
  {
    property: "reportingPerformancePeriod",
    label: "",
    type: "radio",
    options: [{
      code: "Trailing",
      value: "Trailing",
    },{
      code: "CalendarYears",
      value: "Calendar Year",
    },{
      code: "FiscalYears",
      value: "Fiscal Year",
    }],
    subClasses: {
      inputWrapperClasses: "col-sm-12 radio-input-with-divider mt-1 mb-2 w-100",
    },
  },
]

const ManagerPerformanceTableFields:ReportEditField[] = [
  {
    property: "managerPerformancePeriod.lastQuarter",
    label: "1 Quarter",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.yearToDate",
    label: "Year to date (YTD)",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.fiscalYear",
    label: "Fiscal YTD",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.last1Year",
    label: "1 Year",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.last2Years",
    label: "2 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.last3Years",
    label: "3 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.last5Years",
    label: "5 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.last7Years",
    label: "7 Years",
    type: "checkbox",
    subtype: "show",
  },
  {
    property: "managerPerformancePeriod.last10Years",
    label: "10 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.last15Years",
    label: "15 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.last20Years",
    label: "20 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "managerPerformancePeriod.sinceInception",
    label: "Since Inception",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
]

const ManagerPerformanceYearsFields:ReportEditField[] = [
  {
    property: "numberOfYearsToInclude",
    label: "Years to include",
    type: "select",
    subtype: "single",
    options: [{
      code: "1",
      value: " 1",
    },{
      code: "2",
      value: " 2",
    },{
      code: "3",
      value: " 3",
    },{
      code: "4",
      value: " 4",
    },{
      code: "5",
      value: " 5",
    },{
      code: "6",
      value: " 6",
    },{
      code: "7",
      value: " 7",
    },{
      code: "8",
      value: " 8",
    },{
      code: "9",
      value: " 9",
    },{
      code: "10",
      value: "10",
    }],
    required: true,
  },
]

const FiscalMonthEndFields:ReportEditField[] = [
  {
    property: "plan.fiscalMonth",
    label: "Fiscal Month End",
    type: "text",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "managerPerformanceFiscalMonthEndTooltip",
    },
  },
]

const getColumnsByPeriod = (settings: ReportManagerPerformanceFetchFragment) => {
  let result = ManagerPerformanceTableFields
  switch (settings.reportingPerformancePeriod) {
    case ReportingPerformancePeriod.CalendarYears:
      result = ManagerPerformanceYearsFields
      break
    case ReportingPerformancePeriod.FiscalYears:
      result = [...ManagerPerformanceYearsFields, ...FiscalMonthEndFields]
      break
    default: // ReportingPerformancePeriod.Trailing
      break
  }
  return result
}



export const ManagerPerformanceEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, report}) => {
  const settings = component.draftSettings as ReportManagerPerformanceFetchFragment

  const { data: listData } = useListSimpleQuery({
    variables: {id: settings.list?.id || 0},
    skip: !settings || !settings.list?.id,
    errorPolicy: "all",
  })
  if(!settings){
    return(
      <></>
    )
  }

  const ownedComponent = component.owner?.id === user?.person?.id
  let infoColumns = [...ManagerPerformanceInfoFields(handleInputChange), ...ReportEditInfoTotalField(listData?.list || undefined)]
  let tableColumns = [...ManagerPerformanceTableTopFields]
  const useTrailing = !settings.reportingPerformancePeriod || settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing
  let reportingPeriodColumns = [...ManagerPerformanceReportingPeriodFields, ...getColumnsByPeriod(settings)]
  if(settings?.performancePeriodicity === ManagerPerformancePeriodicity.Monthly && useTrailing){
    reportingPeriodColumns = [...ManagerPerformanceReportingPeriodFields,{
      property: "managerPerformancePeriod.lastMonth",
      label: "1 Month",
      type: "checkbox",
      subtype: "show",
    }, ...((!settings.reportingPerformancePeriod || settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing) ? ManagerPerformanceTableFields : ManagerPerformanceYearsFields)]
  }
  return (
    <>
      <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={"Manager Performance"}
        componentType={ComponentType.ManagerPerformance}
        reportId={report.id}
        handleInputChange={handleInputChange}
      />
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <ReportEditInstances component={component}/>
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      />
      <SimpleReportEdit
        name={"Performance"}
        retractable={true}
        fields={ManagerPerformanceReportingFields(settings, handleInputChange)}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <AdditionalReportingEdit
        handleInputChange={handleInputChange}
        currentState={settings}
        hideMonthly={settings?.performancePeriodicity !== ManagerPerformancePeriodicity.Monthly}
      />
      <SimpleReportEdit
        name={"Reporting Period"}
        retractable={true}
        fields={reportingPeriodColumns}
        handleInputChange={(value, property) => {
          if(property === "numberOfYearsToInclude"){
            handleInputChange(parseInt(value), property)
          } else if (property === "reportingPerformancePeriod") {
            if(!get(settings, "numberOfYearsToInclude")) handleInputChange(5, "numberOfYearsToInclude")
            handleInputChange(value, property)
          } else {
            handleInputChange(value, property)
          }
        }}
        currentState={{...settings, plan: (report?.plans ? report?.plans[0] : null)} as any}
      />
      <SimpleReportEdit
        name={"Table"}
        retractable={true}
        fields={tableColumns}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"footnotes"}
        retractable={true}
        fields={ReportEditFootnoteFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
    </>
  )
}

export const ManagerPerformanceAfterPublish: React.FC<AggregatedAfterPublishProps> = (props: AggregatedAfterPublishProps) =>{
//   const { component, report, publishCount } = props
//   const settings = (component.liveSettings || component.draftSettings) as ReportManagerPerformanceFetchFragment
//   const [deletePerformanceOrder] = useDeletePerformanceOrderMutation()

//   const performanceMonthly = settings?.performancePeriodicity === "Monthly"
//   const searchDate = settings?.date
//   const { queryMonthlyVariables, queryQuarterlyVariables, yearsMonthlyVariables, yearsQuarterlyVariables } = getManagerPerformanceQueryVariables({settings, report, searchDate})
//   const useTrailing = !settings.reportingPerformancePeriod || settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing

//   let request

//   if(useTrailing){
//     request = performanceMonthly ?
//       JSON.stringify({variables: queryMonthlyVariables, queryHash: stringHash(JSON.stringify(REPORT_MANAGER_PERFORMANCE_MONTH_TRAILING_DETAIL_QUERY))}) :
//       JSON.stringify({variables: queryQuarterlyVariables, queryHash: stringHash(JSON.stringify(REPORT_MANAGER_PERFORMANCE_QUARTER_TRAILING_DETAIL_QUERY))})
//   } else {
//     request = performanceMonthly ?
//       JSON.stringify({variables: yearsMonthlyVariables, queryHash: stringHash(JSON.stringify(REPORT_MANAGER_PERFORMANCE_MONTH_YEARS_DETAIL_QUERY))}) :
//       JSON.stringify({variables: yearsQuarterlyVariables, queryHash: stringHash(JSON.stringify(REPORT_MANAGER_PERFORMANCE_QUARTER_YEARS_DETAIL_QUERY))})
//   }

//   const [performanceOrder] = usePerformanceOrderIdLazyQuery({
//     variables: {
//       request: request
//     },
//     onCompleted: (data) => {
//       if(data.performanceOrderID){
//         deletePerformanceOrder({
//           variables: {
//             input: {performanceOrderID: data.performanceOrderID}
//           }
//         })
//       }
//     }
//   })

//   useEffect(() => {
//     if(publishCount > 0){
//       performanceOrder()
//     }
//   }, [publishCount])

  return (<></>)
}

export const ManagerPerformanceExportSettings = (props: AggregatedExportSettingsProps): ComponentOverrideSettings =>  {
  const { component } = props
  const reportState = props.reportState as componentState
  const tab = reportState?.tab
  const openItems = reportState?.openItems || []
  const shownQuartiles = reportState?.shownQuartiles

  return { managerPerformance: [{
    componentId: component.id,
    expandedRows: openItems.map((id) => id.toString()),
    selectedDate: tab?.primary ? undefined : tab?.value,
    visibleQuartiles: compact(Object.keys(QuartileMapping).map((quartile) => {
      if(shownQuartiles && shownQuartiles[quartile as keyof ShownQuartiles]) return QuartileMapping[quartile as keyof typeof QuartileMapping]
      return undefined
    })) as Quartile[],
    showPortfolioRankings: shownQuartiles?.showRankings,
  }]}
}

export default ManagerPerformance
import React from "react"
import { FormGroup, Input } from "reactstrap"
import { FormInput } from '../../../../ui/Forms/FormInput'
import { FurtherDetailsInputData, OtherFeesGenericProps } from "../FeeRows"
import { WaterfallOptions } from "../getFeeData"

interface WaterfallRowProps extends OtherFeesGenericProps {
  waterfallInput: {
    options: WaterfallOptions
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
  detailsInput: FurtherDetailsInputData<string>
}

export const WaterfallRow: React.FC<WaterfallRowProps> = ({
  label,
  editing,
  waterfallInput,
  detailsInput,
}) => {
  const waterfall = waterfallInput.options.find(
    (item) => item.code === waterfallInput.value
  )
  const waterfallDisplay = waterfall ? waterfall.value : ""
  return (
    <>
      <tr>
        <td>{label}</td>
        <td>
          {editing ? (
            <Input
              type="select"
              bsSize="sm"
              onChange={waterfallInput.onChange}
              value={waterfallInput.value}
            >
              <option value="">Select Waterfall</option>
              {waterfallInput.options.map((item) => (
                <option key={item.code} value={item.code}>
                  {item.value}
                </option>
              ))}
            </Input>
          ) : (
            waterfallDisplay
          )}
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td colSpan={4}>
          <FormInput
              key={`waterfall-alt-fee-description`}
              property={`waterfall.furtherDescription`}
              displayName={""}
              type={"textarea"}
              placeholder={`Further Details Waterfall`}
              idx={`waterfall-alt-fee-description`}
              editMode={editing}
              propertyVal={detailsInput.value}
              updateValue={detailsInput.onChange}
              subClasses={{inputWrapperClasses: "col-sm-12 alt-fee-description"}}
              showZero={true}
              charactersLimit={200}
            />
        </td>
      </tr>
    </>
  )
}

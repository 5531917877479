import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import { FormInputSubClasses } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { FormInput } from "../../ui/Forms/FormInput"

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  optionSource?: string
}

interface LongShortPolicyTableItemProps {
  data: any
  rowDefItem: RowDefItem
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (idx: number, type: string, value: any, property: string) => void
}

type LongShortPolicyTableProps = {
  data: any
  handleChange: (state: any, property: string) => void
  editMode?: boolean
}

export const LongShortPolicyTableRowDef: RowDefItem[] = [
  {
    field: "totalGrossExposure",
    type: "float",
    subtype: "percent",
    title: "Total Gross Exposure"
  },
  {
    field: "grossShortExposure",
    type: "float",
    subtype: "percent",
    title: "Gross Short Exposure"
  },
  {
    field: "grossLongExposure",
    type: "float",
    subtype: "percent",
    title: "Gross Long Exposure"
  }
]

interface LongShortPolicyTableRowProps {
  data: any
  index: number
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

const LongShortPolicyTableRow = ({
  data,
  index,
  rowDef,
  editMode,
  updateValue
}: LongShortPolicyTableRowProps) => {
  let { field, title, subClasses, type, subtype } = rowDef
  const value = _.get(data, field)
  if (_.isUndefined(value)) {
    return <tr key={`${index}`} className={"invisible"}></tr>
  }
  return (
    <tr key={`${index}`}>
      <td key={`LongShortPolicy-${field}-0`} className={"text-left"}>
        {title}
      </td>
      <td key={`LongShortPolicy-${field}-1`}>
        <FormInput
          property={field + "lsp"}
          displayName={""}
          type={type}
          subtype={subtype}
          idx={index}
          editMode={editMode}
          propertyVal={value}
          placeholder={type === "number" ? "0" : ""}
          subClasses={subClasses}
          updateValue={value => updateValue(index, value, type, field)}
        />
      </td>
    </tr>
  )
}

const LongShortPolicyTable = (seed: LongShortPolicyTableProps) => {
  const tableName = "LongShortPolicy"
  const widthClassName = "col-sm-6"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)
  useEffect(() => {
    setData(seed.data)
  }, [seed.data])

  if (!data) {
    return <div>No data</div>
  }

  const onHandleChangeTableRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData =
      getNewStateObject({
        state: tableState,
        newValue: value,
        property: `${property}`,
        type
      }) || {}

    handleChange(_.get(newData, property), property)
    setData(newData)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size w-100 py-2 mb-2"
        }
        key={`${tableName}-0`}
      >
        {`Long/Short Policy`}
      </div>
      <div
        className={`${tableName}-table ${widthClassName} row form-group pl-3`}
      >
        <Table
          striped
          hover
          borderless
          size="sm"
          key={`LongShortPolicy-table`}
          className={`table-striped exportable`}
          data-export-name={"LongShort Policy"}
        >
          <thead>
            <tr className="table-header">
              <th key={`${tableName}-header-0`}>Exposure</th>
              <th key={`${tableName}-header-1`}>Percent</th>
            </tr>
          </thead>
          <tbody key={0}>
            {LongShortPolicyTableRowDef.map((rowDef, rowIndex) => {
              return (
                <LongShortPolicyTableRow
                  index={rowIndex}
                  key={rowIndex}
                  data={tableState}
                  rowDef={rowDef}
                  editMode={!!editMode}
                  updateValue={onHandleChangeTableRow}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default LongShortPolicyTable

import React, { Component, useState, useContext, useRef, FormEvent, RefObject, useEffect } from 'react'
import _ from 'lodash'
import { CalendarContext } from "../../Context/CalendarContext"
import iassign from 'immutable-assign'
import { useHistory } from 'react-router-dom'

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import { ProductFields, ProductEmployeesQuery, useProductEmployeesQuery, useUpdateProductOverviewEmployeesMutation, UpdateProductEmployeeBreakdownsInput, useGetLookupQuery, GetLookupQuery, useEmployeeJobTypeAssetQuery, EmployeeJobTypeAssetQuery, EmployeeBreakdownDetail, Maybe, useUpdateProductOverviewEmployeesBreakdownDetailMutation, useUpdateProductOverviewEmployeesBreakdownOtherMinoritiesMutation, EmployeeBreakdownByOtherMinorityInput } from '../../__generated__/graphql'
import { convertLookupToString, excludePropertyArray } from '../../helpers/object'
import { EmployeeTable } from '../ui/EmployeeTable'
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { CalendarPicker } from '../CalendarPicker'
import PlaceHolder from '../ui/PlaceHolder'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { DATE_API_FORMAT, FormInputField } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import { EmployeeBreakdownDetailLowerInputs, EmployeeBreakdownDetailUpperInputs } from '../Org/ManagerOverviewEmployees'
import moment from 'moment'
import exportTables from '../../helpers/exportTable'

interface Props {
  setSearchDate: (searchDate:string) => void
  data: ProductEmployeesQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<Result>
  searchDate: string
  raceData: GetLookupQuery
  genderData: GetLookupQuery
  typeData: GetLookupQuery
  minorityData: GetLookupQuery
  mappingData: EmployeeJobTypeAssetQuery
  setReasonErrorMessage: (message: string) => void
}

interface idProps {
  productId: number
  auth: Auth
}

interface ProductEmployeeBreakdownDetailInputField extends FormInputField {
  displayData?: {
    property: string
    value: any
  },
  charactersLimit?: number
}

const reasonCheckPass = (state: ProductEmployeesQuery | undefined) => {
  // check for non-nullable reason code if can't provide employee count info.
  const canProvide = _.get(state as ProductEmployeesQuery, "product.product.employeeBreakdownDetail.canProvideDiversityGender")
  const provideCode = !!_.get(state, "product.product.employeeBreakdownDetail.reason.code")
  return canProvide || provideCode
}

const REASON_ERROR_MESSAGE = `Please select a reason why diversity or gender breakdown cannot be provided.`

/**
 * query ProductOverviewEmployee by managerId, return editable form
 * @param {id:number} idProps for query ManagerSummary
 * **/
const ProductOverviewEmployee: React.FC<idProps> = ({ productId, auth }: idProps) => {
  const context = useContext(CalendarContext)
  // CAL-2190 year-end-date
  const [searchDate, setSearchDate] = useState(context.year)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const resultRef = useRef<Result>(null)
  const history = useHistory()
  const [updateEmployees] = useUpdateProductOverviewEmployeesMutation()
  const [updateEmployeesBreakdownDetail] = useUpdateProductOverviewEmployeesBreakdownDetailMutation()
  const [updateOtherMinorities] = useUpdateProductOverviewEmployeesBreakdownOtherMinoritiesMutation()
  const [reasonErrorMessage, setReasonErrorMessage] = useState("")
  let { data, loading, error, refetch } =
  useProductEmployeesQuery({
    variables: {
      id: productId,
      startDate: searchDate,
      endDate: searchDate
    }
  })
  const { loading: raceLoading, error: raceError, data: raceData } = useGetLookupQuery({
    variables: { name: "EmployeeRaceType" }
  })
  const { loading: genderLoading, error: genderError, data: genderData } = useGetLookupQuery({
    variables: { name: "EmployeeGenderType" }
  })
  const { loading: typeLoading, error: typeError, data: typeData } = useGetLookupQuery({
    variables: { name: "EmployeeType" }
  })
  const { loading: reasonLoading, error: reasonError, data: reasonData } = useGetLookupQuery({
    variables: { name: "EmployeeReasonCode" }
  })
  const { loading: minorityLoading, error: minorityError, data: minorityData } = useGetLookupQuery({
    variables: { name: "EmployeeOtherMinorityType" }
  })
  const { loading: mappingLoading, error: mappingError, data: mappingData } = useEmployeeJobTypeAssetQuery({})

  const [forceUpdateFlag, setForceUpdateFlag] = useState<boolean>(false)

  const handleEdit = () => {
    setReasonErrorMessage("")
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  useEffect(() => {
    // force refetch after saving.
    if(!editMode && forceUpdateFlag && refetch) {
      refetch()
    }
  },[editMode])

  
  const onSubmit = () => {
    let checkPassFlag = reasonCheckPass(resultRef!.current?.state?.currentState)
    if(checkPassFlag) {
      handleSubmit()
    }else {
      setReasonErrorMessage(REASON_ERROR_MESSAGE)
      return
    }
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    let currentProduct = resultRef!.current?.state.currentState.product?.product
    let initialProduct = resultRef!.current?.state.initialState.product?.product
    if(!currentProduct || !currentProduct.employeeTurnover || !currentProduct.employeeBreakdownByGender || !currentProduct.employeeBreakdownByDiversityProfile ){
      return
    }
    if(!initialProduct){
      return
    }
    setReasonErrorMessage("")
    setSaving(true)
    setForceUpdateFlag(true)
    let product = initialProduct
    let employeeTurnover = currentProduct.employeeTurnover.filter(x => {
      if(product.employeeTurnover){
        return !_.find(product.employeeTurnover, (o) => JSON.stringify(o) == JSON.stringify(x))
      }
      return true
    })
    let employeeBreakdownByGender = currentProduct.employeeBreakdownByGender.filter(x => {
      if(product.employeeBreakdownByGender){
        return !_.find(product.employeeBreakdownByGender, (o) => JSON.stringify(o) == JSON.stringify(x))
      }
      return true
    })
    let employeeBreakdownByDiversityProfile = currentProduct.employeeBreakdownByDiversityProfile.filter(x => {
      if(product.employeeBreakdownByDiversityProfile){
        return !_.find(product.employeeBreakdownByDiversityProfile, (o) => JSON.stringify(o) == JSON.stringify(x))
      }
      return true
    })

    const formattedEmployeeTurnover = convertLookupToString(employeeTurnover, false)
    const formattedEmployeeBreakdownByGender = convertLookupToString(employeeBreakdownByGender, false)
    const formattedEmployeeBreakdownByDiversityProfile = convertLookupToString(employeeBreakdownByDiversityProfile, false)

    const patchList = {
      employeeTurnover: _.map(formattedEmployeeTurnover, (fp) => {return excludePropertyArray(fp, ["__typename"])}),
      employeeBreakdownByGender: _.map(formattedEmployeeBreakdownByGender, (fp) => {return excludePropertyArray(fp, ["__typename"])}),
      employeeBreakdownByDiversityProfile: _.map(formattedEmployeeBreakdownByDiversityProfile, (fp) => {return excludePropertyArray(fp, ["__typename"])}),
    }

    const updateData = {
      id: currentProduct.id,
      patch: patchList
    } as UpdateProductEmployeeBreakdownsInput

    const {employeeBreakdownDetail, employeeBreakdownByOtherMinority} = currentProduct
    const formattedEmployeeBreakdownDetail = convertLookupToString(employeeBreakdownDetail || {}, false)
    const updateEmployeeBreakdownDetailData = {
      id: currentProduct.id,
      patch: excludePropertyArray(formattedEmployeeBreakdownDetail, ["__typename"])
    }
    const formattedEmployeeBreakdownByOtherMinority = convertLookupToString(employeeBreakdownByOtherMinority || [], false)
    const updateOtherMinorityData = {
      id: currentProduct.id,
      patch: _.map(formattedEmployeeBreakdownByOtherMinority, (fp) => {return excludePropertyArray(fp, ["__typename"])}),
    } as EmployeeBreakdownByOtherMinorityInput

    let countDown = 3
    const resetAfterCountdown = (countDown: number) => {
      if(countDown <= 0){
        setSaving(false)
        setEditMode(false)
      }
    }
    updateEmployees({ variables: { input: updateData, startDate: searchDate, endDate: searchDate } })
      .then(result => {
        if (result && result.data) {
          let unformattedNewData = result.data.updateProductEmployeeBreakdowns
          console.log(unformattedNewData)
          let previousState = resultRef!.current?.state
          resultRef!.current?.setState({ ...previousState, currentState: unformattedNewData, initialState: unformattedNewData })
          countDown --
          resetAfterCountdown(countDown)
        }
      })
      .catch(err => {
        setSaving(false)
        countDown --
        resetAfterCountdown(countDown)
        console.log("Error updateEmployees", err.message)
      })

    updateOtherMinorities({ variables: { input: updateOtherMinorityData, startDate: searchDate, endDate: searchDate } })
      .then(result => {
        if (result && result.data) {
          let unformattedNewData = result.data.updateProductEmployeeBreakdownByOtherMinorities
          console.log(unformattedNewData)
          let previousState = resultRef!.current?.state
          resultRef!.current?.setState({ ...previousState, currentState: unformattedNewData, initialState: unformattedNewData })
          countDown --
          resetAfterCountdown(countDown)
        }
      })
      .catch(err => {
        setSaving(false)
        countDown --
        resetAfterCountdown(countDown)
        console.log("Error updateEmployeesOtherMinorities", err.message)
      })
    updateEmployeesBreakdownDetail({ variables: { input: updateEmployeeBreakdownDetailData, startDate: searchDate, endDate: searchDate } })
    .then(result => {
      if (result && result.data) {
        let unformattedNewData = result.data.updateProductEmployeeBreakdownDetail
        let previousState = resultRef!.current?.state
        resultRef!.current?.setState({ ...previousState, currentState: unformattedNewData, initialState: unformattedNewData })
        countDown --
        resetAfterCountdown(countDown)
      }
    })
    .catch(err => {
      setSaving(false)
      countDown --
      resetAfterCountdown(countDown)
      console.log("Error updateEmployeesBreakdownDetail", err.message)
    })

    resetAfterCountdown(countDown)
  }

  let explanation = _.get(data, 'product.product.employeeBreakdownDetail.explanation', "")
  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(moment(searchDate, DATE_API_FORMAT).endOf("year").format(DATE_API_FORMAT))}
          defaultPeriod={"year"}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables({extraRows: [{values:["Other Explanation", explanation]}]})}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
      </div>
        {reasonErrorMessage && 
        <span className="text-accent-red py-2 ml-2 d-flex">
          <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
          &nbsp; {`Save Error: ${reasonErrorMessage}`}
        </span>
        }
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={onSubmit} disabled={loading}/>
        }
      </div>
    </>
  )

  if (loading || raceLoading || genderLoading || typeLoading || mappingLoading || reasonLoading || minorityLoading) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <div className='pane'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error || raceError || genderError || typeError || mappingError || reasonError || minorityError) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <div className='pane'>
              <p>{error?.message}</p>
              <p>{raceError?.message}</p>
              <p>{genderError?.message}</p>
              <p>{typeError?.message}</p>
              <p>{mappingError?.message}</p>
              <p>{reasonError?.message}</p>
              <p>{minorityError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (data && data.product && raceData && genderData && typeData && mappingData && reasonData && minorityData) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <Result
              key={searchDate}
              ref={resultRef}
              editMode={editMode}
              setEditMode={setEditMode}
              setSearchDate={setSearchDate}
              data={data}
              searchDate={searchDate}
              raceData={raceData}
              genderData={genderData}
              typeData={typeData}
              minorityData={minorityData}
              mappingData={mappingData}
              setReasonErrorMessage={setReasonErrorMessage}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}



class Result extends Component<Props> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data
  }
  constructor(props: any) {
    super(props)
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    type: any,
    assetClass?: string
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    if(!(oldState?.product?.product as any)[table]){
      _.set(oldState, ["product", "product", table], [])
    }
    let newState = iassign(
      oldState,
      ["product", "product", table],
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as any[])
        var selectedRow = _.find(rows, (o) => {return (o?.type?.code === type?.code) && (!assetClass || o?.assetClass?.id === assetClass)})
        if(selectedRow){
          _.set(selectedRow, property, value)
        } else {
          let newRow
          newRow = {
            date: this.props.searchDate,
            type: {...type, __typename: "Lookup"},
            count: undefined,
          }
          _.set(newRow, property, value)
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  handleProductEmployeeBreakdownDetailChange = (
    value: any,
    property: string,
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["product", "product", "employeeBreakdownDetail", ...property.split(".")]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, value)
    }
    let newState = iassign(
      oldState,
      path,
      () => value
    )
    if(reasonCheckPass(newState)) {
      this.props.setReasonErrorMessage("")
    }
    this.setState({ ...this.state, currentState: newState})
  }

  formatData = (sourceData:any, mappingList:any) => {
    return _.map(mappingList.__type?.enumValues, (type:any) => {
      const foundData = _.find(sourceData, (o:any) => {return o.type.code === type.code})
      if(foundData){
        return foundData
      } else {
        return {
          date: this.props.searchDate,
          type: type,
          count: undefined
        }
      }
    })
  }

  turnoverData = (sourceData:any) => {
    let typeList = this.props.mappingData?.assetClassMaps?.employeeJobTypeAssetClassMap?.reduce((typeList:string[], type:any)=>{
      if(type.assetMixNum == this.props.data.product?.product?.assetClass?.parent?.code) typeList.push(type.code)
      return typeList
    },[]) || []
    const resultList = typeList.reduce((types:any,type:any) => {
      const foundObject = _.find(sourceData, (o) => {return o.type.code == type})
      if(foundObject){
        types.push(foundObject)
      } else {
        types.push({
          date: this.props.searchDate,
          type: {..._.find(this.props.typeData.__type?.enumValues, (o) => {return type == o.code}), __typename: "Lookup"},
          count: undefined,
          gained: undefined,
          lost: undefined,
        })
      }
      return types
    },[])
    return resultList
  }

  generateDetailComponent = (inputFields: ProductEmployeeBreakdownDetailInputField[], state: Maybe<EmployeeBreakdownDetail> | undefined) => {
    return (
      <Col sm="6" className="px-4">
        {inputFields.map(
          (
            {
              property,
              label,
              type,
              subtype,
              placeholder,
              optionSource,
              subClasses,
              displayData,
              charactersLimit,
              required,
            },
            idx
          ) => {
            if (
              (displayData && _.get(state, displayData.property) != displayData.value)
            ) {
              return <React.Fragment key={idx}></React.Fragment>
            }
            let propertyVal: any = _.get(state, property)
            let onChangeCallback = (value: any) =>{
              if(type === "select" && value === ""){
                this.handleProductEmployeeBreakdownDetailChange(null, property)
              } else {
                this.handleProductEmployeeBreakdownDetailChange(value, property)
              }
            }
            return (
              <FormInput
                key={idx}
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={`Details-${idx}`}
                editMode={this.props.editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                subClasses={subClasses}
                showZero={true}
                required={required}
                charactersLimit={charactersLimit}
              />
            )
      })}
      </Col>)
  }

  render() {
    const employeeBreakdown = this.state.currentState.product?.product
    const {
      employeeBreakdownByGender,
      employeeBreakdownByDiversityProfile,
      employeeTurnover,
      employeeBreakdownDetail,
      employeeBreakdownByOtherMinority,
    } = employeeBreakdown as ProductFields
    const formattedGenderData = this.formatData(employeeBreakdownByGender,this.props.genderData)
    const formattedDiversityData = this.formatData(employeeBreakdownByDiversityProfile,this.props.raceData)
    const formattedMinorityData = this.formatData(employeeBreakdownByOtherMinority,this.props.minorityData)
    const formattedTurnoverData = this.turnoverData(employeeTurnover)
    return(
      <div className='pane pane-table'>
        <Row>
          <Col>
            <h3 className="headline underline green-underline mb-3" id="employeeCountTooltipContainer">
              <div className="d-inline-flex align-items-center tooltip-icon" id="employeeCountTooltip">
                Employee Count
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  className="ml-2 mt-0"
                  size="sm"
                />
              </div>
            </h3>
          </Col>
        </Row>
        <Row className="mb-2" key={`employeeBreakdownDetail-1`}>
          {this.generateDetailComponent(EmployeeBreakdownDetailUpperInputs, employeeBreakdownDetail)}
        </Row>
        <Row>
          <Col className="border-right">
            <EmployeeTable
              data={formattedDiversityData || []}
              tableName={`productDiversity`}
              exportTableName={`productDiversity`}
              editMode={this.props.editMode}
              updateValue={(value, property, type) => this.handleInputChange(value,property,"employeeBreakdownByDiversityProfile",type)}
            />
          </Col>
          <Col className="border-right">
            <EmployeeTable
              data={formattedGenderData || []}
              tableName={`productGenderBreakDown`}
              exportTableName={`productGenderBreakdown`}
              editMode={this.props.editMode}
              updateValue={(value, property, type) => this.handleInputChange(value,property,"employeeBreakdownByGender",type)}
            />
          </Col>
          <Col>
            <EmployeeTable
              data={formattedMinorityData || []}
              tableName={`minorityBreakDown`}
              exportTableName={`minorityBreakDown`}
              editMode={this.props.editMode}
              updateValue={(value, property, type) => this.handleInputChange(value,property,"employeeBreakdownByOtherMinority",type)}
            />
          </Col>
        </Row>
        <Row className="mb-2" key={`employeeBreakdownDetail-2`}>
          {this.generateDetailComponent(EmployeeBreakdownDetailLowerInputs, employeeBreakdownDetail)}
        </Row>
        <Row className="mt-5">
          <Col>
            <h3 className="headline underline green-underline mb-3" id="employeeTurnoverTooltipContainer">
              <div className="d-inline-flex align-items-center tooltip-icon" id="employeeTurnoverTooltip">
                Employee Turnover
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  className="ml-2 mt-0"
                  size="sm"
                />
              </div>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <EmployeeTable
              data={formattedTurnoverData || []}
              tableName={`productTurnover`}
              exportTableName={`productTurnover`}
              editMode={this.props.editMode}
              updateValue={(value, property, type) => this.handleInputChange(value,property,"employeeTurnover",type)}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default ProductOverviewEmployee

import React, { Component, RefObject, useRef, useState } from 'react'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Col, Container, Progress, Row } from 'reactstrap'
import * as XLSX from 'xlsx'

import { appDate } from "../../Context/CalendarContext"
import { DATE_API_FORMAT, DATE_TEXT_FORMAT } from "../../helpers/constant"
import { listQuarters } from "../../helpers/helpers"
import { ClientPortfolio, HoldingKey, HoldingStatus, NotifyUploadInput, useHoldingTemplateSignedUrlQuery, useHoldingUploadSignedUrlsLazyQuery, useMeQuery, useNotifyUploadMutation, User, VehicleFields } from '../../__generated__/graphql'
import PlaceHolder from "./../ui/PlaceHolder"

interface IdVehicleFields extends Omit<VehicleFields, 'fundid'> {
  id: string
}

interface ProductCharacteristicsProps {
  portfolio: ClientPortfolio | IdVehicleFields
}

const ProductCharacteristicsHoldings: React.FC<ProductCharacteristicsProps> = props => {
  const { portfolio } = props

  let {data: currentUserData, loading: currentUserLoading, error: currentUserError } = useMeQuery()

  let { data: templateData, loading: templateLoading, error: templateError } = useHoldingTemplateSignedUrlQuery({})
  const CallanHelpLink = (<a href={"http://callandna.helpdocsonline.com/contact-us"} target="_blank" rel="noreferrer"> Callan</a>)
  const heading = (
    <>
      <h2 className="headline underline mt-3">
        Holdings
        <div className="d-inline-flex align-items-center tooltip-icon" id="holdingsTooltip">
          <FontAwesomeIcon
            icon={"question-circle" as IconName}
            className="ml-2 mt-0"
            size="sm"
          />
        </div>
      </h2>
      Supply holdings using the provided template. Download <a download={true} href={templateData?.holdingExcelTemplateSignedUrl || ""}> Excel </a> or <a download={true} href={templateData?.holdingTextTemplateSignedUrl || ""}> Text </a> template. Please contact {CallanHelpLink} if you need help with a locked quarter.
    </>
  )

  if (templateLoading || currentUserLoading) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <div className='pane pane-table'>
                <PlaceHolder />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  if (templateError || currentUserError) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <div className='pane pane-table'>
                <p>{templateError?.message}</p>
                <p>{currentUserError?.message}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  if (templateData && currentUserData) {
    const currentUser = currentUserData.me as User
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <Result
                portfolio={portfolio}
                currentUser={currentUser}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  return <div>data doesn't exist</div>
}


interface Props {
  portfolio: ClientPortfolio | IdVehicleFields
  currentUser: User
}

interface ResultState {
  currentState: HoldingStatus[]
  initialState: HoldingStatus[]
}

interface FileList {
  [key: string]: RefObject<HTMLInputElement>
}

class Result extends Component<Props> {
  constructor(props: Props) {
    super(props)
    const holdingStatusList = props.portfolio.holdingStatus as HoldingStatus[]
    this.state = {
      currentState: holdingStatusList,
      initialState: holdingStatusList,
    }
    this.files = {}
    holdingStatusList.forEach((holdingStatus:HoldingStatus, idx:number) => this.files[`file-${idx}`] = React.createRef<HTMLInputElement>())
  }

  state: ResultState

  files: FileList

  resetForm = () => {
    this.setState({ currentState: this.state.initialState })
  }

  render() {
    const {currentState} = this.state
    const quarters = listQuarters(_.minBy(currentState, (f) => moment(f.quarterDate).valueOf())?.quarterDate || appDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))
    let lastYear:number
    return(
      <div className="mw-700 exportable" data-export-name={`${this.props.portfolio.name}-holdings`}>
        {quarters.map((quarter, idx) => {
          let heading = <></>
          const currentYear = moment(quarter, DATE_API_FORMAT).year()
          if(currentYear !== lastYear){
            heading =(
              <Row className="border-bottom mt-4">
                <Col md={2}><h5>{currentYear}</h5></Col>
                <Col md={4} className="text-uppercase text-gray-70">Holdings for Quarter</Col>
                <Col md={4} className="text-uppercase text-gray-70">Submitted date</Col>
                <Col md={2}></Col>
              </Row>
            )
          }

          lastYear = currentYear
          const holdingStatus = _.find(currentState, (o) => o.quarterDate === quarter)
          return (
            <React.Fragment key={idx}>
              {heading}
              <UploadHoldings
                portfolio={this.props.portfolio}
                holdingStatus={holdingStatus}
                quarter={quarter}
                currentUser={this.props.currentUser}
              />
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}

interface UploadProps {
  portfolio: ClientPortfolio | IdVehicleFields
  quarter: string
  holdingStatus?: HoldingStatus
  currentUser: User
}

enum uploadStatus{
  Locked = 1,
  Unlocked = 2,
  StartUploading = 3,
  Uploading = 4,
  Error = 5,
  Cancelled = 6,
  Uploaded = 7,
}

const CancelToken = axios.CancelToken

const UploadHoldings: React.FC<UploadProps> = props => {
  const { portfolio, holdingStatus, currentUser, quarter } = props
  let idObject:HoldingKey
  const uploadRef = useRef<HTMLInputElement>(null)
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [currentStatus, setCurrentStatus] = useState((!holdingStatus || (holdingStatus.unlock && (!holdingStatus.submittedDate || Math.abs(moment().diff(moment(holdingStatus?.submittedDate),"minutes")) > 30))) ? uploadStatus.Unlocked : uploadStatus.Locked)
  const [file, setFile] = useState({name:"", type:""})
  const [errorMessage, setErrorMessage] = useState(": unknown error")
  const [cancelToken, setcancelToken] = useState(CancelToken.source())
  const currentYear = moment(quarter, DATE_API_FORMAT).year()
  if(portfolio.__typename === "ClientPortfolio"){
    idObject = {portfolioId: portfolio.id}
  } else if (portfolio.__typename === "VehicleFields"){
    idObject = {fundId: portfolio.id}
  } else {
    idObject = {}
  }

  const [holdingUploadSignedUrlQuery, {loading, data, error}] = useHoldingUploadSignedUrlsLazyQuery()
  const [notifyUploadMutation] = useNotifyUploadMutation()
  const handleUpload = (event:any) => {
    const inFile = event.target.files[0]
    let reader = new FileReader();
    reader.onload = function(e) {
      const targetResult = e.target?.result as ArrayBuffer
      let data = new Uint8Array(targetResult)
      let workbook = XLSX.read(data, {type: 'array'})
      var sheetName = workbook.SheetNames[0]
      if (sheetName === "__FDSCACHE__" && workbook.SheetNames.length > 1)
        sheetName = workbook.SheetNames[1]
      const sheet = _.get(workbook, 'Sheets["'+sheetName+'"]')
      const a1Failed = _.get(sheet, "A1.v") !== "Identifier"
      const b1Failed = _.get(sheet, "B1.v") !== "Number of Shares"
      const c1Failed = _.get(sheet, "C1.v") !== "Market Value- USD"
      const d1Failed = _.get(sheet, "D1.v") !== "Security Description"
      if(a1Failed || b1Failed || c1Failed || d1Failed){
        setCurrentStatus(uploadStatus.Error)
        var formatErrorMessage = ""
        if(a1Failed && b1Failed && c1Failed && d1Failed){
          formatErrorMessage = "None of the headings matched 'Identifier', 'Number of Shares', 'Market Value- USD', and 'Security Description' exactly. Make sure it is the first tab and you are using the required headings"
        } else {
          if(a1Failed)
            formatErrorMessage += "A1 is not exactly 'Identifier', "
          if(b1Failed)
            formatErrorMessage += "B1 is not exactly 'Number of Shares', "
          if(c1Failed)
            formatErrorMessage += "C1 is not exactly 'Market Value- USD', "
          if(d1Failed)
            formatErrorMessage += "D1 is not exactly 'Security Description', "
        }
        setErrorMessage(" File format does not match: "+ formatErrorMessage)
        return
      }
      setFile(inFile)
      setCurrentStatus(uploadStatus.StartUploading)

      holdingUploadSignedUrlQuery({ variables: {
          id: idObject,
          quarterDate: quarter,
          filename: inFile.name,
          filetype: inFile.type,
        },
      })
    };
    if(["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", 'text/plain'].includes(inFile.type) && ["xls", "xlsx", "csv", "txt"].includes(inFile.name.split('.').pop())){
      reader.readAsArrayBuffer(inFile)
    } else {
      setCurrentStatus(uploadStatus.Error)
      setErrorMessage(" File format: file is not .xls or .xlsx")
    }
  }

  const onClick = () => {
    if(currentStatus === uploadStatus.Unlocked || currentStatus === uploadStatus.Error || currentStatus === uploadStatus.Cancelled){
      uploadRef?.current?.click()
    }
  }

  const onCancel = () => {
    if(currentStatus === uploadStatus.Uploading){
      cancelToken.cancel("Cancelled By User")
      setCurrentStatus(uploadStatus.Cancelled)
    }
  }

  if(error && currentStatus === uploadStatus.StartUploading){
    setCurrentStatus(uploadStatus.Error)
    setErrorMessage(": Endpoint unavailable")
  } else if(!!data && (currentStatus === uploadStatus.StartUploading) && data.holdingUploadSignedUrls && data.holdingUploadSignedUrls.uploadSignedUrl){
    setCurrentStatus(uploadStatus.Uploading)
    // Put the fileType in the headers for the upload
    var options = {
      headers: {
        'Content-Type': file.type
      },
      onUploadProgress: (progressEvent:ProgressEvent) => {
        setLoadingPercent(Math.round((80 * progressEvent.loaded) / progressEvent.total))
      },
      cancelToken: cancelToken.token
    };
    axios.put(data.holdingUploadSignedUrls?.uploadSignedUrl,file,options)
    .then(result => {
      const updateData = {
        holdingUpload: {
          id: idObject,
          quarterDate: quarter,
          personId: currentUser.person?.id,
        },
        downloadSignedUrl: data.holdingUploadSignedUrls?.downloadSignedUrl,
        filename: file.name,
        filetype: file.type,
        uploadSignedUrl: data.holdingUploadSignedUrls?.uploadSignedUrl,
      } as NotifyUploadInput

      notifyUploadMutation({ variables: {
          input: updateData,
        }
      })
      .then(result => {
        setCurrentStatus(uploadStatus.Uploaded)
      })
      .catch((err) => {
        setCurrentStatus(uploadStatus.Error)
        setErrorMessage(" Confirming upload: " + err.message)
        console.log("Error", err.message)
        // throw new Error(`${err.message}`)
      })
    })
    .catch(error => {
      setCurrentStatus(uploadStatus.Error)
      setErrorMessage(" Upload: " + error.message)
      alert("ERROR " + JSON.stringify(error));
    })
  }

  let icon = <></>
  if(currentStatus === uploadStatus.Locked || currentStatus === uploadStatus.Uploaded ){
    icon = <FontAwesomeIcon icon="circle" className="mr-2 text-olive-100"/>
  } else {
    icon = <FontAwesomeIcon icon="exclamation-circle" className="mr-2 text-accent-red"/>
  }

  let submitted = <></>
  if(currentStatus === uploadStatus.Uploading || currentStatus === uploadStatus.StartUploading){
    submitted = <div  className="d-flex text-align-baseline">
      <FontAwesomeIcon icon="spinner-third" spin={true} className="hover-left-icon"/>
      Uploading
      <Progress value={loadingPercent} barClassName="background-gray-30" className="flex-grow-1">{loadingPercent}%</Progress>
    </div>
  } else if (currentStatus === uploadStatus.Error){
    submitted = <span className="text-accent-red">
      <FontAwesomeIcon icon={["fal", "exclamation-circle"]} className="hover-left-icon"/>
      Error{errorMessage}
    </span>
  } else if (currentStatus === uploadStatus.Cancelled){
    submitted = <span className="text-accent-red">
      Cancelled
    </span>
  } else if (currentStatus === uploadStatus.Uploaded){
    submitted = <>{moment().format(DATE_TEXT_FORMAT)}</>
  } else {
    submitted = <>{holdingStatus?.submittedDate && moment(holdingStatus?.submittedDate, DATE_API_FORMAT).format(DATE_TEXT_FORMAT)}</>
  }
  return(
    <Row className="border-top py-2">
      <Col md={2}></Col>
      <Col md={4} className={`text-${(currentStatus === uploadStatus.Locked || currentStatus === uploadStatus.Uploaded) ? "blue-100" : "gray-50"}`}>
        {icon}
        {`Q${moment(quarter, DATE_API_FORMAT).quarter()} ${currentYear}`}
      </Col>
      <Col md={4}>{submitted}</Col>
      <Col md={2} className="text-right">
        {(!holdingStatus || holdingStatus.unlock) &&
          <input type="file" onChange={(event) => handleUpload(event)} className="d-none" ref={uploadRef}/>
        }
        {currentStatus !== uploadStatus.Uploading &&
          <FontAwesomeIcon icon="upload" className={`mr-2 text-${!(currentStatus === uploadStatus.Locked || currentStatus === uploadStatus.Uploaded) ? "blue-100" : "gray-30"}`} onClick={() => onClick()}/>
        }
        {currentStatus === uploadStatus.Uploading &&
          <div className="text-blue-100 cursor-pointer" onClick={() => onCancel()}>
            Cancel
          </div>
        }
      </Col>
    </Row>
  )
}

export default ProductCharacteristicsHoldings

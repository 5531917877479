import * as Cookies from "js-cookie";
import { DocumentAssociationTypes } from "../Components/Shared/Document"

export const setSessionCookie = (session: any): void => {
  const oldSession = Cookies.get("session") || "{}"
  Cookies.remove("session");
  Cookies.set("session", {...JSON.parse(oldSession), ...session}, { expires: 14 });
};

export const getSessionCookie: any = () => {
  const sessionCookie = Cookies.get("session");

  if (sessionCookie === undefined) {
    return {};
  } else {
    return JSON.parse(sessionCookie);
  }
};

interface RecentManagerEntry {
  id: number,
  name: string
}
interface RecentProductEntry {
  id: number,
  name: string,
  managerName: string
  parentMix: number
}
interface RecentClientEntry {
  id: number,
  name: string
}

interface RecentReportEntry {
  id: number,
  name: string,
  category?: string,
  subCategory?: string,
}

interface RecentDocumentEntry {
  id: string,
  name: string, // document.description (could be undefined) || document.name
  sourceType: DocumentAssociationTypes | "Document", // "Document" for standalone type, sourceId could be 0
  sourceId: number
}

export const registerRecentManager = (id: number, name: string): void => {
  let session = getSessionCookie()
  let recentManagers = session.recentManagers || []
  const manager: RecentManagerEntry = { id, name }

  recentManagers = recentManagers.filter((m: RecentManagerEntry) => m.id !== id)
  recentManagers.unshift(manager)

  setSessionCookie({ recentManagers: recentManagers.slice(0, 11)}) // Store 11 so we can still display 10 if we have to remove a double
}

const DEFAULT_RECENT_ITEM_LIMIT = 10

export const getRecentManagers = (limit?: number): RecentManagerEntry[] => {
  let session = getSessionCookie()
  let recentManagers = session.recentManagers || []
  return recentManagers.slice(0, limit || DEFAULT_RECENT_ITEM_LIMIT) || []
}

export const registerRecentProduct = (id: number, name: string, managerName: string, parentMix: number): void => {
  let session = getSessionCookie()
  let recentProducts = session.recentProducts || []
  const product: RecentProductEntry = { id, name, managerName, parentMix }

  recentProducts = recentProducts.filter((m: RecentProductEntry) => m.id !== id)
  recentProducts.unshift(product)

  setSessionCookie({ recentProducts: recentProducts.slice(0, 11)})
}

export const getRecentProducts = (limit?: number): RecentProductEntry[] => {
  let session = getSessionCookie()
  let recentProducts = session.recentProducts || []
  return recentProducts.slice(0, limit || DEFAULT_RECENT_ITEM_LIMIT) || []
}

export const registerRecentClient = (id: number, name: string): void => {
  let session = getSessionCookie()
  let recentClients = session.recentClients || []
  const manager: RecentClientEntry = { id, name }

  recentClients = recentClients.filter((m: RecentClientEntry) => m.id !== id)
  recentClients.unshift(manager)

  setSessionCookie({ recentClients: recentClients.slice(0, 11)})
}

export const getRecentClients = (): RecentClientEntry[] => {
  let session = getSessionCookie()
  let recentClients = session.recentClients || []
  return recentClients.slice(0, 10) || []
}

export const registerRecentDocument = (props:RecentDocumentEntry): void => {
  let {id, name, sourceType, sourceId} = props

  let document: RecentDocumentEntry = {id, name, sourceType, sourceId}

  let session = getSessionCookie()
  let recentDocuments = (session.recentDocuments || [] ) as RecentDocumentEntry[]
  recentDocuments = recentDocuments.filter(m => m.id !== props.id)
  if(document) {
    recentDocuments.unshift(document)
  }
  setSessionCookie({ recentDocuments: recentDocuments.slice(0, 11)})
}

export const getRecentDocuments = (limit?: number): RecentDocumentEntry[] => {
  let session = getSessionCookie()
  let recentDocuments = session.recentDocuments || []
  return recentDocuments.slice(0, limit || DEFAULT_RECENT_ITEM_LIMIT) || []
}

export const registerRecentReport = (props:RecentReportEntry): void => {
  let {id, name, category, subCategory} = props

  let report: RecentReportEntry = {id, name, category, subCategory}

  let session = getSessionCookie()
  let recentReports = (session.recentReports || [] ) as RecentReportEntry[]
  recentReports = recentReports.filter(m => m.id !== props.id)
  if(report) {
    recentReports.unshift(report)
  }
  setSessionCookie({ recentReports: recentReports.slice(0, 21)})
}

export const getRecentReports = (limit?: number): RecentReportEntry[] => {
  let session = getSessionCookie()
  let recentReports = session.recentReports || []
  return recentReports.slice(0, limit || DEFAULT_RECENT_ITEM_LIMIT) || []
}
import React, { ErrorInfo } from 'react'
import Auth from '../../../Auth/Auth'
import { ComponentType, ConsultantDashboardComponentFragment, ConsultantDashboardComponentSettingsFragment } from '../../../__generated__/graphql'
import { ErrorComponent } from '../../Report/Shared/ReportComponent'
import ClientList from './ClientList'
import RecentlyViewed from './RecentlyViewed'
import NotableInteractions from './NotableInteractions'
import Stoplight from './Stoplight'
import PlanList from './PlanList'
import ReportSchedule from './ReportSchedule'

type ConsultantDashboardBaseComponentProps<Fragment> = {
  component: ConsultantDashboardComponentFragment
  auth: Auth
  draftView: boolean
  expectedTypename: string
  reactComponent: React.FC<ConsultantDashboardIndividualComponentProps<Fragment>>
}

type ConsultantDashboardBaseComponentState<Fragment> = {
  error?: string
  settings?: Fragment
}

export type ConsultantDashboardIndividualComponentProps<Fragment> = {
  component: ConsultantDashboardComponentFragment
  auth: Auth
  settings: Fragment
}

export type ConsultantDashboardAggregatedComponentProps = {
  component: ConsultantDashboardComponentFragment
  auth: Auth
  draftView: boolean
}


export class ConsultantDashboardBaseComponent<Fragment extends ConsultantDashboardComponentSettingsFragment|ConsultantDashboardComponentFragment> extends React.Component<ConsultantDashboardBaseComponentProps<Fragment>, ConsultantDashboardBaseComponentState<Fragment>> {
  constructor(props:ConsultantDashboardBaseComponentProps<Fragment>) {
    super(props)
    const {component, draftView}= props
    let settings: Fragment | undefined = undefined
    let error: string = ""
    if(draftView){
      if(component.draftSettings?.__typename === props.expectedTypename){
        settings = component.draftSettings as Fragment
      } else {
        error= "Draft settings don't match expected type"
      }
    } else {
      if(component.liveSettings?.__typename === props.expectedTypename){
        settings = component.liveSettings as Fragment
      } else {
        error= "Live settings don't match expected type"
      }
    }
    this.state = {
      error,
      settings,
    }
  }

  componentDidCatch(error:Error, info:ErrorInfo) {
    this.setState({ error: error.message });
    console.log({component: this.props.component, error, info})
  }

  render() {
    const name = this.props.component.name || ""
    const { reactComponent, component, auth } = this.props
    const settings = this.state.settings
    if(this.state.error){
      return(<ErrorComponent name={name} error={this.state.error}/>)
    } else if (settings) {
      // Render the reactComponent from props
      return(React.createElement(reactComponent, {component, settings, auth}))
    }
    return(<ErrorComponent name={name} error={"No settings for component"}/>)
  }
}

const ConsultantDashboardComponent: React.FC<ConsultantDashboardAggregatedComponentProps> = (props) => {
  const type = props.component.type
  if(!type) return(<ErrorComponent name={props.component.name || ""} error="Component type not chosen"/>)

const ComponentMapping: { [key in ComponentType]?: React.FC<ConsultantDashboardAggregatedComponentProps>} = {
  [ComponentType.ClientList]: ClientList,
  [ComponentType.PlanList]: PlanList,
  [ComponentType.RecentlyViewed]: RecentlyViewed,
  [ComponentType.NotableInteractions]: NotableInteractions,
  [ComponentType.Stoplight]: Stoplight,
  [ComponentType.ReportSchedule]: ReportSchedule,
}

  const component = ComponentMapping[type]
  if(component)return React.createElement(component, props)

  return(<ErrorComponent name={props.component.name || ""} error="Component not Implemented"/>)
}


export default ConsultantDashboardComponent

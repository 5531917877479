import classnames from 'classnames'
import React, {EventHandler, useState, useEffect, useContext, useRef, LegacyRef} from 'react'
import { debounce, get, set, union, cloneDeep, uniq, some } from 'lodash'

import validate from 'validate.js'
import { FormGroup, Label, Input, InputProps, Col, ListGroupItem, Row, ListGroup, Button, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { autocompleteToItem } from '../../../helpers/list'
import { EditButtonContext } from '../../../Context/EditButtonContext'
import { Maybe, SearchTypes, useAutocompleteQuery } from '../../../__generated__/graphql'
import { InputTooltip } from './TextFieldInput'
import { Link } from 'react-router-dom'
import { noRefreshHistory } from '../../../history'
import { getUrlFor } from '../../../helpers/helpers'

interface SearchInputProps {
  idx: string | number,
  property: string,
  displayName: string,
  propertyValue: any,
  editMode: boolean,
  updateValue: (event:any) => void,
  wrapperClasses?: string,
  labelClasses?: string,
  inputWrapperClasses?: string,
  inputClasses?: string,
  placeholder: string,
  disabled?: boolean,
  required?: boolean
  autoFocus?: boolean
  tooltip?: InputTooltip
  subtype?: string
  inputProps?: InputProps
  searchTypes: SearchTypes[]
  inputRef?: React.RefObject<HTMLInputElement>
  staticText?: string
  resetSearchOnViewMode?: boolean
  clearValueCallback?: (props?: any) => void
  // used for textLink helper function
  textLinkParams?: Maybe<{ url: string | undefined }>
  optionHasLink?: boolean
}

export const SearchInput: React.FC<SearchInputProps> = props => {
  const { idx, property, displayName, editMode, placeholder, labelClasses, inputWrapperClasses, inputClasses, disabled, tooltip, autoFocus, inputProps, searchTypes, inputRef: inInputRef, subtype, staticText, optionHasLink, textLinkParams } = props
  const { resetSearchOnViewMode, clearValueCallback } = props
  const updateValue: EventHandler<any> = props.updateValue
  let [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || '')

  let shortProperty = property.split(".").pop() || property
  let required = props.required || false
  let propertyValue = props.propertyValue || []
  const linkUrl = textLinkParams?.url ? textLinkParams?.url : subtype === "single" && getUrlFor(propertyValue)
  const treatAsLink = !editMode && (optionHasLink || textLinkParams?.url) && linkUrl
  const context = useContext(EditButtonContext)
  const { setError } = context
  const dropdownRef = useRef<HTMLDivElement>(null)
  let inputRef =  useRef<HTMLInputElement>(inInputRef?.current || null)

  const [search, setSearch] = useState("")
  const [hideResult, setHideResult] = useState(false)
  const debouncedHide = useRef(debounce((value:boolean) => setHideResult(value), 200)).current
  const { data } = useAutocompleteQuery({ variables: {
    q: search,
    types: searchTypes,
    filters: searchTypes.includes(SearchTypes.Group) ? {group: {classCode: ["s","m","l","p","5","c","i","h","a"]}} : {}
  }})

  const displayLabel = (displayName !== "")

  useEffect(() => {
    runValidation(propertyValue)
    if(!props.editMode && resetSearchOnViewMode) {
      setSearch("")
    }
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [props.editMode, required])

  const handleClick = (e:any) => {
    if (dropdownRef?.current?.contains(e.target) || inputRef?.current?.contains(e.target)) {
      // inside click
      setHideResult(false)
      return;
    }
    // outside click
    setHideResult(true)
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const runValidation = (value:string | null) => {
    var inputs:{[key:string]: string | null} = {}
    var constraints:{[key:string]: any} = {}

    inputs[shortProperty] = value

    if (required) {
      set(constraints, [shortProperty, 'presence'], true)
    }

    let validations = validate(inputs, constraints)
    if (validations && props.editMode) {
      setError(`${idx}-${shortProperty}`, get(validations, shortProperty))
      setWrapperClasses(wrapperClasses + ' has-error')
    } else {
      setError(`${idx}-${shortProperty}`, [])
      setWrapperClasses(wrapperClasses.replace(/has\-error/g,''))
    }
  }

  const getName = (item:any) => {
    if ("name" in item){
      return item.name
    } else if ("firstName" in item && "lastName" in item){
      return `${item.firstName} ${item.lastName}`
    } else if ("product" in item){
      return item.product.name
    } else if ("vehicle" in item){
      return item.vehicle.name
    } else if ("groupName" in item){
      return item.groupName
    }
    return ""
  }

  const getId = (item:any) => {
    if ("id" in item){
      return item.id
    } else if ("product" in item){
      return item.product.id
    } else if ("vehicle" in item){
      return item.vehicle.id
    }
    return 0
  }

  let results: (JSX.Element | undefined)[]
  if (!!data && data.autocomplete) {
    results = data.autocomplete.map((d, idx) => {
      if (d == null) {
        // return <ListGroupItem className="horizontal with-category autocomplete"></ListGroupItem>
        return <React.Fragment key={idx}></React.Fragment>
      }
      let type:string | undefined, id:number | string |  undefined, name:string | undefined, className: string | undefined
      let managerName: string | "" = ""
      if(d.__typename === "ManagerAutocomplete") {
        type = "Manager"
        id = d.id
        name = d.name
      } else if (d.__typename === "ClientAutocomplete") {
        type = "Client"
        id = d.id
        name = d.clientName
      } else if (d.__typename === "CustodianAutocomplete") {
        type = "Custodian"
        id = d.id
        name = d.custodianName
      } else if (d.__typename === "RecordKeeperAutocomplete") {
        type = "RK"
        id = d.id
        name = d.recordKeeperName
        className = 'record-keeper'
      } else if (d.__typename === "PeopleAutocomplete") {
        type = "Person"
        id = d.id
        name = `${d.firstName} ${d.lastName}`
      } else if (d.__typename === "PlanAutocomplete") {
        type = "Plan"
        id = d.id
        name = d.planName || ""
      } else if (d.__typename === "ProductAutocomplete") {
        type = "Product"
        id = d.id
        name = d.productName
      } else if (d.__typename === "VehicleAutocomplete") {
        type = "Vehicle"
        id = d.vehicleId
        name = d.vehicleName || ""
        managerName = d.managerName || ""
      } else if (d.__typename === "TargetDateAutocomplete") {
        type = "Target"
        id = d.id
        name = d.versionName
      } else if (d.__typename === "IndexAutocomplete") {
        type = "Index"
        id = d.indexId
        name = d.indexName || ""
      } else if (d.__typename === "GroupAutocomplete") {
        type = "Group"
        id = d.groupId
        name = d.groupName || ""
      }
      const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        if(subtype === "single"){
          updateValue(autocompleteToItem(d))
        } else {
          // if(!some(propertyValue,(item:any) => {
          //   let itemId = getId(item)
          //   return itemId === id
          // }))
          updateValue(uniq(union(propertyValue, [autocompleteToItem(d)])))
        }
        setSearch("")
      }
      if(type && id && name && (Array.isArray(propertyValue) ? !propertyValue?.some((item:any) => getId(item) === id) : getId(propertyValue) !== id)){
        return (
          <ListGroupItem tag="a" onClick={handleClick} key={`${type.toLocaleLowerCase()}-auto-${id}`}>
            <div className={`category ${className || type.toLocaleLowerCase()}`}>
              <div className="category-text">
                <h6>{type}</h6>
              </div>
            </div>
            <div className="w-100">
              <Row>
                <Col md="8">
                  <h3 className="white-space-normal" style={{minWidth: 250, paddingRight: 60}}>{name}</h3>
                  {managerName && (<p>MANAGER: {managerName}</p>)}
                </Col>
                <Col md="4">
                  <h5>{type} ID</h5>
                  <p>{id}</p>
                </Col>
              </Row>
            </div>
          </ListGroupItem>
        )
      }
      return <React.Fragment key={idx} />
    })
  } else {
    results = [(<React.Fragment key="1"></React.Fragment>)]
  }
  return (
    <FormGroup
      className={classnames("form-group row", wrapperClasses||"")}
      key={idx}
    >
      {displayLabel &&
        <Label
          className={classnames("col-form-label", labelClasses, {"col-sm-4":!labelClasses})}
          for={`${idx}-${shortProperty}`}
          id={property}
        >
          <div
            className={classnames("d-flex w-100", {'tooltip-icon': tooltip, 'justify-content-start': tooltip?.onClick})}
            id={tooltip ? tooltip.id : ""}
          >
            {displayName}
            {treatAsLink && linkUrl &&
              <FontAwesomeIcon icon={"external-link"} className="ml-2 cursor-pointer" onClick={() => window.open(linkUrl,'_blank')}/>
            }
            {tooltip &&
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={() => { tooltip.onClick && tooltip.onClick() }}
              />
            }
          </div>
        </Label>
      }
      <div className={classnames("exportable-form-input input-group-col-fixer", inputWrapperClasses||"",{"col-sm-8":displayLabel &&!inputWrapperClasses,"col-sm-12":!(displayLabel||inputWrapperClasses)})}>
        <DebouncedSearchBar
          idx={idx}
          shortProperty={shortProperty}
          property={property}
          placeholder={placeholder}
          setSearchTerm={(name:string) => setSearch(name)}
          disabled={disabled}
          editMode={editMode}
          inputClasses={inputClasses}
          required={required}
          autoFocus={autoFocus}
          inputRef={inputRef}
          staticText={staticText}
          searchValue={subtype === "single" ? getName(propertyValue) : undefined}
          clearValueCallback={clearValueCallback}
          url={linkUrl || undefined}
          {...inputProps}
        />
        <ListGroup className="horizontal with-category autocomplete position-absolute above-picker" >
          <div ref={dropdownRef}>
            {!hideResult && results}
          </div>
        </ListGroup>
        {subtype !== "single" &&
          <div className='pl-1'>
            {propertyValue.map((item: any, idx: number) => {
              let name = getName(item)
              let id = getId(item)
              const handleClick = () => {
                let newPropertyValue = cloneDeep(propertyValue)
                newPropertyValue.splice(idx,1)
                updateValue(newPropertyValue)
              }
              return(
                <Button color="light" size="sm" className="mr-1" key={item.__typename + id} onClick={handleClick}>
                  {name}
                  <FontAwesomeIcon
                    icon="times"
                    className="pl-2"
                  />
                </Button>
              )
            })}
          </div>
        }
      </div>
      {textLinkParams?.url && !editMode && <div className='form-link-overlay' onClick={() => window.open(textLinkParams.url,'_blank')}/>}
    </FormGroup>
  )
}


interface DebouncedSearchBarProps{
  setSearchTerm: (name:string) => void
  debounceTimeout?: number
  placeholder?: string
  searchValue?: string
  idx: string | number
  shortProperty: string
  property: string
  editMode: boolean
  inputClasses?: string
  disabled?: boolean
  required?: boolean
  autoFocus?: boolean
  inputProps?: InputProps
  inputRef?: React.RefObject<HTMLInputElement>
  staticText?: string
  clearValueCallback?: (props?: any) => void
  url?: string
}

export const DebouncedSearchBar:React.FC<DebouncedSearchBarProps> = (props:DebouncedSearchBarProps) => {
  const { debounceTimeout, setSearchTerm, placeholder, idx, property, editMode, inputClasses, disabled, required, autoFocus, inputProps, shortProperty, inputRef, searchValue, staticText, clearValueCallback, url } = props
  const [searchString, setSearchString] = useState(searchValue || "")
  const debouncedSave = useRef(debounce((nextValue:string) => setSearchTerm(nextValue), debounceTimeout || 500)).current
  const treatAsLink = !editMode && url

  useEffect(() => {
    setSearchString(searchValue || "")
  },[searchValue])

  const handleSearchChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchString(value)
    debouncedSave(value)
  }
  const resetSearch = (e:any) => {
    setSearchTerm(searchString)
    e.target.select()
  }

  const clearValue = () => {
    setSearchString("")
    if(clearValueCallback) {
      clearValueCallback()
    }
  }

  const renderInputWithLink = () => {
    let input = (
    <Input
      type="text"
      className={classnames("form-control mr-0", inputClasses||"", {"editing": editMode, "has-static-text": !!staticText, "has-long-static-text": staticText && staticText.length > 6, "fake-link": treatAsLink })}
      onChange={handleSearchChange}
      onFocus={resetSearch}
      value={searchString}
      id={`${idx}-${shortProperty}`}
      bsSize="sm"
      name={property}
      label={property}
      placeholder={editMode ? placeholder : undefined}
      disabled={!editMode || disabled}
      required={required}
      autoFocus={autoFocus}
      innerRef={inputRef}
      {...inputProps}
    />)
    return input
  }

  return (
    <div className="relative">
      {!!staticText &&
        <Label className='static-text' data-static-text={staticText} />
      }
      <InputGroup className={classnames({"fake-link": treatAsLink})} onClick={() => treatAsLink && window.open(url,'_blank')}>
        {renderInputWithLink()}
        {editMode && !!clearValueCallback &&  (
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <FontAwesomeIcon icon="times-circle" onClick={clearValue} />
            </InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
    </div>
  )
}
import { disableFragmentWarnings, gql } from "@apollo/client"

disableFragmentWarnings()

export const PRODUCT_OVERVIEW_OUTLOOK_FRAGMENT = gql`
  fragment ProductOverviewOutlookFragment on Product {
    product {
      id
      outlookSurvey {
        inactive
        editable
        questions {
          id
          questionText
          answers {
            answerId: id
            answerText
            answerOptions {
              code
              value
            }
          }
        }
        responses(filter: { startDate: $startDate, endDate: $endDate }) {
          date
          questionResponses {
            questionId
            answerId
            responseText
          }
        }
      }
    }
  }
`

export const PRODUCT_EMPLOYEE_BREAKDOWN_DETAIL_FRAGMENT = gql`
  fragment ProductEmployeeBreakdownDetailFragment on ProductFields {
    employeeBreakdownDetail {
      canProvideDiversityGender
      reason {
        code
        value
      }
      explanation
    }
  }
`

export const PRODUCT_EMPLOYEE_BREAKDOWN_OTHER_MINORITY_FRAGMENT = gql`
  fragment ProductEmployeeBreakdownOtherMinorityFragment on ProductFields {
    employeeBreakdownByOtherMinority(startDate: $startDate, endDate: $endDate) {
      date
      type {
        code
        value
      }
      count
    }
  }
`

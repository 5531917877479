import React, { Component } from "react"
import Auth from "../../Auth/Auth"
import { QueryResult } from "@apollo/client"
import { Query } from "@apollo/client/react/components"
import { RouteComponentProps, Switch } from "react-router"
import { useHistory } from "react-router-dom"
import { WithTopNav } from "../ui/LayoutWrapper"
import { appProps } from "../../queries/extended/appProps"
import { RecordKeeperBasicsQuery, RecordKeeperBasicsQueryVariables, RecordKeeperBasicsFragment, RecordKeeperBasicsDocument } from "../../__generated__/graphql"
import LoadingOverlay from "../ui/Loading"
import { Alert, BreadcrumbProps, Col, Container, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledAlert, UncontrolledDropdown } from "reactstrap"
import classnames from "classnames"
import { CrumbRoute } from "../../routes/crumb-route"
import RecordKeeperOverview from "./RecordKeeperOverview"
import PlaceHolder from "../ui/PlaceHolder"
import ErrorDisplay from "../Shared/ErrorDisplay"

const getActiveTab = (props: any, url: string) => {
  if (props.match.url.indexOf(`${url}/profile`) === 0) {
    return "Profile"
  }
  if (props.match.url.indexOf(`${url}/overview`) === 0) {
    return "Overview"
  }
  if (props.match.url.indexOf(`${url}/people`) === 0) {
    return "People"
  }
  if (props.match.url.indexOf(`${url}/interactions`) === 0) {
    return "Interactions"
  }
  if (props.match.url.indexOf(`${url}/documents`) === 0) {
    return "Documents"
  }
  return "Menu"
}

interface authProps {
  auth: Auth
}

interface RecordKeeperRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.recordKeeperId
    recordKeeperId: string
    type: string
  }> {}

interface RecordKeeperOverviewBasicsFragment {
  data: RecordKeeperBasicsFragment
}
const RecordKeeperDetailComponent: React.FC<appProps & RecordKeeperRouteProps & RecordKeeperOverviewBasicsFragment> = (props) => {
  const history = useHistory()
  const { match, auth } = props
  const { data, ...otherProps } = props
  if (!data) {
    return (
      <Container fluid>
        <UncontrolledAlert color="danger">
          <h4>404: RecordKeeper Not Found</h4>
          <p>No RecordKeeper exists with this id.</p>
        </UncontrolledAlert>
      </Container>
    )
  }

  if (data.__typename !== "RecordKeeper") {
    return (
      <Container fluid>
        <UncontrolledAlert color="danger">
          <h4>400: Org Not Supported</h4>
          <p>Org type {data.__typename} is not currently supported</p>
        </UncontrolledAlert>
      </Container>
    )
  }

  if(!auth.checkPermissions(["view:all_clients"])) {
    return (
      <Alert color="error">
        <p>Sorry, you do not have permission to visit this url.</p>
      </Alert>
    )
  }

  const { recordKeeperId, type } = match.params
  const urlWithoutType = type ? match.url.slice(0, -type.length - 1) : match.url

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline">{data?.name}</h2>
            <Nav className="sub-nav sub-nav-primary collapsed" tabs role="group" aria-label="RecordKeeper Subpage">
              <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                <DropdownToggle caret>{getActiveTab(props, urlWithoutType)}</DropdownToggle>
                <DropdownMenu>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/profile`) === 0,
                      })}
                      onClick={() => history.push(`${urlWithoutType}/profile`)}
                    >
                      Profile
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/overview`) === 0,
                      })}
                      onClick={() => history.push(`${urlWithoutType}/overview`)}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/interactions`) === 0,
                      })}
                      onClick={() => history.push(`${urlWithoutType}/interactions`)}
                    >
                      Interactions
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/documents`) === 0,
                      })}
                      onClick={() => history.push(`${urlWithoutType}/documents`)}
                    >
                      Documents
                    </NavLink>
                  </NavItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Switch>
              {/* <CrumbRoute title="profile" path={`${urlWithoutType}/profile`} render={(params: BreadcrumbProps) => <>Profile Placeholder</>} /> */}
              <CrumbRoute
                exact
                title="overview"
                path={`${urlWithoutType}/overview`}
                render={(params: BreadcrumbProps) => <RecordKeeperOverview recordKeeperId={data.id} data={data} {...otherProps} match={params.match} />}
              />
              {/* {auth.checkPermissions(["view:interactions"]) && (
                <CrumbRoute title="interactions" path={`${urlWithoutType}/interactions/:id([A-Za-z0-9]+)?`} render={() => <>Interaction Placeholder</>} />
              )}
              {(auth.checkPermissions(["view:documents"]) ||
                auth.checkPermissions(["view:my_documents"]) ||
                auth.checkPermissions(["view:manager_documents"]) ||
                auth.checkPermissions(["view:all_documents"])) && <CrumbRoute title="documents" path={`${urlWithoutType}/documents`} render={() => <>Documents Placeholder</>} />} */}
            </Switch>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

interface RecordKeeperDetailsOverviewQueryChildren {
  (result: QueryResult<RecordKeeperBasicsQuery, RecordKeeperBasicsQueryVariables> & authProps & RecordKeeperRouteProps): React.ReactNode
}

const RecordKeeperDetailsOverview: RecordKeeperDetailsOverviewQueryChildren = (params) => {
  const { loading, error, data, history, match, location } = params
  const auth = params.auth as Auth
  const routeProps = { history, match, location }
  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <ErrorDisplay error={error}/>
    )
  }
  if (!loading && data) {
    return <RecordKeeperDetailComponent data={data.overview as RecordKeeperBasicsFragment} auth={auth} {...routeProps} />
  }
}

class QueryRecordKeeperDetails extends Component<RecordKeeperRouteProps & Partial<RecordKeeperBasicsQueryVariables> & appProps> {
  render() {
    const id = parseInt(this.props.match.params.recordKeeperId)
    const { auth, history, location, match } = this.props
    const newProps = { auth, history, location, match }
    return (
      <Query<RecordKeeperBasicsQuery, RecordKeeperBasicsQueryVariables> query={RecordKeeperBasicsDocument} variables={{ id }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
        {(params) => {
          if (!params) {
            return <>Null</>
          }
          const allProps = { ...params, ...newProps }
          if (params.loading) {
            return (
              <Col>
                <div className="pane">
                  <PlaceHolder />
                </div>
              </Col>
            )
          }
          if (params.error) {
            return (
              <Col>
                <div className="pane">
                  <UncontrolledAlert color="danger">
                    <h4>Error Fetching Record Keeper</h4>
                    <p>{params.error.message}</p>
                  </UncontrolledAlert>
                </div>
              </Col>
            )
          }
          return <>{RecordKeeperDetailsOverview(allProps)}</>
        }}
      </Query>
    )
  }
}

export default WithTopNav(QueryRecordKeeperDetails)

import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import { FormInputSubClasses } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { FormInput } from "../../ui/Forms/FormInput"

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  optionSource?: string
}

interface RiskStatisticTargetsTableItemProps {
  data: any
  rowDefItem: RowDefItem
  highlightRow?: boolean
  idx: number
  row: number
  editMode: boolean
  updateValue: (idx: number, type: string, value: any, property: string) => void
}

type RiskStatisticTargetsTableProps = {
  data: any
  handleChange: (state: any, property: string) => void
  editMode?: boolean
}

export const RiskStatisticTargetsTableRowDef: RowDefItem[] = [
  {
    field: "excessReturn",
    type: "number",
    subtype: "bps",
    title: "Excess Return (bps)"
  },
  {
    field: "relativeReturn",
    type: "number",
    subtype: "bps",
    title: "Relative Return (bps)"
  },
  {
    field: "trackingError",
    type: "number",
    subtype: "bps",
    title: "Tracking Error (bps)"
  },
  {
    field: "sharpeRatio",
    type: "float",
    title: "Sharpe Ratio"
  },
  {
    field: "informationRatio",
    type: "float",
    title: "Information Ratio"
  },
  {
    field: "equityBeta",
    subtype: "percent",
    type: "float",
    title: "Beta (%)"
  },
  {
    field: "valueAtRisk",
    subtype: "percent",
    type: "float",
    title: "VAR (1Mon @ 95th Percentile) (%)"
  }
]

const RiskStatisticTargetsColDef: RowDefItem[] = [
  {
    field: "low",
    type: "float",
    // subtype: "percent",
    title: "Low",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "high",
    type: "float",
    title: "High",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  }
]

interface RiskStatisticTargetsTableRowProps {
  data: any
  index: number
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

const RiskStatisticTargetsTableRow = ({
  data,
  index,
  rowDef,
  editMode,
  updateValue
}: RiskStatisticTargetsTableRowProps) => {
  let field = rowDef.field
  const value = _.get(data, field)
  if (_.isUndefined(value)) {
    return <tr key={`${index}`} className={"invisible"}></tr>
  }
  return (
    <tr key={`${index}`}>
      <td key={`RiskStatisticTargets-${field}`} className={"text-left"}>
        {rowDef.title}
      </td>
      {RiskStatisticTargetsColDef.map((col, colIndex) => {
        return (
          <RiskStatisticTargetsTableItem
            data={value[col.field]}
            rowDefItem={rowDef}
            key={colIndex}
            idx={colIndex}
            row={index}
            editMode={editMode}
            updateValue={updateValue}
          />
        )
      })}
    </tr>
  )
}

const RiskStatisticTargetsTableItem = ({
  data,
  rowDefItem,
  idx,
  editMode,
  row,
  updateValue
}: RiskStatisticTargetsTableItemProps) => {
  let { field, type, subClasses, subtype } = rowDefItem
  let { type: colType, subtype: colSubtype } = RiskStatisticTargetsColDef[idx]

  return (
    <td key={`${field}-${idx}`}>
      <FormInput
        property={field + "." + RiskStatisticTargetsColDef[idx].field + "rst"}
        displayName={""}
        type={type || colType}
        subtype={subtype || colSubtype}
        idx={row}
        editMode={editMode}
        propertyVal={data}
        placeholder={type === "number" ? "0" : ""}
        subClasses={subClasses}
        updateValue={value => updateValue(idx, value, type, field)}
      />
    </td>
  )
}

const RiskStatisticTargetsTable = (seed: RiskStatisticTargetsTableProps) => {
  const tableName = "RiskStatisticTargets"
  const widthClassName = "col-sm-8"
  const { data, editMode, handleChange } = seed
  const [tableDate, setTableData] = useState(data)

  useEffect(() => {
    setTableData(seed.data)
  }, [seed.data])

  if (!data) {
    return <div>No data</div>
  }

  const onHandleChangeRiskStatisticTargetsRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData =
      getNewStateObject({
        state: tableDate,
        newValue: value,
        property: `${property}.${RiskStatisticTargetsColDef[idx].field}`,
        type
      }) || {}

    handleChange(_.get(newData, property), property)
    setTableData(newData)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size py-2 mb-2"
        }
        key={`${tableName}-0`}
      >
        {`Risk Statistics Targets`}
      </div>
      <div
        className={`${tableName}-table ${widthClassName} row form-group pl-3`}
      >
        <Table
          striped
          hover
          borderless
          size="sm"
          key={`RiskStatisticTargets-table`}
          className={`table-striped ${widthClassName} exportable`}
          data-export-name={"Risk Statistic Targets"}
        >
          <thead>
            <tr className="table-header">
              <th key={0}></th>
              {RiskStatisticTargetsColDef.map((header, headerIdx) => (
                <th key={headerIdx + 1} className="text-right">{header.title}</th>
              ))}
            </tr>
          </thead>
          <tbody key={0}>
            {RiskStatisticTargetsTableRowDef.map((rowDef, rowIndex) => {
              return (
                <RiskStatisticTargetsTableRow
                  index={rowIndex}
                  key={rowIndex}
                  data={tableDate}
                  rowDef={rowDef}
                  editMode={!!editMode}
                  updateValue={onHandleChangeRiskStatisticTargetsRow}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default RiskStatisticTargetsTable

import React, { Component } from 'react';
import { filter, sortBy} from 'lodash'
import { UncontrolledDropdown, DropdownMenu, Row, Col, Form, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConsultantDashboardLayOutFragment } from '../../../__generated__/graphql'
import classNames from 'classnames'

interface DashboardPickerProps {
  dashboards: ConsultantDashboardLayOutFragment[]
	selectedDashboard: ConsultantDashboardLayOutFragment
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (dashboard: ConsultantDashboardLayOutFragment) => void
}

interface DashboardSelection {
  id: number
  name: string
}

const DashboardPicker: React.FC<DashboardPickerProps> = ({ dashboards, title, children, dropdownClass, onClick }: DashboardPickerProps) => {

  return (
    <UncontrolledDropdown className={classNames("headline-dropdown dashboard-picker-dropdown", dropdownClass)}>
      {children}
      <DropdownMenu>
        <h5>{title}</h5>
        <DashboardSearch key={dashboards.length} data={dashboards} onClick={onClick} />
      </DropdownMenu>
    </UncontrolledDropdown>
  )

}

interface DashboardSearchProps {
  data:DashboardSelection[]
  onClick: (dashboard: ConsultantDashboardLayOutFragment) => void

}

export class DashboardSearch extends Component<DashboardSearchProps> {
  state = {
    dashboards: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredDashboards = filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p.name.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      dashboardss: filteredDashboards
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="6">
            <Form>
              <FormGroup row className="relative m-0">
                <Input
                  type="text"
                  placeholder="Find Dashboard by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          {/* fix CAL-1416 sort alphabetically case-insensitive */}
          { sortBy(this.state.dashboards, [(obj)=>(obj.name).toLowerCase()]).map((dashboard) => {
            if (!dashboard) { return <></> }

            return (<ListGroupItem tag="a" key={`pp-dashboard-${dashboard?.id}`} onClick={() => this.props.onClick(dashboard as ConsultantDashboardLayOutFragment)}>{dashboard?.name}</ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

export default DashboardPicker
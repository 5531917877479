import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import moment, { Moment } from "moment"
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import {
  Col, Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Popover,
  PopoverBody,
  Row,
  UncontrolledPopover
} from "reactstrap"

import { appDate } from "../../../Context/CalendarContext"
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from "../../../helpers/constant"
import { getInitialYearRange } from "../../CalendarPicker"

interface MonthPickerProps {
  id: string
  name?: string
  label?: string
  value?: string
  disabled?: boolean
  required?: boolean
  period: "month" | "quarter" | "monthText"
  updateValue: (value: any) => void
  runValidation?: (value:string) => void
  containerId?: string
  inputClasses?: string
  min?: string
  max?: string
}

const quarterList = [
  {name: "Q1", value: 1},
  {name: "Q2", value: 2},
  {name: "Q3", value: 3},
  {name: "Q4", value: 4}
]

const yearOffsetList = [0,1,2,3,4,5,6,7,8,9,10,11]

const monthFormat = DATE_DISPLAY_FORMAT
const monthTextFormat = "MMMM YYYY"
const quarterFormat = "[Q]Q YYYY"

const defaultMaxDate = moment(appDate).add(12, "years")
const defaultMinDate = moment('1900-01-01')

export const MonthPicker: React.FC<MonthPickerProps> = ({
  id,
  name,
  label,
  value,
  disabled: inputDisabled,
  required: inputRequired,
  period,
  updateValue,
  runValidation,
  containerId,
  inputClasses,
  min,
  max,
}) => {
  let disabled: boolean = !!inputDisabled
  let required: boolean = !!inputRequired
  const bodyRef = useRef<MonthPickerBodyHandle>(null)
  const displayFormat = period === "month" ? monthFormat : (period === "monthText" ? monthTextFormat: quarterFormat)
  const [formattedValue, setFormattedValue] = useState("")
  const [ openPicker, setOpenPicker ] = useState(false)
  const togglePicker = () => !disabled && setOpenPicker(!openPicker)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (value && moment(value, DATE_API_FORMAT).isValid()) {
      const date = moment(value, DATE_API_FORMAT)
      const formattedDate = date.endOf("month").format(displayFormat)
      setFormattedValue(formattedDate)
    }
}, [period, disabled, value])

  const handleClick = (e:any) => {
    if (dropdownRef?.current?.contains(e.target) || inputRef?.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpenPicker(false)
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const clearValue = () => {
    if(bodyRef.current) bodyRef.current.setSelectedDate(moment(appDate))
    setFormattedValue("")
    updateValue("")
    if(runValidation) runValidation("")
  }

  return (
    <div ref={inputRef}>
      <InputGroup onClick={() => togglePicker()}>
        <div
          id={`_${id}`}
          className={classnames(inputClasses, "month-picker-input fake-input form-control transparent", {disabled})}
        >
          {formattedValue}
        </div>
        {!disabled && !required && (
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <FontAwesomeIcon icon="times-circle" onClick={clearValue} />
            </InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
      <Popover
        placement="bottom"
        target={`_${id}`}
        // trigger="legacy"
        popperClassName={classnames(inputClasses, "month-picker")}
        disabled={disabled}
        // enforce popover shows above current container
        container={containerId || "root"}
        className="calendar-dropdown"
        isOpen={openPicker}
        toggle={() => togglePicker()}
      >
        <PopoverBody className="border-0">
          <MonthPickerBody
            ref={bodyRef}
            value={value}
            period={period}
            updateValue={updateValue}
            min={min}
            max={max}
            setFormattedValue={setFormattedValue}
            divRef={dropdownRef}
          />
        </PopoverBody>
      </Popover>
    </div>
  )
}

export type MonthPickerBodyHandle = {
  selectedDate: Moment;
  setSelectedDate: (value:Moment) => void;
};

interface MonthPickerBodyProps {
  value?: string
  period: "month" | "quarter" | "monthText"
  updateValue: (event: any) => void
  min?: string
  max?: string
  setFormattedValue?: (value: string) => void
  divRef?: React.RefObject<HTMLDivElement>
  ref?: React.Ref<MonthPickerBodyHandle>
}

export const MonthPickerBody: React.FC<MonthPickerBodyProps> = forwardRef((props, ref) => {
  const { value, period, updateValue, min, max, setFormattedValue, divRef } = props
  const displayFormat = period === "month" ? monthFormat : (period === "monthText" ? monthTextFormat: quarterFormat)
  const isMonth = period === "month" || period === "monthText"
  const [selectedDate, setSelectedDate] = useState(moment(appDate))
  const [year, setYear] = useState(appDate.year())
  const [ yearRange, setYearRange ] = useState(getInitialYearRange(appDate, 12))
  const minDate = min ? moment(min, DATE_API_FORMAT) : defaultMinDate
  const maxDate = max ? moment(max, DATE_API_FORMAT) : defaultMaxDate
  const months = moment.monthsShort()

  useEffect(() => {
    const newFormat = period === "month" ? monthFormat : (period === "monthText" ? monthTextFormat: quarterFormat)
    if (value && moment(value, DATE_API_FORMAT).isValid()) {
      const date = moment(value, DATE_API_FORMAT)
      const formattedDate = date.endOf("month").format(displayFormat)
      if(setFormattedValue) setFormattedValue(formattedDate)
      setYear(date.year())
      setSelectedDate(date)
      setYearRange(getInitialYearRange(date, 12))
    } else {
      if(setFormattedValue) setFormattedValue("")
      setYear(appDate.year())
      setSelectedDate(moment(appDate))
      setYearRange(getInitialYearRange(appDate, 12))
    }
  }, [value, displayFormat, period])

  const setSelectedYearRange = (yearRange:number) => {
    if(yearRange > maxDate.year() || (yearRange + 11) < minDate.year()){
      return
    }
    setYearRange(yearRange)
  }

  const validDate = (date: moment.Moment) => {
    return date.isBetween(minDate, maxDate, "month", "()")
  }

  const setSelectedYear = (year_number:number) => {
    if(year_number > maxDate.year() || year_number < minDate.year()){
      return
    }
    const newDate = moment(selectedDate).year(year_number).endOf("month")
    if(!validDate(newDate)) return
    setYear(newDate.year())
    setSelectedDate(newDate)
    if(setFormattedValue) setFormattedValue(newDate.format(displayFormat))
    updateValue(newDate.format(DATE_API_FORMAT))
  }

  const setSelectedMonth = (month: number) => {
    const newDate = moment(selectedDate).month(month).endOf("month")
    if(!validDate(newDate)) return
    setSelectedDate(newDate)
    if(setFormattedValue) setFormattedValue(newDate.format(displayFormat))
    updateValue(newDate.format(DATE_API_FORMAT))
  }

  const setSelectedQuarter = (quarter: number) => {
    const newDate = moment(selectedDate).quarter(quarter).endOf("month")
    if(!validDate(newDate)) return
    setSelectedDate(newDate)
    if(setFormattedValue) setFormattedValue(newDate.format(displayFormat))
    updateValue(newDate.format(DATE_API_FORMAT))
  }

  useImperativeHandle(ref, () => {
    return {
      selectedDate: selectedDate,
      setSelectedDate(value:Moment) {
        setSelectedDate(value)
      },
    };
  }, [selectedDate]);

  return(
    <div ref={divRef}>
      {isMonth &&
        <>
          <div className="calendar-navigator mb-2">
            <Row>
              <Col xs="3" onClick={() => setSelectedYear(year - 1)} className={classnames({ 'calendar-item': true, disabled: !validDate(moment(selectedDate).subtract(1, 'year')) })}>-</Col>
              <Col xs="6">{year}</Col>
              <Col xs="3" onClick={() => setSelectedYear(year + 1)} className={classnames({ 'calendar-item': true, disabled: !validDate(moment(selectedDate).add(1, 'year')) })}>+</Col>
            </Row>
          </div>
          <div className="month calendar-options mb-2">
            <Row>
              {months.map((monthObject, idx) => (
                <Col
                  key={idx}
                  xs="3"
                  onClick={() => setSelectedMonth(idx)}
                  className={classnames({ 'calendar-item': true, active: selectedDate.year() === year && selectedDate.month() === (idx), disabled: !validDate(moment(`${year}-${idx+1}-01`).endOf('month')) })}
                >
                  {monthObject}
                </Col>
              ))}
            </Row>
          </div>
        </>
      }
      {period === "quarter" &&
        <>
          <div className="quarter calendar-options mb-2">
            <Row>
              {quarterList.map((quarterObject) => (
                <Col
                  key={quarterObject.value}
                  xs="3"
                  onClick={() => setSelectedQuarter(quarterObject.value)}
                  className={classnames({ 'calendar-item': true, active: selectedDate.year() === year && selectedDate.quarter() === quarterObject.value, disabled: !validDate(moment(`${year}-${quarterObject.value*3}-01`).endOf('month')) })}
                >
                  {quarterObject.name}
                </Col>
              ))}
            </Row>
          </div>
          <div className="calendar-navigator mb-2">
            <Row>
              <Col xs="3" onClick={() => setSelectedYearRange(yearRange-12)} className={classnames({ 'calendar-item': true, disabled: (yearRange-1 < minDate.year()) })}>-</Col>
              <Col xs="6">{yearRange} - {yearRange+11}</Col>
              <Col xs="3" onClick={() => setSelectedYearRange(yearRange+12)} className={classnames({ 'calendar-item': true, disabled: (yearRange+12 > maxDate.year()) })}>+</Col>
            </Row>
          </div>
          <div className="month calendar-options mb-2">
            <Row>
              {yearOffsetList.map((yearOffset) => (
                <Col
                  key={yearOffset}
                  xs="3"
                  onClick={() => setSelectedYear(yearRange + yearOffset)}
                  className={classnames({ 'calendar-item': true, disabled: !validDate(moment(`${yearRange+yearOffset}-${selectedDate.month() + 1}-01`).endOf('month')), active: selectedDate.year() === yearRange + yearOffset })}
                >
                  {yearRange + yearOffset}
                </Col>
              ))}
            </Row>
          </div>
        </>
      }
      {/* nice string */}
      <p className="mb-0 text-center"><span className="text-capitalize">{isMonth? "month": period}</span> ending on {selectedDate.format("MMMM Do, YYYY")}</p>
    </div>
  )
})
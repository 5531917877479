import React, { Component } from 'react'
import { Breadcrumbs } from 'react-breadcrumbs'
import { Link, RouteComponentProps } from 'react-router-dom'
import { NavLink } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { LocationProps } from '../../queries/extended/appProps'
import { DashBoard } from '../Shared/DashBoard'
import Footer from '../Shared/Footer'
import Header from '../Shared/Header'
import TemporaryAlert from '../Shared/TemporaryAlert'
import { SideBar } from '../SideBar'

interface authProps {
  auth: Auth
}

export type  MatchParams = {
  [name:string]: string | undefined
}

export interface TopNavProps extends  RouteComponentProps<MatchParams> {
}

export function WithTopNav<T extends authProps & TopNavProps & Record<string, any>>(
  WrappedComponent: React.ComponentType<T>,
  sideBarDisplay = false,
  data = {}
) {
  return class extends Component<T> {
    render() {
      const { logout } = this.props.auth
      return (
        // root div
        <>
          <>
            {/* header part */}
            <Header auth={this.props.auth} location={this.props.location as LocationProps} history={this.props.history} match={this.props.match}>
              <NavLink
                className="py-0 button header-text"
                onClick={() => logout()}
                tag={Link}
                to="/"
              >
                Log out
              </NavLink>
            </Header>
          </>
          <div className="d-flex" id="outer-container">
            <TemporaryAlert />
            <DashBoard {...this.props}>
              <WrappedComponent {...(this.props as T)} />
            </DashBoard>
          </div>
          <Footer />
        </>
      )
    }
  }
}

import React, { useEffect, useState } from "react"
import { History } from "history"
import Auth from "../../../Auth/Auth"
import { match } from "react-router-dom"
import { Maybe, useGetRolesQuery, useManageUserListQuery } from "../../../__generated__/graphql"
import { ManageUserColumnDef, userRolesComparator } from "./ControlCenterColumnDef"
import { FilterChangedEvent, GridReadyEvent } from "@ag-grid-community/core"
import { ErrorComponent, LoadingComponent } from "../../Report/Shared/ReportComponent"
import RouteLeavingGuard from "../../Shared/RouteLeavingGuard"
import { Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { compact } from "lodash"
import { AddNewUserModal } from "./AddNewUserModal"
import { createManagerUsersDatasource } from "../../Shared/SSRM/DataSource/ManageUsersDataSource"
import SSRMTable from "../../Shared/SSRM/SSRMTable"
import { SortModelDirection } from "../../ResearchProducts/FilterViewModal"

type ManageUsersProps = {
  history: History
  auth: Auth
  match: match
}

enum SavingStatus {
  Initial = 1,
  CreatingUser = 2,
  UpdatingUser = 3,
  DeletingUser = 4,
  Error = 5,
}

export const dnaTableShowRoles = [
  { code: "rol_0IfxmbiDLyaQZObY", value: "callan" },
  { code: "rol_qx4VqUpwvW2kp9Ho", value: "client_fs" },
  { code: "rol_ar3Mval3J5OVcAr6", value: "client_iag" },
  { code: "rol_3I8km8KeT27lWXW6", value: "manager" },
]

export const dnaTableSubRoles = [
  { code: "rol_jdZe3FzLKAn53vuW", value: "client_hf_doc" },
  { code: "rol_ehyCKtSSacHsRfEW", value: "client_pd_doc" },
  { code: "rol_A4evTGW8To1JWKjZ", value: "client_pe_doc" },
  { code: "rol_xNqApf3t0IGBXLuU", value: "client_ra_doc" },
]

const RolesOrders = [...dnaTableShowRoles, ...dnaTableSubRoles]

const dnaAddableRoles = dnaTableShowRoles.filter((el, idx) => idx > 0)

const ClientDNARoles = dnaTableShowRoles.filter(el => el.value.includes("client"))

export const hasClientRole = (roles: Maybe<{ code?: Maybe<string>; value?: Maybe<string> }>[]) => !!roles.find(role => ClientDNARoles.find(dnaRole => dnaRole.code === role?.code))

// display all roles for fetched table users.(show all roles for each user)
const getAllRoles = (roles: Maybe<{ code?: Maybe<string>; value?: Maybe<string> }>[]) => {
  let result = compact(roles).map(({ code, value }) => ({ code: code || "", value: value?.toLowerCase() || "" }))
  let sorted = result.sort((a: any, b: any) => userRolesComparator(a, b, RolesOrders))
  return sorted
}

// addable roles for add-new/update user modal.(what roles to show in modal)
const getMainRolesOptions = (roles: Maybe<{ code?: Maybe<string>; value?: Maybe<string> }>[]) => {
  return compact(roles)
    .map(({ code, value }) => ({ code: code || "", value: value?.toLowerCase() || "" }))
    .filter(({ value }) => dnaAddableRoles.some(({ value: dnaValue }) => value === dnaValue))
}

const getSubRolesOptions = (roles: Maybe<{ code?: Maybe<string>; value?: Maybe<string> }>[]) => {
  return compact(roles)
    .map(({ code, value }) => ({ code: code || "", value: value?.toLowerCase() || "" }))
    .filter(({ value }) => dnaTableSubRoles.some(({ value: dnaValue }) => value === dnaValue))
}

const ManageUsersComponent: React.FC<ManageUsersProps> = (props) => {
  const { auth, history } = props
  const [editMode, setEditMode] = useState<boolean>(false)

  // add new user modal
  const [isAddNewUserModalOpen, setAddNewUserModalOpen] = useState(false)
  const toggleAddNewUserModal = () => setAddNewUserModalOpen(!isAddNewUserModalOpen)

  const [gridReadyEvent, setGridApi] = useState<Maybe<GridReadyEvent>>(null)

  // used so that filter changes update the grid
  const [filterUpdates, setFilterUpdates] = useState(0)

  const { loading: rolesLoading, data: { roles: rolesData } = {}, error: rolesError } = useGetRolesQuery()

  const [dataLoading, setDataLoading] = useState<boolean>(true)

  let variables = { filters: { limit: 100, roles: [] } }
  const { loading, error, refetch } = useManageUserListQuery({ fetchPolicy: "network-only", variables })

  const onSSRMReady = (params: GridReadyEvent) => {
    let { api, columnApi } = params
    setGridApi(params)
    // dataSource from default settings.
    const dataSource = createManagerUsersDatasource(refetch, variables, {}, params)
    api!.setServerSideDatasource(dataSource)
    if (api) {
      let dnaRolesFilterModel = {
        roles: {
          values: dnaTableShowRoles.map(({ value }) => value),
          filterType: "set",
        },
      }
      let filterModel = dnaRolesFilterModel
      setFilterUpdates(filterUpdates + 1)
      api.setFilterModel(filterModel)
    }
    if (columnApi) {
      const defaultSortModel = {
        colId: "name",
        sort: "asc" as SortModelDirection,
      }
      let sortModel = [defaultSortModel]
      columnApi.applyColumnState({ state: sortModel })
    }
  }

  useEffect(() => {
    if (rolesData) {
      refetch()
    }
  }, [rolesData, refetch])

  let roles: { code: string; value: string }[] = []
  let addableRoles: { code: string; value: string }[] = []
  let subRolesOptions: { code: string; value: string }[] = []
  if (loading || rolesLoading) return <LoadingComponent />
  if (error || rolesError) return <ErrorComponent error={error?.message} />
  if (rolesData) {
    roles = getAllRoles(rolesData)
    addableRoles = getMainRolesOptions(rolesData)
    subRolesOptions = getSubRolesOptions(rolesData)
  }

  const colDef = ManageUserColumnDef({ editMode, roles })
  const addPermission = auth.checkPermissions(["create:user"])
  return (
    <>
      <RouteLeavingGuard when={editMode} navigate={(path) => history.push(path)} />
      <div className='pane pane-toolbar sticky-top above-picker w-100'>
        {addPermission && (
          <Button
            color='light btn-thin'
            className='mr-1 text-callan-blue'
            onClick={toggleAddNewUserModal}
            id={"create-person-button"}
          >
            Add User
            <FontAwesomeIcon icon='plus-circle' className='ml-2 text-callan-blue' />
          </Button>
        )}
        <AddNewUserModal
          key='add-new'
          modalOpen={isAddNewUserModalOpen}
          setModalOpen={setAddNewUserModalOpen}
          callBackIfSuccess={refetch}
          roleOptions={addableRoles}
          subRolesOptions={subRolesOptions}
          allRoles={roles}
        />
      </div>
      {!!refetch && (
        <SSRMTable
          key={"ssrm"}
          serverSide={true}
          loading={dataLoading}
          columnDefs={colDef}
          rowId={"id"}
          onReady={onSSRMReady}
          onFilterChanged={(event: FilterChangedEvent) => {
            let model = event?.api.getFilterModel()
            setFilterUpdates(filterUpdates + 1)
          }}
          pageSize={100}
          // exportParams={customExport(type, columnDefs, assetClasses)}
        />
      )}
    </>
  )
}

export default ManageUsersComponent

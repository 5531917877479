import React, { Component } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { get, cloneDeep, set, compact,isNil, sortBy, find } from 'lodash'
import { Table} from 'reactstrap'

import { PerformancePeriodType, PortfolioCurrencyCode, ClosedEndedPrivateNonRegisteredMulitAssetClass, ClosedEndedPrivateNonRegisteredPrivateCredit, ClosedEndedPrivateNonRegisteredPrivateEquity, ClosedEndedPrivateNonRegisteredRealAssets, VehiclePerformanceCashflowSummaryQuery, CashFlowSummary, CashFlowSummaryVintageYear, CashFlowsSummaryInput, GetLookupQuery } from '../../../__generated__/graphql'

import { FormInput } from '../../ui/Forms/FormInput'
import classNames from 'classnames'

import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { ApolloQueryResult } from '@apollo/client'
import { DATE_API_FORMAT } from '../../../helpers/constant'
import { INTROSPECTION_QUERY } from '../../../queries/Lookup'
import { ProductVehiclePerformanceReturnRouteProps } from '../../../queries/extended/types/Product'
import { Query } from '@apollo/client/react/components'
import PlaceHolder from '../../ui/PlaceHolder'

const moment = extendMoment(Moment);

type AllowedVehicleType = ClosedEndedPrivateNonRegisteredMulitAssetClass | ClosedEndedPrivateNonRegisteredPrivateCredit | ClosedEndedPrivateNonRegisteredPrivateEquity | ClosedEndedPrivateNonRegisteredRealAssets

interface ProductPerformanceVehiclesCashflowSummaryProps extends ProductVehiclePerformanceReturnRouteProps {
  id: string
  editMode: boolean
  date: string
  period: PerformancePeriodType
  data: VehiclePerformanceCashflowSummaryQuery
  handleChange: (newDiff:CashFlowsSummaryInput) => void
  editCancelled: boolean
  clearChanges: boolean
  refetch: (variables?: Record<string, any> | undefined) => Promise<ApolloQueryResult<VehiclePerformanceCashflowSummaryQuery>>
}

interface ProductPerformanceVehiclesState {
  stateDiff: CashFlowsSummaryInput
  activeTab: 'total' | 'vintage'
}

export const blankCashFlowSummary = (date:string):CashFlowSummary => {
  return {
    __typename: 'CashFlowSummary',
    periodEndDate: date,
    currency: null,
    totalPartnerships: null,
    activePartnerships: null,
    liquidatedPartnerships: null,
    activeCompanies: null,
    committedCapital: null,
    paidInCapital: null,
    uncalledCapital: null,
    distributedCapital: null,
    netAssetValue: null,
    grossIRR: null,
    netIRR: null
  }
}

export const blankCashFlowVintageSummary = (date:string):CashFlowSummaryVintageYear => {
  return {
    __typename: 'CashFlowSummaryVintageYear',
    vintageYear: moment(date, DATE_API_FORMAT).year(),
    cashFlowSummary: {
      __typename: 'CashFlowSummary',
      periodEndDate: date,
      currency: null,
      totalPartnerships: null,
      activePartnerships: null,
      liquidatedPartnerships: null,
      activeCompanies: null,
      committedCapital: null,
      paidInCapital: null,
      uncalledCapital: null,
      distributedCapital: null,
      netAssetValue: null,
      grossIRR: null,
      netIRR: null
    }
  }
}

class ProductPerformanceVehiclesCashflowSummary extends Component<ProductPerformanceVehiclesCashflowSummaryProps,ProductPerformanceVehiclesState> {
  constructor(props: ProductPerformanceVehiclesCashflowSummaryProps) {
    super(props)

    let tab = props.match.params.summaryTab || 'total'
    this.state = {
      activeTab: tab,
      stateDiff: {}
    }
  }



  static getDerivedStateFromProps(nextProps:ProductPerformanceVehiclesCashflowSummaryProps, prevState:ProductPerformanceVehiclesState){
    if(nextProps.editCancelled || nextProps.clearChanges) {
      return { stateDiff: {} };
    }
    return null;
  }

  setStateDiff = (newDiff:CashFlowsSummaryInput) => {
    this.setState({stateDiff: newDiff}, () => {
      this.props.handleChange(newDiff)
    })
  }

  onTotalChange = (property: keyof CashFlowSummary, value:number) => {
    const cashFlowSummary = this.props.data.vehicle_cashflow_summary?.vehicle?.cashFlowsSummary?.cashFlowSummary || blankCashFlowSummary(this.props.date)
    let cashFlowSummaryInput = cloneDeep(this.state.stateDiff)

    if (isNil(cashFlowSummaryInput.cashFlowSummary?.periodEndDate)) {
      set(cashFlowSummaryInput, ['cashFlowSummary', 'periodEndDate'], cashFlowSummary?.periodEndDate)
      set(cashFlowSummaryInput, ['cashFlowSummary', 'currency'], cashFlowSummary?.currency?.code || PortfolioCurrencyCode.USADOL)
    }

    set(cashFlowSummaryInput, ['cashFlowSummary', property], value)
    this.setStateDiff(cashFlowSummaryInput as CashFlowsSummaryInput)
  }

  onVintageChange = (year: number, property: keyof CashFlowSummary, value:number | string | null) => {
    const cashFlowSummary = find(this.props.data.vehicle_cashflow_summary?.vehicle?.cashFlowsSummary?.cashFlowSummaryVintageYear, ["vintageYear", year]) || blankCashFlowVintageSummary(this.props.date)
    let cashFlowSummaryInput = cloneDeep(this.state.stateDiff)
    let cashFlowSummaryVintageYearInput = cloneDeep(cashFlowSummaryInput.cashFlowSummaryVintageYear)

    if (isNil(cashFlowSummaryVintageYearInput)) {
        cashFlowSummaryVintageYearInput = []
      }

    let yearDataIndex = cashFlowSummaryVintageYearInput.findIndex(yearD => yearD?.vintageYear === year)

    let cashFlowVintageInput = cloneDeep(get(cashFlowSummaryVintageYearInput, yearDataIndex))
    if (yearDataIndex < 0 || isNil(cashFlowVintageInput)) {
      cashFlowVintageInput = {
        vintageYear: year,
        cashFlowSummary: {
          periodEndDate: cashFlowSummary?.cashFlowSummary?.periodEndDate,
          currency: cashFlowSummary?.cashFlowSummary?.currency?.code || PortfolioCurrencyCode.USADOL
        }
      }
    }

    set(cashFlowVintageInput, ["cashFlowSummary", property], value)
    if (yearDataIndex < 0) {
      cashFlowSummaryVintageYearInput.push(cashFlowVintageInput)
    } else {
      cashFlowSummaryVintageYearInput[yearDataIndex] = cashFlowVintageInput
    }

    set(cashFlowSummaryInput, 'cashFlowSummaryVintageYear', cashFlowSummaryVintageYearInput)

    this.setStateDiff(cashFlowSummaryInput as CashFlowsSummaryInput)
  }

  switchTab = (tab: "total" | "vintage") => {
    this.setState({activeTab: tab})
  }

  render() {
    const { id, editMode, date, period, data } = this.props
    const vehicle = data.vehicle_cashflow_summary?.vehicle

    if (vehicle && !isNil(vehicle.cashFlowsSummary)) {
      const cashflowSummary = vehicle.cashFlowsSummary

      return (
        <>
        <ul className={"horizontal-picker"}>
          <li className={classNames("horizontal-picker-button",{ active: this.state.activeTab === "total" })}  onClick={() => {
            this.switchTab('total')
          }} >Total</li>
          <li className={classNames("horizontal-picker-button",{ active: this.state.activeTab === "vintage" })}  onClick={() => {
            this.switchTab('vintage')
            // this.setState({ activeTab: "vintage"})
          }} >Vintage</li>
        </ul>
        <div className="table-container" key="vehicles-cashflow-summary">
          { this.state.activeTab === "total" &&
            <TotalTable
              cashflowSummary={cashflowSummary.cashFlowSummary || blankCashFlowSummary(this.props.date)}
              editMode={editMode}
              updateValue={this.onTotalChange}
              date={date}
              key="vehicles-cashflow-summary-total-table"
            />
          }
          { this.state.activeTab === "vintage" &&
            <VintageTable
              vintageYears={compact(cashflowSummary.cashFlowSummaryVintageYear)}
              editMode={editMode}
              updateValue={this.onVintageChange}
              editCancelled={this.props.editCancelled}
              date={date}
              key="vehicles-cashflow-summary-vintage-table"
            />
          }
        </div>
        </>
      )

    }

    return <></>
  }
}

interface TableColumn {
  key: keyof CashFlowSummary | "currency.code"
  display: string
  type: string
  subtype: string
  editDecimalPlaces?: number
}

const SummaryFields:TableColumn[] = [
  { key: 'currency.code', display: 'Currency', type: 'select', subtype: 'single' },
  { key: 'totalPartnerships', display: "Total Partnerships", type: "number", subtype: ""},
  { key: 'activePartnerships', display: "Active Partnerships", type: "number", subtype: ""},
  { key: 'liquidatedPartnerships', display: "Liquidated Partnerships", type: "number", subtype: ""},
  { key: 'activeCompanies', display: "Active Companies", type: "number", subtype: ""},
  { key: 'committedCapital', display: "Commited Capital", type: "float", subtype: "currency"},
  { key: 'paidInCapital', display: "Paid in Capital", type: "float", subtype: "currency"},
  { key: 'uncalledCapital', display: "Uncalled Capital", type: "float", subtype: "currency"},
  { key: 'distributedCapital', display: "Distributed Capital", type: "float", subtype: "currency"},
  { key: 'netAssetValue', display: "NAV", type: "float", subtype: "currency"},
  { key: 'grossIRR', display: "Gross IRR", type: "float", subtype: "percent", editDecimalPlaces: 4},
  { key: 'netIRR', display: "Net IRR", type: "float", subtype: "percent", editDecimalPlaces: 4},
]

const VintageFields:TableColumn[] = [
  { key: 'currency.code', display: 'Currency', type: "select", subtype: "single"},
  { key: 'totalPartnerships', display: '# Commitments', type: 'number', subtype: ''},
  { key: 'activePartnerships', display: '# Active Commitments', type: 'number', subtype: ''},
  { key: 'committedCapital', display: "Commited Capital", type: "float", subtype: "currency"},
  { key: 'paidInCapital', display: "Paid in Capital", type: "float", subtype: "currency"},
  { key: 'uncalledCapital', display: "Uncalled Capital", type: "float", subtype: "currency"},
  { key: 'distributedCapital', display: "Distributed Capital", type: "float", subtype: "currency"},
  { key: 'netAssetValue', display: "NAV", type: "float", subtype: "currency"},
  { key: 'grossIRR', display: "Gross IRR", type: "float", subtype: "percent", editDecimalPlaces: 4},
  { key: 'netIRR', display: "Net IRR", type: "float", subtype: "percent", editDecimalPlaces: 4},
]

interface TotalTableProps {
  cashflowSummary: CashFlowSummary
  editMode: boolean
  updateValue: (property: keyof CashFlowSummary, value:number) => void
  date: string
}

interface TotalTableState {
  currentState: CashFlowSummary
  initialState: CashFlowSummary
}



export class TotalTable extends Component<TotalTableProps,TotalTableState> {
  constructor(props: TotalTableProps) {
    super(props)

    const { cashflowSummary } = props
    this.state = {
      currentState: cashflowSummary,
      initialState: cashflowSummary
    }
  }

  onChange = (property: keyof CashFlowSummary | 'currency.code', value:number) => {
    let newState = cloneDeep(this.state.currentState)

    set(newState, property, value)

    this.setState({ currentState: newState }, () => {
      this.props.updateValue(property == 'currency.code' ? 'currency' : property, value)
    })
  }

  render() {
    return (
      <Table hover className="table-bordered-internal col-sm-4">
        <thead>
          <tr>
            <th></th>
            <th className="text-right">Current</th>
          </tr>
        </thead>
        <tbody>

          {SummaryFields.map((row, idx) => {
            let value = get(this.state.currentState, row.key)
            if (row.key == "currency.code") {
              return(
                <tr key={`total-row-${row.key}`}>
                  <td className="text-left">
                    {row.display}
                  </td>
                  <td>
                    <FormInput
                      property={row.key}
                      type={row.type}
                      subtype={row.subtype}
                      showZero={true}
                      optionSource="PortfolioCurrencyCode"
                      idx={`cashflow-summary-${row.key}`}
                      editMode={this.props.editMode}
                      propertyVal={value}
                      updateValue={(value) => this.onChange(row.key, value)}
                      subClasses={{ inputClasses: 'ml-0'}}
                    />
                  </td>
                </tr>
              )
            }

            return(
              <tr key={`total-row-${row.key}`}>
                <td className="text-left">
                  {row.display}
                </td>
                <td>
                  <FormInput
                    property={row.key}
                    type={row.type}
                    subtype={row.subtype}
                    showZero={true}
                    idx={`cashflow-summary-${row.key}`}
                    editMode={this.props.editMode}
                    propertyVal={value}
                    updateValue={(value) => this.onChange(row.key, value)}
                    editDecimalPlaces={row.editDecimalPlaces}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

interface VintageTableProps {
  vintageYears: CashFlowSummaryVintageYear[]
  editMode: boolean
  updateValue: (year: number, property: keyof CashFlowSummary, value:number | string | null ) => void
  editCancelled: boolean
  date: string
}

interface VintageTableState {
  currentState: CashFlowSummaryVintageYear[]
  initialState: CashFlowSummaryVintageYear[]
}

export class VintageTable extends Component<VintageTableProps,VintageTableState> {
  constructor(props: VintageTableProps) {
    super(props)

    const { vintageYears } = props

    const years = vintageYears.map(yearData => yearData.vintageYear)
    const availableYears = 50
    const yearRange = Array.from(moment.rangeFromInterval('year', availableYears, moment().subtract(availableYears, 'years')).by('year')).reverse()
    let yearDataCollection:CashFlowSummaryVintageYear[] = []
    yearRange.map((year) => {
      let vintageYearData:CashFlowSummaryVintageYear | null = vintageYears.find(d => d.vintageYear == year.year()) || null

      if (isNil(vintageYearData)) {
        vintageYearData = {
          __typename: 'CashFlowSummaryVintageYear',
          vintageYear: year.year(),
          cashFlowSummary: blankCashFlowSummary(this.props.date)
        }
      }

      yearDataCollection.push(vintageYearData)
    })
    this.state = {
      currentState: yearDataCollection,
      initialState: yearDataCollection,
    }
  }

  static getDerivedStateFromProps(nextProps:VintageTableProps, prevState:VintageTableState){
    if (nextProps.editCancelled) {
      return { currentState: nextProps.vintageYears }
    }

    return null;
  }

  onChange = (year: number, property: keyof CashFlowSummary | 'currency.code', value:number | string | null) => {
    let newState = cloneDeep(this.state.currentState)
    let yearDataIndex = newState.findIndex(yearD => yearD.vintageYear === year)

    if (property == "currency.code" && value === "") {
      value = null
    }

    if (yearDataIndex >= 0) {
      set(newState, [yearDataIndex, "cashFlowSummary", property].join('.'), value)

    } else {
      let yearData:CashFlowSummaryVintageYear = {
        __typename: 'CashFlowSummaryVintageYear',
        vintageYear: year,
        cashFlowSummary: blankCashFlowSummary(this.props.date)
      }

      set(yearData, ['cashFlowSummary', property].join('.'), value)
      newState.push(yearData)
    }

    this.setState({ currentState: newState }, () => {
      this.props.updateValue(year, property == 'currency.code' ? 'currency' : property, value)
    })
  }

  vintageRowHasData = (vintageData:CashFlowSummaryVintageYear) => {
    const summary = vintageData.cashFlowSummary
    return !isNil(summary?.totalPartnerships) || !isNil(summary?.activePartnerships) || !isNil(summary?.liquidatedPartnerships) || !isNil(summary?.activeCompanies) || !isNil(summary?.committedCapital) || !isNil(summary?.paidInCapital) || !isNil(summary?.uncalledCapital) || !isNil(summary?.distributedCapital) || !isNil(summary?.netAssetValue) || !isNil(summary?.grossIRR) || !isNil(summary?.netIRR)
  }

  render() {
    const years = this.state.currentState.map(yearData => yearData.vintageYear)
    const availableYears = 50
    const yearRange = Array.from(moment.rangeFromInterval('year', availableYears, moment().subtract(availableYears, 'years')).by('year')).reverse()

    return (
      <Query<GetLookupQuery> query={INTROSPECTION_QUERY} variables={{ name: 'PortfolioCurrencyCode' }} fetchPolicy="network-only" notifyOnNetworkStatusChange={true}>
        {
          ({loading, error, data}) => {
            if (loading) {
              return <PlaceHolder />
            }
            const allCurrencyOptions = sortBy(data?.__type?.enumValues?.filter(v => v.value != "-"),'value') || []

            return (
              <Table hover className="table-bordered-internal col-sm-4" key="vintage-table">
                <thead>
                  <tr>
                    <th>Vintage Year</th>
                    { VintageFields.map(col => {
                      return (<th key={`vintage-year-col-${col.display}`} className="text-right width-160">{col.display}</th>)
                    })}
                  </tr>
                </thead>
                <tbody>
                  { yearRange.map((year) => {
                   const vintageYearData: CashFlowSummaryVintageYear | null = this.state.currentState.find(d => d.vintageYear == year.year()) || null

                    if (vintageYearData && vintageYearData?.cashFlowSummary && vintageYearData?.vintageYear && (this.vintageRowHasData(vintageYearData) || this.props.editMode)) {
                      return(
                        <AssetClassRow
                          key={`cashflowSummary-row-${year.year()}`}
                          data={vintageYearData.cashFlowSummary}
                          year={vintageYearData.vintageYear}
                          editMode={this.props.editMode}
                          updateValue={this.onChange}
                          columns={VintageFields}
                          allCurrencyOptions={allCurrencyOptions}
                        />
                      )
                    }

                    return <React.Fragment key={`vintage-year-blank-${year}`}></React.Fragment>
                  })}
                </tbody>
              </Table>
            )

          }
        }
      </Query>
    )
  }
}

interface CurrencyOption {
  __typename: "__EnumValue";
  code: string;
  value?: string | null | undefined;
}

interface AssetClassRowProps {
  data: CashFlowSummary
  year: number
  editMode: boolean
  updateValue: (year: number, property: keyof CashFlowSummary | 'currency.code', value:number) => void
  columns: TableColumn[]
  allCurrencyOptions?: CurrencyOption[]
}

export const AssetClassRow = ({data, year, editMode, updateValue, columns, allCurrencyOptions}:AssetClassRowProps) => {
  return (
    <tr key={`vintage-year-${year}`}>
      <td className="text-nowrap text-left">
        { year }
      </td>
      {
        columns.map((field, idx) => {
          const propertyVal = get(data, field.key)
          if (field.key == "currency.code") {

            let options = allCurrencyOptions?.map(o => <option key={`summary-${year}-currency-opt-${o.code}`} value={o.code} >{o.value}</option>)
              options?.unshift(<option key={`summary-${year}-currency-opt-null`} value="">Please select</option>)

            return (
              <td key={`vintage-year-${year}-${idx}`} className="text-right">
                <FormInput
                  property={field.key}
                  type={field.type}
                  subtype={field.subtype}
                  options={<>{options }</> || <></>}
                  idx={`vintage-year-${year}`}
                  key={`vintage-year-${year}-${field.key}`}
                  editMode={editMode}
                  showZero={true}
                  propertyVal={propertyVal}
                  updateValue={(value) => updateValue(year, field.key, value)}
                  editDecimalPlaces={field.editDecimalPlaces}
                />
              </td>
            )
          }

          return (
            <td key={`vintage-year-${year}-${idx}`} className="text-right">
              <FormInput
                property={field.key}
                type={field.type}
                subtype={field.subtype}
                idx={`vintage-year-${year}`}
                key={`vintage-year-${year}-${field.key}`}
                editMode={editMode}
                showZero={true}
                propertyVal={propertyVal}
                updateValue={(value) => updateValue(year, field.key, value)}
                editDecimalPlaces={field.editDecimalPlaces}
                />
            </td>
          )
        })
      }
    </tr>
  )
}

export default ProductPerformanceVehiclesCashflowSummary
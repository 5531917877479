import iassign from "immutable-assign"
import _ from "lodash"
import React, { Component, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { History} from 'history'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import PlaceHolder from "./../ui/PlaceHolder"
import moment from 'moment'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numbro from 'numbro'
import classnames from 'classnames'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import {
  GlidePathAllocationFragment,
  GlidePathSeriesChanges,
  UpdateGlidePathOverviewInput,
  useGlidePathAllocationQuery,
  useUpdateGlidePathAllocationMutation,
  useConfirmGlidePathAllocationMutation,
  Maybe,
} from "../../__generated__/graphql"
import { FormInputField, DATE_API_FORMAT, DATE_TEXT_FORMAT } from "../../helpers/constant"
import {
  convertLookupToString,
  excludePropertyArray,
} from "../../helpers/object"
import { FormInput } from "../ui/Forms/FormInput"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import { GraphData, PercentAreaOptions } from "../../helpers/highCharts"
import exportTables from "../../helpers/exportTable"

// const s = printSchema
interface idProps {
  id: number
  auth: Auth
}

/**
 * query ManagerSummary by managerId, return editable form
 * @param {id:number} idProps for query ManagerSummary
 * **/
const GlidePathOverview: React.FC<idProps> = ({ id, auth }: idProps) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [initiateReview, setInitiateReview] = useState(false)
  const history = useHistory()
  const resultRef = useRef<Result>(null)
  const [updateGlidePath] = useUpdateGlidePathAllocationMutation()
  const [confirmGlidePath] = useConfirmGlidePathAllocationMutation()
  const { data, error, loading } = useGlidePathAllocationQuery({
    variables: { id },
    errorPolicy: "all"
  })

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    const currentGlidePath = resultRef!.current?.state.currentState
    if(!currentGlidePath){
      return
    }
    setSaving(true)
    const { id, ...rest } = currentGlidePath
    const patch = { ...rest }
    const formattedPatch = convertLookupToString(
      patch,
      false,
    )

    const updateData = excludePropertyArray(formattedPatch, ["__typename", "manager", "benchmarkIndex", "allocationConfirmation"])
    const input = { id, patch: updateData } as UpdateGlidePathOverviewInput

    updateGlidePath({ variables: { input } })
      .then(result => {
        setSaving(false)
        if(initiateReview){
          confirmGlidePath({variables: { id }})
            .then(result => {
              if (result && result.data && result.data.glidePath) {
                const formattedData = _.cloneDeep(result.data.glidePath)
                resultRef!.current?.setState({ currentState: formattedData, initialState: formattedData })
                setEditMode(false)
              }
            })
            .catch(err => {
              console.log("Error Confirm Failed", err.message)
              // throw new Error(`${err.message}`)
            })
        } else {
          if (result && result.data && result.data.glidePath) {
            const formattedData = _.cloneDeep(result.data.glidePath)
            resultRef!.current?.setState({ currentState: formattedData, initialState: formattedData })
            setEditMode(false)
          }
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error testManagerSummary", err.message)
        // throw new Error(`${err.message}`)
      })
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
      <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
      </div>
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} />
        }
      </div>
    </>
  )
  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error && !data) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (data && data.glidePath && data.glidePath.allocations) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Result
              ref={resultRef}
              editMode={editMode}
              history={history}
              initiateReview={initiateReview}
              setInitiateReview={(value:boolean) => setInitiateReview(value)}
              data={data?.glidePath as GlidePathAllocationFragment}
              handleEdit={() => handleEdit()}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

interface Props {
  editMode: boolean
  history: History
  initiateReview: boolean
  setInitiateReview: (value:boolean) => void
  data: GlidePathAllocationFragment
  handleEdit: () => void
}



class Result extends Component<Props> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data,
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (value: any, property: string, assetClass: string) => {
    let newState = _.cloneDeep(this.state.currentState)
    newState = iassign(
      newState,
      currentState => currentState?.allocations,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable)
        var selectedRow = _.find(rows, (o) => {return (o?.assetClass?.code === assetClass)})
        if(selectedRow){
          _.set(selectedRow, property, value);
        } else {
          console.log("No Row Found to update")
          // const newRow= {
          //   assetsUnderManagement: 0.0,
          //   categoryType: {code: categoryType, __typename: "CategoryTypeLookup"},
          //   clientType: {code: clientType, __typename: "ClientTypeLookup"},
          //   numberOfAccounts: 0,
          //   quarterEndDate: this.props.searchDate,
          //   __typename: "AssetsByClient",
          // }
          // _.set(newRow, property, value);
          // rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({currentState: newState})
  }

  addSeriesChangesRow = () => {
    let oldState: GlidePathAllocationFragment = _.cloneDeep(this.state.currentState)
    if(!(oldState as any)?.seriesChanges) {
      _.set(oldState, "seriesChanges", [])
    }
    let newState = iassign(
      oldState,
      currentState => (currentState as any)?.seriesChanges,
      (rows: Maybe<GlidePathSeriesChanges>[]) => {
        let rowsClone: Maybe<GlidePathSeriesChanges>[] = _.cloneDeep(rows) || []
        const newRow: GlidePathSeriesChanges = {
          __typename: "GlidePathSeriesChanges",
          year: moment().year(),
          changes: ""
        } as GlidePathSeriesChanges
        rowsClone.unshift(newRow)
        return rowsClone
      }
    )
    this.setState({currentState: newState})
  }

  setReview = (value:boolean, totals:any) => {
    console.log("edit", this.props.editMode, "startReviewed", this.startReviewed(), "Data Changed", this.dataChanged(), "Errors", this.errorCount(totals), this.props.initiateReview)
    if(!this.props.editMode) return
    if(this.startReviewed() && !this.dataChanged) return
    if(value){
      if(!(this.errorCount(totals) > 0)){
        this.props.setInitiateReview(true)
      }
    } else {
      if(this.props.initiateReview){
        this.props.setInitiateReview(false)
      }
    }
  }

  startReviewed = () => {
    return !!this.state.initialState.allocationConfirmation?.confirmDate && moment(this.state.initialState.allocationConfirmation?.confirmDate).valueOf() > moment(this.state.initialState.allocationConfirmation?.saveDate).valueOf()
  }

  dataChanged = () => {
    return JSON.stringify(this.state.currentState) !== JSON.stringify(this.state.initialState)
  }

  errorCount = (totals:any) => {
    let count = 0
    Object.keys(totals).map((total:string) => {
      const value = numbro(_.get(totals,total))
      const difference = value.difference(100)
      if(difference >= 0.01) count += 1
    })
    return count
  }

  render() {
    if(!this.state.currentState.allocations){
      return <>No Data</>
    }
    let totals = {
      year1: 0,
      year5: 0,
      year10: 0,
      year15: 0,
      year20: 0,
      year25: 0,
      year30: 0,
      year35: 0,
      year40: 0,
      year45: 0,
      year50: 0,
      year55: 0,
      year60: 0,
      year65: 0,
      year70: 0,
    }

    const baseGroup = {
      name: "",
      year1: 0,
      year5: 0,
      year10: 0,
      year15: 0,
      year20: 0,
      year25: 0,
      year30: 0,
      year35: 0,
      year40: 0,
      year45: 0,
      year50: 0,
      year55: 0,
      year60: 0,
      year65: 0,
      year70: 0,
    }

    let lastGroup = _.cloneDeep(baseGroup)
    let graphData = this.state.currentState.allocations.reduce(
      (
        result: GraphData[],
        entry: any
      ) => {
        if(entry.assetClass.aggregate){
          if(lastGroup.name !== ""){
            result.push({
              name: lastGroup.name,
              data: ["year1", "year5", "year10", "year15", "year20", "year25", "year30", "year35", "year40", "year45", "year50", "year55", "year60", "year65", "year70"].map((value)=>{return _.get(lastGroup, value)})
            })
          }
          lastGroup = _.cloneDeep(baseGroup)
          lastGroup.name = entry.assetClass.value
        }
        ["year1", "year5", "year10", "year15", "year20", "year25", "year30", "year35", "year40", "year45", "year50", "year55", "year60", "year65", "year70"].forEach((year)=>{
          const value = _.get(entry, year) || 0
          _.set(totals, year, _.get(totals, year) + value)
          _.set(lastGroup, year, _.get(lastGroup, year) + value)
        })
        return result
      },
      []
    ) || []
    if(lastGroup.name !== ""){
      graphData.push({
        name: lastGroup.name,
        data: ["year1", "year5", "year10", "year15", "year20", "year25", "year30", "year35", "year40", "year45", "year50", "year55", "year60", "year65", "year70"].map((value)=>{return _.get(lastGroup, value)})
      })
    }

    const xAxis = ["40", "35", "30", "25", "20", "15", "10", "5", "0", "-5", "-10", "-15", "-20", "-25", "-30"]

    const allocationConfirmation = this.state.currentState?.allocationConfirmation
    const displayReviewCompleted = (this.startReviewed() && !this.dataChanged()) || (this.props.initiateReview)
    const errorCount = this.errorCount(totals)
    return(
      <>
        <div className='pane'>
          <div className='pane-title'>
            <h3 id="glidePathAllocationTooltipContainer">
              <div className="d-inline-flex align-items-center tooltip-icon" id="glidePathAllocationTooltip">
                Glide Path Allocations
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  className="ml-2 mt-0"
                  size="sm"
                />
              </div>
            </h3>
          </div>
          <Row>
            <Col sm="5">
              <div className="status-container">
                <div className="status-content">
                  <div className="mb-4">
                  Once you have reviewed and updated the allocations, change the status to <strong>Confirmed</strong>, This change lets Callan know that the allocations can be used in its reports.
                  </div>
                  <div>
                    <strong>Status</strong>
                    <br />
                    {displayReviewCompleted &&
                      <div
                        className="btn btn-olive mt-2 mb-2"
                        onClick={() => this.setReview(false, totals)}
                      >
                        Confirmed
                      </div>
                    }
                    {!displayReviewCompleted &&
                      <div
                        className={classnames({"btn mt-2 mb-2": true, "btn-danger": errorCount, "btn-secondary": !errorCount})}
                        onClick={() => this.setReview(true, totals)}
                      >
                        Needs Confirmation
                      </div>
                    }
                  </div>
                  {!!allocationConfirmation &&
                    <div className="text-gray-50">
                      Last confirmed on
                      <span className="text-text-color"> {moment(allocationConfirmation?.confirmDate, DATE_API_FORMAT).format(DATE_TEXT_FORMAT)} </span>
                      by
                      <span className="text-text-color"> {allocationConfirmation?.confirmedBy?.firstName} {allocationConfirmation?.confirmedBy?.lastName} </span>
                    </div>
                  }
                  {!allocationConfirmation &&
                    <div className="text-gray-50">
                      Never confirmed
                    </div>
                  }
                </div>
              </div>
            </Col>
            <Col sm="7">
              <HighchartsReact
                options={PercentAreaOptions({heading: this.state.currentState.name || "", subHeading: this.state.currentState?.manager?.name, xAxis: xAxis, data: graphData})}
                highcharts={Highcharts}
              />
            </Col>
          </Row>
        </div>
        <div className="pane pane-table">
          <div className="table-container">
            <Table hover className="table-bordered-internal exportable">
              <thead>
                <tr className="table-title row-border-olive-100">
                  <th colSpan={2}></th>
                  <th colSpan={9} className="py-2">Years Before Retirement</th>
                  <th colSpan={6} className="py-2">Years After Retirement</th>
                </tr>
                <tr>
                  <th className="text-left">Asset Class</th>
                  <th className="text-left">Benchmark</th>
                  <th className="text-right">40</th>
                  <th className="text-right">35</th>
                  <th className="text-right">30</th>
                  <th className="text-right">25</th>
                  <th className="text-right">20</th>
                  <th className="text-right">15</th>
                  <th className="text-right">10</th>
                  <th className="text-right">5</th>
                  <th className="text-right">0</th>
                  <th className="text-right">-5</th>
                  <th className="text-right">-10</th>
                  <th className="text-right">-15</th>
                  <th className="text-right">-20</th>
                  <th className="text-right">-25</th>
                  <th className="text-right">-30</th>
                </tr>
              </thead>
              <tbody>
                {!!errorCount &&
                  <tr>
                    <td colSpan={17} className="background-error text-accent-red text-left">
                      Entries contain {errorCount} errors,
                      {!this.props.editMode &&
                        <span className="underline cursor-pointer" onClick={() => this.props.handleEdit()}> Edit </span>
                      }
                    </td>
                  </tr>
                }
                {this.state.currentState.allocations.map((allocation, idx) => {
                  return(
                    <AssetClassRow
                      data={allocation}
                      row={idx}
                      key={idx}
                      editMode={this.props.editMode}
                      updateValue={(value:any, property:string) => this.handleInputChange(value, property, allocation?.assetClass?.code || "")}
                    />
                  )
                })}
                <tr>
                  <td></td>
                  <td className="text-right">Calculated Total</td>
                  {Object.keys(totals).map((total:string, idx:number) => {
                    const value = numbro(_.get(totals,total))
                    const difference = value.difference(100)
                    return (
                      <td key={idx} className={classnames({"text-right": true, "has-error": difference >= 0.01})}>
                        {value.format("0.00")}%
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </>
    )
  }
}


interface AllocationRowProps {
  data: any
  row: number
  editMode: boolean
  updateValue: (value:any, property:string) => void
}

const AllocationInputList:FormInputField[] = [
  { property: "year1", label: "", type: "float", subtype: "percent"},
  { property: "year5", label: "", type: "float", subtype: "percent"},
  { property: "year10", label: "", type: "float", subtype: "percent"},
  { property: "year15", label: "", type: "float", subtype: "percent"},
  { property: "year20", label: "", type: "float", subtype: "percent"},
  { property: "year25", label: "", type: "float", subtype: "percent"},
  { property: "year30", label: "", type: "float", subtype: "percent"},
  { property: "year35", label: "", type: "float", subtype: "percent"},
  { property: "year40", label: "", type: "float", subtype: "percent"},
  { property: "year45", label: "", type: "float", subtype: "percent"},
  { property: "year50", label: "", type: "float", subtype: "percent"},
  { property: "year55", label: "", type: "float", subtype: "percent"},
  { property: "year60", label: "", type: "float", subtype: "percent"},
  { property: "year65", label: "", type: "float", subtype: "percent"},
  { property: "year70", label: "", type: "float", subtype: "percent"},
]

const AssetClassRow = ({data, row, editMode, updateValue}: AllocationRowProps) => {
  return (
    <tr className={classnames({"aggregate-group": data.assetClass.aggregate})}>
      <td className={classnames({"text-nowrap text-left aggregate-pre": true, "pl-5": !data.assetClass.aggregate})}>
        {data.assetClass.value}
      </td>
      <td className={classnames({"text-nowrap text-left aggregate-pre": true})}>
        {data.benchmarkIndex}
      </td>
      {AllocationInputList.map(({property, label, type, subtype, placeholder, optionSource, readonly}, idx) => {
        let propertyVal, onChangeCallback, dataObject
        propertyVal = _.get(data, property)
        onChangeCallback = (value:any) => updateValue(value, property)
        return(
          <td key={idx} className={classnames({
            "aggregate-pre": idx <= 8,
            "aggregate-post": idx > 8,
          })}>
            <FormInput
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              placeholder={placeholder}
              idx={data.assetClass.code + idx}
              editMode={editMode}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              readonly={readonly}
            />
          </td>
        )
      })}
    </tr>
  )
}



export default GlidePathOverview

import React, { Component } from 'react'
import { filter, compact, sortBy } from 'lodash'
import {
  UncontrolledDropdown,
  DropdownMenu,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useManagerAllProductsQuery } from '../../../__generated__/graphql'
import classNames from 'classnames'
import {
  ResearchProductStrategyTypeScriptOrder as ScriptOrder,
  ResearchProductStrategyTypeScript as Script
} from '../../ResearchProducts/ResearchProductConfig'

interface ResearchProductsPickerProps {
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (type: string) => void
}

const ResearchProductsPicker: React.FC<ResearchProductsPickerProps> = ({
  title,
  children,
  dropdownClass,
  onClick
}: ResearchProductsPickerProps) => {
  return (
    <UncontrolledDropdown className={classNames('headline-dropdown product-picker-dropdown', dropdownClass)}>
      {children}
      <DropdownMenu>
        <h5>{title}</h5>
        <ListGroup className='headline-dropdown-links'>
          {ScriptOrder.map((type) => {
            let title = Script[type].title
            return (
              <ListGroupItem tag='a' key={`research-product-${type}`} onClick={() => onClick(type)}>
                {title}
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default ResearchProductsPicker

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApolloQueryResult } from '@apollo/client'
import classNames from 'classnames'
import { clone, compact, debounce, differenceBy, isEqual, isUndefined, reject, sortBy, union, xor } from 'lodash'
import { default as React, useEffect, useRef, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Alert, Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { AssociationSearchQuery, AssociationSearchQueryVariables, ClientAutocomplete, File as Document, FileDetailsQuery, InteractionAssociationsInput, InteractionFragment, ManagerAutocomplete, SearchTypes, UpdateFileDataInput, UpdateFileMetadataInput, useAddInteractionAssociationMutation, useAssociationSearchQuery, useAutocompleteQuery, useRemoveInteractionAssociationMutation, useUpdateFileMetadataMutation } from '../../__generated__/graphql'
import { DocumentAssociationTypes } from '../Shared/Document'
import PlaceHolder from '../ui/PlaceHolder'
import { Association, AssociationsList } from './AssociationsList'

interface InteractionAssociationsModalProps {
  interaction: InteractionFragment
  modalOpen: boolean
  setModalOpen: (open:boolean) => void
  onSaveSuccess: () => void
  allAssociations: Association[]
  documents: InteractionFragment["documentAssociations"]
  primaryManagerId: number
}

export const InteractionAssociationsModal:React.FC<InteractionAssociationsModalProps> = (props:InteractionAssociationsModalProps) => {
  const {interaction, modalOpen, setModalOpen, onSaveSuccess, documents, primaryManagerId } = props
  const [allAssociations, setAllAssociations] = useState(props.allAssociations)
  const [addInteractionsMutation] = useAddInteractionAssociationMutation()
  const [removeInteractionsMutation] = useRemoveInteractionAssociationMutation()
  const [updateFileMutation] = useUpdateFileMetadataMutation()
  let typeList: SearchTypes[] = [SearchTypes.Manager]

  // fix CAL-1554 association modal display lag
  useEffect(()=>{
    setAllAssociations(props.allAssociations)
  }, [props.allAssociations])

  const handleSubmit = (addedAssociations:AssociationSet, removedAssociations:AssociationSet) => {

    if (addedAssociations.glidePaths.length > 0 || addedAssociations.orgs.length > 0  || addedAssociations.products.length > 0) {
      const addInteractionsInput:InteractionAssociationsInput = {
        id: interaction.id,
        orgs: addedAssociations.orgs,
        glidePaths: addedAssociations.glidePaths,
        products: addedAssociations.products,
      }
      addInteractionsMutation({ variables: { input: addInteractionsInput } })
        .then(result => {

          if (result && result.data) {
            //let unformattedNewData = { assets: result.data.assets?.org }
            setModalOpen(false)
            onSaveSuccess()
          }
        })
        .catch(err => {
          console.error("Error adding associations", err.message)
          // throw new Error(`${err.message}`)

        })

      const associations = allAssociations.concat(addedAssociations.glidePaths.map((g) => { return {__typename: "GlidePath", id: g, name: '' }}),addedAssociations.orgs.map((g) => { return {__typename: "Manager", id: g, name: '' }}), addedAssociations.products.map((g) => { return {__typename: "ProductFields", id: g, name: '' }}))
      // Find the list of associations attached to all documents
      documents.forEach((document) => {
        const managerAssociations:Association[] = compact(document.managers).map(m => { return { __typename: m?.__typename, id: m?.id, name: m?.name || '' } })
        const products = compact(document.products?.map(p => p?.product))
        const productAssociations: Association[] = products.map(p => { return { __typename: p?.__typename, id: p?.id, name: p?.name } })
        const glidePathAssociations: Association[] = compact(document.glidePaths).map(g => { return { __typename: g?.__typename, id: g?.id, name: g?.name || '' } })
        const addAssociations = differenceBy(associations, [...managerAssociations, ...productAssociations, ...glidePathAssociations] || [], (association:Association) => {
          return `${association.__typename}:${association.id}`
        })
        if(addAssociations.length > 0){
          let updateData = {
            managers: compact(union(document.managers?.map((ob:any) => ob.id), addedAssociations.orgs, [primaryManagerId], allAssociations.flatMap((ob:any) => ob.__typename === "Manager" ? ob.id : []))),
            products: compact(union(document.products?.map((ob:any) => ob.product.id), addedAssociations.products, allAssociations.flatMap((ob:any) => ob.__typename === "ProductFields" ? ob.id : []))),
            glidePaths: compact(union(document.glidePaths?.map((ob:any) => ob.id), addedAssociations.glidePaths, allAssociations.flatMap((ob:any) => ob.__typename === "GlidePath" ? ob.id : []))),
          } as UpdateFileDataInput

          const input = { id: document.id, patch: updateData } as UpdateFileMetadataInput

          updateFileMutation({ variables: { input } })
            .catch(err => {
              console.log("Error Update File Metadata", err.message)
            })
        }
      })
    }

    if (removedAssociations.glidePaths.length > 0 || removedAssociations.orgs.length > 0  || removedAssociations.products.length > 0) {
      const removeInteractionsInput:InteractionAssociationsInput = {
        id: interaction.id,
        orgs: removedAssociations.orgs,
        glidePaths: removedAssociations.glidePaths,
        products: removedAssociations.products,
      }
      removeInteractionsMutation({ variables: { input: removeInteractionsInput } })
        .then(result => {

          if (result && result.data) {
            //let unformattedNewData = { assets: result.data.assets?.org }
            setModalOpen(false)
            onSaveSuccess()

          }
        })
        .catch(err => {
          console.error("Error removing associations", err.message)
          // throw new Error(`${err.message}`)

        })
    }
  }
  return(
    <AssociationsModal
      key={`${interaction.id}-associations-modal`}
      interaction={interaction}
      modalOpen={modalOpen}
      allAssociations={allAssociations}
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit}
      typeList={typeList}
    />
  )
}

interface DocumentAssociationsModalProps {
  document: Document
  modalOpen: boolean
  setModalOpen: (open:boolean) => void
  refetchQuery: () => Promise<ApolloQueryResult<FileDetailsQuery>>
  allAssociations: Association[]
  associationType?: DocumentAssociationTypes
}

export const DocumentAssociationsModal:React.FC<DocumentAssociationsModalProps> = (props:DocumentAssociationsModalProps) => {
  const { document, modalOpen, setModalOpen, associationType } = props
  const [allAssociations, setAllAssociations] = useState(props.allAssociations)

  const [updateFileMutation] = useUpdateFileMetadataMutation()

  // fix CAL-1554 association modal display lag
  useEffect(()=>{
    setAllAssociations(props.allAssociations)
  }, [props.allAssociations])

  const handleSubmit = (addedAssociations:AssociationSet, removedAssociations:AssociationSet) => {

    if (addedAssociations.glidePaths.length > 0 || addedAssociations.orgs.length > 0 || addedAssociations.plans.length > 0  || addedAssociations.products.length > 0 || addedAssociations.vehicles.length > 0 || removedAssociations.glidePaths.length > 0 || removedAssociations.orgs.length > 0 || removedAssociations.plans.length > 0  || removedAssociations.products.length > 0 || removedAssociations.vehicles.length > 0 || addedAssociations.clients.length > 0 || removedAssociations.clients.length > 0) {
      const input = {
        id: document.id,
        patch: {
          managers: xor(document.managers?.map((man) => man?.id).concat(addedAssociations.orgs || []), (removedAssociations.orgs || [])),
          glidePaths: xor(document.glidePaths?.map((man) => man?.id).concat(addedAssociations.glidePaths || []), (removedAssociations.glidePaths || [])),
          plans: xor(document.plans?.map((man) => man?.id).concat(addedAssociations.plans || []), (removedAssociations.plans || [])),
          products: xor(document.products?.map((man) => man?.product?.id).concat(addedAssociations.products || []), (removedAssociations.products || [])),
          vehicles: xor(document.vehicles?.map((man) => man?.vehicle?.fundid).concat(addedAssociations.vehicles || []), (removedAssociations.vehicles || [])),
          clients: xor(document.clients?.map((man) => man?.id).concat(addedAssociations.clients || []), (removedAssociations.clients || [])),
        }
      } as UpdateFileMetadataInput

      updateFileMutation({ variables: { input } })
        .then(result => {

          if (result && result.data) {
            //let unformattedNewData = { assets: result.data.assets?.org }
            props.setModalOpen(false)
            props.refetchQuery()
          }
        })
        .catch(err => {
          console.error("Error adding associations", err.message)
          // throw new Error(`${err.message}`)

        })
    }
  }

  let typeList: SearchTypes[] = [SearchTypes.Manager]
  if(associationType === "Client" || associationType === "Plan")
    typeList = [SearchTypes.Manager, SearchTypes.Client]

  return(
    <AssociationsModal
      key={`${document.id}-document-associations-modal`}
      interaction={document}
      modalOpen={modalOpen}
      allAssociations={allAssociations}
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit}
      typeList={typeList}
    />
  )
}


export interface AssociationSet {
  orgs: number[]
  products:number[]
  glidePaths:number[]
  vehicles:string[]
  plans:number[]
  clients:number[]
}

interface AssociationsModalProps {
  interaction: InteractionFragment | Document
  modalOpen: boolean
  setModalOpen: (open:boolean) => void
  allAssociations: Association[]
  handleSubmit: (addedAssociations:AssociationSet, removedAssociations:AssociationSet) => void
  typeList: SearchTypes[]
}

export const AssociationsModal:React.FC<AssociationsModalProps> = (props:AssociationsModalProps) => {
  const {interaction, allAssociations, modalOpen, handleSubmit, typeList } = props
  const [searchQuery, setSearchQuery] = useState("")
  const [filterOpen, setFilterOpen] = useState(false)
  const [alert, setAlert] = useState<string | undefined>(undefined)
  const [saving, setSaving] = useState(false)
  const [managerList, setManagerList] = useState<(ManagerAutocomplete | ClientAutocomplete)[]>([])

  // const productAssociations:AssociationProduct[] = compact(interaction.productAssociations.map(p => p.product as AssociationProduct))
  // let allAssociations:AssociationTypes[] = interaction.otherManagerAssociations
  // allAssociations.concat(compact(productAssociations))
  // allAssociations.concat(compact(interaction.glidePathAssociations))

  // const productAssociations = compact(interaction.productAssociations.map(p => p.product))
  // let allAssociations:Association[] = interaction.otherManagerAssociations.map(m => { return { __typename: m.__typename, id: m.id, name: m.shortName || '' } })
  // allAssociations = allAssociations.concat(compact(productAssociations.map(p => { return { __typename: p.__typename, id: p.id, name: p.name } })))
  // allAssociations = allAssociations.concat(compact(interaction.glidePathAssociations.map(g => { return { __typename: g.__typename, id: g.id, name: g.name || '' } })))

  const productsWithNotes: (string|number)[] = interaction.__typename === "Interaction" && compact(interaction.notes?.specificProductNotes?.map(p => p?.product?.product?.id)) || []

  const [loadedAssociations, setLoadedAssociations] = useState<Association[]>(allAssociations)
  const [removedAssociations, setRemovedAssociations] = useState<Association[]>([])
  const [addedAssociations, setAddedAssociations] = useState<Association[]>([])

  const [searchFilter, setSearchFilter] = useState(["Manager", "Product", "GlidePath", "Plan", "Client"])

  // fix CAL-1554 association modal display lag
  useEffect(()=>{
    setLoadedAssociations(allAssociations)
  }, [allAssociations])

  let searchFilterText = ""
  if (searchFilter.length > 1) {
    searchFilterText = "All Matches"
  } else if (["Manager", "Product", "Client", "Plan"].includes(searchFilter[0])) {
    searchFilterText = searchFilter[0] + "s"
  } else {
    searchFilterText = "Target Date Families"
  }

  const toggle = () => { props.setModalOpen(!modalOpen) }
  const toggleFilters = () => { setFilterOpen(!filterOpen) }

  const handleSearchChange = (value:string) => {
    setSearchQuery(value)
  }

  const addToAssociationList = (result:Association) => {
    setLoadedAssociations([...loadedAssociations, result])
    setAddedAssociations([...addedAssociations, result])

    let removedAssocs = removedAssociations
    removedAssocs = reject(removedAssocs, a => a.id === result.id)
    setRemovedAssociations([...removedAssocs])
  }

  const removeFromAssociationList = (association:Association) => {
    if (association.__typename === "ProductFields" && productsWithNotes.includes(association.id)) {
      setAlert("Products with notes cannot be removed")
      return
    }

    let assocs = loadedAssociations
    assocs = reject(assocs, a => a.id === association.id)
    setLoadedAssociations([...assocs])

    assocs = addedAssociations
    setAddedAssociations([...assocs])

    setRemovedAssociations([...removedAssociations, association])

  }

  const saveAssociations = () => {
    setSaving(true)

    let interactionsAdded:AssociationSet = {
      orgs: [],
      products: [],
      glidePaths: [],
      vehicles: [],
      plans: [],
      clients: [],
    }

    addedAssociations.map(association => {
      if (association.__typename === "Manager" && typeof association.id === "number") {
        interactionsAdded.orgs.push(association.id)
      } else if (association.__typename === "ProductFields" && typeof association.id === "number") {
        interactionsAdded.products.push(association.id)
      } else if (association.__typename === "GlidePath" && typeof association.id === "number") {
        interactionsAdded.glidePaths.push(association.id)
      } else if (association.__typename === "VehicleFields") {
        interactionsAdded.vehicles.push(association.id.toString())
      } else if (association.__typename === "Plan" && typeof association.id === "number") {
        interactionsAdded.plans.push(association.id)
      } else if (association.__typename === "Client" && typeof association.id === "number") {
        interactionsAdded.clients.push(association.id)
      }
    })

    let interactionsRemoved:AssociationSet = {
      orgs: [],
      products: [],
      glidePaths: [],
      vehicles: [],
      plans: [],
      clients: [],
    }

    removedAssociations.map(association => {
      if (association.__typename === "Manager" && association.id && typeof association.id === "number") {
        interactionsRemoved.orgs.push(association.id)
      } else if (association.__typename === "ProductFields" && typeof association.id === "number") {
        interactionsRemoved.products.push(association.id)
      } else if (association.__typename === "GlidePath" && typeof association.id === "number") {
        interactionsRemoved.glidePaths.push(association.id)
      } else if (association.__typename === "VehicleFields") {
        interactionsRemoved.vehicles.push(association.id.toString())
      } else if (association.__typename === "Plan" && typeof association.id === "number") {
        interactionsRemoved.plans.push(association.id)
      } else if (association.__typename === "Client" && typeof association.id === "number") {
        interactionsRemoved.clients.push(association.id)
      }
    })

    handleSubmit(interactionsAdded, interactionsRemoved)
    setAlert(undefined)
    // setSaving(false)

  }

  const sortAssociations = () => {
    let sortedAssociations = sortBy(loadedAssociations, (association) => {
      switch(association.__typename){
        case "Manager":
          return 0
        case "ProductFields":
          return 1
        case "GlidePath":
          return 2
        case "VehicleFields":
          return 3
        case "Plan":
          return 4
        case "Client":
          return 5
        default:
          return 10
      }
    })
    setLoadedAssociations(sortedAssociations)
  }

  return (
    <Modal isOpen={modalOpen} toggle={toggle} className="associations-modal" size="lg" zIndex={1500}>
      <ModalHeader toggle={toggle}>Edit Associations</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={7}>
            <Alert isOpen={!isUndefined(alert)} toggle={() => setAlert(undefined)} color="danger">{alert}</Alert>
            <h5>Choose items to add to the associations list</h5>
            <div className="border p-3 pt-1 pb-2">
              <AssociationManagerSearch
                managerList={managerList}
                setManagerList={(list) => setManagerList(list)}
                typeList={typeList}
              />
              <div className="pl-1 pt-2">
                {managerList.map((manager, idx) => {
                  const handleClick = () => {
                    let newManagerList = clone(managerList)
                    newManagerList.splice(idx,1)
                    setManagerList(newManagerList)
                  }
                  let name = ""
                  if (manager.__typename === "ManagerAutocomplete") name = manager.name
                  if (manager.__typename === "ClientAutocomplete") name = manager.clientName
                  return(
                    <Button color="light" size="sm" className="mr-1" key={manager.id} onClick={handleClick}>
                      {name}
                      <FontAwesomeIcon
                        icon="times"
                        className="pl-2"
                      />
                    </Button>
                  )
                })}
              </div>

              <div className="mt-1">
                <div className="d-flex justify-content-between align-items-end">
                  <h5 className="mb-0">Results</h5>
                  { managerList.length > 0 &&
                    (<ButtonDropdown isOpen={filterOpen} toggle={toggleFilters} className="pr-0 associations-search-filters mb-1">
                      <DropdownToggle className="border-0 p-2" color="link">
                        <FontAwesomeIcon icon="filter" className="mr-2 text-gray-80"/>
                        Show {searchFilterText}
                      </DropdownToggle>
                      <DropdownMenu className="p-0">
                        <DropdownItem onClick={() => setSearchFilter(["Manager", "Product", "GlidePath", "Client", "Plan"])}>All Matches</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => setSearchFilter(["Manager"])}>Managers only</DropdownItem>
                        <DropdownItem onClick={() => setSearchFilter(["Product"])}>Products only</DropdownItem>
                        <DropdownItem onClick={() => setSearchFilter(["GlidePath"])}>Target Date Families only</DropdownItem>
                        {typeList?.includes(SearchTypes.Client) &&
                          <>
                            <DropdownItem onClick={() => setSearchFilter(["Client"])}>Clients only</DropdownItem>
                            <DropdownItem onClick={() => setSearchFilter(["Plan"])}>Plans only</DropdownItem>
                          </>
                        }
                      </DropdownMenu>
                    </ButtonDropdown>)
                  }
                </div>
                <div>
                  <DebouncedSearchBar
                    setSearchTerm={handleSearchChange}
                    placeholder="Filter by Name"
                    searchIcon={true}
                  />
                  <AssociationsSearchList
                    searchQuery={searchQuery || "*"}
                    searchFilter={searchFilter as SearchTypes[]}
                    loadedAssociations={loadedAssociations}
                    addToAssociationList={addToAssociationList}
                    parentManagers={managerList}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="d-flex justify-content-between align-items-end">
              <h5 className="mb-0">Associations</h5>
              <Button color="link" onClick={sortAssociations} className="mb-1 text-gray-80">
                Sort by Type
                <FontAwesomeIcon icon="chevron-down" className="ml-2" />
              </Button>
            </div>

            <AssociationsList
              onClick={removeFromAssociationList}
              associations={loadedAssociations}
              icon={<FontAwesomeIcon icon="times" />}
              removable={true}
              onRemove={removeFromAssociationList}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} className="mr-1">Cancel</Button>

        <SaveButton saving={saving} onSave={saveAssociations} key={`assoc-modal-save-${saving}`}/>
      </ModalFooter>
    </Modal>
  )
}

interface AssociationManagerSearchProps{
  managerList: (ManagerAutocomplete | ClientAutocomplete)[]
  setManagerList: (managers:(ManagerAutocomplete | ClientAutocomplete)[]) => void
  typeList?: SearchTypes[]
}

const AssociationManagerSearch:React.FC<AssociationManagerSearchProps> = (props:AssociationManagerSearchProps) => {
  const [managerName, setManagerName] = useState("")
  const { data } = useAutocompleteQuery({ variables: {
    q: managerName,
    types: props.typeList || [SearchTypes.Manager, SearchTypes.Client]
  }})
  let results: (JSX.Element | undefined)[]
  if (!!data && data.autocomplete) {
    results = data.autocomplete.map((d) => {
      if (d == null) {
        return <ListGroupItem className="horizontal with-category autocomplete"></ListGroupItem>
      }
      let type:string | undefined, id:number | undefined, name:string | undefined
      if(d.__typename === "ManagerAutocomplete") {
        type = "Manager"
        id = d.id
        name = d.name
      } else if (d.__typename === "ClientAutocomplete") {
        type = "Client"
        id = d.id
        name = d.clientName
      }
      const handleClick = () => {
        if(d.__typename === "ClientAutocomplete" || d.__typename === "ManagerAutocomplete"){
          props.setManagerList(union(props.managerList, [d]))
        }
        setManagerName("")
      }
      if(type && id && name){
        return (
          <ListGroupItem tag="a" onClick={handleClick} key={`${type.toLocaleLowerCase()}-auto-${id}`}>
            <div className={`category ${type.toLocaleLowerCase()}`}>
              <div className="category-text">
                <h6>{type}</h6>
              </div>
            </div>
            <div className="w-100">
              <Row>
                <Col md="8">
                  <h3>{name}</h3>
                  <p>&nbsp;</p>
                </Col>
                <Col md="4">
                  <h5>{type} ID</h5>
                  <p>{id}</p>
                </Col>
              </Row>
            </div>
          </ListGroupItem>
        )
      }
    })
  } else {
    results = [(<React.Fragment key="1"></React.Fragment>)]
  }
  return (
    <>
      <Label>Search by Firm Name</Label>
      <DebouncedSearchBar
        setSearchTerm={setManagerName}
        placeholder="Search by Firm Name"
      />
      <ListGroup className="horizontal with-category autocomplete">
        {results}
      </ListGroup>
    </>
  )
}

interface DebouncedSearchBarProps{
  setSearchTerm: (name:string) => void
  debounceTimeout?: number
  placeholder?: string
  searchIcon?: boolean
}

const DebouncedSearchBar:React.FC<DebouncedSearchBarProps> = (props:DebouncedSearchBarProps) => {
  const { debounceTimeout, setSearchTerm, placeholder, searchIcon } = props
  const [searchString, setSearchString] = useState("")
  const debouncedSave = useRef(debounce((nextValue:string) => setSearchTerm(nextValue), debounceTimeout || 500)).current

  const handleSearchChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchString(value)
    debouncedSave(value)
  }
  const resetSearch = () => {
    setSearchTerm(searchString)
  }

  return (
    <div className="relative">
      <Input
        type="text"
        className={classNames("form-control", {"pl-5": !!searchIcon})}
        onChange={handleSearchChange}
        onFocus={resetSearch}
        placeholder={placeholder || "Search"}
        value={searchString}
      />
      {searchIcon &&
        <span className="o-88 absolute center-v pe-none left-1">
          <FontAwesomeIcon
            icon={["fas", "search"]}
            size="2x"
            className="fontawesome-icon dark-icon-color text-gray-50"
          />
        </span>
      }
    </div>
  )
}

interface AssociationsSearchListProps{
  searchQuery: string
  searchFilter: SearchTypes[]
  loadedAssociations: Association[]
  addToAssociationList: (result:Association) => void
  parentManagers: (ManagerAutocomplete | ClientAutocomplete)[]
}

export const AssociationsSearchList:React.FC<AssociationsSearchListProps> = (props:AssociationsSearchListProps) => {
  const {searchQuery, searchFilter, loadedAssociations, addToAssociationList, parentManagers} = props
  const searchVariables = { q: searchQuery, types: searchFilter, limit: 10, parent_firm: parentManagers.map((manager) => manager.id).join(',')}
  const {data, loading, error, fetchMore} = useAssociationSearchQuery({
    variables: searchVariables,
    // partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const searchResults = data?.search
  const [noneReturned, setNoneReturned] = useState<AssociationSearchQueryVariables | undefined>(undefined)
  const hasNextPage = !!searchResults && !!searchResults.data && !!searchResults.hits && searchResults.data.length < searchResults.hits && !isEqual(noneReturned, searchVariables)

  const loadMore = () => {
    if(!searchResults || loading){
      return
    }
    fetchMore({
      variables: { q: searchQuery, types: searchFilter, limit: 10, offset: searchResults.data?.length },
    updateQuery: (previousResult:AssociationSearchQuery, { fetchMoreResult } :any) => {
      if(!fetchMoreResult || !previousResult){
        return previousResult
      }
      const previousResults = previousResult.search?.data
      let newResults = fetchMoreResult.search?.data
      if(newResults.length === 0){
        setNoneReturned(searchVariables)
      }
      if(!previousResults || !newResults){
        return previousResult
      }
      return {
        __typename: previousResult.__typename,
        search:{
          hits: fetchMoreResult.search.hits,
          data: [...previousResults, ...newResults],
          __typename: "SearchResults",
        }
      } as AssociationSearchQuery
    },
    })
  }

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  if (!searchResults?.data) { return <PlaceHolder height={400}/> }

  return (
    <div className="border modal-scrollable ml-1" ref={rootRef}>
      {loading && !hasNextPage &&
        <PlaceHolder  height={100}/>
      }
      <ListGroup className="horizontal with-category">
        {
          searchResults.data.map((result, idx) => {
            if (!result) {
              return (<React.Fragment key={idx}></React.Fragment>)
            }

            if (result.__typename === "Manager" || result.__typename === "ProductFields" || result.__typename === "GlidePathVersion" || result.__typename === "Client" || result.__typename === "Plan") {
              if (loadedAssociations.map(a => a.id).includes(result.id)) {
                return (<React.Fragment key={idx}></React.Fragment>)
              }

              let searchAssociation:Association

              let categoryClass = result.__typename.toLocaleLowerCase()
              let category:string = result.__typename
              let infoHeader:string = ""
              let relationType = "Manager"

              if (result.__typename === "GlidePathVersion") {
                searchAssociation = {
                  __typename: "GlidePath",
                  id: result.glidePath?.id || '',
                  name: result.tdName || '',
                  managerName: result.glidePath?.manager?.name || '',
                }
                category = "Target Date"
                categoryClass = "target-date"
              } else if (result.__typename === "ProductFields") {
                searchAssociation = {
                  __typename: "ProductFields",
                  id: result.id,
                  name: result.name || '',
                  managerName: result.manager?.name || '',
                }
                categoryClass = "product"
                category = "Product"
              } else if (result.__typename === "Plan") {
                searchAssociation = {
                  __typename: "Plan",
                  id: result.id,
                  name: result.name || '',
                  managerName: result.client?.name || '',
                }
                categoryClass = "plan"
                category = "Plan"
                relationType = "Client"
              } else {
                // Manager
                searchAssociation = {
                  __typename: result.__typename,
                  id: result.id,
                  name: result.name
                }
              }
              return (
                // CAL-1553
                <ListGroupItem tag="a" onClick={() => addToAssociationList(searchAssociation)} key={`association-search-list-${categoryClass}-${idx}`}>
                  <div className={classNames("category", categoryClass)}>
                    <div className="category-text">
                      <h6>{category}</h6>
                    </div>
                  </div>
                  <div className="w-100">
                    <Row>
                      <Col md="8">
                        <h3>{ searchAssociation.name }</h3>
                        { searchAssociation.managerName  &&
                          <p>{relationType.toLocaleUpperCase()}: {searchAssociation.managerName}</p>
                        }
                      </Col>
                      <Col md={!!infoHeader ? 2 : 4}>
                        <h5>{category} ID</h5>
                        <p>{searchAssociation.id}</p>
                      </Col>
                      {infoHeader &&
                        <Col md="2">
                          <h5>{infoHeader}</h5>
                          <p>{searchAssociation.info}</p>
                        </Col>
                      }
                    </Row>
                  </div>
                </ListGroupItem>
              )
            }
            return <></>
          })
        }
        {hasNextPage && (
          <div ref={infiniteRef}>
            <PlaceHolder height={200}/>
          </div>
        )}
      </ListGroup>
    </div>
  )
}

interface SaveButtonProps {
  saving: boolean
  onSave: () => void
}
const SaveButton:React.FC<SaveButtonProps> = ({ saving, onSave }:SaveButtonProps) => {
  if (saving) {
    return (<Button color="primary" className="mr-1 ml-auto">
      Saving
      <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />
    </Button>)
  }

  return (
    <Button color="primary" onClick={onSave}>Save <FontAwesomeIcon className="ml-1" icon="pen" /></Button>
  )
}

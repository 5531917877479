import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import classnames from 'classnames';
import _ from "lodash";
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button, Col, Container, Input, Nav, NavItem, NavLink, Row } from 'reactstrap';

import { useManagerGlidePathsQuery, useManagerProductsQuery } from '../../__generated__/graphql';
import { managersGlidePathsListColumnDef, managersProductsListColumnDef } from '../../helpers/columnDefs';
import { ManagerType } from "../../helpers/helpers";
import { ManagerDetailProps } from '../../queries/extended/types/Managers';
import { ManagerDetails_overview } from '../../queries/types/ManagerDetails';
import SortableTable from "../Shared/SortableTable";
import PlaceHolder from '../ui/PlaceHolder';
import ErrorDisplay from '../Shared/ErrorDisplay';
import {CsvExportLimitModal} from '../Shared/CsvExportLimitModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ManagerOverviewProps {
  data: ManagerDetails_overview,
  managerType?: ManagerType
}

interface PageState{
  currentPage:number
  maxPage:number
}

const initialPageState:PageState = {
  currentPage: 1,
  maxPage: 1
}

const ManagerProductsSubTabs = {
  products: "1",
  targetDateFamilies: "2",
}

interface targetDateFamily {
  id: number | string
  name: string
  type: string
  active?: string
  aum?: {
    date: string
    aum: number
  }
  inactive?: boolean
  url?: string
}

const getInitialTab = (subtype: string | undefined, managerType?: ManagerType) => {
  if (!subtype) {
    return ManagerProductsSubTabs["products"]
  }
  if (subtype in ManagerProductsSubTabs) {
    let s =
      ManagerProductsSubTabs[subtype as keyof typeof ManagerProductsSubTabs]
    return s
  } else {
    return ManagerProductsSubTabs["products"]
  }
}

const ManagerProducts: React.FC<ManagerOverviewProps &
  ManagerDetailProps> = props => {
  const id = props.data.id
  const params = useParams() as { subtype?: string }
  const subtype = _.get(props, "match.params.subtype")
  const [activeTab, setActiveTab] = useState(getInitialTab(subtype, props.managerType))
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const [filterText, setFilterText] = useState("")
  const history = useHistory()
  const match = useRouteMatch() || props.match
  const [csvExportModalOpen, setCsvExportModalOpen] = useState(false)

  const handleRedirect = (tab: string) => {
    let urlWithoutSubtype =
      params && params.subtype
        ? match.url.slice(0, -params.subtype.length - 1)
        : match.url
    setActiveTab(getInitialTab(tab))
    history.push(urlWithoutSubtype + "/" + tab)
  }

  const openCsvExportModal = () => {
    setCsvExportModalOpen(!csvExportModalOpen)
  }

  const exportToCsv =() => {
    let exportedRows = 0;
    gridApi?.exportDataAsCsv({
      onlySelected: gridApi?.getSelectedRows().length > 0,
      shouldRowBeSkipped: () => {
        exportedRows++
        if(exportedRows > 1000){
          openCsvExportModal()
        }
        return exportedRows > 1000
      }
    })
  }

  let graphqlData:any, activeLoading:boolean, activeError: any, tableData:any, colDef:any, onReady: (event:GridReadyEvent) => void
  const { loading: productLoading, data: productData, error: productError } = useManagerProductsQuery({
    variables: { id }
  })
  const { loading: glideLoading, data: glideData, error: glideError } = useManagerGlidePathsQuery({
    variables: { id }
  })
  if(activeTab === "1"){
    activeLoading = productLoading
    graphqlData = productData
    activeError = productError
    onReady = (event) => {
      setGridApi(event.api)
      event.api!.setFilterModel({
        'product.inactive': {
          filterType: 'set',
          values: ["Active"]
        }
      })
    }
  } else {
    activeLoading = glideLoading
    graphqlData = glideData
    activeError = glideError
    onReady = (event) => {
      setGridApi(event.api)
      event.api!.setFilterModel({
        'inactive': {
          filterType: 'set',
          values: ["Active"]
        },
      })
    }
  }

  if (activeLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  };
  if (activeError) {
    return (
      <Container fluid>
        <ErrorDisplay error={activeError}/>
      </Container>
    );
  };
  if (!graphqlData) return <div>data doesn't exist</div>;

  let rowId = "id"
  if(activeTab === "1"){
    tableData = _.get(graphqlData, 'org.products', [])
    rowId = "product.id"
    colDef = managersProductsListColumnDef()
  }else{
    const glidePaths = _.get(graphqlData, 'org.glidePaths', [])
    tableData = glidePaths.reduce(
      (
        result: targetDateFamily[],
        glidepath: any
      ) => {
        const glide = {
          id: "Glide Path" + glidepath.id,
          name: glidepath.name,
          type: "Glide Path",
          active: null,
          aum: glidepath.latestAum,
          inactive: !glidepath.dataCollect,
          url: "/glidepaths/" + glidepath.id
        }
        const rest: targetDateFamily[] = glidepath.versions.reduce(
          (
            result: targetDateFamily[],
            versionEntry: any
          ) => {
            const series:targetDateFamily = {
              id: "Series"+versionEntry.id,
              name: versionEntry.name,
              type: "Series",
              active: versionEntry.philosophy.value,
              aum: versionEntry.latestAum,
              inactive: !!versionEntry.terminationDate && moment().isAfter(versionEntry.terminationDate),
              url: "/glidepaths/" + glidepath.id + "/series/" + versionEntry.id
            }
            const products: targetDateFamily[] = versionEntry.series.reduce(
              (
                result: targetDateFamily[],
                productEntry: any
              ) => {
                const product:targetDateFamily = {
                  id: "Product" + productEntry.product.id,
                  name: productEntry.product.name,
                  type: "Product",
                  active: productEntry.product.activePassive,
                  aum: productEntry.product.latestAum,
                  inactive: productEntry.product.inactive,
                  url: "/products/" + productEntry.product.id
                }

                result.push(product)
                return result
              },
              [series]
            )

            result = result.concat(products)
            return result
          },
          [glide]
        )
        result = result.concat(rest)
        return result
      },
      []
    )
    rowId = "id"
    console.log(205, {rowId, tableData})
    colDef = managersGlidePathsListColumnDef
  }

  return (
    <>
      <Container fluid>
        <Nav className="sub-nav sub-nav-secondary" tabs role="group">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => handleRedirect("products")}
            >
              Products
            </NavLink>
          </NavItem>
          {props.managerType?.LGSH &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => handleRedirect("targetDateFamilies")}
              >
                Target Date Families
              </NavLink>
            </NavItem>
          }
        </Nav>
      </Container>
      <Container fluid className="d-flex flex-grow-1 flex-direction-column h-100">
        <Row>
          <Col>
            <div className="pane pane-toolbar">
              <Input
                className="toolbar-search"
                type="text"
                placeholder="Find Products by name"
                onChange={event =>
                  setFilterText(event.target.value)
                }
              />
              <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
                Export CSV
                <img src='/assets/CSV.svg' className="ml-2"/>
              </Button>
              <CsvExportLimitModal
                modalOpen={csvExportModalOpen}
                setModalOpen={setCsvExportModalOpen}
              />
            </div>
          </Col>
        </Row>
        <SortableTable
          key={activeTab}
          loading={activeLoading}
          filterText={filterText}
          columnDefs={colDef}
          tableData={tableData}
          rowId={rowId}
          onReady={onReady}
          // for export as of date
          exportParams={{allColumns: true}}
          pageSize={100}
        />
      </Container>
    </>
  )
}

export default ManagerProducts

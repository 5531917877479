import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface CsvExportLimitProps {
	modalOpen: boolean
	setModalOpen: (value: boolean) => void
}
export const CsvExportLimitModal: React.FC<CsvExportLimitProps>= ({
	modalOpen,
	setModalOpen,
	}) => {
	const closeModal = () => {
			setModalOpen(false)
}

return(
    <>
    <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
        <ModalHeader className="fee-modal-header full-width-header">
            <div className="d-flex justify-content-between">
                <div>
                    Error
                </div>
                <div onClick={() => closeModal()}>
                    <FontAwesomeIcon
                        icon="times"
                        className="ml-auto"
                    />
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
        <h5 className="d-flex justify-content-between">Exceeded 1000 row export limit</h5>
        </ModalBody>
    </Modal>
    </>
)
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { cloneDeep, findIndex, get, merge, set } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupButtonDropdown, Label, ListGroup, ListGroupItem, Row, UncontrolledDropdown } from 'reactstrap'
import { AutocompleteQueryVariables, ClientPortfolioIsCompositeFragment, ClientPortfoliosForClientFragment, ListDetailFragment, ListMemberIdType, PlanAutocomplete, PlanClientPortfolioFragment, SearchAutocompleteFragment, SearchTypes, useAutocompleteQuery, useClientPortfoliosForClientQuery } from '../../../__generated__/graphql'
import Auth from '../../../Auth/Auth'
import { autocompleteMapping, autocompleteToItem, typeIdMapping } from '../../../helpers/list'
import { AutocompleteItemTypes, SearchRows } from '../../Search/SearchAutocomplete'
import { SearchDisplayToTypes, SearchTypeDisplays, SearchTypeListDisplaysOrder } from '../../Search/SearchEnums'
import { PlanSearch, PlanSelection } from './ClientPortfolioMemberOfComposite'

interface ListAddStaticProps {
  list: ListDetailFragment
  // setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
  setEditedList: React.Dispatch<React.SetStateAction<any[] | undefined | any>>
  editMode?: boolean // to ensure dropdown closed in viewMode
  convertAddedItem: (addItem: any) => any
  convertToClientPortfolioFragment: (item: any) => string
  auth: Auth
  searchTypesOverride?: Array<SearchTypeDisplays| "Target">
  planIds?: {id: number, name: string, __typename: string}[] // available planIds to choose from
  showMembers?: boolean
  jsonPath?: string
  wrapperClasses?: string
  targetName?: string
  showTargetName?: boolean
}


export const ClientPortfolioAddStatic: React.FC<ListAddStaticProps> = ({ list, setEditedList, auth, searchTypesOverride,convertAddedItem, convertToClientPortfolioFragment, editMode, planIds, showMembers, jsonPath, wrapperClasses, targetName: currentTargetName, showTargetName }) => {
  let [type, setType] = useState<SearchTypeDisplays| "Target">(searchTypesOverride?  searchTypesOverride[0] : SearchTypeDisplays['Portfolio'])
  let [search, setSearch] = useState<string>("")
  const [selectedPlan, setSelectedPlan]= useState<PlanSelection | null>(null)
  const [planSearchDropdownOpen, setPlanSearchDropdownOpen] = useState(false)
  const [onlyOneSearchTypeAllowed,] = useState(searchTypesOverride && searchTypesOverride.length === 1)

  const [targetName, setTargetName] = useState<string>(currentTargetName || "")
  const [addButtonEnabled, setAddButtonEnabled] = useState<boolean>(true)

  const togglePlan = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    event?.stopPropagation()
    setPlanSearchDropdownOpen(!planSearchDropdownOpen)
  }
  const selectPlan = (plan: PlanSelection) => {
    togglePlan()
    setSelectedPlan(plan)
  }

  const onClickAddTarget = (props: any, jsonPath?: string) => {
    // new target not created yet, has only name & memberType.
    let newTarget = {name: targetName, memberType: "T", __typename: "Target"}
    let newMember = convertAddedItem(newTarget)
    setEditedList((editedList: any)=> {
      if(!newMember) {
        return editedList
      }
      let updatedItems = cloneDeep(editedList) || {}
      let array = updatedItems
      if(jsonPath) {
        array = get(updatedItems, jsonPath) || []
        if(Array.isArray(array)) {
          set(updatedItems, jsonPath, [...array, newMember])
          return updatedItems
        }else {
          let mergedMember = merge(array, newMember)
          set(updatedItems, jsonPath, mergedMember)
          return updatedItems
        }
      }
      if(!editedList) {
        return [newMember]
      }else if(Array.isArray(editedList)){
        return [...editedList, newMember]
      }else {
        console.log("add logic")
      }
    })
    if(!showTargetName) {
      setTimeout(() =>setTargetName(""), 1000)
    }else {
      setAddButtonEnabled(false)
    }
  }

  useEffect(() => {
    if(!editMode) {
      setSelectedPlan(null)
    }
  }, [editMode])

  useEffect(() => {
    if(!targetName) {
      setAddButtonEnabled(true)
    }else {
    }
  }, [targetName])


  const planPicker = (props: any) => {
    let {editMode, planSearchDropdownOpen, togglePlan, selectedPlan, planIds, selectPlan} = props
    if(editMode) {
      return (
        <ButtonDropdown
          isOpen={planSearchDropdownOpen}
          toggle={togglePlan}
          className={classnames("headline-dropdown plan-picker-dropdown-btn")}
        >
          <DropdownToggle caret={editMode}>
            { selectedPlan?.name || "Find plan by name" }
          </DropdownToggle>
          <DropdownMenu onClick={(event)=>event.stopPropagation()}>
            {!!planIds && <PlanSearch data={planIds} onClick={selectPlan} /> }
          </DropdownMenu>
        </ButtonDropdown>
      )
    } else {
      return <React.Fragment key={"plan-picker"}></React.Fragment>
    }
  }

  if(!editMode) {
    return (<div className='h-100'></div>)
  }

  return (
    <div className={classnames('h-100 client-portfolio-add-static', wrapperClasses)}>
      <InputGroup className='pl-1'>
        {!(onlyOneSearchTypeAllowed) &&(<UncontrolledDropdown className="dropdown-select search-bar">
          <DropdownToggle className="btn-toggle">
            { type }
            <FontAwesomeIcon
              icon="chevron-down"
              className="fontawesome-icon"
            />
          </DropdownToggle>
          <DropdownMenu>
            {
              (searchTypesOverride || SearchTypeListDisplaysOrder).map(displayType => {
                return (<DropdownItem onClick={() => setType(displayType as SearchTypeDisplays)} key={`type-option-${displayType}`} className={type === displayType ? 'active' : ''}>{displayType}</DropdownItem>)
              })
            }
          </DropdownMenu>
        </UncontrolledDropdown>)}
        {type === SearchTypeDisplays['Portfolio'] && editMode && planPicker({editMode, planSearchDropdownOpen, togglePlan, selectedPlan, planIds, selectPlan})}
        {(type === SearchTypeDisplays['Vehicles'] || type === SearchTypeDisplays['Index'] || type === SearchTypeDisplays["Group"]) &&
          <>
            <Input
              type="text"
              className="form-control search-bar"
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Find by Name or Id"
              value={search}
            />

            <InputGroupButtonDropdown addonType="append" className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </InputGroupButtonDropdown>
          </>
        }
        {type === 'Target' &&
          <>
            <Input
              type="text"
              className="form-control search-bar"
              onChange={(e) => setTargetName(e.target.value)}
              placeholder="New Benchmark Name"
              value={targetName}
            />
            {targetName && <Button className="" color="primary"
              onClick={(props: any)=>onClickAddTarget(props, jsonPath)} disabled={!addButtonEnabled}
            >
              <span>Add</span>
              {/* <FontAwesomeIcon size="sm" icon="download" className="ml-2 text-blue-100"/> */}
            </Button>
            }
          </>
        }
      </InputGroup>
      {search !== "" && (type === SearchTypeDisplays['Vehicles'] ||  type === SearchTypeDisplays['Index'] || type === SearchTypeDisplays["Group"]) &&
        <StaticSearch
          query={search}
          key={`${type}-search`}
          type={type}
          portfolioList={(list.items || []) as any[]}
          setEditedList={setEditedList}
          convertAddedItem={convertAddedItem}
          convertToClientPortfolioFragment={convertToClientPortfolioFragment}
          jsonPath={jsonPath}
        />
      }
      {type === SearchTypeDisplays['Portfolio'] && (!!selectedPlan?.id) &&
        <StaticClientPortfolioSearch
          key={`portfolio-search-${selectedPlan?.id}`}
          portfolioList={(list.items || []) as any[]}
          selectedPlan={{id: selectedPlan?.id || 0, planName: selectedPlan?.name, __typename: "PlanAutocomplete"}}
          setEditedList={setEditedList}
          convertAddedItem={convertAddedItem}
          convertToClientPortfolioFragment={convertToClientPortfolioFragment}
          showMembers={!!showMembers}
          jsonPath={jsonPath}
        />
      }

    </div>
  )
}

export type UpdatePortfolioListProps<T> = {
  add: T | null,
  remove: T | null,
}

interface StaticClientPortfolioSearchProps {
  portfolioList: ClientPortfoliosForClientFragment[] | any[] // initial portfolio list for the component
  selectedPlan: PlanAutocomplete
  setEditedList: React.Dispatch<React.SetStateAction<any[] | undefined>>
  disabled?: boolean // to ensure dropdown closed in viewMode
  convertAddedItem: (addItem: PlanClientPortfolioFragment) => any
  convertToClientPortfolioFragment: (item: any) => string
  filterRule?: (item: ClientPortfoliosForClientFragment) => boolean
  showMembers?: boolean
  jsonPath?: string
}

/**
 *  static search to get all portfolio associated with one client.
 */
export const StaticClientPortfolioSearch:React.FC<StaticClientPortfolioSearchProps> = ({ portfolioList, selectedPlan, setEditedList, convertAddedItem, convertToClientPortfolioFragment, disabled, filterRule, showMembers, jsonPath}) => {
  const [portfolioQuery, setPortfolioQuery]= useState("")
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const includedRows = portfolioList?.map((item) => ("ClientPortfolio:" + convertToClientPortfolioFragment(item))) || []

  let filters = {plan: [selectedPlan?.id], limit: 1000}
  // variable for MemberOfComposite only
  const { data } = useClientPortfoliosForClientQuery({ variables: {filters, showMembers: !!showMembers}, fetchPolicy: "cache-first"})

  const setDropdownState = (state: boolean, time: number = 100) => setTimeout(() =>setDropdownOpen(state), time)

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // prevent enter from submitting form
    e.key === 'Enter' && e.preventDefault()
  }

  const searchBars = (
    <div className='pl-1 list-add-connected-inputs pt-2'>
      {selectedPlan &&
        <div className='form-group input-group row no-gutters'>
          <Label
            className={"col-form-label"}
            for="portfolio-search"
          >
            <div
              className={"d-flex w-100 justify-content-end mr-2 font-weight-bold"}
            >
              Filter Results:
            </div>
          </Label>
          <div className='flex-fill'>
            <Input
              type="text"
              id="portfolio-search"
              className="form-control search-bar ml-0 border-gray-30 placeholder-gray-50"
              onChange={(e) => setPortfolioQuery(e.target.value)}
              placeholder="Find portfolio by name or ID"
              onBlur={() => setDropdownState(false, 500)}
              onFocus={() => setDropdownState(true, 50)}
              value={portfolioQuery}
              onKeyDown={handleEnterKeyDown}
            />
          </div>
        </div>
      }
    </div>
  )

  if (!!data && data?.clientPortfolios) {
    const onClick = (addItem:ClientPortfolioIsCompositeFragment) => {
      if(!setEditedList) return
      setEditedList((editedList)=> {
        if(!editedList){
          return editedList
        }
        if(editedList){
          let updatedItems = cloneDeep(editedList)
          const baseId = addItem.id
          const newItem = {
            id: baseId,
            name: addItem.name,
            newAdded: true,
            compositeMembers: !!showMembers? addItem?.compositeMembers: null,
            __typename: "ClientPortfolio",
          } as PlanClientPortfolioFragment
          const convertedNewItem = convertAddedItem(newItem)
          let array = updatedItems
          if(jsonPath) {
            array = get(updatedItems, jsonPath) || []
          }
          const newBaseId = convertToClientPortfolioFragment(convertedNewItem)?.toString()
          const mainIndex = findIndex(array, (item) => convertToClientPortfolioFragment(item)?.toString() === newBaseId)
          if(mainIndex === -1 || !jsonPath){
            array.push(convertedNewItem)
            if(jsonPath) {
              set(updatedItems, jsonPath, array)
            }
            return updatedItems
          }else {
            if(jsonPath) {
              set(updatedItems, jsonPath, array)
              return updatedItems
            }
            return editedList
          }
        }
      })
    }

    const sortedPortfolios = data?.clientPortfolios?.filter((portfolio)=>{
      if(!portfolio?.isActive){
        return false
      }
      if(filterRule && !filterRule(portfolio)){
        return false
      }
      if(portfolioQuery.length === 0){
        return true
      }
      if(portfolio?.name?.toLowerCase()?.includes(portfolioQuery.toLowerCase()) || portfolio?.id?.toString()?.includes(portfolioQuery.toLowerCase())){
        return true
      }
      return false
    })
    if(disabled) {
      return <React.Fragment key={"no-show"}></React.Fragment>
    }

    return (
      <>
        {searchBars}
        <div className="client-portfolio-search">
        {isDropdownOpen &&
          <ListGroup className="horizontal with-category autocomplete has-includes headline-dropdown-links">
            {sortedPortfolios.map((portfolio, idx) => {
              if(!portfolio) return (<React.Fragment key={idx}></React.Fragment>)
              const included = includedRows?.includes(`ClientPortfolio:${portfolio.id}`)
              return(
                <ListGroupItem className={classnames({included: included})} tag="a" onClick={() => onClick(portfolio)} key={`client-portfolio-${portfolio.id}`} role="menuitem">
                <div className="category portfolio">
                  <div className="category-text">
                    <h6>Portfolio</h6>
                  </div>
                </div>
                <div className="w-100">
                  <Row>
                    <Col md={6}>
                      <h3>{portfolio.name}</h3>
                      <p>Related to {portfolio?.plan?.name}</p>
                    </Col>
                    <Col md={3}>
                      <h5>Portfolio ID</h5>
                      <p>{portfolio.id}</p>
                    </Col>
                    <Col md={3}>
                      {included && <h5>Included</h5>}
                    </Col>
                  </Row>
                </div>
                {!included &&
                  <div className="hover-overlay">
                    <Button className="add-button">
                      Add
                    </Button>
                  </div>
                }
              </ListGroupItem>
              )
            })}
          </ListGroup>
        }
        {!isDropdownOpen && <Row></Row>}
        </div>
      </>
    )
  }
  return(
    <>
      {searchBars}
    </>
  )
}

interface StaticSearchProps {
  query: string
  type: SearchTypeDisplays
  portfolioList: any[] // initial portfolio list for the component
  setEditedList: React.Dispatch<React.SetStateAction<any[] | undefined>>
  disabled?: boolean // to ensure dropdown closed in viewMode
  convertAddedItem: (addItem: any) => any
  convertToClientPortfolioFragment: (item: any) => string
  jsonPath?: string
}

export const StaticSearch:React.FC<StaticSearchProps> = ({ query, type, portfolioList: list, setEditedList, disabled, convertAddedItem, convertToClientPortfolioFragment, jsonPath }) => {
  let autocompleteVars:AutocompleteQueryVariables = { q: query }
  let searchType = get(SearchDisplayToTypes,type, undefined) as SearchTypes | undefined
  autocompleteVars.types = searchType? [searchType] : []
  const includedRows = list?.map((item) => ("ClientPortfolio:" + convertToClientPortfolioFragment(item))) || [] || []
  const { data } = useAutocompleteQuery({ variables: autocompleteVars})

  if (!!data && data.autocomplete) {
    const onClick = (addItem:AutocompleteItemTypes, linkUrl: string) => {
      setEditedList((editedList)=> {
        if(!editedList){
          return editedList
        }
        let updatedItems = cloneDeep(editedList)
        let typeName = addItem?.__typename
        const baseType = autocompleteMapping[typeName]
        let item = autocompleteToItem(addItem)
        let convertedNewItem = convertAddedItem(item)
        const baseId = get(item, `${typeIdMapping[baseType as ListMemberIdType]}`)

        let array = updatedItems
        if(jsonPath) {
          array = get(updatedItems, jsonPath) || []
        }

        const mainIndex = findIndex(updatedItems, (item) => {
          return item.type === baseType && get(item, `${typeIdMapping[baseType as ListMemberIdType]}`)?.toString() === baseId.toString()
        })
        if(mainIndex === -1){
          array.push(convertedNewItem)
          if(jsonPath) {
            set(updatedItems, jsonPath, array)
            return updatedItems
          }else {
            return updatedItems
          }
        }else {
          if(jsonPath) {
            set(updatedItems, jsonPath, array)
            return updatedItems
          }
          return editedList
        }
      })
    }
    if(disabled) {
      return <></>
    }
    return (
      <div className="list-sidebar-container h-100">
        <ListGroup className="horizontal with-category autocomplete has-includes">
          <SearchRows
            rows={data.autocomplete as SearchAutocompleteFragment[]}
            handleClick={onClick}
            includedRows={includedRows}
          />
        </ListGroup>
      </div>
    )
  }
  return <></>
}
import React, { Component } from 'react'
import { cloneDeep, compact, get, has, isNil, merge, pick, reduce, set, unset } from 'lodash'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import omitDeep from 'omit-deep-lodash'
import { Col, Row, Table } from 'reactstrap'

import Auth from '../../../Auth/Auth'
import { appDate } from '../../../Context/CalendarContext'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import { ActiveOrFrozenCode, DeletePerformanceKey, MarketValueInput, Performance, PerformanceCurrencyCode, PerformanceDividendReinvestmentCode, PerformanceFeesCode, PerformanceFeesLookup, PerformanceInput, PerformancePeriodType, PerformanceProjectionCode, PerformanceProjectionLookup, PerformanceTypeCode, PerformanceTypeLookup, VehiclePerformanceReturnsFragment, VehiclePerformanceReturnsQuery, VehicleUpdateFields } from '../../../__generated__/graphql'
import { FormInput } from '../../ui/Forms/FormInput'
import { GetLookupDataToOptions } from "../../ui/LookupOptions"
import { AllowedReturnVehicleTypes } from './Vehicles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'

const moment = extendMoment(Moment);

interface VehiclePerformanceReturn {
  fundid?: string
  name?: string | null
  netTotal?: VehiclePerformanceReturnsFragment[] | null
  grossTotal?: VehiclePerformanceReturnsFragment[] | null
  netTotalMarket?: VehiclePerformanceReturnsFragment[] | null
  netRetail?: VehiclePerformanceReturnsFragment[] | null
  netInstitutional?: VehiclePerformanceReturnsFragment[] | null
  netEqualWeighted?: VehiclePerformanceReturnsFragment[] | null
  grossIncome?: VehiclePerformanceReturnsFragment[] | null
  grossAppreciation?: VehiclePerformanceReturnsFragment[] | null
  netIncome?: VehiclePerformanceReturnsFragment[] | null
  netAppreciation?: VehiclePerformanceReturnsFragment[] | null
  historicMarketValues?: { date: string, value: number }[] | null
  historicTotalFundMarketValues?: { date: string, value: number }[] | null
}

const firstHistoricalDate = moment(appDate).subtract(30,"years")

const constructVehiclePerformanceReturn = (data:VehiclePerformanceReturnsQuery) => {
  const vehicle = data.vehicle_returns
  const vehicleData = vehicle?.vehicle

  if (isNil(vehicle) || isNil(vehicleData)) {
    return {}
  }

  const isOpenOrClosed = () => has(vehicle, 'closedEndedVehicle')

  let v:VehiclePerformanceReturn = {
    fundid: vehicleData?.id,
    name: vehicleData?.name,
    netTotal: [],
    grossTotal: [],
    netTotalMarket: [],
    netRetail: [],
    netInstitutional: [],
    netEqualWeighted: [],
    grossIncome: [],
    grossAppreciation: [],
    netIncome: [],
    netAppreciation: [],
    historicMarketValues: isOpenOrClosed() ? get(vehicle, 'closedEndedVehicle.historicalMarketValues') : get(vehicle, 'openEndedVehicle.historicalMarketValues'),
    historicTotalFundMarketValues: get(vehicle, 'openEndedVehicle.historicalTotalFundMarketValues'),
  }

  switch(vehicle.__typename) {
    case "OpenEndedVariableAnnuity":
    case "OpenEndedPrivateNonRegisteredHedgeFund":
      merge(v, pick(vehicleData, ['netTotal']))
      break;
    case "OpenEndedSeparateAccount":
    case "OpenEndedPooledVehicle":
    case "OpenEndedPooledVehiclePrivateCredit":
    case "OpenEndedPooledVehiclePrivateEquity":
    case "OpenEndedSeparateAccountStableValue":
      merge(v, pick(vehicleData, ['grossTotal', 'netTotal']))
      break;
    case "OpenEndedExchangeTradedFund":
      merge(v, pick(vehicleData, ['netTotal','netTotalMarket','grossTotal']))
      break;
    case "OpenEndedSeparateAccountRealAssets":
    case "OpenEndedPooledVehicleRealAssets":
    case "ClosedEndedPrivateNonRegisteredRealAssets":
      merge(v, pick(vehicleData, ['grossTotal','grossIncome','grossAppreciation','netTotal','netIncome','netAppreciation']))
      break;
    case "OpenEndedCollectiveInvestmentFundComposite":
    case "OpenEndedCollectiveInvestmentFundCompositeRealAssets":
    case "OpenEndedCollectiveInvestmentFundStableValueComposite":
      merge(v, pick(vehicleData, ['netTotal','grossTotal','netRetail','netInstitutional','netEqualWeighted']))
      break;
    case "OpenEndedMutualFund":
    case "OpenEndedCollectiveInvestmentFund":
    case "OpenEndedCollectiveInvestmentFundRealAssets":
    case "OpenEndedCollectiveInvestmentFundStableValue":
      merge(v, pick(vehicleData, ['netTotal','grossTotal','netRetail','netInstitutional','netEqualWeighted']))
      break;
  }
  return v
}

const generateFeesAndType = (type: keyof RawPerformances):{ type: PerformanceTypeLookup, fees: PerformanceFeesLookup} => {
  switch (type) {
    case "netTotal":
      return {
        type: { code: PerformanceTypeCode.t, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.n, __typename: 'PerformanceFeesLookup' }
      }
    case "netRetail":
      return {
        type: { code: PerformanceTypeCode.r, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.n, __typename: 'PerformanceFeesLookup' }
      }
    case "netIncome":
      return {
        type: { code: PerformanceTypeCode.i, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.n, __typename: 'PerformanceFeesLookup' }
      }
    case "netAppreciation":
      return {
        type: { code: PerformanceTypeCode.a, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.n, __typename: 'PerformanceFeesLookup' }
      }
    case "netTotalMarket":
      return {
        type: { code: PerformanceTypeCode.p, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.n, __typename: 'PerformanceFeesLookup' }
      }
    case "netInstitutional":
      return {
        type: { code: PerformanceTypeCode.m, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.n, __typename: 'PerformanceFeesLookup' }
      }
    case "netEqualWeighted":
      return {
        type: { code: PerformanceTypeCode.n, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.n, __typename: 'PerformanceFeesLookup' }
      }
    case "grossTotal":
      return {
        type: { code: PerformanceTypeCode.t, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.g, __typename: 'PerformanceFeesLookup' }
      }
    case "grossIncome":
      return {
        type: { code: PerformanceTypeCode.i, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.g, __typename: 'PerformanceFeesLookup' }
      }
    case "grossAppreciation":
      return {
        type: { code: PerformanceTypeCode.a, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.g, __typename: 'PerformanceFeesLookup' }
      }
    // Should not get called
    case "totalFundMarketValue":
      console.error("total fund market value should not get called")
      return {
        type: { code: PerformanceTypeCode.a, __typename: 'PerformanceTypeLookup' },
        fees: { code: PerformanceFeesCode.g, __typename: 'PerformanceFeesLookup' }
      }
  }
}

interface ProductPerformanceVehiclesProps {
  id: string
  editMode: boolean
  startDate: string
  endDate: string
  period: PerformancePeriodType
  data: VehiclePerformanceReturnsQuery
  handleChange: (newDiff:VehicleUpdateFields) => void
  handleDelete: (newDiff:DeletePerformanceKey[]) => void
  // handleMarketValueChange: (newMarketValue: OpenEndedMarketValueInput[]) => void
  editCancelled: boolean
  clearChanges: boolean
  fetchMore: any
  auth: Auth
}
interface ProductPerformanceVehiclesState {
  stateDiff: PerformanceInput[]
  deletedPerformances: DeletePerformanceKey[]
  marketValues: MarketValueInput[]
}

class ProductPerformanceVehiclesReturns extends Component<ProductPerformanceVehiclesProps,ProductPerformanceVehiclesState> {
  constructor(props: ProductPerformanceVehiclesProps) {
    super(props)

    this.state = {
      stateDiff: [],
      deletedPerformances: [],
      marketValues: []
    }
  }

  static getDerivedStateFromProps(nextProps:ProductPerformanceVehiclesProps, prevState:ProductPerformanceVehiclesState){
    if(nextProps.editCancelled || nextProps.clearChanges) {
      return { stateDiff: [], deletedPerformances: [], marketValues: []  };
    }

    return null;
  }

  setStateDiff = (newDiff:PerformanceInput[]) => {
    this.setState({stateDiff: newDiff}, () => {
      this.props.handleChange({ performance: newDiff, historicalMarketValues: this.state.marketValues })
    })
  }

  fetchMore = (newDate: moment.Moment) => {
    this.props.fetchMore({
      variables: {
        id: this.props.id,
        startDate: newDate.format(DATE_API_FORMAT),
        endDate: moment(newDate).add(5, "years").format(DATE_API_FORMAT),
        period: this.props.period
      },
      updateQuery: (previousResult: VehiclePerformanceReturnsQuery, { fetchMoreResult}: any) => {
        if(!fetchMoreResult){
          return previousResult
        }

        let newPreviousResult = cloneDeep(previousResult)

        if (newPreviousResult.vehicle_returns?.vehicle && ("netTotal" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.netTotal) {
          newPreviousResult.vehicle_returns.vehicle.netTotal = [...newPreviousResult.vehicle_returns.vehicle.netTotal , ...fetchMoreResult.vehicle_returns.vehicle.netTotal]
         }
        if (newPreviousResult.vehicle_returns?.vehicle && ("grossTotal" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.grossTotal) {
         newPreviousResult.vehicle_returns.vehicle.grossTotal = [...newPreviousResult.vehicle_returns.vehicle.grossTotal , ...fetchMoreResult.vehicle_returns.vehicle.grossTotal]
        }
        if (newPreviousResult.vehicle_returns?.vehicle && ("netTotalMarket" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.netTotalMarket) {
         newPreviousResult.vehicle_returns.vehicle.netTotalMarket = [...newPreviousResult.vehicle_returns.vehicle.netTotalMarket , ...fetchMoreResult.vehicle_returns.vehicle.netTotalMarket]
        }
        if (newPreviousResult.vehicle_returns?.vehicle && ("netRetail" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.netRetail) {
         newPreviousResult.vehicle_returns.vehicle.netRetail = [...newPreviousResult.vehicle_returns.vehicle.netRetail , ...fetchMoreResult.vehicle_returns.vehicle.netRetail]
        }
        if (newPreviousResult.vehicle_returns?.vehicle && ("netInstitutional" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.netInstitutional) {
         newPreviousResult.vehicle_returns.vehicle.netInstitutional = [...newPreviousResult.vehicle_returns.vehicle.netInstitutional , ...fetchMoreResult.vehicle_returns.vehicle.netInstitutional]
        }
        if (newPreviousResult.vehicle_returns?.vehicle && ("netEqualWeighted" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.netEqualWeighted) {
         newPreviousResult.vehicle_returns.vehicle.netEqualWeighted = [...newPreviousResult.vehicle_returns.vehicle.netEqualWeighted , ...fetchMoreResult.vehicle_returns.vehicle.netEqualWeighted]
        }
        if (newPreviousResult.vehicle_returns?.vehicle && ("grossIncome" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.grossIncome) {
         newPreviousResult.vehicle_returns.vehicle.grossIncome = [...newPreviousResult.vehicle_returns.vehicle.grossIncome , ...fetchMoreResult.vehicle_returns.vehicle.grossIncome]
        }
        if (newPreviousResult.vehicle_returns?.vehicle && ("grossAppreciation" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.grossAppreciation) {
         newPreviousResult.vehicle_returns.vehicle.grossAppreciation = [...newPreviousResult.vehicle_returns.vehicle.grossAppreciation , ...fetchMoreResult.vehicle_returns.vehicle.grossAppreciation]
        }
        if (newPreviousResult.vehicle_returns?.vehicle && ("netIncome" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.netIncome) {
         newPreviousResult.vehicle_returns.vehicle.netIncome = [...newPreviousResult.vehicle_returns.vehicle.netIncome , ...fetchMoreResult.vehicle_returns.vehicle.netIncome]
        }
        if (newPreviousResult.vehicle_returns?.vehicle && ("netAppreciation" in newPreviousResult.vehicle_returns.vehicle) && newPreviousResult.vehicle_returns?.vehicle.netAppreciation) {
         newPreviousResult.vehicle_returns.vehicle.netAppreciation = [...newPreviousResult.vehicle_returns.vehicle.netAppreciation , ...fetchMoreResult.vehicle_returns.vehicle.netAppreciation]
        }

        return newPreviousResult
      }
    })

  }

  convertPerformanceToInput = (newStateDiff: PerformanceInput[], updatedPerformance:VehiclePerformanceReturnsFragment, property: keyof RawPerformances, projectionCode?: PerformanceProjectionCode) => {
    const projection = projectionCode ? projectionCode : updatedPerformance.projection?.code

    const typeAndFees = generateFeesAndType(property)
    let performanceInput = omitDeep(updatedPerformance, '__typename')
    set(performanceInput, 'currency', updatedPerformance.currency?.code)
    set(performanceInput, 'type', typeAndFees.type.code)
    set(performanceInput, 'fees', typeAndFees.fees.code)
    set(performanceInput, 'dividendReinvestment', updatedPerformance.dividendReinvestment?.code)
    set(performanceInput, 'projection', projection)
    set(performanceInput, 'yearMonth', parseInt(moment(updatedPerformance.endDate).format('YYYYMM')))
    unset(performanceInput, 'startDate')
    unset(performanceInput, 'endDate')

    //
    const ind = newStateDiff.findIndex(s => { return (
      s.fees === performanceInput.fees &&
      s.type === performanceInput.type &&
      s.yearMonth === performanceInput.yearMonth &&
      s.period === performanceInput.period
    )})

    if (ind >= 0) {
      newStateDiff[ind] = performanceInput
    } else {
      newStateDiff.push(performanceInput)
    }

    return newStateDiff
  }

  removePerformanceFromDiff = (newStateDiff: PerformanceInput[], updatedPerformance:VehiclePerformanceReturnsFragment, property: keyof RawPerformances) => {
    const typeAndFees = generateFeesAndType(property)

    const ind = newStateDiff.findIndex(s => { return (
      s.fees === typeAndFees.fees.code &&
      s.type === typeAndFees.type.code &&
      s.yearMonth === parseInt(moment(updatedPerformance.endDate).format('YYYYMM')) &&
      s.period === updatedPerformance.period
    )})

    if (ind >= 0) {
      newStateDiff.splice(ind,1)
    }

    return newStateDiff
  }

  queuePerformanceForDeletion = (newDeletedPerformances: DeletePerformanceKey[], updatedPerformance:VehiclePerformanceReturnsFragment, property: keyof RawPerformances) => {
    const typeAndFees = generateFeesAndType(property)

    const ind = newDeletedPerformances.findIndex(s => { return (
      s.fees === typeAndFees.fees.code &&
      s.type === typeAndFees.type.code &&
      s.yearMonth === parseInt(moment(updatedPerformance.endDate).format('YYYYMM')) &&
      s.period === updatedPerformance.period
    )})

    if (ind < 0 && typeAndFees.type.code && typeAndFees.fees.code && updatedPerformance.dividendReinvestment?.code && updatedPerformance.period && updatedPerformance.currency?.code) {
      newDeletedPerformances.push({
        yearMonth: parseInt(moment(updatedPerformance.endDate).format('YYYYMM')),
        type: typeAndFees.type.code,
        fees: typeAndFees.fees.code,
        dividendReinvestment: updatedPerformance.dividendReinvestment?.code,
        period: updatedPerformance.period,
        activeOrFrozen: ActiveOrFrozenCode.A,
        currency: updatedPerformance.currency?.code
      })
    }

    return newDeletedPerformances
  }

  removePerformanceFromDeletion = (newDeletedPerformances: DeletePerformanceKey[], updatedPerformance:VehiclePerformanceReturnsFragment, property: keyof RawPerformances) => {
    const typeAndFees = generateFeesAndType(property)

    const ind = newDeletedPerformances.findIndex(s => { return (
      s.fees === typeAndFees.fees.code &&
      s.type === typeAndFees.type.code &&
      s.yearMonth === parseInt(moment(updatedPerformance.endDate).format('YYYYMM')) &&
      s.period === updatedPerformance.period
    )})

    if (ind >= 0) {
      newDeletedPerformances.splice(ind,1)
    }

    return newDeletedPerformances
  }

  handleChange = (updatedPerformance:VehiclePerformanceReturnsFragment, projectionCode: PerformanceProjectionCode, property: keyof RawPerformances) => {
    let newStateDiff = cloneDeep(this.state.stateDiff)
    newStateDiff = this.convertPerformanceToInput(newStateDiff, updatedPerformance, property, projectionCode)

    let newDeletedPerformances = cloneDeep(this.state.deletedPerformances)
    newDeletedPerformances = this.removePerformanceFromDeletion(newDeletedPerformances, updatedPerformance, property)

    this.setState({stateDiff: newStateDiff, deletedPerformances: newDeletedPerformances}, () => {
      this.props.handleChange({ performance: newStateDiff, historicalMarketValues: this.state.marketValues })
      this.props.handleDelete(newDeletedPerformances)
    })
  }

  handleDeletePerformance = (deletedPerformance:VehiclePerformanceReturnsFragment, projectionCode: PerformanceProjectionCode, property: keyof RawPerformances) => {
    let newStateDiff = cloneDeep(this.state.stateDiff)
    newStateDiff = this.removePerformanceFromDiff(newStateDiff, deletedPerformance, property)

    let newDeletedPerformances = cloneDeep(this.state.deletedPerformances)
    newDeletedPerformances = this.queuePerformanceForDeletion(newDeletedPerformances, deletedPerformance, property)

    this.setState({stateDiff: newStateDiff, deletedPerformances: newDeletedPerformances}, () => {
      this.props.handleChange({ performance: newStateDiff, historicalMarketValues: this.state.marketValues })
      this.props.handleDelete(newDeletedPerformances)
    })

  }

  handleProjectionChange = (performances: PerformanceUpdate[]) => {
    let newStateDiff = cloneDeep(this.state.stateDiff)

    performances.map((perf) => newStateDiff = this.convertPerformanceToInput(newStateDiff, perf.performance, perf.key as keyof RawPerformances))

    this.setStateDiff(newStateDiff)
  }

  handleMarketValueChange = (date: string, value: number) => {
    let newMarketValues = cloneDeep(this.state.marketValues)
    let ind = newMarketValues.findIndex(s => s.date === date)
    if (ind >= 0) {
      set(newMarketValues, [ind, 'value'], value)
    } else {
      newMarketValues.push({ date, value})
    }

    this.setState({ marketValues: newMarketValues }, () => {
      this.props.handleChange({ performance: this.state.stateDiff, historicalMarketValues: newMarketValues })
    })
  }

  render() {
    const { editMode, startDate, period, data } = this.props

    if (data && data.vehicle_returns?.vehicle && AllowedReturnVehicleTypes.includes(data.vehicle_returns.__typename)) {
      const vehicle:VehiclePerformanceReturn = constructVehiclePerformanceReturn(data)

     let checkVehiclesLength =  reduce(vehicle, (result, value, key) => {
        if (Array.isArray(value) && key !== 'historicMarketValues') {
          return (value.length > result) ? value.length : result
        }
        return result
     }, 0)

      return (
        <ReturnsDisplay
          key={`returns-${vehicle.fundid}-display-${period}-${startDate}-${checkVehiclesLength}`}
          editMode={editMode}
          vehicle={vehicle}
          startDate={startDate}
          vehicleType={data.vehicle_returns.__typename}
          handleChange={this.handleChange}
          handleDeletePerformance={this.handleDeletePerformance}
          handleProjectionChange={this.handleProjectionChange}
          handleMarketValueChange={this.handleMarketValueChange}
          period={period}
          editCancelled={this.props.editCancelled}
          auth={this.props.auth}
        />
      )
    }

    return <></>
  }

}

interface ReturnsDisplayProps {
  editMode: boolean
  vehicle: VehiclePerformanceReturn
  vehicleType: string
  startDate: string
  handleChange: (updatedPerformance: VehiclePerformanceReturnsFragment, projectionCode: PerformanceProjectionCode, property: keyof RawPerformances) => void
  handleDeletePerformance: (deletedPerformance: VehiclePerformanceReturnsFragment, projectionCode: PerformanceProjectionCode, property: keyof RawPerformances) => void
  handleProjectionChange: (performances: PerformanceUpdate[]) => void
  handleMarketValueChange: (date: string, value: number) => void
  period: PerformancePeriodType
  editCancelled: boolean
  auth: Auth
}

interface ReturnsDisplayState {
  currentState: ReturnsState[]
  initialState: ReturnsState[]
}

interface ReturnsPerformanceValue {
  value: number | null
  raw: VehiclePerformanceReturnsFragment | null
}

// These can definitely be combined somehow but I'm out of time at the moment
const RawPerformancesKeys = [ "netTotal", "grossTotal", "netTotalMarket", "netRetail", "netInstitutional", "netEqualWeighted", "grossIncome", "grossAppreciation", "netIncome", "netAppreciation"]
// type RawPerformancesKeysType = "netTotal" | "grossTotal" | "netTotalMarket" | "netRetail" | "netInstitutional" | "netEqualWeighted" | "grossIncome" | "grossAppreciation" | "netIncome" | "netAppreciation"

interface RawPerformances {
  netTotal: ReturnsPerformanceValue | null
  grossTotal: ReturnsPerformanceValue | null
  netTotalMarket: ReturnsPerformanceValue | null
  netRetail: ReturnsPerformanceValue | null
  netInstitutional: ReturnsPerformanceValue | null
  netEqualWeighted: ReturnsPerformanceValue | null
  grossIncome: ReturnsPerformanceValue | null
  grossAppreciation: ReturnsPerformanceValue | null
  netIncome: ReturnsPerformanceValue | null
  netAppreciation: ReturnsPerformanceValue | null
  totalFundMarketValue: ReturnsPerformanceValue | null
}

interface ReturnsState extends RawPerformances {
  date: string
  projection: PerformanceProjectionLookup | null
  marketValue?: number
}

type PerformanceUpdate  = { key: keyof RawPerformances, performance: VehiclePerformanceReturnsFragment}

interface ColumnEntry {
  title: string
  column: keyof RawPerformances
  editable: boolean
  subtype?: 'currency' | 'percent'
}

const performanceFilter = (performanceArray?:Performance[] | null) => {
  if (isNil(performanceArray)) { return [] }
  return performanceArray
    .filter(p => p?.projection?.code && ['r','e'].includes(p?.projection?.code))
    .filter(p => !isNil(p.value?.active) || (isNil(p.value?.active) && isNil(p.value?.frozen)))
}

class ReturnsDisplay extends Component<ReturnsDisplayProps,ReturnsDisplayState> {
  constructor(props: ReturnsDisplayProps) {
    super(props)
    const { vehicle, startDate, period} = props

    let intiState = ReturnsDisplay.generateVehiclePerformance(vehicle, startDate, period)

    this.state = {
      currentState: intiState,
      initialState: intiState,
    }
  }

  static getDerivedStateFromProps(props: ReturnsDisplayProps, state: ReturnsDisplayState) {
    const { vehicle, startDate, period } = props
    const resetDate = startDate !== moment(firstHistoricalDate).format(DATE_API_FORMAT)
    let intiState = ReturnsDisplay.generateVehiclePerformance(vehicle, startDate, period)
    if(props.editCancelled) {
      return {
        currentState: intiState,
        initialState: intiState,
      }
    }else if(!props.editMode) {
      return {
        currentState: resetDate && !props.editMode ? intiState : state.currentState,
        initialState: resetDate ? intiState : state.initialState,
      }
    }
    return null
  }

  static generateVehiclePerformance = (vehicle: VehiclePerformanceReturn, startDate: string, period: PerformancePeriodType) => {
    let dates: Moment.Moment[] = []
    const endOf = period === PerformancePeriodType.Monthly ? 'month' : 'quarter'
    let dateRange = moment.range(moment(startDate, DATE_API_FORMAT).add(1, endOf).endOf(endOf), moment().subtract(1, endOf).endOf(endOf))

    dates = Array.from(dateRange.by(endOf)).map(d => d.endOf(endOf)).reverse()
    let initState: ReturnsState[] = []
    dates.map(momentDate => {
      const date = momentDate.format(DATE_API_FORMAT)
      const marketValue = vehicle?.historicMarketValues?.find(p => p.date === date)
      const totalFundMarketValue = vehicle?.historicTotalFundMarketValues?.find(p => p.date === date )

      const projections = compact(RawPerformancesKeys.map(key => get(vehicle, [key])?.find((p: VehiclePerformanceReturnsFragment) => p.endDate === date && (!isNil(p.value?.active) || (isNil(p.value?.active) && isNil(p.value?.frozen))))?.projection))
      const projection = projections.find(p => p.code === PerformanceProjectionCode.e) || projections.find(p => p.code === PerformanceProjectionCode.r) || projections[0] || null

      let performances: Record<string, VehiclePerformanceReturnsFragment | null> = {
        netTotal: performanceFilter(vehicle?.netTotal)?.find(p => p.endDate === date) || null,
        grossTotal: performanceFilter(vehicle?.grossTotal)?.find(p => p.endDate === date) || null,
        netTotalMarket: performanceFilter(vehicle?.netTotalMarket)?.find(p => p.endDate === date) || null,
        netRetail: performanceFilter(vehicle?.netRetail)?.find(p => p.endDate === date) || null,
        netInstitutional: performanceFilter(vehicle?.netInstitutional)?.find(p => p.endDate === date) || null,
        netEqualWeighted: performanceFilter(vehicle?.netEqualWeighted)?.find(p => p.endDate === date) || null,
        grossIncome: performanceFilter(vehicle?.grossIncome)?.find(p => p.endDate === date) || null,
        grossAppreciation: performanceFilter(vehicle?.grossAppreciation)?.find(p => p.endDate === date) || null,
        netIncome: performanceFilter(vehicle?.netIncome)?.find(p => p.endDate === date) || null,
        netAppreciation: performanceFilter(vehicle?.netAppreciation)?.find(p => p.endDate === date) || null
      }
      // change for performance display flag.
      const showValue = (!projection || projection?.code === null) || projection.code === PerformanceProjectionCode.r || projection.code === PerformanceProjectionCode.e

      initState.push({
        date: date,
        projection: showValue ? projection : null,
        netTotal: { raw: performances.netTotal, value: performances.netTotal?.value?.active != null && showValue ? performances.netTotal.value.active : null },
        grossTotal: { raw: performances.grossTotal, value: performances.grossTotal?.value?.active != null && showValue ? performances.grossTotal.value.active : null },
        netTotalMarket: { raw: performances.netTotalMarket, value: performances.netTotalMarket?.value?.active != null && showValue ? performances.netTotalMarket.value.active : null },
        netRetail: { raw: performances.netRetail, value: performances.netRetail?.value?.active != null && showValue ? performances.netRetail.value.active : null },
        netInstitutional: { raw: performances.netInstitutional, value: performances.netInstitutional?.value?.active != null && showValue ? performances.netInstitutional.value.active : null },
        netEqualWeighted: { raw: performances.netEqualWeighted, value: performances.netEqualWeighted?.value?.active != null && showValue ? performances.netEqualWeighted.value.active : null },
        grossIncome: { raw: performances.grossIncome, value: performances.grossIncome?.value?.active != null && showValue ? performances.grossIncome.value.active : null },
        grossAppreciation: { raw: performances.grossAppreciation, value: performances.grossAppreciation?.value?.active != null && showValue ? performances.grossAppreciation.value.active : null },
        netIncome: { raw: performances.netIncome, value: performances.netIncome?.value?.active != null && showValue ? performances.netIncome.value.active : null },
        netAppreciation: { raw: performances.netAppreciation, value: performances.netAppreciation?.value?.active != null && showValue ? performances.netAppreciation.value.active : null },
        // show market value if it exists.
        marketValue: marketValue?.value || undefined,
        totalFundMarketValue: { raw: null, value: (totalFundMarketValue?.value || null)},
      })
    })

    return initState
  }

  handleInputChange = (date: string, property: keyof RawPerformances, value:number) => {

    let newState = cloneDeep(this.state.currentState)
    let ind = newState.findIndex(s => s.date === date)
    let updatedPerformance:VehiclePerformanceReturnsFragment | null = get(newState,[ind, property, 'raw'])
    let projectionCode = PerformanceProjectionCode.r
    // Value can only be r or e after an edit
    if(newState[ind].projection?.code === PerformanceProjectionCode.e){
      projectionCode = PerformanceProjectionCode.e
    }

    if (isNil(value)) {
      if (updatedPerformance && ind >= 0) {
        unset(newState, [ind, property])

        this.setState({currentState: newState},() => {
          if (updatedPerformance) {
            this.props.handleDeletePerformance(updatedPerformance, projectionCode, property)
          }
        })
      }
    } else {
      if (updatedPerformance) {
        set(updatedPerformance, ['value', 'active'], value)
      } else {
        updatedPerformance = this.buildNewPerformance(property, this.props.period, date, value, projectionCode )
      }

      if (ind >= 0) {
        set(newState, [ind, property], { value, raw: updatedPerformance})
        set(newState, [ind, 'projection'], { code: projectionCode, __typename: 'PerformanceProjectionLookup'})
      }

      this.setState({currentState: newState},() => {
        if (updatedPerformance) {
          this.props.handleChange(updatedPerformance, projectionCode, property)
        }
      })
    }
  }

  handleProjectionChange = (date: string, value: PerformanceProjectionCode.r | PerformanceProjectionCode.e) => {
    let newState = cloneDeep(this.state.currentState)
    let ind = newState.findIndex(s => s.date === date)

    let updatedPerformances:PerformanceUpdate[] = []

    const editableKeys = this.tableColumns().filter(c => c.editable === true).map(c => c.column)
    editableKeys.map(key => {
      let updatedPerformance = get(newState,[ind, key, 'raw'])

      if (!isNil(updatedPerformance)) {
        set(updatedPerformance, ['projection','code'], value)
        set(newState, [ind, key, 'raw'], updatedPerformance)
        updatedPerformances.push({ key: key as keyof RawPerformances, performance: updatedPerformance})
      }
    })

    if (ind >= 0) {
      set(newState, [ind, 'projection'], { code: value })
    }

    this.setState({currentState: newState},() => {
      if (updatedPerformances) {
        this.props.handleProjectionChange(updatedPerformances)
      }
    })
  }

  handleMarketValueChange = (date: string, value: number) => {
    let newState = cloneDeep(this.state.currentState)
    let ind = newState.findIndex(s => s.date === date)
    if (ind >= 0) {
      set(newState, [ind, 'marketValue'], value)
    } else {
      newState.push({
        date,
        marketValue: value,
        totalFundMarketValue: null,
        netTotal: null,
        grossTotal: null,
        netTotalMarket: null,
        netRetail: null,
        netInstitutional: null,
        netEqualWeighted: null,
        grossIncome: null,
        grossAppreciation: null,
        netIncome: null,
        netAppreciation: null,
        projection: null,
      })
    }

    this.setState({currentState: newState},() => {
      // pass new market value up to Vehicle in whatever format input requires
      this.props.handleMarketValueChange( date, value)
    })
  }

  buildNewPerformance = (type: keyof RawPerformances, period:PerformancePeriodType, endDate:string, value:number, projectionCode: PerformanceProjectionCode) => {
    let mEndDate = moment(endDate).format(DATE_API_FORMAT)
    let mStartDate = moment(endDate).subtract(period === 'Monthly' ? 1 : 3, 'months').add(1,'day').format(DATE_API_FORMAT)
    let newPerformance:Performance = {
      __typename: 'Performance',
      startDate: mStartDate,
      endDate: mEndDate,
      period: period as PerformancePeriodType,
      value: { active: value, __typename: 'PerformanceValue' },
      currency: { code: PerformanceCurrencyCode.u, __typename: 'PerformanceCurrencyLookup' },
      projection: { code: projectionCode, __typename: 'PerformanceProjectionLookup'},
      dividendReinvestment: { code: PerformanceDividendReinvestmentCode.g, __typename: 'PerformanceDividendReinvestmentLookup'}
    }

    const typeAndFees = generateFeesAndType(type)
    newPerformance.type = typeAndFees.type
    newPerformance.fees = typeAndFees.fees

    return newPerformance

  }

  tableColumns = ():ColumnEntry[] => {
    let columns:ColumnEntry[] = []
    const editReturnsCalc = this.props.auth.checkPermissions(['edit:returns_calculated'])
    const editReturns = this.props.auth.checkPermissions(['edit:returns'])
    const viewReturns = this.props.auth.checkPermissions(['view:returns'])
    const isPeriodQuarter = this.props.period === PerformancePeriodType.Quarterly
    const isPeriodMonth = this.props.period === PerformancePeriodType.Monthly

    switch(this.props.vehicleType) {
      case "OpenEndedVariableAnnuity":
      case "OpenEndedPrivateNonRegisteredHedgeFund":
        return [{ title: 'Net', column: 'netTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns }]
      case "OpenEndedSeparateAccount":
      case "OpenEndedPooledVehicle":
      case "OpenEndedSeparateAccountStableValue":
        return [
          { title: 'Gross', column: 'grossTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns  },
          { title: 'Net', column: 'netTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns  },
        ]
      case "OpenEndedPooledVehiclePrivateCredit": // no monthly view
      case "OpenEndedPooledVehiclePrivateEquity": // no monthly view
        return [
          { title: 'Gross', column: 'grossTotal', editable: editReturns  },
          { title: 'Net', column: 'netTotal', editable: editReturns  },
        ]
      case "OpenEndedExchangeTradedFund":
        if (isPeriodMonth) {
          return [
            { title: 'Total Fund Market Value ($M)', column: 'totalFundMarketValue', subtype: 'currency' , editable: false },
            { title: 'Gross', column: 'grossTotal', editable: editReturnsCalc },
            { title: 'Net', column: 'netTotal', editable: editReturnsCalc },
            { title: 'Market Price Return Net', column: 'netTotalMarket', editable: editReturnsCalc },
          ]
        } else {
          return [
            { title: 'Gross', column: 'grossTotal', editable: editReturnsCalc },
            { title: 'Net', column: 'netTotal', editable: editReturnsCalc },
            { title: 'Market Price Return Net', column: 'netTotalMarket', editable: editReturnsCalc },
          ]
        }

      case "OpenEndedSeparateAccountRealAssets": // no monthly view
      case "OpenEndedPooledVehicleRealAssets": // no monthly view
      case "ClosedEndedPrivateNonRegisteredRealAssets": // no monthly view
        return [
          { title: 'Gross', column: 'grossTotal', editable: editReturns },
          { title: 'Net', column: 'netTotal', editable: editReturns },
          { title: 'Income Gross', column: 'grossIncome', editable: editReturns },
          { title: 'Income Net', column: 'netIncome', editable: editReturns },
          { title: 'Appreciation Gross', column: 'grossAppreciation', editable: editReturns },
          { title: 'Appreciation Net', column: 'netAppreciation', editable: editReturns },
        ]
      case "OpenEndedMutualFund":
        columns = [
          { title: 'Total Fund Market Value ($M)', column: 'totalFundMarketValue', subtype: 'currency' , editable: false },
          { title: 'Net', column: 'netTotal', editable: editReturnsCalc }
        ]
        if (viewReturns) {
          columns.unshift({ title: 'Gross', column: 'grossTotal', editable: editReturnsCalc })
          columns = columns.concat([
            { title: 'Equal Weighted Net', column: 'netEqualWeighted', editable: editReturnsCalc },
            { title: 'Institutional Net', column: 'netInstitutional', editable: editReturnsCalc },
            { title: 'Retail Net', column: 'netRetail', editable: editReturnsCalc }
          ])
        }
        return columns

      case "OpenEndedCollectiveInvestmentFundStableValueComposite":
        columns = [
          { title: 'Gross', column: 'grossTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns },
          { title: 'Net', column: 'netTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns },
        ]
        if (viewReturns) {
          columns = columns.concat([
            { title: 'Equal Weighted Net', column: 'netEqualWeighted', editable: editReturnsCalc },
            { title: 'Institutional Net', column: 'netInstitutional', editable: editReturnsCalc },
            { title: 'Retail Net', column: 'netRetail', editable: editReturnsCalc },
          ])
        }

        return columns
      case "OpenEndedCollectiveInvestmentFundStableValue":
        columns = [
          { title: 'Total Fund Market Value ($M)', column: 'totalFundMarketValue', subtype: 'currency' , editable: false },
          { title: 'Gross', column: 'grossTotal', editable: editReturnsCalc },
          { title: 'Net', column: 'netTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns },
        ]
        if (viewReturns) {
          columns = columns.concat([
            { title: 'Equal Weighted Net', column: 'netEqualWeighted', editable: editReturnsCalc },
            { title: 'Institutional Net', column: 'netInstitutional', editable: editReturnsCalc },
            { title: 'Retail Net', column: 'netRetail', editable: editReturnsCalc },
          ])
        }

        return columns
      case "OpenEndedCollectiveInvestmentFund":
      case "OpenEndedCollectiveInvestmentFundRealAssets":
        columns = [
          { title: 'Total Fund Market Value ($M)', column: 'totalFundMarketValue', subtype: 'currency' , editable: false },
          { title: 'Gross', column: 'grossTotal', editable: editReturnsCalc },
          { title: 'Net', column: 'netTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns },
        ]

        if (viewReturns) {
          columns = columns.concat([
            { title: 'Equal Weighted Net', column: 'netEqualWeighted', editable: editReturnsCalc },
            { title: 'Institutional Net', column: 'netInstitutional', editable: editReturnsCalc },
            { title: 'Retail Net', column: 'netRetail', editable: editReturnsCalc },
          ])
        }

        return columns
      case "OpenEndedCollectiveInvestmentFundComposite":
      case "OpenEndedCollectiveInvestmentFundCompositeRealAssets":
        if (viewReturns) {
          return [
            { title: 'Gross', column: 'grossTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns },
            { title: 'Net', column: 'netTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns },
            { title: 'Equal Weighted Net', column: 'netEqualWeighted', editable: editReturnsCalc },
            { title: 'Institutional Net', column: 'netInstitutional', editable: editReturnsCalc },
            { title: 'Retail Net', column: 'netRetail', editable: editReturnsCalc },
          ]
        } else {
          return [
            { title: 'Gross', column: 'grossTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns },
            { title: 'Net', column: 'netTotal', editable: isPeriodQuarter ? editReturnsCalc : editReturns },
          ]
        }
    }
    return []
  }

  render() {
    const returns = this.state.currentState

    // NOTE: totalFundMarketValue starts first
    const returnsTableColumnsOrder: ColumnEntry[] = this.tableColumns().sort((x, y) => {
    return x.column === 'totalFundMarketValue' ? -1 : y.column === 'totalFundMarketValue' ? 1 : 0
    })

    return (
      <div className='pane pane-table'>
        <Row>
          <Col md='12'>
            <div className='table-container'>
              <Table hover className='table-bordered-internal exportable' key={`return-table-${this.props.period}`} data-export-name={`${this.props.vehicle.name} performance-vehicle`}>
                <thead>
                  <tr>
                    <th>Date</th>
                    {returnsTableColumnsOrder.map((column: ColumnEntry) => {
                      return (
                        column.column === 'totalFundMarketValue' && (
                          <th key={`column-title-${column.column}`} className='width-100'>
                            {column.title}
                          </th>
                        )
                      );
                    })}
                    {this.props.vehicleType === 'OpenEndedCollectiveInvestmentFundComposite' || this.props.vehicleType === 'OpenEndedCollectiveInvestmentFundStableValueComposite' || this.props.vehicleType === 'OpenEndedCollectiveInvestmentFundCompositeRealAssets' ? (
                      <th className='width-140'>Total Fund Market Value ($M)</th>
                    ) : (
                      <th className='width-140'>Vehicle Market Value ($M)
                        <div className="d-inline-flex align-items-center tooltip-icon" id="vehicleMarketValueTooltip">
                        <FontAwesomeIcon
                          icon={"question-circle" as IconName}
                          size="sm"
                        />
                        </div>
                      </th>

                    )}
                    {returnsTableColumnsOrder.map((column: ColumnEntry) => {
                      return (
                        column.column !== 'totalFundMarketValue' && (
                          <th key={`column-title-${column.column}`} className='width-100'>
                            {column.title}
                            {column.column === "netTotal" &&
                              <div className="d-inline-flex align-items-center tooltip-icon" id="netTotalTooltip">
                                <FontAwesomeIcon
                                  icon={"question-circle" as IconName}
                                  size="sm"
                                />
                              </div>
                            }
                          </th>
                        )
                      );
                    })}
                    <th>Final/Preliminary</th>
                  </tr>
                </thead>
                <tbody>
                  {returns.map((returnsData: ReturnsState, row: number) => {
                    return (
                      <ReturnsTableRow
                        key={`${this.props.vehicleType}-${returnsData.date}`}
                        vehicleId={this.props.vehicle.fundid || ''}
                        data={returnsData}
                        row={row}
                        columns={returnsTableColumnsOrder}
                        editMode={this.props.editMode}
                        updateValue={(date, property, value) => this.handleInputChange(date, property, value)}
                        updateProjection={this.handleProjectionChange}
                        handleMarketValueChange={this.handleMarketValueChange}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

interface ReturnsTableRowProps {
  data: ReturnsState
  row: number
  editMode: boolean
  columns: ColumnEntry[]
  vehicleId: string
  updateValue: (date: string, property: keyof RawPerformances, value:number) => void
  updateProjection: (date: string, value: PerformanceProjectionCode.r | PerformanceProjectionCode.e) =>  void
  handleMarketValueChange: (date:string, value: number) => void
}


const ReturnsTableRow = ({data, row, editMode, updateValue, columns, vehicleId, handleMarketValueChange, updateProjection}: ReturnsTableRowProps) => {
  const date = moment(data.date)

  const projectionOptions = GetLookupDataToOptions({
    data: [
      {
        code: "r",
        value: "Final",
      },
      {
        code: "e",
        value: "Preliminary",
      },
    ],
    hideBlank: true
  })

  const currentProjection = data?.projection?.code

  return (
    <tr>
      <td>
        {date.format(DATE_DISPLAY_FORMAT)}
      </td>
      {columns.map(({title, column, editable, subtype }, idx) => {

      let propertyVal = get(data, [column,'value'])
      let onChangeCallback = (value: any) => updateValue(date.format(DATE_API_FORMAT), column, value)

       return column === 'totalFundMarketValue' && (
          <td key={idx}>
            <FormInput
              idx={`return-${vehicleId}-${date.format(DATE_API_FORMAT)}-${column}`}
              property={column + '-ac'}
              displayName=''
              type='float'
              subtype={subtype || 'percent'}
              editMode={editMode && editable}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              showZero={true}
              editDecimalPlaces={4}
              inputProps={{
                onFocus: (event) => {
                  event.target.select();
                },
              }}
            />
          </td>
        )
      })}
      <td>
        <FormInput
          idx={`return-${vehicleId}-${date.format(DATE_API_FORMAT)}-marketValue`}
          property={"marketValue"}
          displayName=''
          type='float'
          subtype='currency'
          editMode={editMode}
          propertyVal={data.marketValue}
          updateValue={(v) => handleMarketValueChange(date.format(DATE_API_FORMAT), v)}
          showZero={true}
          inputProps={{
            onFocus: (event) => {event.target.select()}
          }}
        />
      </td>
      {columns.map(({ title, column, editable, subtype }, idx) => {

        let propertyVal = get(data, [column,'value'])
        let onChangeCallback = (value: any) => updateValue(date.format(DATE_API_FORMAT), column, value)

        return column !== 'totalFundMarketValue' && (
          <td key={idx}>
            <FormInput
              idx={`return-${vehicleId}-${date.format(DATE_API_FORMAT)}-${column}`}
              property={column+"-ac"}
              displayName=''
              type='float'
              subtype={subtype || 'percent'}
              editMode={editMode && editable}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              showZero={true}
              editDecimalPlaces={4}
              inputProps={{
                onFocus: (event) => {event.target.select()}
              }}
              />
          </td>
        )
      })}
      <td>
        <FormInput
          idx={`return-${vehicleId}-${date.format(DATE_API_FORMAT)}-projection`}
          property="projection"
          displayName=''
          type='select'
          editMode={editMode}
          propertyVal={currentProjection}
          updateValue={(value:PerformanceProjectionCode.r | PerformanceProjectionCode.e) => updateProjection(date.format(DATE_API_FORMAT), value)}
          options={projectionOptions}
        />
      </td>
    </tr>
  )
}

export default ProductPerformanceVehiclesReturns
import { CellClassParams, ColDef, EditableCallbackParams, ICellRendererParams, IRowNode, RowNode, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from '@ag-grid-community/core'
import classNames from 'classnames'
import _, { cloneDeep, compact, first, get, sortBy } from 'lodash'
import moment from 'moment'
import React from "react"
import { CalendarPickerPeriod } from '../Components/CalendarPicker'
import { CashFlowDataTypeWithEndingAssets } from '../Components/Plan/PlanPerformanceCashFlow'
import { ReturnColumnDataType } from '../Components/Plan/PlanPerformanceReturns'
import { currencyWithMantissaFormat } from '../Components/Report/Shared/ReportComponent'
import { CashFlowDataType, TransactionTypeWithTitles } from '../Components/Report/SumSheet'
import { appDate } from "../Context/CalendarContext"
import { AssetClassAbbreviationCode, BaseCurrencyCode, ClientPortfolioReturnTypeCode, DashboardStoplightFragment, ListSimpleFragment, ListSubCategoryCode, MeFragment, PortfolioPeriod, ReportSimpleFragment, TrainingFrequencyCode } from '../__generated__/graphql'
import { ReportPlanRenderer, benchmarkTooltipCellRenderer, calendarCellRenderer, cashflowAmountTooltipCellRenderer, cashflowChangedDateRenderer, checkOrCrossRenderer, downloadIconRenderer, endingMarketTooltipCellRenderer, favoriteIconRenderer, fileIconRenderer, glidepathLinkRenderer, overValueWarningRenderer, rightAlignedRenderer, selectTransactionTypeRenderer, sourceCashFlowTooltipCellRenderer, sourceTooltipCellRenderer, statusIconRenderer, statusIconRendererWithDate, tickIconRenderer, vehicleReturnTooltipCellRenderer, statusIconRendererWithDateAndMonitor, statusIconRendererWithMonitor } from './agGridHelpers'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, DATE_TEXT_FORMAT, DATE_TIME_API_FORMAT, DATE_TIME_DISPLAY_FORMAT } from "./constant"
import { DocumentSubTypeComparator, ListLinkRenderer, ReportLinkRenderer, clientsLinkRenderer, currencyFormatter, currencySymbolMapping, currentVersionUpdatedGetter, dateFilterParams, dateFormatter, documentClientLinkRenderer, falsyAtBottomComparator, falsyDateAtBottomComparator, falsyDateAtTopComparator, falsyFormatter, fundManagerDocumentLinkRenderer, getLatestPlanAsset, headerComponentWithIcon, listOrderFalsyAtBottomComparator, managerProductCountGetter, managersLinkRenderer, mappingValueGetter, percentFormatter, planAssetsValueGetter, planLinkRenderer, plansLinkRenderer, portfolioLinkRenderer, portfolioManagerLinkRenderer, portfolioProductLinkRenderer, portfolioVehicleLinkRenderer, productLinkRenderer, recentAUMValueGetter, reportClientLinkRenderer, reportPlanLinkRenderer, simpleMappingFormatter, statusValueGetter, statusValueGetterWithDate, stoplightProductLinkRenderer, stoplightVehicleLinkRenderer, totalAUMValueGetter } from './helpers'

export const ManagersListColumnDef = () => [
  {
    headerName: "Manager Name",
    field: "name",
    // for select several
    checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    sort: "asc",
    // width: 500,
    flex: 9,
    minWidth: 500,
    cellRenderer: managersLinkRenderer,
  },
  {
    headerName: "Manager ID",
    field: "id",
    sortable: true,
    minWidth: 120,
    flex: 1,
    cellClass: "table-cell-number"
  },
  {
    headerName: "City",
    field: "city",
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.address
        ? params.data.address.city
        : null
    },
    valueFormatter: falsyFormatter,
    sortable: true,
    minWidth: 100,
    flex: 2,
  },
  {
    headerName: "State",
    field: "state",
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.address
        ? params.data.address.state
        : null
    },
    valueFormatter: falsyFormatter,
    sortable: true,
    minWidth: 100,
    flex: 2,
  },
  {
    headerName: "AUM ($M)",
    field: "aum",
    valueGetter: totalAUMValueGetter,
    valueFormatter: currencyFormatter,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    sortable: true,
    minWidth: 120,
    flex: 2,
  },
  {
    headerName: "# of Products",
    field: "products",
    valueGetter: managerProductCountGetter,
    valueFormatter: falsyFormatter,
    cellClass: "table-cell-number",
    sortable: true,
    minWidth: 130,
    flex: 1,
  },
  {
    headerName: "Status",
    field: "isInactive",
    valueGetter: (params: ValueGetterParams ) => mappingValueGetter(params, "isInactive", InactiveMapping),
    // valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, InactiveMapping),
    // keyCreator: (params:any) => simpleMappingFormatter(params, InactiveMapping),
    sortable: true,
    flex: 1,
    minWidth: 100,
  }
]

export const managerEmployeeJobCategoryRTColumnDef = [
  { Header: "Job Category", accessor: "type.value" },
  { Header: "# of Employees", accessor: "count" }
]

const activePassiveMapping= {
  "ACTIVE": 'Active',
  "Active": 'Active',
  "PASS": 'Passive',
  "Passive": 'Passive',
  "BLEND": 'Blend',
  "Active/Passive Blend": 'Blend',
}

const InactiveMapping= {
  true: 'Inactive',
  false: 'Active'
}

const ActiveMapping= {
  true: 'Active',
  false: 'Inactive'
}

const TestMapping= {
  true: 'Test',
  false: ''
}

const CustomerMapping = {
  CUST: 'Active',
  FCUST: 'Inactive',
}

export const managersProductsListColumnDef = () => [
  {
    headerName: "Product Name",
    field: "product.name",
    // for select several
    // checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    cellRenderer: productLinkRenderer,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    sort: "asc",
    width: 350,
    headerComponentParams: {
      template: headerComponentWithIcon("productNameTooltip")
    }
  },
  {
    headerName: "Product ID",
    field: "product.id",
    sortable: true,
    width: 140,
    cellClass: "table-cell-number",
    headerComponentParams: {
      template: headerComponentWithIcon("productIdTooltip")
    }
  },
  {
    headerName: "Asset Class",
    field: "product.assetClass.parent.shortName",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 160,
  },
  {
    headerName: "Strategy Type",
    field: "product.assetClass.shortName",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 180,
  },
  {
    headerName: "Active/Passive",
    field: "product.activePassive",
    filterParams: {
      valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, activePassiveMapping),
      keyCreator: (params:any)=>simpleMappingFormatter(params, activePassiveMapping),
    },
    valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, activePassiveMapping),
    sortable: true,
    width: 140
  },
  {
    headerName: "AUM ($M)",
    field: "product.latestAum.aum",
    valueGetter: recentAUMValueGetter,
    tooltipField: "product.latestAum.date",
    tooltipComponent: "aumTooltip",
    valueFormatter: currencyFormatter,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    cellClassRules: {
      'est-aum': (params:CellClassParams) => { return params?.data?.product?.latestAum?.date != appDate.format(DATE_API_FORMAT) }
    },
    sortable: true,
    width: 150,
    headerComponentParams: {
      template: headerComponentWithIcon("productAumTooltip")
    }
  },
  {
    // used for exporting
    headerName: "As Of Date",
    field: "product.latestAum.date",
    hide: true,
  },
  {
    headerName: "Status",
    field: "product.inactive",
    filterParams: {
      valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, InactiveMapping),
      keyCreator: (params:any) => simpleMappingFormatter(params, InactiveMapping),
    },
    valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, InactiveMapping),
    sortable: true,
    width: 100
  }
]

export const managersGlidePathsListColumnDef = [
  {
    headerName: "Name",
    field: "name",
    // for select several
    // checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    cellRenderer: glidepathLinkRenderer,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    width: 350
  },
  {
    headerName: "Type",
    field: "type",
    valueFormatter: falsyFormatter,
    // sortable: true,
    width: 160,
  },
  {
    headerName: "Active/Passive",
    field: "active",
    valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, activePassiveMapping),
    filterParams: {
      valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, activePassiveMapping),
      keyCreator: (params:any) => simpleMappingFormatter(params, activePassiveMapping),
    },
    sortable: true,
    width: 180
  },
  {
    headerName: "AUM ($M)",
    field: "aum.aum",
    valueGetter: recentAUMValueGetter,
    tooltipField: "aum.date",
    tooltipComponent: "aumTooltip",
    valueFormatter: currencyFormatter,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    cellClassRules: {
      'est-aum': (params:CellClassParams) => { return params?.data?.aum?.date != appDate.format(DATE_API_FORMAT) }
    },
    sortable: true,
    width: 150,
    headerComponentParams: {
      template:
      `<div class="ag-cell-label-container" role="presentation">
          <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
          <div ref="eLabel" class="ag-header-cell-label" role="presentation">
            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
            <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
            <span ref="eSortAsc">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-down" class="svg-inline--fa fa-arrow-down fa-w-14 ml-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-reactroot=""><path fill="currentColor" d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"></path></svg>
            </span>
            <span ref="eSortDesc">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up" class="svg-inline--fa fa-arrow-up fa-w-14 ml-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-reactroot=""><path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"></path></svg>
            </span>
            <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            <span ref="eFilter">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="filter" class="svg-inline--fa fa-filter fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-reactroot=""><path fill="currentColor" d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"></path></svg>
            </span>
          </div>
          <div class="tooltip-icon" id="aumToolTip">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" class="svg-inline--fa fa-question-circle fa-w-16 fa-sm " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path></svg>
          </div>
        </div>`
    }
  },
  {
    // used for exporting
    headerName: "As Of Date",
    field: "aum.date",
    hide: true,
  },
  {
    headerName: "Status",
    field: "inactive",
    filterParams: {
      valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, InactiveMapping),
      keyCreator: (params:any) => simpleMappingFormatter(params, InactiveMapping),
    },
    valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, InactiveMapping),
    sortable: true,
    width: 100
  }
]

export const glidePathSeriesRelatedColumnDef = [
  {
    headerName: "Product Name",
    field: "name",
    // for select several
    // checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    cellRenderer: glidepathLinkRenderer,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    minWidth: 400,
    flex: 4
  },
  {
    headerName: "Type",
    field: "type",
    valueFormatter: falsyFormatter,
    // sortable: true,
    width: 160,
    flex: 1.6
  },
  {
    headerName: "Vehicle Type",
    field: "vehicleType",
    // valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, activePassiveMapping),
    // keyCreator: (params:any) => simpleMappingFormatter(params, activePassiveMapping),
    sortable: true,
    width: 250,
    flex: 2.4
  },
  {
    headerName: "AUM ($M)",
    field: "aum.aum",
    valueGetter: recentAUMValueGetter,
    tooltipField: "aum.date",
    tooltipComponent: "aumTooltip",
    valueFormatter: currencyFormatter,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    cellClassRules: {
      'est-aum': (params:CellClassParams) => { return (!!params?.data?.aum?.date && params?.data?.aum?.date != appDate.format(DATE_API_FORMAT)) }
    },
    sortable: true,
    minWidth: 120,
    flex: 1.2
  }
]

export const searchDocumentColumnDef = () => {
  return compact([{
    headerName: "",
    field: "url",
    // for select several
    // checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    cellRenderer: fileIconRenderer,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: false,
    width: 35,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Document Name",
    field: "description",
    // for select several
    // checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    sortable: true,
    width: 350,
  },
  {
    headerName: "Updated",
    field: "updated",
    valueGetter: currentVersionUpdatedGetter,
    valueFormatter: dateFormatter,
    filterParams: {
      valueFormatter: dateFormatter,
      comparator: dateFilterParams.comparator,
    },
    sortable: true,
    width: 100,
    getQuickFilterText:() => "",
  },
  // {
  //   headerName: "Type",
  //   field: "type.value",
  //   valueFormatter: falsyFormatter,
  //   sortable: true,
  //   width: 240,
  // },
  {
    headerName: "Sub-Type",
    field: "subType.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 240,
  }])
}

export const clientDocumentListColumnDef = (isCallan:boolean = false) => {
  return compact([{
    headerName: "",
    field: "url",
    // for select several
    // checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    cellRenderer: (params:ICellRendererParams) => downloadIconRenderer(params, isCallan),
    headerCheckboxSelectionFilteredOnly: true,
    suppressMenu: true,
    sortable: true,
    width: 40,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Name",
    field: "description",
    // for select several
    // checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    cellRenderer: (params: ICellRendererParams) => documentClientLinkRenderer(params, isCallan),
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    width: 350,
  },
  {
    headerName: "Associations",
    field: "associations",
    sortable: true,
    width: 300,
    cellClass: "table-cell-number",
  },
  {
    headerName: "Format",
    field: "__typename",
    valueGetter: (params: ValueGetterParams) => params.data.__typename === "File" ? "Document" : "Online Report",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 140,
  },
  {
    headerName: "Type",
    field: "type.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 180,
  },
  {
    headerName: "Sub-Type",
    field: "subType.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 240,
  },
  {
    headerName: "As Of",
    field: "asOfDate",
    valueFormatter: dateFormatter,
    filterParams: {
      valueFormatter: dateFormatter,
      comparator: falsyDateAtTopComparator,
    },
    sortable: true,
    comparator: falsyDateAtTopComparator,
    sort: "asc",
    width: 100,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Updated",
    field: "updated",
    valueGetter: currentVersionUpdatedGetter,
    valueFormatter: dateFormatter,
    filterParams: {
      valueFormatter: dateFormatter,
      comparator: dateFilterParams.comparator,
    },
    sortable: true,
    width: 100,
    getQuickFilterText:() => "",
  },
  isCallan ? {
    headerName: "Access",
    field: "access.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    // filter: 'agSetColumnFilter',
    width: 100
  } : {}]) as ColDef[]
}

export const FundManagerDocumentsListColumnDef = (isCallan:boolean = false) => {
  return compact([{
    headerName: "",
    field: "url",
    cellRenderer: (params:ICellRendererParams) => downloadIconRenderer(params, isCallan),
    // disable filtering & sorting for download.
    suppressMenu: true,
    sortable: true,
    width: 40,
    getQuickFilterText:() => "",
  },
  isCallan ? {
    headerName: "Name",
    field: "description",
    cellRenderer: fundManagerDocumentLinkRenderer,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    width: 350,
  } :
  {
    headerName: "Name",
    field: "description",
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    width: 350,
  },
  {
    headerName: "Managers",
    field: "managerNames",
    sortable: true,
    sort: "asc",
    sortIndex: 2,
    width: 300,
    cellClass: "table-cell-number",
  },
  {
    headerName: "Products",
    field: "productNames",
    sortable: true,
    width: 400,
    cellClass: "table-cell-number",
  },
  {
    headerName: "Type",
    field: "type.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 240,
  },
  {
    headerName: "Sub-Type",
    field: "subType.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    sort: "asc",
    sortedAt: 0,
    filterParams: {
      comparator: DocumentSubTypeComparator,
    },
    comparator: DocumentSubTypeComparator,
    width: 200,
  },
  {
    headerName: "As Of",
    field: "asOfDate",
    valueFormatter: dateFormatter,
    filterParams: {
      valueFormatter: dateFormatter,
      comparator: dateFilterParams.comparator,
    },
    sortable: true,
    sort: "desc",
    sortedAt: 1,
    width: 150,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Access",
    field: "access.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    hide: !isCallan,
    suppressColumnsToolPanel: !isCallan,
    width: 200
  }])
}

export const ClientPortfolioListColumnDef = () => [
  {
    headerName: "Portfolio Name",
    field: "name",
    // for select several
    // checkboxSelection: true,
    // for select all
    // headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    width: 320,
  },
  {
    headerName: "Product",
    field: "relatedVehicle.vehicle.product.product.name",
    sortable: true,
    width: 340,
    cellRenderer: portfolioProductLinkRenderer,
  },
  {
    headerName: "Manager",
    field: "relatedVehicle.vehicle.product.product.manager.name",
    sortable: true,
    width: 250,
    cellRenderer: portfolioManagerLinkRenderer,
  },
  {
    headerName: "Vehicle",
    field: "relatedVehicle.vehicle.name",
    sortable: true,
    width: 320,
    cellRenderer: portfolioVehicleLinkRenderer,
  },
  {
    headerName: "Vehicle Category",
    field: "relatedVehicle.vehicle.category.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 200,
  },
  {
    headerName: "Asset Class",
    field: "relatedVehicle.vehicle.product.product.assetClass.parent.shortName",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 200,
  },
  {
    headerName: "Strategy Type",
    field: "relatedVehicle.vehicle.product.product.assetClass.shortName",
    valueFormatter: falsyFormatter,
    sortable: true,
    width: 220,
  },
  {
    headerName: "Active/Passive",
    field: "relatedVehicle.vehicle.product.product.activePassive",
    filterParams: {
      valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, activePassiveMapping),
      keyCreator: (params:any)=>simpleMappingFormatter(params, activePassiveMapping),
    },
    valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, activePassiveMapping),
    sortable: true,
    width: 140
  },
]

export const ClientsListColumnDef = () => [
  {
    headerName: "Client Name",
    field: "client.name",
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sort: 'asc',
    sortable: true,
    flex: 5,
    minWidth: 300,
    cellRenderer: clientsLinkRenderer,
  },
  {
    headerName: "Client ID",
    field: "client.id",
    sortable: true,
    minWidth: 100,
    flex: 1,
    cellClass: "table-cell-number"
  },
  {
    headerName: "Plan Name",
    field: "name",
    sortable: true,
    minWidth: 150,
    flex: 3,
    valueGetter: function(params: ValueGetterParams) {
      return params.data?.shortName ||
      params.data?.name
    },
    cellRenderer: plansLinkRenderer,
  },
  {
    headerName: "Plan ID",
    field: "id",
    sortable: true,
    flex: 1,
    minWidth: 90,
  },
  {
    headerName: "Plan Assets ($M)",
    field: "assets",
    sortable: true,
    flex: 1,
    minWidth: 160,
    type: "rightAligned",
    tooltipValueGetter: (params: ValueGetterParams) => getLatestPlanAsset(params).date,
    tooltipComponent: "aumTooltip",
    valueGetter: planAssetsValueGetter,
    valueFormatter: currencyFormatter,
    cellClassRules: {
      'est-aum': (params:CellClassParams) => getLatestPlanAsset(params).date != appDate.format(DATE_API_FORMAT)
    },
  },
  {
    // used for exporting
    headerName: "As Of Date",
    field: "assets",
    valueGetter: (params: ValueGetterParams) => getLatestPlanAsset(params).date,
    hide: true,

  },
  {
    headerName: "City",
    field: "client.city",
    valueGetter: function(params: ValueGetterParams) {
      return params.data?.client?.address
        ? params.data.client.address?.city
        : null
    },
    valueFormatter: falsyFormatter,
    sortable: true,
    flex: 2,
  },
  {
    headerName: "State",
    field: "state",
    valueGetter: function(params: ValueGetterParams) {
      return params.data?.client?.address
        ? params.data.client.address?.state
        : null
    },
    valueFormatter: falsyFormatter,
    sortable: true,
    flex: 1,
    minWidth: 90,
  },
  {
    headerName: "Industry",
    field: "client.industry.value",
    sortable: true,
    minWidth: 100,
    flex: 1,
  },
  {
    headerName: "Type",
    field: "sponsorType.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    minWidth: 75,
    flex: 1,
  },
  {
    headerName: "Plan Type",
    field: "fundType.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    minWidth: 130,
    flex: 1,
  },
  {
    headerName: "Plan SubType",
    field: "fundSubtype.value",
    valueFormatter: falsyFormatter,
    sortable: true,
    minWidth: 135,
    flex: 1,
  },
  {
    headerName: "Consultant",
    field: "consultant",
    valueGetter: function(params: ValueGetterParams) {
      let consultant = params.data?.consultant
      return consultant?.firstName && consultant?.lastName
        ? consultant?.firstName + " " + consultant?.lastName
        : null
    },
    valueFormatter: falsyFormatter,
    sortable: true,
    minWidth: 130,
    flex: 1,
  },
  {
    headerName: "Status",
    field: "accountType.code",
    valueGetter: (params: ValueGetterParams ) => mappingValueGetter(params, "accountType.code", CustomerMapping),
    sortable: true,
    minWidth: 90,
    flex: 1,
  },
]

export const ListListColumnDef = ( onFavoriteClicked:(row:ListSimpleFragment) => void, currentUser?: MeFragment, isCallan:boolean = false) => {
  return compact([isCallan ? {
    headerName: "List Name",
    field: "name",
    // for select several
    checkboxSelection: true,
    // for select all
    headerCheckboxSelection: true,
    cellRenderer: ListLinkRenderer,
    sortable: true,
    width: 500,
  } : {
    headerName: "List Name",
    field: "name",
    // for select several
    checkboxSelection: true,
    // for select all
    headerCheckboxSelection: true,
    sortable: true,
    width: 500,
  },
  {
    headerName: "List id",
    field: "id",
    sortable: true,
    width: 100,
  },
  {
    headerName: "Modified",
    field: "lastSavedDate",
    sortable: true,
    width: 120,
    valueGetter: (params: ValueGetterParams) => {
      let dateTime = get(params.data, params?.colDef?.field || "")
      let date = moment(dateTime, DATE_TIME_API_FORMAT).format(DATE_API_FORMAT)
      return dateTime? date : ""
    },
    valueFormatter: (params: ValueFormatterParams) => {
      let date = params.value || ""
      return date? moment(date).format(DATE_TEXT_FORMAT) : falsyFormatter(params)
    },
    filterParams: {
      comparator: dateFilterParams.comparator,
      valueFormatter: (params: ValueFormatterParams) => {
        let date = params.value || ""
        return date? moment(date).format(DATE_TEXT_FORMAT) : falsyFormatter(params)
      },
    },
    getQuickFilterText:() => "",
  },
  {
    headerName: "Owner",
    field: "owner",
    sortable: true,
    valueGetter: function(params: ValueGetterParams) {
      if(params.data?.owner?.id === currentUser?.id){
        return "Me"
      }
      return params.data && params.data.owner ? `${params.data.owner.firstName} ${params.data.owner.lastName}` : ""
    },
    width: 200,
  },
  {
    headerName: "Category",
    field: "category.value",
    sortable: true,
    width: 200,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Subcategory",
    field: "subCategory.value",
    sortable: true,
    width: 200,
    getQuickFilterText:() => "",
  },
  {
    headerName: "List Type",
    field: "type",
    sortable: true,
    width: 100,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Status",
    field: "active",
    filterParams: {
      keyCreator: (params:any) => simpleMappingFormatter(params, ActiveMapping),
      valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, ActiveMapping),
    },
    valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, ActiveMapping),
    sortable: true,
    width: 100,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Favorite",
    field: "isCurrentUsersFavorite",
    cellRenderer: favoriteIconRenderer,
    sortable: true,
    width: 100,
    getQuickFilterText:() => "",
    onCellClicked: (event:any) => onFavoriteClicked(event.data as ListSimpleFragment)
  }])
}

export const ListDetailColumnDef = (editMode: boolean) => {
  return compact([{
    headerName: "Order",
    field: "order",
    sortable: true,
    width: 100,
    comparator: (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) => {
      if(valueA === valueB) return 0
      if(valueA === "" || valueB === "Exclude") return -1
      if(valueB === "" || valueA === "Exclude") return 1
      const splitA = valueA?.split('.')
      const splitB = valueB?.split('.')
      let returnedComparator = 0
      if(splitA && splitB){
        splitA.some((orderA, idx) => {
          const orderB = splitB[idx]
          if(orderA !== orderB){
            returnedComparator = (parseInt(orderA) || 0) > (parseInt(orderB) || 0) ? 1 : -1
            return true
          }
          return false
        })
      }
      return returnedComparator
    },
    rowDrag: editMode,
  },
  {
    headerName: "Member",
    field: "name",
    sortable: true,
    width: 500,
  },
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    width: 120,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Member Type",
    field: "memberType",
    sortable: true,
    width: 200,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Source",
    field: "source",
    sortable: true,
    width: 200,
    getQuickFilterText:() => "",
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.source ? params.data.source : "Current List"
    },
    cellClassRules: {
      'font-italic': (params:CellClassParams) => { return !params?.data?.source }
    }
  }])
}

export const PlanClientPortfolioListColumnDef = () => [
  {
    headerName: "Portfolio ID",
    field: "id",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: true,
    flex: 1,
  },
  {
    headerName: "Name",
    field: "name",
    sortable: true,
    flex: 2,
    cellRenderer: portfolioLinkRenderer,
  },
  {
    headerName: "Vehicle ID",
    field: "relatedVehicle.vehicle.id",
    valueFormatter: falsyFormatter,
    filterValueGetter: function(params: ValueGetterParams) {
      return params.data?.relatedVehicle?.vehicle?.id || "-"
    },
    sortable: true,
    comparator: falsyAtBottomComparator,
    flex: 1,
  },
  {
    headerName: "Type",
    field: "dataType.value",
    sortable: true,
    flex: 1,
  },
  {
    headerName: "Primary Benchmark",
    field: "performanceTargetMap",
    sortable: true,
    flex: 2,
    valueGetter: function(params: ValueGetterParams) {
      let targets = sortBy(params?.data?.performanceTargetMap || [], 'order')
      return get(targets, "[0]target.name")
    },
    filterValueGetter: function(params: ValueGetterParams) {
      let targets = sortBy(params?.data?.performanceTargetMap || [], 'order')
      return get(targets, "[0]target.name") || "-"
    },
    comparator: falsyAtBottomComparator,
    valueFormatter: falsyFormatter,
  },
  {
    headerName: "Active",
    field: "isActive",
    sortable: true,
    valueGetter: (params: ValueGetterParams ) => mappingValueGetter(params, "isActive", ActiveMapping),
    flex: 1,
    minWidth: 50,
    cellRenderer: checkOrCrossRenderer,
  },
  {
    headerName: "Bank Account",
    field: "bankAccount",
    sortable: true,
    flex: 1,
    minWidth: 50,
  },
  {
    headerName: "Test",
    field: "isTest",
    sortable: true,
    valueGetter: (params: ValueGetterParams ) => mappingValueGetter(params, "isTest", TestMapping),
    flex: 1,
    minWidth: 50,
    cellRenderer: checkOrCrossRenderer,
  },
  {
    headerName: "List Order",
    field: "lists",
    sortable: true,
    sort: 'asc',
    flex: 2,
    valueGetter: function(params: ValueGetterParams) {
      let lists = params?.data?.lists || []
      let filteredLists =  lists.filter((list: any )=> {
        return list?.list?.subCategory?.code === ListSubCategoryCode.MASTER
      })
      let result = null
      filteredLists.find((el: any )=> {
        if (_.isNumber(el?.order) && _.isNumber(el?.group)) {
          result = `${el.group}.${el.order}`
          return el
        }
      })
      return result
    },
    filterValueGetter: function(params: ValueGetterParams) {
      let lists = params?.data?.lists || []
      let filteredLists =  lists.filter((list: any )=> {
        return list?.list?.subCategory?.code === ListSubCategoryCode.MASTER
      })
      let result = "-"
      filteredLists.find((el: any )=> {
        if (_.isNumber(el?.order) && _.isNumber(el?.group)) {
          result = `${el.group}.${el.order}`
          return el
        }
      })
      return result
    },
    valueFormatter: falsyFormatter,
    comparator: listOrderFalsyAtBottomComparator,
    filterParams: {
      valueFormatter: falsyFormatter,
    },
  },
]

export const StoplightColumnDef = ( settings: DashboardStoplightFragment, showLastUpdated: boolean) => {
  let columnDefs:ColDef[] = [{
    headerName: "Product",
    field: "product.product.name",
    sortable: true,
    width: 340,
    cellRenderer: stoplightProductLinkRenderer,
  }]

  if(settings.showVehicle){
    columnDefs.push({
      headerName: "Vehicle",
      field: "vehicle.vehicleName",
      sortable: true,
      width: 320,
      cellRenderer: stoplightVehicleLinkRenderer,
    })
  }

  if(settings.showManagerOverall){
    columnDefs.push({
      headerName: "Manager Overall",
      field: "managerOverall",
      cellRenderer: showLastUpdated ? statusIconRendererWithDate : statusIconRenderer,
      valueGetter: showLastUpdated ? statusValueGetterWithDate : statusValueGetter,
      sortable: true,
      width: showLastUpdated ? 180 : 120,
      getQuickFilterText:() => ""
    })
  }

  if(settings.showProductPeople){
    columnDefs.push({
      headerName: "Product People",
      field: "productPeople",
      cellRenderer: showLastUpdated ? statusIconRendererWithDate : statusIconRenderer,
      valueGetter: showLastUpdated ? statusValueGetterWithDate : statusValueGetter,
      sortable: true,
      width: showLastUpdated ? 180 : 120,
      getQuickFilterText:() => ""
    })
  }

  if(settings.showPhilosophyProcess){
    columnDefs.push({
      headerName: "Philosophy",
      field: "philosophy",
      cellRenderer: showLastUpdated ? statusIconRendererWithDate : statusIconRenderer,
      valueGetter: showLastUpdated ? statusValueGetterWithDate : statusValueGetter,
      sortable: true,
      width: showLastUpdated ? 180 : 120,
      getQuickFilterText:() => ""
    })
  }

  if(settings.showProductDynamics){
    columnDefs.push({
      headerName: "Product Dynamics",
      field: "dynamics",
      cellRenderer: showLastUpdated ? statusIconRendererWithDate : statusIconRenderer,
      valueGetter: showLastUpdated ? statusValueGetterWithDate : statusValueGetter,
      sortable: true,
      width: showLastUpdated ? 180 : 120,
      getQuickFilterText:() => ""
    })
  }

  if(settings.showShortTermPerformance){
    columnDefs.push({
      headerName: "Short Term Performance",
      field: "shortTerm",
      cellRenderer: showLastUpdated ? statusIconRendererWithDate : statusIconRenderer,
      valueGetter: showLastUpdated ? statusValueGetterWithDate : statusValueGetter,
      sortable: true,
      width: showLastUpdated ? 180 : 120,
      getQuickFilterText:() => ""
    })
  }

  if(settings.showLongTermPerformance){
    columnDefs.push({
      headerName: "Long Term Performance",
      field: "longTerm",
      cellRenderer: showLastUpdated ? statusIconRendererWithDate : statusIconRenderer,
      valueGetter: showLastUpdated ? statusValueGetterWithDate : statusValueGetter,
      sortable: true,
      width: showLastUpdated ? 180 : 120,
      getQuickFilterText:() => ""
    })
  }

  if(settings.showProductOverall){
    columnDefs.push({
      headerName: "Product Overall",
      field: "productOverall",
      cellRenderer: (params: ICellRendererParams) => showLastUpdated ? statusIconRendererWithDateAndMonitor(params, {showChange: true, period: 90}) : statusIconRendererWithMonitor(params, {showChange: true, period: 90}),
      valueGetter: showLastUpdated ? statusValueGetterWithDate : statusValueGetter,
      sortable: true,
      width: showLastUpdated ? 180 : 120,
      getQuickFilterText:() => ""
    })
  }
  columnDefs.push({
    headerName: "Last Interaction Date",
    field: "product.product.lastActivity",
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value, DATE_API_FORMAT).format("YYYY/MM/DD") : "",
    filterParams: {
      valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value, DATE_API_FORMAT).format("YYYY/MM/DD") : "",
      // comparator: dateFilterParams.comparator,
    },
    sortable: true,
    width: 120,
    getQuickFilterText:() => ""
  })

  return columnDefs
}

export const ReportListColumnDef = ( onFavoriteClicked:(row:ReportSimpleFragment) => void, currentUser?: MeFragment, isCallan:boolean = false) => {
  return compact([isCallan ? {
    headerName: "Report",
    field: "name",
    cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 300,
  } : {
    headerName: "Report",
    field: "name",
    sortable: true,
    width: 300,
  },
  // {
  //   headerName: "Type",
  //   field: "type.value",
  //   sortable: true,
  //   width: 200,
  //   getQuickFilterText:() => "",
  // },
  {
    headerName: "Category",
    field: "category.value",
    sortable: true,
    width: 200,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Subcategory",
    field: "subCategory.value",
    sortable: true,
    width: 200,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Client",
    field: "client.name",
    sortable: true,
    width: 340,
    cellRenderer: reportClientLinkRenderer,
  },
  {
    headerName: "Plan",
    field: "plans",
    sortable: true,
    width: 340,
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.plans ? params.data.plans.map((plan:any) => plan.name) : ""
    },
    cellRenderer: ReportPlanRenderer,
  },
  // {
  //   headerName: "Modified",
  //   field: "lastSavedDate",
  //   sortable: true,
  //   width: 120,
  //   valueGetter: function(params: ValueGetterParams) {
  //     let liveDate = params.data.liveList?.lastSavedDate
  //     let draftDate = params.data.draftList?.lastSavedDate
  //     if(liveDate && draftDate){
  //       return moment(liveDate, DATE_TIME_API_FORMAT).isAfter(moment(draftDate, DATE_TIME_API_FORMAT)) ? liveDate : draftDate
  //     }
  //     if(liveDate){
  //       return liveDate
  //     }
  //     return draftDate
  //   },
  //   valueFormatter: (params:ValueFormatterParams)=> !params.value ? "" : moment(params.value, DATE_TIME_API_FORMAT).format(DATE_TEXT_FORMAT),
  //   getQuickFilterText:() => "",
  //   filter: 'agDateColumnFilter',
  //   filterParams: {
  //     comparator: (filterLocalDateAtMidnight:Date, cellValue:string) => {
  //       const dateParts = moment(cellValue, DATE_TIME_API_FORMAT)
  //       const day = dateParts.date()
  //       const month = dateParts.month()
  //       const year = dateParts.year()
  //       const cellDate = new Date(year, month, day)
  //       if (cellDate < filterLocalDateAtMidnight) {
  //         return -1
  //       } else if (cellDate > filterLocalDateAtMidnight) {
  //         return 1
  //       } else {
  //         return 0
  //       }
  //     },
  //   },
  // },
  {
    headerName: "Owner",
    field: "owner",
    sortable: true,
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.owner ? `${params.data.owner.firstName} ${params.data.owner.lastName}` : ""
    },
    width: 200,
  },
  // {
  //   headerName: "Status",
  //   field: "active",
  //   valueFormatter: (params:ValueFormatterParams)=>simpleMappingFormatter(params, ActiveMapping),
  //   keyCreator: (params:any) => simpleMappingFormatter(params, ActiveMapping),
  //   sortable: true,
  //   width: 100,
  //   getQuickFilterText:() => "",
  // },
  // {
  //   headerName: "Favorite",
  //   field: "isCurrentUsersFavorite",
  //   cellRenderer: favoriteIconRenderer,
  //   sortable: true,
  //   width: 100,
  //   getQuickFilterText:() => "",
  //   onCellClicked: (event:any) => onFavoriteClicked(event.data as ReportSimpleFragment)
  // }
  ])
}

export const ImportReportListColumnDef = () => {
  return compact([{
    headerName: "Name",
    field: "name",
    sortable: true,
    width: 200,
  },
  {
    headerName: "Client",
    field: "client.name",
    sortable: true,
    width: 240,
  },
  // {
  //   headerName: "Category",
  //   field: "category.value",
  //   width: 200,
  //   getQuickFilterText:() => "",
  // },
  {
    headerName: "Subcategory",
    field: "subCategory.value",
    sortable: true,
    width: 150,
    getQuickFilterText:() => "",
  },
  {
    headerName: "Owner",
    field: "owner",
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.owner ? `${params.data.owner.firstName} ${params.data.owner.lastName}` : ""
    },
    sortable: true,
    width: 150,
  },
  ])
}

export const PlanListColumnDef = () => {
  return compact([{
      headerName: "Plan Name",
      field: "name",
      sort: 'asc',
      sortable: true,
      width: 300,
      cellRenderer: planLinkRenderer,
    },
    {
      headerName: "Plan ID",
      field: "id",
      sortable: true,
      width: 150,
      getQuickFilterText: () => "",
    },
    {
      headerName: "Client Name",
      field: "client.name",
      sortable: true,
      width: 340,
      cellRenderer: reportClientLinkRenderer,
    },
    {
      headerName: "Client ID",
      field: "client.id",
      sortable: true,
      width: 150,
    },
    {
      headerName: "Plan Type",
      field: "fundType.value",
      sortable: true,
      width: 200,
      getQuickFilterText: () => "",
    },
    {
      headerName: "Plan SubType",
      field: "fundSubtype.value",
      sortable: true,
      width: 200,
      getQuickFilterText: () => "",
    },
    {
      headerName: "Aggregate",
      field: "isComposite",
      sortable: true,
      width: 130,
      cellRenderer: tickIconRenderer,
      cellStyle: {'justify-content': "center", display: 'flex'}
    },
    {
      headerName: "Consultant",
      field: "consultant",
      sortable: true,
      valueGetter: function (params: ValueGetterParams) {
        return params.data && params.data.consultant
          ? `${params.data.consultant.firstName} ${params.data.consultant.lastName}`
          : ""
      },
      width: 200,
      getQuickFilterText:() => "",
    },
    {
      headerName: "Status",
      field: "accountType.code",
      valueGetter: (params: ValueGetterParams) =>
        mappingValueGetter(params, "accountType.code", CustomerMapping),
      sortable: true,
      width: 100,
      getQuickFilterText:() => "",
    },
  ])
}

export const PlanModalColumnDef = () => {
  return compact([{
      headerName: "Plans",
      field: "name",
      sort: 'asc',
      sortable: true,
      width: 300,
    },
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width: 150,
    },
    {
      headerName: "Client Name",
      field: "client.name",
      sortable: true,
      width: 340,
      hide: true
    },
    {
      headerName: "Client ID",
      field: "client.id",
      sortable: true,
      width: 150,
      hide: true
    },
    {
      headerName: "Plan Type",
      field: "fundType.value",
      sortable: true,
      width: 200,
      getQuickFilterText: () => "",
    },
    {
      headerName: "Plan SubType",
      field: "fundSubtype.value",
      sortable: true,
      width: 200,
      getQuickFilterText: () => "",
      hide: true
    },
    {
      headerName: "Aggregate",
      field: "isComposite",
      sortable: true,
      width: 130,
      cellRenderer: tickIconRenderer,
      cellStyle: {'justify-content': "center", display: 'flex'},
      hide: true
    },
    {
      headerName: "Consultant",
      field: "consultant",
      sortable: true,
      valueGetter: function (params: ValueGetterParams) {
        return params.data && params.data.consultant
          ? `${params.data.consultant.firstName} ${params.data.consultant.lastName}`
          : ""
      },
      width: 200,
      getQuickFilterText:() => "",
      hide: true
    },
    {
      headerName: "Status",
      field: "accountType.code",
      valueGetter: (params: ValueGetterParams) =>
        mappingValueGetter(params, "accountType.code", CustomerMapping),
      sortable: true,
      width: 100,
      getQuickFilterText:() => "",
      hide: true
    },
  ])
}

export const ReportScheduleColumnDef = () => {
  return compact([{
    headerName: "Plan",
    field: "plans",
    sortable: true,
    width: 280,
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.plans ? params.data.plans.map((plan:any) => plan.name) : ""
    },
    cellRenderer: reportPlanLinkRenderer,
  },
  {
    headerName: "Report Name",
    field: "name",
    cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 280,
  },
  {
    headerName: "Client",
    field: "client.name",
    sortable: true,
    width: 280,
    cellRenderer: reportClientLinkRenderer,
  },
  {
    headerName: "Due Date",
    field: "dueDates",
    sort: 'asc',
    sortable: true,
    valueGetter: function(params: ValueGetterParams) {
      return (params.data.dueDates?.find((dueDate:any) => dueDate.quarterDate === appDate.format(DATE_API_FORMAT)) || {quarterDate: appDate.format(DATE_API_FORMAT), dueDate: null}).dueDate
    },
    width: 170,
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_DISPLAY_FORMAT) : "",
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    }
  },
  {
    headerName: "Previous Due Date",
    field: "dueDates",
    sortable: true,
    valueGetter: function(params: ValueGetterParams) {
      return (params.data.dueDates?.find((dueDate:any) => dueDate.quarterDate === moment(appDate).subtract(1, "Q").format(DATE_API_FORMAT)) || {quarterDate: moment(appDate).subtract(1, "Q").format(DATE_API_FORMAT), dueDate: null}).dueDate
    },
    width: 170,
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_DISPLAY_FORMAT) : "",
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    }
  },
  {
    headerName: "Last Published Date",
    field: "lastPublished",
    sortable: true,
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_DISPLAY_FORMAT) : "",
    width: 170,
  },
  {
    headerName: "Monthly",
    field: "frequency.value",
    sortable: true,
    width: 130,
    valueGetter: (params: ValueGetterParams) => { return params.data.frequency.code === TrainingFrequencyCode.MONTH ? true : false},
    cellRenderer: tickIconRenderer,
    cellStyle: {'justify-content': "center", display: 'flex'}
  },
  {
    headerName: "Consultant",
    field: "plans.consultant",
    sortable: true,
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.plans ? params.data.plans.map((plan:any) => `${plan.consultant.firstName} ${plan.consultant.lastName}`) : ""
    },
    width: 170,
  },
  {
    headerName: "Analyst",
    field: "owner",
    sortable: true,
    valueGetter: function(params: ValueGetterParams) {
      return params.data && params.data.owner ? `${params.data.owner.firstName} ${params.data.owner.lastName}` : ""
    },
    width: 170,
  },
  ])
}

export const PlanPerformanceReturnsColumnDef = (setCurrentColumnData: (value:React.SetStateAction<ReturnColumnDataType[]>) => void ,period: CalendarPickerPeriod, editMode: boolean) => {
  let asOfHeader = "As-of"
  if(period === "month") asOfHeader = "As-of (Month)"
  if(period === "quarter") asOfHeader = "As-of (Quarter)"
  const valueSetter = (rowId:string, field: string, value: any) => {
    let changed = false
    setCurrentColumnData((prev) => {
      let updatedRows = cloneDeep(prev)
      const index = updatedRows.findIndex((item) => item.rowId === rowId)
      if(index !== -1 && field){
        const oldRow = cloneDeep(updatedRows[index])
        const newRow = {
          ...updatedRows[index],
          [field]: value,
        }
        if(JSON.stringify(oldRow) !== JSON.stringify(newRow)){
          changed = true
          return [
            ...updatedRows.slice(0, index),
            newRow,
            ...updatedRows.slice(index + 1),
          ]
        }
      }
      return prev
    })
    return changed
  }
  const typeOrder = ["Gross", "Net", "Gross Income", "Net Income", "Appreciation", "Buy and Hold"]
  const typeSecondOrder = ["Gross", "Gross Income", "Net", "Net Income", "Appreciation", "Buy and Hold"]
  return compact([{
    headerName: asOfHeader,
    field: "date",
    sortable: true,
    width: 200,
    // valueGetter: function(params: ValueGetterParams) {
    //   return params.data && params.data.plans ? params.data.plans.map((plan:any) => plan.name) : ""
    // },
    // cellRenderer: reportPlanLinkRenderer,
    checkboxSelection: true,
    // for select all
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_DISPLAY_FORMAT) : "",
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    },
    editable: (params: EditableCallbackParams) => params.data.editable,
    cellRendererParams: {values: { editMode }},
    cellRenderer: calendarCellRenderer,
    cellClass: (params: CellClassParams) => {
      return editMode && !params.data.editable ? 'ag-cell-edit-disabled' : '';
    },
    cellEditor: "formDatePickerComponent",
    cellEditorPopup: true,
    cellEditorParams: {
      period: period,
    },
    valueSetter: (params: ValueSetterParams) => {
      let updatedPreviousId = null
      const previousDate = moment(params.newValue).subtract(1, period === "month" ? "month" : "quarter").format(DATE_API_FORMAT)
      params.api.forEachNode((node:IRowNode) => {
        if(node.data.date === previousDate && node.data.type.code === params.data.type.code){
          updatedPreviousId = node.data.rowId
        }
      })
      valueSetter(params.data.rowId, "previousId", updatedPreviousId)
      return valueSetter(params.data.rowId, "date", params.newValue)
    },
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var cellMoment = moment(dateAsString, DATE_API_FORMAT);
        var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
        return cellMoment.diff(filterLocalDateAtMidnightMoment)
      },
    },
  },
  {
    headerName: "Type",
    field: "type.value",
    // cellRenderer: ReportLinkRenderer,
    sortable: true,
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      let indexA = typeOrder.indexOf(valueA)
      let indexB = typeOrder.indexOf(valueB)
      if(isInverted){
        indexA = typeSecondOrder.indexOf(valueB)
        indexB = typeSecondOrder.indexOf(valueA)
      }
      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      }
      return 0;
    },
    width: 110,
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      cellHeight: 25,
      values: typeOrder,
    },
    cellEditorPopup: true,
    valueSetter: (params: ValueSetterParams) => {
      let value
      if(params.newValue === "Gross"){
        value = {
          code: ClientPortfolioReturnTypeCode.ALL,
          value: "Gross",
          __typename: "ClientPortfolioReturnTypeLookup" as "ClientPortfolioReturnTypeLookup",
        }
      } else if (params.newValue === "Net"){
        value = {
          code: ClientPortfolioReturnTypeCode.ALLN,
          value: "Net",
          __typename: "ClientPortfolioReturnTypeLookup" as "ClientPortfolioReturnTypeLookup",
        }
      } else if (params.newValue === "Appreciation"){
        value = {
          code: ClientPortfolioReturnTypeCode.APPR,
          value: "Appreciation",
          __typename: "ClientPortfolioReturnTypeLookup" as "ClientPortfolioReturnTypeLookup",
        }
      } else if (params.newValue === "Buy and Hold"){
        value = {
          code: ClientPortfolioReturnTypeCode.BNH,
          value: "Buy and Hold",
          __typename: "ClientPortfolioReturnTypeLookup" as "ClientPortfolioReturnTypeLookup",
        }
      } else if (params.newValue === "Gross Income"){
        value = {
          code: ClientPortfolioReturnTypeCode.GINC,
          value: "Gross Income",
          __typename: "ClientPortfolioReturnTypeLookup" as "ClientPortfolioReturnTypeLookup",
        }
      } else if (params.newValue === "Net Income"){
        value = {
          code: ClientPortfolioReturnTypeCode.NINC,
          value: "Net Income",
          __typename: "ClientPortfolioReturnTypeLookup" as "ClientPortfolioReturnTypeLookup",
        }
      }
      let updatedPreviousId = null
      const previousDate = moment(params.data.date).subtract(1, period === "month" ? "month" : "quarter").format(DATE_API_FORMAT)
      params.api.forEachNode((node:IRowNode) => {
        if(node.data.date === previousDate && node.data.type.code === params.data.type.code){
          updatedPreviousId = node.data.rowId
        }
      })
      valueSetter(params.data.rowId, "previousId", updatedPreviousId)
      return valueSetter(params.data.rowId, "type", value)
    },
    editable: (params: EditableCallbackParams) => params.data.editable,
    cellClass: (params: CellClassParams) => {
      return editMode && !params.data.editable ? 'ag-cell-edit-disabled' : '';
    },
  },
  {
    headerName: "Return(%)",
    field: "returnPercent",
    sortable: true,
    width: 120,
    valueFormatter: (params: ValueFormatterParams) => percentFormatter(params, editMode ? 4 : 2),
    editable: (params: EditableCallbackParams) => params.data.editable,
    valueGetter: (params: ValueGetterParams) => {
      if(params.data.returnPercent === 0 ) return "0"
      return params.data.returnPercent ? params.data.returnPercent * 100 : null
    },
    valueSetter: (params: ValueSetterParams) => {
      return valueSetter(params.data.rowId, "returnPercent", params.newValue/100)
    },
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode && !params.data.editable, "text-danger": params.value < 0})
    },
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "number",
      formSubtype: "percent",
    },
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "Return Factor Form",
    field: "returnFactorForm",
    hide: true,
    valueGetter: function(params: ValueGetterParams) {
      return 1 + (params.data.returnPercent || 0)
    },
  },
  {
    headerName: "Assets",
    field: "assets",
    sortable: true,
    width: 170,
    valueFormatter: currencyFormatter,
    editable: (params: EditableCallbackParams) => params.data.editable,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetter(params.data.rowId, "assets", params.newValue)
    },
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode && !params.data.editable, "text-danger": params.value < 0})
    },
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "number",
      formSubtype: "currency",
    },
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "Income",
    field: "income",
    // cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 150,
    valueFormatter: currencyFormatter,
    editable: (params: EditableCallbackParams) => params.data.editable,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetter(params.data.rowId, "income", params.newValue)
    },
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode && !params.data.editable, "text-danger": params.value < 0})
    },
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "number",
      formSubtype: "currency",
    },
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "Purchases",
    field: "purchases",
    // cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 150,
    valueFormatter: currencyFormatter,
    editable: (params: EditableCallbackParams) => params.data.editable,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetter(params.data.rowId, "purchases", params.newValue)
    },
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode && !params.data.editable, "text-danger": params.value < 0})
    },
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "number",
      formSubtype: "currency",
    },
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "TfPos",
    field: "timingFactorPurchases",
    hide: true,
  },
  {
    headerName: "Sales",
    field: "sales",
    // cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 150,
    valueFormatter: currencyFormatter,
    editable: (params: EditableCallbackParams) => params.data.editable,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetter(params.data.rowId, "sales", params.newValue)
    },
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode && !params.data.editable, "text-danger": params.value < 0})
    },
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "number",
      formSubtype: "currency",
    },
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "TfNeg",
    field: "timingFactorSales",
    hide: true,
  },
  {
    headerName: "Fees",
    field: "fee",
    // cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 150,
    valueFormatter: currencyFormatter,
    editable: (params: EditableCallbackParams) => params.data.editable,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetter(params.data.rowId, "fee", params.newValue)
    },
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode && !params.data.editable, "text-danger": params.value < 0})
    },
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "number",
      formSubtype: "currency",
    },
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "Net Cash",
    field: "netFlow",
    // cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 150,
    editable: false,
    valueFormatter: currencyFormatter,
    valueGetter: function(params: ValueGetterParams) {
      const purchases = params.data?.purchases || 0
      const income = params.data?.income || 0
      const sales = params.data?.sales || 0
      return purchases - income - sales
    },
    filter: "agNumberColumnFilter",
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode && !params.data.editable, "text-danger": params.value < 0})
    },
  },
  {
    headerName: "Gain/Loss",
    field: "",
    // cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 150,
    editable: false,
    valueFormatter: currencyFormatter,
    valueGetter: function(params: ValueGetterParams) {
      const assets = params.data?.assets || 0
      const purchases = params.data?.purchases || 0
      const income = params.data?.income || 0
      const sales = params.data?.sales || 0

      let priorAssets = 0
      const priorData = params.api?.getRowNode(params.data.previousId)
      if(!!priorData){
        priorAssets = priorData.data?.assets || 0
      } else if(params.data.otherAssets) {
        priorAssets = params.data.otherAssets
      }
      const value = assets - priorAssets - (purchases - income - sales)
      return value
    },
    filter: "agNumberColumnFilter",
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode && !params.data.editable, "text-danger": params.value < 0})
    },
  },
  {
    headerName: "Updated",
    field: "updated",
    cellRenderer: sourceTooltipCellRenderer,
    sortable: true,
    width: 160,
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_TIME_DISPLAY_FORMAT) : "",
    editable: false,
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    },
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var cellMoment = moment(dateAsString, DATE_API_FORMAT);
        var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
        return cellMoment.diff(filterLocalDateAtMidnightMoment)
      },
    },
    cellClass: (params: CellClassParams) => {
      return editMode ? 'ag-cell-edit-disabled' : '';
    },
  },
  {
    headerName: "Wealths",
    field: "wealthsAvailable",
    sortable: true,
    valueGetter: (params: ValueGetterParams ) => mappingValueGetter(params, "wealthsAvailable", ActiveMapping),
    flex: 1,
    minWidth: 100,
    cellRenderer: checkOrCrossRenderer,
  },
  ])
}

export const SumSheetColumnDef = (period: PortfolioPeriod, date: string, refreshing: boolean) => {
  const periodMapping = {
    "Monthly": "month",
    "Quarterly": "quarter",
  }
  return compact([  {
    headerName: "Portfolio ID",
    field: "id",
    sortable: true,
    width: 132,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(params.data.id < 0 || params.data.uniqId.startsWith("list_header")) return ""
      return params.data.id
    },
    cellClass: (params: CellClassParams) => {
      return classNames({"font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Beginning Assets",
    field: "beginningAssets.totalMarketValue",
    sortable: true,
    width: 170,
    valueFormatter: currencyFormatter,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      let assets = params.data.assets || []
      let endingDate = moment(date, DATE_API_FORMAT).subtract(1, periodMapping[period] as moment.unitOfTime.DurationConstructor).endOf("month").format(DATE_API_FORMAT)
      let correctAsset = assets.find((asset:any) => asset.date === endingDate)
      if(correctAsset?.totalMarketValue === 0 ) return "0"
      return correctAsset?.totalMarketValue ? correctAsset.totalMarketValue : null
    },
    filter: "agNumberColumnFilter",
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Net Cash Activity",
    field: "cashFlowActivity.netFlow",
    sortable: true,
    width: 170,
    valueFormatter: currencyFormatter,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      let assets = params.data.cashFlowActivity || []
      let correctAsset = assets.find((asset:any) => asset.date === date && asset.period === period)
      if(!correctAsset && period === PortfolioPeriod.Quarterly){
        let totalAsset = assets.reduce((acc:any, asset:any) => {
          if(asset.period === PortfolioPeriod.Monthly &&
            moment(asset.date, DATE_API_FORMAT).isSameOrBefore(moment(date, DATE_API_FORMAT)) &&
            moment(asset.date, DATE_API_FORMAT).isAfter(moment(date, DATE_API_FORMAT).subtract(3, "months").endOf("month"))
          ){
            acc = acc ? acc + asset.netFlow : asset.netFlow
          }
          return acc
        }, undefined)
        if(totalAsset) return totalAsset
      }
      if(correctAsset?.netFlow === 0 ) return 0
      return correctAsset?.netFlow ? correctAsset.netFlow : null
    },
    filter: "agNumberColumnFilter",
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Ending Assets",
    field: "EndingAssets.totalMarketValue",
    sortable: true,
    width: 170,
    valueFormatter: currencyFormatter,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      let assets = params.data.assets || []
      let correctAsset = assets.find((asset:any) => asset.date === date)
      if(correctAsset?.totalMarketValue === 0 ) return "0"
      return correctAsset?.totalMarketValue ? correctAsset.totalMarketValue : null
    },
    filter: "agNumberColumnFilter",
    cellRenderer: endingMarketTooltipCellRenderer,
    cellRendererParams: {values: { date: moment(date, DATE_API_FORMAT).endOf("month").format(DATE_API_FORMAT), period: periodMapping[period]}},
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Gross Return",
    field: `grossPerformance.value.active`,
    sortable: true,
    width: 120,
    valueFormatter: percentFormatter,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      let performances = params.data[`${periodMapping[period]}PerformanceGross`] || []
      const loading = performances.find((performance:any) => performance.status !== "Done")
      if(loading) return "Loading"
      let correctPerformance = performances.find((performance:any) => performance.assetClassGroup.code === AssetClassAbbreviationCode.ALL)
      if(correctPerformance?.value?.active === 0 ) return "0"
      return correctPerformance?.value?.active ? correctPerformance.value.active : null
    },
    cellRenderer: rightAlignedRenderer,
    filter: "agNumberColumnFilter",
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Net Return",
    field: `netPerformance.value.active`,
    sortable: true,
    width: 120,
    valueFormatter: percentFormatter,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      let performances = params.data[`${periodMapping[period]}PerformanceNet`] || []
      const loading = performances.find((performance:any) => performance.status !== "Done")
      if(loading) return "Loading"
      let correctPerformance = performances.find((performance:any) => performance.assetClassGroup.code === AssetClassAbbreviationCode.ALLN)
      if(correctPerformance?.value?.active === 0 ) return "0"
      return correctPerformance?.value?.active ? correctPerformance.value.active : null
    },
    cellRenderer: rightAlignedRenderer,
    filter: "agNumberColumnFilter",
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Vehicle Return",
    field: `relatedVehicle.defaultPerformance.value.active`,
    sortable: true,
    width: 120,
    valueFormatter: percentFormatter,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      let vehicle = params.data?.relatedVehicle?.vehicle
      if(!vehicle) return null
      let performances = vehicle[`${periodMapping[period]}Performance`] || []
      // const loading = performances.find((performance:any) => performance.status !== "Done")
      // if(loading) return "Loading"
      let correctPerformance = first(performances) as any
      if(correctPerformance?.value?.active === 0 ) return "0"
      return correctPerformance?.value?.active ? correctPerformance.value.active : null
    },
    cellRenderer: vehicleReturnTooltipCellRenderer,
    filter: "agNumberColumnFilter",
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Benchmark Return",
    field: `performanceTargetMap.target.defaultPerformance.value.active`,
    sortable: true,
    width: 120,
    valueFormatter: percentFormatter,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      let targets = params.data?.performanceTargetMap || []
      let correctTarget = targets.length > 1 ? targets.find((target:any) => target.order === 1) : first(targets)
      if(!correctTarget) return null
      let performances = correctTarget.target[`${periodMapping[period]}Performance`] || []
      const loading = performances.find((performance:any) => performance.status !== "Done")
      if(loading) return "Loading"
      let correctPerformance = first(performances) as any
      if(correctPerformance?.value?.active === 0 ) return "0"
      return correctPerformance?.value?.active ? correctPerformance.value.active : null
    },
    cellRenderer: benchmarkTooltipCellRenderer,
    filter: "agNumberColumnFilter",
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Calculated Return",
    field: `calculatedReturn`,
    sortable: true,
    width: 120,
    valueFormatter: percentFormatter,
    editable: false,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      if(params.data.id < 0 ) return null
      let assets = params.data.assets || []
      let beginningDate = moment(date, DATE_API_FORMAT).subtract(1, periodMapping[period] as moment.unitOfTime.DurationConstructor).endOf("month").format(DATE_API_FORMAT)
      let cashFlows = params.data.cashFlowActivity || []
      let beginningAssets = assets.find((asset:any) => asset.date === beginningDate)
      let endingAsset = assets.find((asset:any) => asset.date === date)
      let netCashActivity = cashFlows.find((asset:any) => asset.date === date && asset.period === period)
      let beginningAssetsValue = beginningAssets?.totalMarketValue || 0
      let endingAssetsValue = endingAsset?.totalMarketValue || 0
      let netCashActivityValue = netCashActivity?.netFlow || 0
      if(beginningAssetsValue === 0 && netCashActivityValue === 0) return "0"
      let finalValue = (endingAssetsValue - beginningAssetsValue - netCashActivityValue) / (beginningAssetsValue + (0.5 * netCashActivityValue))
      if(finalValue === 0 ) return "0"
      return finalValue * 100
    },
    cellRenderer: rightAlignedRenderer,
    filter: "agNumberColumnFilter",
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, "font-weight-bold": params.data.group?.length === 1})
    },
  },
  {
    headerName: "Last Modified",
    field: "cashFlowActivity.lastUpdated",
    cellRenderer: (params: ICellRendererParams) => cashflowChangedDateRenderer(params, date),
    sortable: true,
    width: 180,
    valueGetter: (params: ValueGetterParams) => {
      if(refreshing) return "Loading"
      let assets = params.data.cashFlowActivity || []
      let correctAsset = assets.find((asset:any) => asset.date === date && asset.period === period)
      return correctAsset?.lastUpdated ? correctAsset.lastUpdated : null
    },
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_TIME_DISPLAY_FORMAT) : "",
    editable: false,
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    },
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var cellMoment = moment(dateAsString, DATE_API_FORMAT);
        var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
        return cellMoment.diff(filterLocalDateAtMidnightMoment)
      },
    },
    cellClass: (params: CellClassParams) => {
      return classNames({"font-weight-bold": params.data.group?.length === 1})
    },
  },
  ])
}

export const CashFlowColumnDef = (setCurrentColumnData: (value:React.SetStateAction<CashFlowDataType[]>) => void, lookupData: TransactionTypeWithTitles[], baseCurrency: BaseCurrencyCode, startDate: string, endDate: string, overWarningValue: number) => {
  const valueSetter = (rowId:string, field: string, value: any) => {
    let changed = false
    setCurrentColumnData((prev) => {
      let updatedRows = cloneDeep(prev)
      const index = updatedRows.findIndex((item) => item.rowId === rowId)
      if(index !== -1 && field){
        const oldRow = cloneDeep(updatedRows[index])
        const newRow = {
          ...updatedRows[index],
          [field]: value,
        }
        if(JSON.stringify(oldRow) !== JSON.stringify(newRow)){
          changed = true
          return [
            ...updatedRows.slice(0, index),
            newRow,
            ...updatedRows.slice(index + 1),
          ]
        }
      }
      return prev
    })
    return changed
  }
  const usedSymbol = currencySymbolMapping[baseCurrency]
  return compact([{
    headerName: "Date",
    field: "transactionDate",
    sortable: true,
    width: 200,
    // valueGetter: function(params: ValueGetterParams) {
    //   return params.data && params.data.plans ? params.data.plans.map((plan:any) => plan.name) : ""
    // },
    // cellRenderer: reportPlanLinkRenderer,
    checkboxSelection: true,
    // for select all
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_DISPLAY_FORMAT) : "",
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    },
    editable: true,
    cellRendererParams: {values: { editMode: true }},
    cellRenderer: calendarCellRenderer,
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "date",
      leftAlignValue: true,
      inputProps: {
        min: startDate,
        max: endDate,
      }
      // formSubtype: "currency",
    },
    valueSetter: (params: ValueSetterParams) => {
      //check new value is between start and end date
      if(moment(params.newValue).isBefore(startDate) || moment(params.newValue).isAfter(endDate)){
        return false
      }
      valueSetter(params.data.rowId, "adjustedTransactionDate", params.newValue)
      valueSetter(params.data.rowId, "periodEndDate", moment(params.newValue).endOf("month").format(DATE_API_FORMAT))
      return valueSetter(params.data.rowId, "transactionDate", params.newValue)
    },
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var cellMoment = moment(dateAsString, DATE_API_FORMAT);
        var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
        return cellMoment.diff(filterLocalDateAtMidnightMoment)
      },
    },
  },
  {
    headerName: "Type",
    field: "transactionType",
    sortable: true,
    width: 255,
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      cellHeight: 25,
      values: lookupData.map(data => data.code),
      cellRenderer: (params:ICellRendererParams) => selectTransactionTypeRenderer(params, lookupData),
    },
    cellEditorPopup: true,
    cellRenderer: (params:ICellRendererParams) => selectTransactionTypeRenderer(params, lookupData),
    valueGetter: (params: ValueGetterParams) => {
      return params.data.transactionType
    },
    filterValueGetter: (params: ValueGetterParams) => {
      let newValue = params.data.transactionType
      let matchedType = lookupData.find((item) => item.code === newValue)
      return matchedType ? matchedType.name : newValue
    },
    valueSetter: (params: ValueSetterParams) => {
      let newValue = params.newValue
      if(newValue.startsWith("frequently-")) newValue = newValue.replace("frequently-", "")
      let matchedType = lookupData.find((item) => item.code === newValue)
      if(!matchedType || "title" in matchedType){
        return false
      }
      if(newValue.startsWith("frequently-")) newValue = newValue.replace("frequently-", "")
      return valueSetter(params.data.rowId, "transactionType", "code" in matchedType && matchedType.code)
    },
    editable: true,
  },
  {
    headerName: `Assets ${baseCurrency !== BaseCurrencyCode.USADOL ? `(${usedSymbol})` : ""}`,
    field: "amount",
    sortable: true,
    width: baseCurrency !== BaseCurrencyCode.USADOL ? 150 : 300,
    valueFormatter: (params: ValueFormatterParams) => currencyFormatter(params, {...currencyWithMantissaFormat, currencySymbol: usedSymbol}),
    editable: true,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetter(params.data.rowId, "amount", params.newValue)
    },
    cellRenderer: (params:ICellRendererParams) => overValueWarningRenderer(params, overWarningValue),
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "number",
      formSubtype: "currency",
    },
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0})
    },
    filter: "agNumberColumnFilter",
  },
  baseCurrency !== BaseCurrencyCode.USADOL ? {
    headerName: "Conversion ($)",
    field: "convertedAmount",
    sortable: true,
    width: 150,
    valueFormatter: currencyFormatter,
    editable: false,
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames('ag-cell-edit-disabled',{"text-danger": params.value < 0})
    },
    filter: "agNumberColumnFilter",
  } : null,
  ])
}

export const PlanPortfolioCashFlowColumnDef = (editMode: boolean, setCurrentColumnData: (value:React.SetStateAction<CashFlowDataTypeWithEndingAssets[]>) => void, lookupData: TransactionTypeWithTitles[], baseCurrency: BaseCurrencyCode, startDate: string, endDate: string, overWarningValue: number, historicalView: boolean) => {
  const valueSetter = (rowId:string, field: string, value: any) => {
    let changed = false
    setCurrentColumnData((prev) => {
      let updatedRows = cloneDeep(prev)
      const index = updatedRows.findIndex((item) => item.rowId === rowId)
      if(index !== -1 && field){
        const oldRow = cloneDeep(updatedRows[index])
        const newRow = {
          ...updatedRows[index],
          [field]: value,
        }
        if(JSON.stringify(oldRow) !== JSON.stringify(newRow)){
          changed = true
          return [
            ...updatedRows.slice(0, index),
            newRow,
            ...updatedRows.slice(index + 1),
          ]
        }
      }
      return prev
    })
    return changed
  }
  const usedSymbol = currencySymbolMapping[baseCurrency]
  return compact([{
    headerName: "Date",
    field: "transactionDate",
    sortable: true,
    width: 200,
    // valueGetter: function(params: ValueGetterParams) {
    //   return params.data && params.data.plans ? params.data.plans.map((plan:any) => plan.name) : ""
    // },
    // cellRenderer: reportPlanLinkRenderer,
    checkboxSelection: editMode,
    // for select all
    headerCheckboxSelection: editMode,
    headerCheckboxSelectionFilteredOnly: editMode,
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_DISPLAY_FORMAT) : "",
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    },
    editable: true,
    cellRendererParams: {values: { editMode }},
    cellRenderer: calendarCellRenderer,
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "date",
      leftAlignValue: true,
      inputProps: {
        min: historicalView ? "1950-12-31" : startDate,
        max: endDate,
      }
      // formSubtype: "currency",
    },
    valueSetter: (params: ValueSetterParams) => {
      //check new value is between start and end date
      if((historicalView && moment(params.newValue).isBefore("1950-12-31")) || (!historicalView && moment(params.newValue).isBefore(startDate)) || moment(params.newValue).isAfter(endDate)){
        return false
      }
      valueSetter(params.data.rowId, "adjustedTransactionDate", params.newValue)
      valueSetter(params.data.rowId, "periodEndDate", moment(params.newValue).endOf("month").format(DATE_API_FORMAT))
      valueSetter(params.data.rowId, "transactionNumber", -1)
      return valueSetter(params.data.rowId, "transactionDate", params.newValue)
    },
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var cellMoment = moment(dateAsString, DATE_API_FORMAT);
        var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
        return cellMoment.diff(filterLocalDateAtMidnightMoment)
      },
    },
  },
  historicalView ? {
    headerName: "As-of",
    field: "periodEndDate",
    sortable: true,
    width: 200,
    // valueGetter: function(params: ValueGetterParams) {
    //   return params.data && params.data.plans ? params.data.plans.map((plan:any) => plan.name) : ""
    // },
    // cellRenderer: reportPlanLinkRenderer,
    // checkboxSelection: true,
    // for select all
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_DISPLAY_FORMAT) : "",
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    },
    editable: false,
    cellRendererParams: {values: { editMode }},
    cellRenderer: calendarCellRenderer,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var cellMoment = moment(dateAsString, DATE_API_FORMAT);
        var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
        return cellMoment.diff(filterLocalDateAtMidnightMoment)
      },
    },
    cellClass: (params: CellClassParams) => {
      return classNames({'ag-cell-edit-disabled': editMode})
    },
  } : null,
  {
    headerName: "Type",
    field: "transactionType",
    // cellRenderer: ReportLinkRenderer,
    sortable: true,
    width: 255,
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      cellHeight: 25,
      values: lookupData.map(data => data.code),
      cellRenderer: (params:ICellRendererParams) => selectTransactionTypeRenderer(params, lookupData),
    },
    cellEditorPopup: true,
    cellRenderer: (params:ICellRendererParams) => selectTransactionTypeRenderer(params, lookupData),
    valueGetter: (params: ValueGetterParams) => {
      return params.data.transactionType
    },
    filterValueGetter: (params: ValueGetterParams) => {
      let newValue = params.data.transactionType
      let matchedType = lookupData.find((item) => item.code === newValue)
      return matchedType ? matchedType.name : newValue
    },
    valueSetter: (params: ValueSetterParams) => {
      let newValue = params.newValue
      if(newValue.startsWith("frequently-")) newValue = newValue.replace("frequently-", "")
      let matchedType = lookupData.find((item) => item.code === newValue)
      if(!matchedType || "title" in matchedType){
        return false
      }
      return valueSetter(params.data.rowId, "transactionType", "code" in matchedType && matchedType.code)
    },
    filterParams: {
      valueFormatter: (params: ValueFormatterParams) => {
        let matchedType = lookupData.find((item) => item.code === params.value)
        return matchedType?.name || params.value
      },
      comparator: (a:any, b: any) => {
        let matchedTypeA = lookupData.find((item) => item.code === a)
        let matchedTypeB = lookupData.find((item) => item.code === b)
        return matchedTypeA?.name?.localeCompare(matchedTypeB?.name || "")
      }
    },
    editable: true,
  },
  {
    headerName: `Assets ${baseCurrency !== BaseCurrencyCode.USADOL ? `(${usedSymbol})` : ""}`,
    field: "amount",
    sortable: true,
    width: baseCurrency !== BaseCurrencyCode.USADOL ? 150 : 300,
    valueFormatter: (params: ValueFormatterParams) => currencyFormatter(params, {...currencyWithMantissaFormat, currencySymbol: usedSymbol}),
    editable: true,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetter(params.data.rowId, "amount", params.newValue)
    },
    cellRenderer: (params:ICellRendererParams) => cashflowAmountTooltipCellRenderer(params, {...currencyWithMantissaFormat, currencySymbol: usedSymbol}),
    cellEditor: "formInputComponent",
    // cellEditorPopup: true,
    cellEditorParams: {
      formType: "number",
      formSubtype: "currency",
    },
    filter: "agNumberColumnFilter",
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0})
    },
  },
  baseCurrency !== BaseCurrencyCode.USADOL ? {
    headerName: "Conversion ($)",
    field: "convertedAmount",
    sortable: true,
    width: 150,
    valueFormatter: currencyFormatter,
    editable: false,
    cellRenderer: rightAlignedRenderer,
    cellClass: (params: CellClassParams) => {
      return classNames({"text-danger": params.value < 0, 'ag-cell-edit-disabled': editMode})
    },
    filter: "agNumberColumnFilter",
  } : null,
  {
    headerName: "Updated",
    field: "updateDate",
    cellRenderer: sourceCashFlowTooltipCellRenderer,
    sortable: true,
    width: 160,
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_TIME_DISPLAY_FORMAT) : "",
    editable: false,
    comparator: (valueA:any, valueB:any, nodeA:any, nodeB:any, isInverted:boolean) => {
      if(!valueA && !valueB) return 0
      if(!valueA) return isInverted ? -1 : 1
      if(!valueB) return isInverted ? 1 : -1
      const dateA = moment(valueA)
      const dateB = moment(valueB)
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    },
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var cellMoment = moment(dateAsString, DATE_API_FORMAT);
        var filterLocalDateAtMidnightMoment = moment(filterLocalDateAtMidnight).startOf('day');
        return cellMoment.diff(filterLocalDateAtMidnightMoment)
      },
    },
    cellClass: (params: CellClassParams) => {
      return editMode ? 'ag-cell-edit-disabled' : '';
    },
  },
  ])
}
